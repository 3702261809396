import { ErrorOutline, HighlightOff, InfoOutlined, Sync, SyncProblem } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Avatar,
  // useTheme,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  authorizedFetch,
  drawer,
  getDarkModePreference,
  getDuration,
  GlobalState,
  setLoader,
  snackbar,
  titleCase,
} from "utils";
import CircularLoader from "components/CircularLoader";
import { queryClient } from "index";
import { useQuery } from "react-query";
import {
  BOLT_EARTH_COMPANY_ID,
  BOLT_URL,
  OCPP_URL,
  PAYMENTS_URL,
  WIFI_URL,
} from "utils/constants";
// import BookingsEarnings from "./BookingsEarnings";
import ChargerLogs from "./ChargerLogs";

const InfoDrawer = ({ booking }: any) => {
  const [initiateRefundDialog, setInitiateRefundDialog] = useState(false);
  const [initiateSettlementDialog, setInitiateSettlementDialog] =
    useState(false);

  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );

  // const theme = useTheme();

  const [tab, setTab] = useState(0);

  let { user, company } = useSelector((state: GlobalState) => state.global);
  const shouldShowRefundFlow = user.email === "walletmanager@bolt.earth";
  const isBoltAccount = company.id === BOLT_EARTH_COMPANY_ID;
  const isCUGMonitorAccount = company?.type === "CUG_VENDOR";

  const bookingUrl = `${BOLT_URL}/company/booking/${booking?.bookingId}`;
  const { isLoading: bookingLoading, data: bookingData } = useQuery(
    ["getBooking", booking?.bookingId],
    () => authorizedFetch(bookingUrl)
  );

  const isOCPP = booking?.communicationProtocol === "OCPP";
  const isMQTT = booking?.communicationProtocol === "MQTT";
  const shouldShowLiveData =
    booking?.bookingStatus === "BOOKED" && (isOCPP || isMQTT);

  const url = isOCPP
    ? `${OCPP_URL}/charger/getChargerData`
    : `${WIFI_URL}/fetchEnergyConsumedV2`;

  const { isLoading: chargerLoading, data: chargerData } = useQuery(
    [
      "getChargerData",
      booking?.chargerId,
      booking?.connectorId,
      booking?.bleMac,
      booking?.bookingId,
    ],
    () =>
      authorizedFetch(url, {
        method: "POST",
        headers: {
          stage: "test",
          "Content-Type": "application/json",
        },
        body: isOCPP
          ? {
              chargerId: booking?.chargerId + "/" + booking?.connectorId,
              action: "currentMeterValues",
            }
          : {
              deviceId: booking?.bleMac?.replaceAll(":", ""),
              bookingId: booking?.bookingId,
            },
      }),
    {
      enabled: shouldShowLiveData,
      refetchInterval: shouldShowLiveData ? 10000 : false,
    }
  );

  const bookingSettlementUrl = `${PAYMENTS_URL}/v1/payments/settlement/singlesettlement/payout?bookingId=${booking?.bookingId}`;
  const { data: bookingSettlementData } = useQuery(
    ["getBookingsSettlement", booking?.bookingId],
    () => authorizedFetch(bookingSettlementUrl),
    {
      enabled: !isCUGMonitorAccount && !!booking?.bookingId,
    }
  );

  function initiateSettlement() {
    setLoader(true);
    const url = `${PAYMENTS_URL}/v1/payments/payouts/cashfree/settlements`;
    authorizedFetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        bookingId: booking.bookingId,
      },
    })
      .then((res) => {
        setLoader(false);
        if (res.status >= 400) {
          snackbar.error(res?.data?.message || "Error initiating settlement");
        } else {
          snackbar.success("Settlement initiated");
          queryClient.resetQueries("getBooking");
          queryClient.resetQueries("getBookings");
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
      });
  }

  function initiateRefund() {
    setLoader(true);
    const url = `${PAYMENTS_URL}/v1/payments/refunds/cashfree/initiatedirectrefund`;
    authorizedFetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        bookingId: booking.bookingId,
        amount: booking.amount,
        remark: "Customer requested Refund",
      },
    })
      .then((res) => {
        setLoader(false);
        if (res.status >= 400) {
          snackbar.error(res.message || "Error initiating refund");
        } else {
          snackbar.success("Refund initiated");
          queryClient.resetQueries("getBooking");
          queryClient.resetQueries("getBookings");
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
      });
  }

  const { value, unit } = chargerData?.data || {};

  const liveEnergyConsumed = shouldShowLiveData
    ? isOCPP
      ? // For OCPP
        (value ? value.toFixed(3) : 0) + " " + (unit || "kWh")
      : // For MQTT
        (chargerData?.energyConsumed
          ? (chargerData?.energyConsumed / 1000).toFixed(3)
          : 0) + " kWh"
    : null;

  const energyConsumed =
    (booking?.energyConsumed ? booking.energyConsumed.toFixed(3) : 0) + " kWh";

  const table = [
    { header: "Basic Details" },
    ...(isCUGMonitorAccount ? [
      { label: "Booking ID", value: bookingData?.data?.bookingId },
      { label: "Charger UID", value: bookingData?.data?.chargerId },
      {
        label: "Booking Status",
        value: (<Avatar
          variant="status"
          className={
            bookingData?.data?.bookingStatus === "CANCELLED"
              ? "red"
              : bookingData?.data?.bookingStatus === "PAYMENT_PENDING"
                ? "yellow"
                : bookingData?.data?.bookingStatus === "ENDED"
                  ? "blue"
                  : ""
          }
        >
          {titleCase(
            (bookingData?.data?.bookingStatus === "ENDED"
              ? "TERMINATED"
              : bookingData?.data?.bookingStatus === "TERMINATED"
                ? "ENDED"
                : bookingData?.data?.bookingStatus === "CANCELLED"
                  ? "CANCELLED"
                  : bookingData?.data?.bookingStatus === "PAYMENT_PENDING"
                    ? "PAYMENT_PENDING"
                    : bookingData?.data?.bookingStatus === "BOOKED"
                      ? "BOOKED"
                      : "N/A"
            )
              .split("_")
              .join(" ")
          )}
        </Avatar>)
      },

    ] : []),
    {
      label: "Booking From",
      value: moment(bookingData?.data?.bookingStart).format(
        "MMM DD YYYY, hh:mm a"
      ),
    },
    {
      label: "Booking To",
      value: moment(bookingData?.data?.bookingEnd).format(
        "MMM DD YYYY, hh:mm a"
      ),
    },
    {
      label: `${isCUGMonitorAccount ? "Booking" : "Total"} Duration`,
      value: getDuration((bookingData?.data?.bookingDuration || 0) * 60),
    },
    ...(isCUGMonitorAccount ? [
      { label: "Charging Duration", value: getDuration((bookingData?.data?.chargingDuration || 0) * 60), },
      { label: "Est. Energy Consumption", value: bookingData?.data?.estimatedEnergyConsumption.toFixed(3) + " kWh" },
      { label: "Energy Utilised", value: energyConsumed },
      {
        label: "Sync Status",
        value: bookingData?.data?.kibanaSynced === true ? (
          <Box display="flex" alignItems="center">
            <Sync fontSize="small" color="success" sx={{ mr: 0.5 }} />
            Synced
          </Box>
        ) : (
          <Box display="flex" alignItems="center">
            <SyncProblem
              fontSize="small"
              color="warning"
              sx={{ mr: 0.5 }}
            />
            Not synced
          </Box>
        )
      },
    ] : [
      {
        label: "User Name",
        value: bookingData?.data?.userName || "-",
      },
      {
        label: "User Phone",
        value: bookingData?.data?.userPhone
          ? bookingData.data.userPhone.slice(0, 3) +
          " " +
          bookingData.data.userPhone.slice(3)
          : "-",
      },
      {
        label: "Energy Consumed",
        value: shouldShowLiveData
          ? chargerLoading
            ? "Loading..."
            : liveEnergyConsumed
          : energyConsumed,
      },
    ]),

    ...(isCUGMonitorAccount ? [
      { header: "User Info" },
      {
        label: "User Name",
        value: bookingData?.data?.userName || "-",
      },
      {
        label: "User Phone",
        value: bookingData?.data?.userPhone
          ? bookingData.data.userPhone.slice(0, 3) +
          " " +
          bookingData.data.userPhone.slice(3)
          : "-",
      },
    ] : [
      { header: "Pricing Info" },
      { label: "Payment Type", value: booking?.paymentType },
      { label: "Payment Status", value: booking?.paymentStatus },
      { label: "Transaction ID", value: booking?.transactionId },
    ]),

    { header: "Vehicle Info" },
    { label: "Vehicle Company", value: booking?.vehicleCompany },
    { label: "Vehicle Model", value: booking?.vehicleModel },
    { label: "Vehicle Type", value: booking?.vehicleType.split("_").join(" ") },

    ...(isCUGMonitorAccount ? [
      { header: "Payments Info" },
      { label: "Actual Bill", value: Number(bookingData?.data?.amount + bookingData?.data?.couponDiscount), },
      {
        label: (<Box display="flex" alignItems="center">
          Amount Discounted
          <Tooltip
            title={<>By CUG</>}
            placement="right"
          >
            <InfoOutlined
              sx={{ ml: 0.5, fontSize: 14, cursor: "pointer" }}
            />
          </Tooltip>
        </Box>), value: bookingData?.data?.couponDiscount,
      },
      {
        label: (<Box display="flex" alignItems="center">
          Final Amount Paid
          <Tooltip
            title={<>By User</>}
            placement="right"
          >
            <InfoOutlined
              sx={{ ml: 0.5, fontSize: 14, cursor: "pointer" }}
            />
          </Tooltip>
        </Box>), value: bookingData?.data?.amount,
      },
      { label: "Transaction ID", value: bookingData?.data?.transactionId.split("_").join(" "), },

      { header: "CUG Details" },
      { label: "CUG Name", value: bookingData?.data?.cugDetails?.cugId?.name },
      { label: "CUG Status", value: titleCase(bookingData?.data?.cugDetails?.cugId?.status || "") },
      { label: "Pricing Name", value: bookingData?.data?.cugDetails?.pricingDetails?.label },
      ...(bookingData?.data?.cugDetails?.pricingDetails?.pricing?.type === "DISCOUNT" ? [ // "DISCOUNT"
        {
          label: "Pricing Mode",
          value: titleCase(
            bookingData?.data?.cugDetails?.pricingDetails?.pricing?.type || ""
          ),
        },
        ...(bookingData?.data?.cugDetails?.pricingDetails?.pricing?.discountType === "PERCENT" ? [ // "PERCENTAGE"
          {
            label: "Discount Type",
            value: titleCase(
              bookingData?.data?.cugDetails?.pricingDetails?.pricing?.discountType || ""
            ),
          },
          {
            label: "Pricing Amount",
            value: `${bookingData?.data?.cugDetails?.pricingDetails?.pricing?.value} %`,
          },

        ] : [ // "FIXED"
          {
            label: "Discount Type",
            value: titleCase(
              bookingData?.data?.cugDetails?.pricingDetails?.pricing?.discountType || ""
            ),
          },
          {
            label: "Pricing Amount",
            value: `₹ ${bookingData?.data?.cugDetails?.pricingDetails?.pricing?.value} / kwh`,
          },
        ]),
      ] : [ // "FREE"
        {
          label: "Pricing Mode",
          value: titleCase(
            bookingData?.data?.cugDetails?.pricingDetails?.pricing?.type || ""
          ),
        },
      ]),

    ] : []),
    ...(!isCUGMonitorAccount && booking?.amount >= 0
      ? [
          { header: "Settlement Details" },
          {
            label: "Settlement ID",
            value: bookingSettlementData?.data?.utr || "-",
          },
          {
            label: "Settlement Amount",
            value: (
              <Box display="flex" alignItems="center">
                {`₹${
                  Number(booking.amount || 0) +
                  Number(booking.subscriptionDiscount || 0) +
                  Number(booking.couponDiscount || 0)
                }`}
                <Tooltip
                  title={
                    <>
                      Amount: {`₹${booking.amount}`}
                      <br />
                      Subscription Discount:{" "}
                      {`₹${booking.subscriptionDiscount}`}
                      <br />
                      Coupon Discount: {`₹${booking.couponDiscount}`}
                    </>
                  }
                  placement="right"
                >
                  <InfoOutlined
                    sx={{ ml: 0.5, fontSize: 14, cursor: "pointer" }}
                  />
                </Tooltip>
              </Box>
            ),
          },
          {
            label: "Settlement Status",
            value:
              bookingSettlementData?.data?.status === "PENDING"
                ? "Pending"
                : bookingSettlementData?.data?.status === "REVERSED"
                ? "Pending on Payment Gateway Side"
                : bookingSettlementData?.data?.status === "PENDING_AT_BANK"
                ? "Pending on Bank Side"
                : bookingSettlementData?.data?.status === "SUCCESS"
                ? "Settled"
                : "N/A",
          },
          {
            label: "Settlement Date",
            value:
              bookingSettlementData?.data?.status === "SUCCESS" ||
              bookingSettlementData?.data?.status === "PENDING_AT_BANK"
                ? moment(bookingSettlementData?.data?.processedOn).format(
                    "MMM DD YYYY, hh:mm a"
                  )
                : "-",
          },
        ]
      : []),
    ...(booking?.userFeedback === true
      ? [
          { header: "Additional Details" },
          { label: "User Rating", value: booking?.userRating + "/" + 5 },
        ]
      : []),
  ];

  const refundTable = [
    { header: "Refund Details" },
    {
      label: "Refund Amount",
      value: bookingData?.data?.amount ? "₹" + bookingData?.data?.amount : "-",
    },
    { label: "Status", value: bookingData?.data?.refundStatus || "-" },

    {
      label: "Initiated On",
      value: !bookingData?.data?.refundDate
        ? "-"
        : moment(bookingData?.data?.refundDate).format("MMM DD YYYY, hh:mm a"),
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            px: 3,
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: isDarkMode ? "#000" : "#3D3D3D",
            fontWeight: 500,
            color: "#fff",
          }}
        >
          Booking #{booking.bookingId}
          {/* <Box display="grid" gridTemplateColumns="repeat(2, auto)" gap={1}> */}
          {/* <IconButton
              children={<DeleteOutline />}
              color="inherit"
              size="small"
              onClick={() => setDeleteDialog(true)}
            /> */}
          <IconButton
            children={<HighlightOff />}
            color="inherit"
            size="small"
            onClick={() => drawer.close()}
          />
          {/* </Box> */}
        </Box>
        <Box p={2} pb={0}>
          <Tabs
            className="dense"
            value={tab}
            onChange={(e: any, tab: any) => {
              setTab(tab);
            }}
          >
            <Tab label="Information" />
            {isBoltAccount && <Tab label="Charger Logs" />}
            {shouldShowRefundFlow && <Tab label="Refund" />}
          </Tabs>
        </Box>
        <Box flexGrow={1} overflow="auto" pb={4}>
          {tab === 0 && (
            <>
              {bookingLoading ? (
                <Box mt={2}>
                  <CircularLoader />
                </Box>
              ) : (
                <Box
                  sx={{
                    px: 3,
                    pt: 2.5,
                    "& .table": {
                      borderCollapse: "collapse",
                      width: 1,
                      fontSize: 14,
                      lineHeight: "16px",
                      "& td": {
                        py: 1.5,
                        px: 2,
                        "&.secondary": {
                          color: "text.secondary",
                        },
                      },
                      "& .bold": {
                        fontWeight: 500,
                      },
                      "& .header": {
                        position: "relative",
                        "& td": {
                          py: 2,
                          position: "absolute",
                          verticalAlign: "middle",
                          backgroundColor: (theme: any) =>
                            theme.customColors.header,
                          width: 1,
                          borderRadius: "4px",
                          fontSize: 16,
                          fontWeight: 600,
                          "& span": {
                            display: "inline-block",
                            transform: "translateY(1px)",
                          },
                        },
                      },
                      "& .first > td": {
                        pt: 8.5,
                      },
                      "& .last > td": {
                        pb: 3,
                      },
                    },
                  }}
                >
                  <table className="table">
                    <tbody>
                      {table.map(({ header, label, value }, i) => {
                        const isFirst = table[i - 1]?.header;
                        const isLast = !table[i + 1] || table[i + 1].header;

                        return (
                          <tr
                            key={i}
                            className={
                              header
                                ? "header"
                                : `${isFirst ? "first" : ""} ${
                                    isLast ? "last" : ""
                                  }`
                            }
                          >
                            {header ? (
                              <td colSpan={2}>
                                {header === "Settlement Details" &&
                                !bookingSettlementData?.data?.status ? (
                                  <Box display="flex" alignItems="center">
                                    {header}
                                    <Box
                                      sx={{
                                        ml: 2,
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: 12,
                                        color: "text.secondary",
                                      }}
                                    >
                                      <ErrorOutline
                                        fontSize="inherit"
                                        color="error"
                                        sx={{ fontSize: 14, mr: 0.5 }}
                                      />
                                      Not applicable for this transaction
                                    </Box>
                                  </Box>
                                ) : (
                                  <span>{header}</span>
                                )}
                              </td>
                            ) : (
                              <>
                                <td className="bold">{label}</td>
                                <td
                                  className={
                                    value === "Loading..." ? "secondary" : ""
                                  }
                                >
                                  {value}
                                </td>
                              </>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Box>
              )}
            </>
          )}
          {tab === 1 && (
            <ChargerLogs
              bookingId={booking?.bookingId}
              isOCPP={booking?.communicationProtocol === "OCPP"}
            />
          )}
          {tab === 2 && (
            <Box
              sx={{
                px: 3,
                pt: 2.5,
                "& .table": {
                  borderCollapse: "collapse",
                  width: 1,
                  fontSize: 14,
                  lineHeight: "16px",
                  "& td": {
                    py: 2,
                    px: 2,
                    "&.secondary": {
                      color: "text.secondary",
                    },
                  },
                  "& .bold": {
                    fontWeight: 500,
                  },
                  "& .header": {
                    px: 2,
                    py: 1,
                    position: "relative",
                    "& td": {
                      position: "absolute",
                      verticalAlign: "middle",
                      backgroundColor: (theme: any) =>
                        theme.customColors.header,
                      width: 1,
                      borderRadius: "4px",
                      fontSize: 16,
                      fontWeight: 600,
                      "& span": {
                        display: "inline-block",
                        transform: "translateY(1px)",
                      },
                    },
                  },
                  "& .first > td": {
                    pt: 9,
                  },
                  "& .last > td": {
                    pb: 3,
                  },
                },
              }}
            >
              {bookingLoading ? (
                <CircularLoader />
              ) : (
                <>
                  {bookingData?.data?.amount === 0 ||
                  bookingData?.data?.bookingStatus === "BOOKED" ||
                  bookingData?.data?.bookingStatus === "PAYMENT_PENDING" ? (
                    <Alert
                      severity="info"
                      sx={{ maxWidth: 200, mt: 1, mx: "auto" }}
                    >
                      No refund request
                    </Alert>
                  ) : (
                    <table className="table">
                      <tbody>
                        {refundTable.map(({ header, label, value }, i) => {
                          const isFirst = table[i - 1]?.header;
                          const isLast = !table[i + 1] || table[i + 1].header;

                          return (
                            <tr
                              key={i}
                              className={
                                header
                                  ? "header"
                                  : `${isFirst ? "first" : ""} ${
                                      isLast ? "last" : ""
                                    }`
                              }
                            >
                              {header ? (
                                <td colSpan={2}>
                                  <span>{header}</span>
                                </td>
                              ) : (
                                <>
                                  <td className="bold">{label}</td>
                                  <td
                                    className={
                                      value === "Loading..." ? "secondary" : ""
                                    }
                                  >
                                    {value}
                                  </td>
                                </>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                  {bookingData?.data?.amount === 0 ||
                  bookingData?.data?.refundStatus === "COMPLETED" ||
                  bookingData?.data?.refundStatus === "INITIATED" ||
                  bookingData?.data?.bookingStatus === "BOOKED" ||
                  bookingData?.data?.bookingStatus ===
                    "PAYMENT_PENDING" ? null : (
                    <Box my={2} ml={2}>
                      <Button
                        variant="contained"
                        onClick={() => setInitiateRefundDialog(true)}
                      >
                        Initiate
                      </Button>
                    </Box>
                  )}
                </>
              )}
            </Box>
          )}
        </Box>
      </Box>
      <RefundDialog
        open={initiateRefundDialog}
        handleClose={() => {
          setInitiateRefundDialog(false);
          drawer.close();
        }}
        initiate={() => {
          initiateRefund();
          setInitiateRefundDialog(false);
          drawer.close();
        }}
      />
      <SettlementDialog
        open={initiateSettlementDialog}
        handleClose={() => {
          setInitiateSettlementDialog(false);
          drawer.close();
        }}
        initiate={() => {
          initiateSettlement();
          setInitiateSettlementDialog(false);
          drawer.close();
        }}
      />
    </>
  );
};

const RefundDialog = ({ open, handleClose, initiate }: any) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Initiate Refund</DialogTitle>
      <DialogContent sx={{ marginTop: "15px" }}>
        Are you sure you want to initiate refund ?{" "}
      </DialogContent>
      <DialogActions sx={{ margin: "15px 15px 15px 0" }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={initiate}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const SettlementDialog = ({ open, handleClose, initiate }: any) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Initiate Settlement</DialogTitle>
      <DialogContent sx={{ marginTop: "15px" }}>
        Are you sure you want to initiate settlement ?{" "}
      </DialogContent>
      <DialogActions sx={{ margin: "15px 15px 15px 0" }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={initiate}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default InfoDrawer;
