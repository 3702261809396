import { Box, CircularProgress } from "@mui/material";

const CircularLoader = () => {
  return (
    <Box
      sx={{
        width: 36,
        height: 36,
        mx: "auto",
        borderRadius: 100,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: (theme) => theme.shadows[2],
        bgcolor: (theme) => theme.palette.background.paper,
        zIndex: 1,
      }}
    >
      <CircularProgress size={22} />
    </Box>
  );
};

export default CircularLoader;
