import { Sort } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  Hidden,
  MenuItem,
  Paper,
  Select,
  Tooltip,
} from "@mui/material";
import Breadcrumbs from "components/Breadcrumbs";
import Search from "components/Search";
// import Search from "components/Search";
import Table from "components/Table";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { authorizedFetch, titleCase, drawer } from "utils";
import { LEASE_URL } from "utils/constants";
import DrawerContent from "./DrawerContent";

const KYC = () => {
  const [tab, setTab] = useState(0);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");

  useEffect(() => {
    return () => {
      drawer.close();
    };
  }, []);

  const url = `${LEASE_URL}/company/kyc/getall?first=${pageSize}&skip=${
    pageSize * (page - 1)
  }${
    tab === 0
      ? ""
      : tab === 1
      ? "&status=APPROVED"
      : tab === 2
      ? "&status=PENDING"
      : tab === 3
      ? "&status=REJECTED"
      : "&status=REQUIRED"
  }${search ? `&search=${search}` : ""}`;
  const {
    isLoading: kycLoading,
    data: kycData,
    refetch: refetchKYC,
  } = useQuery(["getKYC", page, pageSize, tab, search], () =>
    authorizedFetch(url)
  );

  const countUrl = `${LEASE_URL}/company/kyc/count/getall`;
  const { data: countData, refetch: refetchCount } = useQuery(
    ["getCount", page, pageSize, tab, search],
    () => authorizedFetch(countUrl)
  );

  return (
    <>
      <Box
        width={1}
        mt={0.5}
        mb={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Breadcrumbs />
      </Box>
      <Paper
        sx={{
          width: 1,
          boxShadow: (theme) => theme.customShadows.small,
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            width: 1,
            p: 3,
            // pb: 2.75,
            // mb: 3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box display="flex" width={1}>
            <Tooltip title="Vehicle Status" placement="top-start">
              <FormControl sx={{ mr: 2 }}>
                <Select
                  size="small"
                  sx={{ width: "auto" }}
                  value={tab}
                  onChange={(e: any) => setTab(e.target.value)}
                  startAdornment={<Sort sx={{ mr: 1 }} />}
                  renderValue={(selected) =>
                    selected === 0
                      ? "Status: All"
                      : selected === 1
                      ? "Status: Approved"
                      : selected === 2
                      ? "Status: Pending"
                      : selected === 3
                      ? "Status: Rejected"
                      : "Status: Required"
                  }
                >
                  <MenuItem value={0}>
                    All{" "}
                    <span style={{ fontSize: 10, marginLeft: 5 }}>
                      ({countData?.data?.count?.totalCount || "0"})
                    </span>
                  </MenuItem>
                  <MenuItem value={1}>
                    Approved{" "}
                    <span style={{ fontSize: 10, marginLeft: 5 }}>
                      ({countData?.data?.count?.approved || "0"})
                    </span>
                  </MenuItem>
                  <MenuItem value={2}>
                    Pending{" "}
                    <span style={{ fontSize: 10, marginLeft: 5 }}>
                      ({countData?.data?.count?.pending || "0"})
                    </span>
                  </MenuItem>
                  <MenuItem value={3}>
                    Rejected{" "}
                    <span style={{ fontSize: 10, marginLeft: 5 }}>
                      ({countData?.data?.count?.rejected || "0"})
                    </span>
                  </MenuItem>
                  {/* <MenuItem value={4}>
                    Required{" "}
                    <span style={{ fontSize: 10, marginLeft: 5 }}>
                      ({countData?.data?.count?.totalCount || "0"})
                    </span>
                  </MenuItem> */}
                </Select>
              </FormControl>
            </Tooltip>
          </Box>
          <Box display="flex">
            <Hidden mdDown>
              <Box>
                <Search
                  handleSearch={(value) => {
                    setSearch(value);
                  }}
                  persist
                  enableClear
                />
              </Box>
            </Hidden>
          </Box>
        </Box>
        <Table
          loading={kycLoading}
          rows={kycData?.data?.documents || []}
          rowCount={kycData?.data?.count}
          serverSidePagination
          activePage={page}
          activePageSize={pageSize}
          onPageChange={(value) => setPage(value)}
          onPageSizeChange={(value) => setPageSize(value)}
          columns={[
            {
              key: "name",
              label: "Name",
              Render: (row) => {
                let { firstName, lastName } = row;
                return `${
                  firstName === null
                    ? ""
                    : firstName.charAt(0).toUpperCase() + firstName.slice(1)
                } ${
                  lastName === null
                    ? ""
                    : lastName.charAt(0).toUpperCase() + lastName.slice(1)
                }`;
              },
            },
            {
              key: "email",
              label: "Email",
              Render: (row) => {
                let { email } = row;
                return `${
                  /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)
                    ? email.toLowerCase()
                    : ""
                }`;
              },
            },
            {
              key: "phone",
              label: "Phone",
              Render: (row) => {
                return (
                  <>
                    {row.phone ? (
                      <Box>
                        {row.phone[0] === `+`
                          ? row.phone.slice(0, 3) + " - " + row.phone.slice(3)
                          : "+91 - " + row.phone}
                      </Box>
                    ) : (
                      <Box>Not Found</Box>
                    )}
                  </>
                );
              },
            },
            {
              key: "kyc.status",
              label: "Status",
              Render: ({ status }: any) => (
                <Avatar
                  variant="status"
                  className={
                    status === "APPROVED"
                      ? "green"
                      : status === "PENDING"
                      ? "yellow"
                      : status === "REJECTED"
                      ? "red"
                      : status === "REQUIRED"
                      ? "grey"
                      : "blue"
                  }
                >
                  {titleCase(status ? status.split("_").join(" ") : "")}
                </Avatar>
              ),
            },

            {
              key: "actions",
              label: "Actions",
              Render: (row) => (
                <Button
                  sx={{ mt: 1.25, mb: 1.25 }}
                  variant="action"
                  size="small"
                  onClick={() => {
                    drawer.open(
                      <DrawerContent
                        user={row}
                        refetchKYC={refetchKYC}
                        refetchCount={refetchCount}
                      />
                    );
                  }}
                >
                  View
                </Button>
              ),
            },
          ]}
        />
      </Paper>
    </>
  );
};

export default KYC;
