import { DeleteOutline, Download, History } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import Table from "components/Table";
import { saveAs } from "file-saver";
import moment from "moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { appBar, drawer, GlobalState } from "utils";
import AppBar from "./AppBar";
import Drawer from "./Drawer";

const Reports = () => {
  const {
    vehicleReports,
    reports,
    activeSection,
    drawer: drawerState,
  } = useSelector((state: GlobalState) => state.global);

  let isCMS = activeSection === "charger";

  useEffect(() => {
    drawer.open(<Drawer />);
    return () => {
      appBar.close();
      drawer.close();
    };
  }, []);

  useEffect(() => {
    appBar.open(
      <AppBar
        isDrawerOpen={drawerState.open}
        onClick={() => {
          if (drawerState.open) drawer.close();
          else drawer.open(<Drawer />);
        }}
      />
    );
  }, [drawerState.open]);

  return (
    <Box
      sx={{
        px: { xs: 2, md: 7 },
        pt: { xs: 2, md: 4.75 },
        pb: { xs: 3, md: 7 },
      }}
    >
      <Paper
        sx={{
          pt: 2,
          px: 2,
          width: 1,
          boxShadow: (theme) => theme.customShadows.card,
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            mb: 2,
            display: "flex",
            alignItems: "center",
            // color: theme => theme.palette.text.disabled
          }}
        >
          <History sx={{ mr: 1 }} />
          <Typography variant="h6" fontWeight={500}>
            Previously Downloaded Reports
          </Typography>
        </Box>
        <Table
          px={0}
          rows={isCMS ? reports || [] : vehicleReports || []}
          columns={[
            {
              key: "name",
              label: "Name",
              Render: (row) => (
                <Tooltip title={row.name}>
                  <Typography variant="body2" maxWidth={360} noWrap>
                    {row.name}
                  </Typography>
                </Tooltip>
              ),
            },
            {
              key: "createdAt",
              label: "Created At",
              format: (value) => moment(value).format("MMM DD, YYYY"),
            },
            {
              key: "actions",
              label: "Actions",
              Render: (row) => (
                <Tooltip title="Download Again">
                  <IconButton
                    size="small"
                    sx={{ color: (theme) => theme.customColors.grey }}
                    onClick={() => {
                      saveAs(row.url, row.name);
                    }}
                    children={<Download fontSize="small" />}
                  />
                </Tooltip>
              ),
            },
          ]}
          toolbar={() => (
            <>
              <Button startIcon={<DeleteOutline />}>Delete</Button>
            </>
          )}
        />
      </Paper>
    </Box>
  );
};

export default Reports;
