import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { queryClient } from "index";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { RETAIL_URL } from "utils/constants";
import RecallVehicleIcon from "assets/images/icons/recall.svg";
import { HighlightOff } from "@mui/icons-material";

const BulkRecallDialog = ({ open, handleClose, bulk, totalItems }: any) => {
  function handleToggle() {
    setLoader(true);
    let url = `${RETAIL_URL}/assembly/component/bulkMark/status`;
    authorizedFetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        fileKeyId: bulk._id,
        statusMark: "UNASSIGNED",
      },
    })
      .then((res) => {
        setLoader(false);
        if (res.meta.success === true) {
          snackbar.success("Selected models unassigned");
          queryClient.resetQueries("getUploadHistoryData");
          handleClose();
        } else {
          console.error(res);
          snackbar.error("Error unassigning device(s)");
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
        snackbar.error("Error unassigning device(s)");
      });
  }
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <div style={{ flexGrow: 1 }}></div>
          <IconButton
            children={<HighlightOff />}
            color="inherit"
            onClick={handleClose}
            sx={{ transform: "translate(8px, -8px)" }}
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={RecallVehicleIcon}
              alt="recall icon"
              style={{ width: 80 }}
            />
          </div>
          <br />
          Are you sure you want to recall selected component
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          onClick={() => {
            handleToggle();
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BulkRecallDialog;
