import { AddRounded } from "@mui/icons-material";
import { Box, Button, Hidden, Typography } from "@mui/material";
import { getPermissions } from "utils";
import LocationSelector from "../Overview/AppBar/LocationSelector";

const AppBar = ({ locations, setLocations, onAddVendorClick }: any) => {
  const { canWrite } = getPermissions("charger:vendors");

  return (
    <Box
      sx={{
        width: 1,
        py: 3,
        px: { xs: 2, md: 7 },
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="h2">Vendors</Typography>
      {canWrite && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "flex-end",
          }}
        >
          <LocationSelector locations={locations} setLocations={setLocations} />
          <Hidden mdDown>
            <Button
              sx={{
                // mr: 3,
                // width: { md: 300 },
                ml: 3,
                borderRadius: 10,
                borderWidth: "1.5px !important",
                borderColor: (theme) => theme.palette.primary.main,
                textTransform: "none",
                fontWeight: 500,
              }}
              variant="outlined"
              startIcon={<AddRounded />}
              onClick={onAddVendorClick}
            >
              Add Vendor
            </Button>
          </Hidden>
          <Hidden mdUp>
            <Button
              sx={{
                ml: 2,
                px: 0.5,
                minWidth: 0,
              }}
              variant="outlined"
              onClick={onAddVendorClick}
            >
              <AddRounded />
            </Button>
          </Hidden>
          {/* <RangeSelector setRange={setRange} /> */}
        </Box>
      )}
    </Box>
  );
};

export default AppBar;
