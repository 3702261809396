import { InfoOutlined, SellOutlined } from "@mui/icons-material";
import { Box, IconButton, Paper, Tooltip } from "@mui/material";
import Breadcrumbs from "components/Breadcrumbs";
import TableComponent from "components/Table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
// import { useSelector } from "react-redux";
import { authorizedFetch, drawer } from "utils";
import { RETAIL_URL } from "utils/constants";
import SellDialog from "../Assembly/components/SellDialog";
import Search from "components/Search";
import VehicleDrawer from "../Assembly/components/VehicleDrawer";

const AssignedVehicles = () => {
  // const { company } = useSelector((state: GlobalState) => state.global);

  const [sellDialog, setSellDialog] = useState({ open: false, vehicle: null });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");

  const first = pageSize;
  const skip = pageSize * (page - 1);

  const url = `${RETAIL_URL}/distribution/vehicles-assigned?first=${first}&skip=${skip}&search=${search}`;

  const { isLoading, data } = useQuery(
    ["getAssignedVehicles", first, skip, search],
    () => authorizedFetch(url)
  );

  let rowCount = data?.meta?.totalCount || 0;
  let rows = data?.data?.constructor === Array ? data.data : [];

  useEffect(() => {
    return () => {
      drawer.close();
    };
  }, []);

  return (
    <Box>
      <SellDialog
        open={sellDialog.open}
        handleClose={() => setSellDialog((prev) => ({ ...prev, open: false }))}
        vehicle={sellDialog.vehicle}
      />
      <Box mt={0.5} mb={3}>
        <Breadcrumbs />
      </Box>
      <Paper
        sx={{
          width: 1,
          pt: 3,
          boxShadow: (theme) => theme.customShadows.small,
          borderRadius: 2,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            mb: 2,
            px: 3,
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
          }}
        >
          <Search
            handleSearch={(input) => setSearch(input)}
            persist
            enableClear
          />
        </Box>
        <TableComponent
          loading={isLoading}
          rowCount={rowCount}
          serverSidePagination={true}
          activePage={page}
          activePageSize={pageSize}
          onPageChange={(value) => setPage(value)}
          onPageSizeChange={(value) => setPageSize(value)}
          rows={rows}
          columns={[
            { key: "vin", label: "VIN" },
            {
              key: "createdAt",
              label: "Created At",
              format: (value) => moment(value).format("MMM DD, YYYY hh:mm a"),
            },
            {
              key: "actions",
              label: "Actions",
              Render: (row) => (
                <Box display="flex">
                  <Tooltip title="Info" followCursor>
                    <IconButton
                      size="small"
                      sx={{
                        mr: 0.5,
                        color: (theme) => theme.customColors.action,
                      }}
                      children={<InfoOutlined fontSize="small" />}
                      onClick={() =>
                        drawer.open(<VehicleDrawer vehicle={row} />)
                      }
                    />
                  </Tooltip>
                  <Tooltip title="Sell" followCursor>
                    <IconButton
                      size="small"
                      sx={{ color: (theme) => theme.customColors.action }}
                      children={<SellOutlined fontSize="small" />}
                      onClick={() =>
                        setSellDialog({ open: true, vehicle: row })
                      }
                    />
                  </Tooltip>
                </Box>
              ),
            },
          ]}
        />
      </Paper>
    </Box>
  );
};

export default AssignedVehicles;
