import { EditOutlined, ErrorOutline, HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  Step,
  StepButton,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { queryClient } from "index";
import { useEffect, useRef, useState } from "react";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { AUTH_URL, RETAIL_URL } from "utils/constants";
import { ReactComponent as UploadIcon } from "assets/images/upload.svg";
import { ReactComponent as FileUploadedIcon } from "assets/images/file-uploaded.svg";

const AddFileOTADialog = ({ open, handleClose }: any) => {
  const [input, setInput] = useState({
    company: "",
    vehicleModel: "",
    type: "",
    primaryPrerequisite: "",
    secondaryPrerequisite: "",
    primaryMinor: 0,
    primaryMajor: 0,
    primaryPatch: 0,
    secondaryMinor: 0,
    secondaryMajor: 0,
    secondaryPatch: 0,
    json: "",
    speedometerType: "",
  });
  const steps = ["Basic Details", "Firmware Details", "File Upload", "Review"];
  const [step, setStep] = useState(0);
  const [vehicleModels, setVehicleModels] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [jsonVersions, setJsonVersions] = useState([]);
  const [primaryPrerequisites, setPrimaryPrerequisites] = useState([]);
  const [secondaryPrerequisites, setSecondaryPrerequisites] = useState([]);
  const [checkboxEnabled, setCheckboxEnabled] = useState(false);

  const fileInputRefs: any = {
    primary: useRef(null),
    secondary: useRef(null),
    batteryAdcLog: useRef(null),
    log: useRef(null),
    read: useRef(null),
    vehicleControl: useRef(null),
    write: useRef(null),
  };

  const [files, setFiles] = useState<
    Record<string, File> | Record<string, null>
  >({
    primary: null,
    secondary: null,
    batteryAdcLog: null,
    log: null,
    read: null,
    vehicleControl: null,
    write: null,
  });

  console.log(files);

  const isPrimaryMainFileValid = () => {
    if (!files.primary) return false;
    const fileType = files.primary.name.split(".").pop()?.toLowerCase();

    if (input.type === "PRIMARY" && fileType !== "bin") return false;
    // if (input.type === "SECONDARY" && fileType !== "pkg") return false;
    if (input.type === "BOTH" && fileType !== "bin" && fileType !== "pkg")
      return false;

    return true;
  };

  const isSecondaryMainFileValid = () => {
    if (!files.secondary) return false;
    const fileType = files.secondary.name.split(".").pop()?.toLowerCase();

    // if (input.type === "PRIMARY" && fileType !== "bin") return false;
    if (input.type === "SECONDARY" && fileType !== "pkg") return false;
    if (input.type === "BOTH" && fileType !== "bin" && fileType !== "pkg")
      return false;

    return true;
  };

  const handleFileChange = (type: string, event: any) => {
    setFiles((prevState) => ({
      ...prevState,
      [type]: event.target.files[0],
    }));
  };

  const handleSave = () => {
    const formData = new FormData();

    console.log(formData, "75");

    for (const jsonType in files) {
      const file = files[jsonType as keyof typeof files];
      if (file) {
        const fileType = file.name.split(".").pop()?.toLowerCase();
        console.log(fileType, jsonType, "fileType");
        if (fileType === "json") formData.append(jsonType, file);
      }
    }

    console.log(files);

    console.log(formData, "86");

    if (isPrimaryMainFileValid() && files.primary) {
      formData.append("primary", files.primary);
    }

    if (isSecondaryMainFileValid() && files.secondary) {
      formData.append("secondary", files.secondary);
    }

    console.log(formData, "95");

    let requestBody: any = {};

    if (input.type === "PRIMARY" || input.type === "BOTH") {
      requestBody = {
        ...requestBody,
        "primary[major]": input.primaryMajor,
        "primary[minor]": input.primaryMinor,
        "primary[patch]": input.primaryPatch,
        company: input.company,
        vehicleModel: input.vehicleModel,
        json: input.json,
      };

      if (input.primaryPrerequisite?.length > 0) {
        requestBody = {
          ...requestBody,
          "primary[preRequisite]": input.primaryPrerequisite,
        };
      }
    }

    if (input.type === "SECONDARY" || input.type === "BOTH") {
      requestBody = {
        ...requestBody,
        "secondary[major]": input.secondaryMajor,
        "secondary[minor]": input.secondaryMinor,
        "secondary[patch]": input.secondaryPatch,
        company: input.company,
        vehicleModel: input.vehicleModel,
        json: input.json,
      };
      if (input.secondaryPrerequisite?.length > 0) {
        requestBody = {
          ...requestBody,
          "secondary[preRequisite]": input.secondaryPrerequisite,
        };
      }
    }

    for (const key in requestBody) {
      formData.append(key, requestBody[key]);
    }

    if (input.type === "BOTH") {
      if (!(files.primary && files.secondary)) {
        snackbar.error("Both files need to be upload");
      }
    }

    authorizedFetch(`${RETAIL_URL}/inventory/ota/upload`, {
      method: "POST",
      onlyBody: formData,
    })
      .then((res) => {
        setLoader(false);
        if (res.msg === "OTA upload successful") {
          snackbar.success(res.msg);
          queryClient.resetQueries("getOtaData");
          handleClose();
        } else {
          console.error(res);
          snackbar.error(res.msg);
        }
        handleClose();
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
        snackbar.error("Error");
      });
    console.log(requestBody, "final state");
  };

  useEffect(() => {
    if (!open) {
      setInput({
        company: "",
        vehicleModel: "",
        type: "",
        primaryPrerequisite: "",
        secondaryPrerequisite: "",
        primaryMinor: 0,
        primaryMajor: 0,
        primaryPatch: 0,
        secondaryMinor: 0,
        secondaryMajor: 0,
        secondaryPatch: 0,
        json: "",
        speedometerType: "",
      });
      setFiles({
        primary: null,
        secondary: null,
        batteryAdcLog: null,
        log: null,
        read: null,
        vehicleControl: null,
        write: null,
      });
      setCheckboxEnabled(false);
      setStep(0);
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      authorizedFetch(`${AUTH_URL}/company/list`)
        .then((res) => setCompanies(res?.data))
        .catch((err) => console.error(err));
    }
  }, [open]);

  useEffect(() => {
    if (input.company) {
      authorizedFetch(
        `${RETAIL_URL}/inventory/ota/vehicle-models?companyId=${input.company}`
      )
        .then((res) => setVehicleModels(res?.data))
        .catch((err) => console.error(err));
    }
  }, [input.company]);

  useEffect(() => {
    if (input.company && input.vehicleModel) {
      authorizedFetch(
        `${RETAIL_URL}/inventory/ota/json-versions?companyId=${input.company}&vehicleModel=${input.vehicleModel}`
      )
        .then((res) => setJsonVersions(res?.data))
        .catch((err) => console.error(err));
    }
  }, [input.company, input.vehicleModel]);

  const getPrimaryPreRequisite = () => {
    authorizedFetch(
      `${RETAIL_URL}/inventory/ota/prerequisites?companyId=${input.company}&vehicleModel=${input.vehicleModel}&type=PRIMARY`
    )
      .then((res) => setPrimaryPrerequisites(res?.data))
      .catch((err) => console.error(err));
  };

  const getSecondaryPreRequisite = () => {
    authorizedFetch(
      `${RETAIL_URL}/inventory/ota/prerequisites?companyId=${input.company}&vehicleModel=${input.vehicleModel}&type=SECONDARY`
    )
      .then((res) => setSecondaryPrerequisites(res?.data))
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (input.company && input.vehicleModel && input.type) {
      if (input.type === "PRIMARY") {
        getPrimaryPreRequisite();
      } else if (input.type === "SECONDARY") {
        getSecondaryPreRequisite();
      } else if (input.type === "BOTH") {
        getPrimaryPreRequisite();
        getSecondaryPreRequisite();
      }
    }
    // eslint-disable-next-line
  }, [input.company, input.vehicleModel, input.type]);

  const validateVersion = (version: any) => {
    const re = /^\d+\.\d+$/;
    return re.test(version);
  };
  function handleNext() {
    if (step === steps.length - 1) {
      handleSave();
    } else setStep(step + 1);
  }
  function handleBack() {
    setStep(step - 1);
  }

  const primaryCheck =
    input.type === "PRIMARY" || input.type === "BOTH"
      ? input.primaryMajor !== 0
      : true;

  const secondaryCheck =
    input.type === "SECONDARY" || input.type === "BOTH"
      ? input.secondaryMajor !== 0
      : true;

  const primaryFileCheck =
    input.type === "PRIMARY" || input.type === "BOTH"
      ? files.primary !== null
      : true;

  const secondaryFileCheck =
    input.type === "SECONDARY" || input.type === "BOTH"
      ? files.secondary !== null
      : true;

  const JSONFileCheck = checkboxEnabled
    ? files.batteryAdcLog !== null &&
      files.log !== null &&
      files.read !== null &&
      files.vehicleControl !== null &&
      files.write !== null
    : true;

  function isComplete(step: number) {
    switch (step) {
      case 0:
        return (
          ![input.company, input.vehicleModel, input.type].includes("") &&
          input.speedometerType !== undefined
        );
      case 1:
        return primaryCheck && secondaryCheck && input.json !== "";
      case 2:
        return primaryFileCheck && secondaryFileCheck && JSONFileCheck;
      default:
        break;
    }
  }

  const disabled =
    step === 0
      ? [input.company, input.vehicleModel, input.type].includes("") ||
        input.speedometerType === undefined
      : step === 1
      ? !primaryCheck || !secondaryCheck || input.json === ""
      : step === 2
      ? !primaryFileCheck || !secondaryFileCheck || !JSONFileCheck
      : false;

  function convertToTitleCase(inputString: any) {
    const words = inputString.split(/(?=[A-Z])/);

    const titleCaseString = words
      .map((word: any) => {
        const capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
        return capitalizedWord;
      })
      .join(" ");

    return titleCaseString;
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          Files
        </Box>
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent>
        <Stepper
          sx={{ my: 4, mx: "auto", maxWidth: 534 }}
          activeStep={step}
          nonLinear
          alternativeLabel
        >
          {steps.map((label, i) => (
            <Step key={i}>
              <StepButton
                disabled
                // onClick={() => setStep(i)}
              >
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>

        <Box
          sx={{
            maxWidth: { xs: 280, sm: 660 },
            mx: "auto",
            py: 2,
            display: "grid",
            gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
            gap: 5,
            "& .required": {
              color: "red",
            },
          }}
        >
          {step === 0 && (
            <>
              <Box>
                <Typography>
                  Company Name <span className="required">&#x2a;</span>
                </Typography>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <Select
                    sx={{ borderRadius: 10 }}
                    value={input.company}
                    onChange={(e: any) => {
                      console.log("input", input);
                      setInput({ ...input, company: e.target.value });
                    }}
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      <em>Select your company name</em>
                    </MenuItem>
                    {companies.constructor === Array &&
                      companies?.length !== 0 &&
                      companies?.map((model: any) => (
                        <MenuItem key={model._id} value={model._id}>
                          {model.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <Typography>
                  Vehicle Model <span className="required">&#x2a;</span>
                </Typography>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <Select
                    sx={{ borderRadius: 10 }}
                    disabled={input.company?.length === 0}
                    value={input.vehicleModel}
                    onChange={(e: any) => {
                      const modelObject: any = vehicleModels.filter(
                        (model: any) => model._id === e.target.value
                      )?.[0];
                      const components = modelObject?.components;
                      const filteredCluster = components.filter(
                        (el: any) => el.category === "CONTROLLER"
                      )?.[0];
                      const clusterKey = filteredCluster?.modelId?.[0]?.key;
                      setInput({
                        ...input,
                        speedometerType: clusterKey,
                        vehicleModel: e.target.value,
                      });
                    }}
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      <em>Select your vehicle model</em>
                    </MenuItem>
                    {vehicleModels?.map((model: any) => (
                      <MenuItem key={model._id} value={model._id}>
                        {model.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <Typography>
                  Speedometer Type <span className="required">&#x2a;</span>
                </Typography>

                <TextField
                  fullWidth
                  InputProps={{ sx: { borderRadius: 10, mt: 1 } }}
                  size="medium"
                  placeholder="Will be autofilled"
                  value={
                    input.speedometerType
                      ? input.speedometerType
                      : "No Speedometer found"
                  }
                  disabled
                />
              </Box>
              <Box>
                <Typography>
                  Update Type <span className="required">&#x2a;</span>
                </Typography>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <Select
                    sx={{ borderRadius: 10 }}
                    disabled={
                      input.company?.length === 0 ||
                      input.vehicleModel?.length === 0
                    }
                    value={input.type}
                    onChange={(e) =>
                      setInput({ ...input, type: e.target.value })
                    }
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      <em>Select your update type</em>
                    </MenuItem>
                    <MenuItem value="PRIMARY">PRIMARY</MenuItem>
                    {input.speedometerType !== "SLED" && (
                      <MenuItem value="SECONDARY">SECONDARY</MenuItem>
                    )}
                    {input.speedometerType !== "SLED" && (
                      <MenuItem value="BOTH">BOTH</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Box>
            </>
          )}

          {step === 1 && (
            <>
              {(input.type === "PRIMARY" || input.type === "BOTH") && (
                <>
                  <Box>
                    <Typography>
                      Firmware Version (Primary)
                      <span className="required">&#x2a;</span>
                    </Typography>
                    <Box sx={{ display: "flex", gap: 3, mt: 1 }}>
                      <TextField
                        type="tel"
                        value={input.primaryMajor || 0}
                        InputProps={{
                          sx: { borderRadius: 10 },
                        }}
                        inputProps={{ style: { textAlign: "center" } }}
                        onChange={(e) => {
                          if (e.target.value.length <= 2) {
                            setInput({
                              ...input,
                              primaryMajor: parseInt(e.target.value),
                            });
                          }
                        }}
                      />
                      <TextField
                        // label="Minor"
                        type="tel"
                        value={input.primaryMinor || 0}
                        onChange={(e) => {
                          if (e.target.value.length <= 2) {
                            setInput({
                              ...input,
                              primaryMinor: parseInt(e.target.value),
                            });
                          }
                        }}
                        InputProps={{
                          sx: { borderRadius: 10 },
                        }}
                        inputProps={{ style: { textAlign: "center" } }}
                      />

                      <TextField
                        // label="Patch"
                        type="tel"
                        value={input.primaryPatch || 0}
                        onChange={(e) => {
                          if (e.target.value.length <= 2) {
                            setInput({
                              ...input,
                              primaryPatch: parseInt(e.target.value),
                            });
                          }
                        }}
                        InputProps={{
                          sx: { borderRadius: 10 },
                        }}
                        inputProps={{ style: { textAlign: "center" } }}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Typography>Previous F/W Requirements (Primary)</Typography>
                    <Select
                      sx={{ borderRadius: 10, mt: 1 }}
                      value={input.primaryPrerequisite}
                      onChange={(e: any) =>
                        setInput({
                          ...input,
                          primaryPrerequisite: e.target.value,
                        })
                      }
                      displayEmpty
                    >
                      <MenuItem disabled value="">
                        <em>Select your primary prerequisites</em>
                      </MenuItem>
                      {primaryPrerequisites?.map((model: any) => (
                        <MenuItem key={model._id} value={model._id}>
                          {model.version}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </>
              )}
              {(input.type === "SECONDARY" || input.type === "BOTH") && (
                <>
                  <Box>
                    <Typography>
                      Firmware Version (Secondary)
                      <span className="required">&#x2a;</span>
                    </Typography>
                    <Box sx={{ display: "flex", gap: 3, mt: 1 }}>
                      <TextField
                        type="tel"
                        value={input.secondaryMajor || 0}
                        InputProps={{
                          sx: { borderRadius: 10 },
                        }}
                        inputProps={{ style: { textAlign: "center" } }}
                        onChange={(e) => {
                          if (e.target.value.length <= 2) {
                            setInput({
                              ...input,
                              secondaryMajor: parseInt(e.target.value),
                            });
                          }
                        }}
                      />
                      <TextField
                        // label="Minor"
                        type="tel"
                        value={input.secondaryMinor || 0}
                        onChange={(e) => {
                          if (e.target.value.length <= 2) {
                            setInput({
                              ...input,
                              secondaryMinor: parseInt(e.target.value),
                            });
                          }
                        }}
                        InputProps={{
                          sx: { borderRadius: 10 },
                        }}
                        inputProps={{ style: { textAlign: "center" } }}
                      />

                      <TextField
                        // label="Patch"
                        type="tel"
                        value={input.secondaryPatch || 0}
                        onChange={(e) => {
                          if (e.target.value.length <= 2) {
                            setInput({
                              ...input,
                              secondaryPatch: parseInt(e.target.value),
                            });
                          }
                        }}
                        InputProps={{
                          sx: { borderRadius: 10 },
                        }}
                        inputProps={{ style: { textAlign: "center" } }}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Typography>
                      Previous F/W Requirements (Secondary)
                    </Typography>
                    <Select
                      sx={{ borderRadius: 10, mt: 1 }}
                      value={input.secondaryPrerequisite}
                      onChange={(e: any) =>
                        setInput({
                          ...input,
                          secondaryPrerequisite: e.target.value,
                        })
                      }
                      displayEmpty
                    >
                      <MenuItem disabled value="">
                        <em>Select your primary prerequisites</em>
                      </MenuItem>
                      {secondaryPrerequisites?.map((model: any) => (
                        <MenuItem key={model._id} value={model._id}>
                          {model.version}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </>
              )}

              <Box>
                <Typography>
                  JSON Version
                  <span className="required">&#x2a;</span>
                </Typography>

                <Select
                  sx={{ borderRadius: 10, mt: 1, width: "100%" }}
                  disabled={
                    checkboxEnabled ||
                    input.company?.length === 0 ||
                    input.vehicleModel?.length === 0
                  }
                  value={checkboxEnabled ? "" : input.json}
                  onChange={(e: any) =>
                    setInput({ ...input, json: e.target.value })
                  }
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    <em>Select JSON Version</em>
                  </MenuItem>
                  {jsonVersions?.map((model: any) => (
                    <MenuItem key={model} value={model}>
                      {model}
                    </MenuItem>
                  ))}
                </Select>
              </Box>

              <Box sx={{ mt: -1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={checkboxEnabled}
                      onChange={(e: any) =>
                        setCheckboxEnabled(e.target.checked)
                      }
                    />
                  }
                  label="Update Required"
                />

                <TextField
                  fullWidth
                  label="Updated JSON Version"
                  value={!checkboxEnabled ? "" : input.json}
                  error={!!input.json && !validateVersion(input.json)}
                  onChange={(e: any) => {
                    if (
                      /^[0-9.]{0,3}$/.test(e.target.value) ||
                      e.target.value.length < input.json.length
                    )
                      setInput({ ...input, json: e.target.value });
                  }}
                  disabled={!checkboxEnabled}
                  sx={{ mt: -0.5 }}
                  InputProps={{
                    sx: { borderRadius: 10 },
                  }}
                />
              </Box>
            </>
          )}

          {step === 2 && (
            <>
              {(input.type === "PRIMARY" || input.type === "BOTH") && (
                <Box
                  sx={{
                    gridColumn: { sm: input.type !== "BOTH" ? "span 2" : "" },
                  }}
                >
                  <Typography>
                    Primary OTA File
                    <span className="required">&#x2a;</span>
                  </Typography>
                  {files?.primary === null ? (
                    <FormControl
                      sx={{
                        margin: "20px 0px",
                        width: input.type === "BOTH" ? "100%" : "50%",
                      }}
                    >
                      <Button
                        variant="outlined"
                        component="label"
                        sx={{ gap: 2, height: 60, borderRadius: 10 }}
                      >
                        <UploadIcon />
                        Upload
                        <input
                          type="file"
                          hidden
                          ref={fileInputRefs.primary}
                          onChange={(e) => handleFileChange("primary", e)}
                          // accept={input.type === 'PRIMARY' ? ".bin" : input.type === 'SECONDARY' ? ".pkg" : ".bin, .pkg"}
                        />
                      </Button>
                    </FormControl>
                  ) : (
                    <>
                      {files["primary"] && (
                        <Box
                          display="flex"
                          mt={1}
                          sx={{ alignItems: "center" }}
                        >
                          <FileUploadedIcon />
                          <Typography
                            sx={{
                              fontSize: 12,
                              color: "#366AB4",
                              width: "50%",
                              wordWrap: "break-word",
                              ml: 1,
                            }}
                          >
                            {files["primary"]?.name}
                          </Typography>
                          <Button
                            variant="text"
                            sx={{ color: "#EB5C5C" }}
                            onClick={(e) =>
                              setFiles((prevState) => ({
                                ...prevState,
                                primary: null,
                              }))
                            }
                          >
                            Remove File
                          </Button>
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              )}
              {(input.type === "SECONDARY" || input.type === "BOTH") && (
                <Box
                  sx={{
                    gridColumn: { sm: input.type !== "BOTH" ? "span 2" : "" },
                  }}
                >
                  <Typography>
                    Secondary OTA File
                    <span className="required">&#x2a;</span>
                  </Typography>
                  {files?.secondary === null ? (
                    <FormControl
                      sx={{
                        margin: "20px 0px",
                        width: input.type === "BOTH" ? "100%" : "50%",
                      }}
                    >
                      <Button
                        variant="outlined"
                        component="label"
                        sx={{ gap: 2, height: 60, borderRadius: 10 }}
                      >
                        <UploadIcon />
                        Upload
                        <input
                          type="file"
                          hidden
                          ref={fileInputRefs.secondary}
                          onChange={(e) => handleFileChange("secondary", e)}
                          // accept={input.type === 'PRIMARY' ? ".bin" : input.type === 'SECONDARY' ? ".pkg" : ".bin, .pkg"}
                        />
                      </Button>
                    </FormControl>
                  ) : (
                    <>
                      {files["secondary"] && (
                        <Box
                          display="flex"
                          mt={1}
                          sx={{ alignItems: "center" }}
                        >
                          <FileUploadedIcon />
                          <Typography
                            sx={{
                              fontSize: 12,
                              color: "#366AB4",
                              width: input.type === "BOTH" ? "50%" : "auto",
                              wordWrap: "break-word",
                              ml: 1,
                            }}
                          >
                            {files["secondary"]?.name}
                          </Typography>
                          <Button
                            variant="text"
                            sx={{ color: "#EB5C5C", ml: 1 }}
                            onClick={(e) =>
                              setFiles((prevState) => ({
                                ...prevState,
                                secondary: null,
                              }))
                            }
                          >
                            Remove File
                          </Button>
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              )}
              {checkboxEnabled && (
                <>
                  <Box
                    sx={{
                      gridColumn: { sm: "span 2" },
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      JSON (Update Required)
                    </Typography>
                  </Box>
                  {[
                    "batteryAdcLog",
                    "log",
                    "read",
                    "vehicleControl",
                    "write",
                  ].map((type, index) => (
                    <Box key={index}>
                      <Typography>
                        Upload {convertToTitleCase(type)}
                        <span className="required">&#x2a;</span>
                      </Typography>
                      {files[type] === null ? (
                        <FormControl sx={{ margin: "20px 0px", width: "100%" }}>
                          <Button
                            variant="outlined"
                            component="label"
                            sx={{ gap: 2, height: 60, borderRadius: 10 }}
                          >
                            <UploadIcon />
                            Upload
                            <input
                              type="file"
                              hidden
                              ref={fileInputRefs[type]}
                              onChange={(e) => handleFileChange(type, e)}
                              // accept={input.type === 'PRIMARY' ? ".bin" : input.type === 'SECONDARY' ? ".pkg" : ".bin, .pkg"}
                            />
                          </Button>
                        </FormControl>
                      ) : (
                        <>
                          {files[type] && (
                            <Box
                              display="flex"
                              mt={1}
                              sx={{ alignItems: "center" }}
                            >
                              <FileUploadedIcon />
                              <Typography
                                sx={{
                                  fontSize: 12,
                                  color: "#366AB4",
                                  width: "50%",
                                  wordWrap: "break-word",
                                  ml: 1,
                                }}
                              >
                                {files[type]?.name}
                              </Typography>
                              <Button
                                variant="text"
                                sx={{ color: "#EB5C5C" }}
                                onClick={(e) =>
                                  setFiles((prevState) => {
                                    const updatedFiles = { ...prevState };
                                    updatedFiles[type] = null;
                                    return updatedFiles;
                                  })
                                }
                              >
                                Remove File
                              </Button>
                            </Box>
                          )}
                        </>
                      )}
                    </Box>
                  ))}
                </>
              )}
            </>
          )}

          {step === 3 && (
            <Box
              sx={{
                width: 650,
                mx: "auto",
                "& .table": {
                  borderCollapse: "collapse",
                  width: 1,
                  fontSize: 14,
                  lineHeight: "16px",
                  "& td": {
                    py: 1.25,
                    px: 2,
                  },
                  "& .bold": {
                    fontWeight: 500,
                  },
                  "& .header": {
                    px: 2,
                    py: 1,
                    position: "relative",
                    "& td": {
                      position: "absolute",
                      verticalAlign: "middle",
                      bgcolor: (theme) => theme.customColors.header,
                      width: 1,
                      borderRadius: "4px",
                      fontSize: 16,
                      fontWeight: 600,
                      "& span": {
                        display: "inline-block",
                        transform: "translateY(1px)",
                      },
                    },
                  },
                  "& .first > td": {
                    pt: 9,
                  },
                  "& .last > td": {
                    pb: 3,
                  },
                },
              }}
            >
              <table className="table">
                <tbody>
                  {[
                    { header: "Basic Details", onEdit: () => setStep(0) },
                    {
                      label: "Company Name",
                      value: companies
                        .filter((el: any) => el._id === input.company)
                        .map((el: any) => el.name)[0],
                      required: true,
                    },
                    {
                      label: "Vehicle Model",
                      value: vehicleModels
                        .filter((el: any) => el._id === input.vehicleModel)
                        .map((el: any) => el.name)[0],
                      required: true,
                    },
                    {
                      label: "Speedometer Type",
                      value: input.speedometerType,
                      required: true,
                    },
                    { label: "Update Type", value: input.type, required: true },
                    { header: "Firmware Details", onEdit: () => setStep(1) },
                    ...(input.type === "PRIMARY" || input.type === "BOTH"
                      ? [
                          {
                            label: "Primary Firmware Version",
                            value: `${input.primaryMajor}.${input.primaryMinor}.${input.primaryPatch}`,
                            required: true,
                          },
                        ]
                      : []),
                    ...(input.type === "PRIMARY" || input.type === "BOTH"
                      ? [
                          {
                            label: "Previous F/W Requirement (Primary)",
                            value: primaryPrerequisites
                              .filter(
                                (el: any) =>
                                  el._id === input.primaryPrerequisite
                              )
                              .map((el: any) => el.version)[0],
                          },
                        ]
                      : []),
                    ...(input.type === "SECONDARY" || input.type === "BOTH"
                      ? [
                          {
                            label: "Secondary Firmware Version",
                            value: `${input.secondaryMajor}.${input.secondaryMinor}.${input.secondaryPatch}`,
                            required: true,
                          },
                        ]
                      : []),
                    ...(input.type === "SECONDARY" || input.type === "BOTH"
                      ? [
                          {
                            label: "Previous F/W Requirement (Secondary)",
                            value: secondaryPrerequisites
                              .filter(
                                (el: any) =>
                                  el._id === input.secondaryPrerequisite
                              )
                              .map((item: any) => item.version)[0],
                          },
                        ]
                      : []),

                    {
                      label: `JSON Version ${
                        checkboxEnabled ? "Update Required" : ""
                      }`,
                      value: input.json,
                      required: true,
                    },

                    { header: "File Upload", onEdit: () => setStep(2) },

                    ...(input.type === "PRIMARY" || input.type === "BOTH"
                      ? [
                          {
                            label: "Primary OTA File",
                            value: files.primary?.name,
                            required: true,
                          },
                        ]
                      : []),
                    ...(input.type === "SECONDARY" || input.type === "BOTH"
                      ? [
                          {
                            label: "Secondary OTA File",
                            value: files.secondary?.name,
                            required: true,
                          },
                        ]
                      : []),
                    ...(checkboxEnabled
                      ? [
                          {
                            label: "BATTERYADCLOG",
                            value: files.batteryAdcLog?.name,
                            required: true,
                          },
                          {
                            label: "LOG",
                            value: files.log?.name,
                            required: true,
                          },
                          {
                            label: "READ",
                            value: files.read?.name,
                            required: true,
                          },
                          {
                            label: "VEHICLECONTROL",
                            value: files.vehicleControl?.name,
                            required: true,
                          },
                          {
                            label: "WRITE",
                            value: files.write?.name,
                            required: true,
                          },
                        ]
                      : []),
                  ].map(
                    ({ header, onEdit, label, value, required }, i, arr) => {
                      const isFirst = arr[i - 1]?.header;
                      const isLast = !arr[i + 1] || arr[i + 1].header;

                      return (
                        <tr
                          key={i}
                          className={
                            header
                              ? "header"
                              : `${isFirst ? "first" : ""} ${
                                  isLast ? "last" : ""
                                }`
                          }
                        >
                          {header ? (
                            <td colSpan={2}>
                              <span>{header}</span>
                              <IconButton
                                sx={{ ml: 1.5 }}
                                children={<EditOutlined />}
                                color="primary"
                                size="small"
                                onClick={onEdit}
                              />
                            </td>
                          ) : (
                            <>
                              <td>{label}</td>
                              <td className="bold">
                                {value ||
                                  (required && (
                                    <Box display="flex" alignItems="center">
                                      <ErrorOutline
                                        fontSize="small"
                                        color="error"
                                        style={{ marginRight: 8 }}
                                      />
                                      Required
                                    </Box>
                                  ))}
                              </td>
                            </>
                          )}
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {step !== 0 && (
          <Button
            variant="outlined"
            onClick={handleBack}
            sx={{ borderRadius: 10 }}
          >
            Back
          </Button>
        )}
        {step === 0 && (
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{ borderRadius: 10 }}
          >
            Cancel
          </Button>
        )}
        <Button
          sx={{ borderRadius: 10 }}
          onClick={handleNext}
          variant={
            isComplete(step) || step === steps.length - 1
              ? "contained"
              : "outlined"
          }
          disableElevation
          disabled={disabled}
        >
          {step === steps.length - 1 ? "Save" : "Next"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddFileOTADialog;
