import { Box, Paper, Skeleton, Typography, useTheme } from "@mui/material";
import InfoLabel from "components/InfoLabel";
import moment from "moment";
import { useMemo } from "react";
import { Line } from "react-chartjs-2";

const TotalBookings = ({ isLoading, data }: any) => {
  const theme = useTheme();

  const count = data?.data?.count || 0;

  const { chartData } = useMemo(() => {
    let chartData: any[] = [];

    if (data?.data?.aggregatedBookings?.constructor === Array) {
      chartData = data.data.aggregatedBookings
        .sort(
          (a: any, b: any) =>
            moment(a.date).valueOf() - moment(b.date).valueOf()
        )
        .map((el: any) => ({
          x: moment(el.date).format("MMM D"),
          y: el.totalBookings,
        }));
    }

    // let average = Math.round(
    //   chartData.reduce((acc, cur: any) => acc + cur.y, 0) / chartData.length
    // );

    return { chartData };
  }, [data]);

  return (
    <Paper
      sx={{
        py: { xs: 3, md: 4 },
        px: { xs: 2, md: 5 },
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          mb: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h3" mr={1.25}>
            Total Bookings
          </Typography>
          {isLoading ? (
            <>
              <Skeleton
                variant="rectangular"
                width={80}
                height={24}
                sx={{ borderRadius: 10, mr: 1.25 }}
              />
              <Skeleton
                variant="rectangular"
                width={80}
                height={24}
                sx={{ borderRadius: 10 }}
              />
            </>
          ) : (
            <>
              <InfoLabel
                dense
                label="Total"
                value={(count || 0).toLocaleString()}
                mr={1.25}
              />
              {/* <InfoLabel
                dense
                label="Daily Average"
                value={(average || 0).toLocaleString()}
              /> */}
            </>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          minHeight: 0,
        }}
      >
        <Line
          data={(canvas) => {
            let color = "#50BFEB";
            return {
              labels: chartData?.map((el: any) => el.x) || [],
              datasets: [
                {
                  fill: true,
                  data: chartData?.map((el: any) => el.y) || [],
                  borderColor: color,
                  backgroundColor: color + "0D",
                  tension: 0.4,
                  pointRadius: 0,
                  pointHoverRadius: 4,
                  pointHoverBackgroundColor: "#fff",
                  pointHoverBorderWidth: 3,
                },
              ],
            };
          }}
          options={{
            scales: {
              xAxis: {
                // type: 'time',
                grid: {
                  display: false,
                  tickWidth: 0,
                  tickLength: 16,
                  drawBorder: false,
                },
                ticks: {
                  color: theme.palette.text.secondary,
                },
              },
              yAxis: {
                title: {
                  display: true,
                  text: "Bookings",
                  padding: {
                    top: 0,
                    bottom: 8,
                  },
                },
                ticks: {
                  color: theme.palette.text.secondary,
                  precision: 0,
                },
                min: 0,
                // suggestedMin: 15,
                // suggestedMax: 30,
                grid: {
                  borderDash: [4],
                  tickWidth: 0,
                  tickLength: 16,
                  drawBorder: false,
                },
              },
            },
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                caretSize: 0,
                mode: "index",
                intersect: false,
                yAlign: "center",
                displayColors: false,
                caretPadding: 16,
                titleFont: {
                  weight: "400",
                },
                bodyFont: {
                  weight: "500",
                },
              },
            },
            interaction: {
              mode: "index",
              intersect: false,
            },
          }}
        />
      </Box>
    </Paper>
  );
};

export default TotalBookings;
