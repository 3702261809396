import { HighlightOff, KeyboardArrowDown } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import RedAsterisk from "components/RedAsterisk";
import { queryClient } from "index";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { RETAIL_URL } from "utils/constants";
import validator from "validator";
import PhoneTextField from "components/PhoneTextField";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import countryNames from "react-phone-number-input/locale/en.json";
import { postcodeValidator } from "postcode-validator";

type CountryCode = keyof typeof countryNames;

const formObj: any = {
  firstName: "",
  lastName: "",
  phone: "",
  // address: "",
  address: {
    local: "",
    district: null,
    state: null,
    pincode: "",
  },
  email: "",
};

const validationsObj = {
  phone: false,
  email: false,
  pincode: false,
};

const SellDialog = ({ open, handleClose, vehicle, onSave }: any) => {
  const [form, setForm] = useState({ ...formObj });
  const [validations, setValidations] = useState({ ...validationsObj });

  const [selectedCountry, setSelectedCountry] = useState<CountryCode>("IN");

  const statesUrl = `https://geocode.revos.in/place/states?country=${countryNames[selectedCountry]}&input=`;
  const { isLoading: statesLoading, data: statesData } = useQuery(
    ["getStates", selectedCountry],
    () => authorizedFetch(statesUrl)
  );

  const citiesUrl = `https://geocode.revos.in/place/cities?country=${countryNames[selectedCountry]}&state=${form.address.state}&input=`;
  const { isLoading: citiesLoading, data: citiesData } = useQuery(
    ["getCities", form.address.state],
    () => authorizedFetch(citiesUrl),
    {
      enabled: Boolean(form.address.state),
    }
  );

  useEffect(() => {
    if (!open) return;
    setForm({ ...formObj });
    setValidations({ ...validationsObj });
  }, [open]);

  useEffect(() => {
    handleChange("address", {
      ...form.address,
      state: "",
      district: "",
      pincode: "",
    });
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [selectedCountry]);

  function handleChange(key: string, value: any) {
    setForm((prev: any) => ({ ...prev, [key]: value }));
  }

  function handleSave() {
    let newValidations = { ...validationsObj };
    if (!isValidPhoneNumber(form.phone)) newValidations.phone = true;
    if (form.email && !validator.isEmail(form.email))
      newValidations.email = true;
    if (
      form.address.pincode &&
      !postcodeValidator(form.address.pincode, selectedCountry)
    )
      newValidations.pincode = true;

    setValidations(newValidations);

    if (Object.values(newValidations).includes(true)) return;

    setLoader(true);
    authorizedFetch(`${RETAIL_URL}/distribution/${vehicle._id}/sell-vehicle`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        firstName: form.firstName,
        ...(form.lastName !== "" ? { lastName: form.lastName } : {}),
        phone: form.phone,
        ...(form.email !== "" ? { email: form.email } : {}),
        address: {
          ...(form.address.local !== "" ? { local: form.address.local } : {}),
          district: form.address.district,
          state: form.address.state,
          ...(form.address.pincode !== ""
            ? { pincode: form.address.pincode }
            : {}),
          country: countryNames[selectedCountry],
        },
      },
    })
      .then((res) => {
        setLoader(false);
        if (res.meta.success) {
          snackbar.success("Saved");
          queryClient.resetQueries("getRetailVehicles");
          onSave && onSave();
          handleClose();
        } else {
          console.error(res);
          snackbar.error("Error: " + res.msg);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
        snackbar.error("An error occurred");
      });
  }

  let disabled = false;
  if (
    !form.firstName ||
    // !form.lastName ||
    !isValidPhoneNumber(form.phone) ||
    !form.phone ||
    // !form.email ||
    // [null, ""].some((el) =>
    //   [form.address.district, form.address.state].includes(el)
    // ) ||
    (form.address.pincode !== "" &&
      !postcodeValidator(form.address.pincode, selectedCountry))
  )
    disabled = true;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          Sell Vehicle
          <Divider flexItem orientation="vertical" sx={{ mx: 1.5 }} />
          <Typography color="text.secondary">{vehicle?.vin}</Typography>
        </Box>
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <Typography variant="overline">Buyer Info</Typography>
          <Typography variant="body2">
            <RedAsterisk /> Required fields
          </Typography>
        </Box>
        <Divider sx={{ mb: 1.5 }} />
        <Box
          sx={{
            py: 1,
            display: "grid",
            gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
            gap: 2.5,
          }}
        >
          <Box>
            <Typography className="label">
              First Name <RedAsterisk />
            </Typography>
            <TextField
              fullWidth
              size="small"
              placeholder="First Name"
              value={form.firstName}
              onChange={(e) => handleChange("firstName", e.target.value)}
            />
          </Box>
          <Box>
            <Typography className="label">Last Name</Typography>
            <TextField
              fullWidth
              size="small"
              placeholder="Last Name"
              value={form.lastName}
              onChange={(e) => handleChange("lastName", e.target.value)}
            />
          </Box>
          <Box>
            <Typography className="label">
              Phone <RedAsterisk />
            </Typography>

            <PhoneInput
              placeholder="Enter phone number"
              value={form.phone}
              onChange={(value) => handleChange("phone", value)}
              onCountryChange={(value: any) => setSelectedCountry(value)}
              defaultCountry="IN"
              international
              limitMaxLength
              inputComponent={PhoneTextField}
              error={!!form.phone && !isValidPhoneNumber(form.phone)}
            />
          </Box>
          <Box>
            <Typography className="label">Email</Typography>
            <TextField
              fullWidth
              size="small"
              placeholder="Email"
              value={form.email}
              onChange={(e) =>
                handleChange("email", e.target.value.toLowerCase())
              }
              error={validations.email}
              helperText={
                validations.email ? "Please enter a valid e-mail" : undefined
              }
            />
          </Box>
          <Box sx={{ gridColumn: { xs: "span 1", sm: "span 2" } }}>
            <Typography variant="overline">Buyer's Address</Typography>
            <Divider sx={{ mb: 1.5 }} />
            {/* <Typography className="label">
              Address <RedAsterisk />
            </Typography> */}
            <Typography className="label">
              Plot/House no./Apartment, Locality/Street Name
            </Typography>
            <TextField
              fullWidth
              size="small"
              // multiline
              // minRows={3}
              // placeholder="Address"
              // value={form.address}
              // onChange={(e) => handleChange("address", e.target.value)}
              placeholder="Plot/House no./Apartment, Locality/Street Name"
              value={form.address.local}
              onChange={(e) =>
                handleChange("address", {
                  ...form.address,
                  local: e.target.value,
                })
              }
            />
          </Box>
          <Box>
            <Typography className="label">
              Country
              {/* <RedAsterisk /> */}
            </Typography>
            <TextField
              fullWidth
              disabled
              size="small"
              value={countryNames[selectedCountry]}
            />
          </Box>
          <Box>
            <Typography className="label">
              State
              {/* <RedAsterisk /> */}
            </Typography>
            <Autocomplete
              loading={statesLoading}
              size="small"
              popupIcon={<KeyboardArrowDown />}
              value={form.address.state}
              onChange={(e, newValue) =>
                handleChange("address", {
                  ...form.address,
                  state: newValue,
                })
              }
              options={(statesData?.data?.constructor === Array
                ? statesData.data
                : []
              ).map((el: any) => el.name)}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select..." />
              )}
            />
          </Box>
          <Box>
            <Typography className="label">Pincode</Typography>
            <TextField
              fullWidth
              size="small"
              placeholder="Enter pincode"
              value={form.address.pincode}
              onChange={(e) => {
                handleChange("address", {
                  ...form.address,
                  pincode: e.target.value,
                });
              }}
              error={
                form.address.pincode !== "" &&
                !postcodeValidator(form.address.pincode, selectedCountry)
              }
              helperText={
                form.address.pincode !== "" &&
                !postcodeValidator(form.address.pincode, selectedCountry)
                  ? "Please enter a valid pincode"
                  : undefined
              }
            />
          </Box>
          <Box>
            <Typography className="label">
              City/District
              {/* <RedAsterisk /> */}
            </Typography>
            <Autocomplete
              disabled={!form.address.state}
              loading={citiesLoading}
              size="small"
              popupIcon={<KeyboardArrowDown />}
              value={form.address.district}
              onChange={(e, newValue) =>
                handleChange("address", {
                  ...form.address,
                  district: newValue,
                })
              }
              options={(citiesData?.data?.constructor === Array
                ? citiesData.data
                : []
              ).map((el: any) => el.name)}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select..." />
              )}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" disabled={disabled} onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SellDialog;
