import {
  HowToRegRounded,
  PeopleOutlined,
  PersonAddAltOutlined,
  PersonOutlineRounded,
  PowerOutlined,
  AccountBalanceWalletOutlined,
  GroupsOutlined,
  EventAvailableOutlined,
  BoltOutlined,
} from "@mui/icons-material";
import { alpha, Box, Paper, Skeleton } from "@mui/material";
import moment from "moment";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { authorizedFetch, GlobalState } from "utils";
import { BOLT_URL } from "utils/constants";

const Cards = ({ tab }: any) => {
  const { company, drawer, masterView } = useSelector(
    (state: GlobalState) => state.global
  );
  const isCUGMonitorAccount = company?.type === "CUG_VENDOR";

  const dateFrom = moment().subtract({ months: 1 }).format("YYYY-MM-DD");
  const dateTo = moment().format("YYYY-MM-DD");

  const appUsersUrl = `${BOLT_URL}/company/users?type=APP_USER&first=1&skip=0`;
  const employeesUrl = `${BOLT_URL}/company/users?type=COMPANY_USER&first=1&skip=0`;

  const { isLoading: appUsersLoading, data: appUsersData } = useQuery(
    ["getTotalAppUsers", masterView],
    () =>
      authorizedFetch(appUsersUrl, {
        headers: {
          master: masterView,
        },
      }),
    { enabled: !isCUGMonitorAccount }
  );
  const { isLoading: employeesLoading, data: employeesData } = useQuery(
    ["getTotalEmployees", masterView],
    () =>
      authorizedFetch(employeesUrl, {
        headers: {
          master: masterView,
        },
      }),
    { enabled: !isCUGMonitorAccount }
  );
  const bookingsStatsUrl = `${BOLT_URL}/company/stats/bookings/date?dateFrom=${dateFrom}&dateTo=${dateTo}`;
  const { isLoading: bookingsStatsLoading, data: bookingsStatsData } = useQuery(
    ["getBookingsStats", dateFrom, dateTo, masterView],
    () =>
      authorizedFetch(bookingsStatsUrl, {
        headers: {
          cache: "true",
          master: masterView,
        },
      }),
    { enabled: !isCUGMonitorAccount }
  );
  const newUsersUrl = `${BOLT_URL}/company/stats/newuser/date?orderBy=BOOKING_TIME_ASC&dateFrom=${dateFrom}&dateTo=${dateTo}`;
  const { isLoading: newUsersLoading, data: newUsersData } = useQuery(
    ["getNewUserStats", dateFrom, dateTo, masterView],
    () =>
      authorizedFetch(newUsersUrl, {
        headers: {
          cache: "true",
          master: masterView,
        },
      }),
    { enabled: !isCUGMonitorAccount }
  );

  const totalHostsUrl = `${BOLT_URL}/company/stats/host/total`;
  const { isLoading: totalHostsLoading, data: totalHostsData } = useQuery(
    ["getTotalHosts", masterView],
    () =>
      authorizedFetch(totalHostsUrl, {
        headers: {
          master: masterView,
        },
      }),
    { enabled: !isCUGMonitorAccount }
  );
  const activeHostsUrl = `${BOLT_URL}/company/stats/host/active`;
  const { isLoading: activeHostsLoading, data: activeHostsData } = useQuery(
    ["getActiveHosts", masterView],
    () =>
      authorizedFetch(activeHostsUrl, {
        headers: {
          master: masterView,
        },
      }),
    { enabled: !isCUGMonitorAccount }
  );
  const newHostsUrl = `${BOLT_URL}/company/stats/host/new`;
  const { isLoading: newHostsLoading, data: newHostsData } = useQuery(
    ["getNewHosts", masterView],
    () =>
      authorizedFetch(newHostsUrl, {
        headers: {
          master: masterView,
        },
      }),
    { enabled: !isCUGMonitorAccount }
  );

  const monitoringOverviewStatsUrl = `${BOLT_URL}/company/v2/usergroup/stats/vendors`;
  const {
    isLoading: monitorOverviewStatsLoading,
    data: monitorOverviewStatsData
  } = useQuery(
    ["getMoniorOverviewStats"],
    () => authorizedFetch(monitoringOverviewStatsUrl, {
      headers: {
        monitoringAccount: isCUGMonitorAccount,
      },
    }),
    { enabled: isCUGMonitorAccount }
  );
  console.log(monitorOverviewStatsData?.data)

  // Users stats:
  // Total Users
  // Active Users (Last 30 Days)
  // Total New Users (Last 30 Days)
  // Avg. Daily New Users (Last 30 Days)

  // Hosts stats:
  // Total Hosts
  // Active Hosts (Last 30 Days)
  // Total New Hosts (Last 30 Days)
  // Avg Daily New Hosts (Last 30 Days)

  const isHostsTabActive = tab === "hosts";

  const totalUsers = (
    (appUsersData?.data?.count || 0) + (employeesData?.data?.count || 0)
  ).toLocaleString();
  const activeUsers =
    bookingsStatsData?.data?.totalUsers?.toLocaleString() || 0;
  const totalNewUsers = (
    newUsersData?.data?.totalNewUsers || 0
  ).toLocaleString();

  const newUsersStatsArr =
    newUsersData?.data?.stats?.constructor === Array
      ? newUsersData.data.stats
      : [];
  const avgDailyNewUsers = Math.round(
    newUsersStatsArr.reduce(
      (acc: number, cur: any) => acc + cur?.newUsersCount || 0,
      0
    ) / (newUsersStatsArr.length || 1)
  ).toLocaleString();

  const totalHosts = totalHostsData?.data?.count?.toLocaleString() || 0;
  const activeHosts = activeHostsData?.data?.count?.toLocaleString() || 0;
  const totalNewHosts = newHostsData?.data?.count?.toLocaleString() || 0;
  const avgDailyNewHosts = parseInt(
    newHostsData?.data?.average || 0
  ).toLocaleString();

  const totalUsersCUG = monitorOverviewStatsData?.data?.usersCount || 0;
  const activeUsersCUG = monitorOverviewStatsData?.data?.activeUsersCount || 0;
  const assignedChargersCUG = monitorOverviewStatsData?.data?.assignedChargersCount || 0;
  const cmsBalanceCUG = monitorOverviewStatsData?.data?.cmsBalance || 0;
  const totalActiveCUGs = monitorOverviewStatsData?.data?.activeCUGCount || 0;
  const totalBookingsCUG = monitorOverviewStatsData?.data?.totalBookings || 0;
  const totalEnergy = monitorOverviewStatsData?.data?.totalEnergyConsumed.toFixed(2) || 0;

  return (
    <Box
      sx={{
        display: "grid",
        mb: { xs: 3, md: 4 },
        gridTemplateColumns: {
          lg: drawer.open ? "1fr" : "1fr 1fr",
        },
        gap: { xs: 3, md: 4 },
        "& .MuiPaper-root": {
          boxShadow: (theme) => theme.customShadows.card,
        },
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr 1fr",
            md: drawer.open ? "1fr" : isCUGMonitorAccount ? "1fr 1fr 1fr" : "1fr 1fr",
            xl: drawer.open ? "1fr 1fr" : "repeat(4, 1fr)",
          },
          gap: { xs: 1.75, md: 4 },
          gridColumn: "1 / -1",
          "& .card": {
            width: 1,
            borderRadius: 0,
            py: { xs: 1.25, md: 2 },
            px: { xs: 1.25, md: 3 },
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            "& .icon": {
              borderRadius: 50,
              height: { xs: 32, md: 98 },
              width: { xs: 32, md: 98 },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              bgcolor: (theme) => alpha(theme.palette.primary.main, 0.2),
              color: (theme) => theme.palette.primary.main,
              fontSize: { xs: 18, md: 44 },
            },
            "& .description": {
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              "& .label": {
                // display: "flex",
                // flexDirection: "column",
                position: "relative",
                mb: { xs: 1, md: 1.5 },
                textAlign: "end",
                fontSize: { xs: "12px", md: 18 },
                fontWeight: 600,
                lineHeight: { xs: "16px", md: "24.55px" },
                color: (theme) => theme.customColors.text.grey,
                "& .label2": {
                  position: "absolute",
                  bottom: { xs: -10, md: -12 },
                  right: 0,
                  fontSize: { xs: 10, md: 12 },
                  lineHeight: "16.37px",
                },
              },
              "& .value": {
                textAlign: "end",
                fontSize: { xs: "16px", md: "32px" },
                fontWeight: 700,
                lineHeight: { xs: "19.1px", md: "44px" },
                letterSpacing: "0em",
              },
            },
          },
        }}
      >
        {
          isCUGMonitorAccount
            ? (<>
              <Paper className="card">
                <Box className="icon">
                  <PersonOutlineRounded fontSize="inherit" />
                </Box>
                <Box className="description">
                  <span className="label">Total Users</span>
                  <span className="value">
                    {monitorOverviewStatsLoading
                      ? (<Skeleton width={40} />)
                      : totalUsersCUG}
                  </span>
                </Box>
              </Paper>
              <Paper className="card">
                <Box className="icon">
                  <HowToRegRounded fontSize="inherit" />
                </Box>
                <Box className="description">
                  <span className="label">Active Users</span>
                  <span className="value">
                    {monitorOverviewStatsLoading
                      ? (<Skeleton width={40} />)
                      : activeUsersCUG}
                  </span>
                </Box>
              </Paper>
              <Paper className="card">
                <Box className="icon">
                  <PowerOutlined fontSize="inherit" />
                </Box>
                <Box className="description">
                  <span className="label">Assigned Chargers</span>
                  <span className="value">
                    {monitorOverviewStatsLoading
                      ? (<Skeleton width={40} />)
                      : assignedChargersCUG}
                  </span>
                </Box>
              </Paper>
              <Paper className="card">
                <Box className="icon">
                  <AccountBalanceWalletOutlined fontSize="inherit" />
                </Box>
                <Box className="description">
                  <span className="label">CMS Balance</span>
                  <span className="value">
                    {monitorOverviewStatsLoading
                      ? (<Skeleton width={40} />)
                      : cmsBalanceCUG}
                  </span>
                </Box>
              </Paper>
              <Paper className="card">
                <Box className="icon">
                  <GroupsOutlined fontSize="inherit" />
                </Box>
                <Box className="description">
                  <span className="label">Total Active CUGs</span>
                  <span className="value">
                    {monitorOverviewStatsLoading
                      ? (<Skeleton width={40} />)
                      : totalActiveCUGs}
                  </span>
                </Box>
              </Paper>
              <Paper className="card">
                <Box className="icon">
                  <EventAvailableOutlined fontSize="inherit" />
                </Box>
                <Box className="description">
                  <span className="label">Total Bookings</span>
                  <span className="value">
                    {monitorOverviewStatsLoading
                      ? (<Skeleton width={40} />)
                      : totalBookingsCUG}
                  </span>
                </Box>
              </Paper>
              <Paper className="card">
                <Box className="icon">
                  <BoltOutlined fontSize="inherit" />
                </Box>
                <Box className="description">
                  <span className="label">Total Energy</span>
                  <span className="value">
                    {monitorOverviewStatsLoading
                      ? (<Skeleton width={40} />)
                      : totalEnergy}
                  </span>
                </Box>
              </Paper>
            </>)
            : (<>
              <Paper className="card">
                <Box className="icon">
                  <PersonOutlineRounded fontSize="inherit" />
                </Box>
                <Box className="description">
                  <span className="label">
                    Total {isHostsTabActive ? "Hosts" : "Users"}
                  </span>
                  <span className="value">
                    {(
                      isHostsTabActive
                        ? totalHostsLoading
                        : appUsersLoading || employeesLoading
                    ) ? (
                      <Skeleton width={60} />
                    ) : isHostsTabActive ? (
                      totalHosts
                    ) : (
                      totalUsers
                    )}
                  </span>
                </Box>
              </Paper>
              <Paper className="card">
                <Box className="icon">
                  <HowToRegRounded fontSize="inherit" />
                </Box>
                <Box className="description">
                  <span className="label">
                    Active {isHostsTabActive ? "Hosts" : "Users"}
                  </span>
                  <span className="value">
                    {(
                      isHostsTabActive ? activeHostsLoading : bookingsStatsLoading
                    ) ? (
                      <Skeleton width={60} />
                    ) : isHostsTabActive ? (
                      activeHosts
                    ) : (
                      activeUsers
                    )}
                  </span>
                </Box>
              </Paper>
              <Paper className="card">
                <Box className="icon">
                  <PeopleOutlined fontSize="inherit" />
                </Box>
                <Box className="description">
                  <span className="label">
                    Total New {isHostsTabActive ? "Hosts" : "Users"}
                    <span className="label2">(Last 30 Days)</span>
                  </span>
                  <span className="value">
                    {(isHostsTabActive ? newHostsLoading : newUsersLoading) ? (
                      <Skeleton width={60} />
                    ) : isHostsTabActive ? (
                      totalNewHosts
                    ) : (
                      totalNewUsers
                    )}
                  </span>
                </Box>
              </Paper>
              <Paper className="card">
                <Box className="icon">
                  <PersonAddAltOutlined fontSize="inherit" />
                </Box>
                <Box className="description">
                  <span className="label">
                    Avg. Daily New {isHostsTabActive ? "Hosts" : "Users"}
                    <span className="label2">(Last 30 Days)</span>
                  </span>
                  <span className="value">
                    {(isHostsTabActive ? newHostsLoading : newUsersLoading) ? (
                      <Skeleton width={60} />
                    ) : isHostsTabActive ? (
                      avgDailyNewHosts
                    ) : (
                      avgDailyNewUsers
                    )}
                  </span>
                </Box>
              </Paper>
            </>)
        }
      </Box>
    </Box>
  );
};

export default Cards;
