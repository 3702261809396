import { Add, DeleteOutline } from "@mui/icons-material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import { Fragment, useEffect, useState } from "react";

const SetAvailability = ({
  open,
  days,
  setDays,
  twentyFourSeven,
  setTwentyFourSeven,
}: any) => {
  const [tab, setTab] = useState(0);

  useEffect(() => {
    if (open) {
      setDays(
        days.map((el: any, i: number) => ({
          id: i,
          day: el.day,
          fromTime:
            el.fromTime.length < 6 ? formatTime(el.fromTime) : el.fromTime,
          toTime: el.toTime.length < 6 ? formatTime(el.toTime) : el.toTime,
        }))
      );
    }
    // eslint-disable-next-line
  }, [open]);

  function formatTime(time: any) {
    return `Mon Dec 20 2021 ${time}:00 GMT+0530 (India Standard Time)`;
  }

  function handleTimeChange(key: string, id: number, time: any) {
    setDays(
      days.map((el: any) => {
        if (el.id === id) {
          el[key] = String(time);
        }
        return el;
      })
    );
  }

  function addSlot() {
    const defaultTime =
      "Mon Dec 20 2021 00:00:00 GMT+0530 (India Standard Time)";
    const newDays = [
      ...days,
      {
        id: days.length,
        day: tab,
        fromTime: defaultTime,
        toTime: defaultTime,
      },
    ];
    setDays(newDays);
  }

  function removeSlot(id: number) {
    const newDays = days.filter((el: any) => el.id !== id);
    setDays(newDays);
  }

  function getDay(input: number) {
    switch (input) {
      case 0:
        return "SUNDAY's";
      case 1:
        return "MONDAY's";
      case 2:
        return "TUESDAY's";
      case 3:
        return "WEDNESDAY's";
      case 4:
        return "THURSDAY's";
      case 5:
        return "FRIDAY's";
      case 6:
        return "SATURDAY's";
    }
  }

  return (
    <Box sx={{ gridColumn: "1/-1" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography className="label" sx={{ mb: "0 !important" }}>
          Availability
        </Typography>
        <FormControlLabel
          sx={{ mr: 0 }}
          label="Available 24/7"
          control={
            <Switch
              sx={{ mr: 0.5 }}
              size="small"
              onChange={() => {
                setTwentyFourSeven(!twentyFourSeven);
              }}
              checked={twentyFourSeven}
            />
          }
        />
      </Box>
      <Tabs
        value={tab}
        onChange={(e, tab) => setTab(tab)}
        sx={{
          mb: 4,
          "& .MuiTabs-indicator": {
            opacity: twentyFourSeven ? "25%" : "100%",
          },
          "& .MuiTab-root": {
            width: 80,
          },
        }}
        variant="scrollable"
      >
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(
          (el: any, i: number) => {
            return <Tab key={i} label={el} disabled={twentyFourSeven} />;
          }
        )}
      </Tabs>
      <Box>
        {twentyFourSeven && (
          <Box
            sx={{
              my: 5,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography mb={1} className="label">
              Charger is <b> Available 24/7 </b> <br />
            </Typography>
            <Typography variant="body2">
              Switch it OFF to make the Charger available on specific days &
              specific time slots
            </Typography>
          </Box>
        )}
        {!twentyFourSeven && !days.some((el: any) => el.day === tab) && (
          <Box
            sx={{
              my: 5,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography mb={1} className="label">
              Charger is Unavailable on <b>{getDay(tab)}</b>
            </Typography>
            <Typography mb={2} variant="body2">
              Add a Slot to make the Charger Available
            </Typography>
            <Button
              sx={{ textTransform: "none" }}
              variant="outlined"
              onClick={addSlot}
              disabled={twentyFourSeven}
              startIcon={<Add />}
            >
              Add Slot
            </Button>
          </Box>
        )}
        {!twentyFourSeven && (
          <Box>
            {days
              ?.filter((el: any) => el.day === tab)
              ?.map((day: any, i: number) => (
                <Fragment key={i}>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr auto",
                      gap: 2,
                      mb: 2,
                    }}
                  >
                    {["fromTime", "toTime"].map((key, i) => (
                      <LocalizationProvider
                        key={i}
                        dateAdapter={AdapterDateFns}
                      >
                        <TimePicker
                          label={key === "fromTime" ? "From" : "To"}
                          value={
                            days.find((el: any) => day.id === el.id)?.[key] ||
                            ""
                          }
                          onChange={(newValue: any) => {
                            // let value = moment(newValue).format("HH:mm");
                            // console.log(newValue, value);
                            handleTimeChange(key, day.id, newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              fullWidth
                              sx={{
                                "& .MuiIconButton-root": {
                                  mr: 0,
                                },
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    ))}
                    <Tooltip title="Remove Slot">
                      <IconButton
                        size="small"
                        color="error"
                        sx={{
                          alignSelf: "center",
                        }}
                        onClick={() => removeSlot(day.id)}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Fragment>
              ))}
            {days.length > 0 && (
              <Button
                sx={{ textTransform: "none" }}
                variant="outlined"
                onClick={addSlot}
                disabled={twentyFourSeven}
                startIcon={<Add />}
              >
                Add Slot
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SetAvailability;
