import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  // TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  GlobalState,
  authorizedFetch,
  setLoader,
  snackbar,
  titleCase,
} from "utils";
import { RETAIL_URL } from "utils/constants";
import CircularLoader from "components/CircularLoader";
import { useSelector } from "react-redux";

const OTAUpdateDialog = ({ open, handleClose, data }: any) => {
  const [type, setType] = useState(0);
  const [latestVersions, setLatestVersions] = useState({
    primary: "",
    secondary: "",
  });
  const { company } = useSelector((state: GlobalState) => state.global);

  const { isLoading: vehicleDataLoading, data: vehicleData } = useQuery(
    ["getVehiclesOTA", data && data?._id],
    () => authorizedFetch(`${RETAIL_URL}/assembly/vehicle/${data?._id}`),
    {
      enabled: open,
    }
  );

  const { isLoading: primaryVersionsLoading, data: primaryVersions } = useQuery(
    ["getPrimaryVersions", data && data?._id, company, type],
    () =>
      authorizedFetch(
        `${RETAIL_URL}/inventory/ota/prerequisites?companyId=${company?._id}&vehicleModel=${data?.model?._id}&type=PRIMARY`
      ),
    {
      enabled: open && (type === 0 || type === 2),
    }
  );

  const { isLoading: secondaryVersionsLoading, data: secondaryVersions } =
    useQuery(
      ["getSecondaryVersions", data && data?._id, company, type],
      () =>
        authorizedFetch(
          `${RETAIL_URL}/inventory/ota/prerequisites?companyId=${company?._id}&vehicleModel=${data?.model?._id}&type=SECONDARY`
        ),
      {
        enabled: open && (type === 1 || type === 2),
      }
    );

  function handleSubmit() {
    setLoader(true);
    authorizedFetch(`${RETAIL_URL}/inventory/ota/mark`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: "1234",
      },
      body: {
        vin: data?.vin,
        ...((type === 0 || type === 2) && { primary: latestVersions.primary }),
        ...((type === 1 || type === 2) && {
          secondary: latestVersions.secondary,
        }),
      },
    })
      .then((res) => {
        setLoader(false);
        if (res.meta.success === true) {
          snackbar.success("Vehicle marked for OTA update");
          handleClose();
        } else {
          snackbar.error(`Error${res.msg ? ": " + res.msg : ""}`);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
        snackbar.error("An error occurred");
      });
  }

  const primaryFirmware =
    vehicleData?.data?.[0]?.components[0]?.id?.currentOta?.primary?.version ||
    "No Firmware Found";

  const secondaryFirmware =
    vehicleData?.data?.[0]?.components[0]?.id?.currentOta?.secondary?.version ||
    "No Firmware Found";

  const latestPrimaryFirmwareNumber = primaryVersions?.data
    .filter((el: any) => el._id === latestVersions.primary)
    .map((el: any) => el.version)[0];

  const latestSecondaryFirmwareNumber = secondaryVersions?.data
    .filter((el: any) => el._id === latestVersions.secondary)
    .map((el: any) => el.version)[0];

  useEffect(() => {
    if (!open) {
      setLatestVersions({
        primary: "",
        secondary: "",
      });
    }
  }, [open]);

  useEffect(() => {
    setLatestVersions({
      primary: "",
      secondary: "",
    });
  }, [type]);

  function compareVersions(versionA: any, versionB: any) {
    console.log(versionA, versionB);
    const partsA = versionA?.split(".").map(Number);
    const partsB = versionB?.split(".").map(Number);

    for (let i = 0; i < Math.max(partsA?.length, partsB?.length); i++) {
      const numA = partsA[i] || 0;
      const numB = partsB[i] || 0;

      console.log(numA, numB);

      if (numA < numB) {
        return -1; // versionA is less than versionB
      } else if (numA > numB) {
        return 1; // versionA is greater than versionB
      }
    }

    return 0; // versionA is equal to versionB
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
        Mark for OTA Update
        <Divider flexItem orientation="vertical" sx={{ mx: 1.5 }} />
        <Typography color="text.secondary">{data?.vin}</Typography>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            py: 2,
            display: "grid",
            columnGap: 3,
            rowGap: 4,
            gridTemplateColumns: "auto 1fr",
            alignItems: "center",
          }}
        >
          <Typography color="text.secondary">Availabilty</Typography>
          <Avatar
            variant="status"
            className={
              data?.rentalStatus === "INSTOCK"
                ? "blue"
                : data?.rentalStatus === "BOOKED"
                ? "yellow"
                : data?.rentalStatus === "AVAILABLE"
                ? "green"
                : "grey"
            }
          >
            {data?.rentalStatus
              ? data?.rentalStatus === "INSTOCK"
                ? "In Stock"
                : titleCase(data?.rentalStatus.split("_").join(" "))
              : "N/A"}
          </Avatar>

          <Typography color="text.secondary">Health</Typography>
          <Avatar
            variant="status"
            className={
              data?.health === "active"
                ? ""
                : data?.health === "moderate"
                ? "yellow"
                : data?.health === "critical"
                ? "red"
                : "grey"
            }
          >
            {data?.health ? titleCase(data?.health) : "N/A"}
          </Avatar>
          <Typography color="text.secondary">OTA Type</Typography>

          <Select
            size="small"
            sx={{ width: "100%" }}
            value={type}
            onChange={(e: any) => setType(e.target.value)}
            renderValue={(selected) =>
              selected === 0
                ? "Type: Primary"
                : selected === 1
                ? "Type: Secondary"
                : "Type: Both"
            }
          >
            <MenuItem value={0}>Primary </MenuItem>
            <MenuItem value={1}>Secondary </MenuItem>
            <MenuItem value={2}>Both </MenuItem>
          </Select>

          {(type === 0 || type === 2) && (
            <>
              <Typography color="text.secondary">
                Current Primary Firmware Version
              </Typography>
              {vehicleDataLoading ? (
                <Box ml={0} mr="auto">
                  <CircularLoader />
                </Box>
              ) : (
                <Typography>{primaryFirmware}</Typography>
              )}
              <Typography color="text.secondary">
                Latest Primary Firmware Version
              </Typography>
              <FormControl
                error={
                  latestVersions.primary !== "" &&
                  compareVersions(
                    latestPrimaryFirmwareNumber,
                    primaryFirmware
                  ) !== 1
                    ? true
                    : false
                }
              >
                <Select
                  size="small"
                  value={latestVersions.primary}
                  onChange={(e) =>
                    setLatestVersions({
                      ...latestVersions,
                      primary: e.target.value,
                    })
                  }
                  fullWidth
                  placeholder="Select"
                  displayEmpty
                  renderValue={
                    latestVersions.primary !== ""
                      ? undefined
                      : () => <em>Select</em>
                  }
                >
                  {primaryVersionsLoading ? (
                    <MenuItem disabled value="">
                      Loading...
                    </MenuItem>
                  ) : (
                    (primaryVersions?.data || []).map((el: any, i: number) => (
                      <MenuItem key={i} value={el._id}>
                        {el.version}
                      </MenuItem>
                    ))
                  )}
                </Select>
                {latestVersions.primary !== "" &&
                  compareVersions(
                    latestPrimaryFirmwareNumber,
                    primaryFirmware
                  ) !== 1 && (
                    <FormHelperText sx={{ fontSize: 9, fontWeight: 500 }}>
                      Latest version cant be{" "}
                      {compareVersions(
                        latestPrimaryFirmwareNumber,
                        primaryFirmware
                      ) === 0
                        ? "equal to "
                        : "less than "}
                      Current version
                    </FormHelperText>
                  )}
              </FormControl>
            </>
          )}

          {(type === 1 || type === 2) && (
            <>
              <Typography color="text.secondary">
                Current Secondary Firmware Version
              </Typography>
              {vehicleDataLoading ? (
                <Box ml={0} mr="auto">
                  <CircularLoader />
                </Box>
              ) : (
                <Typography>{secondaryFirmware}</Typography>
              )}
              <Typography color="text.secondary">
                Latest Secondary Firmware Version
              </Typography>
              <FormControl
                error={
                  latestVersions.secondary !== "" &&
                  compareVersions(
                    latestSecondaryFirmwareNumber,
                    secondaryFirmware
                  ) !== 1
                    ? true
                    : false
                }
              >
                <Select
                  size="small"
                  value={latestVersions.secondary}
                  onChange={(e) =>
                    setLatestVersions({
                      ...latestVersions,
                      secondary: e.target.value,
                    })
                  }
                  fullWidth
                  placeholder="Select"
                  displayEmpty
                  renderValue={
                    latestVersions.secondary !== ""
                      ? undefined
                      : () => <em>Select</em>
                  }
                >
                  {secondaryVersionsLoading ? (
                    <MenuItem disabled value="">
                      Loading...
                    </MenuItem>
                  ) : (
                    (secondaryVersions?.data || []).map(
                      (el: any, i: number) => (
                        <MenuItem key={i} value={el._id}>
                          {el.version}
                        </MenuItem>
                      )
                    )
                  )}
                </Select>
                {latestVersions.secondary !== "" &&
                  compareVersions(
                    latestSecondaryFirmwareNumber,
                    secondaryFirmware
                  ) !== 1 && (
                    <FormHelperText sx={{ fontSize: 9, fontWeight: 500 }}>
                      Latest version cant be{" "}
                      {compareVersions(
                        latestSecondaryFirmwareNumber,
                        secondaryFirmware
                      ) === 0
                        ? "equal to "
                        : "less than "}
                      Current version
                    </FormHelperText>
                  )}
              </FormControl>
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={
            type === 0
              ? latestVersions.primary === "" ||
                compareVersions(
                  latestPrimaryFirmwareNumber,
                  primaryFirmware
                ) !== 1
              : type === 1
              ? latestVersions.secondary === "" ||
                compareVersions(
                  latestSecondaryFirmwareNumber,
                  secondaryFirmware
                ) !== 1
              : type === 2
              ? latestVersions.primary === "" ||
                compareVersions(
                  latestPrimaryFirmwareNumber,
                  primaryFirmware
                ) !== 1 ||
                latestVersions.secondary === "" ||
                compareVersions(
                  latestSecondaryFirmwareNumber,
                  secondaryFirmware
                ) !== 1
              : true
          }
        >
          Mark for Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OTAUpdateDialog;
