import { useEffect, useState } from "react";
import { authorizedFetch, setLoader, snackbar } from "utils";
import {
  Box,
  IconButton,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Avatar,
  TextField,
} from "@mui/material";
import { HighlightOff } from "@mui/icons-material";

import { useMutation } from "react-query";
import { HEALTH_URL } from "utils/constants";
import { queryClient } from "index";

interface VendorProps {
  open: boolean;
  handleClose: () => void;
  data: any;
  closeDrawer: any;
}

const ChargerVisibility: React.FC<VendorProps> = ({
  open,
  handleClose,
  data,
  closeDrawer,
}) => {
  const [messageInput, setMessageInput] = useState("");

  // const disabled = [messageInput].includes("");

  useEffect(() => {
    if (!open) {
      setMessageInput("");
    }
  }, [open]);

  const mutation = useMutation(
    "sendNotificatons",
    () =>
      authorizedFetch(`${HEALTH_URL}/product/toggle`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          productId: data?.charger?.chargerId,
          productType: "CHARGING_SOCKET",
          note: messageInput,
        },
      }),
    {
      onSuccess: () => {
        setLoader(false);
        snackbar.success(
          `Charger ${data?.charger?.hidden === false ? "hidden" : "unhidden"}`
        );
        queryClient.resetQueries("getChargers");
        queryClient.resetQueries("getCharger");
        handleClose();
        closeDrawer();
      },
      onError: () => {
        setLoader(false);
        snackbar.error(
          `Error ${
            data?.charger?.hidden === false ? "hiding" : "unhiding"
          } charger`
        );
      },
    }
  );

  function onSave() {
    setLoader(true);
    mutation.mutate();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: 400,
          width: 1,
          "& .MuiInputBase-root": {
            fontSize: 14,
            borderRadius: 1,
            p: "3.5px 5px",
          },
        },
      }}
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          {data?.charger?.hidden === false ? "Hide" : "Unhide"} Charger
          <IconButton
            children={<HighlightOff />}
            color="inherit"
            onClick={handleClose}
            sx={{ transform: "translate(8px, -8px)" }}
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography
          sx={{
            pb: 2,
            display: "flex",
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          {data?.charger?.chargerId}:
          {data?.charger?.health === "HEALTHY" ? (
            <Avatar
              variant="status"
              sx={{
                background: "#3BB89E30",
                color: "#3BB89E",
                borderColor: "#3BB89E30",
                marginLeft: 3,
              }}
            >
              {data?.charger?.health}
            </Avatar>
          ) : data?.charger?.health === "MODERATE" ? (
            <Avatar
              variant="status"
              sx={{
                background: "#FFFF0030",
                color: "#ffc800",
                borderColor: "#FFFF0030",
                marginLeft: 3,
              }}
            >
              {data?.charger?.health}
            </Avatar>
          ) : data?.charger?.health === "CRITICAL" ? (
            <Avatar
              variant="status"
              sx={{
                background: "#FFA50030",
                color: "orange",
                borderColor: "#FFA50030",
                marginLeft: 3,
              }}
            >
              {data?.charger?.health}
            </Avatar>
          ) : data?.charger?.health === "INACTIVE" ? (
            <Avatar
              variant="status"
              sx={{
                background: "#FF000030",
                color: "red",
                borderColor: "#FF000030",
                marginLeft: 3,
              }}
            >
              {data?.charger?.health}
            </Avatar>
          ) : (
            ""
          )}
        </Typography>
        <Box mt={2}>
          <Typography sx={{ fontWeight: "500", fontSize: 14 }}>
            To{" "}
            {data?.charger?.hidden === false ? (
              <strong>HIDE</strong>
            ) : (
              <strong>UNHIDE</strong>
            )}{" "}
            charger from the map, please mention the reason below:
          </Typography>
          <TextField
            fullWidth
            id="outlined-multiline-static"
            multiline
            rows={5}
            placeholder="Type a message..."
            value={messageInput}
            helperText="Min 10 characters"
            onChange={(e: any) => {
              setMessageInput(e.target.value);
            }}
            sx={{ mt: 2 }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={messageInput.length <= 10}
          onClick={onSave}
        >
          {data?.charger?.hidden === false ? "HIDE" : "UNHIDE"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChargerVisibility;
