import {
  CheckBox,
  CheckBoxOutlineBlank,
  KeyboardArrowDown,
} from "@mui/icons-material";
import { Autocomplete, Checkbox, TextField, Typography } from "@mui/material";

const Selector = ({
  loading,
  isCMS,
  isFMS,
  isCUGMonitorAccount,
  toggle,
  chargerOptions,
  vendorOptions,
  usersOptions,
  cugsOptions,
  fmsVehicleOptions,
  vmsVehicleOptions,
  selected,
  setSelected,
  selectedChargers,
  setSelectedChargers,
  search,
  setSearch,
}: any) => {
  const isMultiple = isCMS && toggle === "chargers";
  const isVMS = !isCMS && !isFMS;

  return (
    <Autocomplete
      {...(isMultiple
        ? {
          multiple: true,
          disableCloseOnSelect: true,
          renderOption: (props, option, { selected }) => {
            const isMoreLabel = String(option || "").includes("more...");

            return (
              <li {...props} style={{ padding: "2px 6px" }}>
                {!isMoreLabel ? (
                  <Checkbox
                    size="small"
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                ) : null}
                <Typography p={isMoreLabel ? 1 : 0}>{option}</Typography>
              </li>
            );
          },
        }
        : isVMS
          ? {
            renderOption: (props, option) => {
              const isMoreLabel = String(option || "").includes("more...");
              return (
                <li {...props}>
                  <Typography p={isMoreLabel ? 1 : 0}>{option}</Typography>
                </li>
              );
            },
          }
          : {})}
      loading={loading}
      sx={{ width: 270 }}
      size="small"
      inputValue={search}
      onInputChange={(e, value) => setSearch(value)}
      popupIcon={<KeyboardArrowDown />}
      value={isCMS && toggle === "chargers" ? selectedChargers : selected}
      getOptionDisabled={(option: any) =>
        option?.includes("more...") ||
        (selectedChargers.includes("All Chargers") && option !== "All Chargers")
      }
      onChange={(e, newValue: any) => {
        if (isCMS && toggle === "chargers") {
          if (
            newValue.constructor === Array &&
            newValue.includes("All Chargers")
          )
            newValue = ["All Chargers"];
          setSelectedChargers(newValue);
        } else setSelected(newValue);
      }}
      options={
        !isCMS
          ? isFMS
            ? fmsVehicleOptions
            : vmsVehicleOptions
          : toggle === "chargers"
            ? chargerOptions
            : toggle === "vendors"
              ? vendorOptions
              : toggle === "users"
                ? usersOptions
                : cugsOptions
      }
      noOptionsText={
        !isCMS
          ? "No vehicles"
          : toggle === "vendors"
            ? "No vendors"
            : search
              ? `No chargers found for "${search}"`
              : "No chargers"
      }
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={isMultiple || isVMS ? "Type to search..." : "Choose"}
        />
      )}
    />
  );
};

export default Selector;
