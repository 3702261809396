import { AddRounded } from "@mui/icons-material";
import { Box, Button, Hidden, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { GlobalState } from "utils";
import AddChargerDialog from "./AddChargerDialog";

const AppBar = () => {
  const [addChargerDialog, setAddChargerDialog] = useState(false);

  let { user, company } = useSelector((state: GlobalState) => state.global);
  const shouldNotShowAddCharger = user.email === "adi.setiakarsa@alvaauto.com";
  const isCUGMonitorAccount = company?.type === "CUG_VENDOR";

  return (
    <>
      <AddChargerDialog
        open={addChargerDialog}
        handleClose={() => setAddChargerDialog(false)}
      />
      <Box
        sx={{
          width: 1,
          py: 3,
          px: { xs: 2, md: 7 },
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h2">Chargers</Typography>
        <Hidden mdUp>
          <Box display="flex" alignItems="center">
            {!shouldNotShowAddCharger && (
              <Button
                sx={{
                  mr: 2,
                  px: 0.5,
                  minWidth: 0,
                }}
                variant="outlined"
                onClick={() => setAddChargerDialog(true)}
              >
                <AddRounded />
              </Button>
            )}
          </Box>
        </Hidden>
        <Hidden mdDown>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-end",
              alignItems: "center",
              "& > :not(:first-of-type)": {
                ml: 3,
              },
            }}
          >
            {!shouldNotShowAddCharger && !isCUGMonitorAccount && (
              <Button
                sx={{
                  // width: { md: 300 },
                  ml: 3,
                  borderRadius: 10,
                  borderWidth: "1.5px !important",
                  borderColor: (theme) => theme.palette.primary.main,
                  textTransform: "none",
                  fontWeight: 500,
                }}
                variant="outlined"
                startIcon={<AddRounded />}
                onClick={() => setAddChargerDialog(true)}
              >
                Add Charger
              </Button>
            )}
          </Box>
        </Hidden>
      </Box>
    </>
  );
};

export default AppBar;
