import {
  DonutLarge,
  ElectricBike,
  Map,
  PinDrop,
  Speed,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { saveReport, saveVehicleReport } from "actions";
import RangePicker from "components/RangePicker";
import { sub } from "date-fns";
import { saveAs } from "file-saver";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  authorizedFetch,
  getDarkModePreference,
  GlobalState,
  setLoader,
  snackbar,
} from "utils";
import { BOLT_URL, LEASE_URL, REPORTS_URL, RETAIL_URL } from "utils/constants";
import ReportDialog from "../ReportDialog";
import ReportPreviewDialog from "./ReportPreviewDialog";
import Selector from "./Selector";

const Drawer = () => {
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );
  const dispatch = useDispatch();
  const { company, activeSection, masterView } = useSelector(
    (state: GlobalState) => state.global
  );
  const isCUGMonitorAccount = company?.type === "CUG_VENDOR";
  const isTVS = company.name === "testTVS";

  let isCMS = activeSection === "charger";
  let isFMS = activeSection === "rental";

  const [toggle, setToggle] = useState(
    isCMS ? (isCUGMonitorAccount ? "users" : "vendors") : "all-vehicles"
  );

  // console.log(`isCMS > ${isCMS} | isCUGMonitorAccount > ${isCUGMonitorAccount} | toggle > ${toggle}`)

  const [search, setSearch] = useState("");

  const [selected, setSelected] = useState<any>(null);
  const [selectedChargers, setSelectedChargers] = useState<any>([]);
  const [format, setFormat] = useState("excel");
  const [platform, setPlatform] = useState(isFMS ? "LEASE" : "RETAIL");

  const [previewDialog, setPreviewDialog] = useState<any>({
    open: false,
    data: null,
  });
  const [dialog, setDialog] = useState<any>({ open: false, data: null });

  const [range, setRange] = useState<any>([
    sub(new Date(), { months: 1 }),
    new Date(),
  ]);
  let startTime = moment(range[0]).toISOString();
  let endTime = moment(range[1]).toISOString();

  const maxListSize = 25;

  const chargersUrl = `${BOLT_URL}/company/chargers?first=${maxListSize}&chargerId=${search}`;
  const vendorsUrl = `${BOLT_URL}/company/getVendors`;
  const fmsVehiclesUrl = `${LEASE_URL}/company/vehicles?all=true`;
  const vmsVehiclesUrl = `${RETAIL_URL}/sold-vehicles?first=${maxListSize}&search=${search}`;
  const usersUrl = `${BOLT_URL}/company/v2/usergroup/users?first=${maxListSize}${
    search ? `&name=${search}` : ""
  }`;
  const cugsUrl = `${BOLT_URL}/company/v2/usergroup?first=${maxListSize}${
    search ? `&search=${search}` : ""
  }`;

  const { isLoading: chargersLoading, data: chargersData } = useQuery(
    ["getChargers", search],
    () => authorizedFetch(chargersUrl),
    { enabled: isCMS && !isCUGMonitorAccount }
  );

  const { isLoading: vendorsLoading, data: vendorsData } = useQuery(
    ["getVendors"],
    () => authorizedFetch(vendorsUrl),
    { enabled: isCMS && !isCUGMonitorAccount }
  );

  const { isLoading: fmsVehiclesLoading, data: fmsVehiclesData } = useQuery(
    ["getRentalVehicles"],
    () => authorizedFetch(fmsVehiclesUrl),
    { enabled: !isCMS }
  );

  const { isLoading: vmsVehiclesLoading, data: vmsVehiclesData } = useQuery(
    ["getSoldVehicles", search],
    () => authorizedFetch(vmsVehiclesUrl),
    { enabled: !isCMS }
  );

  const { isLoading: cugUsersLoading, data: cugUsersData } = useQuery(
    ["getMonitorUsersReport", search, isCUGMonitorAccount],
    () =>
      authorizedFetch(usersUrl, {
        headers: {
          monitoringAccount: isCUGMonitorAccount,
        },
      }),
    { enabled: isCUGMonitorAccount }
  );

  const { isLoading: cugsLoading, data: cugsData } = useQuery(
    ["getMonitorCUGReports", search, isCUGMonitorAccount],
    () =>
      authorizedFetch(cugsUrl, {
        headers: {
          monitoringAccount: isCUGMonitorAccount,
        },
      }),
    { enabled: isCUGMonitorAccount }
  );

  const chargerOptions = useMemo(() => {
    return [
      ...(chargersLoading ? [] : ["All Chargers"]),
      ...(chargersData?.data?.chargers?.constructor === Array
        ? chargersData?.data?.chargers.map((el: any) => el.id)
        : []),
      ...(chargersData?.data?.chargers?.constructor === Array &&
      chargersData.data.chargersCount > maxListSize
        ? [
            `+${chargersData.data.chargersCount - maxListSize} more...${
              search ? ` (for "${search}")` : ""
            }`,
          ]
        : []),
    ];
  }, [chargersLoading, chargersData, search]);

  const vendorOptions = useMemo(() => {
    return [
      ...(vendorsLoading ? [] : ["All Vendors"]),
      ...(vendorsData?.data?.constructor === Array ? vendorsData.data : []).map(
        (el: any) => el.name
      ),
    ];
  }, [vendorsLoading, vendorsData]);

  const fmsVehicleOptions = useMemo(() => {
    return fmsVehiclesData?.data?.vehicles?.constructor === Array
      ? fmsVehiclesData.data.vehicles
          .map((el: any) => el.vin)
          .sort((a: any, b: any) => a.localeCompare(b))
      : [];
  }, [fmsVehiclesData]);

  const vmsVehicleOptions = useMemo(() => {
    return [
      ...(vmsVehiclesData?.data?.constructor === Array
        ? vmsVehiclesData.data
            .map((el: any) => el.vin)
            .sort((a: any, b: any) => a.localeCompare(b))
        : []),
      ...(vmsVehiclesData?.data?.constructor === Array &&
      vmsVehiclesData.meta.totalCount > maxListSize
        ? [
            `+${vmsVehiclesData.meta.totalCount - maxListSize} more...${
              search ? ` (for "${search}")` : ""
            }`,
          ]
        : []),
    ];
  }, [vmsVehiclesData, search]);

  const usersOptions = useMemo(() => {
    return [
      ...(cugUsersLoading ? ["Loading..."] : []),
      ...(cugUsersData?.data?.userDetails?.constructor === Array
        ? cugUsersData?.data?.userDetails
        : []
      ).map((el: any) => `${el.firstName} ${el.lastName}`),
    ];
  }, [cugUsersLoading, cugUsersData]);

  const cugsOptions = useMemo(() => {
    return [
      ...(cugsLoading ? ["Loading..."] : []),
      ...(cugsData?.data?.groups?.constructor === Array
        ? cugsData?.data?.groups
        : []
      ).map((el: any) => el.name),
    ];
  }, [cugsLoading, cugsData]);

  function downloadReports(type?: string) {
    setLoader(true);
    let url = "";
    let token = 1234;

    if (isCMS) {
      type =
        toggle === "chargers"
          ? "Charger"
          : toggle === "vendors"
          ? "Vendors"
          : toggle === "users"
          ? "Users"
          : "CUGs";
      url =
        toggle === "chargers"
          ? selectedChargers.includes("All Chargers")
            ? `${REPORTS_URL}/v2/chargercumulativereport?start=${startTime}&end=${endTime}&token=${token}&format=${format}`
            : selectedChargers.length === 1
            ? `${REPORTS_URL}/v2/chargerindividualreport?uid=${selectedChargers[0]}&start=${startTime}&end=${endTime}&token=${token}&format=${format}&master=${masterView}`
            : `${REPORTS_URL}/v2/chargerbatchreport?start=${startTime}&end=${endTime}&token=${token}&format=${format}&chargerUIDs=${selectedChargers.join(
                ", "
              )}&master=${masterView}`
          : toggle === "vendors"
          ? selected === "All Vendors"
            ? `${REPORTS_URL}/v2/chargerallagencyreport?start=${startTime}&end=${endTime}&token=${token}${
                format && "&format=" + format
              }`
            : `${REPORTS_URL}/v2/chargeragencyreport2?agencyId=${
                (vendorsData?.data || []).find(
                  (el: any) => el.name === selected
                )?.id
              }&start=${startTime}&end=${endTime}&format=${format}&token=${token}`
          : toggle === "users"
          ? `${REPORTS_URL}/v2/cuguserreport?userId=${
              (cugUsersData?.data?.userDetails || []).find(
                (el: any) => el.firstName === selected.split(" ")[0]
              )?._id
            }&start=${startTime}&end=${endTime}&token=${token}&format=${format}`
          : `${REPORTS_URL}/v2/cugreport?cugId=${
              (cugsData?.data?.groups || []).find(
                (el: any) => el.name === selected
              )?._id
            }&start=${startTime}&end=${endTime}&token=${token}&format=${format}`;
    } else {
      if (toggle === "all-vehicles") {
        url = `${REPORTS_URL}/v2/report/summary?startTime=${startTime}&endTime=${endTime}&type=${platform}&token=${token}${
          format ? "&format=" + format : ""
        }`;
      } else {
        switch (type) {
          case "Summary":
            url = `${REPORTS_URL}/v2/report/summary?startTime=${startTime}&endTime=${endTime}&token=${token}&vin=${selected}&type=${platform}${
              format ? "&format=" + format : ""
            }`;
            break;
          case "Overspeed":
            url = `${REPORTS_URL}/v2/report/${selected}/overspeeding?startTime=${startTime}&endTime=${endTime}&token=${token}${
              format ? "&format=" + format : ""
            }`;
            break;
          case "Geofence":
            url = `${REPORTS_URL}/v2/report/${selected}/geofence?startTime=${startTime}&endTime=${endTime}&token=${token}${
              format ? "&format=" + format : ""
            }`;
            break;
          case "Trips":
            url = `${REPORTS_URL}/tripsreport?vin=${selected}&start=${startTime}&end=${endTime}&token=${token}&company=${company}${
              format ? "&format=" + format : ""
            }`;
            break;
          case "Mileage":
            url = `${REPORTS_URL}/v1/report/${selected}/trips-mileage?startTime=${startTime}&endTime=${endTime}`;
            break;
          default:
            break;
        }
      }
    }

    authorizedFetch(url)
      .then((data) => {
        setLoader(false);
        if (data.file) {
          const formattedTime = (time: any) =>
            moment(time).format("DD-MM-YYYY");

          const fileUrl = data.file;
          let fileName = `${
            isCMS && toggle === "chargers"
              ? selectedChargers.length === 1
                ? selectedChargers[0]
                : "Batch"
              : selected || "All"
          }_${type}_Report_${formattedTime(startTime)}-${formattedTime(
            endTime
          )}.${format === "excel" ? "xlsx" : "pdf"}`;

          saveAs(fileUrl, fileName);
          snackbar.success("Report downloaded");

          let payload = {
            name: fileName,
            createdAt: moment().valueOf(),
            url: fileUrl,
          };
          if (isCMS) dispatch(saveReport(payload));
          else dispatch(saveVehicleReport(payload));
        } else if (data.message) {
          snackbar.error(data.message);
        } else {
          const array = selectedChargers.includes("All Chargers")
            ? data.data
            : data.response.info;
          setDialog({
            open: true,
            data: {
              name: selected,
              timeRange: [startTime, endTime],
              array: array.map((cur: any, i: any) => ({ ...cur, id: i })),
              downloadUrl: selectedChargers.includes("All Chargers")
                ? `${REPORTS_URL}/v2/chargercumulativereport?start=${startTime}&end=${endTime}&token=${token}`
                : selectedChargers.length === 1
                ? `${REPORTS_URL}/v2/chargerindividualreport?uid=${selected}&start=${startTime}&end=${endTime}&token=${token}&master=${masterView}`
                : `${REPORTS_URL}/v2/chargerbatchreport?start=${startTime}&end=${endTime}&token=${token}&chargerUIDs=${selectedChargers.join(
                    ", "
                  )}&master=${masterView}`,
            },
          });
        }
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
        snackbar.error("An error occurred");
      });
  }

  useEffect(() => {
    setSelected(null);
    setSelectedChargers([]);
    if (toggle === "chargers") setFormat("pdf");
  }, [toggle]);

  useEffect(() => {
    setToggle(
      isCMS ? (isCUGMonitorAccount ? "users" : "vendors") : "all-vehicles"
    );
  }, [isCMS, isCUGMonitorAccount]);

  useEffect(() => {
    setPlatform(isFMS ? "LEASE" : "RETAIL");
  }, [isFMS]);

  return (
    <>
      <ReportDialog
        open={dialog.open}
        data={dialog.data}
        onClose={() => setDialog((prev: any) => ({ ...prev, open: false }))}
      />
      <ReportPreviewDialog
        open={previewDialog.open}
        handleClose={() => setPreviewDialog({ ...previewDialog, open: false })}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            px: 2.5,
            py: 3,
            backgroundColor: isDarkMode ? "#000" : "#3D3D3D",
            fontWeight: 500,
            color: "#fff",
          }}
        >
          Download Reports
        </Box>
        <Box
          flexGrow={1}
          overflow="auto"
          px={2.5}
          py={3}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box
            display="grid"
            gridTemplateColumns="auto 1fr"
            gap={3}
            alignItems="center"
          >
            <Typography className="label" sx={{ mb: "0 !important", mr: 3.5 }}>
              Pick a Range
            </Typography>
            <RangePicker range={range} setRange={setRange} />
            <Typography className="label" sx={{ mb: "0 !important", mr: 4.5 }}>
              Reports For
            </Typography>
            <FormControl component="fieldset">
              {isTVS ? (
                <RadioGroup row value={"users"}>
                  <FormControlLabel
                    value="users"
                    control={<Radio checked />}
                    label="Users"
                  />
                </RadioGroup>
              ) : isCMS ? (
                isCUGMonitorAccount ? (
                  <RadioGroup
                    row
                    value={toggle}
                    onChange={(e) => setToggle(e.target.value)}
                  >
                    <FormControlLabel
                      value="users"
                      control={<Radio />}
                      label="Users"
                    />
                    <FormControlLabel
                      value="cugs"
                      control={<Radio />}
                      label="CUGs"
                    />
                  </RadioGroup>
                ) : (
                  <RadioGroup
                    row
                    value={toggle}
                    onChange={(e) => setToggle(e.target.value)}
                  >
                    <FormControlLabel
                      value="vendors"
                      control={<Radio />}
                      label="Vendors"
                    />
                    <FormControlLabel
                      value="chargers"
                      control={<Radio />}
                      label="Chargers"
                    />
                  </RadioGroup>
                )
              ) : (
                <RadioGroup
                  value={toggle}
                  onChange={(e) => setToggle(e.target.value)}
                >
                  <FormControlLabel
                    value="all-vehicles"
                    control={<Radio />}
                    label="All Vehicles"
                  />
                  <FormControlLabel
                    value="specific-vehicle"
                    control={<Radio />}
                    label="Specific Vehicle"
                  />
                </RadioGroup>
              )}
            </FormControl>
            <Collapse in={isCMS || toggle === "specific-vehicle"}>
              <Typography className="label" sx={{ mb: "0 !important", mr: 2 }}>
                Select{" "}
                {isTVS
                  ? "Users"
                  : isCMS
                  ? toggle === "chargers"
                    ? "Charger(s)"
                    : toggle === "vendors"
                    ? "Vendor"
                    : toggle === "users"
                    ? "Users"
                    : "CUG(s)"
                  : "Vehicle"}
              </Typography>
            </Collapse>
            <Collapse in={isCMS || toggle === "specific-vehicle"}>
              <Selector
                {...{
                  loading: !isCMS
                    ? isFMS
                      ? fmsVehiclesLoading
                      : vmsVehiclesLoading
                    : toggle === "chargers"
                    ? chargersLoading
                    : toggle === "vendors"
                    ? vendorsLoading
                    : toggle === "users"
                    ? cugUsersLoading
                    : cugsLoading,
                  isCMS,
                  isFMS,
                  isCUGMonitorAccount,
                  toggle,
                  chargerOptions,
                  vendorOptions,
                  usersOptions,
                  cugsOptions,
                  fmsVehicleOptions,
                  vmsVehicleOptions,
                  selected,
                  setSelected,
                  selectedChargers,
                  setSelectedChargers,
                  search,
                  setSearch,
                }}
              />
            </Collapse>
            <Typography className="label" sx={{ mb: "0 !important", mr: 9 }}>
              Format
            </Typography>
            <RadioGroup
              row
              value={format}
              onChange={(e) => setFormat(e.target.value)}
            >
              <FormControlLabel
                value="excel"
                control={<Radio />}
                label="XLSX"
              />
              <FormControlLabel value="pdf" control={<Radio />} label="PDF" />
            </RadioGroup>
          </Box>
          {isCMS ? (
            <Box display="flex" justifyContent="end">
              <Button
                sx={{ height: 40 }}
                // variant="outlined"
                variant="contained"
                onClick={() => downloadReports()}
                disabled={
                  isCMS && toggle === "chargers"
                    ? selectedChargers.length === 0
                    : !selected
                }
              >
                Download Now
              </Button>
            </Box>
          ) : (
            <Box maxWidth={"100%"}>
              <Typography
                className="label"
                sx={{ mb: 1.5, gridColumn: "span 2" }}
              >
                Download Reports
              </Typography>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: 1.5,
                }}
              >
                <Button
                  variant="contained"
                  // color={toggle === "all-vehicles" ? "primary" : "inherit"}
                  startIcon={<DonutLarge />}
                  disabled={toggle === "specific-vehicle" && !selected}
                  onClick={() => downloadReports("Summary")}
                >
                  Summary
                </Button>
                {toggle === "all-vehicles" || !selected ? null : (
                  <>
                    <Button
                      variant="contained"
                      // color="inherit"
                      startIcon={<Speed />}
                      disabled={toggle === "all-vehicles" || !selected}
                      onClick={() => downloadReports("Overspeed")}
                    >
                      Overspeed
                    </Button>
                    <Button
                      variant="contained"
                      // color="inherit"
                      startIcon={<PinDrop />}
                      disabled={toggle === "all-vehicles" || !selected}
                      onClick={() => downloadReports("Geofence")}
                    >
                      Geofence
                    </Button>
                    <Button
                      variant="contained"
                      // color="inherit"
                      startIcon={<Map />}
                      disabled={toggle === "all-vehicles" || !selected}
                      onClick={() => downloadReports("Trips")}
                    >
                      Trips
                    </Button>
                    {format === "excel" &&
                      company?._id === "64d1e10439a798de3db5aec6" && (
                        <Button
                          variant="contained"
                          // color="inherit"
                          startIcon={<ElectricBike />}
                          disabled={toggle === "all-vehicles" || !selected}
                          onClick={() => downloadReports("Mileage")}
                        >
                          Mileage
                        </Button>
                      )}
                  </>
                )}
              </Box>
            </Box>
          )}
          {/* <Button
              sx={{ height: 40, ml: 2 }}
              variant="contained"
            // onClick={() => downloadReports()}
            >
              Preview Report
            </Button> */}
        </Box>
      </Box>
    </>
  );
};

export default Drawer;
