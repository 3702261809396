import { AddCircleOutline, DeleteOutline } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import TableComponent from "components/Table";
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import { authorizedFetch } from "utils";
import { RETAIL_URL } from "utils/constants";
import AssignDialog from "./AssignDialog";
import UnAssignVehicles from "./UnAssignVehicles";
import Search from "components/Search";

const AssignedVehicles = ({ distributor }: any) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  const [assignDialog, setAssignDialog] = useState(false);
  const [unassignVehicles, setUnassignVehicles] = useState({
    open: false,
    data: [],
  });
  const url = `${RETAIL_URL}/distribution/distributor/${
    distributor?._id
  }/assigned-vehicles?first=${pageSize}&skip=${
    pageSize * (page - 1)
  }&search=${search}`;
  const { isLoading, data, refetch } = useQuery(
    ["getAssignedVehicles", distributor?._id, page, pageSize, search],
    () => authorizedFetch(url)
  );

  let rowCount = data?.meta.totalCount || 0;
  let rows = data?.data?.constructor === Array ? data.data : [];

  return (
    <Box>
      <AssignDialog
        open={assignDialog}
        handleClose={() => setAssignDialog(false)}
        distributor={distributor}
      />
      <Box
        sx={{
          px: 3,
          mb: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body2"
          sx={{ color: (theme) => theme.customColors.grey }}
        >
          {isLoading ? "Loading..." : `Total Vehicles Assigned: ${rowCount}`}
        </Typography>
        <Search
          handleSearch={(input) => setSearch(input)}
          persist
          enableClear
        />
      </Box>
      <Box
        sx={{
          px: 3,
          mb: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          color="inherit"
          sx={{ textTransform: "none" }}
          startIcon={<AddCircleOutline />}
          onClick={() => setAssignDialog(true)}
        >
          Assign New
        </Button>
      </Box>
      <TableComponent
        idKey="_id"
        small
        selectable
        selectOnClick
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        loading={isLoading}
        rowCount={rowCount}
        serverSidePagination={true}
        activePage={page}
        activePageSize={pageSize}
        onPageChange={(value) => setPage(value)}
        onPageSizeChange={(value) => setPageSize(value)}
        rows={rows}
        columns={[
          { key: "vin", label: "VIN" },
          {
            key: "createdAt",
            label: "Created At",
            format: (value) => moment(value).format("MMM DD, YYYY HH:mm"),
          },
        ]}
        toolbar={() => (
          <Button
            startIcon={<DeleteOutline />}
            onClick={() => {
              setUnassignVehicles({
                open: true,
                data: selectedRows,
              });
            }}
          >
            Unassign
          </Button>
        )}
      />
      <UnAssignVehicles
        open={unassignVehicles.open}
        handleClose={() => {
          setUnassignVehicles({ ...unassignVehicles, open: false });
          refetch();
        }}
        selected={unassignVehicles.data}
        vendor={distributor}
      />
    </Box>
  );
};

export default AssignedVehicles;
