import {
  AccountBalanceWalletOutlined,
  BoltOutlined,
  ContentCopy,
  CurrencyRupee,
  DeleteOutline,
  EditOutlined,
  EventAvailableOutlined,
  HighlightOff,
  NotificationAddOutlined,
  Payment,
  PowerOutlined,
  Sort,
  Timer,
  Troubleshoot,
  PlayArrowOutlined,
  PauseOutlined,
  GroupsOutlined,
  PercentOutlined,
  OpenInNewOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Switch,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import TableComponent from "components/Table";
import { format } from "date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useQuery } from "react-query";
import { queryClient } from "index";
import { useSelector } from "react-redux";
import {
  GlobalState,
  authorizedFetch,
  drawer,
  getDarkModePreference,
  getDuration,
  getPermissions,
  snackbar,
  titleCase,
} from "utils";
import { BOLT_URL, WALLET_URL } from "utils/constants";
import ConfirmRefund from "./ConfirmRefund";
import NotificationDialog from "./NotificationDialog";
import PaymentDialog from "./PaymentDialog";
import RefundDialog from "./RefundDialog";
import CUGDialog from "./CUGDialog";
import UpdateDialog from "./UpdateDialog";

interface Props {
  type: "user" | "host" | "group";
  row?: any;
  group?: {
    [key: string]: any;
  };
}

const DrawerContent: React.FC<Props> = ({ type, row, group }) => {
  const { company } = useSelector((state: GlobalState) => state.global);
  const isCUGMonitorAccount = company?.type === "CUG_VENDOR";
  let { user } = row || {};

  const { canWrite } = getPermissions("charger:users");
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );
  const [hasWallet, setHasWallet] = useState(false);
  const [anomaly, setAnomaly] = useState(false);

  const [notificationDialog, setNotificationDialog] = useState<any>({
    open: false,
    data: [],
  });
  const [paymentDialog, setPaymentDialog] = useState<any>({
    open: false,
    data: [],
  });
  const [refundDialog, setRefundDialog] = useState<any>({
    open: false,
    user: {},
    data: {},
  });
  const [confirmRefundDialog, setConfirmRefundDialog] = useState<any>({
    open: false,
    data: [],
    user: {},
  });
  const [cugDialog, setCugDialog] = useState<any>({
    open: false,
    isEditMode: false,
    data: null,
  });
  const [updateDialog, setUpdateDialog] = useState<any>({
    open: false,
    data: null,
    type: "",
  });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const history = useHistory();

  const transactionsUrl = `${WALLET_URL}/v2/wallet/getTransactions?customerId=${user?._id || ""
    }&pageNumber=${page}&pageSize=${pageSize}`;

  const {
    isLoading: transactionLoading,
    data: transactionData,
    refetch: refetchTransactions,
  } = useQuery(
    ["Get Transactions", user?._id, page, pageSize],
    () => authorizedFetch(transactionsUrl),
    { enabled: !isCUGMonitorAccount }
  );

  const refundsUrl = `${WALLET_URL}/v1/wallet/getRefundRequest?customerId=${user?._id}&client=DASHBOARD`;
  const {
    isLoading: refundsLoading,
    data: refundsData,
    refetch: refetchRefunds,
  } = useQuery(
    ["Get Refunds", user?._id || ""],
    () => authorizedFetch(refundsUrl),
    {
      enabled: !isCUGMonitorAccount,
    }
  );

  const walletUrl = `${WALLET_URL}/v2/wallet/getWallet?customerId=${user?._id}&client=DASHBOARD`;
  const { data: walletData, refetch: refetchWallet } = useQuery(
    ["Get Wallet Details", user?._id],
    () => authorizedFetch(walletUrl),
    {
      enabled: !isCUGMonitorAccount,
    }
  );

  // let userEmail = useSelector((state: GlobalState) => state.global.user.email);
  // const shouldShowRefundFlow = userEmail === "walletmanager@bolt.earth";
  const shouldShowRefundFlow = false;

  const formattedName = `${(isCUGMonitorAccount ? row?.firstName : user?.firstName) || ""
    } ${(isCUGMonitorAccount ? row?.lastName : user?.lastName) || ""}`;
  const formattedPhone = `${isCUGMonitorAccount ? row?.phone?.slice(0, 3) : user?.phone?.slice(0, 3)
    } ${isCUGMonitorAccount ? row?.phone?.slice(3) : user?.phone?.slice(3)}`;

  return (
    <>
      <CUGDialog
        open={cugDialog.open}
        handleClose={() => {
          setCugDialog((prev: any) => ({
            ...prev,
            open: !cugDialog.open,
          }));
          queryClient.resetQueries("getUserGroup");
          drawer.close();
        }}
        isEditMode={cugDialog.isEditMode}
        data={cugDialog.data}
      />
      <UpdateDialog
        open={updateDialog.open}
        handleClose={() =>
          setUpdateDialog({ ...updateDialog, open: false, type: "" })
        }
        group={updateDialog.data}
        type={updateDialog.type}
      />
      <NotificationDialog
        open={notificationDialog.open}
        handleClose={() =>
          setNotificationDialog({ ...notificationDialog, open: false })
        }
        data={notificationDialog.data}
      />
      <PaymentDialog
        open={paymentDialog.open}
        handleClose={() => setPaymentDialog({ ...paymentDialog, open: false })}
        data={paymentDialog.data}
      />
      <RefundDialog
        open={refundDialog.open}
        handleClose={() => setRefundDialog({ ...refundDialog, open: false })}
        data={refundDialog.data}
        user={refundDialog.user}
        refetchTransactions={refetchTransactions}
        refetchWallet={refetchWallet}
      />
      <ConfirmRefund
        open={confirmRefundDialog.open}
        data={confirmRefundDialog.data}
        user={confirmRefundDialog.user}
        handleClose={() => {
          setConfirmRefundDialog({
            ...confirmRefundDialog,
            open: false,
          });
        }}
        refetchRefunds={refetchRefunds}
      />
      <Box
        key={
          type === "user"
            ? isCUGMonitorAccount
              ? row?._id
              : user?.id
            : group?._id
        }
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            px: 3,
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: isDarkMode ? "#000" : "#3D3D3D",
            fontWeight: 500,
            color: "#fff",
          }}
        >
          {["user", "host"].includes(type) && (
            <Box display="flex" alignItems="center">
              {formattedName && (
                <>
                  {formattedName}
                  <Divider
                    flexItem
                    orientation="vertical"
                    sx={{ mx: 1, opacity: 0.2 }}
                  />
                </>
              )}
              {formattedPhone}
            </Box>
          )}

          {type === "group" && (
            <Box display="flex" alignItems="center">
              {group?.name || "-"}

              <Box
                sx={{
                  ml: 2.5,
                  px: 1.5,
                  py: 0.5,
                  borderRadius: 0.5,
                  color: "#fff",
                  fontSize: 12,
                  lineHeight: "14px",
                  fontWeight: 500,
                  bgcolor:
                    group?.status === "ACTIVE"
                      ? "success.main"
                      : group?.status === "INACTIVE"
                        ? "#FFCC00"
                        : "error.main",
                }}
              >
                {group?.status}
              </Box>
            </Box>
          )}
          <Box display="flex">
            {!isCUGMonitorAccount && type === "group" && canWrite && (
              <Tooltip title="Add Funds">
                <IconButton
                  sx={{ mr: 1 }}
                  children={<CurrencyRupee />}
                  color="inherit"
                  size="small"
                  onClick={() =>
                    setUpdateDialog({ open: true, data: group, type: "FUND" })
                  }
                />
              </Tooltip>
            )}
            {!isCUGMonitorAccount && type === "group" && canWrite && (
              <Tooltip
                title={
                  group?.status === "ACTIVE" ? "Deactivate CUG" : "Activate CUG"
                }
              >
                <IconButton
                  sx={{ mr: 1 }}
                  children={
                    group?.status === "ACTIVE" ? (
                      <PauseOutlined />
                    ) : (
                      <PlayArrowOutlined />
                    )
                  }
                  color="inherit"
                  size="small"
                  onClick={() =>
                    setUpdateDialog({ open: true, data: group, type: "STATUS" })
                  }
                />
              </Tooltip>
            )}
            {type === "group" && canWrite && (
              <Tooltip title="Go to Bookings">
                <IconButton
                  sx={{ mr: 1 }}
                  children={<OpenInNewOutlined />}
                  color="inherit"
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push({
                      pathname: "/chargers",
                      search: group?.name,
                    });
                  }}
                />
              </Tooltip>
            )}
            {!isCUGMonitorAccount && type === "group" && canWrite && (
              <Tooltip title="Edit">
                <IconButton
                  sx={{ mr: 1 }}
                  children={<EditOutlined />}
                  color="inherit"
                  size="small"
                  onClick={() =>
                    setCugDialog({ open: true, isEditMode: true, data: group })
                  }
                />
              </Tooltip>
            )}
            {!isCUGMonitorAccount && type === "group" && canWrite && (
              <Tooltip title="Delete">
                <IconButton
                  sx={{ mr: 1 }}
                  children={<DeleteOutline />}
                  color="inherit"
                  size="small"
                  onClick={() =>
                    setUpdateDialog({ open: true, data: group, type: "DELETE" })
                  }
                />
              </Tooltip>
            )}
            {type === "user" && !isCUGMonitorAccount && canWrite && (
              <>
                {hasWallet && (
                  <>
                    {shouldShowRefundFlow && (
                      <Button
                        variant="contained"
                        size="small"
                        sx={{ mr: 1 }}
                        onClick={() =>
                          setRefundDialog({
                            open: true,
                            user: user,
                            data: "",
                          })
                        }
                      >
                        Add Funds
                      </Button>
                    )}
                  </>
                )}
                <IconButton
                  children={<NotificationAddOutlined />}
                  color="inherit"
                  size="small"
                  sx={{ mr: 1 }}
                  onClick={() =>
                    setNotificationDialog({ open: true, data: user })
                  }
                />
              </>
            )}
            {type === "host" && (
              <Tooltip title="Payment Details">
                <IconButton
                  children={<Payment />}
                  color="inherit"
                  size="small"
                  sx={{ mr: 1 }}
                  onClick={() => setPaymentDialog({ open: true, data: user })}
                />
              </Tooltip>
            )}
            <Tooltip title="Close">
              <IconButton
                children={<HighlightOff />}
                color="inherit"
                size="small"
                onClick={() => {
                  drawer.close();
                  setAnomaly(false);
                }}
              />
            </Tooltip>
          </Box>
        </Box>
        <Paper sx={{ flexGrow: 1, overflow: "auto", borderRadius: 0 }}>
          {type === "user" ? (
            <UserContent
              user={isCUGMonitorAccount ? row : user}
              SetRefundDialog={setRefundDialog}
              hasWallet={hasWallet}
              setHasWallet={setHasWallet}
              transactionData={transactionData}
              transactionLoading={transactionLoading}
              confirmRefundDialog={confirmRefundDialog}
              setConfirmRefundDialog={setConfirmRefundDialog}
              refundsData={refundsData}
              refundsLoading={refundsLoading}
              walletData={walletData}
              anomaly={anomaly}
              setAnomaly={setAnomaly}
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              isCUGMonitorAccount={isCUGMonitorAccount}
            />
          ) : type === "host" ? (
            <HostContent
              user={user}
              hasWallet={hasWallet}
              setHasWallet={setHasWallet}
              confirmRefundDialog={confirmRefundDialog}
              setConfirmRefundDialog={setConfirmRefundDialog}
              refundsData={refundsData}
              refundsLoading={refundsLoading}
              walletData={walletData}
              anomaly={anomaly}
              setAnomaly={setAnomaly}
            />
          ) : (
            <UserGroupContent group={group} />
          )}
        </Paper>
      </Box>
    </>
  );
};

const UserContent: React.FC<{
  user: any;
  SetRefundDialog: React.Dispatch<any>;
  hasWallet: boolean;
  setHasWallet: React.Dispatch<React.SetStateAction<boolean>>;
  transactionData: any;
  transactionLoading: boolean;
  confirmRefundDialog: any;
  setConfirmRefundDialog: React.Dispatch<any>;
  refundsData: any;
  refundsLoading: boolean;
  walletData: any;
  anomaly: any;
  setAnomaly: any;
  page: any;
  setPage: any;
  pageSize: any;
  setPageSize: any;
  isCUGMonitorAccount: boolean;
}> = ({
  user,
  SetRefundDialog,
  hasWallet,
  setHasWallet,
  transactionData,
  transactionLoading,
  setConfirmRefundDialog,
  refundsLoading,
  refundsData,
  walletData,
  anomaly,
  setAnomaly,
  page,
  setPage,
  pageSize,
  setPageSize,
  isCUGMonitorAccount,
}) => {
    const [stats, setStats] = useState<any>(null);

    const [refundsList, setRefundsList] = useState([]);
    const [walletBalance, setWalletBalance] = useState(0);

    const [tab, setTab] = useState(0);
    const [paymentStatus, setPaymentStatus] = useState("");

    let userEmail = useSelector((state: GlobalState) => state.global.user.email);
    const shouldShowRefundFlow = userEmail === "walletmanager@bolt.earth";

    const bookingUrl = `${BOLT_URL}/company/users/bookings?userId=${user?._id}&paymentStatus=${paymentStatus}&anomaly=${anomaly}`;
    const { isLoading: bookingsLoading, data: bookingsData } = useQuery(
      ["getUsersBookings", user?._id, paymentStatus, anomaly],
      () =>
        authorizedFetch(bookingUrl, {
          headers: {
            token: 1234,
          },
        }),
      {
        onError: () => snackbar.error("Error fetching data"),
        enabled: !isCUGMonitorAccount,
      }
    );
    const userStatsUrl = `${BOLT_URL}/company/v2/usergroup/users/${user?._id}/stats`;
    const { isLoading: userStatsLoading, data: userStatsData } = useQuery(
      ["getUsersStatsMonitor", user?._id],
      () =>
        authorizedFetch(userStatsUrl, {
          headers: {
            monitoringAccount: isCUGMonitorAccount,
          },
        }),
      {
        enabled: isCUGMonitorAccount,
      }
    );

    useEffect(() => {
      if (walletData) {
        if (walletData.status === 400) {
          setHasWallet(false);
        }
        if (walletData.status === 200) {
          setHasWallet(true);
        }
        setWalletBalance(walletData?.data?.walletBalance);
      }
    }, [walletData, setHasWallet]);

    useEffect(() => {
      if (bookingsData) {
        setStats(bookingsData?.data?.stats);
      } else {
        setStats(null);
      }
    }, [bookingsData]);

    useEffect(() => {
      if (refundsData && refundsData.data) {
        setRefundsList(refundsData.data);
      } else {
        setRefundsList([]);
      }
    }, [refundsData]);

    return (
      <>
        {bookingsLoading && <LinearProgress />}
        <Box px={2.5} pt={2}>
          <Box
            mb={1.5}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Stats</Typography>
            {/* <RangePicker /> */}
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: 1,
              "& > div": {
                width: 1,
                p: 1.5,
                display: "flex",
                border: 1,
                borderRadius: "3px",
                borderColor: (theme) => theme.customColors.border,
                "& .MuiAvatar-root": {
                  mr: 1,
                },
                "& .info": {
                  display: "flex",
                  flexDirection: "column",
                  "& .label": {
                    fontSize: 17,
                    fontWeight: 600,
                    color: "text.primary",
                    lineHeight: "1.15em",
                  },
                  "& .value": {
                    fontSize: 12,
                    color: "text.secondary",
                  },
                },
              },
            }}
          >
            {!isCUGMonitorAccount ? (
              <>
                <Box>
                  <Avatar variant="icon">
                    <EventAvailableOutlined />
                  </Avatar>
                  <Box className="info">
                    <span className="label">
                      {stats?.totalBookings
                        ? stats.totalBookings.toLocaleString()
                        : "-"}
                    </span>
                    <span className="value">Bookings</span>
                  </Box>
                </Box>
                <Box>
                  <Avatar variant="icon">
                    <Timer />
                  </Avatar>
                  <Box className="info">
                    <span className="label">
                      {stats?.totalBookingDurationInHours
                        ? getDuration(stats?.totalBookingDurationInHours * 60)
                        : "-"}
                    </span>
                    <span className="value">Booking Duration</span>
                  </Box>
                </Box>
                <Box>
                  <Avatar variant="icon">
                    <CurrencyRupee />
                  </Avatar>
                  <Box className="info">
                    <span className="label">
                      {typeof stats?.totalAmount === "number"
                        ? "₹" +
                        stats.totalAmount.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })
                        : "-"}
                    </span>
                    <span className="value">Total Spending</span>
                  </Box>
                </Box>
                <Box>
                  <Avatar variant="icon">
                    <PowerOutlined />
                  </Avatar>
                  <Box className="info">
                    <span className="label">
                      {stats?.totalChargingDurationInHours
                        ? getDuration(stats.totalChargingDurationInHours * 60)
                        : "-"}
                    </span>
                    <span className="value">Charging Duration</span>
                  </Box>
                </Box>
                <Box>
                  <Avatar variant="icon">
                    <BoltOutlined />
                  </Avatar>
                  <Box className="info">
                    <span className="label">
                      {typeof stats?.totalEnergyConsumed === "number"
                        ? stats.totalEnergyConsumed.toLocaleString() + " kWh"
                        : "-"}
                    </span>
                    <span className="value">Energy Consumed</span>
                  </Box>
                </Box>
                <Box>
                  <Avatar variant="icon">
                    <AccountBalanceWalletOutlined />
                  </Avatar>
                  <Box className="info">
                    <span className="label">
                      {hasWallet
                        ? typeof walletBalance === "number"
                          ? "₹" +
                          walletBalance.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })
                          : "No Wallet Found"
                        : "No Wallet Found"}
                    </span>
                    <span className="value">Wallet Balance</span>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Box>
                  <Avatar variant="icon">
                    <GroupsOutlined />
                  </Avatar>
                  <Box className="info">
                    <span className="label">
                      {userStatsData?.data?.activeCUGCount
                        ? userStatsData.data.activeCUGCount
                        : "-"}
                    </span>
                    <span className="value">Active CUGs</span>
                  </Box>
                </Box>
                <Box>
                  <Avatar variant="icon">
                    <EventAvailableOutlined />
                  </Avatar>
                  <Box className="info">
                    <span className="label">
                      {userStatsData?.data?.totalCUGBookings
                        ? userStatsData.data.totalCUGBookings
                        : "-"}
                    </span>
                    <span className="value">Total Bookings</span>
                  </Box>
                </Box>
                <Box>
                  <Avatar variant="icon">
                    <PercentOutlined />
                  </Avatar>
                  <Box className="info">
                    <span className="label">
                      {userStatsData?.data?.totalCUGDiscounts
                        ? "₹" + userStatsData.data.totalCUGDiscounts.toFixed(2)
                        : "-"}
                    </span>
                    <span className="value">Total Discount</span>
                  </Box>
                </Box>
                <Box>
                  <Avatar variant="icon">
                    <BoltOutlined />
                  </Avatar>
                  <Box className="info">
                    <span className="label">
                      {userStatsData?.data?.totalCUGEnergyConsumed
                        ? userStatsData.data.totalCUGEnergyConsumed + " kWh"
                        : "-"}
                    </span>
                    <span className="value">Unit Consumed</span>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>

        {!isCUGMonitorAccount ? (
          <>
            <Box mt={3.75} mb={2} mx={2.5}>
              <Tabs
                className="dense"
                value={tab}
                onChange={(e, tab) => setTab(tab)}
              >
                <Tab label="Recent Bookings" />
                <Tab label="Refund Requests" />
                {shouldShowRefundFlow && <Tab label="Wallet Transactions" />}
              </Tabs>
            </Box>
            {tab === 0 && (
              <Box
                sx={{
                  width: 1,
                  px: 2,
                  pb: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Tooltip title="Payment Status" placement="top">
                  <FormControl sx={{ mr: 1.5 }}>
                    <Select
                      className="rounded"
                      size="small"
                      sx={{ width: "auto" }}
                      value={paymentStatus}
                      onChange={(e: any) => setPaymentStatus(e.target.value)}
                      displayEmpty
                      renderValue={(selected) =>
                        selected === ""
                          ? "Status: All"
                          : selected === "PAYMENT_COMPLETE"
                            ? "Status: Paid"
                            : "Status: Unpaid"
                      }
                    >
                      <MenuItem value="">All</MenuItem>
                      <MenuItem value="PAYMENT_COMPLETE">Paid</MenuItem>
                      <MenuItem value="PAYMENT_INCOMPLETE">Unpaid</MenuItem>
                    </Select>
                  </FormControl>
                </Tooltip>
                <Tooltip title="Anomaly Detection" placement="top">
                  <Box
                    sx={{
                      p: 0.5,
                      px: 1,
                      borderRadius: 10,
                      height: 40,
                      border: (theme) => `1px solid ${theme.palette.divider}`,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <label
                      htmlFor="anomaly"
                      style={{ height: 20, cursor: "pointer", marginRight: 4 }}
                    >
                      <Troubleshoot fontSize="small" color="action" />
                    </label>
                    <Switch
                      id="anomaly"
                      size="small"
                      checked={anomaly}
                      onChange={() => setAnomaly(!anomaly)}
                    />
                  </Box>
                </Tooltip>
              </Box>
            )}
            {tab === 0 ? (
              <TableComponent
                px={2.5}
                loading={bookingsLoading}
                rows={bookingsData?.data?.userBookings[0]?.bookings || []}
                columns={[
                  {
                    key: "bookingId",
                    label: "Booking ID",
                    Render: (row) => {
                      // last 8 digits of booking ID followed by a copy icon button

                      return (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Tooltip title={row?.bookingId} placement="top">
                            <span>...{row?.bookingId?.slice(-8)}</span>
                          </Tooltip>
                          <IconButton
                            size="small"
                            sx={{ ml: 1 }}
                            onClick={() => {
                              navigator.clipboard.writeText(row?.bookingId);
                              snackbar.success("Booking ID copied to clipboard");
                            }}
                          >
                            <ContentCopy
                              sx={{
                                color: (theme) => theme.customColors.action,
                                fontSize: 16,
                              }}
                            />
                          </IconButton>
                        </Box>
                      );
                    },
                  },
                  {
                    key: "chargingStart",
                    label: "Start Time",
                    format: (value) =>
                      moment(value).format("MMM DD, YYYY, hh:mm A"),
                  },
                  {
                    key: "bookingDuration",
                    label: "Booking Duration",
                    format: (value) => getDuration(value * 60),
                  },
                  {
                    key: "actualDuration",
                    label: "Charging Duration",
                    Render: (row) => getDuration(row.chargingDuration * 60),
                  },
                  {
                    key: "energyConsumed",
                    label: "Energy Utilised",
                    Render: (row) => <LiveEnergy booking={row} />,
                  },
                  {
                    key: "amount",
                    label: "Amount",
                    format: (value) =>
                      typeof value === "number" ? "₹" + value : "-",
                  },
                  {
                    key: "paymentType",
                    label: "Mode of Payment",
                    Render: (row) => {
                      return <Box>{row?.paymentType || "-"}</Box>;
                    },
                  },
                  {
                    key: "paymentStatus",
                    label: "Payment",
                    // Render: (row) => {
                    //   return <Box>{row?.paymentStatus || "-"}</Box>;
                    // },
                    Render: (row) => (
                      <Avatar
                        variant="status"
                        className={
                          row?.paymentStatus === "PAYMENT_INCOMPLETE"
                            ? "red"
                            : row?.paymentStatus === "PAYMENT_COMPLETE"
                              ? "green"
                              : "yellow"
                        }
                      >
                        {titleCase(row?.paymentStatus || "-")
                          .split("_")
                          .join(" ")}
                      </Avatar>
                    ),
                  },
                  {
                    key: "bookingStatus",
                    label: "Booking Status",
                    Render: (row) => (
                      <Avatar
                        variant="status"
                        className={
                          row.bookingStatus === "CANCELLED"
                            ? "red"
                            : row.bookingStatus === "PAYMENT_PENDING"
                              ? "yellow"
                              : row.bookingStatus === "ENDED"
                                ? "blue"
                                : ""
                        }
                      >
                        {titleCase(
                          (row.bookingStatus === "ENDED"
                            ? "TERMINATED"
                            : row.bookingStatus === "TERMINATED"
                              ? "ENDED"
                              : row.bookingStatus === "CANCELLED"
                                ? "CANCELLED"
                                : row.bookingStatus === "PAYMENT_PENDING"
                                  ? "PAYMENT_PENDING"
                                  : row.bookingStatus === "BOOKED"
                                    ? "BOOKED"
                                    : "N/A"
                          )
                            .split("_")
                            .join(" ")
                        )}
                      </Avatar>
                    ),
                  },
                ]}
                small
                hideDivider
              />
            ) : tab === 1 ? (
              <TableComponent
                px={2.5}
                loading={refundsLoading}
                rows={refundsList}
                columns={[
                  {
                    key: "bookingId",
                    label: "Booking ID",
                  },
                  {
                    key: "action",
                    label: "Actions",
                    Render: (row) => (
                      <Button
                        sx={{ ml: -1 }}
                        onClick={() =>
                          setConfirmRefundDialog({
                            open: true,
                            data: row,
                            user: user,
                          })
                        }
                        disabled={row.status === "COMPLETE"}
                      >
                        CONFIRM
                      </Button>
                    ),
                  },
                  {
                    key: "amount",
                    label: "Amount",

                    format: (value) => ` ₹ ${value}`,
                  },
                  {
                    key: "timeStamp",
                    label: "Date",
                    format: (value) =>
                      moment(value).format("MMM DD yyyy, hh:mm A"),
                  },
                  {
                    key: "status",
                    label: "Status",
                    Render: (row) => (
                      <Avatar
                        variant="status"
                        className={
                          ["TERMINATED", "FAILED"].some((el) =>
                            String(row.status || "").includes(el)
                          )
                            ? "red"
                            : ""
                        }
                      >
                        {row.status || "-"}
                      </Avatar>
                    ),
                  },
                  {
                    key: "remarks",
                    label: "Remarks",
                  },
                ]}
                small
                hideDivider
              />
            ) : tab === 2 ? (
              <TableComponent
                px={2.5}
                loading={transactionLoading}
                rows={
                  transactionData?.data?.constructor === Array
                    ? transactionData.data
                    : []
                }
                rowCount={(transactionData?.totalPages || 0) * pageSize}
                activePage={page}
                activePageSize={pageSize}
                onPageChange={(value) => setPage(value)}
                onPageSizeChange={(value) => setPageSize(value)}
                serverSidePagination
                columns={[
                  {
                    key: "action",
                    label: "Actions",
                    Render: (row) => (
                      <Button
                        size="small"
                        variant="outlined"
                        sx={{ ml: -1, textTransform: "none" }}
                        onClick={() =>
                          SetRefundDialog({ open: true, data: row, user: user })
                        }
                        startIcon={
                          <AccountBalanceWalletOutlined sx={{ mr: -0.25 }} />
                        }
                      // disabled={row.type !== "USED_FROM_WALLET"}
                      >
                        Add Funds
                      </Button>
                    ),
                  },
                  {
                    key: "category",
                    label: "C/D",
                    Render: (row) => (
                      <Avatar
                        variant="status"
                        className={
                          row.category === "CREDIT"
                            ? "green"
                            : row.category === "DEBIT"
                              ? "blue"
                              : "grey"
                        }
                      >
                        {titleCase(row.category || "N/A")}
                      </Avatar>
                    ),
                  },
                  {
                    key: "amount",
                    label: "Amount",
                    format: (value) =>
                      `₹${(value || 0).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}`,
                  },
                  {
                    key: "timestamp",
                    label: "Date",
                    format: (value) =>
                      moment(value).format("MMM DD, yyyy, hh:mm A"),
                  },
                  {
                    key: "status",
                    label: "Status",
                    Render: (row) => (
                      <Avatar
                        variant="status"
                        className={
                          ["TERMINATED", "FAILED"].some((el) =>
                            String(row.status || "").includes(el)
                          )
                            ? "red"
                            : ""
                        }
                      >
                        {titleCase(row.status || "-")}
                      </Avatar>
                    ),
                  },
                  {
                    key: "transactionType",
                    label: "Description",
                    Render: (row) => {
                      return row?.transactionType !== undefined
                        ? row.transactionType.split("_").join(" ")
                        : "-";
                    },
                  },
                  {
                    key: "id",
                    label: "UTR/WTR ID",
                    Render: (row) => {
                      return row?.paymentDetails?.transactionId !== undefined
                        ? row.paymentDetails.transactionId
                        : row.id;
                    },
                  },
                  {
                    key: "version",
                    label: "Wallet Version",
                    Render: (row) => {
                      return row?.transactionType !== undefined
                        ? "Wallet Version 2.0"
                        : row?.type !== undefined
                          ? "Wallet Version 1.0"
                          : "-";
                    },
                  },
                ]}
                small
                hideDivider
              />
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            <Box mt={3.75} mb={2} mx={2.5}>
              <Tabs
                className="dense"
                value={tab}
                onChange={(e, tab) => setTab(tab)}
              >
                <Tab label="CUG Bookings Stats" />
              </Tabs>
            </Box>
            {tab === 0 && (
              <TableComponent
                px={2.5}
                loading={userStatsLoading}
                rows={userStatsData?.data?.cugStats || []}
                columns={[
                  {
                    key: "name",
                    label: "CUG Name",
                  },
                  {
                    key: "status",
                    label: "Status",
                    Render: (row) => (
                      <Avatar
                        variant="status"
                        className={
                          row?.status === "ACTIVE"
                            ? "green"
                            : row?.status === "INACTIVE"
                              ? "yellow"
                              : "red"
                        }
                      >
                        {titleCase(row?.status || "-")}
                      </Avatar>
                    ),
                  },
                  {
                    key: "totalBookings",
                    label: "Total Bookings",
                  },
                  {
                    key: "totalEnergy",
                    label: "Total Energy",
                    Render: (row) => row?.totalEnergy.toFixed(2) + " kWh"
                  },
                  {
                    key: "totalDiscounts",
                    label: "Total Discounts",
                    Render: (row) => "₹ " + row?.totalDiscounts.toFixed(2)
                  },
                ]}
                small
                hideDivider
              />
            )}
          </>
        )}
      </>
    );
  };

const HostContent: React.FC<{
  user: any;
  hasWallet: boolean;
  setHasWallet: React.Dispatch<React.SetStateAction<boolean>>;
  confirmRefundDialog: any;
  setConfirmRefundDialog: React.Dispatch<any>;
  refundsData: any;
  refundsLoading: boolean;
  walletData: any;
  anomaly: any;
  setAnomaly: any;
}> = ({
  user,
  hasWallet,
  setHasWallet,
  setConfirmRefundDialog,
  refundsLoading,
  refundsData,
  walletData,
  anomaly,
  setAnomaly,
}) => {
    const [stats, setStats] = useState<any>(null);
    const [refundsList, setRefundsList] = useState([]);
    const [walletBalance, setWalletBalance] = useState(0);
    const [tab, setTab] = useState(0);
    const [tabOne, setTabOne] = useState(0);

    let userEmail = useSelector((state: GlobalState) => state.global.user.email);
    const shouldShowRefundFlow = userEmail === "walletmanager@bolt.earth";

    const bookingUrl = `${BOLT_URL}/company/users/bookings?userId=${user["_id"]
      }&tabOne=${tabOne === 0
        ? ""
        : tabOne === 1
          ? "&paymentStatus=PAYMENT_COMPLETE"
          : tabOne === 2
            ? "&paymentStatus=PAYMENT_INCOMPLETE"
            : ""
      }&anomaly=${anomaly}`;

    const { isLoading: bookingsLoading, data: bookingsData } = useQuery(
      ["getUsersBookings", user?._id, tabOne, anomaly],
      () =>
        authorizedFetch(bookingUrl, {
          headers: {
            token: 1234,
          },
        }),
      {
        onError: () => snackbar.error("Error fetching data"),
      }
    );

    const hostStatsUrl = `${BOLT_URL}/company/stats/host/bookings?userId=${user["_id"]}`;
    const { data: hostStatsData } = useQuery(
      ["getHostStatus", user?._id],
      () =>
        authorizedFetch(hostStatsUrl, {
          headers: {
            token: 1234,
          },
        }),
      {
        onError: () => snackbar.error("Error fetching data"),
      }
    );

    useEffect(() => {
      if (hostStatsData) {
        setStats(hostStatsData?.data);
      } else {
        setStats(null);
      }
    }, [hostStatsData]);

    const hostBookingUrl = `${BOLT_URL}/company/hosts/bookings?userId=${user["_id"]
      }&tabOne=${tabOne === 0
        ? ""
        : tabOne === 1
          ? "&paymentStatus=PAYMENT_COMPLETE"
          : tabOne === 2
            ? "&paymentStatus=PAYMENT_INCOMPLETE"
            : ""
      }&anomaly=${anomaly}`;

    const { isLoading: hostBookingsLoading, data: hostBookingsData } = useQuery(
      ["getHostsBookings", user?._id, tabOne, anomaly],
      () =>
        authorizedFetch(hostBookingUrl, {
          headers: {
            token: 1234,
          },
        }),
      {
        onError: () => snackbar.error("Error fetching data"),
      }
    );

    useEffect(() => {
      if (walletData) {
        if (walletData.status === 400) {
          setHasWallet(false);
        }
        if (walletData.status === 200) {
          setHasWallet(true);
        }
        setWalletBalance(walletData?.data?.walletBalance);
      }
    }, [walletData, setHasWallet]);

    useEffect(() => {
      if (refundsData && refundsData.data) {
        setRefundsList(refundsData.data);
      } else {
        setRefundsList([]);
      }
    }, [refundsData]);

    return (
      <>
        {bookingsLoading && <LinearProgress />}
        <Box px={2.5} pt={2}>
          <Box
            mb={1.5}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Stats</Typography>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: 1,
              "& > div": {
                width: 1,
                p: 1.5,
                display: "flex",
                border: 1,
                borderRadius: "3px",
                borderColor: (theme) => theme.customColors.border,
                "& .MuiAvatar-root": {
                  mr: 1,
                },
                "& .info": {
                  display: "flex",
                  flexDirection: "column",
                  "& .label": {
                    fontSize: 17,
                    fontWeight: 600,
                    color: "text.primary",
                    lineHeight: "1.15em",
                  },
                  "& .value": {
                    fontSize: 12,
                    color: "text.secondary",
                  },
                },
              },
            }}
          >
            <Box>
              <Avatar variant="icon">
                <EventAvailableOutlined />
              </Avatar>
              <Box className="info">
                <span className="label">{stats?.totalBookings || "-"}</span>
                <span className="value">Bookings Received</span>
              </Box>
            </Box>
            <Box>
              <Avatar variant="icon">
                <Timer />
              </Avatar>
              <Box className="info">
                <span className="label">
                  {stats?.totalBookingDuration
                    ? getDuration(stats?.totalBookingDuration * 60)
                    : "-"}
                </span>
                <span className="value">Booking Duration</span>
              </Box>
            </Box>
            <Box>
              <Avatar variant="icon">
                <CurrencyRupee />
              </Avatar>
              <Box className="info">
                <span className="label">
                  {typeof stats?.totalEarnings === "number"
                    ? "₹" + stats.totalEarnings?.toFixed(2)
                    : "-"}
                </span>
                <span className="value">Earnings</span>
              </Box>
            </Box>
            <Box>
              <Avatar variant="icon">
                <PowerOutlined />
              </Avatar>
              <Box className="info">
                <span className="label">
                  {stats?.totalChargingDuration
                    ? getDuration(stats.totalChargingDuration * 60)
                    : "-"}
                </span>
                <span className="value">Charging Duration</span>
              </Box>
            </Box>
            <Box>
              <Avatar variant="icon">
                <BoltOutlined />
              </Avatar>
              <Box className="info">
                <span className="label">
                  {typeof stats?.energyConsumed === "number"
                    ? stats.energyConsumed.toLocaleString(undefined, {
                      maximumFractionDigits: 3,
                    }) + " kWh"
                    : "-"}
                </span>
                <span className="value">Energy Dispensed</span>
              </Box>
            </Box>
            <Box>
              <Avatar variant="icon">
                <AccountBalanceWalletOutlined />
              </Avatar>
              <Box className="info">
                <span className="label">
                  {hasWallet
                    ? typeof walletBalance === "number"
                      ? "₹" + walletBalance
                      : "No Wallet Found"
                    : "No Wallet Found"}
                </span>
                <span className="value">Host's Wallet Balance</span>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box mt={3.75} mb={2} mx={2.5}>
          <Tabs className="dense" value={tab} onChange={(e, tab) => setTab(tab)}>
            <Tab label="Personal Bookings" />
            <Tab label="User Bookings" />
            <Tab label="Refund Requests" />
            {shouldShowRefundFlow && <Tab label="Wallet Transactions" />}
          </Tabs>
        </Box>
        <Box
          sx={{
            width: 1,
            px: 2,
            pb: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box display="flex" width={1}>
            <Tooltip title="Booking Status" placement="top-start">
              <FormControl sx={{ mr: 2 }}>
                <Select
                  size="small"
                  sx={{ width: "auto" }}
                  value={tabOne}
                  onChange={(e: any) => setTabOne(e.target.value)}
                  startAdornment={<Sort sx={{ mr: 1 }} />}
                  renderValue={(selected) =>
                    selected === 0
                      ? "Status: All"
                      : selected === 1
                        ? "Status: Paid"
                        : "Status: Unpaid"
                  }
                >
                  <MenuItem value={0}>All</MenuItem>
                  <MenuItem value={1}>Paid</MenuItem>
                  <MenuItem value={2}>Unpaid</MenuItem>
                </Select>
              </FormControl>
            </Tooltip>
          </Box>
          {tab === 0 || tab === 1 ? (
            <Box mx={1}>
              <Tooltip title="Anomaly Detection">
                <Switch
                  size="small"
                  checked={anomaly}
                  onChange={() => setAnomaly(!anomaly)}
                />
              </Tooltip>
            </Box>
          ) : (
            ""
          )}
        </Box>
        {tab === 0 ? (
          <TableComponent
            px={2.5}
            loading={bookingsLoading}
            rows={bookingsData?.data?.userBookings[0]?.bookings || []}
            columns={[
              {
                key: "bookingId",
                label: "Booking ID",
              },
              {
                key: "chargingStart",
                label: "Start Time",
                format: (value) => format(new Date(value), "MMM dd yyyy, HH:mm"),
              },
              {
                key: "bookingDuration",
                label: "Booking Duration",
                format: (value) => getDuration(value * 60),
              },
              {
                key: "actualDuration",
                label: "Charging Duration",
                Render: (row) => getDuration(row.chargingDuration * 60),
              },
              {
                key: "energyConsumed",
                label: "Energy Utilised",
                Render: (row) => <LiveEnergy booking={row} />,
              },
              {
                key: "amount",
                label: "Amount",
                format: (value) =>
                  typeof value === "number" ? "₹" + value : "-",
              },
              {
                key: "paymentType",
                label: "Mode of Payment",
                Render: (row) => {
                  return <Box>{row?.paymentType || "-"}</Box>;
                },
              },
              {
                key: "paymentStatus",
                label: "Payment",
                // Render: (row) => {
                //   return <Box>{row?.paymentStatus || "-"}</Box>;
                // },
                Render: (row) => (
                  <Avatar
                    variant="status"
                    className={
                      row?.paymentStatus === "PAYMENT_INCOMPLETE"
                        ? "red"
                        : row?.paymentStatus === "PAYMENT_COMPLETE"
                          ? "green"
                          : "yellow"
                    }
                  >
                    {titleCase(row?.paymentStatus || "-")
                      .split("_")
                      .join(" ")}
                  </Avatar>
                ),
              },
              {
                key: "bookingStatus",
                label: "Booking Status",
                Render: (row) => (
                  <Avatar
                    variant="status"
                    className={
                      row.bookingStatus === "CANCELLED"
                        ? "red"
                        : row.bookingStatus === "PAYMENT_PENDING"
                          ? "yellow"
                          : row.bookingStatus === "ENDED"
                            ? "blue"
                            : ""
                    }
                  >
                    {titleCase(
                      (row.bookingStatus === "ENDED"
                        ? "TERMINATED"
                        : row.bookingStatus === "TERMINATED"
                          ? "ENDED"
                          : row.bookingStatus === "CANCELLED"
                            ? "CANCELLED"
                            : row.bookingStatus === "PAYMENT_PENDING"
                              ? "PAYMENT_PENDING"
                              : row.bookingStatus === "BOOKED"
                                ? "BOOKED"
                                : "N/A"
                      )
                        .split("_")
                        .join(" ")
                    )}
                  </Avatar>
                ),
              },
            ]}
            small
            hideDivider
          />
        ) : tab === 1 ? (
          <TableComponent
            px={2.5}
            loading={hostBookingsLoading}
            rows={hostBookingsData?.data || []}
            columns={[
              {
                key: "_id",
                label: "Booking ID",
              },
              {
                key: "chargingStart",
                label: "Start Time",
                format: (value) => format(new Date(value), "MMM dd yyyy, HH:mm"),
              },
              {
                key: "bookingDuration",
                label: "Booking Duration",
                format: (value) => getDuration(value * 60),
              },
              {
                key: "actualDuration",
                label: "Charging Duration",
                Render: (row) => getDuration(row.chargingDuration * 60),
              },
              {
                key: "energyConsumed",
                label: "Energy Utilised",
                Render: (row) => <LiveEnergy booking={row} />,
              },
              {
                key: "amount",
                label: "Amount",
                format: (value) =>
                  typeof value === "number" ? "₹" + value : "-",
              },
              {
                key: "paymentType",
                label: "Mode of Payment",
                Render: (row) => {
                  return <Box>{row?.paymentType || "-"}</Box>;
                },
              },
              {
                key: "paymentStatus",
                label: "Payment",
                // Render: (row) => {
                //   return <Box>{row?.paymentStatus || "-"}</Box>;
                // },
                Render: (row) => (
                  <Avatar
                    variant="status"
                    className={
                      row?.paymentStatus === "PAYMENT_INCOMPLETE"
                        ? "red"
                        : row?.paymentStatus === "PAYMENT_COMPLETE"
                          ? "green"
                          : "yellow"
                    }
                  >
                    {titleCase(row?.paymentStatus || "-")
                      .split("_")
                      .join(" ")}
                  </Avatar>
                ),
              },
              {
                key: "bookingStatus",
                label: "Booking Status",
                Render: (row) => (
                  <Avatar
                    variant="status"
                    className={
                      row.bookingStatus === "CANCELLED"
                        ? "red"
                        : row.bookingStatus === "PAYMENT_PENDING"
                          ? "yellow"
                          : row.bookingStatus === "ENDED"
                            ? "blue"
                            : ""
                    }
                  >
                    {titleCase(
                      (row.bookingStatus === "ENDED"
                        ? "TERMINATED"
                        : row.bookingStatus === "TERMINATED"
                          ? "ENDED"
                          : row.bookingStatus === "CANCELLED"
                            ? "CANCELLED"
                            : row.bookingStatus === "PAYMENT_PENDING"
                              ? "PAYMENT_PENDING"
                              : row.bookingStatus === "BOOKED"
                                ? "BOOKED"
                                : "N/A"
                      )
                        .split("_")
                        .join(" ")
                    )}
                  </Avatar>
                ),
              },
            ]}
            small
            hideDivider
          />
        ) : tab === 2 ? (
          <TableComponent
            px={2.5}
            loading={refundsLoading}
            rows={refundsList}
            columns={[
              {
                key: "bookingId",
                label: "Booking ID",
              },
              {
                key: "action",
                label: "Actions",
                Render: (row) => (
                  <Button
                    sx={{ ml: -1 }}
                    onClick={() =>
                      setConfirmRefundDialog({
                        open: true,
                        data: row,
                        user: user,
                      })
                    }
                    disabled={row.status === "COMPLETE"}
                  >
                    CONFIRM
                  </Button>
                ),
              },
              {
                key: "amount",
                label: "Amount",

                format: (value) => ` ₹ ${value}`,
              },
              {
                key: "timeStamp",
                label: "Date",
                format: (value) => moment(value).format("MMM DD yyyy, hh:mm A"),
              },
              {
                key: "status",
                label: "Status",
                Render: (row) => (
                  <Avatar
                    variant="status"
                    className={
                      ["TERMINATED", "FAILED"].some((el) =>
                        String(row.status || "").includes(el)
                      )
                        ? "red"
                        : ""
                    }
                  >
                    {row.status || "-"}
                  </Avatar>
                ),
              },
              {
                key: "remarks",
                label: "Remarks",
              },
            ]}
            small
            hideDivider
          />
        ) : (
          ""
        )}
      </>
    );
  };

const LiveEnergy = ({ booking }: any) => {
  const shouldShowLiveData =
    booking?.bookingStatus === "BOOKED" &&
    booking?.communicationProtocol === "OCPP";

  const OCPP_URL_PROD = "https://ocpp.revos.in";
  const url = `${OCPP_URL_PROD}/charger/getChargerData`;
  const { isLoading: chargerLoading, data: chargerData } = useQuery(
    ["getChargerData", booking?.chargerId, booking?.connectorId],
    () =>
      authorizedFetch(url, {
        method: "POST",
        headers: {
          stage: "test",
          "Content-Type": "application/json",
        },
        body: {
          chargerId: booking?.chargerId + "/" + booking?.connectorId,
          action: "currentMeterValues",
        },
      }).then((res) => res.json()),
    {
      enabled: shouldShowLiveData,
      refetchInterval: shouldShowLiveData ? 10000 : false,
    }
  );

  const { value, unit } = chargerData?.data || {};
  const consumed =
    (value
      ? value.toLocaleString(undefined, {
        maximumFractionDigits: 3,
      })
      : 0) +
    " " +
    (unit || "kWh");

  return (
    <>
      {shouldShowLiveData
        ? chargerLoading
          ? "Loading..."
          : consumed
        : (booking?.energyConsumed
          ? booking.energyConsumed.toLocaleString(undefined, {
            maximumFractionDigits: 3,
          })
          : 0) + " kWh"}
    </>
  );
};

const UserGroupContent: React.FC<{ group: any }> = ({ group }) => {
  const [tab, setTab] = useState(0);

  const userGroupUrl = `${BOLT_URL}/company/v2/usergroup/data/${group._id}`;
  const { isLoading: userGroupLoading, data: userGroupData } = useQuery(
    ["getUserGroup", group?._id || ""],
    () => authorizedFetch(userGroupUrl)
  );

  const cugUsers =
    userGroupData?.data?.users?.constructor === Array
      ? userGroupData.data.users.map((el: any, i: number) => {
        return {
          id: i + 1,
          fullName: `${el?.firstName || ""} ${el?.lastName || ""}`,
          phone: el?.phone === undefined ? "" : el?.phone,
        };
      })
      : [];

  const cugChargers =
    userGroupData?.data?.chargers?.constructor === Array
      ? userGroupData?.data?.chargers.map((el: any, i: number) => ({
        id: i + 1,
        charger: `${el}`,
      }))
      : [];

  const cugPaymentHistory =
    userGroupData?.data?.paymentDetails?.constructor === Array
      ? userGroupData.data.paymentDetails.map((el: any, i: number) => {
        return {
          id: i + 1,
          category: el.category,
          status: el.status,
          openingWalletBalance: el.openingWalletBalance,
          amount: el.amount,
          closingWalletBalance: el.closingWalletBalance,
        };
      })
      : [];

  const table = [
    { header: "Details & Usage" },
    { label: "Group Name", value: userGroupData?.data?.name },
    { label: "Group Description", value: userGroupData?.data?.description },
    {
      label: "Usage Type",
      value: titleCase(userGroupData?.data?.type || ""),
    },
    ...(userGroupData?.data?.type === "BUSINESS"
      ? [{ label: "Business", value: userGroupData?.data?.company[0]?.name }]
      : []),

    { header: "Chargers" },
    {
      label: "Chargers Assigned",
      value:
        userGroupData?.data?.allChargersSelected === false
          ? "Specific chargers"
          : "All chargers",
    },
    {
      label: "Total Chargers",
      value: `${userGroupData?.data?.totalChargers} charger${userGroupData?.data?.totalChargers > 1 ? "s" : ""
        }`,
    },

    { header: "Users" },
    {
      label: "Total Users",
      value: `${userGroupData?.data?.totalUsers} user${userGroupData?.data?.totalUsers > 1 ? "s" : ""
        }`,
    },

    { header: "Payments" },
    {
      label: "Pricing Name",
      value: userGroupData?.data?.pricingDetails?.label,
    },
    {
      label: "Pricing Mode",
      value: titleCase(
        userGroupData?.data?.pricingDetails?.pricing?.type || ""
      ),
    },
    ...(userGroupData?.data?.pricingDetails?.pricing?.type !== "FREE"
      ? [
        {
          label: "Discount Type",
          value: titleCase(
            userGroupData?.data?.pricingDetails?.pricing?.discountType || ""
          ),
        },
        ...(userGroupData?.data?.pricingDetails?.pricing?.discountType ===
          "PERCENT"
          ? [
            {
              label: "Pricing Amount",
              value: `${userGroupData?.data?.pricingDetails?.pricing?.value} %`,
            },
          ]
          : [
            {
              label: "Pricing Amount",
              value: `₹${userGroupData?.data?.pricingDetails?.pricing?.value} / kwh`,
            },
          ]),
      ]
      : []),

    { header: "Cycle Info" },
    // { label: "Paid Amount", value: "" },
    {
      label: "CUG Balance",
      value: userGroupData?.data?.currentCycleBalance === 0
        ? ""
        : `₹${userGroupData?.data?.currentCycleBalance}`
    },
    // { label: "CUG Actual Cost", value: "" },
  ];

  const tableStyle = {
    px: 3,
    "& .table": {
      borderCollapse: "collapse",
      width: 1,
      fontSize: 14,
      lineHeight: "16px",
      "& td": {
        py: 1.5,
        px: 2,
        "&.secondary": {
          color: "text.secondary",
        },
      },
      "& .bold": {
        fontWeight: 500,
      },
      "& .header": {
        position: "relative",
        "& td": {
          py: 2,
          position: "absolute",
          verticalAlign: "middle",
          backgroundColor: (theme: any) => theme.customColors.header,
          width: 1,
          borderRadius: "4px",
          fontSize: 16,
          fontWeight: 600,
          "& span": {
            display: "inline-block",
            transform: "translateY(1px)",
          },
        },
      },
      "& .first > td": {
        pt: 8.5,
      },
      "& .last > td": {
        pb: 3,
      },
    },
  };

  return (
    <>
      <Box mx={2} my={2}>
        <Tabs
          variant="scrollable"
          allowScrollButtonsMobile
          className="dense"
          value={tab}
          onChange={(e: any, tab: any) => {
            setTab(tab);
          }}
        >
          <Tab key="cugDetails" label="Basic Information" />
          <Tab key="users" label="Users" />
          <Tab key="chargers" label="Chargers" />
          <Tab key="transactions" label="Transactions" />
        </Tabs>
      </Box>

      {tab === 0 && (
        <>
          <Box sx={tableStyle}>
            <table className="table">
              <tbody>
                {table.map(({ header, label, value }, i) => {
                  const isFirst = table[i - 1]?.header;
                  const isLast = !table[i + 1] || table[i + 1].header;

                  return (
                    <tr
                      key={i}
                      className={
                        header
                          ? "header"
                          : `${isFirst ? "first" : ""} ${isLast ? "last" : ""}`
                      }
                    >
                      {header ? (
                        <td colSpan={2}>
                          <span>{header}</span>
                        </td>
                      ) : (
                        <>
                          <td className="bold">{label}</td>
                          <td> {value} </td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
        </>
      )}

      {tab === 1 && (
        <Box mt={2} px={3}>
          <TableComponent
            height={500}
            px={0}
            rowsPerPage={5}
            small
            loading={userGroupLoading}
            rows={cugUsers || []}
            columns={[
              { key: "id", label: "S. No." },
              { key: "fullName", label: "Name" },
              {
                key: "phone",
                label: "Phone",
                Render: (row) => {
                  return row.phone.length > 10
                    ? `${row.phone.slice(0, 3)} ${row.phone.slice(3)}`
                    : "-";
                },
              },
            ]}
          />
        </Box>
      )}

      {tab === 2 && (
        <Box mt={2} px={3}>
          <TableComponent
            height={500}
            px={0}
            rowsPerPage={5}
            small
            loading={userGroupLoading}
            rows={cugChargers || []}
            columns={[
              { key: "id", label: "S. No." },
              { key: "charger", label: "Charger ID" },
            ]}
          />
        </Box>
      )}

      {tab === 3 && (
        <Box mt={2} px={3}>
          <TableComponent
            height={500}
            px={0}
            rowsPerPage={5}
            small
            loading={userGroupLoading}
            rows={cugPaymentHistory || []}
            columns={[
              {
                key: "status",
                label: "Status",
                Render: (row) => (
                  <Avatar
                    variant="status"
                    className={
                      row.status === "SUCCESS"
                        ? "green"
                        : "red"
                    }
                  >
                    {titleCase(row.status || "")}
                  </Avatar>
                ),
              },
              { key: "openingWalletBalance", label: "Opening Wallet Balance" },
              {
                key: "category",
                label: "Category",
                Render: (row) => (
                  <Avatar
                    variant="status"
                    className={
                      row.category === "CREDIT"
                        ? "green"
                        : "red"
                    }
                  >
                    {titleCase(row.category || "")}
                  </Avatar>
                ),
              },
              { key: "amount", label: "Amount" },
              { key: "closingWalletBalance", label: "Closing Wallet Balance" },
            ]}
          />
        </Box>
      )}
    </>
  );
};

export default DrawerContent;
