import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { appBar, authorizedFetch, GlobalState } from "utils";
import { OVERVIEW_URL_TEMP } from "utils/constants";
import AppBar from "./AppBar";
import Cards from "./Cards";

const filterLabels: any = {
  access: {
    "Public Free": "PUBLIC_FREE",
    "Public Paid": "PUBLIC_PAID",
    Private: "PRIVATE",
    "Restricted Free": "RESTRICTED_FREE",
    "Restricted Paid": "RESTRICTED_PAID",
  },
  health: {
    "Used Within Last 15 Days": "HEALTHY",
    "Used Between Last 16-30 Days": "MODERATE",
    "Used Between Last 31-45 Days": "CRITICAL",
    "Used Before 45 Days": "INACTIVE",
  },
};

const Overview = () => {
  const { masterView } = useSelector((state: GlobalState) => state.global);

  const [filters, setFilters] = useState<any>({
    access: [],
    health: [],
    locations: [],
    time: [moment().subtract({ days: 7 }), moment()],
  });

  const locations = filters.locations.join("_");

  const startTime = filters.time[0].toISOString();
  const endTime = filters.time[1].toISOString();
  const cities = filters.locations.length
    ? `&city[]=${filters.locations.join("&city[]=")}`
    : "";
  const usageType = filters.access.length
    ? `&usageType[]=${filters.access
        .map((el: any) => filterLabels.access[el])
        .join("&usageType[]=")}`
    : "";
  const chargerHealth = filters.health.length
    ? `&chargerHealth[]=${filters.health
        .map((el: any) => filterLabels.health[el])
        .join("&chargerHealth[]=")}`
    : "";

  const params = {
    masterView,
    startTime,
    endTime,
    cities,
    usageType,
    chargerHealth,
  };

  console.log("OVERVIEW_URL_TEMP > ", OVERVIEW_URL_TEMP)

  // statsData being used in Map
  const statsUrl = `${OVERVIEW_URL_TEMP}/company/stats/all?city=${locations}`;
  console.log("statsUrl > ", statsUrl)
  const { isLoading: statsLoading, data: statsData } = useQuery(
    ["getAllStats", masterView, locations],
    () =>
      authorizedFetch(statsUrl, {
        headers: {
          cache: "true",
          master: masterView,
        },
      })
  );

  useEffect(() => {
    appBar.open(
      <AppBar
        {...{
          filters,
          setFilters,
          filterLabels,
        }}
      />
    );
    return () => {
      appBar.close();
    };
  }, [filters]);

  return (
    <>
      <Cards
        {...{
          statsLoading,
          statsData,
          params,
          locations,
        }}
      />
    </>
  );
};

export default Overview;
