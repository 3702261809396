import { ArrowBack } from "@mui/icons-material";
import { Box, Hidden, IconButton, Tooltip, Typography } from "@mui/material";
import BoltEarthLogo from "components/BoltLogo";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { NavigationLink, navigationLinks } from "routes";
import { GlobalState, getPermissions } from "utils";
import { drawerWidth } from "./constants";
// import { subdomain } from "utils/constants";
import EtoLogo from "assets/images/logos/etoSVG.svg";

interface NavProps {
  isDarkMode: boolean;
  location: string;
  handleClose: () => void;
}

const NavigationDrawer: React.FC<NavProps> = ({
  isDarkMode,
  location,
  handleClose,
}) => {
  const { user, company, activeSection } = useSelector(
    (state: GlobalState) => state.global
  );
  const parents = new Set();
  const userParents: any[] = user.parents;
  userParents?.forEach((parent) => parents.add(parent._id));
  const isCUGMonitorAccount = company?.type === "CUG_VENDOR";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: {
          md: drawerWidth,
        },
        position: "sticky",
        height: "100vh",
        overflowY: "hidden",
        overflowX: "hidden",
        bgcolor: isDarkMode ? "#080808" : "#232728",
        "::-webkit-scrollbar, ::-webkit-scrollbar-corner": {
          backgroundColor: "#202124",
          width: "10px",
        },
        "::-webkit-scrollbar-thumb": {
          borderRadius: "8px",
          backgroundColor: "#757575",
          minHeight: "24px",
          border: "3px solid #202124",
        },
      }}
    >
      <Hidden mdDown>
        {/* {true ? ( */}
        {company.id === "600a715202743900074f9e10" ||
        parents.has("600a715202743900074f9e10") ? (
          <img
            height={55}
            src={EtoLogo}
            alt="ETO Logo"
            style={{ margin: "1.25em auto" }}
          />
        ) : (
          <BoltEarthLogo width={50} margin="1.25em auto" />
        )}
      </Hidden>
      <Hidden mdUp>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            my: 1.5,
            color: "#eee",
          }}
        >
          <Hidden mdUp>
            <IconButton color="inherit" sx={{ mx: 1.5 }} onClick={handleClose}>
              <ArrowBack />
            </IconButton>
          </Hidden>
          {/* {true ? ( */}
          {company.name === "ETO Motors Pvt Ltd" ? (
            <img height={55} src={EtoLogo} alt="ETO Logo" />
          ) : (
            <BoltEarthLogo width={50} />
          )}
        </Box>
      </Hidden>
      <Box id="nav" display="grid" justifyItems={{ md: "center" }} my={2}>
        {activeSection &&
          navigationLinks[activeSection]
            ?.filter((link) => {
              if (
                user.email === "adi.setiakarsa@alvaauto.com" &&
                !["Overview", "Chargers", "Bookings", "Reports"].includes(
                  link.name
                )
              )
                return false;
              if (
                user.email === "srikhantan.s@bounceshare.com" &&
                !["Vehicles", "Trips", "Reports"].includes(link.name)
              )
                return false;
              // if (
              //   activeSection === "rental" &&
              //   company?._id !== "5cfa443da7b11b00073f9657" &&
              //   company?._id !== "5f44a1e3e03dd800075dd466" &&
              //   company?._id !== "632194ffe69fd70293485bd6" &&
              //   company?._id !== "6332c926822ca3f85b2935e8" &&
              //   ["Vendors"].includes(link.name)
              // )
              //   return false;
              if (
                company?.type === "DISTRIBUTOR" &&
                ["KYC", "Geofence", "Admin"].includes(link.name)
              )
                return false;

              if (
                isCUGMonitorAccount &&
                ![
                  "charger:chargers",
                  "charger:users",
                  "charger:reports",
                ].includes(link.id)
              )
                return false;

              if (
                company?.name === "testTVS" &&
                [
                  "Chargers",
                  "Vendors",
                  "Subscriptions",
                  "Coupons",
                  "Invoices",
                  "KYC",
                  "Admin",
                ].includes(link.name)
              )
                return false;

              let { canRead, isAdmin } = getPermissions(link.id);

              let isOEM = company?.type === "OEM";

              if (isOEM && ["retail:assignedVehicles"].includes(link.id))
                return false;
              if (!isOEM && ["retail:assembly"].includes(link.id)) return false;

              if (link.name === "Sold Vehicles") return false;

              return link.id.includes("admin") ? isAdmin : canRead;
            })
            ?.map((link, i) => (
              <NavigationItem
                key={i}
                location={location}
                link={link}
                closeDrawer={handleClose}
              />
            ))}
      </Box>
      {company.name === "ETO Motors Pvt Ltd" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "column",
            flexGrow: 1,
            mb: 5,
            color: "white",
          }}
        >
          <Typography
            sx={{
              mt: 1,
              textAlign: "center",
              userSelect: "none",
              fontSize: 9,
            }}
          >
            Powered By
          </Typography>
          <BoltEarthLogo
            color="rgba(255, 255, 255, 0.65)"
            width={45}
            margin="0.5em auto"
          />
        </Box>
      )}
    </Box>
  );
};

interface NavigationLinkProps {
  location: string;
  link: NavigationLink;
  closeDrawer?: () => void;
}

const NavigationItem: React.FC<NavigationLinkProps> = ({
  location,
  link,
  closeDrawer,
}) => {
  const [open, setOpen] = useState(false);
  const collapseHeight = 634;
  const [hideLabels, setHideLables] = useState(
    window.innerHeight <= collapseHeight && window.outerWidth >= 900
  );

  useEffect(() => {
    setOpen(
      location === link.path ||
        (link.path !== "/" && location.startsWith(link.path))
    );
  }, [location, link.path]);

  useEffect(() => {
    function handleResize() {
      setHideLables(
        window.innerHeight <= collapseHeight && window.outerWidth >= 900
      );
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Tooltip title={hideLabels ? link.name : ""} placement="right">
      <Box
        component={NavLink}
        to={link.path}
        onClick={() => {
          closeDrawer && closeDrawer();
        }}
        sx={{
          display: "flex",
          flexDirection: {
            md: "column",
          },
          alignItems: "center",
          textAlign: "center",
          mx: { xs: 1, md: 0 },
          px: { xs: 1.5, md: 0.5 },
          py: { xs: 1.75, md: 1 },
          width: { xs: 200, md: 70 },
          borderRadius: 1,
          color: open ? "#fff" : "rgba(255, 255, 255, 0.5)",
          fontSize: { xs: 14, md: 9 },
          fontWeight: 300,
          textDecoration: "none",
          transition: "100ms",
          userSelect: "none",
          "&:hover": {
            bgcolor: "rgba(255, 255, 255, 0.05)",
          },
          "& svg": {
            width: 24,
            height: 24,
            mb: { md: 0.5 },
            mr: { xs: 3, md: 0 },
          },
        }}
      >
        {open ? link.icon.active : link.icon.default}
        {!hideLabels && link.name}
      </Box>
    </Tooltip>
  );
};

export default NavigationDrawer;
