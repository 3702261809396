import { EditOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  // InputAdornment,
} from "@mui/material";
import { setGlobalState } from "actions";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authorizedFetch, GlobalState, setLoader, snackbar } from "utils";
import { AUTH_URL } from "utils/constants";

const UserDetails = () => {
  const { user } = useSelector((state: GlobalState) => state.global);
  const dispatch = useDispatch();
  const [isEditMode, setEditMode] = useState(false);

  const [input, setInput] = useState({
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    address: user?.address || "",
    phone: user?.phone || "",
    email: user?.email || "",
  });

  const { firstName, lastName, address, phone, email } = input;
  // const { firstName, lastName, address } = input;

  function handleChange(key: string, value: string) {
    setInput((prev) => ({ ...prev, [key]: value }));
  }

  function refetchUser() {
    authorizedFetch(`${AUTH_URL}/user`).then((res) => {
      let { user } = res.data;
      if (user) {
        dispatch(setGlobalState({ user }));
      }
    });
  }

  function handleSubmit() {
    // console.log(input);
    setLoader(true);
    authorizedFetch(`${AUTH_URL}/user/update`, {
      method: "POST",
      body: {
        firstName,
        lastName,
        address,
        // phone,
        // email
      },
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      setLoader(false);
      refetchUser();
      if (res.status >= 400) snackbar.error("An error occurred");
      else {
        setEditMode(false);
        snackbar.success("Saved changes");
      }
    });
  }

  const regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return (
    <Box
      sx={{
        "& .form": {
          display: "flex",
          flexDirection: "column",
        },
        "& .fields": {
          display: "flex",
          alignItems: "center",
        },
        "& .label": {
          marginBottom: 0,
          width: "30%",
        },
        "& .value": {
          display: "flex",
          alignItems: "center",
          margin: "0 0 0 0",
          width: "100%",
        },
      }}
    >
      <Box
        className="header"
        sx={{
          justifyContent: isEditMode ? "space-between" : "start",
        }}
      >
        <Typography className="heading">Your Profile</Typography>
        {isEditMode ? (
          <Box display="grid" gridTemplateColumns="auto auto" gap={1}>
            <Button
              sx={{ height: 34 }}
              variant="contained"
              onClick={handleSubmit}
            >
              Save
            </Button>
            <Button
              sx={{ height: 34 }}
              variant="outlined"
              onClick={() => setEditMode(false)}
            >
              Cancel
            </Button>
          </Box>
        ) : (
          <IconButton
            sx={{ ml: 2.25 }}
            size="small"
            color="primary"
            onClick={() => setEditMode(true)}
          >
            <EditOutlined />
          </IconButton>
        )}
      </Box>
      <Box className="form">
        <Box className="fields">
          <Typography className="label">First Name</Typography>
          {isEditMode ? (
            <TextField
              autoComplete="off"
              disabled
              value={firstName}
              onChange={(e) => {
                handleChange(
                  "firstName",
                  e.target.value.length > 10
                    ? e.target.value.replace(/[^a-zA-Z ]/, "")
                    : e.target.value
                );
              }}
              size="small"
              fullWidth
              placeholder="First Name"
              inputProps={{ maxLength: 15 }}
            />
          ) : (
            <Typography className="value">
              {
                // user?.firstName || ""
                user?.firstName.charAt(0).toUpperCase() +
                  user?.firstName.slice(1)
              }
            </Typography>
          )}
        </Box>
        <Box className="fields">
          <Typography className="label">Last Name</Typography>
          {isEditMode ? (
            <TextField
              autoComplete="off"
              value={lastName}
              disabled
              onChange={(e) => {
                handleChange(
                  "lastName",
                  e.target.value.length > 0
                    ? e.target.value.replace(/[^a-zA-Z ]/, "")
                    : e.target.value
                );
              }}
              size="small"
              fullWidth
              placeholder="Last Name"
              inputProps={{ maxLength: 15 }}
            />
          ) : (
            <Typography className="value">
              {
                // user?.lastName || "-"
                user?.lastName.charAt(0).toUpperCase() + user?.lastName.slice(1)
              }
            </Typography>
          )}
        </Box>
        <Box
          className="fields"
          // gridColumn="span 2"
        >
          <Typography className="label">Address</Typography>
          {isEditMode ? (
            <TextField
              autoComplete="off"
              multiline
              minRows={3}
              value={address}
              onChange={(e) => handleChange("address", e.target.value)}
              size="small"
              fullWidth
              placeholder="Address"
              inputProps={{ maxLength: 40 }}
            />
          ) : (
            <Typography
              mb={2}
              className="value"
              sx={{ whiteSpace: "pre-line" }}
            >
              {user?.address || "-"}
            </Typography>
          )}
        </Box>
        <Box className="fields">
          <Typography className="label">Phone Number</Typography>
          {isEditMode ? (
            <TextField
              autoComplete="off"
              disabled
              value={phone}
              onChange={(e) => {
                if (e.target.value.length <= 10)
                  handleChange(
                    "phone",
                    e.target.value?.length > 0
                      ? e.target.value.replace(/[^0-9]/, "")
                      : e.target.value
                  );
              }}
              size="small"
              fullWidth
              placeholder="Phone"
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start" sx={{ ml: 1 }}>
              //       +91
              //     </InputAdornment>
              //   ),
              // }}
            />
          ) : (
            <Typography className="value">
              {user?.phone[0] === "+" ? user?.phone : "+91 - " + user?.phone}
            </Typography>
          )}
          {/* <Typography className="value">
            {user?.phone.length === 10 ? "+91 - " + user?.phone : user?.phone}
          </Typography> */}
        </Box>
        <Box className="fields">
          <Typography className="label">Email ID</Typography>
          {isEditMode ? (
            <TextField
              autoComplete="off"
              disabled
              value={email}
              onChange={(e) => handleChange("email", e.target.value)}
              size="small"
              fullWidth
              placeholder="Email"
              error={!regexEmail.test(email) && ![email].includes("")}
            />
          ) : (
            <Typography className="value">{user?.email}</Typography>
          )}
          {/* <Typography className="value">{user?.email}</Typography> */}
        </Box>
      </Box>
    </Box>
  );
};

export default UserDetails;
