import {
  EditOutlined,
  ErrorOutline,
  HighlightOff,
  VisibilityOffOutlined,
  VisibilityOutlined,
  WarningAmber,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Step,
  StepButton,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import PhoneTextField from "components/PhoneTextField";
import RedAsterisk from "components/RedAsterisk";
import { queryClient } from "index";
import { useEffect, useState } from "react";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { authorizedFetch, setLoader, snackbar, titleCase } from "utils";
import { BOLT_URL } from "utils/constants";
import validator from "validator";

interface Props {
  open: boolean;
  handleClose: () => void;
}

type Input = {
  [key: string]: any | { [key: string]: any };
};

const CreateBusiness: React.FC<Props> = ({ open, handleClose }) => {
  // ******* LABEL'S *******
  const steps = ["Company Info", "User Info", "Confirmation"];

  // ******* OBJECT TYPE *******
  const inputObj: Input = {
    companyName: "",
    gstNumber: "",
    companyPhone: "",
    address: "",
    firstName: "",
    lastName: "",
    userPhone: "",
    email: "",
  };

  // ******* INPUT_STATE *******
  const [step, setStep] = useState<number>(0);
  const [input, setInput] = useState({ ...inputObj });
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  // ******* DE-STRUCTURING INPUT_STATE *******
  const {
    companyName,
    gstNumber,
    companyPhone,
    address,
    firstName,
    lastName,
    userPhone,
    email,
  } = input;

  // ******* USEEFFECT'S *******
  useEffect(() => {
    if (open) {
      setStep(0);
      setPassword("");
      setPassword2("");
      setInput({
        companyName: "",
        gstNumber: "",
        companyPhone: "",
        address: "",
        firstName: "",
        lastName: "",
        userPhone: "",
        email: "",
      });
    }
  }, [open]);

  // ******* DAILOG CONTENT FUNCTION'S *******
  function handleChange(key: string, value: any) {
    setInput((prevInput) => ({ ...prevInput, [key]: value }));
  }

  const validateEmail = (email: any) => {
    return validator.isEmail(email);
  };

  const validatePassword = (password: any) => {
    const validate =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.#/_-])[A-Za-z\d@$!%*?&.#/_-]{6,}$/;
    return validate.test(password);
  };

  const validatePhone = (phone: any) => {
    return isValidPhoneNumber(phone);
  };

  const validateGST = (gst: any) => {
    const validate =
      /^[0-9]{2}[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    return validate.test(gst);
  };

  function isComplete(step: number) {
    switch (step) {
      case 0:
        return ![companyName, companyPhone, address].includes("");
      case 1:
        return ![firstName, lastName, userPhone, email].includes("");
      default:
        break;
    }
  }

  // ******* DAILOG ACTION FUNCTION'S *******
  function handleNext() {
    if (step === steps.length - 1) {
      handleSave();
    } else setStep(step + 1);
  }

  function handleBack() {
    setStep(step - 1);
  }

  function handleSave() {
    let newBody: any = {
      user: {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: userPhone,
        password: password,
      },
      company: {
        name: companyName,
        phone: companyPhone,
        address: address,
      },
    };
    if (gstNumber) {
      newBody.company.gstNumber = gstNumber;
    }
    setLoader(true);
    authorizedFetch(`${BOLT_URL}/company/v2/usergroup/vendor`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: newBody,
    })
      .then((res) => {
        setLoader(false);
        if (res.status === 201) {
          snackbar.success(`Company created successfully'`);
          queryClient.resetQueries("getBusiness");
          handleClose();
        } else {
          console.error(res?.message);
          snackbar.error(res?.message.split(" ").map((el: string) => titleCase(el)).join(" "));
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
        snackbar.error(err?.message);
      });
  }

  const disabled =
    [
      companyName,
      companyPhone,
      address,
      firstName,
      lastName,
      userPhone,
      email,
    ].includes("") ||
    !validateEmail(email) ||
    !validatePhone(userPhone) ||
    !validatePhone(companyPhone) ||
    !validatePassword(password) ||
    password !== password2;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: 800,
          width: 1,
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        Create Business
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>

      <DialogContent>
        <Stepper
          sx={{ my: 4, mx: "auto", maxWidth: 534 }}
          activeStep={step}
          nonLinear
          alternativeLabel
        >
          {steps.map((label, i) => (
            <Step key={i}>
              <StepButton onClick={() => setStep(i)}>{label}</StepButton>
            </Step>
          ))}
        </Stepper>

        <Collapse in={step === 0}>
          <>
            <Box
              sx={{
                maxWidth: { xs: 280, sm: 560 },
                mx: "auto",
                mb: 1,
              }}
            >
              <Typography variant="body2" color="text.secondary">
                <RedAsterisk /> Required fields
              </Typography>
            </Box>
            <Box
              sx={{
                maxWidth: { xs: 280, sm: 560 },
                mx: "auto",
                py: 2,
                display: "grid",
                gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                gap: 3,
              }}
            >
              <Box gridColumn={{ sm: "span 2" }}>
                <Typography className="label">
                  Company Name <RedAsterisk />
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Company Name"
                  value={companyName}
                  onChange={(e: any) => {
                    if (/^[A-Za-z0-9\s]*$/.test(e.target.value)) {
                      handleChange("companyName", e.target.value);
                    }
                  }}
                  inputProps={{ maxLength: 20 }}
                  autoComplete="off"
                  required
                />
              </Box>
              <Box>
                <Typography className="label">GST Number</Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="GST Number"
                  value={gstNumber}
                  onChange={(e: any) => {
                    if (/^[A-Za-z0-9\s]*$/.test(e.target.value.toUpperCase())) {
                      handleChange("gstNumber", e.target.value.toUpperCase());
                    }
                  }}
                  error={!!gstNumber && !validateGST(gstNumber)}
                  helperText={
                    gstNumber && !validateGST(gstNumber)
                      ? "Invalid GST Number"
                      : ""
                  }
                  inputProps={{ maxLength: 15 }}
                  autoComplete="off"
                />
              </Box>
              <Box>
                <Typography className="label">
                  Phone <RedAsterisk />
                </Typography>
                <PhoneInput
                  placeholder="Enter phone number"
                  value={companyPhone}
                  onChange={(value) =>
                    handleChange("companyPhone", value || "")
                  }
                  defaultCountry="IN"
                  international
                  limitMaxLength
                  inputComponent={PhoneTextField}
                  error={!!companyPhone && !isValidPhoneNumber(companyPhone)}
                />
              </Box>
              <Box gridColumn={{ sm: "span 2" }}>
                <Typography className="label">
                  Address <RedAsterisk />
                </Typography>
                <TextField
                  multiline
                  minRows={3}
                  fullWidth
                  placeholder="Address"
                  value={address}
                  onChange={(e) => handleChange("address", e.target.value)}
                  autoComplete="off"
                  required
                />
              </Box>
            </Box>
          </>
        </Collapse>

        <Collapse in={step === 1}>
          <>
            <Box
              sx={{
                maxWidth: { xs: 280, sm: 560 },
                mx: "auto",
                mb: 1,
              }}
            >
              <Typography variant="body2" color="text.secondary">
                <RedAsterisk /> Required fields
              </Typography>
            </Box>
            <Box
              sx={{
                maxWidth: { xs: 280, sm: 560 },
                mx: "auto",
                py: 2,
                display: "grid",
                gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                gap: 3,
              }}
            >
              <Box>
                <Typography className="label">
                  First Name <RedAsterisk />
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e: any) => {
                    if (/^[a-zA-Z]*$/.test(e.target.value)) {
                      handleChange("firstName", e.target.value);
                    }
                  }}
                  autoComplete="off"
                  required
                />
              </Box>
              <Box>
                <Typography className="label">
                  Last Name <RedAsterisk />
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e: any) => {
                    if (/^[a-zA-Z]*$/.test(e.target.value)) {
                      handleChange("lastName", e.target.value);
                    }
                  }}
                  autoComplete="off"
                  required
                />
              </Box>
              <Box>
                <Typography className="label">
                  Phone <RedAsterisk />
                </Typography>
                <PhoneInput
                  placeholder="Enter phone number"
                  value={userPhone}
                  onChange={(value) => handleChange("userPhone", value || "")}
                  defaultCountry="IN"
                  international
                  limitMaxLength
                  inputComponent={PhoneTextField}
                  error={!!userPhone && !isValidPhoneNumber(userPhone)}
                />
              </Box>
              <Box>
                <Typography className="label">
                  Email <RedAsterisk />
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Email"
                  value={email}
                  onChange={(e: any) => handleChange("email", e.target.value)}
                  error={!!email && !validateEmail(email)}
                  helperText={
                    email && !validateEmail(email) ? "Incomplete Email" : ""
                  }
                  autoComplete="off"
                  required
                />
              </Box>
              <Box>
                <Typography className="label">
                  Password <RedAsterisk />
                </Typography>
                <Box width={1} position="relative">
                  <TextField
                    sx={{ "& input": { pr: 7 } }}
                    placeholder="Password"
                    size="small"
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e: any) => setPassword(e.currentTarget.value)}
                    error={!!password && !validatePassword(password)}
                    helperText={
                      password && !validatePassword(password)
                        ? "Minimum 6 characters having one uppercase & one lowercase letter, one number & one special character"
                        : ""
                    }
                    autoComplete="new-password"
                  />
                  <IconButton
                    size="small"
                    onClick={() => setShowPassword((value) => !value)}
                    sx={{ position: "absolute", right: 8, top: 5 }}
                  >
                    {showPassword ? (
                      <VisibilityOffOutlined />
                    ) : (
                      <VisibilityOutlined />
                    )}
                  </IconButton>
                </Box>
              </Box>
              <Box>
                <Typography className="label">
                  Confirm Password <RedAsterisk />
                </Typography>
                <Box width={1} position="relative">
                  <TextField
                    sx={{ "& input": { pr: 7 } }}
                    placeholder="Confirm Password"
                    size="small"
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    value={password2}
                    onChange={(e: any) => setPassword2(e.currentTarget.value)}
                    error={!!password2 && password !== password2}
                    helperText={
                      password2 && password !== password2
                        ? "Password doesn't match"
                        : ""
                    }
                    autoComplete="new-password"
                  />
                  <IconButton
                    size="small"
                    onClick={() => setShowPassword((value) => !value)}
                    sx={{ position: "absolute", right: 8, top: 5 }}
                  >
                    {showPassword ? (
                      <VisibilityOffOutlined />
                    ) : (
                      <VisibilityOutlined />
                    )}
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </>
        </Collapse>

        <Collapse in={step === 2}>
          <Box
            sx={{
              maxWidth: 560,
              mx: "auto",
              "& .table": {
                borderCollapse: "collapse",
                width: 1,
                fontSize: 14,
                lineHeight: "16px",
                "& td": {
                  py: 1.25,
                  px: 2,
                },
                "& .bold": {
                  fontWeight: 500,
                },
                "& .header": {
                  px: 2,
                  py: 1,
                  position: "relative",
                  "& td": {
                    position: "absolute",
                    verticalAlign: "middle",
                    bgcolor: (theme: any) => theme.customColors.header,
                    width: 1,
                    borderRadius: "4px",
                    fontSize: 16,
                    fontWeight: 600,
                    "& span": {
                      display: "inline-block",
                      transform: "translateY(1px)",
                    },
                  },
                },
                "& .first > td": {
                  pt: 9,
                },
                "& .last > td": {
                  pb: 3,
                },
              },
            }}
          >
            <table className="table">
              <tbody>
                {[
                  { header: "Company Info", onEdit: () => setStep(0) },
                  { label: "Company Name", value: companyName, required: true },
                  {
                    label: "GST Number",
                    value: gstNumber,
                    isValid: validateGST(gstNumber),
                  },
                  {
                    label: "Phone",
                    value: formatPhoneNumberIntl(companyPhone),
                    required: true,
                    isValid: validatePhone(companyPhone),
                  },
                  { label: "Address", value: address, required: true },

                  { header: "User Info", onEdit: () => setStep(1) },
                  { label: "First Name", value: firstName, required: true },
                  { label: "Last Name", value: lastName, required: true },
                  {
                    label: "Phone",
                    value: formatPhoneNumberIntl(userPhone),
                    required: true,
                    isValid: validatePhone(userPhone),
                  },
                  {
                    label: "Email",
                    value: email,
                    required: true,
                    isValid: validateEmail(email),
                  },
                ].map(
                  (
                    { header, onEdit, label, value, required, isValid },
                    i,
                    arr
                  ) => {
                    const isFirst = arr[i - 1]?.header;
                    const isLast = !arr[i + 1] || arr[i + 1].header;

                    return (
                      <tr
                        key={i}
                        className={
                          header
                            ? "header"
                            : `${isFirst ? "first" : ""} ${
                                isLast ? "last" : ""
                              }`
                        }
                      >
                        {header ? (
                          <td colSpan={2}>
                            <span>{header}</span>
                            <IconButton
                              sx={{ ml: 1.5 }}
                              children={<EditOutlined />}
                              color="primary"
                              size="small"
                              onClick={onEdit}
                            />
                          </td>
                        ) : (
                          <>
                            <td>{label}</td>
                            <td className="bold">
                              {value !== "" ? (
                                <Box display="flex" alignItems="center">
                                  {value}
                                  {isValid === false && (
                                    <>
                                      <WarningAmber
                                        fontSize="small"
                                        color="warning"
                                        sx={{ ml: 2, mr: 1 }}
                                      />
                                      Invalid
                                    </>
                                  )}
                                </Box>
                              ) : (
                                required && (
                                  <Box display="flex" alignItems="center">
                                    <ErrorOutline
                                      fontSize="small"
                                      color="error"
                                      sx={{ mr: 1 }}
                                    />
                                    Required
                                  </Box>
                                )
                              )}
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </Box>
        </Collapse>
      </DialogContent>

      <DialogActions>
        {step !== 0 && (
          <Button
            variant="outlined"
            sx={{
              borderRadius: 10,
              borderWidth: "1.5px !important",
              textTransform: "none",
              fontSize: "16px",
            }}
            onClick={handleBack}
          >
            Back
          </Button>
        )}
        <Button
          sx={{
            borderRadius: 10,
            textTransform: "none",
            fontSize: "16px",
          }}
          variant={
            isComplete(step) || step === steps.length - 1
              ? "contained"
              : "outlined"
          }
          onClick={handleNext}
          disableElevation
          disabled={step === steps.length - 1 && disabled}
        >
          {step === steps.length - 1
            ? "Create"
            : isComplete(step)
            ? "Next"
            : "Skip"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateBusiness;
