import "./assets/styles/normalize.css";
import "./assets/styles/global.css";
import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "@apollo/client/react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { HelmetProvider } from "react-helmet-async";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// import reportWebVitals from './reportWebVitals'
import App from "./App";
import store from "./store";
import storageManager from "./utils/storageManager";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { GQL_URL } from "utils/constants";
import { snackbar } from "utils";
import { onTokenFail } from "utils/request";

if (window.location.hostname === "console.bolt.earth") {
  console.log = () => {};
}

const authLink = setContext((_, { headers }) => {
  let token = storageManager.get("boltToken");
  return {
    headers: {
      ...headers,
      ...(token ? { authorization: `Bearer ${token}` } : {}),
    },
  };
});
const httpLink = createHttpLink({
  // uri: 'http://localhost:8001'
  // uri: "https://gql.revos.in",
  uri: GQL_URL,
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  // defaultOptions: {
  //   query: { fetchPolicy: "no-cache" },
  //   mutate: { fetchPolicy: "no-cache" },
  //   watchQuery: { fetchPolicy: "no-cache" },
  // },
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onSuccess: (res: any) => {
        if (
          [res?.message, res?.msg].some((el) =>
            ["INVALID AUTHORIZATION TOKEN", "UNAUTHORIZED"].includes(el)
          ) ||
          res?.status === 401 // Logout on expired token
        ) {
          console.log("Invalid user");
          onTokenFail();
        }
      },
      onError: (res: any) => {
        console.error(res);
        if (res?.status === 401) {
          onTokenFail();
        } else {
          snackbar.error("Error fetching data");
        }
      },
      refetchOnWindowFocus: false,
    },
    // mutations: {
    //   onError: () => snackbar.error("An error occurred. Please try later."),
    // },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <Router>
            <HelmetProvider>
              <App />
            </HelmetProvider>
          </Router>
        </Provider>
      </ApolloProvider>
      <ReactQueryDevtools position="bottom-right" />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    // console.log("Update available. Reloading...");
    // snackbar.info("An update is available.", {
    //   buttonText: "Refresh",
    //   buttonAction: () => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
    window.location.reload();
    //   },
    // });
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// reportWebVitals()
