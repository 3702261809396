import { Box, IconButton } from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  GlobalState,
  authorizedFetch,
  drawer,
  getDarkModePreference,
} from "utils";
// import { ReactComponent as DeleteIcon } from "assets/images/icons/delete.svg";
import { ReactComponent as CloseIcon } from "assets/images/icons/close.svg";
import { useState } from "react";
import DeleteDialog from "./DeleteDialog";
import { RETAIL_URL } from "utils/constants";
import { useQuery } from "react-query";

const InfoDrawer = ({ ota, refectData }: any) => {
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    data: "",
  });

  const otaUrl = `${RETAIL_URL}/inventory/ota/${ota?._id}`;
  const { data: otaData } = useQuery(["getOta", ota?._id], () =>
    authorizedFetch(otaUrl)
  );

  const table = [
    { header: "File Information" },
    {
      label: "Company Name",
      value: ota?.company?.name || "-",
    },
    {
      label: "Vehicle Model",
      value: ota?.vehicleModel?.name || "-",
    },
    {
      label: "Speedometer",
      value: ota?.vehicleModel?.components[0]?.modelId[0]?.key,
    },
    {
      label: "Previous Firmware Req.",
      value: ota?.preRequisite?.version || "-",
    },
    {
      label: "File Name",
      value: ota.fileName || "-",
    },
    {
      label: "Update Type",
      value: ota?.type || "-",
    },
    {
      label: "Firmware Version",
      value: ota?.version || "-",
    },
    {
      label: "JSON Version",
      value: ota?.json || "-",
    },
    {
      label: "CREATED ON",
      value: moment(ota.createdAt).format("DD-MM-YYYY"),
    },
    {
      label: "Total No of Vehicle",
      value: otaData?.data[0]?.vehiclesCount,
    },
    {
      label: "Update Available For",
      value: otaData?.data[0]?.vehiclesForUpdateCount,
    },
  ];

  return (
    <>
      <DeleteDialog
        open={deleteDialog.open}
        handleClose={() => setDeleteDialog({ ...deleteDialog, open: false })}
        otaId={deleteDialog.data}
        onDelete={() => drawer.close()}
        refectData={refectData}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            px: 3,
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: isDarkMode ? "#000" : "#3D3D3D",
            fontWeight: 500,
            color: "#fff",
          }}
        >
          {ota.fileName || "-"}
          <IconButton
            children={<CloseIcon />}
            color="inherit"
            size="small"
            onClick={() => drawer.close()}
          />
        </Box>
        <Box flexGrow={1} overflow="auto" pb={4}>
          {/* <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Tooltip title="Delete">
              <IconButton
                sx={{ mr: 3, mt: 1 }}
                children={<DeleteIcon />}
                color="inherit"
                size="small"
                onClick={() =>
                  setDeleteDialog({
                    open: true,
                    data: ota._id,
                  })
                }
              />
            </Tooltip>
          </Box> */}
          <Box
            sx={{
              px: 3,
              pt: 2.5,
              "& .table": {
                borderCollapse: "collapse",
                width: 1,
                fontSize: 14,
                lineHeight: "16px",
                "& td": {
                  py: 1.5,
                  px: 2,
                  "&.secondary": {
                    color: "text.secondary",
                  },
                },
                "& .bold": {
                  fontWeight: 500,
                },
                "& .header": {
                  position: "relative",
                  "& td": {
                    py: 2,
                    position: "absolute",
                    verticalAlign: "middle",
                    backgroundColor: (theme: any) => theme.customColors.header,
                    width: 1,
                    borderRadius: "4px",
                    fontSize: 16,
                    fontWeight: 600,
                    "& span": {
                      display: "inline-block",
                      transform: "translateY(1px)",
                    },
                  },
                },
                "& .first > td": {
                  pt: 8.5,
                },
                "& .last > td": {
                  pb: 3,
                },
              },
            }}
          >
            <table className="table">
              <tbody>
                {table.map(({ header, label, value }, i) => {
                  const isFirst = table[i - 1]?.header;
                  const isLast = !table[i + 1] || table[i + 1].header;

                  return (
                    <tr
                      key={i}
                      className={
                        header
                          ? "header"
                          : `${isFirst ? "first" : ""} ${isLast ? "last" : ""}`
                      }
                    >
                      {header ? (
                        <td colSpan={2}>
                          <span>{header.toUpperCase()}</span>
                        </td>
                      ) : (
                        <>
                          <td className="bold">{label}</td>
                          <td
                            className={
                              value === "Loading..." ? "secondary" : ""
                            }
                          >
                            {value}
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default InfoDrawer;
