import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  InputAdornment,
  Tooltip,
  IconButton,
} from "@mui/material";
import { OpenInNew, HighlightOff, } from "@mui/icons-material";
import { setLoader, snackbar } from "utils";
import { BOLT_URL } from "utils/constants";
import { authorizedFetch, drawer } from "utils";
import { queryClient } from "index";

interface UpdateDialogProps {
  open: boolean;
  handleClose: () => void;
  group: any;
  type: string;
}

const UpdateDialog: React.FC<UpdateDialogProps> = ({
  open,
  handleClose,
  group,
  type,
}) => {
  const [fund, setFund] = useState("");

  function handleOperation(mode: string) {
    setLoader(true);
    if (mode === "FUND") {
      authorizedFetch(
        `${BOLT_URL}/company/v2/usergroup/${group?._id}/addToWallet?amount=${fund}`,
        {
          method: "POST",
        }
      )
        .then((res) => {
          setLoader(false);
          if (res.status === 200) {
            window.open(res.data.paymentlinks.web, "_blank");
            handleClose();
          } else {
            snackbar.error(res.message || "An error occurred");
          }
        })
        .catch((err) => {
          setLoader(false);
          console.error(err);
          snackbar.error("An error occurred");
        });
    } else if (mode === "STATUS") {
      authorizedFetch(`${BOLT_URL}/company/v2/usergroup/status/${group?._id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          status: group?.status === "ACTIVE"
            ? "INACTIVE"
            : "ACTIVE",
        },
      })
        .then((res) => {
          setLoader(false);
          if (res.status === 200) {
            snackbar.success(`User group "${group?.name}" has been ${group?.status === "ACTIVE" ? "deactivated" : "activated"}`
            );
            queryClient.resetQueries("getClosedUserGroups");
            handleClose();
            drawer.close();
          } else if (res.status === 400) {
            console.error(res);
            snackbar.error(res?.message);
          } else {
            console.error(res);
            snackbar.error(
              `Error ${group?.status === "ACTIVE"
                ? "deactivating"
                : "activating"
              } user group "${group?.name}"`
            );
          }
        })
        .catch((err) => {
          setLoader(false);
          console.error(err);
          snackbar.error(
            `Error ${group?.status === "ACTIVE"
              ? "deactivating"
              : "activating"
            } user group "${group?.name}"`
          );
        });
    } else {
      authorizedFetch(`${BOLT_URL}/company/v2/usergroup/status/${group?._id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          status: "DELETED"
        },
      })
        .then((res) => {
          setLoader(false);
          if (res.status === 200) {
            snackbar.success(`Deleted user group "${group?.name}"`);
            queryClient.resetQueries("getClosedUserGroups");
            handleClose();
            drawer.close();
          } else {
            console.error(res);
            snackbar.error(
              `Error deleting user group "${group?.name}"`
            );
          }
        })
        .catch((err) => {
          setLoader(false);
          console.error(err);
          snackbar.error(
            `Error deleting user group "${group?.name}"`
          );
        });
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box>
          {type === "STATUS" && (group?.status === "ACTIVE"
            ? <>Deactivate <b>{group?.name}</b> Group</>
            : <>Activate <b>{group?.name}</b> Group</>)}
          {type === "DELETE" && (<>Delete <b>{group?.name}</b> Group</>)}
          {type === "FUND" && (<>Add  Funds to <b>{group?.name}</b> Group</>)}
        </Box>
        <Tooltip title="Close">
          <IconButton
            children={<HighlightOff />}
            color="inherit"
            onClick={handleClose}
            sx={{ transform: "translate(8px, -8px)" }}
          />
        </Tooltip>
      </DialogTitle>

      <DialogContent className="py-1">
        {type === "STATUS" && (<>Are you sure you want to {group?.status === "ACTIVE"
          ? "deactivate"
          : "activate"} "<b>{group?.name}</b>"?</>)}
        {type === "DELETE" && (<>Are you sure you want to delete "<b>{group?.name}</b>"?</>)}
        {type === "FUND" && (<Box
          sx={{
            width: 400,
            mx: "auto",
            display: "grid",
            gridTemplateColumns: "1fr",
            gap: 3,
            py: 2,
          }}
        >
          <Box>
            <Typography className="label">
              Amount (INR)
            </Typography>
            <TextField
              fullWidth
              size="small"
              placeholder="Amount"
              // sx={{
              //   "& .MuiOutlinedInput-root": {
              //     "& fieldset": {
              //       borderRadius: 10,
              //     },
              //   },
              // }}
              inputProps={{ maxLength: 3 }}
              value={fund}
              onChange={(e: any) => {
                if (/^[0-9]{0,10}$/.test(e.target.value)) {
                  setFund(e.target.value)
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ ml: 1.5 }}>
                    ₹
                  </InputAdornment>
                ),
              }}
              autoComplete="off"
            />
          </Box>
        </Box>)}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        {type === "FUND"
          ? (<Button
            variant="contained"
            onClick={() => handleOperation("FUND")}
            disabled={fund === ""}
            endIcon={<OpenInNew sx={{ ml: 0.25 }} />}
          >
            {`Pay ${fund !== "" ? `₹${fund}` : ""}`}
          </Button>)
          : type === "DELETE"
            ? (<Button variant="contained" onClick={() => handleOperation("DELETE")}>Delete</Button>)
            : (<Button variant="contained" onClick={() => handleOperation("STATUS")}>
              {group?.status === "ACTIVE"
                ? "Deactivate"
                : "Activate"}
            </Button>)
        }
      </DialogActions>
    </Dialog>
  );
};

export default UpdateDialog;
