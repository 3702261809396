import {
  Close,
  FilterAlt,
  ModeOfTravelOutlined,
  Moving,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  Typography,
} from "@mui/material";
import CircularLoader from "components/CircularLoader";
import RangePicker from "components/RangePicker";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { authorizedFetch, getDarkModePreference, GlobalState } from "utils";
import { LEASE_URL, TRIPS_URL } from "utils/constants";

const Stats = ({ vehicle }: any) => {
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );
  // const { company } = useSelector((state: GlobalState) => state.global);

  const [useRange, setUseRange] = useState(false);
  const [range, setRange] = useState<any>([
    moment().subtract(1, "years").toDate(),
    new Date(),
  ]);

  let dateFrom = moment(range[0]).format("YYYY-MM-DD");
  let dateTo = moment(range[1]).format("YYYY-MM-DD");

  // const statsUrl = `${DATAFEED_URL}/vehicle/stats?vin=${vehicle?.vin}`;
  // const { isLoading: statsLoading, data: statsData } = useQuery(
  //   ["getVehicleStats", vehicle?.vin],
  //   () => authorizedFetch(statsUrl),
  //   {
  //     enabled: Boolean(vehicle?.vin),
  //   }
  // );

  // const insightsUrl = `${DATAFEED_URL}/v1/vehicle/vin?token=${company.id}&vin=${vehicle?.vin}`;
  // const { isLoading: insightsLoading, data: insightsData } = useQuery(
  //   // ["getVehicleStats", dateFrom, dateTo],
  //   ["getVehicleStats", vehicle?.vin],
  //   () => authorizedFetch(insightsUrl)
  // );

  const { isLoading: statsLoading, data: statsData } = useQuery(
    ["getVehicleTrips"],
    () => authorizedFetch(`${TRIPS_URL}/trips/analytics/${vehicle?.vin}`)
  );

  function parseData(array: any[], key: string) {
    array = array.sort(
      (a: any, b: any) => moment(a.date).valueOf() - moment(b.date).valueOf()
    );
    let chartData = array.reduce((acc: any, cur: any) => {
      let day = moment(cur.date).format("MMM D, yyyy");
      const existing = acc.find((el: any) => el.x === day);
      if (existing) {
        existing.y += cur[key];
      } else {
        acc.push({ x: day, y: cur[key] });
      }
      return acc;
    }, []);
    return chartData;
  }

  const [usersList, setUsersList] = useState<any>();
  const [bookingsList, setBookingList] = useState<any>();
  const [earningsList, setEarningsList] = useState<any>();

  const bookingsUrl = `${LEASE_URL}/company/vehicle/${vehicle?.vin}/insights/bookings?timeframe=custom&starttime=${dateFrom}&endtime=${dateTo}`;
  const earningsUrl = `${LEASE_URL}/company/vehicle/${vehicle?.vin}/insights/earnings?timeframe=custom&starttime=${dateFrom}&endtime=${dateTo}`;
  const usersUrl = `${LEASE_URL}/company/vehicle/${vehicle?.vin}/insights/users?timeframe=custom&starttime=${dateFrom}&endtime=${dateTo}`;

  const { isLoading: bookingsLoading, data: bookingsData } = useQuery(
    ["bookingInsights", dateFrom, dateTo],
    () => authorizedFetch(bookingsUrl)
  );
  const { isLoading: earningsLoading, data: earningsData } = useQuery(
    ["earningInsights", dateFrom, dateTo],
    () => authorizedFetch(earningsUrl)
  );
  const { isLoading: usersLoading, data: usersData } = useQuery(
    ["usersInsights", dateFrom, dateTo],
    () => authorizedFetch(usersUrl)
  );

  useEffect(() => {
    if (bookingsData?.data?.aggregated?.constructor === Array) {
      let chartData = parseData(bookingsData.data.aggregated, "totalBookings");
      setBookingList(chartData);
    }
  }, [bookingsData]);
  useEffect(() => {
    if (earningsData?.data?.aggregated?.constructor === Array) {
      let chartData = parseData(earningsData.data.aggregated, "totalEarnings");
      setEarningsList(chartData);
    }
  }, [earningsData]);
  useEffect(() => {
    if (usersData?.data?.aggregated?.constructor === Array) {
      let chartData = parseData(usersData.data.aggregated, "totalUsers");
      setUsersList(chartData);
    }
  }, [usersData]);

  let key1 = (bookingsList || []).length;
  let key2 = (bookingsList || []).length + 1;
  let key3 = (bookingsList || []).length + 2;

  return (
    <Box sx={{ p: 2.5, pt: 1 }}>
      {statsLoading ? (
        <CircularLoader />
      ) : (
        // : !data || isError
        //   ? <Box sx={{
        //     display: "flex",
        //     justifyContent: "center",
        //   }}>
        //     <Typography variant="body2" color="text.secondary">No data available</Typography>
        //   </Box>
        <>
          {/* <Typography variant="h6" gutterBottom>Stats</Typography> */}
          <Box
            sx={{
              mb: 3,
              display: "grid",
              gridTemplateColumns: { sm: "1fr 1fr" },
              gap: 1,
              "& > div": {
                width: 1,
                p: 1.5,
                display: "flex",
                border: 1,
                borderRadius: "3px",
                borderColor: (theme) => theme.customColors.border,
                "& .MuiAvatar-root": {
                  mr: 1,
                },
                "& .info": {
                  display: "flex",
                  flexDirection: "column",
                  "& .value": {
                    fontSize: 17,
                    fontWeight: 600,
                    color: "text.primary",
                    lineHeight: "1.15em",
                  },
                  "& .label": {
                    fontSize: 12,
                    color: "text.secondary",
                  },
                },
              },
            }}
          >
            <Box>
              <Avatar variant="icon">
                <ModeOfTravelOutlined />
              </Avatar>
              <Box className="info">
                <span className="value">
                  {statsData?.data[0]?.count || "0"}
                </span>
                <span className="label">Trips</span>
              </Box>
            </Box>
            <Box>
              <Avatar variant="icon">
                <Moving />
              </Avatar>
              <Box className="info">
                <span className="value">
                  {typeof statsData?.data[0]?.totalTripDistance === "number"
                    ? (statsData?.data[0]?.totalTripDistance / 1000).toFixed(
                        2
                      ) + " km"
                    : "0 km"}
                </span>
                <span className="label">Distance Travelled</span>
              </Box>
            </Box>
          </Box>
          <Box
            mb={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Insights</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
              }}
            >
              <Collapse in={!useRange} mountOnEnter unmountOnExit>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => setUseRange(true)}
                  startIcon={<FilterAlt />}
                >
                  Filter by date
                </Button>
              </Collapse>
              <Collapse
                in={useRange}
                collapsedSize={0}
                mountOnEnter
                unmountOnExit
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <RangePicker
                    range={range}
                    setRange={setRange}
                    initialRange="1Y"
                  />
                  <IconButton
                    size="small"
                    sx={{ ml: 0.5, mr: -0.5 }}
                    onClick={() => setUseRange(false)}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                </Box>
              </Collapse>
            </Box>
          </Box>
          <Box
            sx={{
              display: "grid",
              gap: 2,
              "& > div": {
                border: 1,
                bgcolor: isDarkMode ? "background.default" : "#fff",
                borderColor: (theme: any) => theme.customColors.border,
                borderRadius: 1,
                p: 2.5,
                display: "flex",
                flexDirection: "column",
                "& span": {
                  display: "flex",
                  alignItems: "center",
                  "& span": {
                    alignItems: "end",
                  },
                },
                "& .value": {
                  fontSize: "1.75rem",
                  fontWeight: 700,
                  lineHeight: "1.75rem",
                },
                "& .unit": {
                  ml: 0.75,
                },
                "& .title": {
                  ml: 1.5,
                  color: "text.secondary",
                },
              },
            }}
          >
            <Box key={key1}>
              {bookingsLoading ? (
                loader
              ) : (
                <LineChart data={bookingsList || []} />
              )}

              <span>
                <Typography className="value">
                  {bookingsData?.data?.count}
                </Typography>
                <Typography className="title">Total Bookings</Typography>
              </span>
            </Box>
            <Box key={key2}>
              {earningsLoading ? (
                loader
              ) : (
                <LineChart isBlue data={earningsList || []} />
              )}
              <span>
                <Typography className="value">
                  ₹{earningsData?.data?.total_earnings}
                </Typography>
                <Typography className="title">Total Earnings</Typography>
              </span>
            </Box>
            <Box key={key3}>
              {usersLoading ? (
                loader
              ) : (
                <LineChart isBlue data={usersList || []} />
              )}
              <span>
                <Typography className="value">
                  {usersData?.data?.count}
                </Typography>
                <Typography className="title">Total Users</Typography>
              </span>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

const loader = (
  <Box
    sx={{
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 70,
      width: 1,
      mb: "3px",
    }}
  >
    <CircularProgress color="primary" size={24} />
  </Box>
);

const LineChart: React.FC<{ isBlue?: boolean; data: any[] }> = ({
  isBlue,
  data,
}) => {
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );
  const min = useMemo(() => {
    return data?.reduce((acc, cur) => (cur.y < acc ? cur.y : acc), 0);
  }, [data]);
  const max = useMemo(() => {
    return data?.reduce((acc, cur) => (cur.y > acc ? cur.y : acc), 0);
  }, [data]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        position: "relative",
        height: 70,
        width: 1,
        mb: "3px",
      }}
    >
      <Line
        height={10}
        style={{ marginTop: 10 }}
        data={(canvas) => {
          const ctx = canvas.getContext("2d");
          const g = ctx?.createLinearGradient(0, 0, 0, 60);

          g?.addColorStop(
            0,
            isBlue ? "rgba(97, 209, 105, 0.7)" : "rgba(97, 209, 105, 0.6)"
          );
          g?.addColorStop(
            0.5,
            isBlue ? "rgba(97, 209, 105, 0.4)" : "rgba(97, 209, 105, 0.2)"
          );
          g?.addColorStop(
            1,
            isDarkMode ? "rgba(0, 0, 0, 0)" : "rgba(255, 255, 255, 0)"
          );

          let color = isBlue ? "rgba(97,209,105)" : "rgba(97,209,105)";

          return {
            // labels: data?.map(el => el.t),
            datasets: [
              {
                fill: true,
                data: data,
                borderColor: color,
                backgroundColor: g,
                tension: 0.4,
                pointRadius: 0,
                pointHoverRadius: 4,
                pointHoverBackgroundColor: "#fff",
                pointHoverBorderColor: color,
                pointHoverBorderWidth: 3,
              },
            ],
          };
        }}
        options={{
          scales: {
            xAxis: {
              display: false,
              // type: "linear",
              // type: 'time'
            },
            yAxis: {
              display: false,
              suggestedMax: max + 1,
              suggestedMin: min - 1,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              caretSize: 0,
              mode: "index",
              intersect: false,
              yAlign: "center",
              displayColors: false,
              caretPadding: 16,
              titleFont: {
                weight: "400",
              },
              bodyFont: {
                weight: "500",
              },
            },
          },
          layout: {
            padding: {
              bottom: 10,
            },
          },
          interaction: {
            mode: "index",
            intersect: false,
          },
        }}
      />
    </Box>
  );
};

export default Stats;
