import { Box, Paper, Typography, useTheme } from "@mui/material";
import { Line } from "react-chartjs-2";
import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import "chartjs-adapter-moment";

const Chart = ({
  isLoading,
  vehicle,
  vehicleLogs,
  isDaily,
  isTripsPage,
  isVMS,
}: any) => {
  const [data, setData] = useState<any>({
    voltage: [],
    speed: [],
  });

  useEffect(() => {
    let commonArray: any[] = [];

    const isVMSTripView = isTripsPage && isVMS;

    const uartArray = isVMSTripView ? vehicleLogs?.metrics : vehicleLogs?.uart;

    const hasUartData =
      uartArray?.constructor === Array && uartArray.length > 0;

    let { wheelDiameter } =
      vehicle?.config?.vehicleConfigValues ||
      vehicle?.model?.config?.configs ||
      {};

    const hasWheelDiameter = ![null, undefined].includes(wheelDiameter);
    const hasWheelRpm =
      hasUartData &&
      uartArray.some((el: any) => ![null, undefined].includes(el.wheelRpm));

    let voltageData =
      !isVMSTripView && hasUartData
        ? uartArray
        : vehicleLogs?.battery?.constructor === Array
        ? vehicleLogs.battery
        : [];
    let speedData =
      hasWheelDiameter && hasWheelRpm
        ? uartArray
        : vehicleLogs?.location?.constructor === Array
        ? vehicleLogs.location
        : [];

    voltageData.forEach((el: any) => {
      commonArray.push({
        voltage: isTripsPage
          ? el.batteryVoltageAdc
          : ![null, undefined].includes(el.batteryVoltage)
          ? el.batteryVoltage
          : el.batteryVoltageAdc || 0,
        speed: null,
        timestamp: el.timestamp,
      });
    });

    speedData.forEach((el: any) => {
      let speed =
        hasWheelDiameter && hasWheelRpm
          ? el.wheelRpm / wheelDiameter
          : el.gpsSpeed;
      if (speed < 0) speed = 0;
      let index = commonArray.findIndex(
        (el2: any) => el.timestamp === el2.timestamp
      );
      if (index !== -1) {
        commonArray[index].speed = speed;
      } else {
        commonArray.push({
          voltage: null,
          speed: speed,
          timestamp: el.timestamp,
        });
      }
    });

    commonArray.sort(
      (a: any, b: any) =>
        moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf()
    );

    const voltage = commonArray.map((el: any) => ({
      x: new Date(el.timestamp),
      y: el.voltage,
    }));
    const speed = commonArray.map((el: any) => ({
      x: new Date(el.timestamp),
      y: el.speed,
    }));

    setData({ voltage, speed });
  }, [vehicle, vehicleLogs, isDaily, isTripsPage, isVMS]);

  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          mb: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography mb={1} variant="h6">
          Voltage vs Speed
        </Typography>
        <Box
          sx={{
            // mb: 3,
            display: "flex",
            alignItems: "center",
            "& .text": {
              position: "relative",
              fontSize: 13,
              lineHeight: "1em",
              color: (theme) => theme.customColors.greySecondary,
              "&:before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: -18,
                height: 10,
                width: 10,
                backgroundColor: (theme) => theme.customColors.violetSecondary,
                borderRadius: 1,
              },
            },
            "& .bookings": {
              ml: 2.5,
              "&:before": {
                backgroundColor: (theme) => theme.customColors.yellowSecondary,
              },
            },
          }}
          display="flex"
          alignItems="center"
        >
          <Box display="flex" alignItems="center">
            <span className="text bookings">Voltage (V)</span>
          </Box>
          <Box display="flex" alignItems="center" ml={4.5}>
            <span className="text earnings">Speed (km/h)</span>
          </Box>
        </Box>
      </Box>
      <LineChart {...{ data, isDaily, isLoading }} />
    </Paper>
  );
};

const LineChart = ({ data, isDaily, isLoading }: any) => {
  const theme = useTheme();

  const { voltageData, speedData, min, max } = useMemo(() => {
    let voltageData = data?.voltage?.constructor === Array ? data.voltage : [];
    let speedData = data?.speed?.constructor === Array ? data.speed : [];

    let min = undefined,
      max = undefined;

    if (voltageData?.[0] && speedData?.[0]) {
      const voltageDataStart = moment(voltageData[0].x).valueOf();
      const speedDataStart = moment(speedData[0].x).valueOf();

      const voltageDataEnd = moment(
        voltageData[voltageData.length - 1].x
      ).valueOf();
      const speedDataEnd = moment(speedData[speedData.length - 1].x).valueOf();

      min =
        voltageDataStart < speedDataStart ? voltageDataStart : speedDataStart;
      max = voltageDataEnd > speedDataEnd ? voltageDataEnd : speedDataEnd;
    }

    return { voltageData, speedData, min, max };
  }, [data]);

  return (
    <Box
      sx={{
        width: 1,
        height: 240,
      }}
    >
      {!isLoading && (
        <Line
          data={(canvas) => {
            return {
              datasets: [
                {
                  label: "Voltage",
                  data: voltageData,
                  pointBackgroundColor: theme.customColors.yellowSecondary,
                  borderColor: theme.customColors.yellowSecondary,
                  yAxisID: "yAxis",
                },
                {
                  label: "Speed",
                  data: speedData,
                  pointBackgroundColor: theme.customColors.violetSecondary,
                  borderColor: theme.customColors.violetSecondary,
                  yAxisID: "yAxis2",
                },
              ],
            };
          }}
          options={{
            animation: {
              duration: 0,
            },
            datasets: {
              line: {
                spanGaps: true,
                tension: 0.2,
                borderWidth: 2,
                pointRadius:
                  voltageData.length === 1 && speedData.length === 1 ? 2 : 0,
                pointHoverRadius: 4,
                pointHoverBackgroundColor: "#fff",
                pointHoverBorderWidth: 3,
              },
            },
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                caretSize: 0,
                mode: "index",
                intersect: false,
                yAlign: "center",
                usePointStyle: true,
                // displayColors: false,
                caretPadding: 16,
                titleFont: {
                  weight: "400",
                },
                bodyFont: {
                  weight: "500",
                },
              },
            },
            interaction: {
              mode: "index",
              intersect: false,
            },
            scales: {
              xAxis: {
                type: "time",
                offset: true,
                min,
                max,
                // time: {
                // unit: isDaily ? "minute" : "second",
                // unit:
                // },
                grid: {
                  display: false,
                  drawBorder: false,
                  tickLength: 0,
                },
                ticks: {
                  major: {
                    enabled: true,
                  },
                  color: theme.palette.text.secondary,
                },
              },
              yAxis: {
                offset: true,
                title: {
                  display: true,
                  text: "Voltage (V)",
                  padding: {
                    top: 0,
                    bottom: 15,
                  },
                  color: theme.customColors.grey,
                  font: {
                    weight: "500",
                    size: 12,
                  },
                },
                ticks: {
                  color: theme.palette.text.secondary,
                },
                min: 0,
                grid: {
                  borderDash: [10],
                  tickWidth: 0,
                  tickLength: 16,
                  drawBorder: false,
                },
              },
              yAxis2: {
                offset: true,
                position: "right",
                title: {
                  display: true,
                  text: "Speed (km/h)",
                  padding: {
                    top: 0,
                    bottom: 15,
                  },
                  color: theme.customColors.grey,
                  font: {
                    weight: "500",
                    size: 12,
                  },
                },
                ticks: {
                  color: theme.palette.text.secondary,
                  // callback: (label, index, lables) => '₹' + label
                },
                min: 0,
                grid: {
                  display: false,
                  tickWidth: 0,
                  tickLength: 16,
                  drawBorder: false,
                },
              },
            },
          }}
        />
      )}
    </Box>
  );
};

export default Chart;
