import { Box, Paper } from "@mui/material";
import MapComponent from "components/Map";
import { useSelector } from "react-redux";
import { GlobalState } from "utils";
import ActiveUsers from "./ActiveUsers";
import ChargerAccess from "./ChargerAccess";
import ChargerAvailability from "./ChargerAvailability";
import NewChargers from "./NewChargers";
import NewUsers from "./NewUsers";
import OperationalStatus from "./OperationalStatus";
import TopChargers from "./TopChargers";
import TopHosts from "./TopHosts";
import TopLocations from "./TopLocations";
import TopUsers from "./TopUsers";
import TotalBookings from "./TotalBookings";
import TotalEarnings from "./TotalEarnings";
import TotalEnergyConsumpution from "./TotalEnergyConsumption";

const Cards = ({ statsLoading, statsData, params, locations }: any) => {
  const { masterView } = useSelector((state: GlobalState) => state.global);

  return (
    <Box
      sx={{
        px: { xs: 2, md: 7 },
        pt: { xs: 2, md: 4.75 },
        pb: { xs: 3, md: 7 },
        display: "grid",
        gridTemplateColumns: {
          lg: "repeat(12, 1fr)",
        },
        gap: { xs: 2, md: 4.5 },
        "& .MuiPaper-root": {
          borderRadius: "14px",
          boxShadow: (theme) => theme.customShadows.card,
          height: { xs: 260, md: 438 },
        },
      }}
    >
      <Paper
        sx={{
          gridColumn: { lg: "span 7" },
          overflow: "hidden",
          height: { xs: 260, md: 438 },
        }}
      >
        <MapComponent
          key={JSON.stringify({ masterView, locations })}
          loading={statsLoading}
          type="points"
          dataArray={statsData?.data?.chargers || []}
        />
      </Paper>
      <Paper
        sx={{
          py: { xs: 3, md: 4 },
          px: { xs: 2, md: 5 },
          gridColumn: { lg: "span 5" },
        }}
      >
        <ChargerAvailability {...{ statsLoading, statsData }} />
      </Paper>
      <Paper
        sx={{
          py: { xs: 3, md: 4 },
          px: { xs: 2, md: 5 },
          gridColumn: { lg: "span 6" },
          height: { xs: "auto !important", md: 438 },
        }}
      >
        <OperationalStatus {...{ statsLoading, statsData }} />
      </Paper>
      <Box sx={{ gridColumn: { lg: "span 6" } }}>
        <TopLocations params={params} />
      </Box>
      <Box sx={{ gridColumn: { lg: "span 6" } }}>
        <ChargerAccess {...{ statsLoading, statsData }} />
      </Box>
      <Box sx={{ gridColumn: { lg: "span 6" } }}>
        <NewChargers params={params} />
      </Box>
      <Box
        sx={{
          gridColumn: { lg: "span 6" },
          height: { xs: 390, md: 438 },
          "& > .MuiPaper-root": {
            height: "100% !important",
          },
        }}
      >
        <TopChargers params={params} />
      </Box>
      <Box
        sx={{
          gridColumn: { lg: "span 6" },
          height: { xs: 390, md: 438 },
          "& > .MuiPaper-root": {
            height: "100% !important",
          },
        }}
      >
        <TopHosts params={params} />
      </Box>
      <Box sx={{ gridColumn: { lg: "span 6" } }}>
        <ActiveUsers params={params} />
      </Box>
      <Box sx={{ gridColumn: { lg: "span 6" } }}>
        <NewUsers params={params} />
      </Box>
      <Box
        sx={{
          gridColumn: { lg: "span 6" },
          height: { xs: 390, md: 438 },
          "& > .MuiPaper-root": {
            height: "100% !important",
          },
        }}
      >
        <TopUsers params={params} />
      </Box>
      <Box sx={{ gridColumn: { lg: "span 6" } }}>
        <TotalEarnings params={params} />
      </Box>
      <Box sx={{ gridColumn: { lg: "span 6" } }}>
        <TotalBookings params={params} />
      </Box>
      <Box sx={{ gridColumn: { lg: "span 6" } }}>
        <TotalEnergyConsumpution params={params} />
      </Box>
    </Box>
  );
};

export default Cards;
