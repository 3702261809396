import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  // AttachMoney,
  EventAvailableOutlined,
  HighlightOff,
  Timer,
  StraightenOutlined,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Avatar,
  Tabs,
  Tab,
  CircularProgress,
  // InputAdornment,
  // TextField,
  // Tab,
  // Tabs,
} from "@mui/material";
import {
  authorizedFetch,
  drawer,
  getDarkModePreference,
  getDuration,
  GlobalState,
  // setLoader,
  // snackbar,
} from "utils";
import moment from "moment";
import Table from "components/Table";
import { LEASE_URL } from "utils/constants";
import { useQuery } from "react-query";

// import Table from "components/Table";

const DrawerContent = ({ key, user }: any) => {
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [tab, setTab] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  // const [refundDialog, setRefundDialog] = useState({
  //   open: false,
  //   user: "",
  //   data: "",
  // });

  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );
  function DeleteHandleClose() {
    setDeleteDialog(false);
  }

  const userURL = `${LEASE_URL}/company/users/${
    user._id
  }/bookings?first=${pageSize}&skip=${pageSize * (page - 1)}`;

  const { isLoading: userLoading, data: userData } = useQuery(
    ["getUserBookings", page, pageSize, user],
    () => authorizedFetch(userURL)
  );

  // const userEarningsURL = `${LEASE_URL}/company/users/${user.id}/distance`;

  // const { data: userEarningsData } = useQuery("getUserDistance", () =>
  //   authorizedFetch(userEarningsURL)
  // );

  // console.log(userEarningsData);

  const userDurationURL = `${LEASE_URL}/company/users/${user._id}/duration`;

  const { data: userDurationData, isLoading: userDurationLoading } = useQuery(
    ["getUserDuration", user],
    () => authorizedFetch(userDurationURL)
  );

  const userDistanceURL = `${LEASE_URL}/company/users/${user._id}/distance`;

  const { data: userDistanceData, isLoading: userDistanceLoading } = useQuery(
    ["getUserDistance", user],
    () => authorizedFetch(userDistanceURL)
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            px: 3,
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: isDarkMode ? "#000" : "#3D3D3D",
            fontWeight: 500,
            color: "#fff",
          }}
        >
          {user.firstName
            ? `${
                user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1)
              } ${
                user.lastName.charAt(0).toUpperCase() + user.lastName.slice(1)
              }`
            : "Not Found"}
          <Box display="grid" gridTemplateColumns="repeat(2, auto)" gap={1}>
            {/* <IconButton
              children={<DeleteOutline />}
              color="inherit"
              size="small"
              onClick={() => setDeleteDialog(true)}
            /> */}
            {/* <Button
              variant="contained"
              size="small"
              sx={{ mr: 1 }}
              onClick={() =>
                SetRefundDialog({
                  open: true,
                  user: user,
                  data: "",
                })
              }
            >
              Refund
            </Button> */}
            <IconButton
              children={<HighlightOff />}
              color="inherit"
              size="small"
              onClick={() => drawer.close()}
            />
          </Box>
        </Box>
        <Box px={2.5} pt={2} sx={{ overflowX: "scroll" }}>
          <Box
            mb={1.5}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Stats</Typography>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: 1,
              "& > div": {
                width: 1,
                p: 1.5,
                display: "flex",
                border: 1,
                borderRadius: "3px",
                borderColor: (theme) => theme.customColors.border,
                "& .MuiAvatar-root": {
                  mr: 1,
                },
                "& .info": {
                  display: "flex",
                  flexDirection: "column",
                  "& .label": {
                    fontSize: 17,
                    fontWeight: 600,
                    color: "text.primary",
                    lineHeight: "1.15em",
                  },
                  "& .value": {
                    fontSize: 12,
                    color: "text.secondary",
                  },
                },
              },
            }}
          >
            <Box>
              <Avatar variant="icon">
                <Timer />
              </Avatar>
              <Box className="info">
                <span className="label">
                  {userDurationLoading ? (
                    <Box
                      sx={{
                        display: "flex",
                        gap: 5,
                        alignItems: "left",
                        justifyContent: "left",
                        width: "100%",
                        height: "auto",
                      }}
                    >
                      <CircularProgress
                        color="primary"
                        sx={{ maxWidth: 15, maxHeight: 15 }}
                      />
                    </Box>
                  ) : userDurationData?.data?.total_duration ? (
                    getDuration(userDurationData?.data?.total_duration)
                  ) : (
                    "0 min"
                  )}
                </span>
                <span className="value">Total Booking Duration</span>
              </Box>
            </Box>
            {/* <Box>
              <Avatar variant="icon">
                <AttachMoney />
              </Avatar>
              <Box className="info">
                <span className="label">
                  {"₹ " + userEarningsData?.data?.total_earnings || 0}
                </span>
                <span className="value">Earnings</span>
              </Box>
            </Box> */}
            <Box
            // sx={{
            //   gridColumn: { sm: "span 2" },
            // }}
            >
              <Avatar variant="icon">
                <EventAvailableOutlined />
              </Avatar>
              <Box className="info">
                <span className="label">
                  {userLoading ? (
                    <Box
                      sx={{
                        display: "flex",
                        gap: 5,
                        alignItems: "left",
                        justifyContent: "left",
                        width: "100%",
                        height: "auto",
                      }}
                    >
                      <CircularProgress
                        color="primary"
                        sx={{ maxWidth: 15, maxHeight: 15 }}
                      />
                    </Box>
                  ) : (
                    userData?.data?.count
                  )}
                </span>
                <span className="value">Total Bookings</span>
              </Box>
            </Box>
            <Box
              sx={{
                gridColumn: { sm: "span 2" },
              }}
            >
              <Avatar variant="icon">
                <StraightenOutlined />
              </Avatar>
              <Box className="info">
                <span className="label">
                  {userDistanceLoading ? (
                    <Box
                      sx={{
                        display: "flex",
                        gap: 5,
                        alignItems: "left",
                        justifyContent: "left",
                        width: "100%",
                        height: "auto",
                      }}
                    >
                      <CircularProgress
                        color="primary"
                        sx={{ maxWidth: 15, maxHeight: 15 }}
                      />
                    </Box>
                  ) : userDistanceData?.data?.total_distance ? (
                    userDistanceData?.data?.total_distance.toFixed(2) + " km"
                  ) : (
                    "0 km"
                  )}
                </span>
                <span className="value">Total Distance</span>
              </Box>
            </Box>
          </Box>
          <Box mt={6} mb={2}>
            <Tabs
              className="dense"
              value={tab}
              onChange={(e, tab): any => setTab(tab)}
              sx={{ width: 150, mb: 2 }}
            >
              <Tab label="Recent Bookings" />
            </Tabs>
            {tab === 0 ? (
              <Table
                px={0}
                rowCount={userData?.data?.count}
                serverSidePagination={true}
                activePage={page}
                activePageSize={pageSize}
                onPageChange={(value: any) => setPage(value)}
                onPageSizeChange={(value: any) => setPageSize(value)}
                height={530}
                rowsPerPage={8}
                small
                smallPagination
                loading={userLoading}
                rows={userData?.data?.bookings || []}
                columns={[
                  {
                    key: "vin",
                    label: "Vin",
                  },
                  {
                    key: "startTime",
                    label: "Start",
                    format: (value: any) =>
                      moment(value).format("DD/MM/YY, hh:mm a"),
                  },
                  // {
                  //   key: "endTime",
                  //   label: "end",
                  //   format: (value: any) =>
                  //     moment(value).format("DD/MM/YY, hh:mm a"),
                  // },
                  {
                    key: "bookingDuration",
                    label: "Duration",
                    Render: (row) => {
                      return (
                        <Box>
                          {row?.bookingDuration?.hours
                            ? row?.bookingDuration?.hours + "h"
                            : ""}{" "}
                          {row?.bookingDuration?.minutes
                            ? row?.bookingDuration?.minutes + "m"
                            : "-"}{" "}
                          {row?.bookingDuration?.seconds
                            ? row?.bookingDuration?.seconds + "s"
                            : ""}
                        </Box>
                      );
                    },
                  },
                  {
                    key: "bookingAmount",
                    label: "Amount",
                    // eslint-disable-next-line
                    format: (value) => (value ? "₹" + " " + value : "₹ 0"),
                  },
                  {
                    key: "bookingDistance",
                    label: "Distance",
                    // eslint-disable-next-line
                    format: (value) => (value ? value + " km" : "0 km"),
                  },
                  // {
                  //   key: "actions",
                  //   label: "Actions",
                  //   Render: (row) => (
                  //     <Button
                  //       variant="action"
                  //       size="small"
                  //       onClick={() => {
                  //         setRefundDialog({
                  //           open: true,
                  //           data: row,
                  //           user: user,
                  //         });
                  //       }}
                  //     >
                  //       Refund
                  //     </Button>
                  //   ),
                  // },
                ]}
              />
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Box>
      <DeleteVendor open={deleteDialog} handleClose={DeleteHandleClose} />
      {/* <RefundDialog
        open={refundDialog.open}
        data={refundDialog.data}
        handleClose={() => {
          setRefundDialog({ ...refundDialog, open: false });
        }}
        refetchTransactions={refetchTransactions}
        user={refundDialog.user}
      /> */}
    </>
  );
};

// interface Props {
//   open: boolean;
//   handleClose: () => void;
//   data: any;
//   user: any;
//   refetchTransactions: () => void;
// }

// const RefundDialog: React.FC<Props> = ({
//   open,
//   handleClose,
//   data,
//   refetchTransactions,
//   user,
// }) => {
//   const [input, setInput] = useState({
//     amount: "",
//     remarks: "",
//   });

//   console.log(data);

//   useEffect(() => {
//     if (open && data) {
//       setInput({
//         ...input,
//         remarks: `Refund for BookingId: ${data._id}`,
//       });
//     }
//     if (!open) {
//       setInput({
//         amount: "",
//         remarks: "",
//       });
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [open, data]);

//   const { amount, remarks } = input;

//   const mutation = useMutation(
//     ["initiateRefund", amount],
//     () =>
//       authorizedFetch(
//         `https://payments.dev.revos.in/v1/payments/refunds/cashfree/initiaterefund`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: {
//             bookingId: data._id,
//             orderId: data._id,
//             amount: amount,
//             remark: remarks,
//           },
//         }
//       ),
//     {
//       onSuccess: () => {
//         snackbar.success(`Refund Successful`);
//         refetchTransactions();
//         setLoader(false);
//       },
//       onError: () => {
//         snackbar.error(`Error sending refund`);
//       },
//     }
//   );

//   function onSend() {
//     setLoader(true);
//     mutation.mutate();
//     handleClose();
//   }

//   return (
//     <Dialog open={open} onClose={handleClose}>
//       <DialogTitle>
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "start",
//           }}
//         >
//           Refund Money
//           <IconButton
//             children={<HighlightOff />}
//             color="inherit"
//             onClick={handleClose}
//             sx={{ transform: "translate(8px, -8px)" }}
//           />
//         </Box>
//         <Typography sx={{ fontSize: 13, mt: -1.5 }}>
//           {user.firstName ? user?.firstName + " " + user?.lastName : "-"}
//         </Typography>
//       </DialogTitle>

//       <DialogContent className="py-1">
//         <Box mt={2}>
//           <Typography className="label">Amount</Typography>
//           <TextField
//             fullWidth
//             size="small"
//             placeholder="0"
//             value={amount}
//             onChange={(e: any) => {
//               setInput({ ...input, amount: e.target.value });
//             }}
//             InputProps={{
//               startAdornment: (
//                 <InputAdornment position="start">₹</InputAdornment>
//               ),
//             }}
//           />
//         </Box>

//         <Box mt={4}>
//           <Typography className="label">Remarks</Typography>
//           <TextField
//             fullWidth
//             id="outlined-multiline-static"
//             multiline
//             rows={5}
//             placeholder="Type a remark..."
//             value={remarks}
//             onChange={(e: any) => {
//               setInput({ ...input, remarks: e.target.value });
//             }}
//           />
//         </Box>
//       </DialogContent>
//       <DialogActions>
//         <Button variant="outlined" onClick={handleClose}>
//           Cancel
//         </Button>
//         <Button
//           disabled={input.remarks.length === 0 || input.amount.length === 0}
//           variant="contained"
//           onClick={onSend}
//         >
//           Refund
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

interface DeleteVendorProps {
  open: any;
  handleClose: () => void;
}

const DeleteVendor: React.FC<DeleteVendorProps> = ({ open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Delete Booking</DialogTitle>
      <DialogContent sx={{ marginTop: "20px" }}>
        Are you sure you want to DELETE:{" "}
        <span style={{ fontWeight: "bold" }}>Booking</span>?
      </DialogContent>
      <DialogActions sx={{ margin: "20px 20px 20px 0" }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          // onClick={confirm}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DrawerContent;
