import { Box, Skeleton, Typography } from "@mui/material";
import { Fragment } from "react";
import { Doughnut } from "react-chartjs-2";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { authorizedFetch, GlobalState } from "utils";
import { LEASE_URL } from "utils/constants";

const colors: any = {
  Running: "#26C72D",
  Idle: "#FFAB01",
  Stopped: "#EB5C5C",
};

const styles: any = {};

Object.keys(colors).forEach((label) => {
  styles[`&.${label.replaceAll(" ", "_")}::before`] = {
    bgcolor: colors[label],
  };
});

const VehicleService = () => {
  const { masterView } = useSelector((state: GlobalState) => state.global);

  const { isLoading, data } = useQuery(["getFMSServiceStats", masterView], () =>
    authorizedFetch(
      `${LEASE_URL}/company/vehicles/service?master=${masterView}`
    )
  );

  const total = (
    (data?.data?.running || 0) +
    (data?.data?.stop || 0) +
    (data?.data?.idle || 0)
  ).toLocaleString();

  return (
    <>
      <Box
        sx={{
          minWidth: { xs: 0, md: 360 },
          mb: { xs: 2, md: 7.5 },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "flex-start", md: "center" },
        }}
      >
        <Typography variant="h3" mr={2.5} mb={{ xs: 2.5, md: 0 }}>
          Vehicle Service
        </Typography>
        {/* {isLoading ? (
          <Skeleton
            variant="rectangular"
            width={95}
            height={28}
            sx={{ borderRadius: 10 }}
          />
        ) : (
          <InfoLabel label="Total" value={total} />
        )} */}
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "140px auto",
            md: "minmax(0, 265px) auto",
          },
          gap: { xs: 3, md: 4 },
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              width: 1,
              position: "relative",
              pt: "100%",
            }}
          >
            <Skeleton
              variant="circular"
              sx={{
                position: "absolute",
                top: 0,
                width: 1,
                height: 1,
              }}
            />
          </Box>
        ) : (
          <Box width={1} alignSelf="center" position="relative">
            <Doughnut
              style={{ position: "relative", zIndex: 2 }}
              data={(canvas) => {
                return {
                  datasets: [
                    {
                      data: [
                        data?.data?.running,
                        data?.data?.idle,
                        data?.data?.stop,
                      ],
                      backgroundColor: Object.values(colors),
                      hoverBackgroundColor: Object.values(colors),
                      borderWidth: 0,
                      cutout: "85%",
                      hoverOffset: 3,
                    },
                  ],
                  labels: Object.keys(colors),
                };
              }}
              options={{
                layout: {
                  padding: 5,
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    displayColors: false,
                  },
                },
              }}
            />
            <Box
              sx={{
                zIndex: 1,
                position: "absolute",
                top: "35%",
                right: 0,
                left: 0,
                mx: "auto",
                pointerEvents: "none",
                textAlign: "center",
                color: "text.secondary",
              }}
            >
              <Typography fontSize={{ xs: 18, md: 32 }} fontWeight={700}>
                {total}
              </Typography>
              <Typography fontSize={14}>Total Vehicles</Typography>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            width: 1,
            minWidth: 0,
            maxWidth: 210,
            justifySelf: "end",
            height: "fit-content",
            ml: 2,
            display: "grid",
            gridTemplateColumns: "1fr auto",
            rowGap: 4,
            columnGap: 1,
            fontSize: { xs: 12, md: 16 },
            "& .label": {
              position: "relative",
              "&::before": {
                content: '""',
                position: "absolute",
                mt: 0.25,
                transform: "translateX(-15.96px)",
                borderRadius: 10,
                width: "6.39px",
                height: "17.89px",
              },
              ...styles,
            },
            "& .value": {
              // textAlign: "right",
              fontWeight: 700,
            },
          }}
        >
          {[
            {
              label: "Running",
              value: data?.data?.running?.toLocaleString(),
            },
            {
              label: "Idle",
              value: data?.data?.idle?.toLocaleString(),
            },
            {
              label: "Stopped",
              value: data?.data?.stop?.toLocaleString(),
            },
          ].map((el, i) => (
            <Fragment key={i}>
              {isLoading ? (
                <Skeleton width={150} />
              ) : (
                <span className={`label ${el.label.replaceAll(" ", "_")}`}>
                  {el.label}
                </span>
              )}
              {isLoading ? (
                <Skeleton width={20} />
              ) : (
                <span className="value">{el.value}</span>
              )}
            </Fragment>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default VehicleService;
