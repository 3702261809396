import {
  Add,
  DeleteOutline,
  EditOutlined,
  HighlightOff,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  Tab,
  Tabs,
  Typography,
  Avatar,
  Button,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import Map from "components/Map";
import RangePicker from "components/RangePicker";
import Table from "components/Table";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import {
  authorizedFetch,
  drawer,
  getDarkModePreference,
  getPermissions,
  GlobalState,
  snackbar,
} from "utils";
import AssignChargers from "./AssignChargers";
import UnAssignChargers from "./UnAssignCharger";
import EditDialog from "./EditDialog";
import DeleteDialog from "./DeleteDialog";
import { VENDORS_URL_TEMP } from "utils/constants";
import { useQuery } from "react-query";
import { format, sub } from "date-fns";
import { formatPhoneNumberIntl } from "react-phone-number-input";

const DrawerContent = ({ vendor, viewChargers }: any) => {
  const { canWrite } = getPermissions("charger:vendors");
  const [tab, setTab] = useState(viewChargers ? 1 : 0);
  const [vendorInfo, setVendorInfo] = useState<any>({
    companyInfo: [
      { label: "Company Name", value: "" },
      { label: "Phone Number", value: "" },
      { label: "GST Number", value: "" },
      { label: "Address", value: "" },
    ],
    userInfo: [
      { label: "First Name", value: "" },
      { label: "Last Name", value: "" },
      { label: "Phone Number", value: "" },
      { label: "Email", value: "" },
      // { label: "Reports", value: "" },
    ],
  });
  console.log("vendor => vendor MOd ==>", vendor);
  const [selectedRows, setSelectedRows] = useState<any>([]);

  const [assignChargersDialog, setAssignChargersDialog] = useState(false);
  const [unassignChargersDialog, setUnassignChargersDialog] = useState({
    open: false,
    data: [],
  });
  const [editVendorDialog, setEditVendorDialog] = useState({
    open: false,
    initialStep: 0,
  });
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    id: "",
    name: "",
  });

  const vendorsUrl = `${VENDORS_URL_TEMP}/company/vendorChargers/${vendor.id}`;
  const { isLoading: vendorLoading, data: vendorData } = useQuery(
    ["getVendorChargers", vendor.id],
    () => authorizedFetch(vendorsUrl)
  );
  console.log("vendordata => vendors", vendorData);

  useEffect(() => {
    if (viewChargers) setTab(1);
  }, [viewChargers]);

  useEffect(() => {
    if (vendor) {
      setVendorInfo({
        companyInfo: [
          { label: "Company Name", value: vendor.name },
          {
            label: "Phone Number",
            value: vendor.phone ? formatPhoneNumberIntl(vendor.phone) : "-",
          },
          { label: "GST Number", value: vendor.gstNumber },
          { label: "Address", value: vendor.address },
          // { label: "Reports", value: vendor.reports }
        ],
        userInfo: [
          { label: "First Name", value: vendor.users[0]?.firstName },
          { label: "Last Name", value: vendor.users[0]?.lastName },
          {
            label: "Phone Number",
            value: vendor.users[0]?.phone
              ? formatPhoneNumberIntl(vendor.users[0]?.phone)
              : "-",
          },
          { label: "Email", value: vendor.users[0]?.email },
          // { label: "Reports", value: vendor.users[0]?.reports },
        ],
      });
    }
    setSelectedRows([]);
  }, [vendor, vendorLoading]);

  const [vendorsChartData, setVendorsChartData] = useState<any>(null);

  const [range, setRange] = useState<any>([
    sub(new Date(), { months: 1 }),
    new Date(),
  ]);

  let dateFrom = format(range[0], "yyyy-MM-dd");
  let dateTo = format(range[1], "yyy-MM-dd");

  const vendorUrl = `${VENDORS_URL_TEMP}/company/stats/vendor/date?orderBy=BOOKING_TIME_ASC&dateFrom=${dateFrom}&dateTo=${dateTo}&vendorId=${
    vendor?.id === "all" ? "" : vendor?.id
  }`;

  const { isLoading, data: vendorsData } = useQuery(
    ["getVendorStatsByDate", dateFrom, dateTo, vendor],
    () => authorizedFetch(vendorUrl),
    {
      onError: () => snackbar.error("Error fetching data"),
    }
  );

  useEffect(() => {
    if (vendorsData?.data?.stats?.constructor === Array) {
      setVendorsChartData([]);
      let chartData: any = {
        bookings: [],
        earnings: [],
        energy: [],
        users: [],
      };
      // eslint-disable-next-line
      vendorsData?.data?.stats?.map((el: any) => {
        let date = format(new Date(el.date), "MMM d, yyyy");
        chartData.bookings.push({ x: date, y: el.totalBookings });
        chartData.earnings.push({ x: date, y: el.totalEarnings });
        chartData.energy.push({ x: date, y: el.totalEnergyConsumed });
        chartData.users.push({ x: date, y: el.totalUsers });
      });

      setVendorsChartData(chartData);
    }
  }, [vendorsData, dateFrom, dateTo]);

  const loader = (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 70,
        width: 1,
        mb: "3px",
      }}
    >
      <CircularProgress color="primary" size={24} />
    </Box>
  );

  let chartArray1 = vendorsChartData?.bookings || [];
  let chartArray2 = vendorsChartData?.users || [];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            px: 3,
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: isDarkMode ? "#000" : "#3D3D3D",
            fontWeight: 500,
            color: "#fff",
          }}
        >
          {vendor.name}
          <Box display="flex">
            {canWrite && (
              <IconButton
                sx={{ mr: 1 }}
                children={<DeleteOutline />}
                color="inherit"
                size="small"
                onClick={() => {
                  setDeleteDialog({
                    open: true,
                    id: vendor.id,
                    name: vendor.name,
                  });
                }}
              />
            )}
            <IconButton
              children={<HighlightOff />}
              color="inherit"
              size="small"
              onClick={() => drawer.close()}
            />
          </Box>
        </Box>
        <Box flexGrow={1} overflow="auto">
          <Box pt={3} pr={2.75} pl={2.5} width={1}>
            <Tabs
              className="dense"
              value={tab}
              onChange={(e: any, tab: any) => setTab(tab)}
              variant="scrollable"
            >
              <Tab label="Basic Details" />
              <Tab label="Assigned Chargers" />
              <Tab label="Insights" />
            </Tabs>
          </Box>
          {tab === 0 ? (
            <Box
              sx={{
                px: 3,
                pt: 2.5,
                "& .table": {
                  borderCollapse: "collapse",
                  width: 1,
                  fontSize: 14,
                  lineHeight: "16px",
                  "& td": {
                    py: 1.25,
                    px: 2,
                  },
                  "& .bold": {
                    fontWeight: 500,
                  },
                  "& .header": {
                    px: 2,
                    py: 1,
                    position: "relative",
                    "& td": {
                      position: "absolute",
                      verticalAlign: "middle",
                      backgroundColor: (theme: any) =>
                        theme.customColors.header,
                      width: 1,
                      borderRadius: "4px",
                      fontSize: 16,
                      fontWeight: 600,
                      "& .label": {
                        display: "inline-block",
                        transform: "translateY(1px)",
                        py: 1.125,
                      },
                    },
                  },
                  "& .first > td": {
                    pt: 9,
                  },
                  "& .last > td": {
                    pb: 2.75,
                  },
                },
              }}
            >
              <table className="table">
                <tbody>
                  <tr className="header">
                    <td colSpan={2}>
                      <span className="label">Company Info</span>
                      {canWrite && (
                        <IconButton
                          sx={{ ml: 1.5 }}
                          children={<EditOutlined />}
                          color="primary"
                          size="small"
                          onClick={() => {
                            setEditVendorDialog({
                              open: true,
                              initialStep: 0,
                            });
                          }}
                        />
                      )}
                    </td>
                  </tr>
                  {vendorInfo.companyInfo.map(
                    ({ label, value }: any, i: any) => (
                      <tr
                        key={i}
                        className={
                          i === 0
                            ? "first"
                            : i === vendorInfo.companyInfo.length - 1
                            ? "last"
                            : ""
                        }
                      >
                        <td>{label}</td>
                        <td className="bold">{value}</td>
                      </tr>
                    )
                  )}
                  <tr className="header">
                    <td colSpan={2}>
                      <span className="label">User Info</span>
                      {canWrite && (
                        <IconButton
                          sx={{ ml: 1.5 }}
                          children={<EditOutlined />}
                          color="primary"
                          size="small"
                          onClick={() => {
                            setEditVendorDialog({
                              open: true,
                              initialStep: 1,
                            });
                          }}
                        />
                      )}
                    </td>
                  </tr>
                  {vendorInfo.userInfo.map(({ label, value }: any, i: any) => (
                    <tr
                      key={vendorInfo.companyInfo.length + i}
                      className={
                        i === 0
                          ? "first"
                          : i === vendorInfo.userInfo.length - 1
                          ? "last"
                          : ""
                      }
                    >
                      <td className="bold">{label}</td>
                      <td>{value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          ) : tab === 1 ? (
            <Box>
              <Box
                width={1}
                sx={{
                  mt: 3,
                  mb: { xs: 1.5, sm: 2.5 },
                  px: 2.5,
                  display: { sm: "flex" },
                  justifyContent: { sm: "space-between" },
                  alignItems: { sm: "center" },
                }}
              >
                <Typography
                  sx={{
                    mb: { xs: 1, sm: 0 },
                    fontSize: 14,
                    color: (theme: any) => theme.customColors.text.grey,
                    "& span": {
                      fontWeight: 500,
                      color: "text.primary",
                    },
                  }}
                >
                  Total Chargers Assigned:{" "}
                  {vendorLoading ? (
                    <CircularProgress size={14} color="inherit" />
                  ) : (
                    vendorData?.data?.chargers?.length || "0"
                  )}
                </Typography>
                {canWrite && (
                  <Button
                    size="small"
                    sx={{ textTransform: "none" }}
                    color="inherit"
                    onClick={() => {
                      setAssignChargersDialog(true);
                    }}
                    startIcon={<Add />}
                    disabled={vendorLoading}
                  >
                    Assign New
                  </Button>
                )}
              </Box>
              <Table
                small
                loading={vendorLoading}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                selectable={canWrite}
                selectOnClick
                rows={vendorData?.data?.chargers || []}
                columns={[
                  {
                    key: "chargerId",
                    label: "Charger UID",
                    Render: (row) => (
                      <Box sx={{ py: 2 }}>{row.charger.chargerId}</Box>
                    ),
                  },
                  {
                    key: "health",
                    label: "Health",
                    Render: (row) =>
                      row.charger.health === "HEALTHY" ? (
                        <Tooltip title="Charger health is good">
                          <Avatar
                            variant="status"
                            sx={{
                              background: "#3BB89E30",
                              color: "#3BB89E",
                              borderColor: "#3BB89E30",
                            }}
                          >
                            {row.charger.health}
                          </Avatar>
                        </Tooltip>
                      ) : row.charger.health === "MODERATE" ? (
                        <Tooltip title="Charger has not been pinged since the past 15 days">
                          <Avatar
                            variant="status"
                            sx={{
                              background: "#FFFF0030",
                              color: "#ffc800",
                              borderColor: "#FFFF0030",
                            }}
                          >
                            {row.charger.health}
                          </Avatar>
                        </Tooltip>
                      ) : row.charger.health === "CRITICAL" ? (
                        <Tooltip title="Charger has not been pinged since the past 30 days">
                          <Avatar
                            variant="status"
                            sx={{
                              background: "#FFA50030",
                              color: "orange",
                              borderColor: "#FFA50030",
                            }}
                          >
                            {row.charger.health}
                          </Avatar>
                        </Tooltip>
                      ) : row.charger.health === "INACTIVE" ? (
                        <Tooltip title="Charger has not been pinged since the past 45 days">
                          <Avatar
                            variant="status"
                            sx={{
                              background: "#FF000030",
                              color: "red",
                              borderColor: "#FF000030",
                            }}
                          >
                            {row.charger.health}
                          </Avatar>
                        </Tooltip>
                      ) : (
                        ""
                      ),
                  },
                ]}
                toolbar={() => (
                  <Button
                    startIcon={<DeleteOutline />}
                    onClick={() => {
                      setUnassignChargersDialog({
                        open: true,
                        data: selectedRows,
                      });
                    }}
                  >
                    Unassign
                  </Button>
                )}
              />
            </Box>
          ) : tab === 2 ? (
            <Box py={3} px={2.5}>
              <Box
                mb={2.5}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography fontSize={18} fontWeight={500}>
                  Insights
                </Typography>
                <RangePicker range={range} setRange={setRange} />
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gap: 2,
                  "& > div": {
                    border: 1,
                    bgcolor: isDarkMode ? "background.default" : "#fff",
                    borderColor: (theme: any) => theme.customColors.border,
                    borderRadius: 1,
                    p: 2.5,
                    display: "flex",
                    flexDirection: "column",
                    "& span": {
                      display: "flex",
                      alignItems: "center",
                      "& span": {
                        alignItems: "end",
                      },
                    },
                    "& .value": {
                      fontSize: "1.75rem",
                      fontWeight: 700,
                      lineHeight: "1.75rem",
                    },
                    "& .unit": {
                      ml: 0.75,
                    },
                    "& .title": {
                      ml: 1.5,
                      color: "text.secondary",
                    },
                  },
                }}
              >
                <Box key={chartArray1.length}>
                  {isLoading ? (
                    loader
                  ) : (
                    <LineChart data={vendorsChartData?.bookings || []} />
                  )}
                  <span>
                    <Typography className="value">
                      {typeof vendorsData?.data?.totalBookings === "number"
                        ? vendorsData?.data?.totalBookings
                        : "-"}
                    </Typography>
                    <Typography className="title">Total Bookings</Typography>
                  </span>
                </Box>
                <Box key={chartArray1.length + 1}>
                  {isLoading ? (
                    loader
                  ) : (
                    <LineChart isBlue data={vendorsChartData?.earnings || []} />
                  )}
                  <span>
                    <Typography className="value">
                      {typeof vendorsData?.data?.totalEarnings === "number"
                        ? "₹" + vendorsData?.data?.totalEarnings.toFixed(0)
                        : "-"}
                    </Typography>
                    <Typography className="title">Total Earnings</Typography>
                  </span>
                </Box>
                <Box
                  key={
                    [
                      chartArray1.length,
                      chartArray1.length + 1,
                      chartArray1.length + 2,
                    ].includes(chartArray2.length)
                      ? chartArray2.length + 3
                      : chartArray2.length
                  }
                >
                  {isLoading ? (
                    loader
                  ) : (
                    <LineChart data={vendorsChartData?.users || []} />
                  )}
                  <span>
                    <Typography className="value">
                      {typeof vendorsData?.data?.totalUsers === "number"
                        ? vendorsData?.data?.totalUsers
                        : "-"}
                    </Typography>
                    <Typography className="title">Total Users</Typography>
                  </span>
                </Box>
                <Box key={chartArray1.length + 2}>
                  {isLoading ? (
                    loader
                  ) : (
                    <LineChart isBlue data={vendorsChartData?.energy || []} />
                  )}
                  <span>
                    <span>
                      <Typography className="value">
                        {typeof vendorsData?.data?.totalBookings === "number"
                          ? vendorsData?.data?.totalEnergyConsumed.toFixed(3)
                          : "-"}
                      </Typography>
                      <Typography className="unit">kWh</Typography>
                    </span>
                    <Typography className="title">Energy Dispensed</Typography>
                  </span>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box p={2.5} height={436}>
              <Map loading={false} type="charger" borderRadius={1} />
            </Box>
          )}
        </Box>
      </Box>
      <AssignChargers
        open={assignChargersDialog}
        vendor={vendor}
        existingChargers={vendorData?.data?.chargers || []}
        handleClose={() => {
          setAssignChargersDialog(false);
        }}
      />
      <UnAssignChargers
        open={unassignChargersDialog.open}
        handleClose={() => {
          setUnassignChargersDialog({ ...unassignChargersDialog, open: false });
        }}
        selected={unassignChargersDialog.data}
        vendor={vendor}
      />
      <EditDialog
        open={editVendorDialog.open}
        handleClose={() =>
          setEditVendorDialog({ ...editVendorDialog, open: false })
        }
        vendor={vendor}
        initialStep={editVendorDialog.initialStep}
      />
      <DeleteDialog
        open={deleteDialog.open}
        handleClose={() => {
          setDeleteDialog({ ...deleteDialog, open: false });
        }}
        vendor={vendor}
      />
    </>
  );
};

const LineChart: React.FC<{ isBlue?: boolean; data: any[] }> = ({
  isBlue,
  data,
}) => {
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );

  return (
    <Box
      sx={{
        flexGrow: 1,
        position: "relative",
        height: 70,
        width: 1,
        mb: "3px",
      }}
    >
      <Line
        height={70}
        data={(canvas) => {
          const ctx = canvas.getContext("2d");
          const g = ctx?.createLinearGradient(0, 0, 0, 60);

          g?.addColorStop(
            0,
            isBlue ? "rgba(97, 209, 105, 0.7)" : "rgba(97, 209, 105, 0.6)"
          );
          g?.addColorStop(
            0.5,
            isBlue ? "rgba(97, 209, 105, 0.4)" : "rgba(97, 209, 105, 0.2)"
          );
          g?.addColorStop(
            1,
            isDarkMode ? "rgba(0, 0, 0, 0)" : "rgba(255, 255, 255, 0)"
          );

          let color = isBlue ? "rgba(97,209,105)" : "rgba(97,209,105)";

          let labels = [];
          for (let i = 1; i <= 11; i++) {
            labels.push(`October ${i}, 2021`);
          }

          return {
            // labels: data?.map(el => el.t),
            datasets: [
              {
                fill: true,
                data: data,
                borderColor: color,
                backgroundColor: g,
                tension: 0.4,
                pointRadius: 0,
                pointHoverRadius: 4,
                pointHoverBackgroundColor: "#fff",
                pointHoverBorderColor: color,
                pointHoverBorderWidth: 3,
              },
            ],
          };
        }}
        options={{
          scales: {
            xAxis: {
              display: false,
              // type: "linear",
              // type: 'time'
            },
            yAxis: {
              display: false,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              caretSize: 0,
              mode: "index",
              intersect: false,
              yAlign: "center",
              displayColors: false,
              caretPadding: 16,
              titleFont: {
                weight: "400",
              },
              bodyFont: {
                weight: "500",
              },
            },
          },
          layout: {
            padding: {
              bottom: 20,
            },
          },
          interaction: {
            mode: "index",
            intersect: false,
          },
        }}
      />
    </Box>
  );
};

export default DrawerContent;
