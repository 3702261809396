import { HighlightOff } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";

const QRDialog = ({ open, handleClose, setData, chargers }: any) => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const handleQRData = (data: any) => {
    if (chargers?.some((charger: any) => charger?.id === data) === false) {
      setData("Charger UID not found");
      handleClose();
    } else {
      setData(data);
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiPaper-root": {
          margin: 2,
        },
      }}
      PaperProps={{
        sx: {
          maxWidth: 400,
          height: "auto",
          width: 1,
          p: 0,
          "& .MuiInputBase-root": {
            fontSize: 14,
            borderRadius: 1,
            p: "3.5px 5px",
            m: 0,
          },
          "& .MuiPaper-root": {
            margin: 0,
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        Scan a Charger QR
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent>
        <>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              mt: 2,
            }}
          >
            <Box
              sx={{
                width: "100%",
                "& .qr-reader": {
                  "& div": {
                    height: 400,
                    mb: 5,

                    "& .finderContainer": {
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: 2,
                      "& .finder": {
                        width: 170,
                        height: 170,
                        border: "7px dotted white",
                      },
                    },
                    "& video": {
                      objectFit: "cover",
                      minHeight: "100%",
                      borderRadius: 2,
                      zIndex: 1,
                    },
                  },
                },
              }}
            >
              <QrReader
                onResult={(result: any, error: any) => {
                  if (!!result) {
                    handleQRData(result?.text);
                  }

                  if (!!error) {
                    console.info(error);
                  }
                }}
                constraints={{ facingMode: isMobile ? "environment" : "user" }}
                // constraints={{ facingMode: "user" }}
                // scanDelay={300}
                className="qr-reader"
                ViewFinder={() => (
                  <Box className="finderContainer">
                    <Box className="finder"></Box>
                  </Box>
                )}
              />
            </Box>
          </Box>
        </>
      </DialogContent>
    </Dialog>
  );
};

export default QRDialog;
