import {
  AccountBalanceWalletOutlined,
  Notes,
  PersonOutline,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Paper,
  Skeleton,
  Typography,
  alpha,
} from "@mui/material";
import { useState } from "react";
import CompanyDetails from "./CompanyDetails";
import UserDetails from "./UserDetails";
import { useSelector } from "react-redux";
import { GlobalState, authorizedFetch } from "utils";
import { PAYMENTS_URL } from "utils/constants";
import { useQuery } from "react-query";

const Account = () => {
  const { user } = useSelector((state: GlobalState) => state.global);
  const isWalletManager = user.email === "walletmanager@bolt.earth";

  const views = [
    { label: "Your Profile", icon: <PersonOutline /> },
    { label: "Company Details", icon: <Notes /> },
  ];

  const [activeView, setActiveView] = useState(0);

  const cashfreeBalanceUrl = `${PAYMENTS_URL}/v1/payments/payouts/cashfree/balance`;
  const { isLoading: cashfreeBalanceLoading, data: cashfreeBalanceData } =
    useQuery(["getCashfreeBalance"], () => authorizedFetch(cashfreeBalanceUrl));

  return (
    <Box height={1} display="flex" flexDirection="column">
      <Typography mt={1} mb={3} variant="h2">
        Account
      </Typography>
      {isWalletManager && (
        <Box
          sx={{
            "& .card": {
              mb: 3,
              width: 320,
              borderRadius: 1,
              py: 1.5,
              px: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              boxShadow: (theme) => theme.customShadows.medium,
              "& .icon": {
                borderRadius: 50,
                height: 64,
                width: 64,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.2),
                color: (theme) => theme.palette.primary.main,
                fontSize: 36,
              },
              "& .description": {
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                "& .label": {
                  textAlign: "end",
                  fontWeight: 600,
                  color: (theme) => theme.customColors.text.grey,
                },
                "& .value": {
                  textAlign: "end",
                  fontSize: "1.5rem",
                  fontWeight: 700,
                  letterSpacing: "0em",
                },
              },
            },
          }}
        >
          <Paper className="card">
            <Box className="icon">
              <AccountBalanceWalletOutlined fontSize="inherit" />
            </Box>
            <Box className="description">
              <span className="label">Cashfree Wallet Balance</span>
              <span className="value">
                {cashfreeBalanceLoading ? (
                  <Skeleton width={60} />
                ) : cashfreeBalanceData?.data?.availableBalance ? (
                  `₹${Number(
                    cashfreeBalanceData.data.availableBalance
                  ).toLocaleString()}`
                ) : (
                  "-"
                )}
              </span>
            </Box>
          </Paper>
        </Box>
      )}
      <Paper
        sx={{
          flexGrow: 1,
          overflow: "hidden",
          p: 4,
          borderRadius: 2,
          boxShadow: "0 0 4px #15223214",
          display: "grid",
          gridTemplateColumns: "230px 1fr",
        }}
      >
        <Box
          sx={{
            height: 1,
            borderRight: "2px solid",
            borderColor: (theme) => theme.customColors.border,
          }}
        >
          {views.map(({ label, icon }, i) => (
            <Button
              sx={{
                mb: 2,
                justifyContent: "start",
                color: activeView === i ? "text.primary" : "text.secondary",
                textTransform: "none",
                fontSize: 16,
                fontWeight: activeView === i ? 700 : 500,
                "& .MuiButton-startIcon": { mr: 2 },
                "& .MuiAvatar-root": { height: 36, width: 36 },
              }}
              key={i}
              fullWidth
              disableRipple
              startIcon={<Avatar variant="icon" children={icon} />}
              onClick={() => setActiveView(i)}
            >
              {label}
            </Button>
          ))}
        </Box>
        <Box
          sx={{
            px: 4,
            overflow: "auto",
            "& > div": {
              maxWidth: 680,
            },
            "& .header": {
              mb: 2.5,
              display: "flex",
              alignItems: "center",
              "& .heading": {
                fontSize: 18,
                lineHeight: "21px",
                fontWeight: 700,
              },
            },
            "& .form": {
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              rowGap: 3,
              columnGap: 5,
              "& .value": { height: 40 },
            },
          }}
        >
          {activeView === 0 ? <UserDetails /> : <CompanyDetails />}
        </Box>
      </Paper>
    </Box>
  );
};

export default Account;
