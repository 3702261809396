import { AddRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { getPermissions } from "utils";

const AppBar = ({
  tripsLoading,
  counts,
  setCreateDialog,
  type,
  setType,
}: any) => {
  const { canWrite } = getPermissions("rental:geofence");
  return (
    <Box
      sx={{
        width: 1,
        py: 3,
        px: { xs: 2, md: 7 },
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box display="flex" alignItems="center">
        <Typography variant="h2">Geofence</Typography>
        {!tripsLoading && (
          <Typography
            sx={{
              ml: 1,
              fontSize: { xs: 16, md: 20 },
              fontWeight: 600,
              color: (theme) => theme.customColors.text.grey,
            }}
          >
            ({(counts?.all || 0).toLocaleString()})
          </Typography>
        )}
      </Box>
      <Box>
        <Tooltip title="Geofence Status" placement="top-start">
          <FormControl size="small" sx={{ mr: 3 }}>
            <Select
              className="shadow rounded"
              sx={{ width: 180 }}
              value={type}
              onChange={(e: any) => setType(e.target.value)}
              // startAdornment={<Sort sx={{ mr: 1 }} />}
              renderValue={(selected) =>
                selected === 0
                  ? "Status : All"
                  : selected === 1
                  ? "Status: Circular"
                  : "Status: Polygonal"
              }
            >
              <MenuItem value={0}>
                All
                <span style={{ fontSize: 10, marginLeft: 5 }}>
                  ({counts?.all})
                </span>
              </MenuItem>
              <MenuItem value={1}>
                Circular
                <span style={{ fontSize: 10, marginLeft: 5 }}>
                  ({counts?.circular})
                </span>
              </MenuItem>
              <MenuItem value={2}>
                Polygonal
                <span style={{ fontSize: 10, marginLeft: 5 }}>
                  ({counts?.polygonal})
                </span>
              </MenuItem>
            </Select>
          </FormControl>
        </Tooltip>
        {canWrite && (
          <Button
            sx={{
              // width: { md: 300 },
              px: 6,
              py: 1,
              borderRadius: 10,
              borderWidth: "1.5px !important",
              borderColor: (theme) => theme.palette.primary.main,
              textTransform: "none",
              fontWeight: 500,
            }}
            variant="outlined"
            startIcon={<AddRounded />}
            onClick={() => setCreateDialog(true)}
          >
            Add New
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default AppBar;
