import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Paper,
  Skeleton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import InfoLabel from "components/InfoLabel";
import moment from "moment";
import { useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import { useQuery } from "react-query";
import { authorizedFetch } from "utils";
import { LEASE_URL } from "utils/constants";

const TotalUsers = ({ dateFrom, dateTo, vendorsGraphList }: any) => {
  const theme = useTheme();

  const vendorList = [
    { id: "all", label: "All Vendors" },

    ...(vendorsGraphList?.data?.vendors?.constructor === Array
      ? vendorsGraphList?.data?.vendors
      : []
    ).map((el: any) => ({ id: el._id, label: el.name })),
  ];

  const [selectedVendor, setSelectedVendor] = useState(vendorList[0]);

  const allUrl = `${LEASE_URL}/company/vendors/distance/total?timeframe=custom&startTime=${dateFrom}&endTime=${dateTo}`;

  const vendorUrl = `${LEASE_URL}/company/vendor/${selectedVendor?.id}/distance?timeframe=custom&startTime=${dateFrom}&endTime=${dateTo}`;

  const { isLoading, data } = useQuery(
    ["getVendorDistanceStats", dateFrom, dateTo, selectedVendor],
    () => authorizedFetch(selectedVendor?.id === "all" ? allUrl : vendorUrl)
  );

  const totalDistance =
    data?.data && data?.data?.totalDistance !== 0
      ? (data?.data?.totalDistance / 1000).toFixed(2) + " Km"
      : 0 + " Km";

  const { chartData } = useMemo(() => {
    let chartData: any[] = [];

    if (data?.data?.distancePerDay?.constructor === Array) {
      chartData = data.data.distancePerDay
        .sort(
          (a: any, b: any) =>
            moment(a.date).valueOf() - moment(b.date).valueOf()
        )
        .map((el: any) => ({
          x: moment(el.date).format("MMM D"),
          y: (el.totalDistance / 1000).toFixed(2),
        }));
    }

    // let average = Math.round(
    //   chartData.reduce((acc, cur: any) => acc + cur.y, 0) / chartData.length
    // );

    return { chartData };
  }, [data]);

  return (
    <Paper
      sx={{
        py: { xs: 3, md: 4 },
        px: { xs: 2, md: 3 },
        display: "flex",
        height: "auto",
        flexDirection: "column",
        mb: 5,
      }}
    >
      <Box
        sx={{
          mb: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: 1,
        }}
      >
        <Box display="flex" alignItems={"center"}>
          <Typography variant="h3" mr={1.25}>
            Vendor vs Distance
          </Typography>
          {isLoading ? (
            <>
              <Skeleton
                variant="rectangular"
                width={80}
                height={24}
                sx={{ borderRadius: 10, mr: 1.25 }}
              />
              <Skeleton
                variant="rectangular"
                width={80}
                height={24}
                sx={{ borderRadius: 10 }}
              />
            </>
          ) : (
            <>
              <InfoLabel
                dense
                label="Total"
                value={(totalDistance || 0).toLocaleString()}
                mr={1.25}
              />
              {/* <InfoLabel
                dense
                label="Daily Average"
                value={(average || 0).toLocaleString()}
              /> */}
            </>
          )}
        </Box>
        <Box>
          <Autocomplete
            size="small"
            sx={{
              width: 210,
              "& .MuiOutlinedInput-root": {
                borderRadius: 10,
                height: 40,
                fontSize: 16,
              },
              "& .MuiAutocomplete-popupIndicatorOpen": {
                color: (theme) => theme.palette.primary.main,
              },
            }}
            disableClearable
            popupIcon={<KeyboardArrowDown />}
            value={selectedVendor}
            onChange={(e: any, newValue: any) => {
              setSelectedVendor(newValue);
            }}
            options={vendorList}
            renderInput={(params: any) => (
              <TextField
                {...params}
                placeholder="Vendor"
                sx={{
                  "& input": { pr: "16px !important" },
                }}
              />
            )}
          />
        </Box>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          minHeight: 0,
        }}
      >
        <Line
          data={(canvas) => {
            let color = "#CC52E2";
            return {
              labels: chartData?.map((el: any) => el.x) || [],
              datasets: [
                {
                  fill: true,
                  data: chartData?.map((el: any) => el.y) || [],
                  borderColor: color,
                  backgroundColor: color + "0D",
                  tension: 0.4,
                  pointRadius: 0,
                  pointHoverRadius: 4,
                  pointHoverBackgroundColor: "#fff",
                  pointHoverBorderWidth: 3,
                },
              ],
            };
          }}
          options={{
            scales: {
              xAxis: {
                // type: 'time',
                grid: {
                  display: false,
                  tickWidth: 0,
                  tickLength: 16,
                  drawBorder: false,
                },
                ticks: {
                  color: theme.palette.text.secondary,
                },
              },
              yAxis: {
                title: {
                  display: true,
                  text: "Distance (Km)",
                  padding: {
                    top: 0,
                    bottom: 8,
                  },
                },
                ticks: {
                  color: theme.palette.text.secondary,
                  precision: 0,
                },
                min: 0,
                // suggestedMin: 15,
                // suggestedMax: 30,
                grid: {
                  borderDash: [4],
                  tickWidth: 0,
                  tickLength: 16,
                  drawBorder: false,
                },
              },
            },
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                caretSize: 0,
                mode: "index",
                intersect: false,
                yAlign: "center",
                displayColors: false,
                caretPadding: 16,
                titleFont: {
                  weight: "400",
                },
                bodyFont: {
                  weight: "500",
                },
              },
            },
            interaction: {
              mode: "index",
              intersect: false,
            },
          }}
        />
      </Box>
    </Paper>
  );
};

export default TotalUsers;
