import { useEffect, useState } from "react";
import { appBar, authorizedFetch, drawer } from "utils";
import AppBar from "./AppBar";
import AddVendorDialog from "./AddVendorDialog";
import Total from "./Total";
import VehicleHealth from "./VehicleHealth";
import VendorVSDistance from "./VendorVSDistance";
import List from "./List";
import { LEASE_URL } from "utils/constants";
import { useQuery } from "react-query";
import { Box } from "@mui/system";
import moment from "moment";

const Vendors = () => {
  const [addVendorDialog, setAddVendorDialog] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");

  const [filters, setFilters] = useState<any>({
    time: [moment().subtract({ months: 1 }), moment()],
  });

  const dateFrom = filters.time[0].format("YYYY-MM-DD");
  const dateTo = filters.time[1].format("YYYY-MM-DD");

  const vendorsUrl = `${LEASE_URL}/company/vendors?first=${pageSize}&skip=${
    pageSize * (page - 1)
  }&search=${search}`;

  const {
    isLoading: vendorsLoading,
    data: vendorsData,
    refetch: refetchVendors,
  } = useQuery(["getVendors", page, pageSize, search], () =>
    authorizedFetch(vendorsUrl)
  );

  const countUrl = `${LEASE_URL}/company/vendors`;

  const {
    isLoading: countLoading,
    data: countData,
    refetch: refetchVendorCounts,
  } = useQuery(["getVendorsCounts"], () => authorizedFetch(countUrl));

  useEffect(() => {
    appBar.open(
      <AppBar
        {...{
          setAddVendorDialog,
          filters,
          setFilters,
        }}
      />
    );
    return () => {
      appBar.close();
      drawer.close();
    };
  }, [filters]);

  const vendorsGraphUrl = `${LEASE_URL}/company/vendors/list`;

  const {
    // isLoading: vendorsLoading,
    data: vendorsGraphList,
    refetch: refetchGraphVendors,
  } = useQuery(["getFMSGraphVendors"], () => authorizedFetch(vendorsGraphUrl));

  return (
    <>
      <AddVendorDialog
        open={addVendorDialog}
        handleClose={() => {
          setAddVendorDialog(false);
        }}
        refetchVendors={refetchVendors}
        refetchVendorCounts={refetchVendorCounts}
      />
      <Total vendorsData={countData} vendorsLoading={countLoading} />
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr",
            lg: "repeat(2, 1fr)",
          },
          gap: { xs: 1.75, md: 4 },
        }}
      >
        <VehicleHealth />
        <VendorVSDistance
          dateTo={dateTo}
          dateFrom={dateFrom}
          vendorsGraphList={vendorsGraphList}
        />
      </Box>

      <List
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setSearch={setSearch}
        search={search}
        vendorsLoading={vendorsLoading}
        vendorsData={vendorsData}
        refetchVendors={refetchVendors}
        refetchVendorCounts={refetchVendorCounts}
        refetchGraphVendors={refetchGraphVendors}
      />
    </>
  );
};

export default Vendors;
