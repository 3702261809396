import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { queryClient } from "index";
import { authorizedFetch, drawer, setLoader, snackbar } from "utils";
import { RETAIL_URL } from "utils/constants";

const DeleteDialog = ({ open, handleClose, distributor }: any) => {
  function handleDelete() {
    setLoader(true);
    let url = `${RETAIL_URL}/distribution/distributor/${distributor?._id}`;
    authorizedFetch(url, { method: "DELETE" })
      .then((res) => {
        setLoader(false);
        if (res.meta.success) {
          snackbar.success(`Distributor deleted`);
          queryClient.resetQueries("getDistributors");
          drawer.close();
          handleClose();
        } else {
          console.error(res);
          snackbar.error(`Error deleting distributor`);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
        snackbar.error(`Error deleting distributor`);
      });
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Delete Distributor?</DialogTitle>
      <DialogContent>
        <Typography sx={{ my: 1, mr: 5 }}>
          Are you sure you want to delete <b>{distributor?.name}</b>?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={handleDelete}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
