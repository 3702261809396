import { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import Breadcrumbs from "../../../components/Breadcrumbs";
import ViewSelector, { View } from "components/ViewSelector";
import Dashboard from "./Dashboard";
import List from "./List";

import { authorizedFetch, getPermissions } from "utils";
import AddDialog from "./AddDialog";
import { LEASE_URL } from "utils/constants";

import { useQuery } from "react-query";

const Vehicles = () => {
  const { canWrite } = getPermissions("rental:leases");

  const [view, setView] = useState<View>("grid");
  const [addDialog, setAddDialog] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tab, setTab] = useState(0);

  const [sortByAscending, setSortByAscending] = useState("1");

  const [search, setSearch] = useState("");

  const [type, setType] = useState(0);

  const leasesURL = `${LEASE_URL}/company/bookings?first=${pageSize}&skip=${
    pageSize * (page - 1)
  }&sort=${
    sortByAscending === "1" ||
    sortByAscending === "4" ||
    sortByAscending === "5"
      ? -1
      : 1
  }&sortBy=${
    sortByAscending === "1" || sortByAscending === "2"
      ? "createdAt"
      : sortByAscending === "3" || sortByAscending === "4"
      ? "vin"
      : "bookingAmount"
  }&status=${
    tab === 0
      ? ""
      : tab === 1
      ? "ACTIVE"
      : tab === 2
      ? "BOOKED"
      : tab === 3
      ? "PAYMENT_INITIALISED"
      : tab === 4
      ? "TERMINATED"
      : tab === 5
      ? "TERMINATED_FAILED_PAYMENT"
      : "ENDED"
  }&search=${search}&type=${
    type === 0 ? "" : type === 1 ? "SHORT_TERM" : "LONG_TERM"
  }`;

  const {
    isLoading: leaseLoading,
    data: leasesData,
    refetch: refetchBookings,
  } = useQuery(
    ["getAllBookings", page, pageSize, tab, sortByAscending, search, type],
    () => authorizedFetch(leasesURL)
  );

  const bookingStats = `${LEASE_URL}/company/bookings/stats`;

  const {
    data: statsData,
    refetch: refetchStats,
    isLoading: statsLoading,
  } = useQuery("getBookingsStats", () => authorizedFetch(bookingStats));

  useEffect(() => {
    setPage(1);
    setPageSize(10);
  }, [sortByAscending, search, type]);

  useEffect(() => {
    if (search) {
      setTab(0);
    }
  }, [search]);
  // useEffect(() => {
  //   setTab(0);
  // }, [type]);

  return (
    <>
      <Box
        width={1}
        mb={{ xs: view === "grid" ? 1 : 2, md: view === "grid" ? 1 : 3 }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Breadcrumbs />

        <ViewSelector
          view={view}
          setView={setView}
          extras={
            canWrite || view === "list"
              ? () => {
                  return (
                    <>
                      {canWrite && (
                        <Button
                          sx={{ mr: { xs: 1, md: 2 } }}
                          onClick={() => setAddDialog(true)}
                        >
                          <Add />
                        </Button>
                      )}
                    </>
                  );
                }
              : undefined
          }
        />
        {/* )} */}
      </Box>
      {view === "grid" ? (
        <Box
          sx={{
            width: 1,
          }}
        >
          <Dashboard statsData={statsData} statsLoading={statsLoading} />
        </Box>
      ) : (
        <List
          page={page}
          setPage={setPage}
          tab={tab}
          setTab={setTab}
          pageSize={pageSize}
          setPageSize={setPageSize}
          leaseLoading={leaseLoading}
          leasesData={leasesData}
          refetchBookings={refetchBookings}
          sortByAscending={sortByAscending}
          setSortByAscending={setSortByAscending}
          setSearch={setSearch}
          type={type}
          setType={setType}
          statsData={statsData}
          refetchStats={refetchStats}
        />
      )}
      <AddDialog
        open={addDialog}
        handleClose={() => {
          setAddDialog(false);
        }}
        refetchBookings={refetchBookings}
        refetchStats={refetchStats}
      />
    </>
  );
};

export default Vehicles;
