import {
  AddRounded,
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from "@mui/icons-material";
import {
  Box,
  Chip,
  ClickAwayListener,
  Grow,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Popper,
  TextField,
} from "@mui/material";
import { useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";

interface Props {
  locations: string[];
  // setLocations: React.Dispatch<React.SetStateAction<string[]>>
  setLocations: (arg: string[]) => void;
}

const LocationSelector = ({ locations, setLocations }: Props) => {
  const anchorEl = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");

  const url = `https://geocode.revos.in/place/cities?country=India&state=&input=`;
  const { isLoading, data } = useQuery(["getCities"], () =>
    fetch(url, {
      headers: {
        cache: "true",
        // master: masterView,
      },
    }).then((res) => res.json())
  );

  const list = useMemo(() => {
    return (data?.data?.constructor === Array ? data.data : []).filter(
      (el: any) =>
        !["Bangalore Rural", "Bangalore Urban"].includes(el.name) && // Hide these two in favor of "Bengaluru"
        !locations.includes(el.name) &&
        el.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [data, search, locations]);

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => {
      setSearch("");
    }, 100);
  };

  const maxListLength = 5;

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box zIndex={9}>
        <TextField
          ref={anchorEl}
          size="small"
          value={
            isOpen
              ? search
              : locations.length === 0
              ? "Select Location"
              : locations.join(", ")
          }
          onChange={(e) => setSearch(e.target.value)}
          placeholder={"Type to search..."}
          onFocus={() => setIsOpen(true)}
          sx={{
            "& .MuiOutlinedInput-input": {
              cursor: "pointer",
              color: (theme) => theme.customColors.text.grey,
            },
          }}
          InputProps={{
            sx: {
              borderRadius: 10,
              boxShadow: (theme) => theme.customShadows.inputField,
              "&::placeholder": {
                color: (theme) => theme.customColors.text.grey + " !important",
                opacity: "1 !important",
              },
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  // size="small"
                  onClick={() => (isOpen ? handleClose() : setIsOpen(true))}
                >
                  {isOpen ? (
                    <KeyboardArrowUpRounded color="primary" />
                  ) : (
                    <KeyboardArrowDownRounded />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputProps={{
            autoComplete: "off",
          }}
        />
        <Popper
          transition
          open={isOpen}
          anchorEl={anchorEl.current}
          disablePortal
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: "50% 0",
              }}
            >
              <Paper
                sx={{
                  width: 260,
                  maxHeight: 400,
                  overflow: "auto",
                }}
                elevation={8}
              >
                {locations.length > 0 && (
                  <Box
                    sx={{
                      p: 2,
                      pb: 0,
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {locations.map((el: string, i: number) => (
                      <Chip
                        key={i}
                        size="small"
                        sx={{
                          mr: 1,
                          mb: 1,
                          lineHeight: "1em",
                          color: (theme) => theme.customColors.text.grey,
                          bgcolor: (theme) =>
                            theme.customColors.background.lightGrey,
                        }}
                        label={el}
                        onDelete={() =>
                          setLocations(
                            locations.filter((el2: string) => el !== el2)
                          )
                        }
                        // deleteIcon={<Close fontSize="small" />}
                      />
                    ))}
                    {locations.length > 1 && (
                      <Chip
                        size="small"
                        color="secondary"
                        variant="outlined"
                        onClick={() => setLocations([])}
                        label="Clear All"
                      />
                    )}
                  </Box>
                )}
                {isLoading ? (
                  <List dense>
                    <ListItem>
                      <ListItemText secondary="Loading..." />
                    </ListItem>
                  </List>
                ) : (
                  <List dense>
                    {list.slice(0, maxListLength).map((el: any, i: number) => (
                      <ListItem
                        key={i}
                        sx={{
                          "&:hover": {
                            background: (theme) => theme.palette.action.hover,
                          },
                        }}
                      >
                        <ListItemText primary={el.name} />
                        <ListItemSecondaryAction>
                          <IconButton
                            size="small"
                            onClick={() =>
                              setLocations([...locations, el.name])
                            }
                          >
                            <AddRounded
                              fontSize="small"
                              sx={{ color: "text.primary" }}
                            />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                    {list.length > maxListLength && (
                      <ListItem>
                        <ListItemText
                          secondary={`+${list.length - maxListLength} more...`}
                        />
                      </ListItem>
                    )}
                    {list.length === 0 && (
                      <ListItem>
                        <ListItemText
                          sx={{ textAlign: "center" }}
                          secondary={`No locations found${
                            search ? ` for "${search}"` : ""
                          }`}
                        />
                      </ListItem>
                    )}
                  </List>
                )}
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

export default LocationSelector;
