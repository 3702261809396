import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { appBar, authorizedFetch, drawer, GlobalState } from "utils";
import { VENDORS_URL_TEMP } from "utils/constants";
import AddDialog from "./AddDialog";
import AppBar from "./AppBar";
import Cards from "./Cards";
import List from "./List";

const Vendors = () => {
  const [addDialog, setAddDialog] = useState(false);

  const { masterView } = useSelector((state: GlobalState) => state.global);
  const [search, setSearch] = useState("");
  const [locations, setLocations] = useState<string[]>([]);

  const cities = locations.join("_");

  const statsUrl = `${VENDORS_URL_TEMP}/company/stats/all?city=${locations}`;
  const { isLoading: statsLoading, data: statsData } = useQuery(
    ["getAllStats", masterView, locations],
    () =>
      authorizedFetch(statsUrl, {
        headers: {
          cache: "true",
          master: masterView,
        },
      })
  );

  const vendorsUrl = `${VENDORS_URL_TEMP}/company/getVendors?orderBy=CREATED_AT_DESC&city=${cities}&search=${search}`;
  const { isLoading: vendorsLoading, data: vendorsData } = useQuery(
    ["getVendors", cities, search],
    () => authorizedFetch(vendorsUrl)
  );

  useEffect(() => {
    appBar.open(
      <AppBar
        {...{
          locations,
          setLocations,
          onAddVendorClick: () => setAddDialog(true),
        }}
      />
    );
    return () => {
      appBar.close();
      drawer.close();
    };
  }, [locations]);

  return (
    <>
      <AddDialog open={addDialog} handleClose={() => setAddDialog(false)} />
      <Box
        sx={{
          px: { xs: 2, md: 7 },
          pt: { xs: 2, md: 4.75 },
          pb: { xs: 3, md: 7 },
        }}
      >
        <Cards
          {...{
            statsLoading,
            statsData,
            vendorsLoading,
            vendorsData,
          }}
        />
        <List
          {...{
            vendorsLoading,
            vendorsData,
            setSearch,
          }}
        />
      </Box>
    </>
  );
};

export default Vendors;
