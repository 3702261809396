import { Box, Typography } from "@mui/material";
import List from "./List";
import { useEffect } from "react";
import { appBar } from "utils";

const Trips = () => {
  useEffect(() => {
    appBar.open(<AppBar />);

    return () => {
      appBar.close();
    };
  }, []);

  return (
    <Box
      sx={{
        px: { xs: 2, md: 7 },
        pt: { xs: 2, md: 4.75 },
        pb: { xs: 3, md: 7 },
      }}
    >
      <List />
    </Box>
  );
};

const AppBar = () => {
  return (
    <Box
      sx={{
        width: 1,
        py: 3,
        px: { xs: 2, md: 7 },
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h2">Trips</Typography>
      </Box>
    </Box>
  );
};

export default Trips;
