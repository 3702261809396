import {
  AddBoxOutlined,
  Circle,
  Error,
  InfoOutlined,
  PeopleAltOutlined,
  SystemUpdateAlt,
  VerifiedUser,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Link,
  // Tab,
  // Tabs,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { alpha } from "@mui/system";
import { ReactComponent as RupeeCycleIcon } from "assets/images/icons/rupee-cycle.svg";
import Table, { Column } from "components/Table";
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import {
  authorizedFetch,
  drawer,
  getPermissions,
  titleCase,
  GlobalState,
} from "utils";
import { BOLT_URL } from "utils/constants";
import AssignVendorDialog from "./AssignVendorDialog";
import DrawerContent from "./DrawerContent";
import OTAUpdateDialog from "./OTAUpdateDialog";
import RestrictedUsersDialog from "./RestrictedUsersDialog";
import SettlementDialog from "./SettlementDialog";
import { useSelector } from "react-redux";

// type SortOptions =
//   "BOOKINGS_DESC"
//   | "CREATED_AT_DESC"
//   | "CREATED_AT_ASC"
//   | "BOOKINGS_ASC"
//   | "BOOKINGS_DESC"
//   | "AMOUNT_ASC"
//   | "AMOUNT_DESC"
//   | "CHARGE_PER_HOUR_ASC"
//   | "CHARGE_PER_HOUR_DESC"
//   | "CHARGE_PER_KWH_ASC"
//   | "CHARGE_PER_KWH_DESC"

const ChargerList = ({
  page,
  setPage,
  pageSize,
  setPageSize,
  sortBy,
  setSortBy,
  isLoading,
  data,
  masterView,
}: any) => {
  const { company } = useSelector((state: GlobalState) => state.global);
  const isCUGMonitorAccount = company?.type === "CUG_VENDOR";

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md") as any);

  const { canWrite } = getPermissions("charger:chargers");

  const [restrictedUsersDialog, setRestrictedUsersDialog] = useState({
    open: false,
    data: null,
  });

  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [vendorsDialog, setVendorsDialog] = useState({
    open: false,
    data: {},
  });
  const [settlementDialog, setSettlementDialog] = useState({
    open: false,
    data: null,
  });

  const [otaUpdateDialog, setOTAUpdateDialog] = useState({
    open: false,
    data: null,
  });

  return (
    <>
      <Table
        px={isMdUp ? 3 : 2}
        rowCount={data?.data?.chargersCount}
        serverSidePagination
        activePage={page}
        activePageSize={pageSize}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        serverSideSorting
        sortModel={{
          "charger.createdAt": {
            asc: "CREATED_AT_ASC",
            desc: "CREATED_AT_DESC",
          },
          "chargerStats.totalBookings": {
            asc: "BOOKINGS_ASC",
            desc: "BOOKINGS_DESC",
          },
          "chargerStats.totalEarnings": {
            asc: "AMOUNT_ASC",
            desc: "AMOUNT_DESC",
          },
        }}
        sortBy={sortBy}
        setSortBy={setSortBy}
        loading={isLoading}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        selectable={!isCUGMonitorAccount && canWrite}
        // selectOnClick
        rows={data?.data?.chargers || []}
        columns={[
          {
            key: isCUGMonitorAccount ? "chargerId" : "charger.chargerId",
            label: "Charger UID",
            Render: (row) => (
              <Box display="flex" alignItems="center">
                <Link
                  sx={{
                    color: "text.primary",
                    cursor: "pointer",
                    textDecorationColor: (theme) =>
                      alpha(theme.palette.text.primary, 0.4),
                  }}
                  onClick={() =>
                    drawer.open(
                      <DrawerContent
                        chargerId={
                          isCUGMonitorAccount
                            ? row.chargerId
                            : row.charger.chargerId
                        }
                        openTab={0}
                      />
                    )
                  }
                >
                  {isCUGMonitorAccount ? row.chargerId : row.charger.chargerId}
                </Link>
                {!isCUGMonitorAccount && row.charger.boltVerified && (
                  <Tooltip title="Verified" followCursor>
                    <VerifiedUser
                      color="primary"
                      fontSize="inherit"
                      sx={{ ml: 1, fontSize: 16 }}
                    />
                  </Tooltip>
                )}
                {!isCUGMonitorAccount &&
                  ["RESTRICTED_FREE", "RESTRICTED_PAID"].includes(
                    row.charger.usageType
                  ) && (
                    <Tooltip title="Restricted Charger">
                      <Box
                        sx={{
                          ml: 1,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "16px",
                          height: "16px",
                          fontSize: 10,
                          lineHeight: 10,
                          fontWeight: 700,
                          color: "error.main",
                          cursor: "default",
                          border: (theme) =>
                            `1px solid ${theme.palette.error.main}`,
                          borderRadius: "3px",
                        }}
                      >
                        R
                      </Box>
                    </Tooltip>
                  )}
              </Box>
            ),
          },
          // {
          //   key: "vendor.name",
          //   label: "Vendor",
          //   Render: (row) =>
          //     row?.vendor?.name ? row?.vendor?.name : "Unassigned",
          // },
          {
            key: isCUGMonitorAccount ? "health" : "charger.health",
            label: "Operational Status",
            Render: (row) => {
              const health = isCUGMonitorAccount
                ? row.health
                : row.charger.health;
              return (
                <Tooltip
                  title={
                    health === "HEALTHY"
                      ? "Used Within Last 15 Days"
                      : health === "MODERATE"
                      ? "Used Between Last 16-30 Days"
                      : health === "CRITICAL"
                      ? "Used Between Last 31-45 Days"
                      : health === "INACTIVE"
                      ? "Used Before 45 Days"
                      : "Not available"
                  }
                >
                  <IconButton
                    size="small"
                    sx={{
                      ml: 3,
                      color:
                        health === "HEALTHY"
                          ? "#01A229"
                          : health === "MODERATE"
                          ? "#62D16A"
                          : health === "CRITICAL"
                          ? "#FFCC27"
                          : health === "INACTIVE"
                          ? "#EB5C5C"
                          : "Not available",
                    }}
                    children={<Circle fontSize="small" />}
                  />
                </Tooltip>
              );
            },
          },
          {
            key: isCUGMonitorAccount
              ? "chargerStatus"
              : "charger.chargerStatus",
            label: "Status",
            Render: (row) => (
              <ChargerStatus
                row={row}
                isCUGMonitorAccount={isCUGMonitorAccount}
              />
            ),
          },
          ...(!isCUGMonitorAccount
            ? ([
                {
                  key: "charger.createdAt",
                  label: "Initialized On",
                  Render: (row) =>
                    moment(row.charger.createdAt).format("MMM DD, YYYY "),
                },
                {
                  key: "charger.lastPing",
                  label: "Last Ping",
                  Render: (row) =>
                    row?.charger?.lastPing
                      ? moment(row.charger.lastPing).format(
                          "MMM DD, YYYY, hh:mm a"
                        )
                      : "N/A",
                },
                {
                  key: "chargerStats.totalEnergyConsumed",
                  label: "Energy Dispensed",
                  format: (val) =>
                    val
                      ? Number(val.toFixed(3)).toLocaleString() + " kWh"
                      : "0 kWh",
                },
                {
                  key: "chargerStats.totalBookings",
                  label: "Bookings",
                  format: (val) => val.toLocaleString(),
                },
                {
                  key: "chargerStats.totalEarnings",
                  label: "Earnings",
                  format: (val) => "₹" + val.toLocaleString(),
                },
                // {
                //   key: "paymentDetails.costPerkWh",
                //   label: "Cost per kWh",
                //   format: val => "₹" + val,
                // },
                // {
                //   key: "paymentDetails.chargePerHour",
                //   label: "Cost per hour",
                //   format: val => "₹" + val,
                // },
                {
                  key: "chargerType.communicationProtocol",
                  label: "Protocol",
                },
              ] as Column[])
            : []),
          {
            key: isCUGMonitorAccount
              ? "booking.lastUsed"
              : "charger.lastBookingDate",
            label: "Last Used",
            format: (val) => (val ? moment(val).format("MMM DD, YYYY") : "N/A"),
          },
          {
            key: "actions",
            label: "Actions",
            Render: (row) => (
              <>
                <Tooltip title="Info">
                  <IconButton
                    size="small"
                    sx={{
                      color: (theme: any) => theme.customColors.grey,
                      mr: 0.5,
                    }}
                    onClick={() =>
                      drawer.open(
                        <DrawerContent
                          chargerId={
                            isCUGMonitorAccount
                              ? row.chargerId
                              : row.charger.chargerId
                          }
                          openTab={0}
                        />
                      )
                    }
                    children={<InfoOutlined fontSize="small" />}
                  />
                </Tooltip>
                {!isCUGMonitorAccount &&
                  String(row.charger.modelId).includes("BOLT") &&
                  ["WIFI", "SIM", "BLE"].includes(row.chargerType.mode) && (
                    <Tooltip
                      title={
                        row.chargerType.mode === "BLE"
                          ? "Mark for OTA Update"
                          : "Push OTA Update"
                      }
                    >
                      <IconButton
                        size="small"
                        sx={{
                          color: (theme: any) => theme.customColors.grey,
                          mr: 0.5,
                          transform: "rotate(180deg)",
                        }}
                        children={<SystemUpdateAlt fontSize="small" />}
                        onClick={() =>
                          setOTAUpdateDialog({ open: true, data: row })
                        }
                      />
                    </Tooltip>
                  )}
                {!isCUGMonitorAccount &&
                  ["RESTRICTED_FREE", "RESTRICTED_PAID"].includes(
                    row.charger.usageType
                  ) && (
                    <Tooltip title="Assign Users">
                      <IconButton
                        size="small"
                        sx={{
                          color: (theme: any) => theme.customColors.grey,
                        }}
                        onClick={() =>
                          setRestrictedUsersDialog({ open: true, data: row })
                        }
                        children={<PeopleAltOutlined fontSize="small" />}
                      />
                    </Tooltip>
                  )}

                {!isCUGMonitorAccount &&
                  ["MODERATE", "CRITICAL", "INACTIVE"].includes(
                    row.charger.health
                  ) && (
                    <Tooltip title="Action Required">
                      <IconButton
                        size="small"
                        sx={{
                          color:
                            row.charger.health === "MODERATE"
                              ? "#FFA500"
                              : row.charger.health === "CRITICAL"
                              ? "#FFA500"
                              : row.charger.health === "INACTIVE"
                              ? "#FFA500"
                              : "",
                        }}
                        onClick={() => {
                          drawer.open(
                            <DrawerContent
                              chargerId={row.charger.chargerId}
                              openTab={4}
                            />
                          );
                        }}
                        children={<Error fontSize="small" />}
                      />
                    </Tooltip>
                  )}
              </>
            ),
          },
        ]}
        toolbar={() => (
          <>
            <Button
              startIcon={<AddBoxOutlined />}
              sx={{ mr: 2 }}
              onClick={() => {
                setVendorsDialog({ open: true, data: selectedRows });
              }}
            >
              Assign Vendor
            </Button>
            <Button
              startIcon={<RupeeCycleIcon />}
              onClick={() => {
                setSettlementDialog({ open: true, data: selectedRows });
              }}
            >
              Settlement Cycle
            </Button>
          </>
        )}
      />
      <OTAUpdateDialog
        open={otaUpdateDialog.open}
        data={otaUpdateDialog.data}
        handleClose={() =>
          setOTAUpdateDialog((prev) => ({ ...prev, open: false }))
        }
      />
      <RestrictedUsersDialog
        key={restrictedUsersDialog.data}
        open={restrictedUsersDialog.open}
        handleClose={() =>
          setRestrictedUsersDialog({ ...restrictedUsersDialog, open: false })
        }
        charger={restrictedUsersDialog.data}
      />
      <AssignVendorDialog
        open={vendorsDialog.open}
        data={vendorsDialog.data}
        handleClose={() => {
          setVendorsDialog({ ...vendorsDialog, open: false });
        }}
      />
      <SettlementDialog
        open={settlementDialog.open}
        data={settlementDialog.data}
        handleClose={() =>
          setSettlementDialog((prev) => ({ ...prev, open: false }))
        }
        setSelectedRows={setSelectedRows}
      />
    </>
  );
};

const ChargerStatus = ({ row, isCUGMonitorAccount }: any) => {
  const isOCPP = row?.chargerType?.communicationProtocol === "OCPP";

  const { isLoading, data } = useQuery(
    ["getChargerStatus", row?.charger?.chargerId],
    () => authorizedFetch(`${BOLT_URL}/charger/get/${row?.charger?.chargerId}`),
    {
      enabled: isOCPP,
    }
  );

  let status = isOCPP
    ? isLoading
      ? "Loading..."
      : data?.data?.charger?.chargerStatus || "UNKNOWN"
    : isCUGMonitorAccount
    ? row.chargerStatus
    : row?.charger?.chargerStatus || "UNKNOWN";

  return (
    <Avatar
      variant="status"
      className={
        status === "AVAILABLE"
          ? "green"
          : status === "CHARGING"
          ? "blue"
          : status === "BOOKED"
          ? "red"
          : "grey"
      }
    >
      {titleCase(status.split("_").join(" "))}
    </Avatar>
  );
};

export default ChargerList;
