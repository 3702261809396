import {
  InfoOutlined,
  MonitorOutlined,
  SystemUpdateAlt,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import Breadcrumbs from "components/Breadcrumbs";
import Search from "components/Search";
import TableComponent from "components/Table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { authorizedFetch, drawer, getDuration, titleCase } from "utils";
import { RETAIL_URL } from "utils/constants";
import VehicleDrawer from "views/Common/VehicleView/VehicleDrawer";
import OTAUpdateDialog from "../../Common/Actions/OTAUpdateDialog";

const List = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [otaUpdateDialog, setOTAUpdateDialog] = useState({
    open: false,
    data: null,
  });

  const history = useHistory();

  const url = `${RETAIL_URL}/sold-vehicles?first=${pageSize}&skip=${
    pageSize * (page - 1)
  }&orderBy=updatedAt&ascending=false${search ? "&search=" + search : ""}`;

  const { isLoading, data } = useQuery(
    ["soldVehicles", page, pageSize, search],
    () => authorizedFetch(url, { headers: { token: 1234 } })
  );

  useEffect(() => {
    return () => {
      drawer.close();
    };
  }, []);

  return (
    <>
      <OTAUpdateDialog
        open={otaUpdateDialog.open}
        data={otaUpdateDialog.data}
        handleClose={() =>
          setOTAUpdateDialog((prev) => ({ ...prev, open: false }))
        }
      />
      <Box
        width={1}
        mt={0.5}
        mb={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Breadcrumbs />
      </Box>
      <Paper
        sx={{
          width: 1,
          boxShadow: (theme) => theme.customShadows.small,
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            width: 1,
            p: 3,
            pb: 2.75,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box width="fit-content">
            <Tabs className="dense" value={0}>
              <Tab
                label="All"
                className="hasCount"
                sx={{
                  "&:after": {
                    content: `"${data?.meta?.totalCount || 0}"`,
                  },
                }}
              />
            </Tabs>
          </Box>
          <Search
            handleSearch={(value) => {
              setPage(1);
              setSearch(value);
            }}
            persist
            enableClear
          />
          {/* <FilterBy /> */}
        </Box>
        <TableComponent
          idKey="_id"
          loading={isLoading}
          rowCount={data?.meta?.totalCount}
          rows={data?.data?.constructor === Array ? data.data : []}
          serverSidePagination
          activePage={page}
          activePageSize={pageSize}
          onPageChange={(value) => setPage(value)}
          onPageSizeChange={(value) => setPageSize(value)}
          columns={[
            { key: "vin", label: "VIN" },
            {
              key: "model.name",
              label: "Model",
            },
            {
              key: "model.protocol",
              label: "Protocol",
            },
            {
              key: "lastPinged",
              label: "Last Ping",
              Render: ({ lastPinged }) => {
                return (
                  <Box>
                    {lastPinged
                      ? (moment().valueOf() - moment(lastPinged).valueOf()) /
                          60000 <
                        360
                        ? getDuration(
                            (moment().valueOf() -
                              moment(lastPinged).valueOf()) /
                              60000
                          )
                        : (moment().valueOf() - moment(lastPinged).valueOf()) /
                            60000 <
                          0
                        ? "0s"
                        : "Dormant"
                      : "Dormant"}
                  </Box>
                );
              },
            },
            {
              key: "updatedAt",
              label: "Updated At",
              format: (value) => moment(value).format("MMM DD, YYYY"),
            },
            {
              key: "health",
              label: "Health",
              Render: ({ health }: any) => {
                return (
                  <Avatar
                    variant="status"
                    className={
                      health === "active"
                        ? ""
                        : health === "moderate"
                        ? "yellow"
                        : health === "critical"
                        ? "red"
                        : "grey"
                    }
                  >
                    {health ? titleCase(health) : "Inactive"}
                  </Avatar>
                );
              },
            },
            {
              key: "actions",
              label: "Actions",
              Render: (row) => (
                <Box display="flex" alignItems="center">
                  <Tooltip title="Info" followCursor>
                    <IconButton
                      size="small"
                      sx={{ color: (theme) => theme.customColors.action }}
                      children={<InfoOutlined fontSize="small" />}
                      onClick={() =>
                        drawer.open(<VehicleDrawer key={row.id} id={row._id} />)
                      }
                    />
                  </Tooltip>
                  {/* <Tooltip title="Stats" followCursor>
                    <IconButton
                      size="small"
                      sx={{
                        ml: 0.5,
                      }}
                      children={<BarChart fontSize="small" />}
                      onClick={() =>
                        drawer.open(
                          <VehicleDrawer
                            key={row.id}
                            vin={row.vin}
                            openToTab={1}
                          />
                        )
                      }
                    />
                  </Tooltip> */}
                  <Tooltip title="Monitor" followCursor>
                    <IconButton
                      size="small"
                      sx={{
                        ml: 0.5,
                        color: (theme) => theme.customColors.action,
                      }}
                      children={<MonitorOutlined fontSize="small" />}
                      onClick={() => {
                        history.push("/sold-vehicles/" + row._id);
                      }}
                    />
                  </Tooltip>
                  {row?.components?.some(
                    (obj: any) => obj.category === "CONTROLLER"
                  ) &&
                    ["SMART", "SMART_PLUS"].includes(row?.model?.protocol) && (
                      <Tooltip title={"Mark for OTA Update"} followCursor>
                        <IconButton
                          size="small"
                          sx={{
                            color: (theme: any) => theme.customColors.action,
                            mr: 0.5,
                            transform: "rotate(180deg)",
                          }}
                          children={<SystemUpdateAlt fontSize="small" />}
                          onClick={() => {
                            console.log(row);
                            setOTAUpdateDialog({ open: true, data: row });
                          }}
                        />
                      </Tooltip>
                    )}
                </Box>
              ),
            },
          ]}
        />
      </Paper>
    </>
  );
};

export default List;
