import { useState } from "react";
import { useSelector } from "react-redux";
import { getDarkModePreference, GlobalState } from "utils";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { subdomain } from "utils/constants";
import { useHistory } from "react-router-dom";

const SignUp = ({ setView }: any) => {
  const history = useHistory();

  let isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );
  const [link, setLink] = useState("");

  const [valid, setValid] = useState(true);

  function handleSubmit() {
    // TODO: check if valid link
    if (
      !link ||
      !["/company/invite/", "/user/invite/"].some((el) => link.includes(el))
    )
      setValid(false);
    else
      history.push(
        `/${link.includes("company/invite") ? "company" : "user"}/invite/${
          link.split("invite/")[1]
        }`
      );
  }

  return (
    <>
      <Helmet>
        <title>Sign up - {subdomain.name}</title>
      </Helmet>
      <Box
        sx={{
          mt: { xs: 15, md: 0 },
          maxWidth: 400,
          width: 1,
          display: "grid",
        }}
      >
        <Box mx="auto" maxWidth="max-content" width={1}>
          <Box mb={1} display="flex" alignItems="center">
            <Tooltip title="Go back">
              <IconButton
                sx={{ ml: -1.5, mr: 0.5 }}
                onClick={() => setView("sign-in")}
              >
                <ArrowBack />
              </IconButton>
            </Tooltip>
            <Typography
              variant="h5"
              sx={{
                fontSize: "1.5em",
                lineHeight: "1em",
                fontWeight: 600,
                color: isDarkMode ? "common.white" : "common.black",
              }}
            >
              Sign up with an invite link
            </Typography>
          </Box>
          <Typography variant="body1" mb={3.5} color="textSecondary">
            Enter the invite link you've received from your company or team.
          </Typography>
          <Box display="flex" alignItems="start">
            <TextField
              fullWidth
              sx={{ mr: 2 }}
              label="Invite link"
              value={link}
              onChange={(e) => {
                setValid(true);
                setLink(e.target.value);
              }}
              error={!valid}
              helperText={!valid && "Please enter a valid invite link."}
            />
            <Button
              sx={{ mt: 1 }}
              size="large"
              variant="contained"
              onClick={handleSubmit}
            >
              Continue
            </Button>
          </Box>
          {/* <Box
          sx={{
            my: 6,
            mx: "auto",
            maxWidth: 300,
            width: 1,
            position: "relative",
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            "& span": {
              position: "absolute",
              width: 50,
              mx: "auto",
              right: 0,
              left: 0,
              top: "-0.75em ",
              textAlign: "center",
              bgcolor: "background.default",
              userSelect: "none",
              fontSize: "1em",
              color: "text.disabled",
            },
          }}
        >
          <span>OR</span>
        </Box>
        <Button
          size="large"
          fullWidth
          variant="outlined"
          onClick={() => setContext("company-details")}
        >
          Register new company
        </Button> */}
        </Box>
      </Box>
    </>
  );
};

export default SignUp;
