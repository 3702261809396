import { AddBusinessOutlined, PersonAddAlt } from "@mui/icons-material";
import {
  Box,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { getPermissions } from "utils";
import CompanyInviteDialog from "./components/CompanyInviteDialog";
import InviteDialog from "./components/InviteDialog";

const AppBar = () => {
  const { isSuperAdmin } = getPermissions("charger:admin");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [inviteDialog, setInviteDialog] = useState(false);
  const [companyInviteDialog, setCompanyInviteDialog] = useState(false);

  return (
    <>
      <InviteDialog
        open={inviteDialog}
        handleClose={() => setInviteDialog(false)}
      />
      <CompanyInviteDialog
        open={companyInviteDialog}
        handleClose={() => setCompanyInviteDialog(false)}
      />
      <Box
        sx={{
          width: 1,
          py: 3,
          px: { xs: 2, md: 7 },
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h2">Admin</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& > :not(:first-of-type)": {
              ml: 3,
            },
          }}
        >
          <Button
            sx={{
              width: { md: 300 },
              borderRadius: 10,
              borderWidth: "1.5px !important",
              borderColor: (theme) => theme.palette.primary.main,
              textTransform: "none",
              fontWeight: 500,
            }}
            variant="outlined"
            // startIcon={<AddRounded />}
            onClick={(e) =>
              isSuperAdmin
                ? setAnchorEl(e.currentTarget)
                : setInviteDialog(true)
            }
          >
            Invite
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem
              onClick={() => {
                setInviteDialog(true);
                setAnchorEl(null);
              }}
            >
              <ListItemIcon
                children={<PersonAddAlt sx={{ color: "text.primary" }} />}
              />
              Member
            </MenuItem>
            <MenuItem
              onClick={() => {
                setCompanyInviteDialog(true);
                setAnchorEl(null);
              }}
            >
              <ListItemIcon
                children={
                  <AddBusinessOutlined sx={{ color: "text.primary" }} />
                }
              />
              Company
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </>
  );
};

export default AppBar;
