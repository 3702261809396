import { DataSaverOn, ImageOutlined, Upload } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  GlobalState,
  authorizedFetch,
  drawer,
  getDarkModePreference,
} from "utils";
import { RETAIL_URL } from "utils/constants";
import AddVehicleDialog from "./components/AddVehicleDialog";
import ModelDrawer from "./components/ModelDrawer";
import BulkUploadDialog from "./BulkUploadDialog";
import { useSelector } from "react-redux";

const Grid = ({
  search,
  setView,
  setModelFilter,
  setTab,
  modelSearch,
}: any) => {
  const [addVehicleDialog, setAddVehicleDialog] = useState<any>({
    open: false,
    model: null,
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [bulkUploadDialog, setBulkUploadDialog] = useState({
    open: false,
    model: null,
  });

  const [selected, setSelected] = useState("");
  const [page, setPage] = useState(1);
  // const [pageSize, setPageSize] = useState(10);
  const pageSize = 9;

  let isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );

  const modelsUrl = `${RETAIL_URL}/assembly/model?first=${pageSize}&skip=${
    pageSize * (page - 1)
  }&search=${modelSearch}&orderBy=createdAt&ascending=false`;
  const { isLoading, data } = useQuery(
    ["getModels", page, pageSize, modelSearch],
    () => authorizedFetch(modelsUrl)
  );

  function handleDrawerClose() {
    setSelected("");
    drawer.close();
  }

  useEffect(() => {
    return () => {
      drawer.close();
    };
  }, []);

  useEffect(() => {
    setPage(1);
    handleDrawerClose();
  }, [search]);

  let placeholderRows = [];
  for (let i = 0; i < pageSize; i++) {
    placeholderRows.push("foo");
  }

  let rows = data?.data?.constructor === Array ? data.data : [],
    rowCount = data?.meta?.totalCount;

  return (
    <>
      <AddVehicleDialog
        open={addVehicleDialog.open}
        handleClose={() =>
          setAddVehicleDialog((prev: any) => ({ ...prev, open: false }))
        }
        model={addVehicleDialog.model}
        onSave={() => setView("list")}
      />
      <BulkUploadDialog
        open={bulkUploadDialog.open}
        handleClose={() =>
          setBulkUploadDialog((prev: any) => ({ ...prev, open: false }))
        }
        model={bulkUploadDialog.model}
        setTab={setTab}
        setView={setView}
      />
      {!isLoading && rows.length === 0 && (
        <Alert severity="info" sx={{ maxWidth: "fit-content", mx: "auto" }}>
          No records found.
        </Alert>
      )}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr",
            sm: "1fr 1fr",
            lg: selected ? "1fr 1fr" : "1fr 1fr 1fr",
            xl: selected ? "1fr 1fr 1fr" : "1fr 1fr 1fr 1fr",
          },
          gap: isLoading ? 5 : 1,
          columnGap: isLoading ? 4 : 2,
          pt: isLoading ? 2 : 0,
        }}
      >
        {isLoading
          ? placeholderRows.map((el: any, i: number) => (
              <Paper
                key={i}
                sx={{
                  borderRadius: 2,
                  p: 2,
                  boxShadow: (theme) => theme.customShadows.small,
                }}
              >
                <Box sx={{ display: "flex", height: 150, mb: 1 }}>
                  <Skeleton
                    sx={{ mb: 1, height: 150, width: 0.35, borderRadius: 2 }}
                    variant="rectangular"
                  />
                  <Box
                    sx={{
                      display: "flex",
                      height: 150,
                      flexDirection: "column",
                      gap: 2,
                      width: "65%",
                      pl: 2,
                    }}
                  >
                    <Skeleton sx={{ height: 32, width: 1 }} />
                    <Skeleton sx={{ height: 24, width: 1 }} />
                    <Skeleton sx={{ height: 24, width: 1 }} />
                    <Skeleton sx={{ height: 24, width: 1 }} />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: 2,
                  }}
                >
                  <Skeleton sx={{ height: 42 }} />
                  <Skeleton sx={{ height: 42 }} />
                </Box>
              </Paper>
            ))
          : rows.map((el: any, i: number) => (
              <Paper
                key={i}
                sx={{
                  borderRadius: 2,
                  my: 2,
                  mx: 1,
                  boxShadow: (theme) => theme.customShadows.small,
                  ...(selected === el._id
                    ? {
                        outline: (theme) =>
                          `2px solid ${theme.palette.primary.main}`,
                      }
                    : {
                        "&:hover": {
                          transition: "150ms",
                          transform: "scale(1.005)",
                          boxShadow: (theme) => theme.customShadows.medium,
                          cursor: "pointer",
                        },
                      }),
                }}
                onClick={() => {
                  setSelected(el._id);
                  drawer.open(
                    <ModelDrawer model={el} handleClose={handleDrawerClose} />
                  );
                }}
              >
                <Box
                  sx={{
                    widt: 1,
                    height: 150,
                    bgcolor: isDarkMode ? "ffffff50" : "#F7F7F7",
                    borderRadius: "10px 10px 0px 0px",
                    display: "flex",
                    alignItems: "center",
                    px: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: "35%",
                      height: "80%",
                      borderRadius: "10px",
                      display: "flex",
                      alignItems: "center",

                      overflow: "hidden",
                    }}
                  >
                    {el.image ? (
                      <img
                        src={el.image || "/asdf.jpg"}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        alt="Model Thumbnail"
                      />
                    ) : (
                      <ImageOutlined fontSize="large" color="disabled" />
                    )}
                  </Box>
                  <Box
                    sx={{
                      width: "65%",
                      height: "80%",
                      display: "flex",

                      flexDirection: "column",
                      overflow: "hidden",

                      pl: 2,
                      gap: 1,
                    }}
                  >
                    <Typography fontWeight={700}>{el.name}</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography fontWeight={400} fontSize={14}>
                        Protocol
                      </Typography>
                      <Avatar
                        variant="status"
                        className="grey"
                        sx={{ scale: 0.8 }}
                      >
                        {el.protocol}
                      </Avatar>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography fontWeight={400} fontSize={14}>
                        In Stock
                      </Typography>
                      <Avatar variant="status" className="blue">
                        {el.unassignedVehiclesCount}
                      </Avatar>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography fontWeight={400} fontSize={14}>
                        Sold Vehicles
                      </Typography>
                      <Avatar variant="status" className="green">
                        {el.soldVehiclesCount}
                      </Avatar>
                    </Box>
                  </Box>
                </Box>
                {/* <Box
                  sx={{
                    mb: 1.5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography fontWeight={700}>{el.name}</Typography>
                  <Avatar variant="status">
                    In Stock:&nbsp;
                    <span style={{ fontWeight: 600 }}>
                      {el.vehicles?.length}
                    </span>
                  </Avatar>
                </Box>
                <Box
                  sx={{
                    mb: 1,
                    width: 1,
                    height: 150,
                    borderRadius: 1,
                    border: (theme) => `1px solid ${theme.customColors.border}`,
                    overflow: "hidden",
                    bgcolor: (theme) =>
                      Boolean(el.image)
                        ? theme.palette.background.paper
                        : theme.palette.action.disabledBackground,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {el.image ? (
                    <img
                      src={el.image || "/asdf.jpg"}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                      alt="Model Thumbnail"
                    />
                  ) : (
                    <ImageOutlined fontSize="large" color="disabled" />
                  )}
                </Box>
                <Avatar variant="status">{el.protocol}</Avatar> */}
                <Box
                  sx={{
                    mt: 1.5,
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: 2,
                    px: 2,
                    pb: 2,
                    pt: 1,
                  }}
                >
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={(e) => {
                      e.stopPropagation();
                      setView("list");
                      setModelFilter(el);
                    }}
                    sx={{ borderRadius: 5 }}
                  >
                    Vehicles
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ borderRadius: 5 }}
                    onClick={(e) => {
                      if (el.protocol === "SMART") {
                        e.stopPropagation();
                        setAddVehicleDialog({
                          ...addVehicleDialog,
                          model: el,
                        });
                        setBulkUploadDialog({
                          ...bulkUploadDialog,
                          model: el,
                        });
                        setAnchorEl(e.currentTarget);
                      } else {
                        e.stopPropagation();
                        setAddVehicleDialog({
                          open: true,
                          model: el,
                        });
                      }
                    }}
                  >
                    Assemble
                  </Button>
                  {el.protocol === "SMART" && (
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={(e: any) => {
                        e.stopPropagation();
                        setAnchorEl(null);
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      sx={{
                        "& .MuiPaper-root": {
                          boxShadow: "0px 0px 10px 0px #00000010",
                          borderRadius: 2,
                          mt: 1,
                          width: "auto",
                        },
                      }}
                    >
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          setAddVehicleDialog({
                            ...addVehicleDialog,
                            open: true,
                          });

                          setAnchorEl(null);
                        }}
                      >
                        <ListItemIcon
                          children={
                            <>
                              <DataSaverOn
                                sx={{
                                  color: "primary.main",
                                  transform: "rotate(270deg)",
                                }}
                              />
                            </>
                          }
                        />
                        Single Entry
                      </MenuItem>

                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          setBulkUploadDialog({
                            ...bulkUploadDialog,
                            open: true,
                          });
                          setAnchorEl(null);
                        }}
                      >
                        <ListItemIcon
                          children={
                            <>
                              <Upload
                                sx={{
                                  color: "primary.main",
                                }}
                              />
                            </>
                          }
                        />
                        Bulk Upload
                      </MenuItem>
                    </Menu>
                  )}
                </Box>
              </Paper>
            ))}
      </Box>
      <Divider sx={{ width: 1, my: 2 }} />
      <Box
        sx={{
          userSelect: "none",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Pagination
          boundaryCount={2}
          count={Math.ceil((rowCount ? rowCount : rows.length) / pageSize)}
          page={page}
          onChange={(e, value) => {
            if (typeof value === "number") {
              setPage(value);
            }
          }}
        />
      </Box>
    </>
  );
};

export default Grid;
