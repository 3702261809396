import {
  AccountTreeOutlined,
  AddRounded,
  CurrencyRupee,
  DirectionsCarFilledOutlined,
  FileUploadOutlined,
  History,
} from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getPermissions, GlobalState } from "utils";
import BulkUploadDialog from "./BulkUploadDialog";

const AppBar = ({
  setAddModelDialog,
  setAddVehicleDialog,
  setAddPricingDialog,
  setActiveView,
}: any) => {
  const { canWrite } = getPermissions("rental:vehicles");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [bulkUploadDialogOpen, setBulkUploadDialogOpen] = useState(false);

  const { company } = useSelector((state: GlobalState) => state.global);

  return (
    <Box
      sx={{
        width: 1,
        py: 3,
        px: { xs: 2, md: 7 },
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="h2">Vehicles</Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          "& > :not(:first-of-type)": {
            ml: 3,
          },
        }}
      >
        {canWrite && (
          <Button
            sx={{
              // width: { md: 300 },
              px: 6,
              py: 1,
              borderRadius: 10,
              borderWidth: "1.5px !important",
              borderColor: (theme) => theme.palette.primary.main,
              textTransform: "none",
              fontWeight: 500,
            }}
            variant="outlined"
            startIcon={<AddRounded />}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            Add New
          </Button>
        )}
        {company?.type !== "DISTRIBUTOR" && (
          <ButtonGroup
            variant="outlined"
            sx={{
              ml: 2,
              "& .MuiButton-root": {
                borderRadius: 10,
                borderWidth: "1.5px !important",
                borderColor: (theme) => theme.palette.primary.main,
              },
            }}
          >
            <Button
              sx={{
                // width: { md: 300 },
                px: 4,
                py: 1,
                textTransform: "none",
                fontWeight: 500,
              }}
              variant="outlined"
              startIcon={<FileUploadOutlined />}
              onClick={() => setBulkUploadDialogOpen(true)}
            >
              Bulk Upload
            </Button>
            <Tooltip title="View Upload History">
              <Button
                sx={{ pl: 1.25, pr: 1.75 }}
                size="small"
                onClick={() => setActiveView("upload history")}
              >
                <History fontSize="small" />
              </Button>
            </Tooltip>
          </ButtonGroup>
        )}
      </Box>
      <BulkUploadDialog
        open={bulkUploadDialogOpen}
        handleClose={() => setBulkUploadDialogOpen(false)}
        setActiveView={setActiveView}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem
          onClick={() => {
            setAddPricingDialog(true);
            setAnchorEl(null);
          }}
        >
          <ListItemIcon
            children={
              <>
                <CurrencyRupee sx={{ color: "text.primary" }} />
                <Typography
                  sx={{
                    color: "text.primary",
                    ml: -0.5,
                    mt: -0.5,
                  }}
                >
                  +
                </Typography>
              </>
            }
          />
          Pricing
        </MenuItem>
        {company?.type !== "DISTRIBUTOR" && (
          <>
            <MenuItem
              onClick={() => {
                setAddModelDialog(true);
                setAnchorEl(null);
              }}
            >
              <ListItemIcon
                children={
                  <>
                    <AccountTreeOutlined sx={{ color: "text.primary" }} />
                    <Typography
                      sx={{
                        color: "text.primary",
                        ml: -0.2,
                        mt: -0.5,
                      }}
                    >
                      +
                    </Typography>
                  </>
                }
              />
              Model
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAddVehicleDialog(true);
                setAnchorEl(null);
              }}
            >
              <ListItemIcon
                children={
                  <>
                    <DirectionsCarFilledOutlined
                      sx={{ color: "text.primary" }}
                    />
                    <Typography
                      sx={{
                        color: "text.primary",
                        mt: -0.5,
                        ml: -0.3,
                      }}
                    >
                      +
                    </Typography>
                  </>
                }
              />
              Vehicle
            </MenuItem>
          </>
        )}
      </Menu>
    </Box>
  );
};

export default AppBar;
