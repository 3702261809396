import { Box, Paper, Typography, Avatar, Skeleton } from "@mui/material";
import {
  DoDisturbAltOutlined,
  EventAvailableOutlined,
  TaskAlt,
} from "@mui/icons-material";

const Loading = () => (
  <Box>
    <Skeleton width={38} height={38} variant="circular" />
    <Typography variant="h5" className="value">
      <Skeleton height={20} width="30%" />
    </Typography>
    <Typography variant="body2" className="title">
      <Skeleton width="60%" />
    </Typography>
  </Box>
);

const Total = ({ statsData, statsLoading }: any) => {
  return (
    <Paper
      sx={{
        height: "auto",
        p: 2,
        display: "grid",
        gap: 2,
        overflowX: "auto",
        gridTemplateColumns: {
          xs: "repeat(3, minmax(max-content, 1fr))",
          lg: "1fr",
        },
        "& > div": {
          p: 2,
          border: 1,
          borderColor: (theme) => theme.customColors.border,
          borderRadius: "4px",
          "& .icon": {
            mr: 2,
            width: 38,
            height: 38,
            borderRadius: 50,
          },
          "& .value": {
            fontWeight: 700,
            fontSize: 24,
            color: "text.primary",
            lineHeight: "1em",
            mb: 1,
            marginTop: 2,
            "& span": {
              fontWeight: 500,
              fontSize: 16,
            },
          },
          "& .title": {
            fontSize: 15,
            lineHeight: "20px",
            color: "text.secondary",
          },
        },
      }}
    >
      {statsLoading ? (
        <Loading />
      ) : (
        <Box>
          <Avatar variant="icon" className="icon">
            <EventAvailableOutlined sx={{ color: "#62D16A" }} />
          </Avatar>
          <Typography className="value">
            {statsData?.data?.booked
              ? Number(statsData?.data?.booked).toLocaleString()
              : 0}
          </Typography>
          <Typography className="title">Total Booked</Typography>
        </Box>
      )}
      {statsLoading ? (
        <Loading />
      ) : (
        <Box>
          <Avatar variant="icon" className="icon">
            <TaskAlt sx={{ color: "#62D16A" }} />
          </Avatar>
          <Typography className="value">
            {statsData?.data?.active
              ? Number(statsData?.data?.active)?.toLocaleString()
              : 0}
          </Typography>
          <Typography className="title">Total Active</Typography>
        </Box>
      )}
      {statsLoading ? (
        <Loading />
      ) : (
        <Box>
          <Avatar variant="icon" className="icon">
            <DoDisturbAltOutlined sx={{ color: "#62D16A" }} />
          </Avatar>
          <Typography className="value">
            {statsData?.data?.ended
              ? Number(statsData?.data?.ended).toLocaleString()
              : 0}
          </Typography>
          <Typography className="title">Total Ended</Typography>
        </Box>
      )}
    </Paper>
  );
};

export default Total;
