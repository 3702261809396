import { AddPhotoAlternateOutlined, Autorenew } from "@mui/icons-material";
import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CircularLoader from "components/CircularLoader";
import RedAsterisk from "components/RedAsterisk";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  authorizedFetch,
  getDarkModePreference,
  GlobalState,
  snackbar,
} from "utils";
import { RETAIL_URL } from "utils/constants";

function readFile(file: any) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

const ModelInfo = ({
  form,
  setForm,
  isRental,
  isEditMode,
  controllersLoading,
  controllersData,
}: any) => {
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );

  // States
  const [nameExists, setNameExists] = useState(false);

  // Queries
  const protocolsUrl = `${RETAIL_URL}/inventory/protocol`;
  const { isLoading: protocolsLoading, data: protocols } = useQuery(
    ["getProtocols"],
    () => authorizedFetch(protocolsUrl)
  );

  const [loading, setLoading] = useState(false);
  const { image, imageKey } = form;

  const modelsURL = `${RETAIL_URL}/assembly/model?ascending=true`;
  const {
    // isLoading: modelsLoading,
    data: modelsData,
    // refetch: refetchModels,
  } = useQuery(["getModels"], () => authorizedFetch(modelsURL));

  // Effects
  useEffect(() => {
    if (isRental) {
      setForm((prev: any) => ({ ...prev, protocol: "PNP" }));
    }
    // eslint-disable-next-line
  }, [isRental]);

  // Handlers
  function handleModelSearch(search: any) {
    const check = modelsData?.data?.find(
      (item: any) => item?.name.toLowerCase() === search.toLowerCase()
    );
    if (check === undefined) {
      setNameExists(false);
    } else {
      setNameExists(true);
    }
  }

  async function handleImageInput(
    e: React.ChangeEvent<HTMLInputElement>,
    shouldDelete = false
  ) {
    const uploadImageUrl = `${RETAIL_URL}/file/upload?type=model`;
    const deleteImageUrl = `${RETAIL_URL}/file/delete?type=model`;

    function uploadImage(body: FormData) {
      authorizedFetch(uploadImageUrl, {
        method: "POST",
        onlyBody: body,
      }).then((res) => {
        setLoading(false);
        // if (res.meta.success) {
        if (res.msg === "File uploaded successfully") {
          setForm((prev: any) => ({ ...prev, imageKey: res.data[0].key }));
        } else {
          setForm((prev: any) => ({ ...prev, image: "", imageKey: "" }));
          console.error(res);
          snackbar.error("An error occurred");
        }
      });
    }

    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      setForm((prev: any) => ({ ...prev, image: imageDataUrl }));

      let formData = new FormData();
      formData.append("image", file, file.name);

      setLoading(true);
      if (shouldDelete) {
        authorizedFetch(deleteImageUrl, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            file: imageKey,
          },
        }).then((res) => {
          // if (res.meta.success) {
          if (res.msg === "File deleted successfully") {
            uploadImage(formData);
          } else {
            setLoading(false);
            snackbar.error("An error occurred");
            console.error(res);
          }
        });
      } else {
        uploadImage(formData);
      }
    }
  }

  return (
    <>
      <Typography sx={{ mb: 2 }} variant="body2" color="text.secondary">
        All fields marked with ( <RedAsterisk /> ) are required
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
          gap: 4,
          "& .required": {
            color: "red",
          },
        }}
      >
        <Box>
          <Typography className="label">
            Model Name<span className="required">&#x2a;</span>
          </Typography>
          <TextField
            fullWidth
            size="small"
            placeholder="Model Name"
            autoComplete="off"
            value={form.name}
            onChange={(e) => {
              setForm((prev: any) => ({ ...prev, name: e.target.value }));
              handleModelSearch(e.target.value);
            }}
            error={form?.name?.length > 20 || nameExists}
            // inputProps={{ maxLength: 20 }}
            helperText={
              form?.name?.length > 20
                ? "Model name cannot exceed 20 characters"
                : undefined || nameExists
                ? "Model name already exists"
                : ""
            }
            disabled={isEditMode}
          />
        </Box>
        {/* <Box>
          <Typography className="label">Model ID </Typography>
          <TextField
            fullWidth
            size="small"
            placeholder="Model ID"
            value={form.id}
            onChange={(e) => setForm((prev: any) => ({ ...prev, id: e.target.value }))}
          />
        </Box> */}
        {!isRental && (
          <Box>
            <Typography className="label">
              Protocol<span className="required">&#x2a;</span>
            </Typography>
            <Select
              size="small"
              value={form.protocol}
              onChange={(e) =>
                setForm((prev: any) => ({ ...prev, protocol: e.target.value }))
              }
              fullWidth
              placeholder="Select"
              displayEmpty
              renderValue={
                form.protocol !== "" ? undefined : () => <em>Select</em>
              }
              disabled={isEditMode}
            >
              {protocolsLoading ? (
                <MenuItem disabled value="">
                  Loading...
                </MenuItem>
              ) : (
                (protocols?.data?.constructor === Array
                  ? protocols.data
                  : []
                ).map((el: any, i: number) => (
                  <MenuItem key={i} value={el}>
                    {el}
                  </MenuItem>
                ))
              )}
            </Select>
          </Box>
        )}

        <Box>
          <Typography className="label">Cluster Type</Typography>
          <Select
            size="small"
            value={form.controller}
            onChange={(e) =>
              setForm((prev: any) => ({ ...prev, controller: e.target.value }))
            }
            fullWidth
            placeholder="Select"
            displayEmpty
            renderValue={
              form.controller !== "" ? undefined : () => <em>Select</em>
            }
            disabled={!isRental && !form.protocol}
          >
            {controllersLoading ? (
              <MenuItem disabled value="">
                Loading...
              </MenuItem>
            ) : (
              (controllersData?.data?.[0] || []).map((el: any, i: number) => (
                <MenuItem key={i} value={el.key}>
                  {el.key}
                </MenuItem>
              ))
            )}
          </Select>
        </Box>

        <Box>
          <Typography className="label">
            Vehicle Type<span className="required">&#x2a;</span>
          </Typography>
          <Select
            size="small"
            value={form.type}
            onChange={(e) =>
              setForm((prev: any) => ({ ...prev, type: e.target.value }))
            }
            fullWidth
            placeholder="Select"
            displayEmpty
            renderValue={form.type !== "" ? undefined : () => <em>Select</em>}
          >
            <MenuItem value="TWO">Two Wheeler</MenuItem>
            <MenuItem value="THREE">Three Wheeler</MenuItem>
            {/* <MenuItem value="FOUR">Four Wheeler</MenuItem> */}
          </Select>
        </Box>
        <Box sx={{ gridColumn: "1/-1" }}>
          <Typography className="label">
            Add Image<span className="required">&#x2a;</span>
          </Typography>
          <Box
            sx={{
              position: "relative",
              mb: 3,
              width: 1,
              height: 120,
              p: 2,
              borderRadius: 2,
              border: (theme) =>
                `2px ${image === "" ? "dashed" : "solid"} ${
                  theme.customColors.border
                }`,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            {image === "" ? (
              <>
                <AddPhotoAlternateOutlined
                  fontSize="inherit"
                  sx={{ my: 1, fontSize: 28 }}
                />
                <Box display="flex" alignItems="center">
                  <input
                    id="model-image-input"
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={handleImageInput}
                  />
                  <label htmlFor="model-image-input">
                    <Button
                      component="span"
                      sx={{
                        textTransform: "none",
                        fontWeight: 600,
                      }}
                    >
                      Upload an image
                    </Button>
                  </label>
                  {/* <Typography variant="body2" color="text.secondary">
                or drag and drop
              </Typography> */}
                </Box>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    position: "absolute",
                    height: 1,
                    width: 1,
                    "& img": {
                      position: "absolute",
                      width: 1,
                      height: 1,
                      top: 0,
                      left: 0,
                      objectFit: "cover",
                    },
                  }}
                >
                  <img src={image} alt="Model Thumbnail" />
                  <Box
                    sx={{
                      position: "absolute",
                      width: 1,
                      height: 1,
                      top: 0,
                      left: 0,
                      bgcolor: isDarkMode ? "#000" : "#fff",
                      opacity: 0.6,
                    }}
                  />
                </Box>
                {loading ? (
                  <CircularLoader />
                ) : (
                  <>
                    <input
                      id="model-image-input-change"
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={(e) => handleImageInput(e, true)}
                    />
                    <label htmlFor="model-image-input-change">
                      <Button
                        component="span"
                        variant="outlined"
                        size="small"
                        startIcon={<Autorenew />}
                      >
                        Change
                      </Button>
                    </label>
                  </>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ModelInfo;
