import {
  login as loginAction,
  loginFail,
  loginSuccess,
  signUpSuccess,
  signUpFail,
} from "actions";
import { call, all, put, takeEvery } from "redux-saga/effects";
import { getSectionPermissions, setLoader, snackbar } from "./utils";
import {
  firebaseLoginRequest,
  getTokenId,
  resetPasswordRequest,
  revosLogin,
  revosUserSignUp,
  firebaseSignUpRequest,
  onTokenFail,
} from "./utils/request";

function getPayload(data, firebaseResponse) {
  let { company = {} } = data.user;
  // TODO: Remove once data migration for auth API is complete

  if (!company?.permissions || company.permissions?.length === 0) {
    company.permissions = ["dashboard:*"];
  }

  const { permissions } = data.user;
  const canAccessCMS = getSectionPermissions("charger", permissions);
  const canAccessFMS = getSectionPermissions("rental", permissions);
  const canAccessVMS = getSectionPermissions("retail", permissions);
  const canAccessSCM = getSectionPermissions("housekeeping", permissions);

  if (!canAccessCMS && !canAccessFMS && !canAccessVMS && !canAccessSCM) {
    onTokenFail("User doesn't have the appropriate permissions.");
    return null;
  }

  // console.log(permissions);
  console.log("canAccessCMS ==> ", canAccessCMS, permissions);
  console.log("canAccessFMS ==> ", canAccessFMS);
  console.log("canAccessVMS ==> ", canAccessVMS);
  console.log("canAccessSCM ==> ", canAccessSCM);

  return {
    firebase: { ...firebaseResponse },
    token: data.token,
    user: data.user,
    company,
    activeSection: canAccessCMS
      ? "charger"
      : canAccessFMS
      ? "rental"
      : canAccessVMS
      ? "retail"
      : "housekeeping",
  };
}

function* login(action) {
  const response = yield call(firebaseLoginRequest, action.payload);
  if (response.user) {
    const firebaseToken = yield call(getTokenId, true);

    let { shouldSignUp, signUpForm, code } = action.payload;

    const loginResponse = yield call(
      revosLogin,
      firebaseToken,
      shouldSignUp ? code : null
    );

    console.log("revoslogin ==> ", loginResponse);

    if (loginResponse.status < 400 || shouldSignUp) {
      if (shouldSignUp) {
        const signUpResponse = yield call(revosUserSignUp, {
          ...signUpForm,
          uid: response.user.uid,
        });

        if (signUpResponse.status < 400) {
          let payload = getPayload(signUpResponse.data, response);
          if (payload !== null) {
            yield put(loginSuccess(payload));
            snackbar.success("Account created");
          }
        } else {
          // snackbar.error("Error signing in");
          yield put(loginFail(response));
          yield put(signUpFail({ signUpResponse: { ...signUpResponse } }));
        }
      } else {
        let payload = getPayload(loginResponse.data, response);
        console.log(loginResponse.data);
        if (payload !== null) {
          yield put(loginSuccess(payload));
          snackbar.success("You have successfully logged in!");
        }
      }
    } else {
      snackbar.error("Error signing in");
      yield put(loginFail(response));
    }
  } else yield put(loginFail(response));
}

function* signUp(action) {
  let { signUpForm, step } = action.payload;
  if (step === 0) {
    let { email, password } = signUpForm;
    let response = yield call(firebaseSignUpRequest, email, password);
    if (response?.code && response.code !== "auth/email-already-in-use") {
      snackbar.error("Error signing up");
      yield put(signUpFail({ signUpResponse: { ...response } }));
    } else {
      yield put(
        signUpSuccess({
          signUpResponse: {
            step: 1,
          },
        })
      );
    }
  } else if (step === 1) {
    let { firstName, lastName, email, phone, address, password, code } =
      signUpForm;
    const payload = {
      email,
      password,
      shouldSignUp: true,
      signUpForm: {
        firstName,
        lastName,
        address,
        email,
        phone,
      },
      code,
    };
    yield put(loginAction(payload));
  }
}

function* resetPassword(action) {
  let response = {};
  response = yield call(resetPasswordRequest, action.payload);
  setLoader(false);
  if (response.code) {
    snackbar.error(`${response.message}`);
  } else {
    snackbar.success("Password reset link sent to email");
  }
}

function* loginSaga() {
  yield takeEvery("LOGIN", login);
}

function* signUpSaga() {
  yield takeEvery("SIGNUP", signUp);
}

function* resetPasswordSaga() {
  yield takeEvery("RESET_PASSWORD", resetPassword);
}

export default function* rootSaga() {
  yield all([loginSaga(), signUpSaga(), resetPasswordSaga()]);
}
