import { Close } from "@mui/icons-material";
import { alpha, Box, Button, Divider, Drawer, Typography } from "@mui/material";
import RangeSelector from "components/RangeSelector";
import moment from "moment";
import { useState } from "react";

const FiltersDrawer = ({
  open,
  onClose,
  // filters,
  setFilters,
}: any) => {
  const [tab, setTab] = useState(0);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      PaperProps={{
        sx: {
          height: 1,
          width: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 2,
          pb: 0.5,
        }}
      >
        <Typography variant="h3">Filters</Typography>
        <Button
          sx={{ textTransform: "none" }}
          color="inherit"
          size="small"
          startIcon={<Close />}
          onClick={onClose}
        >
          Close
        </Button>
      </Box>
      <Divider />
      <Box
        sx={{
          flexGrow: 1,
          display: "grid",
          gridTemplateColumns: "120px 1fr",
          fontSize: 14,
        }}
      >
        <Box
          sx={{
            background: alpha("#ECECEC", 0.5),
            borderRight: 1,
            borderColor: (theme) => theme.customColors.border,
            "& > .MuiBox-root": {
              py: 3,
              px: 1.5,
              borderLeft: 4,
              borderColor: "transparent",
              cursor: "pointer",
              "&.active": {
                background: "white",
                borderColor: "#62D16A",
              },
            },
          }}
        >
          {["Time"].map((el, i) => (
            <Box
              key={i}
              className={tab === i ? "active" : ""}
              onClick={() => setTab(i)}
            >
              {el}
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {tab === 0 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Select range
              <RangeSelector
                setRange={(range: any) => {
                  setFilters((prev: any) => ({
                    ...prev,
                    time: range,
                  }));
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          py: 3,
          px: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow: "0px -3px 12px rgba(0, 0, 0, 0.05)",
        }}
      >
        <Button
          sx={{
            textTransform: "none",
            borderRadius: 3,
          }}
          color="inherit"
          onClick={() =>
            setFilters({
              access: [],
              health: [],
              locations: [],
              time: [moment().subtract({ months: 1 }), moment()],
            })
          }
        >
          Clear all
        </Button>
        <Button
          sx={{
            textTransform: "none",
            borderRadius: 3,
            px: 3.5,
          }}
          variant="contained"
          onClick={onClose}
        >
          Apply Filters
        </Button>
      </Box>
    </Drawer>
  );
};

export default FiltersDrawer;
