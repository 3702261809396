import {
  FullscreenOutlined,
  // EditOutlined,
  HighlightOff,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Update,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  // Typography,
  CircularProgress,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  authorizedFetch,
  drawer,
  getDarkModePreference,
  // getPermissions,
  GlobalState,
} from "utils";
// import { gql } from "@apollo/client";
import TableComponent from "components/Table";
import moment from "moment";
import { useQuery } from "react-query";
import { KYC_URL, USERTRACK_URL } from "utils/constants";
import ImageDialog from "./ImageDialog";
import UpdateKYC from "./UpdateKYC";

// const GET_KYC_DOCS = gql`
//   query KYCDocuments($companyId: ID, $userId: ID) {
//     company {
//       get(where: { id: $companyId }) {
//         rentalUsers(where: { id: $userId }) {
//           kyc {
//             associations {
//               kyc {
//                 documents {
//                   id
//                   file {
//                     buffer
//                   }
//                   type
//                   status
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `;

const DrawerContent = ({ user }: any) => {
  const [tab, setTab] = useState(0);
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );
  // const { company } = useSelector((state: GlobalState) => state.global);
  // const [docs, setDocs] = useState([]);
  // const [viewDialog, setViewDialog] = useState({ open: false, doc: null });
  // const { canWrite } = getPermissions("charger:chargers");

  // let { firstName, lastName } = user;
  // let name =
  //   firstName || lastName ? `${firstName || ""} ${lastName || ""}` : "-";

  const logsUrl = `${USERTRACK_URL}/getlogs?size=100&productId=${user?.chargerId}&service=${KYC_URL}`;
  const { isLoading: logsLoading, data: logsData } = useQuery(
    ["getLogs", user?.chargerId],
    () => authorizedFetch(logsUrl)
  );

  let table = [
    { header: "User Details" },
    { label: "Charger UID", value: user?.chargerId },
    { label: "Name", value: user?.userName },
    { label: "Email", value: user?.userEmail },
    { label: "Phone", value: user?.userPhone || "-" },
  ];
  // let selectImage = [{ header: "Selected Image" }];

  // const [getDocs, { loading, data }] = useLazyQuery(GET_KYC_DOCS, {
  //   variables: {
  //     userId: user?.id,
  //     companyId: company.id,
  //   },
  // });

  // useEffect(() => {
  //   getDocs();
  // }, [user, getDocs]);

  // useEffect(() => {
  //   if (data) {
  //     let docs =
  //       data.company?.get?.rentalUsers[0]?.kyc?.associations[0]?.kyc
  //         ?.documents || [];
  //     setDocs(docs);
  //   }
  // }, [data]);

  const url = `${KYC_URL}/product/${user?.chargerId}`;

  const { isLoading: isKycLoading, data: kycData } = useQuery(
    ["getKyc", user],
    () =>
      authorizedFetch(url, {
        headers: {
          token: "39efcnegzzzegc",
        },
      })
  );

  const [images, setImages] = useState<any>([]);

  useEffect(() => {
    if (kycData) {
      let imageArr = kycData?.data?.documents?.map((el: any, i: number) => ({
        url: el.url,
        id: el._id,
      }));
      setImages(imageArr);
    }
  }, [kycData]);

  const [activeImage, setActiveImage] = React.useState(0);

  const [updateDialog, setUpdateDialog] = useState({ open: false, user: "" });
  const [imageDialog, setImageDialog] = useState({
    open: false,
    images: [],
  });

  return (
    <>
      {/* <ViewDialog
        open={viewDialog.open}
        handleClose={() => setViewDialog((prev) => ({ ...prev, open: false }))}
        doc={viewDialog.doc}
      /> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            px: 3,
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: isDarkMode ? "#000" : "#3D3D3D",
            fontWeight: 500,
            color: "#fff",
          }}
        >
          <Box display="flex" alignItems="center">
            {user.chargerId}
            <Avatar
              variant="status"
              className={
                user.status === "REJECTED"
                  ? "red"
                  : user.status === "APPROVED"
                  ? ""
                  : "yellow"
              }
              sx={{
                ml: 2,
              }}
            >
              {user.status}
            </Avatar>
          </Box>
          <Box>
            {/* <Button
              variant="contained"
              size="small"
              onClick={() =>
                setUpdateDialog({
                  open: true,
                  user: user,
                })
              }
              sx={{ mr: 1 }}
            >
              Update Status
            </Button> */}
            <Tooltip title="Update KYC">
              <IconButton
                sx={{ mr: 1 }}
                children={<Update />}
                color="inherit"
                size="small"
                onClick={() => {
                  setUpdateDialog({
                    open: true,
                    user,
                  });
                }}
              />
            </Tooltip>
            <IconButton
              children={<HighlightOff />}
              color="inherit"
              size="small"
              onClick={() => drawer.close()}
            />
          </Box>
        </Box>
        <Box p={2} pb={0}>
          <Tabs
            className="dense"
            value={tab}
            onChange={(e: any, tab: any) => {
              console.log(tab, "charger-drawer");
              setTab(tab);
            }}
          >
            <Tab label="Info" />
            <Tab label="Logs" />
          </Tabs>
        </Box>
        <Box flexGrow={1} overflow="auto">
          {tab === 0 && (
            <Box
              sx={{
                px: 3,
                py: 2.5,
                "& .table": {
                  borderCollapse: "collapse",
                  width: 1,
                  fontSize: 14,
                  lineHeight: "16px",
                  "& td": {
                    py: 1.25,
                    px: 2,
                  },
                  "& .bold": {
                    fontWeight: 500,
                  },
                  "& .header": {
                    px: 2,
                    position: "relative",
                    "& td": {
                      py: 2,
                      position: "absolute",
                      verticalAlign: "middle",
                      backgroundColor: (theme) => theme.customColors.header,
                      width: 1,
                      borderRadius: "4px",
                      fontSize: 16,
                      fontWeight: 600,
                      "& span": {
                        display: "inline-block",
                        transform: "translateY(1px)",
                      },
                    },
                  },
                  "& .first > td": {
                    pt: 9,
                  },
                  "& .last > td": {
                    pb: 3,
                  },
                },
              }}
            >
              <table className="table">
                <tbody>
                  {table.map(({ header, label, value }, i) => {
                    const isFirst = table[i - 1]?.header;
                    const isLast = !table[i + 1] || table[i + 1].header;

                    return (
                      <tr
                        key={i}
                        className={
                          header
                            ? "header"
                            : `${isFirst ? "first" : ""} ${
                                isLast ? "last" : ""
                              }`
                        }
                      >
                        {header ? (
                          <td colSpan={2}>
                            <span>{header}</span>
                          </td>
                        ) : (
                          <>
                            {label && <td className="bold">{label}</td>}
                            {value && <td>{value}</td>}
                          </>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <Box
                sx={{
                  mb: 3,
                  px: 2,
                  py: 1.5,
                  width: 1,
                  backgroundColor: (theme) => theme.customColors.header,
                  borderRadius: "4px",
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                KYC Documents
              </Box>
              {isKycLoading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="200px"
                >
                  <CircularProgress size={24} />
                </Box>
              ) : (
                <Box
                  width="100%"
                  height="350px"
                  display="flex"
                  alignItems="center"
                  sx={{ position: "relative" }}
                  mt={3}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: "auto",
                      right: 0,
                      zIndex: 2,
                    }}
                  >
                    <IconButton
                      sx={{
                        background: "rgba(255, 255, 255, 0.3)",
                        "&:hover": {
                          background: "rgba(255, 255, 255, 0.4)",
                        },
                      }}
                      onClick={() => {
                        setImageDialog({
                          open: true,
                          images: images,
                        });
                      }}
                    >
                      <FullscreenOutlined
                        sx={{
                          width: 25,
                          borderRadius: 1,
                        }}
                      />
                    </IconButton>
                  </Box>
                  <Box
                    width="100%"
                    height="100%"
                    sx={{
                      position: "absolute",
                      top: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      zIndex: 1,
                    }}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        p: 0,
                        minWidth: 40,
                        width: 40,
                        height: 40,
                        borderRadius: 50,
                      }}
                      onClick={() => setActiveImage((prev) => prev - 1)}
                      disabled={activeImage === 0}
                    >
                      <KeyboardArrowLeft />
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{
                        p: 0,
                        minWidth: 40,
                        width: 40,
                        height: 40,
                        borderRadius: 50,
                      }}
                      onClick={() => setActiveImage((prev) => prev + 1)}
                      disabled={activeImage === images.length - 1}
                    >
                      <KeyboardArrowRight />
                    </Button>
                  </Box>
                  {images.map((image: any, i: number) => (
                    <Box
                      key={i}
                      component="img"
                      sx={{
                        height: 350,
                        maxWidth: 1,
                        maxHeight: 1,
                        width: 1,
                        display: activeImage === i ? "block" : "none",
                        objectFit: "contain",
                        overflow: "hidden",
                      }}
                      src={image.url}
                      alt={"KYC Document"}
                    />
                  ))}
                </Box>
              )}

              {/* <table className="table" style={{ marginTop: 30 }}>
                <tbody>
                  {selectImage.map(({ header, label, value }: any, i) => {
                    const isFirst = table[i - 1]?.header;
                    const isLast = !table[i + 1] || table[i + 1].header;

                    return (
                      <tr
                        key={i}
                        className={
                          header
                            ? "header"
                            : `${isFirst ? "first" : ""} ${
                                isLast ? "last" : ""
                              }`
                        }
                      >
                        {header ? (
                          <>
                            <td colSpan={2}>
                              <span>
                                {header}
                                {canWrite && (
                                  <IconButton
                                    sx={{ ml: 1.5, p: 0 }}
                                    children={<EditOutlined />}
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                      setUpdateDialog({
                                        open: true,
                                        user: "",
                                      });
                                    }}
                                  />
                                )}
                              </span>
                            </td>
                          </>
                        ) : (
                          <>
                            {label && <td className="bold">{label}</td>}
                            {value && <td>{value}</td>}
                          </>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table> */}

              {/* {selectImage.some((obj: any) =>
                Object.keys(obj).includes("label")
              ) ? (
                ""
              ) : (
                <Box mt={9} ml={2} mb={2}>
                  <Typography fontSize={15}>No Images Selected Yet</Typography>
                </Box>
              )} */}

              {/* <Box px={2} display="grid" gap={2.25}>
              {isLoading ? (
                <CircularProgress />
              ) : docs.length > 0 ? (
                docs.map((doc: any, i) => (
                  <Box key={i} display="grid" gridTemplateColumns="1fr auto">
                    <Box
                      sx={{
                        p: 1.75,
                        bgcolor: "background.default",
                        borderRadius: 1,
                        display: "flex",
                        alignItems: "start",
                      }}
                    >
                      <Avatar
                        sx={{
                          width: 60,
                          height: 60,
                          mr: 2,
                          bgcolor: (theme) => theme.customColors.grey,
                          cursor: "zoom-in",
                        }}
                        onClick={() => setViewDialog({ open: true, doc })}
                        variant="rounded"
                      >
                        {doc?.file?.buffer ? (
                          <img
                            src={doc?.file.buffer}
                            width={60}
                            alt="KYC Document"
                          />
                        ) : (
                          <InsertDriveFileOutlined />
                        )}
                      </Avatar>
                      <Box>
                        <Typography
                          mb={1}
                          fontSize={16}
                          fontWeight={500}
                          color={isDarkMode ? "#fff" : "#000"}
                          lineHeight="18px"
                        >
                          {doc.type}
                        </Typography>
                        <Typography
                          mb={1.5}
                          fontSize={14}
                          lineHeight="16px"
                          color="text.secondary"
                        >
                          {doc.description}
                        </Typography>
                        <Box
                          display="flex"
                          alignItems="center"
                          color={
                            doc.status === "APPROVED"
                              ? "success.main"
                              : "error.main"
                          }
                        >
                          {doc.status === "APPROVED" ? (
                            <CheckCircleOutline sx={{ mr: 1, fontSize: 18 }} />
                          ) : (
                            <ErrorOutline sx={{ mr: 1, fontSize: 18 }} />
                          )}
                          <Typography
                            fontSize={12}
                            lineHeight="14px"
                            fontWeight={600}
                            color="inherit"
                          >
                            {doc.status}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box width={100}>
                      <Box
                        pl={3}
                        width={1}
                        height={1}
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-evenly"
                      >
                        <Button
                          sx={{ width: "fit-content", textTransform: "none" }}
                          color="success"
                        >
                          Approve
                        </Button>
                        <Button
                          sx={{ width: "fit-content", textTransform: "none" }}
                          color="error"
                        >
                          Reject
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                ))
              ) : (
                <Typography color="textSecondary">No documents</Typography>
              )}
            </Box> */}
            </Box>
          )}
          {tab === 1 && (
            <Box pt={2}>
              <TableComponent
                rows={logsData?.data || []}
                loading={logsLoading}
                columns={[
                  {
                    key: "action",
                    label: "Action",
                  },
                  {
                    key: "userDetail.userName",
                    label: "By",
                  },
                  {
                    key: "timestamp",
                    label: "Date",
                    format: (value) =>
                      moment(value).format("MMM DD, YYYY, HH:mm"),
                  },
                  {
                    key: "updatedData.create.record.remark",
                    label: "Remark",
                  },
                  {
                    key: "updatedData.create.record.internalRemark",
                    label: "Internal Remark",
                  },
                ]}
              />
            </Box>
          )}
        </Box>
      </Box>
      <ImageDialog
        open={imageDialog.open}
        handleClose={() => {
          setImageDialog({ ...imageDialog, open: false });
        }}
        images={imageDialog.images}
        activeImage={activeImage}
        user={user}
      />
      <UpdateKYC
        open={updateDialog.open}
        handleClose={() => {
          setUpdateDialog({ ...updateDialog, open: false });
        }}
        user={updateDialog.user}
      />
    </>
  );
};

export default DrawerContent;
