import { InfoOutlined } from "@mui/icons-material";
import { Box, IconButton, Paper, Tab, Tabs, Tooltip } from "@mui/material";
import Table from "components/Table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { GlobalState, authorizedFetch, drawer, getDuration } from "utils";
import { LEASE_URL } from "utils/constants";
import Search from "../../../components/Search";
import DrawerContent from "./DrawerContent";
import { useSelector } from "react-redux";

const List = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const first = pageSize;
  const skip = pageSize * (page - 1);
  const tripsURL = `${LEASE_URL}/company/trips?first=${first}&search=${search}&skip=${skip}`;

  const { isLoading: tripsLoading, data: tripsData } = useQuery(
    ["getTrips", first, skip, search],
    () => authorizedFetch(tripsURL),
  );

  useEffect(() => {
    if (search) {
      setPage(1);
      setPageSize(10);
    }
  }, [search]);

  useEffect(() => {
    return () => {
      drawer.close();
    };
  }, []);

  let userEmail = useSelector((state: GlobalState) => state.global.user.email);
  const shouldHideFields = userEmail === "srikhantan.s@bounceshare.com";

  return (
    <>
      <Paper
        sx={{
          width: 1,
          overflow: "hidden",
          borderRadius: "14px",
          boxShadow: (theme) => theme.customShadows.card,
        }}
      >
        <Box
          sx={{
            p: { xs: 2, md: 3 },
            pb: 2.75,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box width="fit-content">
            <Tabs className="dense" value={0}>
              <Tab
                label="All"
                className="hasCount"
                sx={{
                  "&:after": {
                    content: `"${tripsData?.data?.count || 0}"`,
                  },
                }}
              />
            </Tabs>
          </Box>
          <Search
            handleSearch={(value) => {
              setSearch(value);
            }}
            persist
            enableClear
          />
        </Box>
        <Table
          rowCount={tripsData?.data?.count}
          serverSidePagination={true}
          activePage={page}
          activePageSize={pageSize}
          onPageChange={(value) => setPage(value)}
          onPageSizeChange={(value) => setPageSize(value)}
          loading={tripsLoading}
          rows={tripsData?.data?.trips || []}
          columns={[
            {
              key: "vin",
              label: "VIN",
              format: (value) =>
                `${value.length > 17 ? value.slice(0, 17) : value}`,
            },
            {
              key: "startTime",
              label: "Start Time",
              format: (value) => moment(value).format("DD/MM/YY, hh:mm a"),
            },
            {
              key: "duration",
              label: "Duration",
              valueGetter: (row) => {
                let duration = moment.duration(
                  moment(row.endTime).diff(moment(row.startTime)),
                );

                return duration.asMinutes();
              },
              format: (value) => getDuration(value),
            },
            ...(shouldHideFields
              ? []
              : [
                  {
                    key: "maxGpsSpeed",
                    label: "Max Speed",
                    valueGetter: (row: any) => {
                      return row.maxVehicleSpeed === 0
                        ? `${
                            typeof row.maxGpsSpeed === "number"
                              ? row.maxGpsSpeed.toFixed(0)
                              : 0
                          } km/h`
                        : `${
                            typeof row.maxVehicleSpeed === "number"
                              ? row.maxVehicleSpeed.toFixed(0)
                              : 0
                          } km/h`;
                    },
                  },
                ]),
            ...(shouldHideFields
              ? []
              : [
                  {
                    key: "avgGpsSpeed",
                    label: "Avg Speed",
                    valueGetter: (row: any) => {
                      return row.avgVehicleSpeed === 0
                        ? `${
                            typeof row.avgGpsSpeed === "number"
                              ? row.avgGpsSpeed.toFixed(2)
                              : 0
                          } km/h`
                        : `${
                            typeof row.avgVehicleSpeed === "number"
                              ? row.avgVehicleSpeed.toFixed(2)
                              : 0
                          } km/h`;
                    },
                  },
                ]),
            {
              key: "distance",
              label: "Distance",
              format: (value) =>
                `${
                  typeof value === "number" ? (value / 1000).toFixed(2) : 0
                } km`,
            },
            {
              key: "driverScore",
              label: "Driver Score",
              format: (value) => value || "N/A",
            },
            {
              key: "actions",
              label: "Actions",
              Render: (row) => (
                <Tooltip title="Info" followCursor>
                  <IconButton
                    size="small"
                    sx={{
                      color: (theme) => theme.customColors.action,
                    }}
                    children={<InfoOutlined fontSize="small" />}
                    onClick={() =>
                      drawer.open(<DrawerContent key={row?._id} trip={row} />)
                    }
                  />
                </Tooltip>
              ),
            },
          ]}
        />
      </Paper>
    </>
  );
};

export default List;
