import { Avatar, Box, Button, IconButton } from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import { GlobalState, drawer, getDarkModePreference, titleCase } from "utils";
import { ReactComponent as CloseIcon } from "assets/images/icons/close.svg";
import { useState } from "react";
import BulkAssignDialog from "./BulkAssignDialog";
import BulkRecallDialog from "./BulkRecallDialog";

const BulkInfoDrawer = ({ bulk, totalItems }: any) => {
    const isDarkMode = useSelector((state: GlobalState) =>
        getDarkModePreference(state)
    );

    const [bulkAssignDialog, setBulkAssignDialog] = useState(false);
    const [bulkRecallDialog, setBulkRecallDialog] = useState(false);

    const table = [
        { header: "File Information" },
        {
            label: "Uploaded File Name",
            value: bulk?.fileKey,
        },
        {
            value: bulk?.status,
            label: "File Status",
        },
        {
            label: "Total Items",
            value: totalItems,
        },
        {
            label: "Success Count",
            value: bulk?.passedEntries,
        },
        {
            label: "Failed Count",
            value: bulk?.failedEntries,
        },
        {
            label: "Status",
            value: bulk?.fileKeyStatus,
        },
        {
            label: "Company Name",
            value: bulk?.oem?.name,
        },
        {
            label: "Vehicle Model",
            value: bulk?.vehicleModel?.name || "-",
        },
        {
            label: "Speedometer",
            value: bulk?.speedometer || "-",
        },
        {
            label: "Hardware Version",
            value: bulk?.hardwareVersion || "-",
        },
        {
            label: "Primary Firmware Version",
            value: bulk?.currentOta?.primary?.version || "-",
        },
        {
            label: "Secondary Firmware Version",
            value: bulk?.currentOta?.secondary?.version || "-",
        },
        {
            label: "Warranty Details",
            value: bulk?.warranty?.duration === undefined
                ? "-"
                : bulk?.warranty?.duration + " days",
        },
        {
            label: "CREATED ON",
            value: moment(bulk.createdAt).format("DD-MM-YYYY"),
        },
    ];

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: 1,
                    overflow: "hidden",
                }}
            >
                <Box
                    sx={{
                        px: 3,
                        py: 2,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: isDarkMode ? "#000" : "#3D3D3D",
                        fontWeight: 500,
                        color: "#fff",
                    }}
                >
                    {bulk.fileKey}
                    <IconButton
                        children={<CloseIcon />}
                        color="inherit"
                        size="small"
                        onClick={() => drawer.close()}
                    />
                </Box>
                <Box flexGrow={1} overflow="auto" pb={4}>
                    <Box
                        sx={{
                            px: 3,
                            pt: 2.5,
                            "& .table": {
                                borderCollapse: "collapse",
                                width: 1,
                                fontSize: 14,
                                lineHeight: "16px",
                                "& td": {
                                    py: 1.5,
                                    px: 2,
                                    "&.secondary": {
                                        color: "text.secondary",
                                    },
                                },
                                "& .bold": {
                                    fontWeight: 500,
                                },
                                "& .header": {
                                    position: "relative",
                                    "& td": {
                                        py: 2,
                                        position: "absolute",
                                        verticalAlign: "middle",
                                        backgroundColor: (theme: any) => theme.customColors.header,
                                        width: 1,
                                        borderRadius: "4px",
                                        fontSize: 16,
                                        fontWeight: 600,
                                        "& span": {
                                            display: "inline-block",
                                            transform: "translateY(1px)",
                                        },
                                    },
                                },
                                "& .first > td": {
                                    pt: 8.5,
                                },
                                "& .last > td": {
                                    pb: 3,
                                },
                            },
                        }}
                    >
                        <Button
                            sx={{
                                display: "flex",
                                height: 41,
                                maxWidth: 250,
                                float: "right",
                                borderRadius: 10,
                                borderWidth: "1.5px !important",
                                borderColor: (theme) => theme.palette.primary.main,
                                textTransform: "none",
                                fontWeight: 500,
                                px: 4,
                                ml: "auto",
                                marginRight: 0.5,
                                marginLeft: "8px",
                                backgroundColor: bulk?.fileKeyStatus === "ASSIGNED" ? "#FA5F55" : "#26C72D",
                                transition: "background-color 0.3s ease",
                                "&:hover": {
                                    backgroundColor: bulk?.fileKeyStatus === "ASSIGNED" ? "#FF524D" : "#2DEA47",
                                },
                            }}
                            variant="contained"
                            onClick={() => {
                                bulk?.fileKeyStatus === "ASSIGNED" ? setBulkRecallDialog(true) : setBulkAssignDialog(true)
                            }}
                        >
                            {bulk?.fileKeyStatus === "ASSIGNED" ? "Recall" : "Assign"}
                        </Button>
                        <br />
                        <br />
                        <table className="table">
                            <tbody>
                                {table.map(({ header, label, value }, i) => {
                                    const isFirst = table[i - 1]?.header;
                                    const isLast = !table[i + 1] || table[i + 1].header;

                                    return (
                                        <tr
                                            key={i}
                                            className={
                                                header
                                                    ? "header"
                                                    : `${isFirst ? "first" : ""} ${isLast ? "last" : ""}`
                                            }
                                        >
                                            {header ? (
                                                <td colSpan={2}>
                                                    <span>{header.toUpperCase()}</span>
                                                </td>
                                            ) : (
                                                <>
                                                    <td className="bold">{label}</td>
                                                    <td
                                                        className={
                                                            value === "Loading..." ? "secondary" : ""
                                                        }
                                                    >
                                                        {label === 'File Status'
                                                            ? <div className="header">
                                                                <Avatar
                                                                    variant="status"
                                                                    className={
                                                                        bulk.status === "Failed"
                                                                            ? "red"
                                                                            : bulk.status === "Success"
                                                                                ? "green"
                                                                                : ""
                                                                    }
                                                                >
                                                                    {bulk.status
                                                                        ? bulk.status === "Success"
                                                                            ? "Success"
                                                                            : bulk.status === "Failed"
                                                                                ? "Failed"
                                                                                : titleCase(bulk.status.split("_").join(" "))
                                                                        : "N/A"
                                                                    }
                                                                </Avatar>
                                                            </div>
                                                            : label === "Status"
                                                                ? <div className="header">
                                                                    <Avatar
                                                                        variant="status"
                                                                        className={
                                                                            bulk.fileKeyStatus === "ASSIGNED"
                                                                                ? "green"
                                                                                : bulk.fileKeyStatus === "UNASSIGNED"
                                                                                    ? "grey"
                                                                                    : ""
                                                                        }
                                                                    >
                                                                        {bulk.fileKeyStatus
                                                                            ? bulk.fileKeyStatus === "ASSIGNED"
                                                                                ? "Assigned"
                                                                                : bulk.fileKeyStatus === "UNASSIGNED"
                                                                                    ? "Unassigned"
                                                                                    : titleCase(bulk.fileKeyStatus.split("_").join(" "))
                                                                            : "N/A"}
                                                                    </Avatar>
                                                                </div>
                                                                : value}
                                                    </td>
                                                </>
                                            )}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Box>
                </Box>
                <BulkAssignDialog
                    open={bulkAssignDialog}
                    handleClose={() => setBulkAssignDialog(false)}
                    bulk={bulk}
                    totalItems={totalItems}
                />
                <BulkRecallDialog
                    open={bulkRecallDialog}
                    handleClose={() => setBulkRecallDialog(false)}
                    bulk={bulk}
                    totalItems={totalItems}
                />
            </Box>
        </>
    );
};

export default BulkInfoDrawer;
