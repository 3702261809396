import { Box, Skeleton, Typography } from "@mui/material";
import { Fragment, useMemo } from "react";
import { Doughnut } from "react-chartjs-2";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { authorizedFetch } from "utils";
import { TRIPS_URL } from "utils/constants";

const colors: any = [
  "#366AB4",
  "#F4845D",
  "#FFAB01",
  "#6F7BF7",
  "#6EBDE7",
  "#26C72D",
];

let styles: any = {};

const CO2 = () => {
  const emmissionsUrl = `${TRIPS_URL}/emissionAndSavings/company`;
  const { data: emmissionsData, isLoading: emmissionsLoading } = useQuery(
    ["getEmissionsCompany"],
    () => authorizedFetch(emmissionsUrl)
  );

  const totalCO2 = emmissionsData?.data?.co2Emission;

  const { chartData } = useMemo(() => {
    let chartData: any[] = [];

    if (emmissionsData?.data?.vinsWithMostEmissions?.constructor === Array) {
      chartData = emmissionsData?.data?.vinsWithMostEmissions
        .sort((a: any, b: any) => b.co2Emission - a.co2Emission)
        .map((el: any, index: any) => ({
          x: el.co2Emission,
          y: el.vin ? el.vin : el.others,
          color: colors[index],
        }));
    }

    return { chartData };
  }, [emmissionsData]);

  chartData?.forEach((el: any) => {
    styles[`&.${el?.y?.replaceAll(" ", "_")}::before`] = {
      bgcolor: el?.color,
    };
  });

  const displayArray: any = chartData?.map((el: any) => {
    return { label: el.y, value: el.x.toFixed(2) + " Kg" };
  });

  const history = useHistory();

  return (
    <>
      <Box
        sx={{
          minWidth: { xs: 0, md: 360 },
          mb: { xs: 2, md: 7.5 },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "flex-start", md: "center" },
        }}
      >
        <Typography variant="h3" mr={2.5} mb={{ xs: 2.5, md: 0 }}>
          Over All CO<sub>2</sub> Emission Saved
        </Typography>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "140px auto",
            md: "minmax(0, 265px) auto",
          },
          gap: { xs: 3, md: 4 },
        }}
      >
        {emmissionsLoading ? (
          <Box
            sx={{
              width: 1,
              position: "relative",
              pt: "100%",
            }}
          >
            <Skeleton
              variant="circular"
              sx={{
                position: "absolute",
                top: 0,
                width: 1,
                height: 1,
              }}
            />
          </Box>
        ) : (
          <Box width={1} alignSelf="center" position="relative">
            <Doughnut
              style={{ position: "relative", zIndex: 2 }}
              data={(canvas) => {
                return {
                  datasets: [
                    {
                      data: chartData?.map((el: any) => el.x) || [],
                      backgroundColor:
                        chartData?.map((el: any) => el.color) || [],
                      hoverBackgroundColor:
                        chartData?.map((el: any) => el.color) || [],
                      borderWidth: 0,
                      cutout: "85%",
                      hoverOffset: 3,
                    },
                  ],
                  labels: chartData?.map((el: any) => el.y),
                };
              }}
              options={{
                onClick: (e: any, element: any) => {
                  let dataIndex = element[0].index;

                  history.push(
                    "/vehicles?vin=" + e?.chart?.data?.labels[dataIndex]
                  );
                },
                onHover: (event: any, chartElement) => {
                  const target = event.native
                    ? event.native.target
                    : event.target;
                  target.style.cursor = chartElement[0] ? "pointer" : "default";
                },
                layout: {
                  padding: 5,
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    displayColors: false,
                  },
                },
              }}
            />
            <Box
              sx={{
                zIndex: 1,
                position: "absolute",
                top: "35%",
                right: 0,
                left: 0,
                mx: "auto",
                pointerEvents: "none",
                textAlign: "center",
                color: "text.secondary",
              }}
            >
              <Typography fontSize={{ xs: 18, md: 28 }} fontWeight={700}>
                {totalCO2?.toFixed(2)}
                {totalCO2 ? " Kg" : ""}
              </Typography>
              <Typography fontSize={14}>Total</Typography>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            width: 1,
            minWidth: 0,
            maxWidth: 250,
            justifySelf: "end",
            height: "fit-content",
            ml: 2,
            display: "grid",
            gridTemplateColumns: "1fr auto",
            rowGap: 4,
            columnGap: 1,
            fontSize: { xs: 12, md: 14 },
            "& .label": {
              position: "relative",

              "&::before": {
                content: '""',
                position: "absolute",
                mt: 1,
                transform: "translateX(-15.96px)",
                borderRadius: 100,
                width: "6.39px",
                height: "6.39px",
              },
              ...styles,
            },
            "& .value": {
              // textAlign: "right",
              fontWeight: 700,
              fontSize: { xs: 10, md: 13 },
            },
          }}
        >
          {displayArray.map((el: any, i: any) => (
            <Fragment key={i}>
              {emmissionsLoading ? (
                <Skeleton width={150} />
              ) : (
                <span
                  className={`label ${el?.label?.replaceAll(" ", "_")}`}
                  onClick={() => {
                    return el.label !== "Others"
                      ? history.push("/vehicles?vin=" + el.label)
                      : "";
                  }}
                  style={{
                    cursor: el.label !== "Others" ? "pointer" : "default",
                  }}
                >
                  {el?.label}
                </span>
              )}
              {emmissionsLoading ? (
                <Skeleton width={20} />
              ) : (
                <span
                  className="value"
                  onClick={() => {
                    history.push("/vehicles?vin=" + el.label);
                  }}
                  style={{
                    cursor: el.label !== "Others" ? "pointer" : "default",
                  }}
                >
                  {el?.value}
                </span>
              )}
            </Fragment>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default CO2;
