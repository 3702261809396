import {
  Avatar,
  Box,
  CircularProgress,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import RangePicker from "components/RangePicker";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { authorizedFetch, getDarkModePreference, GlobalState } from "utils";
import { format, sub } from "date-fns";
import { useQuery } from "react-query";
import { LEASE_URL } from "utils/constants";
import moment from "moment";

const Earnings = () => {
  const [range, setRange] = useState<any>([
    sub(new Date(), { months: 1 }),
    new Date(),
  ]);
  const [chartData, setChartData] = useState<any>(null);

  let dateFrom = format(range[0], "yyyy-MM-dd");
  let dateTo = format(range[1], "yyy-MM-dd");

  function parseData(array: any[], key: string) {
    array = array.sort(
      (a: any, b: any) => moment(a.date).valueOf() - moment(b.date).valueOf()
    );

    let chartData = array.reduce((acc: any, cur: any) => {
      if (array.length < 50) {
        let day = moment(cur.date).format("MMM D");
        const existing = acc.find((el: any) => el.x === day);
        if (existing) {
          existing.y += cur[key];
        } else {
          acc.push({ x: day, y: parseFloat(cur[key]?.toFixed(2)) });
        }
      } else {
        let x = "";
        if (array.length >= 365) {
          let month = moment(cur.date).startOf("month").format("MMM yyyy");
          x = month;
        } else {
          let startDate = moment(cur.date).startOf("week").format("MMM D");
          let endDate = moment(cur.date).endOf("week").format("MMM D");
          x = `${startDate} - ${endDate}`;
        }

        let existing = acc.find((el: any) => el.x === x);
        if (existing) {
          existing.y += cur[key];
        } else {
          acc.push({ x, y: parseFloat(cur[key]?.toFixed(2)) });
        }
      }

      return acc;
    }, []);

    return chartData;
  }

  const earningsUrl = `${LEASE_URL}/company/bookings/earnings?timeframe=custom&starttime=${dateFrom}&endtime=${dateTo}`;

  const { isLoading: earningsLoading, data: earningsData } = useQuery(
    ["company/bookings/earnings", dateFrom, dateTo],
    () => authorizedFetch(earningsUrl)
  );

  useEffect(() => {
    if (earningsData?.data?.aggregatedEarnings?.constructor === Array) {
      let chartData = parseData(
        earningsData.data.aggregatedEarnings,
        "totalEarnings"
      );
      setChartData(chartData);
    }
  }, [earningsData]);

  return (
    <Paper
      sx={{
        height: 400,
        p: { xs: 2, md: 3 },
        gridColumn: { lg: "span 3" },
        display: "flex",
        flexDirection: "column",
        border: 1,
        borderColor: (theme) => theme.customColors.border,
        borderRadius: 1,
      }}
    >
      <Box
        sx={{
          mb: 2,
          width: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box display="flex">
          <Typography variant="h6">Earnings (₹)</Typography>
          {/* <Tooltip title="Graph showing addition of unique users to the bolt platform everyday">
            <InfoOutlined
              fontSize="inherit"
              sx={{ ml: 0.5, mt: 0.5, cursor: "pointer" }}
            />
          </Tooltip> */}
          <Avatar variant="label" sx={{ ml: 1 }}>
            {earningsLoading ? (
              <CircularProgress size={13} />
            ) : (
              earningsData?.data?.total_earnings?.toLocaleString() || 0
            )}
          </Avatar>
        </Box>

        <RangePicker range={range} setRange={setRange} />
      </Box>
      {earningsLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
        >
          <CircularProgress />
        </Box>
      ) : (
        <LineChart data={chartData || []} />
      )}
    </Paper>
  );
};

const LineChart: React.FC<{ data: any[] }> = ({ data }) => {
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );
  const theme = useTheme();

  return (
    <Box sx={{ width: 1, minHeight: 0, flexGrow: 1 }}>
      <Line
        data={(canvas) => {
          const ctx = canvas.getContext("2d");
          const g = ctx?.createLinearGradient(0, 0, 0, 280);

          g?.addColorStop(0, theme.customColors.green);
          g?.addColorStop(
            1,
            isDarkMode ? "rgba(0, 0, 0, 0)" : "rgba(255, 255, 255, 0)"
          );

          // let color = "rgb(87, 184, 255)";
          return {
            // labels: data?.map((el: any) => el.x.split(",")[0]),
            labels: data?.map((el: any) => el.x),
            datasets: [
              {
                fill: true,
                data: data.map((el: any) => el.y),
                borderColor: theme.customColors.green,
                backgroundColor: g,
                tension: 0.4,
                pointRadius: 0,
                pointHoverRadius: 4,
                pointHoverBackgroundColor: "#fff",
                pointHoverBorderWidth: 3,
              },
            ],
          };
        }}
        options={{
          scales: {
            xAxis: {
              // type: 'time',
              title: {
                display: false,
                text: "Day",
                padding: {
                  bottom: 0,
                  top: 15,
                },
                color: theme.customColors.grey,
                font: {
                  weight: "500",
                  size: 12,
                },
              },
              grid: {
                display: false,
                tickWidth: 0,
                tickLength: 16,
                drawBorder: false,
              },
            },
            yAxis: {
              // title: {
              //   display: true,
              //   text: "Earnings (₹)",
              //   padding: {
              //     top: 0,
              //     bottom: 15,
              //   },
              //   color: theme.customColors.grey,
              //   font: {
              //     weight: "500",
              //     size: 12,
              //   },
              // },
              ticks: {
                color: theme.palette.text.secondary,
              },
              // suggestedMin: 15,
              // suggestedMax: 30,
              grid: {
                borderDash: [4],
                tickWidth: 0,
                tickLength: 16,
                drawBorder: false,
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              caretSize: 0,
              mode: "index",
              intersect: false,
              yAlign: "center",
              displayColors: false,
              caretPadding: 16,
              titleFont: {
                weight: "400",
              },
              bodyFont: {
                weight: "500",
              },
              callbacks: {
                label: (context: any) => {
                  return "₹" + context.parsed.y;
                },
              },
            },
          },
          interaction: {
            mode: "index",
            intersect: false,
          },
        }}
      />
    </Box>
  );
};

export default Earnings;
