import { Box, Skeleton, Typography } from "@mui/material";
import InfoLabel from "components/InfoLabel";
import { Fragment } from "react";
import { Doughnut } from "react-chartjs-2";
import { useHistory } from "react-router-dom";

const colors: any = {
  Booked: "#EB5C5C",
  Available: "#6EBDE7",
  "In Stock": "#FFCB00",
  "Under Maintenance": "#00000050",
};

const styles: any = {};

Object.keys(colors).forEach((label) => {
  styles[`&.${label.replaceAll(" ", "_")}::before`] = {
    bgcolor: colors[label],
  };
});

const VehicleAvailability = ({ isLoading, data }: any) => {
  const total = (data?.data?.[0]?.count || 0).toLocaleString();

  const history = useHistory();

  return (
    <>
      <Box
        sx={{
          minWidth: { xs: 0, md: 360 },
          mb: { xs: 2, md: 7.5 },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "flex-start", md: "center" },
        }}
      >
        <Typography variant="h3" mr={2.5} mb={{ xs: 2.5, md: 0 }}>
          Vehicle Availability
        </Typography>
        {isLoading ? (
          <Skeleton
            variant="rectangular"
            width={95}
            height={28}
            sx={{ borderRadius: 10 }}
          />
        ) : (
          <InfoLabel label="Total" value={total} />
        )}
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "140px auto",
            md: "minmax(0, 265px) auto",
          },
          gap: { xs: 3, md: 4 },
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              width: 1,
              position: "relative",
              pt: "100%",
            }}
          >
            <Skeleton
              variant="circular"
              sx={{
                position: "absolute",
                top: 0,
                width: 1,
                height: 1,
              }}
            />
          </Box>
        ) : (
          <Box width={1} alignSelf="center" position="relative">
            <Doughnut
              style={{ position: "relative", zIndex: 2 }}
              data={(canvas) => {
                return {
                  datasets: [
                    {
                      data: [
                        data?.data?.[1]?.count,
                        data?.data?.[2]?.count,
                        data?.data?.[3]?.count,
                        data?.data?.[4]?.count,
                      ],
                      backgroundColor: Object.values(colors),
                      hoverBackgroundColor: Object.values(colors),
                      borderWidth: 0,
                      cutout: "85%",
                      hoverOffset: 3,
                    },
                  ],
                  labels: [
                    "Booked",
                    "Available",
                    "In Stock",
                    "Under Maintenance",
                  ],
                };
              }}
              options={{
                onClick: (e: any, element: any) => {
                  let dataIndex = element[0].index;

                  history.push(
                    "/vehicles?status=" +
                      e?.chart?.data?.labels[dataIndex].replace(/\s+/, "")
                  );
                },
                onHover: (event: any, chartElement) => {
                  const target = event.native
                    ? event.native.target
                    : event.target;
                  target.style.cursor = chartElement[0] ? "pointer" : "default";
                },
                layout: {
                  padding: 5,
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    displayColors: false,
                  },
                },
              }}
            />
            <Box
              sx={{
                zIndex: 1,
                position: "absolute",
                top: "35%",
                right: 0,
                left: 0,
                mx: "auto",
                pointerEvents: "none",
                textAlign: "center",
                color: "text.secondary",
              }}
            >
              <Typography fontSize={{ xs: 18, md: 32 }} fontWeight={700}>
                {total}
              </Typography>
              <Typography fontSize={14}>Total</Typography>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            width: 1,
            minWidth: 0,
            maxWidth: 210,
            justifySelf: "end",
            height: "fit-content",
            ml: 2,
            display: "grid",
            gridTemplateColumns: "1fr auto",
            rowGap: 4,
            columnGap: 1,
            fontSize: { xs: 12, md: 16 },
            "& .label": {
              position: "relative",
              cursor: "pointer",
              "&::before": {
                content: '""',
                position: "absolute",
                mt: 0.25,
                transform: "translateX(-15.96px)",
                borderRadius: 10,
                width: "6.39px",
                height: "17.89px",
              },
              ...styles,
            },
            "& .value": {
              // textAlign: "right",
              fontWeight: 700,
              cursor: "pointer",
            },
          }}
        >
          {[
            {
              label: "Booked",
              value: data?.data?.[1]?.count?.toLocaleString(),
            },
            {
              label: "Available",
              value: data?.data?.[2]?.count?.toLocaleString(),
            },
            {
              label: "In Stock",
              value: data?.data?.[3]?.count?.toLocaleString(),
            },
            {
              label: "Under Maintenance",
              value: data?.data?.[4]?.count?.toLocaleString(),
            },
          ].map((el, i) => (
            <Fragment key={i}>
              {isLoading ? (
                <Skeleton width={150} />
              ) : (
                <span
                  className={`label ${el.label.replaceAll(" ", "_")}`}
                  onClick={() => {
                    history.push(
                      "/vehicles?status=" + el.label.replaceAll(" ", "")
                    );
                  }}
                >
                  {el.label}
                </span>
              )}
              {isLoading ? (
                <Skeleton width={20} />
              ) : (
                <span
                  className="value"
                  onClick={() => {
                    history.push(
                      "/vehicles?status=" + el.label.replaceAll(" ", "")
                    );
                  }}
                >
                  {el.value}{" "}
                </span>
              )}
            </Fragment>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default VehicleAvailability;
