import { Box, Button, Paper, Tab, Tabs } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Table from "components/Table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { authorizedFetch, appBar, drawer } from "utils";
import { RETAIL_URL } from "utils/constants";
import AppBar from "./AppBar";
import MarkStatusDialog from "./MarkStatusDialog";

const OEM = () => {
  const [tab, setTab] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [markOEMDialog, setMarkOEMDialog] = useState({
    open: false,
    data: {},
    type: "",
  });

  useEffect(() => {
    appBar.open(<AppBar />);
    return () => {
      appBar.close();
      drawer.close();
    };
  }, []);

  const first = pageSize;
  const skip = pageSize * (page - 1);
  const status = tab === 0 ? "IN_STOCK" : "UNHEALTHY";

  const url = `${RETAIL_URL}/inventory/components?first=${first}&skip=${skip}&inventorystatus=${status}`;

  const { isLoading: oemLoading, data: oemData } = useQuery(
    ["getOemData", first, skip, status],
    () => authorizedFetch(url)
  );

  useEffect(() => {
    setPage(1);
  }, [tab]);

  return (
    <Box
      sx={{
        // px: { xs: 2, md: 7 },
        // pt: { xs: 2, md: 4.75 },
        pb: { xs: 3, md: 7 },
      }}
    >
      <Paper
        sx={{
          width: 1,
          boxShadow: (theme) => theme.customShadows.card,
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            width: 1,
            p: 3,
            pb: 2.75,
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: { md: "space-between" },
            alignItems: { md: "center" },
          }}
        >
          <Tabs
            sx={{
              mr: { md: 2 },
              mb: { xs: 2, md: 0 },
            }}
            className="dense"
            value={tab}
            onChange={(e, tab) => setTab(tab)}
          >
            <Tab label="Available" />
            <Tab label="Defective" />
          </Tabs>
        </Box>

        <Table
          loading={oemLoading}
          rowCount={oemData?.meta?.totalCount}
          rows={
            oemData?.data?.filter(
              (el: any) =>
                el.inventoryStatus === (tab === 0 ? "IN_STOCK" : "UNHEALTHY")
            ) || []
          }
          serverSidePagination
          activePage={page}
          activePageSize={pageSize}
          onPageChange={(value) => setPage(value)}
          onPageSizeChange={(value) => setPageSize(value)}
          columns={[
            { key: "serialNumber", label: "Serial Number" },
            { key: "specs.macId", label: "MAC ID" },
            { key: "parentModel.name", label: "Model" },
            ...(tab === 0
              ? [
                  {
                    key: "updatedAt",
                    label: "Updated On",
                    Render: (row: any) => (
                      <Box>
                        {moment(row.updatedAt).format("ddd, MMM DD, YYYY")}
                      </Box>
                    ),
                  },
                ]
              : [
                  {
                    key: "createddAt",
                    label: "Created On",
                    Render: (row: any) => (
                      <Box>
                        {moment(row.createdAt).format("ddd, MMM DD, YYYY")}
                      </Box>
                    ),
                  },
                ]),
            {
              key: "actions",
              label: "Actions",
              Render: (row: any) => (
                <Button
                  sx={{
                    // px: 2,
                    // py: 1,
                    borderRadius: 10,
                    textTransform: "none",
                  }}
                  variant="outlined"
                  color={tab === 0 ? "error" : "primary"}
                  onClick={() => {
                    setMarkOEMDialog({
                      open: true,
                      data: row,
                      type: tab === 0 ? "defective" : "available",
                    });
                  }}
                  startIcon={tab === 0 ? <WarningAmberIcon /> : <TaskAltIcon />}
                >
                  Mark {tab === 0 ? "Defective" : "Available"}
                </Button>
              ),
            },
          ]}
        />
      </Paper>
      <MarkStatusDialog
        open={markOEMDialog.open}
        handleClose={() => {
          setMarkOEMDialog({
            ...markOEMDialog,
            open: false,
          });
        }}
        data={markOEMDialog.data}
        type={markOEMDialog.type}
      />
    </Box>
  );
};

export default OEM;
