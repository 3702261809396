import {
  Box,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  // TextField,
  // InputAdornment,
  // Autocomplete,
  DialogContent,
  Typography,
} from "@mui/material";
import { HighlightOff } from "@mui/icons-material";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { queryClient } from "index";
import { RETAIL_URL } from "utils/constants";
// import { useQuery } from "react-query";
import { useEffect } from "react";
import AssignVehicleIcon from "assets/images/icons/vehicle.svg";

const AssignOem = ({
  open,
  handleClose,
  selectedRows,
  setSelectedRows,
  setTab,
}: any) => {
  // const [selectedOEM, setSelectedOEM] = useState<any>(null);

  // const oemListUrl = `${RETAIL_URL}/admin/oems`;
  // const { isLoading: oemListLoading, data: oemListData } = useQuery(
  //   ["getOemList"],
  //   () => authorizedFetch(oemListUrl)
  // );

  useEffect(() => {
    if (!open) return;
    // setSelectedOEM(null);
  }, [open]);

  function handleToggle() {
    setLoader(true);
    let url = `${RETAIL_URL}/inventory/component/updateAssignedStatus`;
    authorizedFetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        ids: selectedRows,
        status: "ASSIGNED",
      },
    })
      .then((res) => {
        setLoader(false);
        if (res.meta.success) {
          snackbar.success(`Selected models assigned`);
          queryClient.resetQueries("getSCMData");
          setSelectedRows([]);
          handleClose();
        } else {
          console.error(res);
          snackbar.error("Error assigning device(s)");
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
        snackbar.error("Error assigning device(s)");
      });
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <div style={{ flexGrow: 1 }}></div>
          <IconButton
            children={<HighlightOff />}
            color="inherit"
            onClick={handleClose}
            sx={{ transform: "translate(8px, -8px)" }}
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={AssignVehicleIcon}
              alt="Vehicle icon"
              style={{ width: 80 }}
            />
          </div>
          <br />
          {/* Assign <b>{selectedRows.length}</b> selected device
          {selectedRows.length > 1 && "s"} to: */}
          Are you sure you want to assign
          {selectedRows.length > 1 ? " these " : " this "}
          component{selectedRows.length > 1 ? "s" : ""}?
        </Typography>
        {/* <Autocomplete
          loading={oemListLoading}
          value={selectedOEM}
          onChange={(e, newValue: any) => {
            setSelectedOEM(newValue);
          }}
          size="small"
          getOptionLabel={(option) => option.name}
          options={
            oemListData?.data?.constructor === Array ? oemListData.data : []
          }
          disableClearable
          sx={{
            my: 2,
            width: 250,
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderRadius: 10,
              },
            },
            "& .MuiInputBase-input": {
              height: "28px !important",
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={`Select OEM`}
              InputProps={{
                ...params.InputProps,
                // startAdornment: (
                //   <InputAdornment position="start" sx={{ ml: 1, mr: 0 }}>
                //     <SearchOutlined />
                //   </InputAdornment>
                // ),
                endAdornment: (
                  <InputAdornment position="end">
                    {params.InputProps.endAdornment}
                  </InputAdornment>
                ),
              }}
            />
          )}
        /> */}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleToggle();
            setTab(0);
          }}
          disabled={selectedRows.length === 0}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignOem;
