import {
  Box,
  FormControlLabel,
  Input,
  Slider,
  Switch,
  Typography,
} from "@mui/material";

const Config = ({ isPnp, values, setValues }: any) => {
  return (
    <Box
      sx={{
        maxWidth: 500,
        mx: "auto",
        display: "grid",
        gridTemplateColumns: {
          xs: "1fr",
          sm: "1fr 1fr",
        },
        columnGap: 3,
        rowGap: 2,
      }}
    >
      {[
        ...(!isPnp
          ? [
              {
                id: "speedLimit",
                label: "Speed Limit",
              },
              {
                id: "pickupControlLimit",
                label: "Pickup",
              },
              {
                id: "brakeRegenLimit",
                label: "Regeneration on Braking",
              },
              {
                id: "zeroThrottleRegenLimit",
                label: "Regeneration on Zero Throttle",
              },
              {
                id: "currentLimit",
                label: "Current Limit",
              },
            ]
          : []),
        {
          id: "overVoltageLimit",
          label: "Controller Over Voltage Limit",
        },
        {
          id: "underVoltageLimit",
          label: "Controller Under Voltage Limit",
        },
        {
          id: "batteryMinVoltage",
          label: "Battery Minimum Voltage Limit",
        },
        {
          id: "batteryMaxVoltage",
          label: "Battery Maximum Voltage Limit",
        },
      ].map(({ id, label }, i) => (
        <Box key={i}>
          <Typography variant="body2">{label}</Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 30px",
              gap: 2,
            }}
          >
            <Slider
              min={0}
              max={id === "speedLimit" ? 150 : id === "currentLimit" ? 50 : 100}
              size="small"
              value={typeof values[id] === "number" ? values[id] : 0}
              onChange={(e, val) =>
                setValues((prev: any) => ({ ...prev, [id]: val }))
              }
              disabled
            />
            <Input
              value={values[id]}
              size="small"
              onChange={(e) =>
                setValues((prev: any) => ({
                  ...prev,
                  [id]: e.target.value === "" ? "" : Number(e.target.value),
                }))
              }
              onBlur={() => {
                if (values[id] < 0) {
                  setValues((prev: any) => ({ ...prev, [id]: 0 }));
                } else if (values[id] > 100) {
                  setValues((prev: any) => ({ ...prev, [id]: 100 }));
                }
              }}
              disabled
            />
          </Box>
        </Box>
      ))}
      {!isPnp && (
        <FormControlLabel
          sx={{ gridColumn: 1 }}
          control={
            <Switch
              checked={values?.hillAssistStatus || false}
              onChange={(e) =>
                setValues((prev: any) => ({
                  ...prev,
                  hillAssistStatus: e.target.checked,
                }))
              }
            />
          }
          label="Hill Assist"
        />
      )}
      {!isPnp && (
        <FormControlLabel
          control={
            <Switch
              checked={values?.eabsStatus || false}
              onChange={(e) =>
                setValues((prev: any) => ({
                  ...prev,
                  eabsStatus: e.target.checked,
                }))
              }
            />
          }
          label="E-ABS Status"
        />
      )}
    </Box>
  );
};

export default Config;
