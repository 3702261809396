import { HighlightOff } from "@mui/icons-material";

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { authorizedFetch, setLoader, snackbar } from "utils";

import { LEASE_URL, RETAIL_URL } from "utils/constants";
import Map from "./Map";

interface Props {
  open: boolean;
  handleClose: () => void;
  refetchFences: any;
  refetchCount: any;
}

type inputData = {
  geofenceName: string;

  type: string;
};

const AddPricingDialog: React.FC<Props> = ({
  open,
  handleClose,
  refetchFences,
  refetchCount,
}) => {
  const [input, setInput] = useState<inputData>({
    geofenceName: "",

    type: "CIRCULAR",
  });

  const [circleCoords, setCircleCoord] = useState<any>([]);
  const [polygonCoords, setPolygonCoord] = useState<any>([]);

  const { geofenceName, type } = input;

  function handleChange(key: string, value: string) {
    setInput((prevInput: inputData) => ({ ...prevInput, [key]: value }));
  }

  useEffect(() => {
    if (!open) {
      setInput({
        geofenceName: "",
        type: "CIRCULAR",
      });
    }
  }, [open]);

  const circleRadius =
    type === "CIRCULAR" ? { radius: circleCoords[0]?.radius } : "";

  const vehiclesUrl = `${LEASE_URL}/company/vehicles?all=true`;
  const { data: vehiclesData } = useQuery("getVehicles", () =>
    authorizedFetch(vehiclesUrl)
  );
  const [vehicleList, setVehicleList] = useState<any>([]);

  useEffect(() => {
    if (vehiclesData) {
      let arr: any = [];
      // eslint-disable-next-line
      vehiclesData?.data?.vehicles?.map((el: any) => {
        arr.push(el?.vin);
      });
      setVehicleList(arr.sort((a: any, b: any) => a.localeCompare(b)));
    }
  }, [vehiclesData]);

  const [selectedVehicles, setSelectedVehicles] = useState<any>([]);

  const onSave = () => {
    setLoader(true);
    authorizedFetch(`${RETAIL_URL}/fence/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // token: "1234",
      },
      body: {
        name: geofenceName,
        // uid: String(Math.random() * 100000000 + 1),
        ...circleRadius,
        type: type === "CIRCULAR" ? "CIRCULAR" : "POLYGONAL",
        location: {
          type: type === "CIRCULAR" ? "Point" : "Polygon",
          coordinates:
            type === "CIRCULAR" ? circleCoords[0]?.center : polygonCoords,
        },
        vins: selectedVehicles,
      },
    })
      .then((res) => {
        setLoader(false);

        if (res.meta.success) {
          refetchFences();
          refetchCount();
          snackbar.success(`Fence created`);
          handleClose();
        } else {
          if (res.msg.includes("Can't extract geo keys")) {
            snackbar.error(`Polygon shape is invalid. Please try again.`);
          } else if (
            res.msg.includes("duplicate") &&
            res.msg.includes("name")
          ) {
            snackbar.error("Fence with that name already exists");
          } else {
            snackbar.error("Error creating fence");
          }
        }
      })
      .catch((err) => {
        setLoader(false);
        snackbar.error(`Error creating geofence`);
      });
  };

  const disabled =
    [geofenceName].includes("") ||
    selectedVehicles.length === 0 ||
    (type === "CIRCULAR"
      ? circleCoords.length === 0
      : polygonCoords.length === 0);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: "90%",
          height: "90vh",
          width: 1,
          "& .MuiInputBase-root": {
            fontSize: 14,
            borderRadius: 1,
            p: "3.5px 5px",
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        Create Geofence
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent sx={{ pb: "16px !important" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            mx: "auto",
            py: 2,
            display: "grid",
            gridTemplateColumns: { xs: "1fr", sm: "1fr 3fr" },
            gap: 3,
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              rowGap: 4,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box>
              <Typography className="label">Geofence Name</Typography>
              <TextField
                fullWidth
                size="small"
                value={geofenceName}
                placeholder="Geofence Name"
                onChange={(e) => {
                  if (e.target.value.length <= 20) {
                    handleChange("geofenceName", e.target.value);
                  }
                }}
              />
            </Box>
            <Box>
              <Typography className="label">Vehicle(s)</Typography>
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={vehicleList}
                disableCloseOnSelect
                getOptionLabel={(option: any) => option}
                value={selectedVehicles}
                onChange={(e, newValue) => setSelectedVehicles(newValue)}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      size="small"
                      sx={{ mr: 1, ml: -0.5 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Search Vehicles..." />
                )}
              />
              {/* <Select
                fullWidth
                value={vehicle}
                onChange={(e: any) => {
                  handleChange("vehicle", e.target.value);
                }}
                displayEmpty
              >
                <MenuItem disabled value="">
                  <em>Select</em>
                </MenuItem>
                {vehicleList?.map((el: any) => (
                  <MenuItem key={el.id} value={el.vin}>
                    {el.vin}
                  </MenuItem>
                ))}
              </Select> */}
            </Box>
            <Box>
              <Typography className="label">Type</Typography>
              <RadioGroup
                row
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                }}
                value={type}
                onChange={(e: any) => handleChange("type", e.target.value)}
              >
                <FormControlLabel
                  value="CIRCULAR"
                  control={<Radio />}
                  label="Circular"
                />

                <FormControlLabel
                  value="POLYGON"
                  control={<Radio />}
                  label="Polygon"
                />
              </RadioGroup>
            </Box>
          </Box>
          <Box sx={{ width: "100%", height: "100%" }}>
            <Map
              circleCoords={circleCoords}
              setCircleCoord={setCircleCoord}
              polygonCoords={polygonCoords}
              setPolygonCoord={setPolygonCoord}
              type={input.type}
              handleChange={handleChange}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" disabled={disabled} onClick={onSave}>
          Create Zone
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPricingDialog;
