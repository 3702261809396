import {
  FileDownloadOutlined,
  FilterAltOutlined,
  HelpOutline,
  SearchOutlined,
  Troubleshoot,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  IconButton,
  MenuItem,
  Select,
  Switch,
  Tooltip,
} from "@mui/material";
import Search from "components/Search";
import { saveAs } from "file-saver";
import moment from "moment";
import { useState } from "react";
import { authorizedFetch, setLoader, snackbar, GlobalState } from "utils";
import { REPORTS_URL } from "utils/constants";
import FiltersDrawer from "./FiltersDrawer";
import { useSelector } from "react-redux";

const filterLabels: any = {
  status: {
    BOOKED: "Booked",
    CANCELLED: "Cancelled",
    // ENDED: "Ended",
    // TERMINATED: "Terminated",
    ENDED: "Terminated",
    TERMINATED: "Ended", // TODO: Remove this after backend fix
  },
  refundStatus: {
    INITIATED: "Initiated",
    COMPLETED: "Completed",
  },
};

const downloadBookingsReport = (urlParams: any) => {
  const bookingReportURL = `${REPORTS_URL}/v2/booking/reports/filter?${urlParams}`;
  setLoader(true);
  authorizedFetch(bookingReportURL)
    .then((data) => {
      if (data.file) {
        const fileUrl = data.file;
        let fileName = `Bookings Report - ${moment(new Date()).format(
          "DD-MM-YYYY"
        )}.xlsx`;
        saveAs(fileUrl, fileName);
        setLoader(false);
        snackbar.success("Report downloaded");
      } else if (data.message) {
        snackbar.error(data.message);
      }
    })
    .catch((error) => {
      console.error(error);
      snackbar.error("An error occurred");
    });
};

const BookingFilters = ({ filters, setFilters, urlParams }: any) => {
  const { company } = useSelector((state: GlobalState) => state.global);
  const isCUGMonitorAccount = company?.type === "CUG_VENDOR";

  const [filtersDrawerOpen, setFiltersDrawerOpen] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {!isCUGMonitorAccount && (
        <>
          <Tooltip title="Download Reports" placement="top">
            <IconButton
              size="small"
              sx={{ mr: 1 }}
              onClick={() => downloadBookingsReport(urlParams)}
              children={<FileDownloadOutlined fontSize="medium" />}
            />
          </Tooltip>
          <Tooltip title="Filter" placement="top">
            <IconButton
              size="small"
              sx={{ mr: 1 }}
              onClick={() => setFiltersDrawerOpen(true)}
            >
              <Badge
                color="primary"
                variant="dot"
                invisible={
                  ![
                    filters.dateFrom,
                    filters.dateTo,
                    filters.city,
                    filters.status,
                    filters.refundStatus,
                  ].some((el) => !!el)
                }
              >
                <FilterAltOutlined />
              </Badge>
            </IconButton>
          </Tooltip>
        </>
      )}
      <FiltersDrawer
        open={filtersDrawerOpen}
        onClose={() => setFiltersDrawerOpen(false)}
        {...{ filters, setFilters, filterLabels }}
      />
      <Tooltip title="Search by..." placement="top">
        <Select
          sx={{
            mr: 1,
            "& .MuiInputBase-input": {
              fontSize: 14,
            },
          }}
          size="small"
          className="rounded"
          value={filters.searchBy}
          onChange={(e) => setFilters({ ...filters, searchBy: e.target.value })}
          startAdornment={
            <SearchOutlined
              fontSize="small"
              color="action"
              sx={{ ml: -0.5, mr: 0.5 }}
            />
          }
          renderValue={(value) => {
            let labels: any = {
              bookingId: "Booking ID",
              partialBookingId: "Short Booking ID",
              chargerId: "Charger UID",
              username: "User/Host Name",
              phone: "Phone",
              cug: "CUG Name",
            };
            return labels[value];
          }}
        >
          <MenuItem value="bookingId">
            Booking ID
            <Tooltip title="24-character booking ID" placement="right">
              <HelpOutline
                sx={{
                  ml: 0.5,
                  fontSize: 16,
                  color: (theme) => theme.customColors.action,
                }}
              />
            </Tooltip>
          </MenuItem>
          <MenuItem value="partialBookingId">
            Short Booking ID
            <Tooltip title="Last 8 characters of booking ID" placement="right">
              <HelpOutline
                sx={{
                  ml: 0.5,
                  fontSize: 16,
                  color: (theme) => theme.customColors.action,
                }}
              />
            </Tooltip>
          </MenuItem>
          <MenuItem value="chargerId">Charger UID</MenuItem>
          <MenuItem value="username">User/Host Name</MenuItem>
          <MenuItem value="phone">Phone</MenuItem>
          <MenuItem value="cug">CUG Name</MenuItem>
        </Select>
      </Tooltip>
      <Search
        value={filters.search}
        handleSearch={(value) => {
          setFilters({ ...filters, search: value });
        }}
        persist
        enableClear
        hideIcon
        isValid={(value: string) => {
          if (
            filters.searchBy === "bookingId" &&
            !/^[a-zA-Z0-9]{24}$/.test(value)
          ) {
            snackbar.error(
              "Booking ID must be alphanumeric and 24 characters long"
            );
            return false;
          } else if (
            filters.searchBy === "partialBookingId" &&
            !/^[a-zA-Z0-9]{8}$/.test(value)
          ) {
            snackbar.error(
              "Short booking ID must be alphanumeric and 8 characters long"
            );
            return false;
          }
          return true;
        }}
      />
      {!isCUGMonitorAccount && (
        <>
          <Tooltip title="Anomaly Detection" placement="top">
            <Box
              sx={{
                p: 0.5,
                px: 1,
                ml: 1,
                borderRadius: 10,
                height: 40,
                border: (theme) => `1px solid ${theme.palette.divider}`,
                display: "flex",
                alignItems: "center",
              }}
            >
              <label
                htmlFor="anomaly"
                style={{ height: 20, cursor: "pointer", marginRight: 4 }}
              >
                <Troubleshoot fontSize="small" color="action" />
              </label>
              <Switch
                id="anomaly"
                size="small"
                checked={filters.anomaly}
                onChange={() =>
                  setFilters({ ...filters, anomaly: !filters.anomaly })
                }
              />
            </Box>
          </Tooltip>
        </>
      )}
    </Box>
  );
};

export default BookingFilters;
