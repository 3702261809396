import { ReactComponent as SvgLogo } from "assets/images/logo-custom.svg";

const BoltEarthLogo = ({ color, ...rest }: any) => {
  return (
    <div
      style={{ color: color || "#26C75D", ...rest, cursor: "pointer" }}
      onClick={() => {
        if (window.location.hostname === "eto.revos.in")
          window.open("https://bolt.earth", "_blank");
        else {
          if (window.location.pathname !== "/") window.open("/", "_self");
        }
      }}
    >
      <SvgLogo style={{ width: "100%", height: "auto" }} />
    </div>
  );
};

export default BoltEarthLogo;
