import { DateRangeOutlined } from "@mui/icons-material";
import { DateRange } from "@mui/lab/DateRangePicker";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { sub } from "date-fns";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { guidGenerator } from "utils";

interface Props {
  range: DateRange<Date>;
  setRange: (range: DateRange<Date>) => void;
  presets?: string[];
  initialRange?: string;
}

const RangePicker: React.FC<Partial<Props>> = ({
  range,
  setRange,
  presets = ["7D", "1M", "3M", "1Y", "YTD"],
  initialRange,
}) => {
  const id = useMemo(() => guidGenerator(), []);
  const [value, setValue] = useState<string | null>(initialRange || "1M");

  useEffect(() => {
    if (range === null) setValue(null);
  }, [range]);

  function handleChange(newValue: string) {
    setValue(newValue);
    if (!setRange) return;
    if (newValue !== "custom") {
      setRange([
        newValue === "YTD"
          ? moment().startOf("year").toDate()
          : sub(new Date(), {
              ...(newValue === "12H" ? { hours: 12 } : {}),
              ...(newValue === "1D" ? { days: 1 } : {}),
              ...(newValue === "4D" ? { days: 4 } : {}),
              ...(newValue === "7D" ? { days: 7 } : {}),
              ...(newValue === "1M" ? { months: 1 } : {}),
              ...(newValue === "3M" ? { months: 3 } : {}),
              ...(newValue === "1Y" ? { years: 1 } : {}),
            }),
        new Date(),
      ]);
    }
  }

  useEffect(() => {
    $("#" + (id || "daterange")).daterangepicker({
      opens: "left",
      maxDate: moment(),
      startDate: moment().subtract({ months: 1 }),
      // endDate: moment(),
      showDropdowns: true,
      autoApply: true,
      locale: {
        format: "DD/MM/YYYY",
        firstDay: 1,
      },
    });
    $("#" + (id || "daterange")).on(
      "apply.daterangepicker",
      function (e, picker) {
        handleChange("custom");
        setRange &&
          setRange([picker.startDate.toDate(), picker.endDate.toDate()]);
      }
    );
    // eslint-disable-next-line
  }, []);

  return (
    <ToggleButtonGroup
      sx={{
        "& .MuiToggleButton-root": {
          py: 1.25,
          px: 1.5,
          fontSize: 16,
          lineHeight: "24px",
        },
      }}
      exclusive
      size="small"
      color="primary"
      value={value}
      onChange={(e, newValue: any) => {
        if (newValue && newValue !== "custom") handleChange(newValue);
      }}
    >
      {presets.map((el, i) => (
        <ToggleButton key={i} value={el}>
          <div>{el}</div>
        </ToggleButton>
      ))}
      <ToggleButton id={id || "daterange"} disableRipple value="custom">
        <DateRangeOutlined sx={{ fontSize: 18 }} />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default RangePicker;
