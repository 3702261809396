import {
  AccountTreeOutlined,
  BoltOutlined,
  CurrencyRupee,
  EditOutlined,
  EventAvailableOutlined,
  FlagOutlined,
  HighlightOff,
  TimerOutlined,
  VerifiedUser,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Switch,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import CircularLoader from "components/CircularLoader";
import Map from "components/Map";
import RangePicker from "components/RangePicker";
import Table from "components/Table";
import { format, sub } from "date-fns";
import moment from "moment";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  GlobalState,
  authorizedFetch,
  drawer,
  getDarkModePreference,
  getDuration,
  getPermissions,
  snackbar,
} from "utils";
import { BOLT_URL, OCPP_URL, WIFI_URL } from "utils/constants";
import AssignVendorDialog from "./AssignVendorDialog";
import ChargerHistory from "./ChargerHistory";
import ChargerVisibility from "./ChargerVisibility";
import EditChargerDialog from "./EditChargerDialog";
import EditOwnershipDialog from "./EditOwnershipDialog";
import FlagChargerDialog from "./FlagChargerDialog";
import NotificationDialog from "./NotificationDialog";
import { formatPhoneNumberIntl } from "react-phone-number-input";
// import { getBoltToken } from "utils/request";

interface Props {
  chargerId: string;
  openTab: number;
}

function getTime(charger: any, day: number) {
  return charger?.availability?.twentyFourSeven
    ? "All Day"
    : charger?.availability.days.some((el: any) => el.day === day) === false
    ? "Unavailable"
    : charger?.availability.days.map((el: any) => {
        if (el.day === day) {
          return (
            <div>
              {moment(el.fromTime, ["HH:mm"]).format("h:mm A") +
                "-" +
                moment(el.toTime, ["HH:mm"]).format("h:mm A")}
            </div>
          );
        } else {
          return "";
        }
      });
}

const DrawerContent: React.FC<Props> = ({ chargerId, openTab }) => {
  const { canWrite } = getPermissions("charger:chargers");
  const {
    drawer: drawerState,
    masterView,
    company,
  } = useSelector((state: GlobalState) => state.global);
  const isCUGMonitorAccount = company?.type === "CUG_VENDOR";
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );

  const [tab, setTab] = useState(openTab);
  const [bookingsFilter, setBookingsFilter] = useState("");
  const [anomaly, setAnomaly] = useState(false);

  const [assignVendorDialog, setAssignVendorDialog] = useState({
    open: false,
    data: {},
  });
  const [flagDialog, setFlagDialog] = useState({
    open: false,
    data: {},
  });
  const [editDialog, setEditDialog] = useState({
    open: false,
    count: 0,
  });
  const [editOwnershipDialog, setEditOwnershipDialog] = useState({
    open: false,
    charger: null,
  });
  const [notificationDialog, setNotificationDialog] = useState({
    open: false,
    data: {},
  });
  const [chargerVisibilityDialog, setChargerVisibilityDialog] = useState({
    open: false,
    data: {},
  });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const first = pageSize;
  const skip = pageSize * (page - 1);

  useEffect(() => {
    setPage(1);
  }, [anomaly, bookingsFilter]);

  const chargerUrl = `${BOLT_URL}/company/charger/${chargerId}`;
  const { isLoading: chargerLoading, data: chargerData } = useQuery(
    ["getCharger", chargerId, masterView],
    () =>
      authorizedFetch(chargerUrl, {
        headers: {
          master: masterView,
        },
      }),
    {
      enabled: !!chargerId,
      onSuccess: (data) => {
        if (!Boolean(data?.data?.charger)) {
          drawer.close();
          snackbar.error(`No charger found with ID "${chargerId}"`);
        }
      },
    }
  );

  const charger = chargerData?.data || null;

  const { isLoading: bookingsLoading, data: bookingsData } = useQuery(
    ["getBookings", chargerId, first, skip, anomaly, bookingsFilter],
    () =>
      authorizedFetch(
        `${BOLT_URL}/company/bookings?&orderBy=BOOKING_TIME_DESC&chargerId=${chargerId}&first=${first}&skip=${skip}&anomaly=${anomaly}&status=${bookingsFilter}`,
        {
          headers: {
            master: masterView,
          },
        }
      )
  );

  const nearbyChargersUrl = `${BOLT_URL}/charger/getAvailableList?lat=${charger?.station.location.latitude}&lng=${charger?.station.location.longitude}&first=20&skip=0&radius=10000`;
  const { data: nearbyChargers } = useQuery(
    ["getNearbyChargers", chargerId],
    () => authorizedFetch(nearbyChargersUrl),
    {
      enabled: !!charger,
    }
  );

  const chargerSpecsUrl = `${BOLT_URL}/charger/${chargerId}/config`;
  const { data: chargerSpecsData } = useQuery(
    ["getChargerSpecification", chargerId],
    () =>
      authorizedFetch(chargerSpecsUrl, {
        headers: {
          master: masterView,
        },
      }),
    {
      enabled: !!chargerId,
    }
  );

  const chargerSpecs = chargerSpecsData?.data?.specification || null;

  const chargerDetails = {
    basicInfo: [
      { label: "UID", value: charger?.charger.chargerId },
      { label: "Name", value: charger?.charger.chargerName },
      ...(!isCUGMonitorAccount
        ? [
            {
              label: "Initialized On",
              value: moment(charger?.charger.createdAt).format(
                "MMM DD, YYYY, HH:mm"
              ),
            },
            {
              label: "Last Ping",
              value: charger?.charger?.lastPing
                ? moment(charger.charger.lastPing).format("MMM DD, YYYY, HH:mm")
                : "N/A",
            },
            // {
            //   label: "Company",
            //   value: charger?.charger.companyName,
            // },
          ]
        : []),
    ],
    ownerInfo: [
      ...(!isCUGMonitorAccount
        ? [
            {
              label: "Name",
              value: charger?.owner?.firstName
                ? charger?.owner.firstName + " " + charger?.owner.lastName
                : "-",
            },
            {
              label: "Phone Number",
              value: charger?.owner?.phone
                ? formatPhoneNumberIntl(charger.owner.phone)
                : "-",
            },
            {
              label: "Alternate Phone 1",
              value: charger?.owner?.altPhone1
                ? formatPhoneNumberIntl(charger.owner.altPhone1)
                : "-",
            },
            {
              label: "Alternate Phone 2",
              value: charger?.owner?.altPhone2
                ? formatPhoneNumberIntl(charger.owner.altPhone2)
                : "-",
            },
            {
              label: "Email",
              value: charger?.owner?.email ? charger.owner.email : "-",
            },
          ]
        : []),
      {
        label: "Address",
        value: charger?.owner?.address ? charger.owner.address : "-",
      },
    ],
    stationInfo: [
      ...(!isCUGMonitorAccount
        ? [
            {
              label: "Maintainer Name",
              value: charger?.incharge?.name,
            },
            {
              label: "Maintainer Phone",
              value: charger?.incharge?.phoneNumber
                ? formatPhoneNumberIntl(charger.incharge.phoneNumber)
                : "-",
            },
            {
              label: "Usage Type",
              value: charger?.charger.usageType,
            },
            {
              label: "Station Name",
              value: charger?.station.stationName,
            },
          ]
        : []),
      {
        label: "Station Address",
        value: charger?.station.address,
      },
      {
        label: "City",
        value: charger?.station.city,
      },
      {
        label: "Latitude",
        value: charger?.station.location.latitude,
      },
      {
        label: "Longitude",
        value: charger?.station.location.longitude,
      },
    ],
    paymentInfo: [
      ...(charger?.chargerType?.communicationProtocol !== "OCPP"
        ? [
            {
              label: "Billing Mode",
              value: charger?.paymentDetails?.tariffMode || "-",
            },
          ]
        : []),
      {
        label: "Energy Tariff",
        value: "₹" + charger?.paymentDetails?.ownerCostPerkWh || "0",
      },
      ...(charger?.paymentDetails?.tariffMode === "HOURLY"
        ? [
            {
              label: "Base Price",
              value: "₹" + charger?.paymentDetails?.baseAmount || "0",
            },
            {
              label: "Cost Per Hour",
              value: `₹${charger?.paymentDetails?.chargePerHour}`,
            },
          ]
        : [
            {
              label: "Cost Per kWh",
              value: `₹${charger?.paymentDetails?.costPerkWh}`,
            },
          ]),
      // {
      //   label: "Parking Charges",
      //   value: "₹" + charger?.paymentDetails?.parkingCharges || "0",
      // },
      {
        label: "Settlement Cycle",
        value: charger?.paymentDetails?.settlementCycle || "DAILY",
      },
      {
        label: "Pricing Type",
        value: charger?.paymentDetails?.tariffMode || "-",
      },
    ],
    availability: [
      {
        label: "Sunday",
        value: getTime(charger, 0),
      },
      {
        label: "Monday",
        value: getTime(charger, 1),
      },
      {
        label: "Tuesday",
        value: getTime(charger, 2),
      },
      {
        label: "Wednesday",
        value: getTime(charger, 3),
      },
      {
        label: "Thursday",
        value: getTime(charger, 4),
      },
      {
        label: "Friday",
        value: getTime(charger, 5),
      },
      {
        label: "Saturday",
        value: getTime(charger, 6),
      },
    ],
    specs: [
      ...(charger?.chargerType.communicationProtocol !== "OCPP"
        ? [
            {
              label: "Auto Cutoff",
              value: `${
                (chargerSpecs?.chargerConfigParams.autoCutOff.value || 0) * 100
              } AMP`,
            },
            {
              label: "Auto Cutoff Time",
              value: `${
                chargerSpecs?.chargerConfigParams.autoCutOffTime.value || 0
              } seconds`,
            },
          ]
        : [
            {
              label: "Auto Cutoff",
              value: `${
                chargerSpecs?.chargerConfigParams.autoCutOffTime.value || 0
              } seconds`,
            },
          ]),
      {
        label: "Power Output",
        value: `${chargerSpecs?.powerOutput.value || 0} A`,
      },
      {
        label: "Manufacturer",
        value:
          charger?.charger.manufacturer ||
          charger?.chargerType.manufacturerName ||
          "-",
      },
      {
        label: "Model",
        value: charger?.charger.model || charger?.chargerType.modelName || "-",
      },
      {
        label: "Power Rating",
        value:
          (charger?.charger.powerRating.value ||
            charger?.chargerType.powerRating.value) + " kW",
      },
      ...(charger?.chargerType.communicationProtocol !== "OCPP"
        ? [
            {
              label: "Firmware Version",
              value: charger?.specification.firmware,
            },
          ]
        : []),
      {
        label: "Communication Protocol",
        value: charger?.chargerType.communicationProtocol,
      },
      {
        label: "Connector Type",
        value: charger?.chargerType.connectorType,
      },
      {
        label: "Current",
        value: charger?.chargerType.current.value + "A",
      },
      {
        label: "Voltage",
        value: charger?.chargerType.voltage.value + "V",
      },
      {
        label: "Connectivity Type",
        value: charger?.chargerType?.mode || "-",
      },
      ...(charger?.chargerType.communicationProtocol !== "OCPP"
        ? [
            {
              label: "Over Voltage",
              value: `${chargerSpecs?.chargerConfigParams.overVoltage} V`,
            },
            {
              label: "Over Voltage Time",
              value: `${chargerSpecs?.chargerConfigParams.overVoltageTime.value} seconds`,
            },
            {
              label: "Under Voltage",
              value: `${chargerSpecs?.chargerConfigParams.underVoltage} V`,
            },
            {
              label: "Under Voltage Time",
              value: `${chargerSpecs?.chargerConfigParams.underVoltageTime.value} seconds`,
            },
            {
              label: "Over Current",
              value: `${chargerSpecs?.chargerConfigParams.overCurrent} AMP`,
            },
            {
              label: "Over Current Time",
              value: `${chargerSpecs?.chargerConfigParams.overCurrentTime.value} seconds`,
            },
            {
              label: "Over Temperature",
              value: `${chargerSpecs?.chargerConfigParams.overTemperature} °C`,
            },
            {
              label: "Over Temperature Time",
              value: `${chargerSpecs?.chargerConfigParams.overTemperatureTime.value} seconds`,
            },
            {
              label: "Under Temperature",
              value: `${chargerSpecs?.chargerConfigParams.underTemperature} °C`,
            },
            {
              label: "Under Temperature Time",
              value: `${chargerSpecs?.chargerConfigParams.underTemperatureTime.value} seconds`,
            },
            {
              label: "Current Factor",
              value: chargerSpecs?.chargerConfigParams.currentFactor,
            },
            {
              label: "Voltage Factor",
              value: chargerSpecs?.chargerConfigParams.voltageFactor,
            },
            {
              label: "Active Power Factor",
              value: chargerSpecs?.chargerConfigParams.activePowerFactor,
            },
            {
              label: "Energy Factor",
              value: charger?.charger.energyFactor,
            },
          ]
        : []),
    ],
  };

  const AlertBox = ({ color, mainText, showUnhideButton }: any) => {
    return (
      <>
        <Box
          width="100%"
          height="60px"
          sx={{
            width: 1,
            height: "60px",
            px: 1.5,
            background: color,
            borderRadius: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography sx={{ fontSize: 14, fontWeight: "600" }}>
              {mainText}
            </Typography>

            <Typography sx={{ fontSize: 12, fontWeight: "400" }}>
              Contact Owner Now!
            </Typography>
          </Box>

          <Box>
            <Button
              variant={showUnhideButton ? "outlined" : "contained"}
              size="small"
              sx={{
                background: showUnhideButton ? "white" : "",
                mr: 1,
                height: 38,
              }}
              onClick={() => {
                setNotificationDialog({ open: true, data: charger });
              }}
            >
              Notify
            </Button>
            {showUnhideButton ? (
              <Button
                variant="contained"
                size="small"
                sx={{ height: 38 }}
                onClick={() => {
                  setChargerVisibilityDialog({
                    open: true,
                    data: charger,
                  });
                }}
              >
                Unhide
              </Button>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </>
    );
  };

  useEffect(() => {
    if (drawerState.open) {
      setTab(openTab);
      setBookingsFilter("");
      // getVendorsData();
    }
  }, [drawerState.open, openTab]);

  const [openButton, setOpenButton] = useState(false);

  const [chartData, setChartData] = useState<any>();

  const [range, setRange] = useState<any>([
    sub(new Date(), { months: 1 }),
    new Date(),
  ]);

  let dateFrom = format(range[0], "yyyy-MM-dd");
  let dateTo = format(range[1], "yyy-MM-dd");

  const bookingsNewUrl = `${BOLT_URL}/company/stats/charger/date?orderBy=BOOKING_TIME_ASC&dateFrom=${dateFrom}&dateTo=${dateTo}&chargerId=${chargerId}`;

  const { isLoading: chartLoading, data: chartResponse } = useQuery(
    ["getBookingsStatsByDate", dateFrom, dateTo, chargerId],
    () =>
      authorizedFetch(bookingsNewUrl, {
        headers: {
          master: masterView,
        },
      }),
    {
      onError: () => snackbar.error("Error fetching data"),
    }
  );

  useEffect(() => {
    if (chartResponse?.data?.stats?.constructor === Array) {
      let dataArray = chartResponse.data.stats.sort(
        (a: any, b: any) => moment(a.date).valueOf() - moment(b.date).valueOf()
      );
      let chartData = dataArray.reduce(
        (acc: any, cur: any) => {
          let day = moment(cur.date).format("MMM D, yyyy");
          const getExisting = (key: string) =>
            acc[key].find((el: any) => el.x === day);
          if (getExisting("bookings")) {
            getExisting("bookings").y += cur.totalBookings;
            getExisting("earnings").y += Math.round(
              parseFloat(cur.totalEarnings)
            );
            getExisting("energy").y += cur.totalEnergyConsumed;
            getExisting("users").y += cur.totalUsers;
          } else {
            acc.bookings.push({ x: day, y: cur.totalBookings });
            acc.earnings.push({
              x: day,
              y: Math.round(parseFloat(cur.totalEarnings)),
            });
            acc.energy.push({ x: day, y: cur.totalEnergyConsumed });
            acc.users.push({ x: day, y: cur.totalUsers });
          }
          return acc;
        },
        {
          bookings: [],
          earnings: [],
          energy: [],
          users: [],
        }
      );
      setChartData(chartData);
    }
  }, [drawerState.open, chartResponse, chargerId]);

  let chartArray = chartData?.bookings || [];

  useEffect(() => {
    if (drawerState.open) {
      setRange([sub(new Date(), { months: 1 }), new Date()]);
    }
  }, [drawerState.open, charger]);

  const loader = (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 70,
        width: 1,
        mb: "3px",
      }}
    >
      <CircularProgress color="primary" size={24} />
    </Box>
  );

  if (chargerLoading || !charger)
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            mb: 2,
            px: 3,
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: isDarkMode ? "#000" : "#3D3D3D",
            fontWeight: 500,
            color: "#fff",
          }}
        >
          {chargerId}
          <IconButton
            children={<HighlightOff />}
            color="inherit"
            size="small"
            onClick={() => drawer.close()}
          />
        </Box>
        <CircularLoader />
      </Box>
    );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            px: 3,
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: isDarkMode ? "#000" : "#3D3D3D",
            fontWeight: 500,
            color: "#fff",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {charger?.charger.chargerId}
            {charger?.charger.boltVerified === true && (
              <Tooltip title="Verified">
                <Box
                  sx={{
                    ml: 1,
                    position: "relative",
                    height: 16,
                    width: 16,
                  }}
                >
                  <span
                    style={{
                      position: "absolute",
                      backgroundColor: "#fff",
                      width: "8px",
                      height: "8px",
                      left: 4,
                      top: 4,
                      zIndex: 0,
                    }}
                  ></span>
                  <VerifiedUser
                    color="primary"
                    fontSize="inherit"
                    sx={{ position: "absolute", fontSize: 16, zIndex: 2 }}
                  />
                </Box>
              </Tooltip>
            )}
          </Box>
          <Box display="flex">
            {!isCUGMonitorAccount && canWrite && (
              <>
                <Tooltip
                  title={
                    charger?.charger?.hidden ? "Unhide Charger" : "Hide Charger"
                  }
                >
                  <IconButton
                    sx={{ mr: 1 }}
                    children={
                      charger?.charger?.hidden ? (
                        <VisibilityOffOutlined />
                      ) : (
                        <VisibilityOutlined />
                      )
                    }
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setChargerVisibilityDialog({ open: true, data: charger });
                    }}
                  />
                </Tooltip>
                <Tooltip title="Flag Charger">
                  <IconButton
                    sx={{ mr: 1 }}
                    children={<FlagOutlined />}
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setFlagDialog({ open: true, data: charger });
                    }}
                  />
                </Tooltip>
                <Tooltip title="Assign Vendor">
                  <IconButton
                    sx={{ mr: 1 }}
                    children={<AccountTreeOutlined />}
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setAssignVendorDialog({ open: true, data: charger });
                    }}
                  />
                </Tooltip>
              </>
            )}
            <IconButton
              children={<HighlightOff />}
              color="inherit"
              size="small"
              onClick={() => {
                drawer.close();
                setAnomaly(false);
              }}
            />
          </Box>
        </Box>
        <Box flexGrow={1} overflow="auto" pb={2}>
          {!isCUGMonitorAccount &&
            ["MODERATE", "CRITICAL", "INACTIVE"].includes(
              charger?.charger?.health
            ) && (
              <Box sx={{ px: 2, pt: 1 }}>
                {charger?.charger?.health === "MODERATE" ? (
                  <AlertBox
                    color="#FFFF0030"
                    mainText="CHARGER INACTIVE SINCE LAST 15 DAYS."
                    showUnhideButton={false}
                  />
                ) : charger?.charger?.health === "CRITICAL" ? (
                  <AlertBox
                    color="#FFA50030"
                    mainText="CHARGER INACTIVE SINCE LAST 30 DAYS."
                    showUnhideButton={false}
                  />
                ) : charger?.charger?.health === "INACTIVE" ? (
                  <AlertBox
                    color="#FF000020"
                    mainText={
                      charger?.charger?.hidden === true
                        ? "CHARGER HIDDEN FROM THE MAP."
                        : "CHARGER INACTIVE SINCE LAST 45 DAYS."
                    }
                    showUnhideButton={charger?.charger?.hidden === true}
                  />
                ) : null}
              </Box>
            )}
          <Box p={2} pb={0}>
            <Tabs
              variant="scrollable"
              allowScrollButtonsMobile
              className="dense"
              value={tab}
              onChange={(e: any, tab: any) => {
                setTab(tab);
              }}
            >
              <Tab label="Information" />
              {!isCUGMonitorAccount && <Tab label="Bookings" />}
              {!isCUGMonitorAccount && <Tab label="Stats" />}
              {!isCUGMonitorAccount && <Tab label="Map View" />}
              {!isCUGMonitorAccount && <Tab label="Support Logs" />}
            </Tabs>
          </Box>
          {tab === 0 ? (
            <Box
              sx={{
                px: 3,
                pt: 2.5,
                "& .table": {
                  borderCollapse: "collapse",
                  width: 1,
                  fontSize: 14,
                  lineHeight: "16px",
                  "& td": {
                    py: 1.25,
                    px: 2,
                  },
                  "& .bold": {
                    fontWeight: 500,
                  },
                  "& .header": {
                    px: 2,
                    py: 1,
                    position: "relative",
                    "& td": {
                      position: "absolute",
                      verticalAlign: "middle",
                      backgroundColor: (theme: any) =>
                        theme.customColors.header,
                      width: 1,
                      borderRadius: "4px",
                      fontSize: 16,
                      fontWeight: 600,
                      "& .label": {
                        display: "inline-block",
                        transform: "translateY(1px)",
                        py: 1.125,
                      },
                    },
                  },
                  "& .first > td": {
                    pt: 9,
                  },
                  "& .last > td": {
                    pb: 2.75,
                  },
                },
              }}
            >
              <table className="table">
                <tbody>
                  <tr className="header">
                    <td colSpan={2}>
                      <span className="label">Charger Info</span>
                    </td>
                  </tr>
                  {chargerDetails?.basicInfo?.map(
                    ({ label, value }: any, i: number) => (
                      <tr
                        key={i}
                        className={
                          i === 0
                            ? "first"
                            : i === chargerDetails.basicInfo.length - 1
                            ? "last"
                            : ""
                        }
                      >
                        <td>{label}</td>
                        <td className="bold" width="50%">
                          {value}
                        </td>
                      </tr>
                    )
                  )}
                  <tr className="header">
                    <td colSpan={2}>
                      <span className="label">Owner Info</span>
                      {canWrite && !isCUGMonitorAccount && (
                        <Tooltip title="Change Ownership" placement="right">
                          <IconButton
                            sx={{ ml: 1.5 }}
                            children={<EditOutlined />}
                            color="primary"
                            size="small"
                            onClick={() => {
                              setEditOwnershipDialog({
                                open: true,
                                charger,
                              });
                            }}
                          />
                        </Tooltip>
                      )}
                    </td>
                  </tr>
                  {chargerDetails?.ownerInfo?.map(
                    ({ label, value }: any, i: number) => (
                      <tr
                        key={i}
                        className={
                          i === 0
                            ? "first"
                            : i === chargerDetails.ownerInfo.length - 1
                            ? "last"
                            : ""
                        }
                      >
                        <td>{label}</td>
                        <td className="bold" width="50%">
                          {value}
                        </td>
                      </tr>
                    )
                  )}
                  <tr className="header">
                    <td colSpan={2}>
                      <span className="label">Station Info</span>
                      {canWrite && !isCUGMonitorAccount && (
                        <IconButton
                          sx={{ ml: 1.5 }}
                          children={<EditOutlined />}
                          color="primary"
                          size="small"
                          onClick={() => {
                            setEditDialog({
                              open: true,
                              count: 0,
                            });
                          }}
                        />
                      )}
                    </td>
                  </tr>
                  {chargerDetails?.stationInfo?.map(
                    ({ label, value }: any, i: number) => (
                      <tr
                        key={chargerDetails.stationInfo.length + i}
                        className={
                          i === 0
                            ? "first"
                            : i === chargerDetails.stationInfo.length - 1
                            ? "last"
                            : ""
                        }
                      >
                        <td>{label}</td>
                        <td className="bold">{value}</td>
                      </tr>
                    )
                  )}
                  {!["PUBLIC_FREE", "RESTRICTED_FREE"].includes(
                    charger?.charger?.usageType
                  ) && (
                    <>
                      <tr className="header">
                        <td colSpan={2}>
                          <span className="label">Payment Details</span>
                          {canWrite && !isCUGMonitorAccount && (
                            <IconButton
                              sx={{ ml: 1.5 }}
                              children={<EditOutlined />}
                              color="primary"
                              size="small"
                              onClick={() => {
                                setEditDialog({
                                  open: true,
                                  count: 0,
                                });
                              }}
                            />
                          )}
                        </td>
                      </tr>
                      {!isCUGMonitorAccount &&
                        chargerDetails?.paymentInfo?.map(
                          ({ label, value }: any, i: number) => (
                            <tr
                              key={chargerDetails.paymentInfo.length + i}
                              className={
                                i === 0
                                  ? "first"
                                  : i === chargerDetails.paymentInfo.length - 1
                                  ? "last"
                                  : ""
                              }
                            >
                              <td>{label}</td>
                              <td className="bold">{value}</td>
                            </tr>
                          )
                        )}
                    </>
                  )}
                  <tr className="header">
                    <td colSpan={2}>
                      <span className="label">Availability</span>
                      {canWrite && !isCUGMonitorAccount && (
                        <IconButton
                          sx={{ ml: 1.5 }}
                          children={<EditOutlined />}
                          color="primary"
                          size="small"
                          onClick={() => {
                            setEditDialog({
                              open: true,
                              count: 1,
                            });
                          }}
                        />
                      )}
                    </td>
                  </tr>
                  {chargerDetails?.availability?.map(
                    ({ label, value }: any, i: number) => (
                      <tr
                        key={chargerDetails.stationInfo.length + i}
                        className={
                          i === 0
                            ? "first"
                            : i === chargerDetails.stationInfo.length - 1
                            ? "last"
                            : ""
                        }
                      >
                        <td>{label}</td>
                        <td className="bold">{value}</td>
                      </tr>
                    )
                  )}
                  {!isCUGMonitorAccount && (
                    <tr className="header" style={{ marginTop: "20px" }}>
                      <td
                        style={{
                          height: "50px",
                          display: "flex",
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                        colSpan={2}
                      >
                        <span className="label">Specifications</span>
                        {canWrite && (
                          <IconButton
                            sx={{ ml: 1.5 }}
                            children={<EditOutlined />}
                            color="primary"
                            size="small"
                            onClick={() => {
                              setEditDialog({
                                open: true,
                                count: 2,
                              });
                            }}
                          />
                        )}
                      </td>
                    </tr>
                  )}

                  {!isCUGMonitorAccount &&
                    chargerDetails?.specs?.map(
                      ({ label, value }: any, i: number) => (
                        <tr
                          key={chargerDetails.basicInfo.length + i}
                          className={
                            i === 0
                              ? "first"
                              : i === chargerDetails.specs.length - 1
                              ? "last"
                              : ""
                          }
                        >
                          <td>{label}</td>
                          <td className="bold">{value}</td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            </Box>
          ) : tab === 1 ? (
            <Box>
              <Box
                my={2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingLeft: 2,
                }}
              >
                <Box>
                  <RadioGroup
                    row
                    value={bookingsFilter}
                    onChange={(e: any) => setBookingsFilter(e.target.value)}
                  >
                    <FormControlLabel
                      value=""
                      control={<Radio />}
                      label="All"
                    />
                    <FormControlLabel
                      value="TERMINATED"
                      control={<Radio />}
                      label="Terminated"
                    />
                    {/* <FormControlLabel value="ongoing" control={<Radio />} label="Ongoing" /> */}
                    <FormControlLabel
                      value="ENDED"
                      control={<Radio />}
                      label="Ended"
                    />
                    <FormControlLabel
                      value="PENDING"
                      control={<Radio />}
                      label="Pending Payment"
                    />
                  </RadioGroup>
                </Box>
                <Box
                  sx={{
                    paddingRight: 1,
                  }}
                >
                  <Tooltip title="Anomaly Detection">
                    <Switch
                      size="small"
                      checked={anomaly}
                      onChange={() => setAnomaly(!anomaly)}
                    />
                  </Tooltip>
                </Box>
              </Box>
              <Table
                loading={bookingsLoading}
                serverSidePagination
                rowCount={bookingsData?.data?.bookingsCount}
                activePage={page}
                activePageSize={pageSize}
                onPageChange={(value) => setPage(value)}
                onPageSizeChange={(value) => setPageSize(value)}
                rows={bookingsData?.data?.bookings || []}
                columns={[
                  {
                    key: "userName",
                    label: "User",
                    Render: (row) => (
                      <Box>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => setOpenButton(!openButton)}
                        >
                          {openButton ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                        <Collapse in={openButton} timeout="auto" unmountOnExit>
                          {!row.userName || row.userName === " "
                            ? "-"
                            : row.userName}
                          <br />
                          {!row.userEmail || row.userEmail === " "
                            ? "-"
                            : row.userEmail}
                          <br />
                          {!row.userPhone || row.userPhone === " "
                            ? "-"
                            : formatPhoneNumberIntl(row.userPhone)}
                        </Collapse>
                      </Box>
                    ),
                  },
                  {
                    key: "bookingStart",
                    label: "Start Time",
                    Render: (row) => (
                      <Box>
                        {moment(row.bookingStart).format("DD MMM 'YY, hh:mm a")}
                      </Box>
                    ),
                  },
                  {
                    key: "bookingDuration",
                    label: "Booking Duration",
                    format: (value) => getDuration(value * 60),
                  },
                  {
                    key: "chargingDuration",
                    label: "Charging Duration",
                    format: (value) => getDuration(value * 60),
                  },
                  {
                    key: "amount",
                    label: "Cost",
                    format: (val) => `₹${val || 0}`,
                  },
                  {
                    key: "energyConsumed",
                    label: "Energy Consum.",
                    Render: (row) => (
                      <LiveEnergy
                        booking={row}
                        chargerType={chargerData?.data?.chargerType}
                        specification={chargerData?.data?.specification}
                      />
                    ),
                  },
                ]}
              />
            </Box>
          ) : tab === 2 ? (
            <Box py={3} px={2.5}>
              <Box mb={3}>
                <Box
                  mb={1.5}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6">Stats</Typography>
                  {/* <RangePicker /> */}
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: 1,
                    "& > div": {
                      width: 1,
                      p: 1.5,
                      display: "flex",
                      border: 1,
                      borderRadius: "3px",
                      borderColor: (theme) => theme.customColors.border,
                      "& .MuiAvatar-root": {
                        mr: 1,
                      },
                      "& .info": {
                        display: "flex",
                        flexDirection: "column",
                        "& .label": {
                          fontSize: 17,
                          fontWeight: 600,
                          color: "text.primary",
                          lineHeight: "1.15em",
                        },
                        "& .value": {
                          fontSize: 12,
                          color: "text.secondary",
                        },
                      },
                    },
                  }}
                >
                  <Box>
                    <Avatar variant="icon">
                      <EventAvailableOutlined />
                    </Avatar>
                    <Box className="info">
                      <span className="label">
                        {charger?.chargerStats?.totalBookings || "-"}
                      </span>
                      <span className="value">Bookings</span>
                    </Box>
                  </Box>
                  <Box>
                    <Avatar variant="icon">
                      <CurrencyRupee />
                    </Avatar>
                    <Box className="info">
                      <span className="label">
                        {typeof charger?.chargerStats?.totalEarnings ===
                        "number"
                          ? "₹" +
                            charger?.chargerStats.totalEarnings?.toFixed(2)
                          : "-"}
                      </span>
                      <span className="value">Earnings</span>
                    </Box>
                  </Box>
                  <Box>
                    <Avatar variant="icon">
                      <TimerOutlined />
                    </Avatar>
                    <Box className="info">
                      <span className="label">
                        {typeof charger?.paymentDetails?.chargerPerHour ===
                        "number"
                          ? `₹${charger.paymentDetails.chargePerHour}`
                          : "₹0"}
                      </span>
                      <span className="value">Cost Per Hour</span>
                    </Box>
                  </Box>
                  <Box>
                    <Avatar variant="icon">
                      <BoltOutlined />
                    </Avatar>
                    <Box className="info">
                      <span className="label">
                        {typeof charger?.paymentDetails?.costPerkWh === "number"
                          ? `₹${charger.paymentDetails.costPerkWh}`
                          : "₹0"}
                      </span>
                      <span className="value">Cost Per kWh</span>
                    </Box>
                  </Box>
                  <Box sx={{ gridColumn: "span 2" }}>
                    <Avatar variant="icon">
                      <BoltOutlined />
                    </Avatar>
                    <Box className="info">
                      <span className="label">
                        {typeof charger?.chargerStats?.totalEnergyConsumed ===
                        "number"
                          ? `${
                              charger?.chargerStats.totalEnergyConsumed.toFixed(
                                2
                              ) + " kWh"
                            }`
                          : "0 kWh"}
                      </span>
                      <span className="value">Energy Consumed</span>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                mb={2.5}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6">Insights</Typography>
                <RangePicker range={range} setRange={setRange} />
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gap: 2,
                  "& > div": {
                    border: 1,
                    bgcolor: isDarkMode ? "background.default" : "#fff",
                    borderColor: (theme: any) => theme.customColors.border,
                    borderRadius: 1,
                    p: 2.5,
                    display: "flex",
                    flexDirection: "column",
                    "& span": {
                      display: "flex",
                      alignItems: "center",
                      "& span": {
                        alignItems: "end",
                      },
                    },
                    "& .value": {
                      fontSize: "1.75rem",
                      fontWeight: 700,
                      lineHeight: "1.75rem",
                    },
                    "& .unit": {
                      ml: 0.75,
                    },
                    "& .title": {
                      ml: 1.5,
                      color: "text.secondary",
                    },
                  },
                }}
              >
                <Box key={chartArray.length}>
                  {chartLoading ? (
                    loader
                  ) : (
                    <LineChart data={chartData?.bookings || []} />
                  )}

                  <span>
                    <Typography className="value">
                      {chartResponse?.data?.totalBookings
                        ? chartResponse?.data?.totalBookings
                        : 0}
                    </Typography>
                    <Typography className="title">Total Bookings</Typography>
                  </span>
                </Box>
                <Box key={chartArray.length + 1}>
                  {chartLoading ? (
                    loader
                  ) : (
                    <LineChart isBlue data={chartData?.earnings || []} />
                  )}
                  <span>
                    <Typography className="value">
                      ₹{chartResponse?.data?.totalEarnings}
                    </Typography>
                    <Typography className="title">Total Earnings</Typography>
                  </span>
                </Box>
                <Box key={chartArray.length + 2}>
                  {chartLoading ? (
                    loader
                  ) : (
                    <LineChart isBlue data={chartData?.users || []} />
                  )}
                  <span>
                    <Typography className="value">
                      {chartResponse?.data?.totalUsers}
                    </Typography>
                    <Typography className="title">Total Users</Typography>
                  </span>
                </Box>
                <Box key={chartArray.length + 3}>
                  {chartLoading ? (
                    loader
                  ) : (
                    <LineChart data={chartData?.energy || []} />
                  )}
                  <span>
                    <span>
                      <Typography className="value">
                        {chartResponse?.data?.totalEnergyConsumed?.toFixed(3)}
                      </Typography>
                      <Typography className="unit">kWh</Typography>
                    </span>
                    <Typography className="title">Energy Dispensed</Typography>
                  </span>
                </Box>
              </Box>
            </Box>
          ) : tab === 3 ? (
            <Box p={2.5} height={436}>
              <Map
                loading={false}
                type="charger"
                borderRadius={1}
                location={charger?.station.location}
                dataArray={nearbyChargers?.data || []}
                onClick={(id: string) => {
                  drawer.open(<DrawerContent chargerId={id} openTab={0} />);
                }}
              />
            </Box>
          ) : tab === 4 ? (
            <ChargerHistory data={charger} tab={tab} open={drawerState.open} />
          ) : null}
        </Box>
      </Box>
      <AssignVendorDialog
        open={assignVendorDialog.open}
        handleClose={() => {
          setAssignVendorDialog({ ...assignVendorDialog, open: false });
        }}
        data={[charger?.charger?.chargerId]}
      />
      <FlagChargerDialog
        open={flagDialog.open}
        handleClose={() => {
          setFlagDialog({ ...flagDialog, open: false });
        }}
        data={charger}
        closeDrawer={() => drawer.close()}
      />
      <EditChargerDialog
        open={editDialog.open}
        handleClose={() => {
          setEditDialog({ open: false, count: 0 });
        }}
        data={charger}
        chargerData={chargerData}
        count={editDialog.count}
      />
      <EditOwnershipDialog
        open={editOwnershipDialog.open}
        charger={editOwnershipDialog.charger}
        handleClose={() =>
          setEditOwnershipDialog((prev) => ({ ...prev, open: false }))
        }
      />
      <NotificationDialog
        open={notificationDialog.open}
        handleClose={() => {
          setNotificationDialog({
            ...notificationDialog,
            open: false,
          });
        }}
        data={notificationDialog.data}
      />
      <ChargerVisibility
        open={chargerVisibilityDialog.open}
        handleClose={() => {
          setChargerVisibilityDialog({
            ...chargerVisibilityDialog,
            open: false,
          });
        }}
        closeDrawer={drawer.close}
        data={chargerVisibilityDialog.data}
      />
    </>
  );
};

const LiveEnergy = ({ booking, chargerType, specification }: any) => {
  const isOCPP = chargerType?.communicationProtocol === "OCPP";
  const isMQTT = chargerType?.communicationProtocol === "MQTT";
  const shouldShowLiveData =
    booking?.bookingStatus === "BOOKED" && (isOCPP || isMQTT);

  // const OCPP_URL_PROD = "https://ocpp.revos.in";
  // const WIFI_URL_PROD = "https://bolt-wifi.revos.in";

  const url = isOCPP
    ? `${OCPP_URL}/charger/getChargerData`
    : `${WIFI_URL}/fetchEnergyConsumedV2`;

  const { isLoading: chargerLoading, data: chargerData } = useQuery(
    [
      "getChargerData",
      booking?.chargerId,
      booking?.connectorId,
      specification?.bleMac,
      booking?.bookingId,
    ],
    () =>
      authorizedFetch(url, {
        method: "POST",
        headers: {
          stage: "test",
          "Content-Type": "application/json",
        },
        body: isOCPP
          ? {
              chargerId: booking?.chargerId + "/" + booking?.connectorId,
              action: "currentMeterValues",
            }
          : {
              deviceId: specification?.bleMac?.replaceAll(":", ""),
              bookingId: booking?.bookingId,
            },
      }),
    {
      enabled: shouldShowLiveData,
      refetchInterval: shouldShowLiveData ? 10000 : false,
    }
  );

  const { value, unit } = chargerData?.data || {};

  const liveEnergyConsumed = shouldShowLiveData
    ? isOCPP
      ? // For OCPP
        (typeof value === "number" ? value.toFixed(3) : 0) +
        " " +
        (unit || "kWh")
      : // For MQTT
        (chargerData?.energyConsumed
          ? (chargerData?.energyConsumed / 1000).toFixed(3)
          : 0) + " kWh"
    : null;

  const energyConsumed =
    (booking?.energyConsumed ? booking.energyConsumed.toFixed(3) : 0) + " kWh";

  return (
    <>
      {shouldShowLiveData
        ? chargerLoading
          ? "Loading..."
          : liveEnergyConsumed
        : energyConsumed}
    </>
  );
};

const LineChart: React.FC<{ isBlue?: boolean; data: any[] }> = ({
  isBlue,
  data,
}) => {
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );

  return (
    <Box
      sx={{
        flexGrow: 1,
        position: "relative",
        height: 70,
        width: 1,
        mb: "3px",
      }}
    >
      <Line
        height={10}
        style={{ marginTop: 10 }}
        data={(canvas) => {
          const ctx = canvas.getContext("2d");
          const g = ctx?.createLinearGradient(0, 0, 0, 60);

          g?.addColorStop(
            0,
            isBlue ? "rgba(97, 209, 105, 0.7)" : "rgba(97, 209, 105, 0.6)"
          );
          g?.addColorStop(
            0.5,
            isBlue ? "rgba(97, 209, 105, 0.4)" : "rgba(97, 209, 105, 0.2)"
          );
          g?.addColorStop(
            1,
            isDarkMode ? "rgba(0, 0, 0, 0)" : "rgba(255, 255, 255, 0)"
          );

          let color = isBlue ? "rgba(97,209,105)" : "rgba(97,209,105)";

          return {
            // labels: data?.map(el => el.t),
            datasets: [
              {
                fill: true,
                data: data,
                borderColor: color,
                backgroundColor: g,
                tension: 0.4,
                pointRadius: 0,
                pointHoverRadius: 4,
                pointHoverBackgroundColor: "#fff",
                pointHoverBorderColor: color,
                pointHoverBorderWidth: 3,
              },
            ],
          };
        }}
        options={{
          scales: {
            xAxis: {
              display: false,
              // type: "linear",
              // type: 'time'
            },
            yAxis: {
              display: false,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              caretSize: 0,
              mode: "index",
              intersect: false,
              yAlign: "center",
              displayColors: false,
              caretPadding: 16,
              titleFont: {
                weight: "400",
              },
              bodyFont: {
                weight: "500",
              },
            },
          },
          layout: {
            padding: {
              bottom: 20,
            },
          },
          interaction: {
            mode: "index",
            intersect: false,
          },
        }}
      />
    </Box>
  );
};

export default DrawerContent;
