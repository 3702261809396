import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { useMutation } from "react-query";
import { HighlightOff } from "@mui/icons-material";
import { WALLET_URL } from "utils/constants";

interface Props {
  open: boolean;
  handleClose: () => void;
  data: any;
  user: any;
  refetchTransactions: () => void;
  refetchWallet: () => void;
}

const RefundDialog: React.FC<Props> = ({
  open,
  handleClose,
  data,
  user,
  refetchTransactions,
  refetchWallet,
}) => {
  const [input, setInput] = useState({
    amount: "",
    remarks: "",
  });

  useEffect(() => {
    if (open && data) {
      setInput({
        ...input,
        remarks: `Refund for Transaction ID: ${data.id}`,
      });
    }
    if (!open) {
      setInput({
        amount: "",
        remarks: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, data]);

  const { amount, remarks } = input;

  const mutation = useMutation(
    ["sendEMAIL", amount, user?._id],
    () =>
      authorizedFetch(
        `${WALLET_URL}/v1/wallet/refund?customerId=${user?._id}&amount=${amount}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        },
      ),
    {
      onSuccess: () => {
        snackbar.success(`Refund successful`);
        refetchTransactions();
        refetchWallet();
        setLoader(false);
      },
      onError: () => {
        snackbar.error(`Error sending refund`);
      },
    },
  );

  function onSend() {
    setLoader(true);
    mutation.mutate();

    handleClose();
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          Add Funds to Wallet
          <IconButton
            children={<HighlightOff />}
            color="inherit"
            onClick={handleClose}
            sx={{ transform: "translate(8px, -8px)" }}
          />
        </Box>
        <Typography sx={{ fontSize: 13, mt: -1.5 }}>
          {user.firstName ? user?.firstName + " " + user?.lastName : "-"}
        </Typography>
      </DialogTitle>

      <DialogContent className="py-1">
        <Box mt={2}>
          <Typography className="label">Amount</Typography>
          <TextField
            fullWidth
            size="small"
            placeholder="0"
            value={amount}
            helperText="Max amount 200"
            onChange={(e: any) => {
              if (
                e.target.value === "" ||
                (/^[0-9.]{0,5}$/.test(e.target.value) &&
                  parseFloat(e.target.value) <= 200)
              )
                setInput({ ...input, amount: e.target.value });
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">₹</InputAdornment>
              ),
            }}
          />
        </Box>

        <Box mt={4}>
          <Typography className="label">Remarks</Typography>
          <TextField
            fullWidth
            id="outlined-multiline-static"
            multiline
            rows={5}
            placeholder="Type a remark..."
            value={remarks}
            onChange={(e: any) => {
              setInput({ ...input, remarks: e.target.value });
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          disabled={input.remarks.length === 0 || input.amount.length === 0}
          variant="contained"
          onClick={onSend}
        >
          ADD
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RefundDialog;
