/* This is storage util file
contains:
  getters-setters in localStorage
*/

const localStorage = window && window.localStorage;

const storageManager = {
  get: (key) => {
    let value = localStorage && localStorage.getItem(key);
    return value;
  },
  set: (key, value) => {
    localStorage &&
      localStorage.setItem(
        key,
        typeof value !== "string" ? JSON.stringify(value) : value
      );
  },
  remove: (key) => {
    localStorage && localStorage.removeItem(key);
  },
};

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (err) {
    // Ignore write errors.
  }
};

export default storageManager;
