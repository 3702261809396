import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Search from "components/Search";
import TableComponent from "components/Table";
import { queryClient } from "index";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
// import { useSelector } from "react-redux";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { RETAIL_URL } from "utils/constants";

const AssignDialog = ({ open, handleClose, distributor }: any) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  // const { company } = useSelector((state: GlobalState) => state.global);

  const URL = `${RETAIL_URL}/distribution/vehicles-assigned?first=${pageSize}&skip=${
    pageSize * (page - 1)
  }&search=${search}`;

  // const url = `${RETAIL_URL}/distribution/distributor/${
  //   company.id
  // }/assigned-vehicles?first=${pageSize}&skip=${pageSize * (page - 1)}`;
  // &search=${search}&orderBy=createdAt&ascending=false&status=UNASSIGNED`;
  const { isLoading, data } = useQuery(
    ["getVehicles", page, pageSize, search],
    () => authorizedFetch(URL)
  );

  useEffect(() => {
    if (open) setSelectedRows([]);
  }, [open]);

  function handleSave() {
    setLoader(true);
    let url = `${RETAIL_URL}/distribution/assign-distributor`;
    authorizedFetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        distributor: distributor?._id,
        vehicles: selectedRows,
      },
    })
      .then((res) => {
        setLoader(false);
        if (res.meta.success) {
          snackbar.success(`Assigned vehicles`);
          queryClient.resetQueries("getVehicles");
          queryClient.resetQueries("getDistributors");
          queryClient.resetQueries("getAssignedVehicles");
          handleClose();
        } else {
          console.error(res);
          snackbar.error(res.msg);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
        snackbar.error(err.msg);
      });
  }

  let rowCount = data?.meta.totalCount || 0;
  let rows = data?.data?.constructor === Array ? data.data : [];

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Assign Vehicles
        <Search
          handleSearch={(input) => setSearch(input)}
          persist
          enableClear
        />
      </DialogTitle>
      <DialogContent sx={{ mt: 1, mb: -3 }}>
        <TableComponent
          idKey="_id"
          px={0}
          small
          smallPagination
          selectable
          selectOnClick
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          loading={isLoading}
          rowCount={rowCount}
          serverSidePagination={true}
          activePage={page}
          activePageSize={pageSize}
          onPageChange={(value) => setPage(value)}
          onPageSizeChange={(value) => setPageSize(value)}
          rows={rows}
          columns={[
            { key: "vin", label: "VIN" },
            { key: "model.name", label: "Model" },
            {
              key: "createdAt",
              label: "Created At",
              format: (value) => moment(value).format("MMM DD, YYYY HH:mm"),
            },
          ]}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={handleSave}>
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignDialog;
