import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout, toggleDarkMode } from "actions";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Slide,
  Typography,
} from "@mui/material";
import {
  AccountCircleOutlined,
  ArrowBack,
  Brightness4Outlined,
  Check,
  ChevronRight,
  ExitToApp,
  // HelpOutline,
  // SettingsOutlined,
  Translate,
} from "@mui/icons-material";
import { authorizedFetch, GlobalState, setLoader } from "utils";
import { queryClient } from "index";
import { AUTH_URL } from "utils/constants";

interface Props {
  anchor: HTMLButtonElement | null;
  setAnchor: Function;
  isDarkMode?: boolean;
}

const AccountPopover: React.FC<Props> = ({ anchor, setAnchor, isDarkMode }) => {
  const { user } = useSelector((state: GlobalState) => state.global);
  const darkModeState = useSelector(
    (state: GlobalState) => state.global.prefersDarkMode
  );

  const [selectThemeView, setSelectThemeView] = useState(false);
  const [selectLanguageView, setSelectLanguageView] = useState(false);
  const [backAnimation, setBackAnimation] = useState(false);

  const handlePopoverClose = () => {
    setAnchor(null);
    setTimeout(() => {
      setSelectThemeView(false);
      setSelectLanguageView(false);
    }, 200);
  };
  const handleBackAnimation = () => {
    setBackAnimation(true);
    setTimeout(() => {
      setBackAnimation(false);
    }, 200);
  };

  const dispatch = useDispatch();
  const handleThemeChage = (arg: boolean | null) => {
    dispatch(toggleDarkMode(arg));
  };
  const handleLogout = () => {
    setLoader(true);
    authorizedFetch(`${AUTH_URL}/user/logout`, { method: "POST" }).then(() => {
      setLoader(false);
      localStorage.clear();
      queryClient.clear();
      dispatch(logout());
    });
  };

  return (
    <Popover
      sx={{
        "& .MuiPaper-root": {
          boxShadow: (theme) => theme.shadows[3],
          borderRadius: 2,
        },
        "& .MuiList-root": {
          p: 0.5,
        },
        "& .MuiListItemButton-root": {
          borderRadius: 1.5,
        },
        "& .MuiListItemIcon-root": {
          minWidth: 38,
          "& svg": {
            height: "0.85em",
            width: "0.85em",
          },
        },
        "& .header": {
          marginLeft: "0.5em",
          fontSize: "1.1em",
          fontWeight: 500,
        },
      }}
      open={Boolean(anchor)}
      anchorEl={anchor}
      onClose={handlePopoverClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      {!selectThemeView && !selectLanguageView && (
        <Slide in direction={backAnimation ? "right" : undefined}>
          <div>
            <Box
              sx={{
                m: 0.5,
                mb: 0,
                p: 2,
                display: "grid",
                gridTemplateColumns: "auto 1fr",
                justifyItems: "center",
                alignItems: "center",
                backgroundImage: `linear-gradient(to bottom, ${
                  isDarkMode ? "#262626, #2a2a2a" : "#F4F7FC, #fafafa"
                })`,
                borderRadius: 1.5,
              }}
            >
              <AccountCircleOutlined
                sx={{
                  mr: 1,
                  fontSize: 42,
                  transform: "translateX(-4px)",
                  color: "primary.light",
                }}
              />
              {(user?.firstName || user?.lastName || user?.email) && (
                <Box width={180}>
                  <Typography variant="h6">
                    {`${user.firstName || ""} ${user.lastName || ""}`}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ color: isDarkMode ? "#e0e0e0" : "#616161" }}
                    noWrap
                  >
                    {user.email || ""}
                  </Typography>
                </Box>
              )}
            </Box>
            <List disablePadding>
              <ListItemButton
                component={Link}
                to="/account"
                onClick={handlePopoverClose}
              >
                <ListItemIcon>
                  <AccountCircleOutlined />
                </ListItemIcon>
                <ListItemText primary="Your account" />
              </ListItemButton>
              {/* <ListItemButton
                component={Link}
                to="/settings"
                onClick={handlePopoverClose}
              >
                <ListItemIcon>
                  <SettingsOutlined />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItemButton> */}
              <ListItemButton onClick={handleLogout}>
                <ListItemIcon>
                  <ExitToApp />
                </ListItemIcon>
                <ListItemText primary="Sign out" />
              </ListItemButton>
            </List>
            <Divider />
            <List disablePadding>
              <ListItemButton dense onClick={() => setSelectThemeView(true)}>
                <ListItemIcon>
                  <Brightness4Outlined />
                </ListItemIcon>
                <ListItemText
                  primary="Appearance"
                  secondary={
                    darkModeState === true ? (
                      <Box display="flex" alignItems="center">
                        Dark theme
                        <Typography
                          sx={{
                            ml: 0.75,
                            color: "text.secondary",
                            lineHeight: 0,
                            fontSize: 11,
                            opacity: 0.5,
                          }}
                        >
                          (BETA)
                        </Typography>
                      </Box>
                    ) : darkModeState === false ? (
                      "Light theme"
                    ) : (
                      "Device theme"
                    )
                  }
                />
                <ChevronRight />
              </ListItemButton>
              <ListItemButton dense onClick={() => setSelectLanguageView(true)}>
                <ListItemIcon>
                  <Translate />
                </ListItemIcon>
                <ListItemText primary="Language" secondary="English" />
                <ChevronRight />
              </ListItemButton>
              {/* <ListItemButton
                component={Link}
                to="/help"
                onClick={handlePopoverClose}
              >
                <ListItemIcon>
                  <HelpOutline />
                </ListItemIcon>
                <ListItemText primary="Help" />
              </ListItemButton> */}
            </List>
          </div>
        </Slide>
      )}
      {selectThemeView && (
        <Slide in={selectThemeView} direction="left" mountOnEnter unmountOnExit>
          <div>
            <Box display="flex" alignItems="center" p={0.5}>
              <IconButton
                onClick={() => {
                  setSelectThemeView(false);
                  handleBackAnimation();
                }}
              >
                <ArrowBack />
              </IconButton>
              <Typography className="header">Appearance</Typography>
            </Box>
            <Divider />
            <List disablePadding style={{ minWidth: 220 }}>
              <ListItemButton onClick={() => handleThemeChage(null)}>
                <ListItemText primary="Use device theme" />
                {darkModeState === null && <Check />}
              </ListItemButton>
              <ListItemButton onClick={() => handleThemeChage(true)}>
                <ListItemText
                  sx={{ display: "flex", alignItems: "center" }}
                  disableTypography
                >
                  Dark theme
                  <Typography
                    sx={{
                      ml: 0.75,
                      color: "text.secondary",
                      lineHeight: 0,
                      fontSize: 12,
                      opacity: 0.7,
                    }}
                  >
                    (BETA)
                  </Typography>
                </ListItemText>
                {darkModeState === true && <Check />}
              </ListItemButton>
              <ListItemButton onClick={() => handleThemeChage(false)}>
                <ListItemText primary="Light theme" />
                {darkModeState === false && <Check />}
              </ListItemButton>
            </List>
          </div>
        </Slide>
      )}
      {selectLanguageView && (
        <Slide
          in={selectLanguageView}
          direction="left"
          mountOnEnter
          unmountOnExit
        >
          <div>
            <Box display="flex" alignItems="center" p={0.5} pr={3}>
              <IconButton
                onClick={() => {
                  setSelectLanguageView(false);
                  handleBackAnimation();
                }}
              >
                <ArrowBack />
              </IconButton>
              <Typography className="header">Choose your language</Typography>
            </Box>
            <Divider />
            <List disablePadding style={{ minWidth: 220 }}>
              <ListItemButton>
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
                <ListItemText primary="English (US)" />
              </ListItemButton>
              {/* <ListItemButton disabled>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Chinese" />
              </ListItemButton> */}
            </List>
          </div>
        </Slide>
      )}
    </Popover>
  );
};

export default AccountPopover;
