import {
  EditOutlined,
  HighlightOff,
  InfoOutlined,
  KeyboardArrowDown,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Step,
  StepButton,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import CircularLoader from "components/CircularLoader";
import { queryClient } from "index";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { LEASE_URL, RETAIL_URL } from "utils/constants";
import validator from "validator";

const AddDialog = ({
  open,
  handleClose,
  isEditMode,
  distributor,
  refetchVendors,
  refetchVendorCounts,
}: any) => {
  const [step, setStep] = useState(0);
  const steps = isEditMode
    ? ["Company Info", "Confirm"]
    : ["Company Info", "User Info", "Confirm"];
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [showPass, setShowPass] = useState(false);

  const inputObj: any = {
    companyName: "",
    gstNumber: "",
    companyPhone: "",
    address: "",
    district: "",
    state: "",
    pincode: "",
    firstName: "",
    lastName: "",
    userPhone: "",
    email: "",
  };
  const [input, setInput] = useState({ ...inputObj });

  const {
    companyName,
    gstNumber,
    companyPhone,
    address,
    district,
    state,
    pincode,
    firstName,
    lastName,
    userPhone,
    email,
  } = input;

  type RequiredFields =
    | "companyName"
    | "gstNumber"
    | "companyPhone"
    | "address"
    | "district"
    | "state"
    | "pincode"
    | "firstName"
    | "lastName"
    | "userPhone"
    | "email"
    | "password";

  const validationsObj = {
    companyName: true,
    gstNumber: true,
    companyPhone: true,
    address: true,
    district: true,
    state: true,
    pincode: true,
    firstName: true,
    lastName: true,
    userPhone: true,
    email: true,
    password: true,
  };

  const [validations, setValidations] = useState<{
    [key in RequiredFields]: Boolean;
  }>({ ...validationsObj });

  const disabled =
    step === 0
      ? [
          companyName,
          gstNumber,
          companyPhone,
          address,
          district,
          state,
          pincode,
        ].includes("") || Object.values(validations).some((el) => el === false)
      : step === 1
      ? [firstName, lastName, userPhone, email, password, confirmPass].includes(
          ""
        )
      : // || Object.values(validations).some((el) => el === false)
        false;

  const distributorUrl = `${RETAIL_URL}/distribution/distributor/${distributor?._id}`;
  const { isLoading, data } = useQuery(
    ["getDistributor", distributor?._id],
    () => authorizedFetch(distributorUrl),
    { enabled: Boolean(isEditMode && distributor) }
  );

  const createUrl = `${LEASE_URL}/company/vendor/create`;
  const createMutation = useMutation(
    `addDistributor`,
    () =>
      authorizedFetch(createUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          vendor: {
            name: input.companyName,
            gstNumber,
            phone: input.companyPhone,
            address: {
              district: input.district,
              state: input.state,
              pincode: input.pincode,
              local: input.address,
            },
          },
          user: {
            firstName: input.firstName,
            lastName: input.lastName,
            phone: input.userPhone,
            email: input.email,
            password: password,
          },
        },
      }),
    {
      onSuccess: (res) => {
        setLoader(false);
        console.log(res);
        if (res.msg === "Vendor created") {
          snackbar.success(`Vendor created`);
          // queryClient.resetQueries("getDistributors");
          refetchVendors();
          refetchVendorCounts();
          handleClose();
        } else {
          snackbar.error("Error adding vendor: " + res.msg);
        }
      },
      onError: () => {
        snackbar.error(`Error adding vendor`);
      },
    }
  );
  const updateUrl = `${RETAIL_URL}/distribution/distributor/${distributor?._id}`;
  const updateMutation = useMutation(
    `updateDistributor`,
    () =>
      authorizedFetch(updateUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          vendor: {
            name: input.companyName,
            gstNumber,
            phone: input.companyPhone,
            address: {
              district: input.district,
              state: input.state,
              pincode: input.pincode,
              local: input.address,
            },
          },
        },
      }),
    {
      onSuccess: (res) => {
        setLoader(false);
        if (res.meta.success) {
          snackbar.success(`Distributor updated`);
          queryClient.resetQueries("getDistributor");
          queryClient.resetQueries("getDistributors");
          handleClose();
        } else {
          snackbar.error("Error updating distributor: " + res.msg);
        }
      },
      onError: () => {
        snackbar.error(`Error updating distributor`);
      },
    }
  );

  function handleChange(key: string, value: string) {
    setInput((prev: any) => ({ ...prev, [key]: value }));
  }

  useEffect(() => {
    let newValidations = { ...validationsObj };
    if (step === 0) {
      if (!companyName) newValidations.companyName = false;
      if (!/^[a-zA-Z0-9]{15}$/.test(gstNumber))
        newValidations.gstNumber = false;
      if (!/^[\d]{10}$/.test(companyPhone)) newValidations.companyPhone = false;
      if (!address) newValidations.address = false;
      if (!district) newValidations.district = false;
      if (!state) newValidations.state = false;
      if (!/^\d{6}/.test(pincode)) newValidations.pincode = false;
    } else if (!isEditMode && step === 1) {
      if (!firstName) newValidations.firstName = false;
      if (!lastName) newValidations.lastName = false;
      if (!/^[\d]{10}$/.test(userPhone)) newValidations.userPhone = false;
      if (!validator.isEmail(email)) newValidations.email = false;

      if (validatePassword() !== null) newValidations.password = false;
    }
    setValidations(newValidations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  function handleNext() {
    // let newValidations = { ...validationsObj };

    // if (step === 0) {
    //   if (!companyName) newValidations.companyName = false;
    //   if (!/^[a-zA-Z0-9]{15}$/.test(gstNumber))
    //     newValidations.gstNumber = false;
    //   if (!/^[\d]{10}$/.test(companyPhone)) newValidations.companyPhone = false;
    //   if (!address) newValidations.address = false;
    //   if (!district) newValidations.district = false;
    //   if (!state) newValidations.state = false;
    //   if (!/^\d{6}/.test(pincode)) newValidations.pincode = false;
    // } else if (!isEditMode && step === 1) {
    //   if (!firstName) newValidations.firstName = false;
    //   if (!lastName) newValidations.lastName = false;
    //   if (!/^[\d]{10}$/.test(userPhone)) newValidations.userPhone = false;
    //   if (!validator.isEmail(email)) newValidations.email = false;
    //   if (validatePassword() !== null) newValidations.password = false;
    // }

    // setValidations(newValidations);

    // if (Object.values(newValidations).some((el) => el === false)) return;

    if (step === steps.length - 1) {
      handleSave();
    } else setStep(step + 1);
  }

  function handleBack() {
    setStep(step - 1);
  }

  function handleSave() {
    setLoader(true);
    if (isEditMode) updateMutation.mutate();
    else createMutation.mutate();
  }

  useEffect(() => {
    if (open && isEditMode) {
      setStep(0);
      setPassword("");
      setConfirmPass("");
      if (data) {
        let distributor = data?.data?.[0]?.distributor || {};
        setInput({
          ...inputObj,
          companyName: distributor.name || "",
          gstNumber: distributor.gstNumber || "",
          companyPhone: distributor.phone?.substring(3) || "",
          address: distributor.address?.local || "",
          district: distributor.address?.district || "",
          state: distributor.address?.state || "",
          pincode: distributor.address?.pincode || "",
        });
      }
    } else if (open) {
      setStep(0);
      setPassword("");
      setConfirmPass("");
      setInput({ ...inputObj });
    }
    // eslint-disable-next-line
  }, [open, isEditMode, data]);

  function validatePassword() {
    if (password === "" && confirmPass === "") return "Enter a password";
    else if (password !== confirmPass) return "Passwords don't match";
    else if (password.length < 6)
      return "Use 6 characters or more for your password";
    else if (
      !/[A-Za-z]/.test(password) ||
      !/[0-9]/.test(password) ||
      !/[^A-Za-z0-9]/.test(password)
    )
      return "Please choose a stronger password. Try a mix of letters, numbers, and symbols.";
    else return null;
  }

  const statesUrl = `https://geocode.revos.in/place/states?country=India&input=`;
  const { isLoading: statesLoading, data: statesData } = useQuery(
    ["getStates"],
    () => authorizedFetch(statesUrl)
  );

  const citiesUrl = `https://geocode.revos.in/place/cities?country=India&state=${state}&input=`;
  const { isLoading: citiesLoading, data: citiesData } = useQuery(
    ["getCities", state],
    () => authorizedFetch(citiesUrl),
    {
      enabled: Boolean(state),
    }
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: 800,
          width: 1,
          "& .MuiInputBase-root": {
            fontSize: 14,
            borderRadius: 1,
            p: "3.5px 5px",
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        {isEditMode ? "Edit" : "Add"} Vendor
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent sx={{ pb: "16px !important" }}>
        <Stepper
          sx={{ my: 4, mx: "auto", maxWidth: 534 }}
          activeStep={step}
          // nonLinear
          alternativeLabel
        >
          {steps.map((label, i) => (
            <Step key={i}>
              <StepButton onClick={() => setStep(i)}>{label}</StepButton>
            </Step>
          ))}
        </Stepper>
        {isEditMode && isLoading ? (
          <CircularLoader />
        ) : (
          step === 0 && (
            <Box
              sx={{
                maxWidth: { xs: 280, sm: 560 },
                mx: "auto",
                py: 2,
                display: "grid",
                gridTemplateColumns: { sm: "1fr 1fr" },
                gap: 3,
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gridColumn: { sm: "span 2" },
                }}
                variant="body2"
                color="text.secondary"
              >
                <InfoOutlined
                  fontSize="small"
                  sx={{
                    mr: 0.75,
                    color: "primary.main",
                  }}
                />{" "}
                All fields are required
              </Typography>
              <Box gridColumn={{ sm: "span 2" }}>
                <Typography className="label">Company Name</Typography>
                <TextField
                  required
                  fullWidth
                  size="small"
                  value={companyName}
                  placeholder="Company Name"
                  onChange={(e) => {
                    if (/^[a-zA-Z ]{0,50}$/.test(e.target.value))
                      handleChange("companyName", e.target.value);
                  }}
                  // error={!validations.companyName && companyName !== ""}
                  // helperText={
                  //   !validations.companyName && companyName !== ""
                  //     ? "Required"
                  //     : undefined
                  // }
                />
              </Box>
              <Box>
                <Typography className="label">GST Number</Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={gstNumber}
                  placeholder="GST Number"
                  onChange={(e: any) => {
                    if (/^[a-zA-Z0-9]{0,15}$/.test(e.target.value))
                      handleChange("gstNumber", e.target.value);
                    // setInput({
                    //   ...input,
                    //   gstNumber:
                    //     e.target.value?.length > 0
                    //       ? e.target.value.replace(/[^a-zA-Z0-9 ]/g, "")
                    //       : e.target.value,
                    // });
                  }}
                  // error={!validations.gstNumber && gstNumber !== ""}
                  // helperText={
                  //   !validations.gstNumber && gstNumber !== ""
                  //     ? "Please enter a valid 15-character GST number"
                  //     : undefined
                  // }
                />
              </Box>
              <Box>
                <Typography className="label">Phone Number</Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={companyPhone}
                  placeholder="Phone Number"
                  inputProps={{ maxLength: 10 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+91</InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    if (/^[0-9]{0,10}$/.test(e.target.value))
                      handleChange("companyPhone", e.target.value);
                  }}
                  // error={!validations.companyPhone && companyPhone !== ""}
                  // helperText={
                  //   !validations.companyPhone && companyPhone !== ""
                  //     ? "Please enter a valid 10-digit phone number"
                  //     : undefined
                  // }
                />
              </Box>
              <Box gridColumn={{ sm: "span 2" }}>
                <Typography className="label">Address</Typography>
                <TextField
                  // multiline
                  // minRows={3}
                  fullWidth
                  size="small"
                  value={address}
                  placeholder="Plot/House no./Apartment, Locality/Street Name"
                  onChange={(e) => {
                    handleChange("address", e.target.value);
                  }}
                  // error={!validations.address && address !== ""}
                  // helperText={
                  //   !validations.address && address !== ""
                  //     ? "Required"
                  //     : undefined
                  // }
                  // sx={{
                  //   textArea: {
                  //     px: 1.75,
                  //     py: 1,
                  //   },
                  // }}
                />
              </Box>

              <Box>
                <Typography className="label">State</Typography>
                <Autocomplete
                  loading={statesLoading}
                  size="small"
                  popupIcon={<KeyboardArrowDown />}
                  value={state}
                  onChange={(e, newValue) => handleChange("state", newValue)}
                  options={(statesData?.data?.constructor === Array
                    ? statesData.data
                    : []
                  ).map((el: any) => el.name)}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select..." />
                  )}
                />
              </Box>
              <Box>
                <Typography className="label">City/District</Typography>
                <Autocomplete
                  disabled={!state}
                  loading={citiesLoading}
                  size="small"
                  popupIcon={<KeyboardArrowDown />}
                  value={district}
                  onChange={(e, newValue) => handleChange("district", newValue)}
                  options={(citiesData?.data?.constructor === Array
                    ? citiesData.data
                    : []
                  ).map((el: any) => el.name)}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select..." />
                  )}
                />
              </Box>
              <Box gridColumn={{ sm: "span 2" }}>
                <Typography className="label">Pincode</Typography>
                <TextField
                  required
                  fullWidth
                  size="small"
                  value={pincode}
                  placeholder="Pincode"
                  onChange={(e) => {
                    if (e.target.value[0] !== "0") {
                      if (/^[0-9]{0,6}$/.test(e.target.value))
                        handleChange("pincode", e.target.value);
                    }
                  }}
                  // error={pincode[0] === "0"}
                  // helperText={
                  //   !validations.pincode
                  //     ? "Please enter a valid 6-digit pincode"
                  //     : undefined
                  // }
                />
              </Box>
            </Box>
          )
        )}
        {!isEditMode && step === 1 && (
          <Box
            sx={{
              maxWidth: { xs: 280, sm: 560 },
              mx: "auto",
              py: 2,
              display: "grid",
              gridTemplateColumns: { sm: "1fr 1fr" },
              gap: 3,
            }}
          >
            <Box>
              <Typography className="label">First Name</Typography>
              <TextField
                required
                fullWidth
                size="small"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => {
                  if (/^[a-zA-Z]{0,20}$/.test(e.target.value))
                    handleChange("firstName", e.target.value);
                }}
                // error={!validations.firstName}
                // helperText={!validations.firstName ? "Required" : undefined}
              />
            </Box>
            <Box>
              <Typography className="label">Last Name</Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => {
                  if (/^[a-zA-Z]{0,20}$/.test(e.target.value))
                    handleChange("lastName", e.target.value);
                }}
                // error={!validations.lastName}
                // helperText={!validations.lastName ? "Required" : undefined}
              />
            </Box>
            <Box>
              <Typography className="label">Phone Number</Typography>
              <TextField
                required
                fullWidth
                size="small"
                placeholder="Phone"
                value={userPhone}
                inputProps={{ maxLength: 10 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+91</InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  if (/^[0-9]{0,10}$/.test(e.target.value))
                    handleChange("userPhone", e.target.value);
                }}
                // error={!validations.userPhone}
                // helperText={
                //   !validations.userPhone
                //     ? "Please enter a valid 10-digit phone number"
                //     : undefined
                // }
              />
            </Box>
            <Box>
              <Typography className="label">Email</Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="Email"
                value={email}
                onChange={(e) => {
                  handleChange("email", e.target.value.toLowerCase());
                }}
                // error={!validations.email}
                // helperText={
                //   !validations.email ? "Please enter a valid email" : undefined
                // }
              />
            </Box>
            <Box
              sx={{
                gridColumn: { sm: "span 2" },
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: { sm: "1fr 1fr" },
                  gap: 3,
                  mb: 1,
                }}
              >
                <Box>
                  <Typography className="label">Password</Typography>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type={showPass ? "text" : "password"}
                    autoComplete="new-password"
                    error={
                      !validations.password &&
                      password !== "" &&
                      Boolean(validatePassword())
                    }
                  />
                </Box>
                <Box>
                  <Typography className="label">Confirm Password</Typography>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Confirm"
                    value={confirmPass}
                    onChange={(e) => setConfirmPass(e.target.value)}
                    type={showPass ? "text" : "password"}
                    autoComplete="new-password"
                    error={
                      !validations.password &&
                      confirmPass !== "" &&
                      (confirmPass === "" || Boolean(validatePassword()))
                    }
                  />
                </Box>
              </Box>
              <Typography
                variant="body2"
                sx={{
                  py: 1,
                  px: 1.75,
                  color: !validations.password
                    ? "error.main"
                    : "text.secondary",
                  gridColumn: { sm: "span 2" },
                }}
              >
                {!validations.password
                  ? validatePassword()
                  : validatePassword()
                  ? "Use 8 or more characters with a mix of letters, numbers & symbols"
                  : null}
              </Typography>
              <FormControlLabel
                label="Show password"
                control={
                  <Checkbox
                    size="small"
                    checked={showPass}
                    onChange={(e) => setShowPass(e.target.checked)}
                  />
                }
              />
            </Box>
          </Box>
        )}

        {step === (isEditMode ? 1 : 2) && (
          <Box
            sx={{
              maxWidth: 560,
              mx: "auto",
              "& .table": {
                borderCollapse: "collapse",
                width: 1,
                fontSize: 14,
                lineHeight: "16px",
                "& td": {
                  py: 1.25,
                  px: 2,
                },
                "& .bold": {
                  fontWeight: 500,
                },
                "& .header": {
                  px: 2,
                  py: 1,
                  position: "relative",
                  "& td": {
                    position: "absolute",
                    verticalAlign: "middle",
                    bgcolor: (theme: any) => theme.customColors.header,
                    width: 1,
                    borderRadius: "4px",
                    fontSize: 16,
                    fontWeight: 600,
                    "& span": {
                      display: "inline-block",
                      transform: "translateY(1px)",
                    },
                  },
                },
                "& .first > td": {
                  pt: 9,
                },
                "& .last > td": {
                  pb: 3,
                },
              },
            }}
          >
            <table className="table">
              <tbody>
                {[
                  { header: "Company Info", onEdit: () => setStep(0) },
                  { label: "Company Name", value: input.companyName },
                  { label: "GST Number", value: input.gstNumber },
                  { label: "Phone Number", value: input.companyPhone },
                  { label: "Address", value: input.address },
                  { label: "District", value: input.district },
                  { label: "State", value: input.state },
                  { label: "Pincode", value: input.pincode },

                  ...(!isEditMode
                    ? [
                        { header: "User Info", onEdit: () => setStep(1) },
                        { label: "First Name", value: input.firstName },
                        { label: "Last Name", value: input.lastName },
                        { label: "Phone Number", value: input.userPhone },
                        { label: "Email", value: input.email },
                      ]
                    : []),
                ].map(({ header, onEdit, label, value }, i, arr) => {
                  const isFirst = arr[i - 1]?.header;
                  const isLast = !arr[i + 1] || arr[i + 1].header;

                  return (
                    <tr
                      key={i}
                      className={
                        header
                          ? "header"
                          : `${isFirst ? "first" : ""} ${isLast ? "last" : ""}`
                      }
                    >
                      {header ? (
                        <td colSpan={2}>
                          <span>{header}</span>
                          <IconButton
                            sx={{ ml: 1.5 }}
                            children={<EditOutlined />}
                            color="primary"
                            size="small"
                            onClick={onEdit}
                          />
                        </td>
                      ) : (
                        <>
                          <td>{label}</td>
                          <td className="bold">{value}</td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {step !== 0 && (
          <Button
            variant="outlined"
            onClick={handleBack}
            sx={{ borderRadius: 20 }}
          >
            Back
          </Button>
        )}
        <Button
          onClick={handleNext}
          variant={disabled ? "outlined" : "contained"}
          disableElevation
          disabled={disabled}
          sx={{ borderRadius: 20 }}
        >
          {step === steps.length - 1 ? "Save" : "Next"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDialog;
