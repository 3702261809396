import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AccountCircleOutlined,
  AdminPanelSettingsOutlined,
  Apps,
  CarRental,
  Construction,
  EvStation,
  Menu,
  PrecisionManufacturing,
} from "@mui/icons-material";
import {
  Box,
  Hidden,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Switch,
  Tooltip,
} from "@mui/material";
import { switchTo } from "actions";
import {
  getDarkModePreference,
  getEnabledSections,
  getSectionPermissions,
  GlobalState,
  setMasterView,
} from "utils";
import AccountPopover from "./AccountPopover";
import NotificationsPopover from "./NotificationsPopover";
import SearchBox from "./SearchBox";
import { useLocation } from "react-router-dom";
// import storageManager from "utils/storageManager";

interface Props {
  handleDrawerToggle: () => void;
}

const AppBar: React.FC<Props> = ({ handleDrawerToggle }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const state = useSelector((state: GlobalState) => state);
  const isDarkMode = getDarkModePreference(state);
  const enabledSections = getEnabledSections(state);
  // const getSections = getSectionPermissions(state);
  const { company, masterView, activeSection, appBar, user } = state.global;

  const showMasterViewSwitch =
    company.name === "REVOS" &&
    activeSection === "charger" &&
    ["/", "/chargers", "/vendors", "/users", "/bookings", "/reports"].includes(
      location.pathname
    );

  const [sectionAnchor, setSectionAnchor] = useState<HTMLButtonElement | null>(
    null
  );
  const [accountAnchor, setAccountAnchor] = useState<HTMLButtonElement | null>(
    null
  );

  console.log(enabledSections);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateRows: `min-content auto`,
      }}
    >
      <Box
        sx={{
          zIndex: 9,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: {
            xs: 1.5,
            md: 3,
          },
          py: {
            xs: 1,
            md: 1.75,
          },
          bgcolor: {
            xs: isDarkMode ? "#080808" : "#232728",
            md: "background.paper",
          },
          color: {
            xs: "white",
            md: "inherit",
          },
          boxShadow: "0 1px 4px rgba(21, 34, 50, 0.08)",
        }}
      >
        <Box display="flex" alignItems="center">
          <IconButton
            sx={{ mr: 1.5, display: { md: "none" } }}
            onClick={handleDrawerToggle}
            color="inherit"
          >
            <Menu />
          </IconButton>
          <Hidden mdDown>
            <Box ml={1}>
              <SearchBox />
            </Box>
          </Hidden>
        </Box>
        <Box
          display="grid"
          gridTemplateColumns={`repeat(${
            showMasterViewSwitch ? "4" : "3"
          }, auto)`}
          gap={{ xs: 1, md: 1.5 }}
          alignItems="center"
        >
          {/* <Link to="/developer" style={{ color: "inherit" }}>
          <IconButton color="inherit">
            <Code />
          </IconButton>
        </Link> */}
          {showMasterViewSwitch && (
            <Tooltip title="Master View: Switch this on to see all data for the Bolt.Earth platform irrespective of the company">
              <Box
                sx={{
                  px: 1,
                  mr: 1,
                  borderRadius: 10,
                  height: 40,
                  border: (theme) => `1px solid ${theme.palette.divider}`,
                  bgcolor: "background.paper",
                  color: "text.primary",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <label
                  htmlFor="master-view"
                  style={{ height: 24, cursor: "pointer", marginRight: 4 }}
                >
                  <AdminPanelSettingsOutlined color="inherit" />
                </label>
                <Switch
                  id="master-view"
                  size="small"
                  checked={masterView}
                  onChange={() => setMasterView(!masterView)}
                />
              </Box>
            </Tooltip>
          )}
          <Box>
            <Tooltip title="Switch to...">
              <IconButton
                color="inherit"
                onClick={(e) => setSectionAnchor(e.currentTarget)}
              >
                <Apps />
              </IconButton>
            </Tooltip>
            <Popover
              open={Boolean(sectionAnchor)}
              anchorEl={sectionAnchor}
              onClose={() => setSectionAnchor(null)}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
              sx={{
                "& .MuiPaper-root": {
                  boxShadow: (theme) => theme.shadows[3],
                  borderRadius: 2,
                },
                "& .MuiList-root": {
                  p: 0.5,
                },
                "& .MuiListItemButton-root": {
                  borderRadius: 1.5,
                  "&.active": {
                    bgcolor: (theme) => theme.palette.action.selected,
                  },
                },
                "& .MuiListItemIcon-root": {
                  minWidth: 38,
                  color: "text.secondary",
                },
              }}
            >
              <List>
                {getSectionPermissions(
                  "retail",
                  state.global.user.permissions
                ) && (
                  <ListItemButton
                    className={activeSection === "retail" ? "active" : ""}
                    onClick={() => {
                      setSectionAnchor(null);
                      dispatch(switchTo("retail"));
                    }}
                  >
                    <ListItemIcon>
                      <PrecisionManufacturing />
                    </ListItemIcon>
                    <ListItemText
                      primary="Vehicle Management"
                      // secondary="Vehicle Management"
                    />
                  </ListItemButton>
                )}
                {getSectionPermissions(
                  "rental",
                  state.global.user.permissions
                ) && (
                  <ListItemButton
                    className={activeSection === "rental" ? "active" : ""}
                    onClick={() => {
                      setSectionAnchor(null);
                      dispatch(switchTo("rental"));
                    }}
                  >
                    <ListItemIcon>
                      <CarRental />
                    </ListItemIcon>
                    <ListItemText
                      primary="Fleet Management"
                      // secondary="Vehicle Lease Management"
                    />
                  </ListItemButton>
                )}
                {getSectionPermissions(
                  "charger",
                  state.global.user.permissions
                ) && (
                  <ListItemButton
                    className={activeSection === "charger" ? "active" : ""}
                    onClick={() => {
                      setSectionAnchor(null);
                      dispatch(switchTo("charger"));
                    }}
                  >
                    <ListItemIcon>
                      <EvStation />
                    </ListItemIcon>
                    <ListItemText
                      primary="Charger Management"
                      // secondary="Charger Management"
                    />
                  </ListItemButton>
                )}
                {(user?._id === "5cfa43c6a7b11b00073f9656" ||
                  user?._id === "624c0baac6cf8c0007883ae8" ||
                  company?.name === "REVOS") &&
                  getSectionPermissions(
                    "housekeeping",
                    state.global.user.permissions
                  ) && (
                    <ListItemButton
                      className={
                        activeSection === "housekeeping" ? "active" : ""
                      }
                      onClick={() => {
                        setSectionAnchor(null);
                        dispatch(switchTo("housekeeping"));
                      }}
                    >
                      <ListItemIcon>
                        <Construction />
                      </ListItemIcon>
                      <ListItemText
                        primary="Housekeeping"
                        // secondary="In-House Management"
                      />
                    </ListItemButton>
                  )}
              </List>
            </Popover>
          </Box>
          <NotificationsPopover />
          <Box>
            <Tooltip title="Account">
              <IconButton
                onClick={(e) => setAccountAnchor(e.currentTarget)}
                color="inherit"
              >
                <AccountCircleOutlined />
              </IconButton>
            </Tooltip>
            <AccountPopover
              anchor={accountAnchor}
              setAnchor={setAccountAnchor}
              isDarkMode={isDarkMode}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          height: appBar.open ? { xs: 56, md: 94 } : 0,
          zIndex: 8,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          bgcolor: "background.paper",
          backdropFilter: "saturate(180%) blur(20px)",
          boxShadow: "0px 4px 8px 0px #00000012",
          ...(!appBar.open
            ? {
                overflow: "hidden",
              }
            : {}),
        }}
      >
        {appBar.content}
      </Box>
    </Box>
  );
};

export default AppBar;
