import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { appBar, authorizedFetch } from "utils";
import { TICKETS_URL } from "utils/constants";
import AddTicketDialog from "./AddTicketDialog";
import UpdateTicketDialog from "./UpdateTicketDialog";
import AppBar from "./AppBar";
import List from "./List";

const filterLabels: any = {
  sortParams: {
    "Date Created (Newest First)": "-1|createdAt",
    "Date Created (Oldest First)": "1|createdAt",
  },
  status: {
    All: "",
    "Service Initiated": "SERVICE_INITIATED",
    "Firmware Updated": "FIRMWARE_UPDATED",
    "RCA Initiated": "RCA_INITIATED",
    "Charger Fixed": "CHARGER_FIXED",
    "Charger Replaced": "CHARGER_REPLACED",
    Closed: "CLOSED",
  },
};

const ChargerTickets = () => {
  const [createDialog, setCreateDialog] = useState(false);
  const [updateDialog, setUpdateDialog] = useState({
    open: false,
    data: {},
  });
  const [filters, setFilters] = useState<any>({
    sortParams: filterLabels.sortParams["Date Created (Newest First)"],
    status: filterLabels.status["All"],
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");

  const first = pageSize;
  const skip = pageSize * (page - 1);

  const { sortParams, status } = filters;

  const sort = sortParams.split("|")[0];
  const sortBy = sortParams.split("|")[1];

  const ticketsURL = `${TICKETS_URL}/ticket/chargers/all?first=${first}&skip=${skip}&sort=${sort}&status=${status}&search=${search}`;

  const { isLoading, data, refetch } = useQuery(
    ["GET TICKETS /chargers/all", first, skip, sort, sortBy, status, search],
    () => authorizedFetch(ticketsURL)
  );

  useEffect(() => {
    setPage(1);
  }, [filters, search, pageSize]);

  useEffect(() => {
    appBar.open(
      <AppBar {...{ setCreateDialog, filters, setFilters, filterLabels }} />
    );
    return () => {
      appBar.close();
    };
  }, [filters]);

  return (
    <>
      <List
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setSearch={setSearch}
        isLoading={isLoading}
        data={data}
        setUpdateDialog={setUpdateDialog}
      />
      <AddTicketDialog
        open={createDialog}
        handleClose={() => {
          setCreateDialog(false);
        }}
        refetch={refetch}
      />
      <UpdateTicketDialog
        open={updateDialog.open}
        handleClose={() => {
          setUpdateDialog({ ...updateDialog, open: false });
        }}
        refetch={refetch}
        data={updateDialog.data}
      />
    </>
  );
};

export default ChargerTickets;
