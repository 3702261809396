import { NotificationsOutlined } from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import { setGlobalState } from "actions";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "utils";
// import { TRIGGER_URL } from "utils/constants";
import { TRIGGER_WS_URL } from "utils/constants";
import NotificationsDrawer from "./NotificationsDrawer";
const io = require("socket.io-client/dist/socket.io");

const NotificationsPopover = () => {
  const {
    company,
    // notifications,
    lastReadNotification,
    activeSection,
  } = useSelector((state: GlobalState) => state.global);

  const isCMS = activeSection === "charger";

  const dispatch = useDispatch();

  const socketRef = useRef<any>(null);

  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState<any>([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (socketRef.current === null) {
      socketRef.current = io(`${TRIGGER_WS_URL}/triggers`, {
        path: "/socket.io",
        transports: ["websocket", "xhr-polling", "jsonp-polling", "polling"],
      });
      const socket = socketRef.current;

      socket.emit("roomId", company?.id);
      socket.on("notification", (data: any) => {
        console.log(data);

        setLoading(false);

        if (!data) return;

        if (
          !lastReadNotification ||
          moment(lastReadNotification).valueOf() <
            moment(data.timestamp).valueOf()
        )
          setUnreadCount((prev: any) => prev + 1);

        // notifications.unshift(data.responseData)
        // dispatch(setGlobalState({ notifications }))
        setNotifications((prev: any) => {
          prev.unshift(data);
          return prev;
        });
        // });
        // socket.on("error", (err: any) => {
        //   setLoading(false);
        //   console.error(err);
        // });
      });
      // socket.emit(
      //   "REQUEST:ALL_NOTIFICATIONS",
      //   JSON.stringify({ companyId: company?.id })
      // );
      setInterval(() => {
        setLoading(false);
      }, 5000);
      // socket.on("RESPONSE:ALL_NOTIFICATIONS", (data: any) => {
      //   setLoading(false);

      //   if (!data?.responseData) return;

      //   if (
      //     !lastReadNotification ||
      //     moment(lastReadNotification).valueOf() <
      //       moment(data.responseData.time).valueOf()
      //   )
      //     setUnreadCount((prev: any) => prev + 1);

      //   // notifications.unshift(data.responseData)
      //   // dispatch(setGlobalState({ notifications }))
      //   setNotifications((prev: any) => {
      //     prev.unshift(data.responseData);
      //     return prev;
      //   });
      // });
      // socket.on("error", (err: any) => {
      //   setLoading(false);
      //   console.error(err);
      // });
    }

    return () => {
      socketRef.current?.close();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (notifications.length === 0 || anchor === null) return;

    dispatch(setGlobalState({ lastReadNotification: notifications[0].time }));
    setUnreadCount(0);

    // eslint-disable-next-line
  }, [anchor, notifications]);

  return (
    <Box>
      <Drawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        anchor="top"
        PaperProps={{
          sx: { width: 1 },
        }}
      >
        <NotificationsDrawer
          notifications={isCMS ? [] : notifications}
          onClose={() => setDrawerOpen(false)}
        />
      </Drawer>
      <Tooltip title="Notifications">
        <IconButton
          onClick={(e) => setAnchor(e.currentTarget)}
          color="inherit"
          sx={{
            "& .MuiBadge-badge": {
              color: "white",
            },
          }}
        >
          <Badge
            color="primary"
            overlap="circular"
            badgeContent={isCMS ? 0 : unreadCount}
          >
            <NotificationsOutlined />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        sx={{
          "& .header": {
            marginLeft: "0.5em",
            fontSize: "1.25em",
            fontWeight: 500,
          },
          "& .MuiPaper-root": {
            boxShadow: (theme) => theme.shadows[3],
            p: 0,
            borderRadius: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: 280,
            maxWidth: 320,
            height: 400,
          },
        }}
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        {loading ? (
          <CircularProgress size={24} />
        ) : isCMS || notifications.length === 0 ? (
          "No new notifications"
        ) : (
          <Box
            sx={{
              width: 1,
              height: 1,
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                width: 1,
                p: 1,
                px: 1.5,
                pb: 0.5,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: 14,
                }}
              >
                Notifications
              </Typography>
              <Button
                sx={{ textTransform: "none" }}
                size="small"
                color="info"
                onClick={() => {
                  setAnchor(null);
                  setDrawerOpen(true);
                }}
              >
                View All
              </Button>
            </Box>
            <Divider />
            <List dense disablePadding sx={{ flexGrow: 1, overflow: "auto" }}>
              {notifications
                ?.slice(0, 10)
                .map((notification: any, i: number) => (
                  <ListItemButton key={i}>
                    <ListItemText
                      primary={notification?.message || ""}
                      secondary={moment(notification?.timestamp).calendar()}
                      secondaryTypographyProps={{
                        textAlign: "right",
                      }}
                    />
                  </ListItemButton>
                ))}
            </List>
          </Box>
        )}
      </Popover>
    </Box>
  );
};

export default NotificationsPopover;
