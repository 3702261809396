import {
  CloudUploadOutlined,
  DescriptionOutlined,
  Download,
  HighlightOff,
  InfoOutlined,
} from "@mui/icons-material";
import ErrorIcon from "assets/images/icons/error.svg";
import {
  alpha,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  MenuItem,
  Select,
  Skeleton,
  Step,
  StepButton,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { ReactComponent as SuccessIcon } from "assets/images/success.svg";
import { queryClient } from "index";
import { useEffect, useRef, useState } from "react";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { AUTH_URL, RETAIL_URL } from "utils/constants";
import saveAs from "file-saver";
import RedAsterisk from "components/RedAsterisk";
const ExcelJS = require("exceljs");

const BulkUploadDialog = ({ open, handleClose, setTab }: any) => {
  const [isDragActive, setDragActive] = useState(0);
  const [step, setStep] = useState(0);
  const steps = ["Basic Details", "File Upload", "Review"];
  const [input, setInput] = useState({
    company: "",
    vehicleModel: "",
    primaryPrerequisite: "",
    secondaryPrerequisite: "",
    minor: 0,
    major: 0,
    patch: 0,
    model: "",
    warranty: 420,
    assign: false,
  });
  const [companies, setCompanies] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);
  const [primaryPrerequisites, setPrimaryPrerequisites] = useState([]);
  const [secondaryPrerequisites, setSecondaryPrerequisites] = useState([]);

  const [file, setFile] = useState<null | File>(null);
  const [tempFile, setTempFile] = useState<null | File>(null);
  const fileInputRef = useRef<any>();

  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);

  const [latestUploadInfo, setLatestUploadInfo] = useState<any>(null);

  useEffect(() => {
    if (!file && fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }, [file]);

  useEffect(() => {
    if (!open) {
      setInput({
        company: "",
        vehicleModel: "",
        primaryPrerequisite: "",
        secondaryPrerequisite: "",
        minor: -1,
        major: 0,
        patch: -1,
        model: "",
        warranty: 420,
        assign: false,
      });
      setStep(0);
      setFile(null);
      setUploaded(false);
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      authorizedFetch(`${AUTH_URL}/company/list`)
        .then((res) => setCompanies(res?.data))
        .catch((err) => console.error(err));
    }
  }, [open]);

  useEffect(() => {
    if (input.company) {
      authorizedFetch(
        `${RETAIL_URL}/inventory/ota/vehicle-models?companyId=${input.company}`
      )
        .then((res) => setVehicleModels(res?.data))
        .catch((err) => console.error(err));
    }
  }, [input.company]);

  const getPrimaryPreRequisite = () => {
    authorizedFetch(
      `${RETAIL_URL}/inventory/ota/prerequisites?companyId=${input.company}&vehicleModel=${input.vehicleModel}&type=PRIMARY`
    )
      .then((res) => setPrimaryPrerequisites(res?.data))
      .catch((err) => console.error(err));
  };

  const getSecondaryPreRequisite = () => {
    authorizedFetch(
      `${RETAIL_URL}/inventory/ota/prerequisites?companyId=${input.company}&vehicleModel=${input.vehicleModel}&type=SECONDARY`
    )
      .then((res) => setSecondaryPrerequisites(res?.data))
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (input.company && input.vehicleModel) {
      getPrimaryPreRequisite();
      getSecondaryPreRequisite();
    }
    // eslint-disable-next-line
  }, [input.company, input.vehicleModel]);

  console.log(input);

  function handleUpload() {
    if (!file) return;

    const fileSize = file.size / 1000 / 1000; // in MB

    if (fileSize > 10) {
      snackbar.error("File size should be less than 10MB");
    } else {
      const formData = new FormData();
      formData.append("uploadFile", file, file.name);

      setUploading(true);

      let requestBody: any = {
        company: input.company,
        vehicleModel: input.vehicleModel,
        primary: input.primaryPrerequisite,
        ...(input?.model !== "SLED" && {
          secondary: input.secondaryPrerequisite,
        }),
        model: input.model,
        "hardware[major]": input.major,
        "hardware[minor]": input.minor,
        "hardware[patch]": input.patch,
        warranty: input.warranty,
        assign: input.assign,
      };

      for (const key in requestBody) {
        formData.append(key, requestBody[key]);
      }

      authorizedFetch(
        `${RETAIL_URL}/assembly/component/bulkAddComponents?componentType=controller`,
        {
          method: "POST",
          onlyBody: formData,
        }
      )
        .then((res) => {
          setUploading(false);
          if (res?.meta?.success) {
            setUploaded(true);
            getLatestUploadInfo();
            queryClient.resetQueries("getUploadHistoryData");
          } else {
            snackbar.error("An error occurred");
            setTempFile(file);
            setFile(null);
          }
        })
        .catch((err) => {
          console.error(err);
          setUploading(false);
          snackbar.error("An error occurred");
          setTempFile(file);
          setFile(null);
        });
    }
  }

  function getLatestUploadInfo() {
    setLatestUploadInfo(null);
    authorizedFetch(
      `${RETAIL_URL}/assembly/component/bulkAdd/history?first=1&skip=0`
    )
      .then((res) => {
        if (res.meta.success) {
          setLatestUploadInfo(res?.data?.[0]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const companyObject: any = companies.filter(
    (company: any) => company._id === input.company
  )?.[0];
  const companyName = companyObject?.name;

  const modelObject: any = vehicleModels.filter(
    (model: any) => model._id === input.vehicleModel
  )?.[0];
  const vehicleModelName = modelObject?.name;

  const primaryFirmware: any = primaryPrerequisites.filter(
    (version: any) => version._id === input.primaryPrerequisite
  )?.[0];
  const primaryVersion = primaryFirmware?.version;

  const secondaryFirmware: any = secondaryPrerequisites.filter(
    (version: any) => version._id === input.secondaryPrerequisite
  )?.[0];
  const secondaryVersion = secondaryFirmware?.version;

  const template: any = {
    Company: [companyName],
    "Vehicle Model": [vehicleModelName],
    "Hardware Version": [input.major + "." + input.minor + "." + input.patch],
    "Primary F/W Version": [primaryVersion],
    "Secondary F/W Version": [secondaryVersion],
    "Warranty (Days)": [input.warranty],
    "Cluster Type": [input.model],
    "Serial Number": "",
    "MAC ID": "",
  };

  function getReferenceFile(type: "xlsx" | "csv") {
    setLoader(true);
    const localFilePath = "/docs/Cluster.example.xlsx";

    fetch(localFilePath)
      .then((response) => response.blob())
      .then((blob) => {
        setLoader(false);
        snackbar.success("Sample CSV file has been successfully downloaded");
        if (type === "xlsx") {
          const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
          const workbook = new ExcelJS.Workbook();
          const sheet = workbook.addWorksheet("Vehicle Upload");
          sheet.columns = Object.keys(template).map((el) => ({
            header: el,
            width: 15,
          }));

          const modelSheet = workbook.addWorksheet("Models");
          modelSheet.columns = [
            {
              key: "model",
              width: 15,
            },
          ];
          modelSheet.state = "veryHidden";

          Object.keys(template).forEach((key, i) => {
            const column = alphabet[i];

            sheet.getCell(`${column}1`).alignment = {
              horizontal: "center",
            };
            sheet.getCell(`${column}1`).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFFFF00" },
            };

            if (template[key].length) {
              if (key === "Model") {
                const modelArray = template[key];
                modelSheet.addRows(
                  modelArray.map((el: any, i: number) => ({ model: el }))
                );
              }

              const cellValue = template[key][0]; // Assuming you want to use the first value in the array.

              for (let rowIndex = 2; rowIndex <= 2001; rowIndex++) {
                sheet.getCell(`${column}${rowIndex}`).value = cellValue;
              }
            }
          });

          workbook.xlsx.writeBuffer().then(function (data: any) {
            var blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            saveAs(blob, "Bulk Upload Format.xlsx");
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
        snackbar.error("Error downloading sample file");
      });
  }

  return (
    <Dialog maxWidth="lg" fullWidth open={open} onClose={handleClose}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        Bulk Upload
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent sx={{ pb: "16px !important" }}>
        <Stepper
          sx={{ my: 4, mx: "auto", maxWidth: 534 }}
          activeStep={step}
          // nonLinear
          alternativeLabel
        >
          {steps.map((label, i) => (
            <Step key={i}>
              <StepButton onClick={() => setStep(i)}>{label}</StepButton>
            </Step>
          ))}
        </Stepper>
        {step === 0 && (
          <Box
            sx={{
              maxWidth: { xs: 280, sm: 560 },
              mx: "auto",
              py: 2,
              display: "grid",
              gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
              gap: 3,
            }}
          >
            <Box>
              <Typography className="label">
                Company
                <RedAsterisk />
              </Typography>
              <Select
                fullWidth
                value={input.company}
                onChange={(e: any) => {
                  setInput({ ...input, company: e.target.value });
                }}
                displayEmpty
                sx={{ maxHeight: 40, borderRadius: 10 }}
              >
                <MenuItem disabled value={""}>
                  {<em>Choose a company</em>}
                </MenuItem>
                {companies?.map((model: any) => (
                  <MenuItem key={model._id} value={model._id}>
                    {model.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box>
              <Typography className="label">
                Model
                <RedAsterisk />
              </Typography>
              <Select
                fullWidth
                disabled={input.company?.length === 0}
                value={input.vehicleModel}
                onChange={(e: any) => {
                  const modelObject: any = vehicleModels.filter(
                    (model: any) => model._id === e.target.value
                  )?.[0];
                  const components = modelObject?.components;
                  const filteredCluster = components.filter(
                    (el: any) => el.category === "CONTROLLER"
                  )?.[0];
                  const clusterKey = filteredCluster?.modelId?.[0]?.key;
                  setInput({
                    ...input,
                    model: clusterKey,
                    vehicleModel: e.target.value,
                  });
                  console.log(clusterKey, "modelObject");
                }}
                displayEmpty
                sx={{ maxHeight: 40, borderRadius: 10 }}
              >
                <MenuItem disabled value={""}>
                  {<em>Select a model</em>}
                </MenuItem>
                {vehicleModels?.map((model: any) => (
                  <MenuItem key={model._id} value={model._id}>
                    {model.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box>
              <Typography className="label">
                Cluster Type
                <RedAsterisk />
              </Typography>
              <TextField
                fullWidth
                InputProps={{ sx: { borderRadius: 10 } }}
                size="small"
                placeholder="Auto fill"
                value={input.model}
                disabled
              />
            </Box>
            <Box>
              <Typography className="label">
                Hardware Version
                <RedAsterisk />
              </Typography>
              <Box sx={{ display: "flex" }}>
                <TextField
                  // type="number"
                  disabled={input.vehicleModel?.length === 0}
                  InputProps={{ sx: { borderRadius: 10, margin: "3px 4px" } }}
                  placeholder="0"
                  value={input.major > 0 ? input.major : ""}
                  size="small"
                  onChange={(e: any) => {
                    if (
                      /^[1-9]{0,1}$/.test(e.target.value) &&
                      e.target.value.length
                    ) {
                      setInput({ ...input, major: parseInt(e.target.value) });
                    } else {
                      setInput({ ...input, major: 0 });
                    }
                  }}
                  sx={{ margin: "3px 4px" }}
                />
                <TextField
                  // type="number"
                  disabled={input.vehicleModel?.length === 0}
                  InputProps={{ sx: { borderRadius: 10, margin: "3px 4px" } }}
                  placeholder="0"
                  value={input.minor > -1 ? input.minor : ""}
                  size="small"
                  onChange={(e: any) => {
                    if (
                      /^[0-9]{0,1}$/.test(e.target.value) &&
                      e.target.value.length
                    ) {
                      console.log(e.target.value, "minor");
                      setInput({ ...input, minor: parseInt(e.target.value) });
                    } else {
                      setInput({ ...input, minor: -1 });
                    }
                  }}
                  sx={{ margin: "3px 4px" }}
                />
                <TextField
                  // type="number"
                  disabled={input.vehicleModel?.length === 0}
                  InputProps={{ sx: { borderRadius: 10, margin: "3px 4px" } }}
                  placeholder="0"
                  value={input.patch > -1 ? input.patch : ""}
                  size="small"
                  onChange={(e: any) => {
                    console.log(e.target.value, "val");
                    if (
                      /^[0-9]{0,1}$/.test(e.target.value) &&
                      e.target.value?.length
                    ) {
                      setInput({ ...input, patch: parseInt(e.target.value) });
                    } else {
                      console.log("setting input");
                      setInput({ ...input, patch: -1 });
                    }
                  }}
                />
              </Box>
            </Box>
            <Box>
              <Typography className="label">
                Primary F/W Version
                <RedAsterisk />
              </Typography>
              <Select
                fullWidth
                value={input.primaryPrerequisite}
                disabled={
                  input.company?.length === 0 ||
                  input.vehicleModel?.length === 0
                }
                onChange={(e: any) =>
                  setInput({
                    ...input,
                    primaryPrerequisite: e.target.value,
                  })
                }
                displayEmpty
                sx={{ maxHeight: 40, borderRadius: 10 }}
              >
                <MenuItem disabled value={""}>
                  {<em>Select Primary F/W Version</em>}
                </MenuItem>
                {primaryPrerequisites?.map((model: any) => (
                  <MenuItem key={model._id} value={model._id}>
                    {model.version}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            {input?.model !== "SLED" && (
              <Box>
                <Typography className="label">
                  Secondary F/W Version
                  <RedAsterisk />
                </Typography>
                <Select
                  fullWidth
                  value={input.secondaryPrerequisite}
                  disabled={
                    input.company?.length === 0 ||
                    input.vehicleModel?.length === 0
                  }
                  onChange={(e: any) =>
                    setInput({
                      ...input,
                      secondaryPrerequisite: e.target.value,
                    })
                  }
                  displayEmpty
                  sx={{ maxHeight: 40, borderRadius: 10 }}
                >
                  <MenuItem disabled value={""}>
                    {<em>Select Secondary F/W Version</em>}
                  </MenuItem>
                  {secondaryPrerequisites?.map((model: any) => (
                    <MenuItem key={model._id} value={model._id}>
                      {model.version}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}

            <Box>
              <Typography className="label">
                Warranty Details
                <RedAsterisk />
              </Typography>
              <Select
                fullWidth
                value={input.warranty}
                onChange={(e: any) => {
                  setInput({ ...input, warranty: e.target.value });
                }}
                displayEmpty
                sx={{ maxHeight: 40, borderRadius: 10 }}
              >
                <MenuItem value={input.warranty}>14 MONTHS/420 DAYS</MenuItem>
              </Select>
            </Box>
          </Box>
        )}

        {step === 1 && (
          <Box>
            <Box
              sx={{
                mb: 3,
                py: 6,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                border: `${file ? "1px solid" : "2px dashed"}`,
                borderColor: (theme) =>
                  isDragActive > 0
                    ? alpha(theme.palette.primary.main, 0.5)
                    : theme.customColors.border,
                backgroundColor: (theme) =>
                  isDragActive > 0
                    ? alpha(theme.palette.primary.main, 0.05)
                    : "inherit",
                borderRadius: 2.5,
              }}
              {...(!file
                ? {
                  onDrop: (e: any) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setDragActive((prev) => prev - 1);

                    if (
                      e.dataTransfer.files &&
                      e.dataTransfer.files.length > 0
                    ) {
                      const file = e.dataTransfer.files[0];
                      if (
                        [
                          ".csv",
                          "text/csv",
                          "application/csv",
                          "text/comma-separated-values",
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        ].includes(file.type)
                      ) {
                        setFile(file);
                      }
                    }
                  },
                  onDragOver: (e: any) => {
                    e.stopPropagation();
                    e.preventDefault();
                  },
                  onDragEnter: (e: any) => setDragActive((prev) => prev + 1),
                  onDragLeave: (e: any) => setDragActive((prev) => prev - 1),
                  onMouseOut: (e: any) => setDragActive(0),
                }
                : {})}
            >
              {console.log(file, "file")}
              <Box>
                <Typography variant="h2" mb={uploaded ? 4 : 2}>
                  {file
                    ? uploaded
                      ? "Your file has been successfully uploaded"
                      : uploading
                        ? "Uploading..."
                        : "Ready for Upload"
                    : "Drop your XLSX file here, or"}{" "}
                  {!file && (
                    <label htmlFor="bulk-upload-input">
                      <Link
                        sx={{
                          cursor: "pointer",
                        }}
                      >
                        browse
                      </Link>
                    </label>
                  )}
                  <input
                    ref={fileInputRef}
                    id="bulk-upload-input"
                    type="file"
                    accept=".csv, text/csv, application/csv, text/comma-separated-values, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    hidden
                    onChange={(e) => {
                      if (e.target.files && e.target.files.length > 0) {
                        const file = e.target.files[0];
                        setFile(file);
                      }
                    }}
                  />
                </Typography>
                {!uploaded &&
                  (file ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h6">
                        {file.name} ({(file.size / 1000).toFixed(1)} kB)
                        {!uploading && (
                          <Link
                            sx={{
                              ml: 1.25,
                              fontWeight: 600,
                              textDecoration: "none",
                              cursor: "pointer",
                              color: (theme) => theme.palette.error.light,
                            }}
                            onClick={() => {
                              setFile(null);
                            }}
                          >
                            Remove File
                          </Link>
                        )}
                      </Typography>
                    </Box>
                  ) : (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      component="ul"
                    >
                      <li>The file should be in .xlsx or format</li>
                      <li>Size should be less than 10 MB</li>
                      <li>
                        Make sure that your file has the correct column header
                        as per the sample file
                      </li>
                    </Typography>
                  ))}
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              {file && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "start",
                    margin: "30px 26px",
                  }}
                >
                  <Checkbox
                    checked={input.assign}
                    onChange={(e: any) =>
                      setInput({
                        ...input,
                        assign: e.target.checked,
                      })
                    }
                  />
                  <Typography
                    className="label"
                    sx={{ margin: "12px 15px" }}
                  >
                    {companyName === undefined
                      ? "Assign to OEM"
                      : `Assign to ${companyName}`}
                  </Typography>
                </Box>
              )}
              <Button
                variant="contained"
                size="large"
                sx={{
                  textTransform: "none",
                  borderRadius: 5,
                  py: 1.5,
                  px: 6.5,
                  height: 50,
                  visibility: file ? "visible" : "hidden",
                  marginLeft: "auto",
                }}
                onClick={() => {
                  if (uploaded) {
                    handleClose();
                    setTimeout(() => {
                      setFile(null);
                      setUploaded(false);
                    }, 1000);
                  } else {
                    handleUpload();
                    setStep(2);
                  }
                }}
                disabled={uploading}
              >
                {uploaded
                  ? "Done"
                  : input.assign === true
                    ? "Assign & Upload"
                    : "Upload"}
              </Button>
            </Box>
            <br />
            <br />
            <Box>
              {!uploaded && !file && (
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "2fr 3fr",
                    gap: 2.5,
                    mb: 6,
                    "& > .MuiCard-root": {
                      border: 1,
                      borderRadius: 2.5,
                      borderColor: (theme) => theme.customColors.border,
                    },
                  }}
                >
                  <Card elevation={0}>
                    <CardActionArea sx={{ height: 1 }}>
                      <CardContent sx={{ px: 3, py: 2, height: 1 }}>
                        <Typography variant="h6" mb={1.5}>
                          Need help?
                        </Typography>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 56px",
                            gap: 4.5,
                          }}
                        >
                          <Typography variant="body2" color="text.secondary">
                            Getting started with bulk upload? Click here to view
                            the bulk upload user guide.
                          </Typography>
                          <Box
                            sx={{
                              mb: 1,
                              width: 56,
                              height: 56,
                              bgcolor: (theme) => theme.palette.primary.main,
                              borderRadius: 1.5,
                              color: "#FFF",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <InfoOutlined color="inherit" fontSize="large" />
                          </Box>
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                  <Card
                    sx={{
                      border: 1,
                      borderRadius: 2.5,
                      borderColor: (theme) => theme.customColors.border,
                    }}
                    elevation={0}
                  >
                    <CardContent sx={{ px: 3, py: 2 }}>
                      <Typography variant="h6" mb={1.5}>
                        Download Sample File
                      </Typography>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "1fr 56px",
                          gap: 4.5,
                        }}
                      >
                        <Box>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            mb={1.5}
                          >
                            Bulk upload of vehicles are required to be in the
                            format specified in the sample file provided. Please
                            download the file and edit it with your vehicle's
                            information.
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              "& .MuiButton-root": {
                                textTransform: "none",
                              },
                            }}
                          >
                            <Button
                              size="small"
                              variant="outlined"
                              startIcon={<Download fontSize="small" />}
                              sx={{ mr: 1.5 }}
                              onClick={() => {
                                saveAs(
                                  "/docs/Telematics.example.xlsx",
                                  "Telematics example.xlsx"
                                );
                                snackbar.success(
                                  "Sample CSV file has been successfully downloaded"
                                );
                              }}
                            >
                              Telematics Sample XLSX
                            </Button>
                            <Button
                              size="small"
                              variant="outlined"
                              startIcon={<Download fontSize="small" />}
                              onClick={() => getReferenceFile("xlsx")}
                            >
                              Cluster Sample XLSX
                            </Button>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            mb: 1,
                            width: 56,
                            height: 56,
                            bgcolor: (theme) => theme.palette.primary.main,
                            borderRadius: 1.5,
                            color: "#FFF",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <DescriptionOutlined
                            color="inherit"
                            fontSize="large"
                          />
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              )}
            </Box>
          </Box>
        )}
        {step === 2 && (
          <Box>
            <Box sx={{ mb: 1.5 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column", // Ensure it's in a row
                  height: "20vh",
                }}
              >
                {file ? (
                  uploaded ? (
                    <SuccessIcon />
                  ) : uploading ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress size={80} />
                      </Box>
                    </>
                  ) : (
                    <DescriptionOutlined
                      color="primary"
                      sx={{ fontSize: 80 }}
                    />
                  )
                ) : uploaded ? (
                  <CloudUploadOutlined color="inherit" sx={{ fontSize: 80 }} />
                ) : (
                  <>
                    <br />
                    <img
                      src={ErrorIcon}
                      alt="Error icon"
                      style={{ width: 70 }}
                    />
                    <Typography style={{ color: "#FA5F55", fontSize: "25px" }}>
                      <b>Unexpected error!</b>
                    </Typography>
                  </>
                )}
                <Typography variant="h2" mb={uploaded ? 4 : 2}>
                  {file
                    ? uploaded
                      ? "Your file is successfully uploaded"
                      : uploading
                        ? "Uploading..."
                        : "Ready for Upload"
                    : "An unexpected error occured, Please try again"}{" "}
                  <br />
                  <br />
                  {file &&
                    (uploading ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="h6">
                          {/* {"Please wait while we upload & validate file"}
                          <br/> */}
                          {file.name} ({(file.size / 1000).toFixed(1)} kB)
                        </Typography>
                      </Box>
                    ) : (
                      ""
                    ))}
                  {!file && (
                    <label htmlFor="bulk-upload-input">
                      {tempFile && (
                        <Typography variant="h6">
                          {tempFile.name} ({(tempFile.size / 1000).toFixed(1)}{" "}
                          kB){", "}
                          <Link
                            sx={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setStep(1);
                            }}
                          >
                            Retry Upload
                          </Link>
                        </Typography>
                      )}
                    </label>
                  )}
                  <input
                    ref={fileInputRef}
                    id="bulk-upload-input"
                    type="file"
                    accept=".csv, text/csv, application/csv, text/comma-separated-values, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    hidden
                    onChange={(e) => {
                      if (e.target.files && e.target.files.length > 0) {
                        const file = e.target.files[0];
                        setFile(file);
                      }
                    }}
                  />
                </Typography>
              </div>
            </Box>
            {file && uploaded && (
              <Box
                sx={{
                  mb: 3,
                  p: 3,
                  border: (theme) => `1px solid ${theme.customColors.border}`,
                  borderRadius: 2.5,
                  display: "grid",
                  gridTemplateColumns: "35px 1fr",
                  gap: 3,
                }}
              >
                <DescriptionOutlined color="primary" sx={{ fontSize: 35 }} />
                <Box>
                  <Typography variant="h6" mb={0.5}>
                    {file?.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" mb={1}>
                    {(file.size / 1000).toFixed(1)} kB
                  </Typography>
                  <Divider />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "end",
                    }}
                  >
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "auto auto auto",
                        gap: 7.5,
                        "& > .MuiBox-root": {
                          py: 2,
                          display: "flex",
                          flexDirection: "column",
                          "& .label": {
                            color: (theme) => theme.palette.text.primary,
                            fontWeight: 600,
                            mb: 1,
                          },
                          "& .value": {
                            color: (theme) => theme.palette.text.secondary,
                            "&.red": {
                              color: (theme) => theme.palette.error.light,
                            },
                          },
                        },
                      }}
                    >
                      <Box>
                        <span className="label">Total Items</span>
                        <span className="value">
                          {!latestUploadInfo ? (
                            <Skeleton />
                          ) : (
                            latestUploadInfo?.passedEntries +
                            latestUploadInfo?.failedEntries
                          )}
                        </span>
                      </Box>
                      <Box>
                        <span className="label">Successful</span>
                        <span className="value">
                          {!latestUploadInfo ? (
                            <Skeleton />
                          ) : (
                            latestUploadInfo?.passedEntries
                          )}
                        </span>
                      </Box>
                      <Box>
                        <span className="label">Failed</span>
                        <span className="value red">
                          {!latestUploadInfo ? (
                            <Skeleton />
                          ) : (
                            latestUploadInfo?.failedEntries
                          )}
                        </span>
                      </Box>
                    </Box>
                    <Button
                      variant="outlined"
                      size="large"
                      sx={{ textTransform: "none", borderRadius: "100px" }}
                      onClick={() => {
                        handleClose();
                        setTimeout(() => {
                          setFile(null);
                          setUploaded(false);
                        }, 1000);
                        setTab(2);
                      }}
                    >
                      View all uploads
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                mb: 6,
              }}
            >
              {uploaded && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "start",
                    margin: "22px 20px",
                    width: "100%",
                  }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    sx={{
                      textTransform: "none",
                      borderRadius: 5,
                      py: 1.5,
                      px: 6.5,
                      visibility: file ? "visible" : "hidden",
                      marginLeft: "auto",
                    }}
                    onClick={() => {
                      if (uploaded) {
                        handleClose();
                        setTimeout(() => {
                          setFile(null);
                          setUploaded(false);
                        }, 1000);
                      } else {
                        handleUpload();
                        setStep(2);
                      }
                      setTab(2);
                    }}
                    disabled={uploading}
                  >
                    {!uploaded
                      ? "Upload"
                      : input.assign === true
                        ? "Assign & Close"
                        : input.assign === false
                          ? "Done"
                          : "Upload"}
                  </Button>
                </div>
              )}
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {step !== 1 && step !== 2 && (
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{ borderRadius: 10 }}
          >
            Cancel
          </Button>
        )}
        {step !== 1 && step !== 2 && (
          <Button
            sx={{ borderRadius: 10 }}
            onClick={() => {
              setStep(step + 1);
            }}
            variant={"contained"}
            disableElevation
            disabled={
              input.company?.length === 0 ||
              input.vehicleModel?.length === 0 ||
              input.primaryPrerequisite?.length === 0 ||
              (input?.model !== "SLED" &&
                input.secondaryPrerequisite?.length === 0) ||
              // !input.major ||
              input.major < 1 ||
              input.patch < 0 ||
              input.minor < 0
            }
          >
            Next
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default BulkUploadDialog;
