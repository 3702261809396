import { Close } from "@mui/icons-material";
import {
  alpha,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Drawer,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import RangeSelector from "components/RangeSelector";
import moment from "moment";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";

const FiltersDrawer = ({
  open,
  onClose,
  filters,
  setFilters,
  filterLabels,
  setLocations,
}: any) => {
  const { locations } = filters;

  const [tab, setTab] = useState(0);

  const [search, setSearch] = useState("");

  const url = `https://geocode.revos.in/place/cities?country=India&state=&input=`;
  const { isLoading, data } = useQuery(["getCities"], () =>
    fetch(url, {
      headers: {
        cache: "true",
        // master: masterView,
      },
    }).then((res) => res.json())
  );

  const list = useMemo(() => {
    return (data?.data?.constructor === Array ? data.data : []).filter(
      (el: any) =>
        !locations.includes(el.name) &&
        el.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [data, search, locations]);

  const maxListLength = 5;

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      PaperProps={{
        sx: {
          height: 1,
          width: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 2,
          pb: 0.5,
        }}
      >
        <Typography variant="h3">Filters</Typography>
        <Button
          sx={{ textTransform: "none" }}
          color="inherit"
          size="small"
          startIcon={<Close />}
          onClick={onClose}
        >
          Close
        </Button>
      </Box>
      <Divider />
      <Box
        sx={{
          flexGrow: 1,
          display: "grid",
          gridTemplateColumns: "120px 1fr",
          fontSize: 14,
        }}
      >
        <Box
          sx={{
            background: alpha("#ECECEC", 0.5),
            borderRight: 1,
            borderColor: (theme) => theme.customColors.border,
            "& > .MuiBox-root": {
              py: 3,
              px: 1.5,
              borderLeft: 4,
              borderColor: "transparent",
              cursor: "pointer",
              "&.active": {
                background: "white",
                borderColor: "#62D16A",
              },
            },
          }}
        >
          {["Access", "Operational Chargers", "Location", "Time"].map(
            (el, i) => (
              <Box
                key={i}
                className={tab === i ? "active" : ""}
                onClick={() => setTab(i)}
              >
                {el}
              </Box>
            )
          )}
        </Box>
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {tab === 0 &&
            Object.keys(filterLabels.access).map((el, i) => (
              <FormControlLabel
                key={i}
                sx={{
                  mb: 1,
                  "& .MuiFormControlLabel-label": {
                    fontSize: 14,
                  },
                }}
                control={
                  <Checkbox
                    size="small"
                    checked={filters.access.includes(el)}
                    onChange={(e) =>
                      setFilters((prev: any) => ({
                        ...prev,
                        access: e.target.checked
                          ? [...filters.access, el]
                          : filters.access.filter((el2: string) => el2 !== el),
                      }))
                    }
                  />
                }
                label={el}
              />
            ))}
          {tab === 1 &&
            Object.keys(filterLabels.health).map((el, i) => (
              <FormControlLabel
                key={i}
                sx={{
                  mb: 1,
                  "& .MuiFormControlLabel-label": {
                    fontSize: 14,
                  },
                }}
                control={
                  <Checkbox
                    size="small"
                    checked={filters.health.includes(el)}
                    onChange={(e) =>
                      setFilters((prev: any) => ({
                        ...prev,
                        health: e.target.checked
                          ? [...filters.health, el]
                          : filters.health.filter((el2: string) => el2 !== el),
                      }))
                    }
                  />
                }
                label={el}
              />
            ))}
          {tab === 2 && (
            <>
              <TextField
                sx={{ mb: 1.5 }}
                size="small"
                placeholder="Search..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {locations.length > 0 && (
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {locations.map((el: string, i: number) => (
                    <Chip
                      key={i}
                      size="small"
                      sx={{
                        mr: 1,
                        mb: 1,
                        lineHeight: "1em",
                        color: (theme) => theme.customColors.text.grey,
                        bgcolor: (theme) =>
                          theme.customColors.background.lightGrey,
                      }}
                      label={el}
                      onDelete={() =>
                        setLocations(
                          locations.filter((el2: string) => el !== el2)
                        )
                      }
                      // deleteIcon={<Close fontSize="small" />}
                    />
                  ))}
                  {locations.length > 1 && (
                    <Chip
                      size="small"
                      color="secondary"
                      variant="outlined"
                      onClick={() => setLocations([])}
                      label="Clear All"
                    />
                  )}
                </Box>
              )}
              {isLoading ? (
                "Loading..."
              ) : (
                <>
                  {list.slice(0, maxListLength).map((el: any, i: number) => (
                    <FormControlLabel
                      key={i}
                      sx={{
                        mb: 1,
                        "& .MuiFormControlLabel-label": {
                          fontSize: 14,
                        },
                      }}
                      control={
                        <Checkbox
                          size="small"
                          checked={filters.locations.includes(el.name)}
                          onChange={(e) =>
                            setFilters((prev: any) => ({
                              ...prev,
                              locations: e.target.checked
                                ? [...filters.locations, el.name]
                                : filters.locations.filter(
                                    (el2: string) => el2 !== el.name
                                  ),
                            }))
                          }
                        />
                      }
                      label={el.name}
                    />
                  ))}
                  <Box mt={1}>
                    {list.length === 0
                      ? `No locations found${search ? ` for "${search}"` : ""}`
                      : ""}
                    {list.length > maxListLength
                      ? `+${list.length - maxListLength} more...`
                      : ""}
                  </Box>
                </>
              )}
            </>
          )}
          {tab === 3 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Select range
              <RangeSelector
                initialRange="Last 7 days"
                setRange={(range: any) => {
                  setFilters((prev: any) => ({
                    ...prev,
                    time: range,
                  }));
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          py: 3,
          px: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow: "0px -3px 12px rgba(0, 0, 0, 0.05)",
        }}
      >
        <Button
          sx={{
            textTransform: "none",
            borderRadius: 3,
          }}
          color="inherit"
          onClick={() =>
            setFilters({
              access: [],
              health: [],
              locations: [],
              time: [moment().subtract({ months: 1 }), moment()],
            })
          }
        >
          Clear all
        </Button>
        <Button
          sx={{
            textTransform: "none",
            borderRadius: 3,
            px: 3.5,
          }}
          variant="contained"
          onClick={onClose}
        >
          Apply Filters
        </Button>
      </Box>
    </Drawer>
  );
};

export default FiltersDrawer;
