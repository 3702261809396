import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Search from "components/Search";
import TableComponent from "components/Table";
import { queryClient } from "index";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
// import { useSelector } from "react-redux";
import { authorizedFetch, setLoader, snackbar, titleCase } from "utils";
import { LEASE_URL } from "utils/constants";

const AssignDialog = ({ open, handleClose, vendor }: any) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  // const { company } = useSelector((state: GlobalState) => state.global);

  // const URL = `${RETAIL_URL}/distribution/vehicles-assigned?first=${pageSize}&skip=${
  //   pageSize * (page - 1)
  // }&search=${search}`;

  // const url = `${RETAIL_URL}/distribution/distributor/${
  //   company.id
  // }/assigned-vehicles?first=${pageSize}&skip=${pageSize * (page - 1)}`;
  // &search=${search}&orderBy=createdAt&ascending=false&status=UNASSIGNED`;
  // const { isLoading, data } = useQuery(
  //   ["getVehicles", page, pageSize, search],
  //   () => authorizedFetch(URL)
  // );

  const vehiclesUrl = `${LEASE_URL}/company/vendors/vehicles-unassigned?first=${pageSize}&skip=${
    pageSize * (page - 1)
  }&search=${search}&sort=${-1}&sortBy=${"createdAt"}`;
  const { isLoading, data: vehiclesData } = useQuery(
    ["getVendorVehicles", page, pageSize, search],
    () => authorizedFetch(vehiclesUrl)
  );

  useEffect(() => {
    if (open) setSelectedRows([]);
  }, [open]);

  function handleSave() {
    setLoader(true);
    let url = `${LEASE_URL}/company/vendors/assign-vendor`;
    authorizedFetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        distributor: vendor?._id,
        vehicles: selectedRows,
      },
    })
      .then((res) => {
        setLoader(false);
        if (res.msg.includes("Vendor assigned")) {
          snackbar.success(`Vehicles Assigned`);
          queryClient.resetQueries("getVehicles");
          queryClient.resetQueries("getVendorVehicles");
          queryClient.resetQueries("getVendorVehicle");
          handleClose();
        } else {
          console.error(res);
          snackbar.error(res.msg);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
        snackbar.error(err.msg);
      });
  }

  let rowCount = vehiclesData?.data?.count || 0;
  let rows =
    vehiclesData?.data?.vehicles?.constructor === Array
      ? vehiclesData.data.vehicles
      : [];

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Assign Vehicles
        <Search
          handleSearch={(input) => setSearch(input)}
          persist
          enableClear
        />
      </DialogTitle>
      <DialogContent sx={{ mt: 1, mb: -3 }}>
        <TableComponent
          idKey="_id"
          px={0}
          small
          smallPagination
          selectable
          selectOnClick
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          loading={isLoading}
          rowCount={rowCount}
          serverSidePagination={true}
          activePage={page}
          activePageSize={pageSize}
          onPageChange={(value) => setPage(value)}
          onPageSizeChange={(value) => setPageSize(value)}
          rows={rows}
          columns={[
            { key: "vin", label: "VIN" },
            { key: "model.name", label: "Model" },
            // {
            //   key: "createdAt",
            //   label: "Created At",
            //   format: (value) => moment(value).format("MMM DD, YYYY HH:mm"),
            // },
            {
              key: "health",
              label: "Health",
              Render: ({ health }: any) => {
                return (
                  <Avatar
                    variant="status"
                    className={
                      health === "active"
                        ? ""
                        : health === "moderate"
                        ? "yellow"
                        : health === "critical"
                        ? "red"
                        : "grey"
                    }
                  >
                    {health ? titleCase(health) : "Inactive"}
                  </Avatar>
                );
              },
            },
          ]}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={handleSave}>
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignDialog;
