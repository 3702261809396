import { HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { queryClient } from "index";
import { useEffect, useState } from "react";
import { authorizedFetch, setLoader, snackbar, titleCase } from "utils";
import { RETAIL_URL } from "utils/constants";

const MarkStatusDialog = ({ open, handleClose, data, type }: any) => {
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (open) return;
    setMessage("");
  }, [open]);

  function onSave() {
    setLoader(true);
    authorizedFetch(`${RETAIL_URL}/inventory/component/updateInventoryStatus`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        id: data?._id,
        status: type === "available" ? "IN_STOCK" : "UNHEALTHY",
        remark: message,
      },
    })
      .then((res) => {
        setLoader(false);
        snackbar.success(`OEM marked as ${type}`);
        queryClient.resetQueries("getOems");
        queryClient.resetQueries("getOemData");
        handleClose();
      })
      .catch((err) => {
        setLoader(false);
        snackbar.error(`Error: Failed to marked as ${type}`);
      });
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <Box display="flex" alignItems="center">
            Mark {titleCase(type)}
            {data?.specs?.macId && (
              <>
                <Divider flexItem orientation="vertical" sx={{ mx: 1.5 }} />
                <Typography color="text.secondary">
                  {data?.specs?.macId}
                </Typography>
              </>
            )}
          </Box>
          <IconButton
            children={<HighlightOff />}
            color="inherit"
            onClick={handleClose}
            sx={{ transform: "translate(8px, -8px)" }}
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mt={2}>
          <Typography className="label">Reason</Typography>
          <TextField
            fullWidth
            multiline
            rows={5}
            placeholder={`State the reason for marking this as ${type}.`}
            helperText="Min 10 characters & max 500 characters"
            value={message}
            onChange={(e: any) => {
              if (e.target.value.length <= 500) setMessage(e.target.value);
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={onSave}
          disabled={message.length <= 10}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MarkStatusDialog;
