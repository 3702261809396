import { CheckCircleOutline, ErrorOutline } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import CircularLoader from "components/CircularLoader";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { authorizedFetch } from "utils";
import { TICKETS_URL } from "utils/constants";

function TicketStatus({
  tab,
  qrData,
  selectedCharger,
  open,
  setIsChargerOpen,
  isChargerOpen,
}: any) {
  const chargerTicketURL = `${TICKETS_URL}/ticket/chargers/all?search=${
    tab === 0 ? qrData : selectedCharger
  }&first=10&skip=0`;

  const { isLoading: chargerOpenLoading, data: isChargerOpenData } = useQuery(
    ["Get chargerTicketStatus", qrData, selectedCharger],
    () => authorizedFetch(chargerTicketURL),
    {
      enabled: open,
    }
  );

  console.log(qrData);

  useEffect(() => {
    if (isChargerOpenData?.data?.tickets) {
      setIsChargerOpen(
        isChargerOpenData?.data?.tickets?.some(
          (e: any) => e.status !== "CLOSED"
        )
      );
    } else {
      setIsChargerOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChargerOpenData]);

  return (
    <Box
      gridColumn={{ sm: "span 2" }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: isChargerOpen ? "#fc030310" : "#00800120",
        borderRadius: 2,
        height: isChargerOpen ? 60 : 40,
        px: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: isChargerOpen ? "top" : "center",
          justifyContent: "center",
          gap: 1,
        }}
      >
        {chargerOpenLoading ? (
          <CircularLoader />
        ) : isChargerOpen ? (
          <ErrorOutline sx={{ fontSize: 14, mt: isChargerOpen ? 0.5 : 0 }} />
        ) : (
          <CheckCircleOutline sx={{ fontSize: 14 }} />
        )}

        <Typography sx={{ fontSize: 14 }}>
          {isChargerOpen
            ? "Charger already has an open ticket. Please close the existing ticket before creating a new one."
            : "Charger has no open tickets."}
        </Typography>
      </Box>
    </Box>
  );
}

export default TicketStatus;
