import {
  Avatar,
  Box,
  Paper,
  Typography,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { alpha } from "@mui/system";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import {
  getDarkModePreference,
  GlobalState,
  authorizedFetch,
} from "../../../utils";
import RangePicker from "components/RangePicker";
import { LEASE_URL } from "utils/constants";
import { format, sub } from "date-fns";

import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import moment from "moment";

interface Props {}

const Leases: React.FC<Props> = () => {
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );
  const theme = useTheme();

  const [chartData, setChartData] = useState<any>(null);

  const [range, setRange] = useState<any>([
    sub(new Date(), { months: 1 }),
    new Date(),
  ]);

  let dateFrom = format(range[0], "yyyy-MM-dd");
  let dateTo = format(range[1], "yyy-MM-dd");

  function parseData(array: any[], key: string) {
    array = array.sort(
      (a: any, b: any) => moment(a.date).valueOf() - moment(b.date).valueOf()
    );
    let chartData = array.reduce((acc: any, cur: any) => {
      let day = moment(cur.date).format("MMM D, yyyy");
      const existing = acc.find((el: any) => el.x === day);
      if (existing) {
        existing.y += cur[key];
      } else {
        acc.push({ x: day, y: cur[key] });
      }
      return acc;
    }, []);
    return chartData;
  }

  const bookingsUrl = `${LEASE_URL}/company/bookings/count?timeframe=custom&starttime=${dateFrom}&endtime=${dateTo}`;

  const { isLoading: bookingsLoading, data: bookingsData } = useQuery(
    ["company/bookings/count", dateFrom, dateTo],
    () => authorizedFetch(bookingsUrl)
  );

  useEffect(() => {
    if (bookingsData?.data?.aggregatedBookings?.constructor === Array) {
      let chartData = parseData(
        bookingsData.data.aggregatedBookings,
        "totalBookings"
      );
      setChartData(chartData);
    }
  }, [bookingsData]);

  console.log(chartData?.map((el: any) => el.x.split(",")[0]));

  return (
    <Paper
      sx={{
        p: { xs: 2, md: 3 },
        pb: { xs: 3, md: 4 },
        gridColumn: { lg: "span 5" },
        height: 490,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        mb={{ xs: 3, md: 4 }}
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent={{ sm: "space-between" }}
        alignItems={{ sm: "center" }}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h6">Leases</Typography>
          <Avatar variant="label" sx={{ ml: "11px" }}>
            {bookingsLoading ? (
              <CircularProgress size={13} />
            ) : (
              bookingsData?.data?.count
            )}
          </Avatar>
        </Box>
        <Box
          sx={{
            my: { xs: 1, sm: 0 },
            alignSelf: { xs: "center", sm: "unset" },
          }}
        >
          <RangePicker range={range} setRange={setRange} />
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          flexGrow: 1,
          width: 1,
          minHeight: 0,
        }}
      >
        {bookingsLoading ? (
          <CircularProgress />
        ) : (
          <Line
            data={(canvas) => {
              let color = theme.customColors.green;
              let labels = chartData?.map((el: any) => el.x.split(",")[0]);

              return {
                labels,
                datasets: [
                  {
                    fill: true,
                    data: chartData?.map((el: any) => el.y),
                    borderColor: color,
                    borderWidth: 2,
                    backgroundColor: alpha(
                      theme.customColors.green,
                      isDarkMode ? 0.1 : 0.2
                    ),
                    tension: 0,
                    pointRadius: 0,
                    pointHoverRadius: 4,
                    pointHoverBackgroundColor: "#fff",
                    pointHoverBorderWidth: 3,
                  },
                ],
              };
            }}
            options={{
              scales: {
                xAxis: {
                  // type: 'time',
                  grid: {
                    display: false,
                    tickWidth: 0,
                    tickLength: 16,
                    drawBorder: false,
                  },
                  ticks: {
                    autoSkip: false,
                    callback: function (
                      this: any,
                      value: any,
                      index: any,
                      values: any
                    ) {
                      const displayValue =
                        values.length < 9
                          ? this.getLabelForValue(value)
                          : index === values.length - 1
                          ? this.getLabelForValue(value)
                          : index % Math.round((values.length - 1) / 22.93) ===
                            0
                          ? this.getLabelForValue(value)
                          : "";
                      return displayValue;
                    },
                    color: theme.palette.text.secondary,
                  },
                },
                yAxis: {
                  ticks: {
                    color: theme.palette.text.secondary,
                  },
                  suggestedMin: 15,
                  suggestedMax: 25,
                  grid: {
                    borderDash: [10],
                    tickWidth: 0,
                    tickLength: 16,
                    drawBorder: false,
                  },
                },
              },
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  caretSize: 0,
                  mode: "index",
                  intersect: false,
                  yAlign: "center",
                  displayColors: false,
                  caretPadding: 16,
                  titleFont: {
                    weight: "400",
                  },
                  bodyFont: {
                    weight: "500",
                  },
                },
              },
              interaction: {
                mode: "index",
                intersect: false,
              },
            }}
          />
        )}
      </Box>
    </Paper>
  );
};

export default Leases;
