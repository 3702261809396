import { HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Slider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  authorizedFetch,
  getEnabledSections,
  GlobalState,
  makeCamelCase,
  setLoader,
  snackbar,
  titleCase,
} from "utils";
import { AUTH_URL, getSectionName, pageIDs } from "utils/constants";

const EditPermsDialog = ({ open, handleClose, user, refresh }: any) => {
  const enabledSections = useSelector((state: GlobalState) =>
    getEnabledSections(state),
  );
  const [superPerm, setSuperPerm] = useState("Read");
  const [sectionPerms, setSectionPerms] = useState<{ [key: string]: string }>({
    retail: "Read",
    rental: "Read",
    charger: "Read",
  });
  const obj = pageIDs.reduce((acc: any, cur) => {
    const section = cur.split(":")[0];
    let page = cur.split(":")[1].replace(/([A-Z])/g, " $1");
    page = page.charAt(0).toUpperCase() + page.slice(1);
    if (!acc[section]) acc[section] = {};
    acc[section][page] = "Read";
    return acc;
  }, {});
  const [pagePerms, setPagePerms] = useState<{ [key: string]: any }>(obj);

  useEffect(() => {
    if (!user?.permissions) return;

    let { permissions } = user;
    if (permissions.includes("dashboard:*")) setSuperPerm("Admin");
    else if (permissions.includes("dashboard:*:*:READ")) setSuperPerm("Read");
    else if (permissions.includes("dashboard:*:*:WRITE")) setSuperPerm("Write");
    else {
      setSuperPerm("Custom");
      enabledSections.forEach((section) => {
        if (permissions.includes(`dashboard:${section}:*`))
          setSectionPerms((prev) => ({ ...prev, [section]: "Admin" }));
        else if (permissions.includes(`dashboard:${section}:*:READ`))
          setSectionPerms((prev) => ({ ...prev, [section]: "Read" }));
        else if (permissions.includes(`dashboard:${section}:*:WRITE`))
          setSectionPerms((prev) => ({ ...prev, [section]: "Write" }));
        else if (
          permissions.some((el: string) => el.includes(`dashboard:${section}`))
        ) {
          Object.keys(pagePerms[section]).forEach((page) => {
            if (
              permissions.includes(
                `dashboard:${section}:${makeCamelCase(page)}:READ`,
              )
            )
              setPagePerms((prev) => {
                prev[section][page] = "Read";
                return prev;
              });
            else if (
              permissions.includes(
                `dashboard:${section}:${makeCamelCase(page)}:WRITE`,
              )
            )
              setPagePerms((prev) => {
                prev[section][page] = "Write";
                return prev;
              });
            else
              setPagePerms((prev) => {
                prev[section][page] = "No Access";
                return prev;
              });
          });
        } else setSectionPerms((prev) => ({ ...prev, [section]: "No Access" }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  function handleSubmit() {
    let permissions =
      user?.permissions.filter((el: string) => !el.startsWith("dashboard:")) ||
      []; // Keep existing non dashboard-related permissions
    if (superPerm !== "Custom") {
      switch (superPerm) {
        case "Read":
          permissions.push("dashboard:*:*:READ");
          break;
        case "Write":
          permissions.push("dashboard:*:*:WRITE");
          break;
        case "Admin":
          permissions.push("dashboard:*");
          break;
        default:
          break;
      }
    } else {
      enabledSections.forEach((section) => {
        if (sectionPerms[section] !== "Custom") {
          switch (sectionPerms[section]) {
            case "Read":
              permissions.push(`dashboard:${section}:*:READ`);
              break;
            case "Write":
              permissions.push(`dashboard:${section}:*:WRITE`);
              break;
            case "Admin":
              permissions.push(`dashboard:${section}:*`);
              break;
            default:
              break;
          }
        } else {
          Object.keys(pagePerms[section]).forEach((page) => {
            let perm = pagePerms[section][page];
            switch (perm) {
              case "Read":
                permissions.push(
                  `dashboard:${section}:${makeCamelCase(page)}:READ`,
                );
                break;
              case "Write":
                permissions.push(
                  `dashboard:${section}:${makeCamelCase(page)}:WRITE`,
                );
                break;
              default:
                break;
            }
          });
        }
      });
    }
    setLoader(true);
    authorizedFetch(
      `${AUTH_URL}/company/users/${user ? user["_id"] : ""}/permissions/update`,
      {
        method: "POST",
        body: { permissions },
        headers: {
          "Content-Type": "application/json",
        },
      },
    ).then((response) => {
      setLoader(false);
      if (response.status >= 400) {
        snackbar.error("An error occurred.");
      } else {
        snackbar.success("Updated permissions");
        handleClose();
        refresh();
      }
    });
  }

  let { firstName, lastName } = user;
  let name =
    firstName || lastName
      ? `${firstName ? titleCase(firstName) : ""} ${
          lastName ? titleCase(lastName) : ""
        }`
      : "-";

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box display="flex" alignItems="center">
          Edit
          <Divider flexItem orientation="vertical" sx={{ mx: 1.5 }} />
          <Typography color="textSecondary">{name}</Typography>
        </Box>
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent>
        <Box py={1}>
          <Box
            display="grid"
            gridTemplateColumns="auto 1fr"
            alignItems="center"
            gap={2}
          >
            <Typography sx={{ fontWeight: 600 }}>Permissions</Typography>
            <Select
              size="small"
              value={superPerm}
              onChange={(e) => setSuperPerm(e.target.value)}
            >
              {["Read", "Write", "Admin", "Custom"].map((option, i) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Collapse in={superPerm === "Custom"}>
            <Divider sx={{ mt: 2 }} />
            {enabledSections.map((section, i) => (
              <Box key={i}>
                <Box
                  my={1}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography sx={{ fontWeight: 500 }}>
                    {getSectionName(section)}
                  </Typography>
                  <Select
                    size="small"
                    value={sectionPerms[section]}
                    onChange={(e) =>
                      setSectionPerms((prev) => ({
                        ...prev,
                        [section]: e.target.value,
                      }))
                    }
                  >
                    {["No Access", "Read", "Write", "Admin", "Custom"].map(
                      (option, i) => (
                        <MenuItem key={i} value={option}>
                          {option}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </Box>
                <Collapse in={sectionPerms[section] === "Custom"}>
                  <Box
                    display="grid"
                    gridTemplateColumns="1fr 2fr"
                    alignItems="center"
                  >
                    <Box
                      gridColumn="span 2"
                      mt={1}
                      mb={0.5}
                      pl={13.5}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Typography variant="body2" fontWeight={500}>
                        No Access
                      </Typography>
                      <Typography variant="body2" fontWeight={500}>
                        Read
                      </Typography>
                      <Typography variant="body2" fontWeight={500}>
                        Write
                      </Typography>
                    </Box>
                    {Object.keys(pagePerms[section]).map((page, i) => (
                      <React.Fragment key={i}>
                        <Typography variant="body1" color="textSecondary">
                          {page}
                        </Typography>
                        <Box pl={3} pr={2}>
                          <Slider
                            size="medium"
                            color="secondary"
                            valueLabelDisplay="auto"
                            defaultValue={0}
                            valueLabelFormat={(value) =>
                              value === 0
                                ? "No Access"
                                : value === 1
                                ? "Read"
                                : "Write"
                            }
                            step={1}
                            marks
                            min={0}
                            max={2}
                            value={
                              pagePerms[section][page] === "No Access"
                                ? 0
                                : pagePerms[section][page] === "Read"
                                ? 1
                                : 2
                            }
                            onChange={(e, value) => {
                              let perm =
                                value === 0
                                  ? "No Access"
                                  : value === 1
                                  ? "Read"
                                  : "Write";
                              setPagePerms((prev) => {
                                let newPagePerms = Object.assign({}, prev);
                                newPagePerms[section][page] = perm;
                                return newPagePerms;
                              });
                            }}
                          />
                        </Box>
                      </React.Fragment>
                    ))}
                  </Box>
                </Collapse>
                <Divider />
              </Box>
            ))}
          </Collapse>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditPermsDialog;
