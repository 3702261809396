import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material";
import { queryClient } from "index";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { RETAIL_URL } from "utils/constants";

const DeleteDialog = ({ open, handleClose, onDelete, scm }: any) => {
    function handleDelete() {
        setLoader(true);
        let url = `${RETAIL_URL}/assembly/component/${scm?.specs.macId}/delete`;
        authorizedFetch(url, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                setLoader(false);
                if (res.message === "Deleted") {
                    snackbar.success(`Component deleted successfully`);
                    handleClose();
                    queryClient.resetQueries("getSCMData");
                    onDelete();
                } else {
                    snackbar.error(res.msg);
                    handleClose();
                    onDelete();
                }
            })
            .catch((err) => {
                setLoader(false);
                console.error(err);
                snackbar.error(`Error deleting Component`);
                handleClose();
                onDelete();
            });
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Delete Component?</DialogTitle>
            <DialogContent>
                <Typography sx={{ my: 1, mr: 5 }}>
                    Are you sure you want to delete component?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button variant="contained" onClick={handleDelete}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteDialog;
