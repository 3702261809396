import { Add, InfoOutlined, Sort } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  // Typography,
} from "@mui/material";
import Breadcrumbs from "components/Breadcrumbs";
import Search from "components/Search";
import TableComponent from "components/Table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { authorizedFetch, drawer } from "utils";
import { RETAIL_URL } from "utils/constants";
import AddDialog from "./AddDialog";
import DrawerContent from "./DrawerContent";

const Distribution = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [addDialog, setAddDialog] = useState(false);
  const [sortByAscending, setSortByAscending] = useState("false");

  const url = `${RETAIL_URL}/distribution/distributors?first=${pageSize}&skip=${
    pageSize * (page - 1)
  }&search=${search}&orderBy=createdAt&ascending=${sortByAscending}`;
  const { isLoading, data } = useQuery(
    ["getDistributors", page, pageSize, search, sortByAscending],
    () => authorizedFetch(url)
  );

  useEffect(() => {
    return () => {
      drawer.close();
    };
  }, []);

  let rows = data?.data?.constructor === Array ? data.data : [];
  let rowCount = data?.meta?.totalCount || 0;

  return (
    <Box>
      <AddDialog open={addDialog} handleClose={() => setAddDialog(false)} />
      <Box
        sx={{
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Breadcrumbs />
        <Button
          variant="contained"
          startIcon={<Add />}
          onClick={() => setAddDialog(true)}
        >
          Add Distributor
        </Button>
      </Box>
      <Paper
        sx={{
          width: 1,
          pt: 3,
          boxShadow: (theme) => theme.customShadows.small,
          borderRadius: 2,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            mb: 2,
            px: 3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Tooltip title="Sort By" placement="top" disableInteractive>
            <Select
              size="small"
              sx={{ mr: 2 }}
              value={sortByAscending}
              onChange={(e) => setSortByAscending(e.target.value)}
              startAdornment={<Sort sx={{ mr: 1 }} />}
              renderValue={(selected) =>
                selected === "false" ? "New to Old" : "Old to New"
              }
            >
              <MenuItem value="false">Created On: New to Old</MenuItem>
              <MenuItem value="true">Created On: Old to New</MenuItem>
            </Select>
          </Tooltip>
          {/* <Typography variant="h6">Distributors</Typography> */}
          <Search
            handleSearch={(input) => setSearch(input)}
            persist
            enableClear
          />
        </Box>
        <TableComponent
          loading={isLoading}
          rowCount={rowCount}
          serverSidePagination={true}
          activePage={page}
          activePageSize={pageSize}
          onPageChange={(value) => setPage(value)}
          onPageSizeChange={(value) => setPageSize(value)}
          rows={rows}
          columns={[
            { key: "name", label: "Distributor Name" },
            { key: "count", label: "Vehicle Count" },
            {
              key: "status",
              label: "Status",
              Render: ({ status }) => (
                <Avatar
                  variant="status"
                  className={status !== "ACTIVE" ? "yellow" : undefined}
                >
                  {status}
                </Avatar>
              ),
            },
            {
              key: "createdAt",
              label: "Created On",
              format: (value) => moment(value).format("MMM DD, YYYY HH:mm"),
            },
            {
              key: "actions",
              label: "Actions",
              Render: (row) => (
                <IconButton
                  size="small"
                  sx={{ color: (theme) => theme.customColors.action }}
                  children={<InfoOutlined fontSize="small" />}
                  onClick={() =>
                    drawer.open(<DrawerContent distributor={row} />)
                  }
                />
              ),
            },
          ]}
        />
      </Paper>
    </Box>
  );
};

export default Distribution;
