import { Download, History } from "@mui/icons-material";
import { Box, Button, Hidden, Typography } from "@mui/material";

const AppBar = ({ isDrawerOpen, onClick }: any) => {
  return (
    <Box
      sx={{
        width: 1,
        py: 3,
        px: { xs: 2, md: 7 },
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h2">Reports</Typography>
        <Hidden mdUp>
          {isDrawerOpen ? (
            <Button
              sx={{ textTransform: "none" }}
              startIcon={<History />}
              color="inherit"
              onClick={onClick}
            >
              View Downloads
            </Button>
          ) : (
            <Button
              sx={{ textTransform: "none" }}
              startIcon={<Download />}
              color="inherit"
              onClick={onClick}
            >
              Download New
            </Button>
          )}
        </Hidden>
      </Box>
    </Box>
  );
};

export default AppBar;
