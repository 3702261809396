import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Close, FileDownload, HighlightOff, Info } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import {
  drawer,
  getDarkModePreference,
  GlobalState,
  authorizedFetch,
  setLoader,
  snackbar,
} from "utils";

import { LEASE_URL, REPORTS_URL } from "utils/constants";
import { useMutation, useQuery } from "react-query";

import AddImageDialog from "./AddImageDialog";
import moment from "moment";

import PaymentDialog from "./PaymentDialog";
import saveAs from "file-saver";

const DrawerContent = ({ vehicle, refetchBookings, refetchStats }: any) => {
  const [deleteDialog, setDeleteDialog] = useState(false);
  // const [tab, setTab] = useState(0);
  const [table, setTable] = useState([
    { header: "Basic Details" },
    { label: "Vin", value: "" },
    { label: "User", value: "" },
    { label: "Start Time", value: "" },
    { label: "Type", value: "" },
  ]);

  const [imageDialog, setImageDialog] = useState({
    open: false,
    data: {},
    type: "",
  });

  const [startUrls, setStartUrls] = useState([]);
  const [endUrls, setEndUrls] = useState([]);

  const [paymentDialog, setPaymentDialog] = useState({
    open: false,
    data: {},
  });

  console.log(vehicle);

  useEffect(() => {
    if (vehicle) {
      setTable([
        { header: "Basic Details" },
        {
          label: "Amount",
          value: "₹ " + vehicle?.bookingAmount?.toFixed() || "₹0",
        },
        {
          label: "Start Time",
          value: moment(vehicle?.startTime).format("DD/MM/YY, hh:mm a") || "-",
        },
        {
          label: "End Time",
          value: moment(vehicle?.endTime).format("DD/MM/YY, hh:mm a") || "-",
        },
        { label: "Type", value: vehicle?.type },
        { header: "User Details" },
        {
          label: "Name",
          value: `${
            vehicle?.leasee?.firstName ? vehicle?.leasee?.firstName : "-"
          }`,
        },
        {
          label: "Email",
          value: `${vehicle?.leasee?.email ? vehicle?.leasee?.email : "-"}`,
        },
        {
          label: "Phone",
          value: `${
            vehicle?.leasee?.phone
              ? "+91 " + vehicle?.leasee?.phone.replace(/\D/g, "").slice(-10)
              : "-"
          }`,
        },
      ]);
    }
  }, [vehicle]);

  const [timeDifferenceStart, setTimeDifferenceStart] = useState<any>();
  const [timeDifferenceEnd, setTimeDifferenceEnd] = useState<any>();
  const isOpen = useSelector(
    (state: GlobalState) => state?.global?.drawer?.open
  );

  console.log(timeDifferenceEnd);

  useEffect(() => {
    if (isOpen === true) {
      const startCheck = setInterval(() => {
        let s: any = new Date(vehicle?.startTime)?.getTime();
        let n: any = new Date().getTime();

        let diffStart = Math.floor((s - n) / 1000 / 60);
        setTimeDifferenceStart(diffStart);
        // console.log(diffStart);
      }, 3000);
      return () => {
        clearInterval(startCheck);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (isOpen === true) {
      const endCheck = setInterval(() => {
        let s: any = new Date(vehicle?.endTime)?.getTime();
        let n: any = new Date().getTime();

        // console.log(s - n);

        let diffStart = Math.floor((s - n) / 1000 / 60);
        setTimeDifferenceEnd(diffStart);
        // console.log(diffStart);
      }, 3000);
      return () => {
        clearInterval(endCheck);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );

  function DeleteHandleClose() {
    setDeleteDialog(false);
  }

  const [viewDialog, setViewDialog] = useState({
    open: false,
    data: null,
  });

  const [cancelDialog, setCancelDialog] = useState({
    open: false,
    data: {},
  });

  const bookingURL = `${LEASE_URL}/company/vehicle/${vehicle?.vin}/booking/${vehicle?.id}`;

  const {
    isLoading: bookingLoading,
    data: bookingData,
    refetch: refetchBooking,
  } = useQuery(["getBooking", vehicle], () => authorizedFetch(bookingURL));

  useEffect(() => {
    if (bookingData) {
      let urlss: any = [];
      // eslint-disable-next-line
      bookingData?.data?.vehicleImages?.start?.map((el: any, i: any) => {
        urlss.push(el.url);
      });
      setStartUrls(urlss);

      let urlsss: any = [];
      // eslint-disable-next-line
      bookingData?.data?.vehicleImages?.end?.map((el: any) => {
        urlsss.push(el.url);
      });
      setEndUrls(urlsss);
    }
  }, [bookingData]);

  console.log(endUrls);

  useEffect(() => {
    if (timeDifferenceStart <= 0 && timeDifferenceStart > -1) {
      refetchBooking();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeDifferenceStart]);

  function downloadLeaseInvoice(individualLease: any) {
    setLoader(true);
    let request: any = authorizedFetch(
      `${REPORTS_URL}/v2/lease/invoice?format=pdf&id=${individualLease.id}&vin=${individualLease.vin}`
    );
    request
      .then((result: any) => {
        setLoader(false);
        if (result.file) {
          const fileURL: any = result.file;
          saveAs(
            fileURL,
            `${individualLease.id}_${moment(individualLease.bookingTime).format(
              "DD-MM-YYYY"
            )}.pdf`
          );
          snackbar.success("Downloaded lease invoice");
        } else if (result.message) {
          snackbar.error("Error downloading lease invoice");
          console.error(result);
        }
      })
      .catch((err: Error) => {
        console.error(err);
        setLoader(false);
        snackbar.error("Error downloading lease invoice");
      });
  }

  const BookingBox = ({ color, mainText, secText, status }: any) => {
    return (
      <>
        <Box
          width="100%"
          height="60px"
          sx={{
            background: color,
            borderRadius: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 1.5,
          }}
        >
          <Box>
            <Typography sx={{ fontSize: 14, fontWeight: "600" }}>
              {mainText}
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography sx={{ fontSize: 12, fontWeight: "400" }}>
                {secText}
              </Typography>
              {status === "BOOKED" || status === "ACTIVE" ? (
                <Tooltip title="Booking can only be started during the booked time">
                  <Info
                    sx={{
                      fontSize: 14,
                      opacity: 0.5,
                      ml: 0.5,
                      color: "#9d9e9d",
                    }}
                  />
                </Tooltip>
              ) : (
                ""
              )}
            </Box>
          </Box>

          <Box>
            {status === "BOOKED" ? (
              <Button
                variant="outlined"
                size="small"
                sx={{
                  background: "white",
                  mr: 1,
                  maxHeight: 30,
                  fontSize: 12,
                }}
                disabled={
                  new Date(bookingData?.data?.startTime).getTime() >
                    new Date().getTime() ||
                  new Date(bookingData?.data?.endTime).getTime() <
                    new Date().getTime()
                }
                onClick={() => {
                  setImageDialog({
                    open: true,
                    data: vehicle,
                    type: "start",
                  });
                }}
              >
                Start
              </Button>
            ) : (
              ""
            )}
            {status === "ACTIVE" ? (
              <Button
                variant="outlined"
                size="small"
                sx={{
                  background: "white",
                  mr: 1,
                  maxHeight: 30,
                  fontSize: 12,
                }}
                onClick={() => {
                  setImageDialog({
                    open: true,
                    data: vehicle,
                    type: "end",
                  });
                }}
                // disabled={
                //   new Date(bookingData?.data?.startTime).getTime() >
                //     new Date().getTime() ||
                //   new Date(bookingData?.data?.endTime).getTime() <
                //     new Date().getTime()
                // }
              >
                End
              </Button>
            ) : (
              ""
            )}
            {status === "PAYMENT_INITIALISED" ? (
              <Button
                variant="outlined"
                size="small"
                sx={{
                  background: "white",
                  mr: 1,
                  maxHeight: 30,
                  fontSize: 12,
                }}
                onClick={() => {
                  setPaymentDialog({ open: true, data: vehicle?.id });
                }}
              >
                Pay
              </Button>
            ) : (
              ""
            )}

            {status === "BOOKED" || status === "PAYMENT_INITIALISED" ? (
              <Button
                variant="outlined"
                size="small"
                sx={{
                  background: "white",
                  mr: 1,
                  maxHeight: 30,
                  fontSize: 12,
                }}
                onClick={() => {
                  setCancelDialog({ open: true, data: vehicle });
                }}
              >
                Cancel
              </Button>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            px: 3,
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: isDarkMode ? "#000" : "#3D3D3D",
            fontWeight: 500,
            color: "#fff",
          }}
        >
          {vehicle.vin}

          <Box
            display="grid"
            gridTemplateColumns="repeat(2, auto)"
            gap={1}
            alignItems="center"
          >
            {/* <IconButton
              children={<DeleteOutline />}
              color="inherit"
              size="small"
              onClick={() => setDeleteDialog(true)}
            /> */}
            {/* <IconButton
              children={<AddPhotoAlternateOutlinedIcon />}
              color="inherit"
              size="small"
              onClick={() => {
                setImageDialog({ open: true, data: vehicle });
              }}
            /> */}
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              sx={{
                background: "none",
                ":hover": { background: "none" },
                maxHeight: 30,
              }}
              onClick={() => {
                downloadLeaseInvoice(vehicle);
              }}
            >
              <span style={{ fontSize: 12 }}>Invoice</span>
              <FileDownload sx={{ ml: 0.5, fontSize: 14 }} />
            </Button>
            <IconButton
              children={<HighlightOff />}
              color="inherit"
              size="small"
              onClick={() => drawer.close()}
            />
          </Box>
        </Box>
        {/* <Box width={270} mt={4} ml={3}>
          <Tabs value={tab} onChange={(e: any, value) => setTab(value)}>
            <Tab label="Details" />
            <Tab label="Recent Trips" />
          </Tabs>
        </Box> */}
        <Box flexGrow={1} overflow="auto">
          <Box pt={2} px={2}>
            {bookingLoading ? (
              <Box
                sx={{
                  display: "flex",
                  gap: 5,
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "60px",
                }}
              >
                <CircularProgress
                  color="primary"
                  sx={{ maxWidth: 15, maxHeight: 15 }}
                />
              </Box>
            ) : bookingData?.data?.status === "BOOKED" ? (
              <BookingBox
                color="#579BFC20"
                mainText="Vehicle Booked"
                secText={`${moment(vehicle?.startTime).format(
                  "DD/MM/YY, hh:mm a"
                )} - ${moment(vehicle?.endTime).format("DD/MM/YY, hh:mm a")}`}
                status="BOOKED"
              />
            ) : bookingData?.data?.status === "PAYMENT_INITIALISED" ||
              bookingData?.data?.status === "PENDING_PAYMENT" ? (
              <BookingBox
                color="#FFFF0030"
                mainText="Payment Initialised"
                secText={"Please complete the payment process"}
                status="PAYMENT_INITIALISED"
              />
            ) : bookingData?.data?.status === "TERMINATED" ||
              bookingData?.data?.status === "TERMINATED_FAILED_PAYMENT" ? (
              <BookingBox
                color="#FF000020"
                mainText="Booking Terminated"
                status="TERMINATED"
                secText={"Please add another Booking"}
              />
            ) : bookingData?.data?.status === "ACTIVE" ? (
              <BookingBox
                color="#68D6A550"
                mainText="Booking Active"
                secText={`${moment(vehicle?.startTime).format(
                  "DD/MM/YY, hh:mm a"
                )} - ${moment(vehicle?.endTime).format("DD/MM/YY, hh:mm a")}`}
                status="ACTIVE"
              />
            ) : bookingData?.data?.status === "ENDED" ? (
              <BookingBox
                color="#579BFC20"
                mainText="Booking Ended"
                status="ENDED"
                secText={"Please add another Booking"}
              />
            ) : (
              ""
            )}
          </Box>
          <Box
            sx={{
              px: 3,
              pt: 2.5,
              "& .table": {
                borderCollapse: "collapse",
                width: 1,
                fontSize: 14,
                lineHeight: "16px",
                "& td": {
                  py: 2,
                  px: 2,
                },
                "& .bold": {
                  fontWeight: 500,
                },
                "& .header": {
                  px: 2,
                  py: 1,
                  position: "relative",
                  "& td": {
                    position: "absolute",
                    verticalAlign: "middle",
                    backgroundColor: (theme) => theme.customColors.header,
                    width: 1,
                    borderRadius: "4px",
                    fontSize: 16,
                    fontWeight: 600,
                    "& span": {
                      display: "inline-block",
                      transform: "translateY(1px)",
                    },
                  },
                },
                "& .first > td": {
                  pt: 9,
                },
                "& .last > td": {
                  pb: 3,
                },
              },
            }}
          >
            <table className="table">
              <tbody>
                {table.map(({ header, label, value }: any, i) => {
                  const isFirst = table[i - 1]?.header;
                  const isLast = !table[i + 1] || table[i + 1].header;

                  return (
                    <tr
                      key={i}
                      className={
                        header
                          ? "header"
                          : `${isFirst ? "first" : ""} ${isLast ? "last" : ""}`
                      }
                    >
                      {header ? (
                        <td colSpan={2}>
                          <span>{header}</span>
                          {/* <IconButton
                            sx={{ ml: 1.5 }}
                            children={<EditOutlined />}
                            color="primary"
                            size="small"
                          /> */}
                        </td>
                      ) : (
                        <>
                          <td className="bold">{label}</td>
                          <td>{value}</td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Box
              sx={{
                mb: 3,
                px: 2,
                py: 1.5,
                width: 1,
                backgroundColor: (theme) => theme.customColors.header,
                borderRadius: "4px",
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              Start Images
            </Box>
            {bookingLoading ? (
              <Box
                sx={{
                  display: "flex",
                  gap: 5,
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "60px",
                }}
              >
                <CircularProgress
                  color="primary"
                  sx={{ maxWidth: 15, maxHeight: 15 }}
                />
              </Box>
            ) : startUrls.length === 0 ? (
              <Typography textAlign="center" color="text.secondary" mb={2}>
                No Start Images
              </Typography>
            ) : (
              startUrls.map((url: any, i: number) => (
                <Box
                  key={i}
                  sx={{
                    mb: 2.5,
                    width: 1,
                    display: "flex",
                    alignItems: "center",
                    // justifyContent: "space-between"
                  }}
                >
                  <Card
                    onClick={() => setViewDialog({ open: true, data: url })}
                    variant="outlined"
                    sx={{ width: 1 }}
                  >
                    <CardActionArea sx={{ display: "flex" }}>
                      <CardMedia
                        component="img"
                        sx={{ width: 300, height: 150 }}
                        image={url || ""}
                      />
                      <CardContent sx={{ width: 200 }}>
                        <Typography mb={0.5} ml={2} variant="h6">
                          {`Image No. ${i + 1}`}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                  {/* <ToggleButtonGroup
                    size="small"
                    orientation="vertical"
                    exclusive
                    defaultValue={doc.status}
                  >
                    <ToggleButton value="APPROVED">Approve</ToggleButton>
                    <ToggleButton value="REJECTED">Reject</ToggleButton>
                  </ToggleButtonGroup> */}
                </Box>
              ))
            )}
            <Box
              sx={{
                mb: 3,
                px: 2,
                py: 1.5,
                width: 1,
                backgroundColor: (theme) => theme.customColors.header,
                borderRadius: "4px",
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              End Images
            </Box>
            {bookingLoading ? (
              <Box
                sx={{
                  display: "flex",
                  gap: 5,
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "60px",
                }}
              >
                <CircularProgress
                  color="primary"
                  sx={{ maxWidth: 15, maxHeight: 15 }}
                />
              </Box>
            ) : endUrls.length === 0 ? (
              <Typography textAlign="center" color="text.secondary">
                No End Images
              </Typography>
            ) : (
              endUrls.map((url: any, i: number) => (
                <Box
                  key={i}
                  sx={{
                    mb: 2.5,
                    width: 1,
                    display: "flex",
                    alignItems: "center",
                    // justifyContent: "space-between"
                  }}
                >
                  <Card
                    onClick={() => setViewDialog({ open: true, data: url })}
                    variant="outlined"
                    sx={{ width: 1 }}
                  >
                    <CardActionArea sx={{ display: "flex" }}>
                      <CardMedia
                        component="img"
                        sx={{ width: 300, height: 150, objectFit: "contain" }}
                        image={url || ""}
                      />
                      <CardContent sx={{ width: 200 }}>
                        <Typography mb={0.5} ml={2} variant="h6">
                          {`Image No. ${i + 1}`}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                  {/* <ToggleButtonGroup
                    size="small"
                    orientation="vertical"
                    exclusive
                    defaultValue={doc.status}
                  >
                    <ToggleButton value="APPROVED">Approve</ToggleButton>
                    <ToggleButton value="REJECTED">Reject</ToggleButton>
                  </ToggleButtonGroup> */}
                </Box>
              ))
            )}
          </Box>
        </Box>
      </Box>
      <PaymentDialog
        open={paymentDialog.open}
        handleClose={() => {
          setPaymentDialog({ ...paymentDialog, open: false });
        }}
        data={paymentDialog.data}
        refetchBookings={refetchBookings}
        refetchBooking={refetchBooking}
      />
      <CancelDialog
        open={cancelDialog.open}
        handleClose={() => {
          setCancelDialog({ ...cancelDialog, open: false });
        }}
        booking={cancelDialog.data}
        refetchBookings={refetchBookings}
        refetchBooking={refetchBooking}
        refetchStats={refetchStats}
      />
      <AddImageDialog
        open={imageDialog.open}
        data={imageDialog.data}
        handleClose={() => {
          setImageDialog({ ...imageDialog, open: false });
        }}
        refetchBookings={refetchBookings}
        type={imageDialog.type}
        refetchBooking={refetchBooking}
        refetchStats={refetchStats}
        drawer={drawer}
      />
      <ViewDialog
        open={viewDialog.open}
        data={viewDialog.data}
        handleClose={() => setViewDialog((prev) => ({ ...prev, open: false }))}
      />

      <DeleteVendor open={deleteDialog} handleClose={DeleteHandleClose} />
    </>
  );
};

const CancelDialog = ({
  open,
  handleClose,
  booking,
  refetchBookings,
  refetchBooking,
  refetchStats,
}: any) => {
  const cancelURL = `${LEASE_URL}/company/booking/${booking?.id}/cancel`;

  const cancelMutation = useMutation(
    `cancelBooking`,
    () =>
      authorizedFetch(cancelURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    {
      onSuccess: (data) => {
        if (data?.msg === "Booking cancelled") {
          snackbar.success(data.msg);
        } else {
          snackbar.error(data.msg);
        }
        refetchBooking();
        refetchBookings();
        refetchStats();
        setLoader(false);
      },
    }
  );

  const onCancel = () => {
    setLoader(true);
    cancelMutation.mutate();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Cancel Booking</DialogTitle>
      <DialogContent sx={{ marginTop: "20px" }}>
        Are you sure you want to
        <span style={{ fontWeight: "bold" }}> Cancel Booking</span>?
      </DialogContent>
      <DialogActions sx={{ margin: "20px 20px 20px 0" }}>
        <Button onClick={handleClose}>Back</Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={onCancel}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ViewDialog = ({ open, data, handleClose }: any) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <img
        style={{ objectFit: "cover", maxHeight: 600 }}
        src={data || ""}
        alt="Booking Images"
      />
      <IconButton
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          m: 1,
        }}
        onClick={handleClose}
        color="inherit"
      >
        <Close />
      </IconButton>
    </Dialog>
  );
};

interface DeleteVendorProps {
  open: any;
  handleClose: () => void;
}

const DeleteVendor: React.FC<DeleteVendorProps> = ({ open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Delete Booking</DialogTitle>
      <DialogContent sx={{ marginTop: "20px" }}>
        Are you sure you want to DELETE:{" "}
        <span style={{ fontWeight: "bold" }}>Booking</span>?
      </DialogContent>
      <DialogActions sx={{ margin: "20px 20px 20px 0" }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          // onClick={confirm}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DrawerContent;
