import { setLoader } from "utils";

export const setGlobalState = (payload) => {
  return {
    type: "SET_GLOBAL_STATE",
    payload,
  };
};
export const switchTo = (payload) => {
  return {
    type: "SWITCH_TO",
    payload,
  };
};
export const saveReport = (payload) => {
  return {
    type: "SAVE_REPORT",
    payload,
  };
};
export const saveVehicleReport = (payload) => {
  return {
    type: "SAVE_VEHICLE_REPORT",
    payload,
  };
};
export const resetPassword = (payload) => {
  return {
    type: "RESET_PASSWORD",
    payload,
  };
};
export const login = (payload) => {
  setLoader(true);
  return {
    type: "LOGIN",
    payload,
  };
};
export const loginSuccess = (payload) => {
  setLoader(false);
  return {
    type: "LOGIN_SUCCEEDED",
    payload,
  };
};
export const loginFail = (payload) => {
  setLoader(false);
  return {
    type: "LOGIN_FAILED",
    payload,
  };
};
export const signUp = (payload) => {
  setLoader(true);
  return {
    type: "SIGNUP",
    payload,
  };
};
export const signUpSuccess = (payload) => {
  setLoader(false);
  return {
    type: "SIGNUP_SUCCEEDED",
    payload,
  };
};
export const signUpFail = (payload) => {
  setLoader(false);
  return {
    type: "SIGNUP_FAILED",
    payload,
  };
};
export const logout = () => {
  return {
    type: "LOGOUT",
  };
};
export const toggleDarkMode = (payload) => {
  return {
    type: "TOGGLE_DARK_MODE",
    payload,
  };
};
export const toggleMasterView = (payload) => {
  return {
    type: "TOGGLE_MASTER_VIEW",
    payload,
  };
};
export const toggleLoader = (payload) => {
  return {
    type: "TOGGLE_LOADER",
    payload,
  };
};
export const toggleSnackbar = (payload) => {
  return {
    type: "TOGGLE_SNACKBAR",
    payload,
  };
};
export const toggleDrawer = (payload) => {
  return {
    type: "TOGGLE_DRAWER",
    payload,
  };
};
