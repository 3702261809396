import {
  Alert,
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import CircularLoader from "components/CircularLoader";
import { useEffect, useState } from "react";

const Devices = ({
  form,
  devices,
  setDevices,
  setSelectedDevices,
  deviceOptionsLoading,
  deviceOptionsData,
}: any) => {
  const [selectedArray, setSelectedArray] = useState<any>();

  function handleChange(category: string, value: any) {
    let updatedDevices = [...devices];
    let index = updatedDevices.findIndex((el: any) => el.category === category);

    let array = typeof value === "string" ? value.split(",") : value;

    if (index > -1) updatedDevices[index].modelId = array;
    else
      updatedDevices.push({
        category,
        modelId: array,
      });

    setDevices(updatedDevices);
    setSelectedArray(array);
  }

  useEffect(() => {
    if (selectedArray) {
      // eslint-disable-next-line
      let selectedValues = devices.map((el: any) => {
        // eslint-disable-next-line
        if (el.category === "TELEMATICS") {
          return el.modelId.map((id: any) => {
            return dataObj["TELEMATICS"].find((el2: any) => el2._id === id)
              ?.name;
          });
        }
      });

      setSelectedDevices(
        ...selectedValues.filter((el: any) => el !== undefined)
      );
    }
    // eslint-disable-next-line
  }, [selectedArray]);

  let dataObj = deviceOptionsData?.data?.[0] || {};

  return (
    <Box
      sx={{
        maxWidth: 350,
        mx: "auto",
        py: 6,
        display: "grid",
        gap: 4,
      }}
    >
      {deviceOptionsLoading ? (
        <CircularLoader />
      ) : Object.keys(dataObj).length === 0 ? (
        <Alert severity="info">
          No device options for "{form.protocol}" protocol
        </Alert>
      ) : (
        Object.keys(dataObj).map((category, i) => {
          let label = category[0] + category.substring(1).toLowerCase();

          let record = devices.find((el: any) => el.category === category);
          let array = record ? record.modelId : [];

          return (
            <FormControl key={i} size="small">
              <InputLabel id={label}>{label}</InputLabel>
              <Select
                labelId={label}
                label={label}
                fullWidth
                multiple
                value={array}
                onChange={(e) => handleChange(category, e.target.value)}
                // renderValue={(selected) => selected.map((id: any) => dataObj[category].find((el: any) => el._id === id).name).join(', ')}
                renderValue={(selected) => {
                  let selectedValues = selected.map(
                    (id: any) =>
                      dataObj[category].find((el: any) => el._id === id)?.name
                  );

                  return (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selectedValues.map((value: any, i: number) => (
                        <Chip key={i} label={value} />
                      ))}
                    </Box>
                  );
                }}
                MenuProps={{
                  sx: {
                    "& ul": {
                      maxHeight: 300,
                      overflow: "auto",
                    },
                  },
                }}
              >
                {dataObj[category].map((el: any, i: number) => (
                  <MenuItem key={i} value={el._id}>
                    <Checkbox
                      size="small"
                      checked={array.indexOf(el._id) > -1}
                    />
                    <ListItemText primary={el.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        })
      )}
    </Box>
  );
};

export default Devices;
