import { EditOutlined, ErrorOutline, HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  Step,
  StepButton,
  Stepper,
  Typography,
} from "@mui/material";
import CircularLoader from "components/CircularLoader";
import { useState, useEffect, useMemo } from "react";
import { useMutation } from "react-query";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { LEASE_URL } from "utils/constants";

interface Props {
  open: boolean;
  handleClose: () => void;
  selected: any;
  refetchVehicles: any;
  setSelected: React.Dispatch<any>;
  pricingLoading: boolean;
  pricingData: any;
}

type inputData = {
  hourly: string;
  daily: string;
  weekly: string;
  monthly: string;
};

const AddPricingDialog: React.FC<Props> = ({
  open,
  handleClose,
  selected,
  setSelected,
  refetchVehicles,
  pricingLoading,
  pricingData,
}) => {
  const [step, setStep] = useState(0);
  const steps = ["Hourly", "Daily", "Weekly", "Monthly", "Finish"];

  const [input, setInput] = useState<inputData>({
    hourly: "",
    daily: "",
    weekly: "",
    monthly: "",
  });

  const { hourly, weekly, monthly, daily } = input;

  const addPricingURL = `${LEASE_URL}/company/pricing/assign`;

  const hasHourly = hourly ? { hourly: hourly } : "";
  const hasDaily = daily ? { daily: daily } : "";
  const hasWeekly = weekly ? { weekly: weekly } : "";
  const hasMonthly = monthly ? { monthly: monthly } : "";

  const mutation = useMutation(
    `assignPricing`,
    () =>
      authorizedFetch(addPricingURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          vehicles: selected,
          pricing: {
            ...hasHourly,
            ...hasDaily,
            ...hasWeekly,
            ...hasMonthly,
          },
        },
      }),
    {
      onSuccess: () => {
        snackbar.success(`Pricing Assigned`);
        refetchVehicles();
        setLoader(false);
      },
      onError: () => {
        snackbar.error(`Error assigning pricing`);
      },
    }
  );

  function handleChange(key: string, value: string) {
    setInput((prevInput: inputData) => ({ ...prevInput, [key]: value }));
  }

  const handleSave = () => {
    setLoader(true);
    mutation.mutate();
    setSelected([]);
    handleClose();
  };

  function handleNext() {
    if (step === steps.length - 1) {
      handleSave();
    } else setStep(step + 1);
  }
  function handleBack() {
    setStep(step - 1);
  }

  const disabled =
    [hourly].includes("") &&
    [weekly].includes("") &&
    [monthly].includes("") &&
    [daily].includes("");

  useEffect(() => {
    if (!open) {
      setInput({
        hourly: "",
        daily: "",
        weekly: "",
        monthly: "",
      });
      setStep(0);
    }
  }, [open]);

  // const pricingUrl = `${LEASE_URL}/company/pricing/all`;
  // const { isLoading, data: pricingData } = useQuery(["getPricings"], () =>
  //   authorizedFetch(pricingUrl)
  // );

  const { hourlyList, dailyList, weeklyList, monthlyList } = useMemo(() => {
    let hourlyList: any[] = [],
      dailyList: any[] = [],
      weeklyList: any[] = [],
      monthlyList: any[] = [];

    (pricingData?.data?.constructor === Array ? pricingData.data : []).forEach(
      (el: any) => {
        let record = {
          id: el?._id,
          value: el?.name,
        };
        if (el?.unit === "HOURLY") {
          hourlyList.push(record);
        } else if (el.unit === "DAILY") {
          dailyList.push(record);
        } else if (el.unit === "WEEKLY") {
          weeklyList.push(record);
        } else if (el.unit === "MONTHLY") {
          monthlyList.push(record);
        }
      }
    );

    return { hourlyList, dailyList, weeklyList, monthlyList };
  }, [pricingData]);

  const hourlyFinish = hourly
    ? [
        { header: "Hourly Info", onEdit: () => setStep(0) },
        {
          label: "Hourly Pricing",
          value: hourlyList.find((el: any) => {
            return el.id === hourly ? el.value : "";
          })?.value,
          required: false,
        },
      ]
    : [];
  const dailyFinish = daily
    ? [
        { header: "Daily Info", onEdit: () => setStep(1) },
        {
          label: "Daily Pricing",
          value: dailyList.find((el: any) => {
            return el.id === daily ? el.value : "";
          })?.value,
          required: false,
        },
      ]
    : [];
  const weeklyFinish = weekly
    ? [
        { header: "Weekly Info", onEdit: () => setStep(2) },
        {
          label: "Weekly Pricing",
          value: weeklyList.find((el: any) => {
            return el.id === weekly ? el.value : "";
          })?.value,
          required: false,
        },
      ]
    : [];
  const monthlyFinish = monthly
    ? [
        { header: "Monthly Info", onEdit: () => setStep(3) },
        {
          label: "Monthly Pricing",
          value: monthlyList.find((el: any) => {
            return el.id === monthly ? el.value : "";
          })?.value,
          required: false,
        },
      ]
    : [];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: 600,
          width: 1,
          "& .MuiInputBase-root": {
            fontSize: 14,
            borderRadius: 1,
            p: "3.5px 5px",
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        Assign Pricing
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent sx={{ pb: "16px !important" }}>
        <Stepper
          sx={{ mt: 4, mb: 6, mx: "auto", maxWidth: 534 }}
          activeStep={step}
          nonLinear
          alternativeLabel
        >
          {steps.map((label, i) => (
            <Step key={i}>
              <StepButton onClick={() => setStep(i)}>{label}</StepButton>
            </Step>
          ))}
        </Stepper>
        {pricingLoading ? (
          <Box my={4}>
            <CircularLoader />
          </Box>
        ) : (
          <>
            {step === 0 && (
              <Box
                sx={{
                  maxWidth: 300,
                  mx: "auto",
                  py: 2,
                }}
              >
                <Typography className="label">Hourly Pricing</Typography>
                <Select
                  fullWidth
                  value={hourly}
                  onChange={(e: any) => {
                    handleChange("hourly", e.target.value);
                  }}
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    <em>Select</em>
                  </MenuItem>
                  {hourlyList?.map((el: any) => (
                    <MenuItem key={el.id} value={el.id}>
                      {el.value}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}
            {step === 1 && (
              <Box
                sx={{
                  maxWidth: 300,
                  mx: "auto",
                  py: 2,
                }}
              >
                <Typography className="label">Daily Pricing</Typography>
                <Select
                  fullWidth
                  value={daily}
                  onChange={(e: any) => {
                    handleChange("daily", e.target.value);
                  }}
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    <em>Select</em>
                  </MenuItem>
                  {dailyList?.map((el: any) => (
                    <MenuItem key={el.id} value={el.id}>
                      {el.value}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}
            {step === 2 && (
              <Box
                sx={{
                  maxWidth: 300,
                  mx: "auto",
                  py: 2,
                }}
              >
                <Typography className="label">Weekly Pricing</Typography>
                <Select
                  fullWidth
                  value={weekly}
                  onChange={(e: any) => {
                    handleChange("weekly", e.target.value);
                  }}
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    <em>Select</em>
                  </MenuItem>
                  {weeklyList?.map((el: any) => (
                    <MenuItem key={el.id} value={el.id}>
                      {el.value}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}
            {step === 3 && (
              <Box
                sx={{
                  maxWidth: 300,
                  mx: "auto",
                  py: 2,
                }}
              >
                <Typography className="label">Monthly Pricing</Typography>
                <Select
                  fullWidth
                  value={monthly}
                  onChange={(e: any) => {
                    handleChange("monthly", e.target.value);
                  }}
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    <em>Select</em>
                  </MenuItem>
                  {monthlyList?.map((el: any) => (
                    <MenuItem key={el.id} value={el.id}>
                      {el.value}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}
          </>
        )}
        {step === 4 && (
          <Box
            sx={{
              maxWidth: 560,
              mx: "auto",
              "& .table": {
                borderCollapse: "collapse",
                width: 1,
                fontSize: 14,
                lineHeight: "16px",
                "& td": {
                  py: 1.25,
                  px: 2,
                },
                "& .bold": {
                  fontWeight: 500,
                },
                "& .header": {
                  px: 2,
                  py: 1,
                  position: "relative",
                  "& td": {
                    position: "absolute",
                    verticalAlign: "middle",
                    bgcolor: (theme) => theme.customColors.header,
                    width: 1,
                    borderRadius: "4px",
                    fontSize: 16,
                    fontWeight: 600,
                    "& span": {
                      display: "inline-block",
                      transform: "translateY(1px)",
                    },
                  },
                },
                "& .first > td": {
                  pt: 9,
                },
                "& .last > td": {
                  pb: 3,
                },
              },
            }}
          >
            <table className="table">
              <tbody>
                {[
                  ...hourlyFinish,
                  ...dailyFinish,
                  ...weeklyFinish,
                  ...monthlyFinish,
                ].map(({ header, onEdit, label, value, required }, i, arr) => {
                  const isFirst = arr[i - 1]?.header;
                  const isLast = !arr[i + 1] || arr[i + 1].header;

                  return (
                    <tr
                      key={i}
                      className={
                        header
                          ? "header"
                          : `${isFirst ? "first" : ""} ${isLast ? "last" : ""}`
                      }
                    >
                      {header ? (
                        <td colSpan={2}>
                          <span>{header}</span>
                          <IconButton
                            sx={{ ml: 1.5 }}
                            children={<EditOutlined />}
                            color="primary"
                            size="small"
                            onClick={onEdit}
                          />
                        </td>
                      ) : (
                        <>
                          <td>{label}</td>
                          <td className="bold">
                            {value ||
                              (required && (
                                <Box display="flex" alignItems="center">
                                  <ErrorOutline
                                    fontSize="small"
                                    color="error"
                                    style={{ marginRight: 8 }}
                                  />
                                  Required
                                </Box>
                              ))}
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Box sx={{ width: 1, display: "flex", justifyContent: "center" }}>
              {disabled ? (
                <>
                  <ErrorOutline
                    fontSize="small"
                    color="error"
                    style={{ marginRight: 6, marginTop: 2 }}
                  />
                  <Typography>
                    Atleast one timeslot should be selected
                  </Typography>
                </>
              ) : (
                ""
              )}
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ mt: 2 }}>
        {step !== 0 && (
          <Button variant="outlined" onClick={handleBack}>
            Back
          </Button>
        )}
        {step === 0 && (
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        )}
        <Button
          onClick={handleNext}
          variant={step === steps.length - 1 ? "contained" : "outlined"}
          disableElevation
          disabled={step === steps.length - 1 && disabled}
        >
          {step === steps.length - 1 ? "Save" : "Next"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPricingDialog;
