import {
  CheckBox,
  CheckBoxOutlineBlank,
  FilterAltOutlined,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  FormControl,
  Hidden,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import RangeSelector from "components/RangeSelector";
import { useState } from "react";
import FiltersDrawer from "./FiltersDrawer";
import LocationSelector from "./LocationSelector";

const AppBar = ({ filters, setFilters, filterLabels }: any) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <Box
      sx={{
        width: 1,
        py: 3,
        px: { xs: 2, md: 7 },
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="h2">Overview</Typography>
      <Hidden mdUp>
        <IconButton onClick={() => setDrawerOpen(true)}>
          <Badge
            color="primary"
            variant="dot"
            invisible={
              ![filters.access, filters.health, filters.locations].some(
                (el) => el.length > 0
              )
            }
          >
            <FilterAltOutlined />
          </Badge>
        </IconButton>
        <FiltersDrawer
          {...{
            open: drawerOpen,
            onClose: () => setDrawerOpen(false),
            filters,
            setFilters,
            filterLabels,
            setLocations: (val: any) =>
              setFilters((prev: any) => ({
                ...prev,
                locations: val,
              })),
          }}
        />
      </Hidden>
      <Hidden mdDown>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-end",
            alignItems: "center",
            "& > :not(:first-of-type)": {
              ml: 3,
            },
          }}
        >
          <FormControl size="small">
            <InputLabel id="access">Access</InputLabel>
            <Select
              className="shadow rounded"
              labelId="access-label"
              label="Access"
              sx={{ width: 125 }}
              multiple
              value={filters.access}
              onChange={(e) =>
                setFilters((prev: any) => ({
                  ...prev,
                  access:
                    typeof e.target.value === "string"
                      ? e.target.value.split(",")
                      : e.target.value,
                }))
              }
              renderValue={(selected) => selected.join(", ")}
            >
              {Object.keys(filterLabels.access).map((el, i) => (
                <MenuItem key={i} value={el}>
                  {filters.access.includes(el) ? (
                    <CheckBox fontSize="small" color="action" sx={{ mr: 1 }} />
                  ) : (
                    <CheckBoxOutlineBlank
                      fontSize="small"
                      color="action"
                      sx={{ mr: 1 }}
                    />
                  )}
                  <ListItemText primary={el} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl size="small">
            <InputLabel id="operational-label">Operational Status</InputLabel>
            <Select
              className="shadow rounded"
              labelId="operational-label"
              label="Operational Status"
              sx={{ width: 210 }}
              multiple
              value={filters.health}
              onChange={(e) =>
                setFilters((prev: any) => ({
                  ...prev,
                  health:
                    typeof e.target.value === "string"
                      ? e.target.value.split(",")
                      : e.target.value,
                }))
              }
              renderValue={(selected) => selected.join(", ")}
            >
              {Object.keys(filterLabels.health).map((el, i) => (
                <MenuItem key={i} value={el}>
                  {filters.health.includes(el) ? (
                    <CheckBox fontSize="small" color="action" sx={{ mr: 1 }} />
                  ) : (
                    <CheckBoxOutlineBlank
                      fontSize="small"
                      color="action"
                      sx={{ mr: 1 }}
                    />
                  )}
                  <ListItemText primary={el} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <LocationSelector
            locations={filters.locations}
            setLocations={(val) =>
              setFilters((prev: any) => ({
                ...prev,
                locations: val,
              }))
            }
          />
          <RangeSelector
            initialRange="Last 7 days"
            setRange={(range: any) => {
              setFilters((prev: any) => ({
                ...prev,
                time: range,
              }));
            }}
          />
        </Box>
      </Hidden>
    </Box>
  );
};

export default AppBar;
