import { Avatar, Box, Button, IconButton, Tooltip } from "@mui/material";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import {
  drawer,
  getDarkModePreference,
  GlobalState,
  snackbar,
  titleCase,
} from "utils";
import { HighlightOff } from "@mui/icons-material";
import saveAs from "file-saver";
import moment from "moment";

const UploadHistoryDrawer = ({ data }: any) => {
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );

  let vehicleDetails: any = {
    "File Info": {
      Status: data?.passedEntries === 0 ? "Failed" : "Success",
      "Total Vehicles": data?.passedEntries + data?.failedEntries,
      Success: data?.passedEntries,
      Failed: data?.failedEntries,
      "Created At": moment(data?.createdAt).format("DD-MMM-YYYY, hh:mm A"),
    },
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            px: 3,
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: isDarkMode ? "#000" : "#3D3D3D",
            fontWeight: 500,
            color: "#fff",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {data?.fileKey}
          </Box>
          <Box display="flex">
            <IconButton
              children={<HighlightOff />}
              color="inherit"
              size="small"
              onClick={() => drawer.close()}
            />
          </Box>
        </Box>
        <Box sx={{ ml: "auto", mr: 3, mt: 2, mb: 1 }}>
          <Tooltip title="Download File" followCursor>
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              sx={{ fontSize: 10 }}
              onClick={() => {
                saveAs(data.fileURL, data.fileKey);
                snackbar.success("Your file has been successfully downloaded");
              }}
            >
              Download
            </Button>
          </Tooltip>
        </Box>
        <Box flexGrow={1} overflow="auto">
          <Box
            sx={{
              px: 3,
              py: 2,

              "& .table": {
                borderRadius: "4px",
                border: "1px solid #00000010",
                width: 1,
                fontSize: 14,
                lineHeight: "16px",
                "& td": {
                  py: 1.25,
                  px: 2,
                  whiteSpace: "pre-line",
                },
                "& .bold": {
                  fontWeight: 500,
                },
                "& .header": {
                  px: 2,
                  py: 1,
                  position: "relative",
                  "& td": {
                    position: "absolute",
                    verticalAlign: "middle",
                    backgroundColor: (theme: any) => theme.customColors.header,
                    width: 1,
                    borderRadius: "4px",
                    fontSize: 16,
                    fontWeight: 600,
                    "& .label": {
                      display: "inline-block",
                      transform: "translateY(1px)",
                      py: 1.125,
                    },
                  },
                },
                "& .first > td": {
                  pt: 9,
                },
                "& .last > td": {
                  pb: 2.75,
                },
              },
            }}
          >
            <table className="table">
              <tbody>
                {Object.keys(vehicleDetails).map((category: any, i: number) => (
                  <Fragment key={i}>
                    <tr className="header">
                      <td colSpan={2}>
                        <span className="label">{category}</span>
                      </td>
                    </tr>
                    {Object.keys(vehicleDetails[category]).map(
                      (label: any, i: number, arr) => {
                        let value = vehicleDetails[category][label];
                        return (
                          <tr
                            key={i}
                            className={
                              (i === 0 ? "first " : "") +
                              (i === arr.length - 1 ? "last" : "")
                            }
                          >
                            <td>{label}</td>
                            <td className="bold">
                              {label === "Status" ? (
                                <Avatar
                                  variant="status"
                                  className={
                                    value === "Success" ? "green" : "red"
                                  }
                                >
                                  {titleCase(value)}
                                </Avatar>
                              ) : (
                                value
                              )}
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UploadHistoryDrawer;
