import { ContentCopy, HighlightOff, Link } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
// import Slide from "@mui/material/Slide";
// import { TransitionProps } from "@mui/material/transitions";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  authorizedFetch,
  getEnabledSections,
  GlobalState,
  makeCamelCase,
  setLoader,
  snackbar,
} from "utils";
import { AUTH_URL, getSectionName, pageIDs, sectionIDs } from "utils/constants";
import validator from "validator";

const InviteDialog = ({ open, handleClose }: any) => {
  const enabledSections = useSelector((state: GlobalState) =>
    getEnabledSections(state),
  );
  const { company } = useSelector((state: GlobalState) => state.global);
  const [linkDialog, setLinkDialog] = useState<{ open: boolean; data: any }>({
    open: false,
    data: null,
  });
  const [email, setEmail] = useState("");
  const [superPerm, setSuperPerm] = useState("Read");

  const sectionPermsObj = sectionIDs.reduce((acc: any, cur) => {
    acc[cur] = "Write";
    return acc;
  }, {});

  const [sectionPerms, setSectionPerms] = useState<{ [key: string]: string }>({
    ...sectionPermsObj,
  });

  const obj = pageIDs.reduce((acc: any, cur) => {
    const section = cur.split(":")[0];
    let page = cur.split(":")[1].replace(/([A-Z])/g, " $1");
    page = page.charAt(0).toUpperCase() + page.slice(1);
    if (!acc[section]) acc[section] = {};
    acc[section][page] = "Read";
    return acc;
  }, {});

  const [pagePerms, setPagePerms] = useState<{ [key: string]: any }>(obj);

  function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault();

    let permissions = [`company:${company.id}`];
    if (superPerm !== "Custom") {
      switch (superPerm) {
        case "Read":
          permissions.push("dashboard:*:*:READ");
          break;
        case "Write":
          permissions.push("dashboard:*:*:WRITE");
          break;
        case "Admin":
          permissions.push("dashboard:*");
          break;
        default:
          break;
      }
    } else {
      enabledSections.forEach((section) => {
        if (sectionPerms[section] !== "Custom") {
          switch (sectionPerms[section]) {
            case "Read":
              permissions.push(`dashboard:${section}:*:READ`);
              break;
            case "Write":
              permissions.push(`dashboard:${section}:*:WRITE`);
              break;
            default:
              break;
          }
        } else {
          Object.keys(pagePerms[section]).forEach((page) => {
            let perm = pagePerms[section][page];
            switch (perm) {
              case "Read":
                permissions.push(
                  `dashboard:${section}:${makeCamelCase(page)}:READ`,
                );
                break;
              case "Write":
                permissions.push(
                  `dashboard:${section}:${makeCamelCase(page)}:WRITE`,
                );
                break;
              case "Admin":
                permissions.push(`dashboard:${section}:*`);
                break;
              default:
                break;
            }
          });
        }
      });
    }
    setLoader(true);
    authorizedFetch(`${AUTH_URL}/user/invite`, {
      method: "POST",
      body: {
        email,
        inviteType: "DASHBOARD",
        permissions,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      setLoader(false);
      let { status, data } = response;
      if (status >= 400) {
        snackbar.error(
          status === 409 ? "User already exists" : "An error occurred",
        );
      } else {
        let link = data.link;
        setLinkDialog({ open: true, data: { email, link } });
      }
    });
  }

  useEffect(() => {
    if (!open) {
      setEmail("");
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        Invite New Member
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Box py={1}>
            <Box
              display="grid"
              gridTemplateColumns="auto 1fr"
              alignItems="center"
              gap={2}
            >
              <Typography sx={{ fontWeight: 600 }}>Email</Typography>
              <TextField
                autoComplete="off"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                fullWidth
                size="small"
                type="email"
                required
                error={email !== "" && !validator.isEmail(email)}
                helperText={
                  email !== "" && !validator.isEmail(email)
                    ? "Invalid email address"
                    : ""
                }
              />
              <Typography sx={{ fontWeight: 600 }}>Permissions</Typography>
              <Select
                size="small"
                value={superPerm}
                onChange={(e) => setSuperPerm(e.target.value)}
              >
                {["Read", "Write", "Admin", "Custom"].map((option, i) => (
                  <MenuItem key={i} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Collapse in={superPerm === "Custom"}>
              <Divider sx={{ mt: 2 }} />
              {enabledSections.map((section, i) => (
                <Box key={i}>
                  <Box
                    my={1}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography sx={{ fontWeight: 500 }}>
                      {getSectionName(section)}
                    </Typography>
                    <Select
                      size="small"
                      value={sectionPerms[section]}
                      onChange={(e) =>
                        setSectionPerms((prev) => ({
                          ...prev,
                          [section]: e.target.value,
                        }))
                      }
                    >
                      {["No Access", "Read", "Write", "Admin", "Custom"].map(
                        (option, i) => (
                          <MenuItem key={i} value={option}>
                            {option}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </Box>
                  <Collapse in={sectionPerms[section] === "Custom"}>
                    <Box
                      display="grid"
                      gridTemplateColumns="1fr 2fr"
                      alignItems="center"
                    >
                      <Box
                        gridColumn="span 2"
                        mt={1}
                        mb={0.5}
                        pl={13.5}
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Typography variant="body2" fontWeight={500}>
                          No Access
                        </Typography>
                        <Typography variant="body2" fontWeight={500}>
                          Read
                        </Typography>
                        <Typography variant="body2" fontWeight={500}>
                          Write
                        </Typography>
                      </Box>
                      {Object.keys(pagePerms[section]).map((page, i) => (
                        <React.Fragment key={i}>
                          <Typography variant="body1" color="textSecondary">
                            {page}
                          </Typography>
                          <Box pl={3} pr={2}>
                            <Slider
                              size="medium"
                              color="secondary"
                              valueLabelDisplay="auto"
                              defaultValue={0}
                              valueLabelFormat={(value) =>
                                value === 0
                                  ? "No Access"
                                  : value === 1
                                  ? "Read"
                                  : "Write"
                              }
                              step={1}
                              marks
                              min={0}
                              max={2}
                              value={
                                pagePerms[section][page] === "No Access"
                                  ? 0
                                  : pagePerms[section][page] === "Read"
                                  ? 1
                                  : 2
                              }
                              onChange={(e, value) => {
                                let perm =
                                  value === 0
                                    ? "No Access"
                                    : value === 1
                                    ? "Read"
                                    : "Write";
                                setPagePerms((prev) => {
                                  let newPagePerms = Object.assign({}, prev);
                                  newPagePerms[section][page] = perm;
                                  return newPagePerms;
                                });
                              }}
                            />
                          </Box>
                        </React.Fragment>
                      ))}
                    </Box>
                  </Collapse>
                  <Divider />
                </Box>
              ))}
            </Collapse>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit">
            Invite
          </Button>
          <LinkDialog
            open={linkDialog.open}
            handleClose={() =>
              setLinkDialog((prev) => ({ ...prev, open: false }))
            }
            data={linkDialog.data}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

// const Transition = React.forwardRef<
//   unknown,
//   TransitionProps & {
//     children?: React.ReactElement;
//   }
// >((props, ref) => {
//   return <Slide direction="up" timeout={200} ref={ref} {...props} />;
// });

const LinkDialog = ({ open, handleClose, data }: any) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      // TransitionComponent={Transition}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            transform: "translateY(-4px)",
          }}
        >
          <Avatar sx={{ mr: 1.5, bgcolor: "secondary.main" }}>
            <Link sx={{ color: "white" }} />
          </Avatar>
          Invite Link
        </Box>
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent>
        <Box pt={1.5} pb={6}>
          <Typography mb={2} sx={{ wordWrap: "revert" }}>
            For: <b>{data?.email}</b>
            <br />
            Please ask them to sign up using this link.
          </Typography>
          <TextField value={data?.link} size="small" fullWidth />
          <Button
            variant="contained"
            // color="secondary"
            startIcon={<ContentCopy />}
            sx={{ mt: 2 }}
            onClick={() => {
              navigator.clipboard.writeText(data?.link);
              snackbar.success("Link copied to clipboard");
            }}
          >
            Copy
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default InviteDialog;
