import { AccountBalanceOutlined, HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import PhoneTextField from "components/PhoneTextField";
import RedAsterisk from "components/RedAsterisk";
import { queryClient } from "index";
import { useEffect, useState } from "react";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { BOLT_URL, PAYMENTS_URL } from "utils/constants";
import PaymentDialog from "views/Charger/Users/PaymentDialog";

const EditOwnershipDialog = ({ open, charger, handleClose }: any) => {
  const [phone, setPhone] = useState("");

  const [paymentDialog, setPaymentDialog] = useState<any>({
    open: false,
    data: null,
  });
  const [choosePaymentMethodDialog, setChoosePaymentMethodDialog] = useState({
    open: false,
    paymentMethods: [],
  });
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    paymentMethodId: "",
  });

  const existingPhone = charger?.owner?.phone;
  const disabled = !isValidPhoneNumber(phone) || phone === existingPhone;

  useEffect(() => {
    if (!open) return;
    setPhone("");
  }, [open]);

  function handleSubmit() {
    const shouldCheckForPaymentDetails =
      charger?.charger?.usageType?.includes("PAID");

    if (shouldCheckForPaymentDetails) {
      setLoader(true);
      // Search for user by phone number
      authorizedFetch(
        `${BOLT_URL}/company/users?type=APP_USER&first=1&skip=0&phone=${phone}`
      )
        .then((res) => {
          const user = res?.data?.users?.[0]?.user;
          if (!user || user.phone !== phone) {
            snackbar.error("No user found with this phone number");
            setLoader(false);
            return;
          }
          // Search for payment details
          authorizedFetch(
            `${PAYMENTS_URL}/v1/payments/paymentmeta/default/getAll?userId=${user?._id}`
          )
            .then((res) => {
              setLoader(false);
              if (res?.data?.constructor !== Array) {
                snackbar.error("Error fetching payment details");
                return;
              }

              if (res.data.length === 0) {
                snackbar.info(
                  "Please add payment details for this user and try again"
                );
                setPaymentDialog({
                  open: true,
                  data: user,
                });
              } else if (
                res.data.length === 1 ||
                (res.data.length > 1 &&
                  res.data.some(
                    (paymentMethod: any) => paymentMethod.isDefault
                  ))
              ) {
                setConfirmDialog({
                  open: true,
                  paymentMethodId: "",
                });
              } else {
                setChoosePaymentMethodDialog({
                  open: true,
                  paymentMethods: res.data,
                });
              }
            })
            .catch((err) => {
              setLoader(false);
              snackbar.error("Error fetching payment details");
              console.log(err);
            });
        })
        .catch((err) => {
          setLoader(false);
          snackbar.error("Error fetching user details");
          console.log(err);
        });
    } else {
      setConfirmDialog({
        open: true,
        paymentMethodId: "",
      });
    }
  }

  return (
    <>
      <PaymentDialog
        open={paymentDialog.open}
        handleClose={() =>
          setPaymentDialog((prev: any) => ({ ...prev, open: false }))
        }
        data={paymentDialog.data}
      />
      <ChoosePaymentMethodDialog
        open={choosePaymentMethodDialog.open}
        paymentMethods={choosePaymentMethodDialog.paymentMethods}
        handleClose={() =>
          setChoosePaymentMethodDialog((prev: any) => ({
            ...prev,
            open: false,
          }))
        }
        handleSubmit={(paymentMethodId: any) => {
          setChoosePaymentMethodDialog((prev: any) => ({
            ...prev,
            open: false,
          }));
          setConfirmDialog({ open: true, paymentMethodId });
        }}
      />
      <ConfirmDialog
        open={confirmDialog.open}
        chargerId={charger?.chargerId}
        paymentMethodId={confirmDialog.paymentMethodId}
        phone={phone}
        handleClose={() =>
          setConfirmDialog((prev: any) => ({ ...prev, open: false }))
        }
        handleDialogClose={handleClose}
      />
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            Change Ownership
            <Divider flexItem orientation="vertical" sx={{ mx: 1.5 }} />
            <Typography color="text.secondary">{charger?.chargerId}</Typography>
          </Box>
          <IconButton
            children={<HighlightOff />}
            color="inherit"
            onClick={handleClose}
            sx={{ transform: "translate(8px, -8px)" }}
          />
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              py: 2,
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              gap: 2,
              alignItems: "center",
            }}
          >
            <Typography color="text.secondary">Current Phone Number</Typography>
            <Typography fontWeight="medium">
              {existingPhone
                ? formatPhoneNumberIntl(existingPhone)
                : "Not Available"}
            </Typography>
            <Typography color="text.secondary">
              New Phone Number
              <RedAsterisk />
            </Typography>
            <PhoneInput
              placeholder="Enter phone number"
              value={phone}
              onChange={(value) => setPhone(value || "")}
              defaultCountry="IN"
              international
              limitMaxLength
              inputComponent={PhoneTextField}
              error={!!phone && disabled}
              helperText={
                phone === existingPhone
                  ? "New phone number cannot be same as current phone number"
                  : undefined
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={disabled}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ConfirmDialog = ({
  open,
  chargerId,
  paymentMethodId,
  phone,
  handleClose,
  handleDialogClose,
}: any) => {
  function handleConfirm() {
    setLoader(true);
    authorizedFetch(`${BOLT_URL}/restricted/changeowner`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        chargerIds: [chargerId],
        phone: phone,
        ...(paymentMethodId ? { paymentMethodId } : {}),
      },
    })
      .then((res) => {
        setLoader(false);

        if (res.message === "changeChargerOwner request successful") {
          snackbar.success("Ownership changed successfully");
          queryClient.resetQueries("getCharger");
          handleClose();
          handleDialogClose();
        } else {
          snackbar.error(
            `Error${res.message ? ": " + res.message : " changing ownership"}`
          );
          handleClose();
        }
      })
      .catch((err) => {
        setLoader(false);
        snackbar.error("Error changing ownership");
        handleClose();
        console.log(err);
      });
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>Confirm</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to change the ownership of charger{" "}
          <b>{chargerId}</b> to <b>{formatPhoneNumberIntl(phone)}</b>?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          No
        </Button>
        <Button variant="contained" onClick={handleConfirm}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ChoosePaymentMethodDialog = ({
  open,
  paymentMethods,
  handleClose,
  handleSubmit,
}: any) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        Choose Payment Method
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent>
        <Typography mb={3}>
          Please choose the payment method to be used for settlements.
        </Typography>
        <Box
          sx={{
            maxWidth: 380,
            mx: "auto",
            gridColumn: "1/-1",
            display: "grid",
            gap: 3,
            mb: 6,
          }}
        >
          {paymentMethods.map((el: any, i: number) => (
            <Card variant="outlined">
              <CardActionArea
                sx={{
                  display: "flex",
                  height: 1,
                }}
                onClick={() => handleSubmit(el._id)}
              >
                <Box
                  sx={{
                    bgcolor: (theme) => theme.palette.divider,
                    color: (theme) => theme.customColors.action,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 1,
                    width: 1,
                    maxWidth: 100,
                    position: "relative",
                  }}
                >
                  {el?.isDefault && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        p: "2px 6px",
                        borderBottomRightRadius: "6px",
                        bgcolor: "primary.main",
                        color: "white",
                        fontSize: 12,
                        fontWeight: 600,
                      }}
                    >
                      Primary
                    </Box>
                  )}
                  <AccountBalanceOutlined color="inherit" fontSize="large" />
                </Box>
                <Box
                  sx={{
                    py: 1,
                    px: 2,
                    flexGrow: 1,
                  }}
                >
                  <Typography variant="overline">
                    {el.bankDetails.bankName}
                  </Typography>
                  <Typography variant="h6" mb={1}>
                    •••• {el.bankDetails.bankAccount.slice(-4)}
                  </Typography>
                  <Typography variant="caption">
                    {el.bankDetails.ifsc}
                  </Typography>
                </Box>
              </CardActionArea>
            </Card>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EditOwnershipDialog;
