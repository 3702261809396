import {
  ChevronRight,
  Close,
  InfoOutlined,
  Settings,
} from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router-dom";

const AppBar = ({
  vehicle,
  isLoading,
  setDrawerOpen,
  isDarkMode,
  isRetail,
  data,
  setVehicleSettingsDialog,
}: any) => {
  const history = useHistory();

  return (
    <Box
      sx={{
        width: 1,
        py: 3,
        px: { xs: 2, md: 7 },
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box display="flex">
        <Breadcrumbs
          sx={{
            "& .MuiTypography-root": {
              // fontSize: 20,
              color: "text.primary",
              // letterSpacing: "-0.015em",
            },
          }}
          separator={<ChevronRight />}
        >
          <Link
            underline="hover"
            key={"vehicle"}
            href={"/vehicles"}
            onClick={(e: any) => {
              e.preventDefault();
              history.push("/vehicles");
            }}
          >
            <Typography variant="h2">Vehicles</Typography>
          </Link>
          <Typography variant="h2" fontWeight={700}>
            {isLoading ? "Loading..." : vehicle?.vin}
          </Typography>
        </Breadcrumbs>
        <Tooltip title="Info" followCursor>
          <IconButton
            sx={{ ml: 1 }}
            size="small"
            children={<InfoOutlined fontSize="small" />}
            // onClick={() => drawer.open(<VehicleDrawer vin={vin} />)}
            onClick={() => setDrawerOpen(true)}
          />
        </Tooltip>
      </Box>

      <Box>
        <Button
          sx={{
            minWidth: 40,
            height: 40,
            p: 0,
            border: 1,
            mr: 1,
            borderColor: (theme) => theme.customColors.border,
            bgcolor: isDarkMode ? "background.default" : "#fff",
          }}
          disabled={!data}
          onClick={() => setVehicleSettingsDialog(true)}
        >
          <Settings />
        </Button>
        <Button
          sx={{
            minWidth: 40,
            height: 40,
            p: 0,
            border: 1,
            borderColor: (theme) => theme.customColors.border,
            bgcolor: isDarkMode ? "background.default" : "#fff",
          }}
          onClick={() => {
            history.push(isRetail ? "/sold-vehicles" : "/vehicles");
          }}
        >
          <Close />
        </Button>
      </Box>
    </Box>
  );
};

export default AppBar;
