import {
  InfoOutlined,
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Grow,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { guidGenerator } from "utils";

const RangeSelector = ({ initialRange, setRange }: any) => {
  const anchorEl = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(initialRange || "Last 1 month");

  const rangePickerButtonId = useMemo(() => guidGenerator(), []);

  useEffect(() => {
    console.log("set filters");
    $("#" + rangePickerButtonId).daterangepicker({
      opens: "left",
      maxDate: moment(),
      startDate: moment().subtract({ months: 1 }),
      // endDate: moment(),
      maxSpan: {
        months: 6,
      },
      showDropdowns: true,
      autoApply: true,
      locale: {
        format: "DD/MM/YYYY",
        firstDay: 1,
      },
    });
    setRange &&
      setRange([
        moment().subtract(
          initialRange === "Last 7 days" ? { days: 7 } : { months: 1 }
        ),
        moment(),
      ]);
    $("#" + rangePickerButtonId).on(
      "apply.daterangepicker",
      function (e, picker) {
        setRange && setRange([picker.startDate, picker.endDate]);
        setValue("Custom");
        handleClose();
      }
    );
    // eslint-disable-next-line
  }, []);

  const handleChange = (type: string) => {
    setValue(type);
    setRange &&
      setRange([
        moment().subtract(
          type.includes("days")
            ? { days: 7 }
            : type.includes("1 month")
            ? { months: 1 }
            : type.includes("3 months")
            ? { months: 3 }
            : { years: 1 }
        ),
        moment(),
      ]);
    handleClose();
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Box>
      <Button
        ref={anchorEl}
        sx={{
          color: (theme) => theme.customColors.text.grey,
          height: 40,
          fontSize: 16,
          letterSpacing: "normal",
          textTransform: "none",
          px: 2,
          border: 1,
          // borderWidth: isOpen ? 2 : 1,
          borderRadius: 10,
          boxShadow: (theme) => theme.customShadows.inputField,
          borderColor: (theme) =>
            isOpen ? theme.palette.primary.main : theme.customColors.grey,
          "&:hover": {
            bgcolor: "transparent",
            boxShadow: (theme) => theme.customShadows.inputField,
          },
        }}
        color="inherit"
        disableRipple
        onClick={() => setIsOpen((prev) => !prev)}
        endIcon={
          isOpen ? (
            <KeyboardArrowUpRounded color="primary" />
          ) : (
            <KeyboardArrowDownRounded />
          )
        }
      >
        {value}
      </Button>
      <Popper
        keepMounted // Required for daterangepicker to work
        transition
        open={isOpen}
        anchorEl={anchorEl.current}
        disablePortal
        placement="bottom"
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "50% 0",
            }}
          >
            <Paper
              sx={{
                width: 190,
              }}
              elevation={8}
            >
              <List dense>
                <ListItemButton onClick={() => handleChange("Last 7 days")}>
                  <ListItemText primary="Last 7 days" />
                </ListItemButton>
                <ListItemButton onClick={() => handleChange("Last 1 month")}>
                  <ListItemText primary="Last 1 month" />
                </ListItemButton>
                <ListItemButton onClick={() => handleChange("Last 3 months")}>
                  <ListItemText primary="Last 3 months" />
                </ListItemButton>
                {/* <ListItemButton onClick={() => handleChange("Last 1 year")}>
                  <ListItemText primary="Last 1 year" />
                </ListItemButton> */}
                <ListItemButton id={rangePickerButtonId}>
                  <ListItemText
                    primaryTypographyProps={{
                      sx: {
                        display: "flex",
                        alignItems: "center",
                      },
                    }}
                  >
                    Custom
                    <Tooltip
                      title="The maximum span between the selected start and end dates can be 6 months"
                      placement="top"
                    >
                      <InfoOutlined
                        color="action"
                        sx={{ fontSize: 16, ml: 0.5 }}
                      />
                    </Tooltip>
                  </ListItemText>
                </ListItemButton>
              </List>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default RangeSelector;
