import { Box, Typography } from "@mui/material";

const AppBar = () => {
  return (
    <Box
      sx={{
        width: 1,
        py: 3,
        px: { xs: 2, md: 7 },
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box display="flex" alignItems="center">
        <Typography variant="h2">KYC</Typography>
      </Box>
    </Box>
  );
};

export default AppBar;
