import { Box, Skeleton, Typography } from "@mui/material";
import InfoLabel from "components/InfoLabel";
import { Fragment } from "react";
import { Doughnut } from "react-chartjs-2";
import { useHistory } from "react-router-dom";

export const operationalStatusLegend = [
  {
    param: "HEALTHY",
    key: "healthyChargersCount",
    label: "Used Within Last 15 Days",
    color: "#01A229",
  },
  {
    param: "MODERATE",
    key: "moderateChargersCount",
    label: "Used Between Last 16-30 Days",
    color: "#62D16A",
  },
  {
    param: "CRITICAL",
    key: "criticalChargersCount",
    label: "Used Between Last 31-45 Days",
    color: "#FFCC27",
  },
  // {
  //   param: "",
  //   key: "",
  //   label: "Unused between 30 to 45 Days",
  //   color: "#F4845D",
  // },
  {
    param: "INACTIVE",
    key: "damagedChargersCount",
    label: "Used Before 45 Days",
    color: "#EB5C5C",
  },
];

const legendStyles: any = {};

operationalStatusLegend.forEach((el) => {
  legendStyles[`&.${el.param}::before`] = {
    bgcolor: el.color,
  };
});

const OperationalStatus = ({
  statsLoading: isLoading,
  statsData: data,
  disableTotal,
  setChargerHealth,
}: any) => {
  const history = useHistory();

  function setHealthFilter(health: string) {
    if (history.location.pathname === "/") {
      history.push("/chargers?health=" + health);
    } else {
      setChargerHealth && setChargerHealth(health);
    }
  }

  const totalChargers = data?.data?.stats?.totalChargers || 0;

  function format(n: number) {
    return n.toLocaleString();
  }

  return (
    <>
      <Box
        sx={{
          mb: { xs: 2, md: 7.5 },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "flex-start", md: "center" },
        }}
      >
        <Typography variant="h3" mr={1.25} mb={{ xs: 1.5, md: 0 }}>
          Operational Status
        </Typography>
        {!disableTotal ? (
          isLoading ? (
            <Skeleton
              variant="rectangular"
              width={80}
              height={24}
              sx={{ borderRadius: 10 }}
            />
          ) : (
            <InfoLabel dense label="Total" value={format(totalChargers)} />
          )
        ) : null}
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "auto",
            md: "minmax(0, 280px) auto",
          },
          gap: { xs: 0, md: 4 },
        }}
      >
        <Box
          sx={{
            width: 1,
            maxWidth: 250,
            mx: "auto",
          }}
        >
          {isLoading ? (
            <Box
              sx={{
                width: 1,
                position: "relative",
                pt: "100%",
              }}
            >
              <Skeleton
                variant="circular"
                sx={{
                  position: "absolute",
                  top: 0,
                  width: 1,
                  height: 1,
                }}
              />
            </Box>
          ) : (
            <Box width={1} alignSelf="center" position="relative">
              <Doughnut
                style={{
                  position: "relative",
                  transform: "translateY(-25%)",
                  zIndex: 2,
                }}
                data={(canvas) => {
                  return {
                    datasets: [
                      {
                        data: operationalStatusLegend.map(
                          (el) => data?.data?.stats?.[el.key] || 0
                        ),
                        backgroundColor: operationalStatusLegend.map(
                          (el) => el.color
                        ),
                        hoverBackgroundColor: operationalStatusLegend.map(
                          (el) => el.color
                        ),
                        borderWidth: 0,
                        cutout: "87%",
                        circumference: 180,
                        rotation: -90,
                        borderRadius: 10,
                        hoverOffset: 10,
                      },
                    ],
                    labels: operationalStatusLegend.map((el) => el.label),
                  };
                }}
                options={{
                  layout: {
                    padding: 15,
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      displayColors: false,
                    },
                  },
                  onClick(click, elements, chart) {
                    if (elements[0]) {
                      let health =
                        operationalStatusLegend[elements[0].index].param || "";
                      setHealthFilter(health);
                    }
                  },
                  onHover: (event: any, chartElement) =>
                    (event.native.target.style.cursor = chartElement[0]
                      ? "pointer"
                      : "default"),
                }}
              />
              <Box
                sx={{
                  zIndex: 1,
                  position: "absolute",
                  top: "25%",
                  right: 0,
                  left: 0,
                  mx: "auto",
                  pointerEvents: "none",
                  textAlign: "center",
                  color: (theme) => theme.customColors.text.plain,
                }}
              >
                <Typography
                  fontSize={{ xs: 24, md: 32 }}
                  fontWeight={700}
                  lineHeight="43.65px"
                >
                  {format(totalChargers)}
                </Typography>
                <Typography
                  fontSize={{ xs: 14, md: 16 }}
                  color="text.secondary"
                >
                  Total chargers
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            mt: { xs: -10, md: 0 },
            pl: { xs: 3, md: 4 },
            pr: { xs: 2, md: 0 },
            width: 1,
            minWidth: 0,
            maxWidth: { md: 312 },
            height: "fit-content",
            justifySelf: { md: "end" },
            display: "grid",
            gridTemplateColumns: "1fr auto",
            rowGap: 4,
            columnGap: 1,
            fontSize: { xs: 12, md: 16 },
            "& .label": {
              position: "relative",
              "&::before": {
                content: '""',
                position: "absolute",
                mt: 0.25,
                transform: "translateX(-15.96px)",
                borderRadius: 10,
                width: "6.39px",
                height: "17.89px",
              },
              ...legendStyles,
            },
            "& .value": {
              // textAlign: "right",
              fontWeight: 700,
            },
          }}
        >
          {operationalStatusLegend.map((el, i) => (
            <Fragment key={i}>
              {isLoading ? (
                <Skeleton width={150} />
              ) : (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => setHealthFilter(el.param)}
                  className={`label ${el.param}`}
                >
                  {el.label}
                </span>
              )}
              {isLoading ? (
                <Skeleton width={20} />
              ) : (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => setHealthFilter(el.param)}
                  className="value"
                >
                  {data?.data?.stats?.[el.key]?.toLocaleString()}
                </span>
              )}
            </Fragment>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default OperationalStatus;
