import {
  Add,
  DeleteOutline,
  EditOutlined,
  ErrorOutline,
  HighlightOff,
  InfoOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  ListItemIcon,
  MenuItem,
  Select,
  Step,
  StepButton,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import TableComponent from "components/Table";
import { useEffect, useState } from "react";
import { useQueries, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { GlobalState, authorizedFetch, setLoader, snackbar } from "utils";
import {
  BOLT_URL,
  PAYMENTS_URL,
  REVENUE_SPLIT_URL_TEMP,
} from "utils/constants";
import PaymentDialog from "views/Charger/Users/PaymentDialog";

// const splitTypes = [
//   {
//     label: "Revenue Based Share",
//     value: "REVENUE_PERCENTAGE",
//   },
//   {
//     label: "Profit Based Share",
//     value: "PROFIT_PERCENTAGE",
//   },
//   {
//     label: "Flat Convenience Fee",
//     value: "FLAT_FEE",
//   },
// ];

const inputObj: any = {
  products: [],
  splitType: "FLAT_FEE",
  splits: [
    {
      vendor: {
        id: "",
        name: "Bolt.Earth",
      },
      user: {
        _id: "",
      },
      vendorType: "UJOY",
      paymentMethod: "N/A",
      percentage: 100,
      flatFeePerUnit: 0,
    },
    // {
    //   vendor: {
    //     id: "",
    //     name: "Host",
    //   },
    //   user: {
    //     _id: "",
    //   },
    //   vendorType: "HOST",
    //   paymentMethod: "N/A",
    //   percentage: 0,
    //   flatFeePerUnit: 0,
    // },
  ],
  unitPrice: 0,
};

const RevenueSplitDialog = ({ open, handleClose }: any) => {
  const { company } = useSelector((state: GlobalState) => state.global);
  const isCUGMonitorAccount = company?.type === "CUG_VENDOR";
  // const steps = ["Add Chargers", "Split Type", "Add Vendors", "Finish"];
  const steps = ["Add Chargers", "Add Vendors", "Finish"];
  const [step, setStep] = useState(0);

  const [input, setInput] = useState({ ...inputObj });

  const [paymentDialog, setPaymentDialog] = useState({
    open: false,
    data: null,
  });

  const { products, splitType, splits, unitPrice } = input;

  const isPercentBased = ["REVENUE_PERCENTAGE", "PROFIT_PERCENTAGE"].includes(
    splitType
  );
  const isProfitBased = splitType === "PROFIT_PERCENTAGE";

  const [search, setSearch] = useState("");

  const chargersUrl = `${BOLT_URL}/company/chargers?chargerId=${search}&first=10&skip=0`;
  const { isLoading: chargersLoading, data: chargersData } = useQuery(
    ["getChargers", search],
    () => authorizedFetch(chargersUrl),
    {
      enabled: !isCUGMonitorAccount,
    }
  );

  const vendorsUrl = `${BOLT_URL}/company/getVendors?orderBy=CREATED_AT_DESC&search=${search}`;
  const { isLoading: vendorsLoading, data: vendorsData } = useQuery(
    ["getVendors", search],
    () => authorizedFetch(vendorsUrl)
  );

  const paymentData: any = useQueries(
    splits?.map((el: any) => ({
      queryKey: ["getPaymentDetails", el.user._id],
      queryFn: () =>
        authorizedFetch(
          `${PAYMENTS_URL}/v1/payments/paymentmeta/default/getAll?userId=${el.user._id}`
        ),
      enabled: !!el.user._id,
    }))
  );

  useEffect(() => {
    if (!open) return;
    setStep(0);
    setInput({
      ...inputObj,
    });
  }, [open]);

  const cumulativePercentage = () => {
    let sum = input.splits.reduce((acc: any, cur: any) => {
      return Number(acc) + Number(cur.percentage || 0);
    }, 0);

    return sum;
  };

  const cumulativeFlatFee = () => {
    let sum = input.splits.reduce((acc: any, cur: any) => {
      return Number(acc) + Number(cur.flatFeePerUnit || 0);
    }, 0);

    return sum;
  };

  function handleNext() {
    if (step === steps.length - 1) {
      handleSave();
    } else setStep(step + 1);
  }

  function handleBack() {
    setStep(step - 1);
  }

  function isComplete(step: number) {
    switch (step) {
      case 0:
        return input.products.length > 0;
      // case 1:
      //   return !!splitType;
      case 1:
        return (
          splits.length > 0 &&
          (isPercentBased
            ? cumulativePercentage() === 100
            : cumulativeFlatFee() > 0) &&
          splits.every((el: any) => !!el.paymentMethod)
        );
      default:
        break;
    }
    return false;
  }

  function handleSave() {
    setLoader(true);
    authorizedFetch(`${REVENUE_SPLIT_URL_TEMP}/v1/payments/revenuesplit/deal`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        products: products.map((el: any) => el.charger._id),
        splits: splits.map((el: any, i: number) => ({
          vendorType: el.vendorType,
          ...(isPercentBased
            ? {
                percentage: el.percentage,
              }
            : {
                flatFeePerUnit: el.flatFeePerUnit,
              }),
          // `user`, `vendor`, and `paymentMethod` are not needed for the first entry (Bolt.Earth)
          ...(i > 0 && {
            vendor: el.vendor.id,
            user: el.user._id,
            paymentMethod: el.paymentMethod,
          }),
        })),
        splitType,
        ...(isProfitBased && {
          unitPrice,
        }),
      },
    }).then((res: any) => {
      setLoader(false);
      console.log(res);
      if (res.status === 200) {
        snackbar.success("Revenue split created");
        handleClose();
      } else {
        snackbar.error(
          res.message ? "Error: " + res.message : "Error creating revenue split"
        );
      }
    });
  }

  const disabled =
    !products.length ||
    !splits.length ||
    !splitType ||
    (isPercentBased && cumulativePercentage() !== 100) ||
    (!isPercentBased && cumulativeFlatFee() === 0) ||
    splits.some((el: any) => !el.paymentMethod);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          // maxWidth: 800,
          // width: 1,
          "& .MuiInputBase-root": {
            fontSize: 14,
            borderRadius: 1,
            p: "3.5px 5px",
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        Revenue Splitting
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent>
        <Stepper
          sx={{ mt: 1, mb: 4, mx: "auto", maxWidth: 534 }}
          activeStep={step}
          nonLinear
          alternativeLabel
        >
          {steps.map((label, i) => (
            <Step key={i} completed={isComplete(i)}>
              <StepButton onClick={() => setStep(i)}>{label}</StepButton>
            </Step>
          ))}
        </Stepper>
        {step === 0 && (
          <Box
            sx={{
              maxWidth: 560,
              mx: "auto",
            }}
          >
            <Autocomplete
              className="rounded"
              loading={chargersLoading}
              inputMode="search"
              inputValue={search}
              onInputChange={(e, newValue) => setSearch(newValue)}
              value={null}
              onChange={(e, newValue: any) => {
                if (!newValue) return;
                console.log(newValue);
                setInput({
                  ...input,
                  products: [...products, newValue],
                });
                setSearch("");
              }}
              size="small"
              getOptionLabel={(option) => option.id}
              options={(chargersData?.data?.chargers?.constructor === Array
                ? chargersData.data.chargers
                : []
              ).filter(
                (el: any) => !products.some((el2: any) => el.id === el2.id)
              )}
              sx={{
                my: 2,
                width: 250,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={`Search by Charger ID...`}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start" sx={{ ml: 0.5 }}>
                        <SearchOutlined fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Typography
              variant="body2"
              sx={{ mb: 2, display: "flex", alignItems: "center" }}
            >
              <InfoOutlined fontSize="small" sx={{ mr: 1 }} color="info" />
              All selected chargers should belong to the same host.
            </Typography>
            <TableComponent
              height={330}
              rowsPerPage={5}
              px={0}
              small
              smallPagination
              rows={input.products || []}
              columns={[
                { key: "id", label: "Charger UID" },
                {
                  key: "host",
                  label: "Host",
                  Render: (row) =>
                    row.owner
                      ? !!row.owner.firstName || !!row.owner.lastName
                        ? `${row.owner.firstName || ""} ${
                            row.owner.lastName || ""
                          }`
                        : !!row.owner.email
                        ? row.owner.email
                        : row.owner.phone || "-"
                      : "-",
                },
                {
                  key: "actions",
                  label: "Action",
                  Render: (row) => (
                    <IconButton
                      color="error"
                      size="small"
                      onClick={() =>
                        setInput({
                          ...input,
                          products: input.products.filter(
                            (el: any) => el.id !== row.id
                          ),
                        })
                      }
                    >
                      <DeleteOutline fontSize="small" />
                    </IconButton>
                  ),
                },
              ]}
            />
          </Box>
        )}
        {/* {step === 1 && (
          <Box
            sx={{
              maxWidth: 560,
              minHeight: 300,
              mx: "auto",
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
              gap: 2,
              mt: 2,
            }}
          >
            <Box>
              <FormControl>
                <Typography className="label">Revenue Split Type</Typography>
                <RadioGroup
                  value={input.splitType}
                  onChange={(e) =>
                    setInput({ ...input, splitType: e.target.value })
                  }
                >
                  {splitTypes.map((el, i) => (
                    <FormControlLabel
                      key={i}
                      value={el.value}
                      control={<Radio />}
                      label={el.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
            {isProfitBased && (
              <Box>
                <Typography className="label">Energy Tariff (₹)</Typography>
                <TextField
                  type="number"
                  size="small"
                  value={unitPrice}
                  onChange={(e: any) => {
                    if (/^[0-9.]{0,2}$/.test(e.target.value))
                      setInput({ ...input, unitPrice: e.target.value });
                  }}
                />
              </Box>
            )}
          </Box>
        )} */}
        {step === 1 && (
          <Box
            sx={{
              maxWidth: 560,
              minHeight: 300,
              mx: "auto",
            }}
          >
            <Autocomplete
              sx={{
                my: 2,
                width: 250,
              }}
              className="rounded"
              loading={vendorsLoading}
              inputMode="search"
              inputValue={search}
              onInputChange={(event, newValue) => setSearch(newValue)}
              value={null}
              onChange={(e, newValue: any) => {
                if (!newValue) return;
                let vendor = vendorsData.data.find(
                  (el: any) => el.id === newValue.id
                );
                if (!vendor.users?.[0]) {
                  snackbar.error("No user found for this vendor");
                  return;
                }
                setInput({
                  ...input,
                  splits: [
                    ...input.splits,
                    {
                      vendor: vendor,
                      vendorType: "VENDOR",
                      user: {
                        ...vendor.users[0],
                        _id: vendor.users[0].id,
                      },
                      percentage: 0,
                      flatFeePerUnit: 0,
                      paymentMethod: "",
                    },
                  ],
                });
                setSearch("");
              }}
              size="small"
              options={(vendorsData?.data?.constructor === Array
                ? vendorsData.data
                : []
              )
                .filter(
                  (el: any) =>
                    !input.splits.some((el2: any) => el2.vendor.id === el.id)
                )
                .map((el: any) => ({
                  id: el.id,
                  label: el.name,
                }))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Add Vendors..."
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start" sx={{ ml: 0.5 }}>
                        <SearchOutlined fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Box
              sx={{
                display: "grid",
                // gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
                gap: 2,
              }}
            >
              {splits.map((el: any, i: number) => {
                const paymentMethods =
                  i === 0 || i === 1
                    ? []
                    : (paymentData[i]?.data?.data?.constructor === Array
                        ? paymentData[i].data.data
                        : []
                      ).map((el: any) => ({
                        label: `****${el.bankDetails?.bankAccount?.slice(
                          -4
                        )} - ${el.bankDetails?.bankName}`,
                        value: el._id,
                      }));

                return (
                  <Box
                    key={i}
                    sx={{
                      border: 1,
                      borderRadius: 1,
                      borderColor: "grey.200",
                      gridColumn: i > 1 ? "1/-1" : "unset",
                      height: "min-content",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        px: 2,
                        py: 1,
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography fontWeight={600}>
                          {el.vendor.name}
                        </Typography>
                        {i === 0 && (
                          <Avatar variant="status" sx={{ ml: 1 }}>
                            Primary Company
                          </Avatar>
                        )}
                      </Box>
                      {i > 0 && (
                        <IconButton
                          color="error"
                          onClick={() => {
                            let newSplits = [...splits];
                            newSplits.splice(i, 1);
                            setInput({ ...input, splits: newSplits });
                          }}
                          sx={{ width: "min-content" }}
                        >
                          <DeleteOutline fontSize="small" />
                        </IconButton>
                      )}
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        display: "grid",
                        // gridTemplateColumns: {
                        //   xs: "1fr",
                        //   md: i > 1 ? "1fr 1fr" : "1fr",
                        // },
                        gridTemplateColumns: "1fr 1fr",
                        gap: 2,
                        p: 2,
                      }}
                    >
                      <Box>
                        <Typography className="label">
                          {isPercentBased
                            ? "Share Percentage"
                            : "Flat Fee Per Unit"}
                        </Typography>
                        <TextField
                          type="number"
                          size="small"
                          fullWidth
                          value={
                            isPercentBased ? el.percentage : el.flatFeePerUnit
                          }
                          onChange={(e: any) => {
                            if (/^[0-9.]{0,5}$/.test(e.target.value)) {
                              let newSplits = [...splits];
                              newSplits[i] = {
                                ...newSplits[i],
                                [isPercentBased
                                  ? "percentage"
                                  : "flatFeePerUnit"]: e.target.value,
                              };
                              setInput({ ...input, splits: newSplits });
                            }
                          }}
                          placeholder={
                            isPercentBased
                              ? "Share Percent"
                              : "Flat Fee Per Unit"
                          }
                          autoComplete="off"
                          InputProps={
                            isPercentBased
                              ? {
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      sx={{ mr: 1 }}
                                    >
                                      %
                                    </InputAdornment>
                                  ),
                                }
                              : {
                                  startAdornment: (
                                    <InputAdornment
                                      position="start"
                                      sx={{ ml: 1 }}
                                    >
                                      ₹
                                    </InputAdornment>
                                  ),
                                }
                          }
                        />
                      </Box>
                      {i > 0 && (
                        <Box>
                          <Typography className="label">
                            Payment Method
                          </Typography>
                          <Select
                            size="small"
                            fullWidth
                            displayEmpty
                            value={el.paymentMethod}
                            onChange={(e) => {
                              let newSplits = [...splits];
                              newSplits[i] = {
                                ...newSplits[i],
                                paymentMethod: e.target.value,
                              };
                              setInput({ ...input, splits: newSplits });
                            }}
                            renderValue={(value) =>
                              !value ? (
                                <em>Select</em>
                              ) : (
                                paymentMethods.find(
                                  (el: any) => el.value === value
                                )?.label
                              )
                            }
                          >
                            <MenuItem disabled value="">
                              <em>
                                {paymentData[i].isLoading
                                  ? "Loading..."
                                  : !paymentMethods.length
                                  ? "No Payment Methods Found"
                                  : "Select"}
                              </em>
                            </MenuItem>
                            {paymentMethods.map((el: any, i: number) => (
                              <MenuItem key={i} value={el.value}>
                                {el.label}
                              </MenuItem>
                            ))}
                            <MenuItem
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setPaymentDialog({
                                  open: true,
                                  data: el.user,
                                });
                              }}
                            >
                              <ListItemIcon>
                                <Add />
                              </ListItemIcon>
                              Add New
                            </MenuItem>
                          </Select>
                        </Box>
                      )}
                    </Box>
                  </Box>
                );
              })}
            </Box>
            <PaymentDialog
              open={paymentDialog.open}
              handleClose={() =>
                setPaymentDialog({ ...paymentDialog, open: false })
              }
              data={paymentDialog.data}
            />
            {isPercentBased && cumulativePercentage() !== 100 && (
              <Typography mt={2} variant="body2" color="error.main">
                Total percentage cannot be{" "}
                {cumulativePercentage() > 100 ? "more" : "less"} than 100%
              </Typography>
            )}
            {!isPercentBased && cumulativeFlatFee() === 0 && (
              <Typography mt={2} variant="body2" color="error.main">
                Total fee cannot be 0
              </Typography>
            )}
          </Box>
        )}
        {step === 2 && (
          <Box
            sx={{
              maxWidth: 560,
              mx: "auto",
              "& .table": {
                borderCollapse: "collapse",
                width: 1,
                fontSize: 14,
                lineHeight: "16px",
                "& td": {
                  py: 1.25,
                  px: 2,
                },
                "& .bold": {
                  width: 300,
                  fontWeight: 500,
                },
                "& .header": {
                  px: 2,
                  py: 1,
                  position: "relative",
                  "& td": {
                    position: "absolute",
                    verticalAlign: "middle",
                    bgcolor: (theme: any) => theme.customColors.header,
                    width: 1,
                    borderRadius: "4px",
                    fontSize: 16,
                    fontWeight: 600,
                    "& span": {
                      display: "inline-block",
                      transform: "translateY(1px)",
                    },
                  },
                },
                "& .first > td": {
                  pt: 9,
                },
                "& .last > td": {
                  pb: 3,
                },
              },
            }}
          >
            <table className="table">
              <tbody>
                {[
                  { header: "Add Chargers", onEdit: () => setStep(0) },
                  {
                    label: "Chargers",
                    value: input.products.length === 0 ? "" : input.products,
                    required: true,
                  },

                  // { header: "Split Type", onEdit: () => setStep(1) },
                  // {
                  //   label: "Revenue Split Type",
                  //   value: splitTypes.find((el) => el.value === splitType)
                  //     ?.label,
                  //   required: true,
                  // },
                  // ...(isProfitBased
                  //   ? [
                  //       {
                  //         label: "Energy Tariff",
                  //         value: `₹${unitPrice}`,
                  //       },
                  //     ]
                  //   : []),

                  { header: "Add Vendors", onEdit: () => setStep(1) },
                  {
                    label: "Vendors",
                    value: splits.length === 0 ? "" : splits,
                    required: true,
                  },
                ].map(({ header, onEdit, label, value, required }, i, arr) => {
                  const isFirst = arr[i - 1]?.header;
                  const isLast = !arr[i + 1] || arr[i + 1].header;

                  return (
                    <tr
                      key={i}
                      className={
                        header
                          ? "header"
                          : `${isFirst ? "first" : ""} ${isLast ? "last" : ""}`
                      }
                    >
                      {header ? (
                        <td colSpan={2}>
                          <span>{header}</span>
                          <IconButton
                            sx={{ ml: 1.5 }}
                            children={<EditOutlined />}
                            color="primary"
                            size="small"
                            onClick={onEdit}
                          />
                        </td>
                      ) : (
                        <>
                          <td>{label}</td>
                          <td className="bold">
                            {!!value ? (
                              value.constructor === Array ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                  }}
                                >
                                  {value.map((el: any, i: number) =>
                                    !!el?.charger?._id ? (
                                      i < 5 ? (
                                        <Chip
                                          key={i}
                                          sx={{ mb: 1, mr: 0.25 }}
                                          label={el.id}
                                        />
                                      ) : null
                                    ) : i < 5 ? (
                                      <Chip
                                        key={i}
                                        sx={{ mb: 1, mr: 0.25 }}
                                        label={`${el.vendor.name} - ${
                                          isPercentBased
                                            ? `${el.percentage}%`
                                            : `₹${el.flatFeePerUnit}`
                                        }${
                                          !el.paymentMethod
                                            ? " - Payment Method Required"
                                            : ""
                                        }`}
                                      />
                                    ) : null
                                  )}
                                  {value.length > 5 &&
                                    `+${value.length - 5} more...`}
                                </Box>
                              ) : value.constructor === Array ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  {value}
                                </Box>
                              ) : (
                                <Box display="flex" alignItems="center">
                                  {value}
                                </Box>
                              )
                            ) : (
                              required && (
                                <Box display="flex" alignItems="center">
                                  <ErrorOutline
                                    fontSize="small"
                                    color="error"
                                    sx={{ mr: 1 }}
                                  />
                                  Required
                                </Box>
                              )
                            )}
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {step !== 0 && (
          <Button variant="outlined" onClick={handleBack}>
            Back
          </Button>
        )}
        <Button
          onClick={handleNext}
          variant={
            isComplete(step) || step === steps.length - 1
              ? "contained"
              : "outlined"
          }
          disableElevation
          disabled={step === steps.length - 1 && disabled}
        >
          {step === steps.length - 1
            ? "Save"
            : isComplete(step)
            ? "Next"
            : "Skip"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RevenueSplitDialog;
