import { Circle, HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { HEALTH_URL } from "utils/constants";

import { queryClient } from "index";
import { useMutation } from "react-query";

interface VendorProps {
  open: boolean;
  handleClose: () => void;
  data: any;
  closeDrawer: () => void;
}

const FlagChargerDialog: React.FC<VendorProps> = ({
  open,
  handleClose,
  data,
  closeDrawer,
}) => {
  const [selectedStatus, setSelectedStatus] = useState<string>(
    data?.charger?.health
  );
  const healthStatus = ["HEALTHY", "MODERATE", "CRITICAL", "INACTIVE"];

  const [note, setNote] = useState("");

  const answeredList = ["SELECT", "YES", "NO"];
  const [ansValue, setAnsValue] = useState<any>(answeredList[0]);

  const [phoneList, setPhoneList] = useState<any[]>(["SELECT"]);
  const [phn, setPhn] = useState<any>(phoneList[0]);

  useEffect(() => {
    if (data && open) {
      let list = ["SELECT"];
      setSelectedStatus(data?.charger?.health);

      if (data?.incharge?.phoneNumber) {
        list.push(`Incharge: ${data?.incharge?.phoneNumber}`);
      }
      if (data?.owner?.phone) {
        list.push(`Owner: ${data?.owner?.phone}`);
      }
      if (data?.owner?.altPhone1) {
        list.push(`Alternate No. 1: ${data?.owner?.altPhone1}`);
      }
      if (data?.owner?.altPhone2) {
        list.push(`Alternate No. 2: ${data?.owner?.altPhone2}`);
      }
      setPhoneList(list);
    }
    if (!open) {
      setPhoneList(["SELECT"]);
      setPhn(phoneList[0]);
      setAnsValue(answeredList[0]);
      setNote("");
      setSelectedStatus("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, open]);

  const mutation = useMutation(
    "updateHealth",
    () =>
      authorizedFetch(`${HEALTH_URL}/product/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: "1234",
        },
        body: {
          productId: data?.charger?.chargerId,
          productType: "CHARGING_SOCKET",
          answered: ansValue === "YES" ? true : ansValue === "NO" ? false : "",
          contactedThrough: phn,
          note: note,
          health: selectedStatus,
        },
      }),
    {
      onSuccess: () => {
        setLoader(false);
        snackbar.success(`Charger updated`);
        queryClient.resetQueries("getChargers");
        handleClose();
        closeDrawer();
      },
      onError: () => {
        setLoader(false);
        snackbar.error(`Error updating charger`);
      },
    }
  );

  function onSave() {
    setLoader(true);
    mutation.mutate();
  }

  const disabled = ansValue === "SELECT" || phn === "SELECT" || note === "";

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: 400,
          width: 1,
          "& .MuiInputBase-root": {
            fontSize: 14,
            borderRadius: 1,
            p: "3.5px 5px",
          },
        },
      }}
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          Flag Charger
          <IconButton
            children={<HighlightOff />}
            color="inherit"
            onClick={handleClose}
            sx={{ transform: "translate(8px, -8px)" }}
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        {/* <Typography
          sx={{
            py: 2,
            display: "flex",
            pt: 2,
            fontSize: 14,
            fontWeight: "light",
          }}
        >
          No. of Contact Attempts:
          <strong style={{ marginLeft: 15, fontWeight: "bold" }}>2</strong>
        </Typography> */}
        <Typography
          sx={{
            mb: 1,
            display: "flex",
            alignItems: "center",
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          {data.charger.chargerId}:
          <Tooltip
            placement="right"
            title={
              data.charger.health === "HEALTHY"
                ? "Used Within Last 15 Days"
                : data.charger.health === "MODERATE"
                ? "Used Between Last 16-30 Days"
                : data.charger.health === "CRITICAL"
                ? "Used Between Last 31-45 Days"
                : data.charger.health === "INACTIVE"
                ? "Used Before 45 Days"
                : "Not available"
            }
          >
            <IconButton
              size="small"
              sx={{
                ml: 1,
                color:
                  data.charger.health === "HEALTHY"
                    ? "#01A229"
                    : data.charger.health === "MODERATE"
                    ? "#62D16A"
                    : data.charger.health === "CRITICAL"
                    ? "#FFCC27"
                    : data.charger.health === "INACTIVE"
                    ? "#EB5C5C"
                    : "Not available",
              }}
              children={<Circle fontSize="small" />}
            />
          </Tooltip>
        </Typography>

        {/* <Box sx={{ display: "flex", pt: 2 }}>
          <Typography sx={{ py: 2, display: "flex" }}>
            Add Contact Attempt:
          </Typography>
          <ButtonGroup
            size="small"
            aria-label="small outlined button group"
            sx={{ height: 35, mt: 1, ml: 2 }}
          >
            {displayCounter && <Button onClick={handleDecrement}>-</Button>}
            {displayCounter && <Button disabled>{state}</Button>}
            <Button onClick={handleIncrement}>+</Button>
          </ButtonGroup>
        </Box> */}

        <Typography sx={{ pt: 2, pb: 1 }}>Contacted through:</Typography>
        <Select
          size="small"
          fullWidth
          className="primary"
          value={phn}
          onChange={(e) => setPhn(e.target.value)}
        >
          {phoneList?.map((filter, i) => (
            <MenuItem key={i} value={filter}>
              {filter}
            </MenuItem>
          ))}
        </Select>
        <Typography sx={{ pt: 2, pb: 1 }}>Answered:</Typography>
        <Select
          size="small"
          fullWidth
          className="primary"
          value={ansValue}
          onChange={(e) => setAnsValue(e.target.value)}
        >
          {answeredList?.map((filter, i) => (
            <MenuItem key={i} value={filter}>
              {filter}
            </MenuItem>
          ))}
        </Select>
        <Typography sx={{ pt: 2, pb: 1 }}>Change Health Status:</Typography>
        <Select
          size="small"
          fullWidth
          className="primary"
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
        >
          {healthStatus?.map((filter, i) => (
            <MenuItem key={i} value={filter}>
              {filter}
            </MenuItem>
          ))}
        </Select>
        <Typography sx={{ pt: 2, pb: 1 }}>Description</Typography>
        <TextField
          fullWidth
          multiline
          rows={6}
          placeholder="Type a message..."
          value={note}
          onChange={(e: any) => {
            if (e.target.value.length <= 30) setNote(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onSave} disabled={disabled}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FlagChargerDialog;
