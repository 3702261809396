import { Box, Paper, Tab, Tabs } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  GlobalState,
  appBar,
  authorizedFetch,
  drawer,
  getUrlParams,
} from "utils";
import { BOLT_URL } from "utils/constants";
import AppBar from "./components/AppBar";
import BookingFilters from "./components/BookingFilters";
import BookingList from "./components/BookingList";
import ChargerFilters from "./components/ChargerFilters";
import ChargerList from "./components/ChargerList";
import DrawerContent from "./components/ChargerList/DrawerContent";

export const chargerFiltersObj = {
  sortBy: "CREATED_AT_DESC",
  health: "", // Space separated
  usageType: "", // Space separated
  mode: "", // Space separated
  productType: "", // Space separated
  city: "", // Underscore separated
  hidden: false,
  searchBy: "chargerId", // chargerId | owner | phone
  search: "",
};

export const bookingsFilterObj = {
  dateFrom: moment().subtract({ days: 7 }).format("YYYY-MM-DD"),
  dateTo: moment().format("YYYY-MM-DD"),
  city: "", // Underscore separated
  status: "", // Space separated
  refundStatus: "", // Space separated
  anomaly: false,
  searchBy: "bookingId", // bookingId | partialBookingId | chargerId | username | phone | cugName
};

const Chargers = ({ containerRef, ...props }: any) => {
  const {
    masterView,
    drawer: drawerState,
    company,
  } = useSelector((state: GlobalState) => state.global);
  const isCUGMonitorAccount = company?.type === "CUG_VENDOR";

  const [tab, setTab] = useState(0);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const first = pageSize;
  const skip = pageSize * (page - 1);

  const [chargerFilters, setChargerFilters] = useState<any>({
    ...chargerFiltersObj,
  });

  const chargerUrlParams = getChargerUrlParams(chargerFilters);
  const chargersUrl = `${BOLT_URL}/company/chargers?${chargerUrlParams}&first=${first}&skip=${skip}`;
  const { isLoading: chargersLoading, data: chargersData } = useQuery(
    [
      "getChargers",
      first,
      skip,
      chargerUrlParams,
      masterView,
      isCUGMonitorAccount,
    ],
    () =>
      authorizedFetch(chargersUrl, {
        headers: isCUGMonitorAccount
          ? {
              monitoringAccount: isCUGMonitorAccount,
            }
          : {
              master: masterView,
            },
      })
  );

  const [bookingFilters, setBookingFilters] = useState<any>({
    ...bookingsFilterObj,
  });

  const bookingUrlParams = getBookingUrlParams(
    bookingFilters,
    isCUGMonitorAccount
  );
  const bookingsUrl = `${BOLT_URL}/company/bookings?${bookingUrlParams}&first=${first}&skip=${skip}`;
  const { isLoading: bookingsLoading, data: bookingsData } = useQuery(
    [
      "getBookings",
      first,
      skip,
      bookingUrlParams,
      masterView,
      isCUGMonitorAccount,
    ],
    () =>
      authorizedFetch(bookingsUrl, {
        headers: isCUGMonitorAccount
          ? {
              monitoringAccount: isCUGMonitorAccount,
            }
          : {
              master: masterView,
            },
      })
  );

  useEffect(() => {
    if (window.location.search) {
      let searchCug = window.location.search.slice(1).split("%20").join(" ");
      setTab(1);
      setBookingFilters((prev: any) => ({
        ...prev,
        searchBy: "cug",
        search: searchCug,
      }));
    }
    return () => {
      setTab(0);
      setBookingFilters((prev: any) => ({ ...prev, searchBy: "bookingId" }));
    };
  }, []);

  useEffect(() => {
    appBar.open(<AppBar />);

    if (window.location.search) {
      let params = getUrlParams();
      let id = params.find((el: any) => Boolean(el.id))?.id;
      if (id) {
        drawer.open(<DrawerContent chargerId={id} openTab={0} />);
        setChargerFilters((prev: any) => ({ ...prev, search: id }));
        // scrollTo();
      }
      let health = params.find((el: any) => Boolean(el.health))?.health;
      if (health) {
        setChargerFilters((prev: any) => ({ ...prev, health }));
        // scrollTo();
      }
    }

    return () => {
      appBar.close();
      drawer.close();
    };
  }, []);

  useEffect(() => {
    setPage(1);
  }, [chargerUrlParams, bookingUrlParams, tab, setPage]);

  return (
    <Box
      sx={{
        px: { xs: 2, md: 7 },
        pt: { xs: 2, md: 4.75 },
        pb: { xs: 3, md: 7 },
      }}
    >
      <Paper
        sx={{
          width: 1,
          overflow: "hidden",
          borderRadius: "14px",
          boxShadow: (theme) => theme.customShadows.card,
        }}
      >
        <Box
          sx={{
            width: 1,
            overflowX: "auto",
            p: { xs: 2, md: 3 },
            pb: 2.75,
            display: "flex",
            flexDirection: {
              xs: "column",
              md: drawerState.open ? "column" : "row",
              xl: "row",
            },
            alignItems: {
              xs: "flex-start",
              md: drawerState.open ? "flex-start" : "center",
              xl: "center",
            },
            justifyContent: {
              xs: "flex-start",
              md: drawerState.open ? "flex-start" : "space-between",
              xl: "space-between",
            },
          }}
        >
          <Box
            width="fit-content"
            mb={{ xs: 1.5, md: drawerState.open ? 1.5 : 0, xl: 0 }}
          >
            <Tabs
              className="dense"
              value={tab}
              onChange={(e, value) => setTab(value)}
              sx={{
                "& .MuiTab-root": {
                  fontSize: 16,
                  fontWeight: 600,
                  letterSpacing: "normal",
                },
              }}
            >
              <Tab label="Chargers" />
              <Tab label="Bookings" />
            </Tabs>
          </Box>
          {tab === 0 && (
            <ChargerFilters
              {...{
                filters: chargerFilters,
                setFilters: setChargerFilters,
                urlParams: chargerUrlParams,
              }}
            />
          )}
          {tab === 1 && (
            <BookingFilters
              {...{
                filters: bookingFilters,
                setFilters: setBookingFilters,
                urlParams: bookingUrlParams,
              }}
            />
          )}
        </Box>
        {tab === 0 && (
          <ChargerList
            {...{
              page,
              pageSize,
              setPage,
              setPageSize,
              sortBy: chargerFilters.sortBy,
              setSortBy: (sortBy: string) =>
                setChargerFilters({ ...chargerFilters, sortBy }),
              isLoading: chargersLoading,
              data: chargersData,
            }}
          />
        )}
        {tab === 1 && (
          <BookingList
            {...{
              page,
              setPage,
              pageSize,
              setPageSize,
              isLoading: bookingsLoading,
              data: bookingsData,
              masterView,
              onChargerClick: (chargerId: string) => {
                drawer.open(
                  <DrawerContent chargerId={chargerId} openTab={0} />
                );
              },
            }}
          />
        )}
      </Paper>
    </Box>
  );
};

function getChargerUrlParams(filters: any) {
  const {
    sortBy,
    health,
    usageType,
    mode,
    productType,
    city,
    hidden,
    searchBy,
    search,
  } = filters;

  let urlParams = `orderBy=${sortBy}&hidden=${hidden}`;

  if (health) urlParams += `&health=${health}`;
  if (usageType) urlParams += `&usageType=${usageType}`;
  if (mode) urlParams += `&mode=${mode}`;
  if (productType) urlParams += `&productType=${productType}`;
  if (city) urlParams += `&city=${city}`;
  if (search)
    urlParams += `&${searchBy}=${
      searchBy === "phone"
        ? encodeURIComponent(search.replace("+", "\\+"))
        : search
    }`;

  return urlParams;
}

function getBookingUrlParams(filters: any, isCUGMonitorAccount: boolean) {
  const {
    dateFrom,
    dateTo,
    city,
    status,
    refundStatus,
    anomaly,
    searchBy,
    search,
  } = filters;

  let urlParams = `orderBy=BOOKING_TIME_DESC`;

  if (!isCUGMonitorAccount)
    urlParams += `&dateFrom=${dateFrom}&dateTo=${dateTo}&anomaly=${anomaly}`;
  if (city) urlParams += `&city=${city}`;
  if (status) urlParams += `&status=${status}`;
  if (refundStatus) urlParams += `&refundStatus=${refundStatus}`;
  if (search)
    urlParams += `&${searchBy}=${
      searchBy === "phone"
        ? encodeURIComponent(search.replace("+", "\\+"))
        : search
    }`;

  return urlParams;
}

export default Chargers;
