import { Box } from "@mui/material";

const InfoLabel = ({ label, value, dense, ml, mr }: any) => {
  return (
    <Box
      sx={{
        ml: ml || 0,
        mr: mr || 0,
        px: dense ? 1.25 : 2.75,
        py: 0.75,
        display: "flex",
        alignItems: "center",
        fontSize: { xs: 10, md: 12 },
        fontWeight: 700,
        lineHeight: "16.37px",
        background: "#C4C4C421",
        borderRadius: 10,
        whiteSpace: "nowrap",
        "& .label": {
          mr: 1,
          color: "text.primary",
        },
        "& .value": {
          color: (theme) => theme.customColors.text.grey,
        },
      }}
    >
      {label && <span className="label">{label}</span>}
      <span className="value">{value}</span>
    </Box>
  );
};

export default InfoLabel;
