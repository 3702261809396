import { Box } from "@mui/material";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Dashboard from "./Dashboard";
import RangePicker from "components/RangePicker";
import { useState } from "react";
import { sub } from "date-fns";

const Overview = () => {
  const [range, setRange] = useState<any>([
    sub(new Date(), { months: 1 }),
    new Date(),
  ]);

  return (
    <>
      <Box
        width={1}
        mb={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Breadcrumbs />
        <RangePicker range={range} setRange={setRange} />
      </Box>
      <Box
        sx={{
          width: 1,
        }}
      >
        <Dashboard range={range} />
      </Box>
    </>
  );
};

export default Overview;
