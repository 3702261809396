import "regenerator-runtime/runtime";
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers";
import rootSaga from "./saga";
import { saveState } from "./utils/storageManager";
import throttle from "lodash/throttle";

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  process.env.NODE_ENV !== "production" &&
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

// Save state to localStorage
store.subscribe(
  throttle(() => {
    saveState({
      global: {
        firebase: store.getState().global.firebase,
        token: store.getState().global.token,
        user: store.getState().global.user,
        company: store.getState().global.company,
        prefersDarkMode: store.getState().global.prefersDarkMode,
        activeSection: store.getState().global.activeSection,
        reports: store.getState().global.reports,
        vehicleReports: store.getState().global.vehicleReports,
        lastReadNotification: store.getState().global.lastReadNotification,
      },
    });
  }),
  1000
);

sagaMiddleware.run(rootSaga);

export default store;
