import { Brightness4, Brightness7 } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { toggleDarkMode } from "actions";
import Banner from "assets/images/banner.png";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router";
import { getDarkModePreference, GlobalState } from "utils";
import CompanySignUp from "./CompanySignUp";
import ResetPassword from "./ResetPassword";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import UserSignUp from "./UserSignUp";

export const Heading = ({ value }: { value: string }): JSX.Element => {
  let isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );
  return (
    <Typography
      sx={{
        mb: 1,
        fontSize: 28,
        fontWeight: 600,
        color: isDarkMode ? "common.white" : "common.black",
      }}
    >
      {value}
    </Typography>
  );
};

const Auth = () => {
  const [view, setView] = useState("sign-in");
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  let isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );

  const dispatch = useDispatch();
  const handleThemeChage = () => {
    dispatch(toggleDarkMode(!isDarkMode));
  };

  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  // const circleSize: (x: number) => object = (x) => {
  //   let size = 360 + 190 * x;
  //   return { height: size, width: size };
  // };

  return (
    <>
      <Box
        sx={{
          height: 1,
          width: 1,
        }}
      >
        <Box
          sx={{
            minHeight: 1,
            minWidth: 1,
            display: "grid",
            gridTemplateColumns: { xs: "1fr", md: "1fr 2fr" },
            gridTemplateRows: { xs: "min-content 1fr", md: "auto" },
            bgcolor: "background.default",
          }}
        >
          {isMdUp && (
            <Box
              sx={{
                // bgcolor: "#eee",
                width: 1,
                height: 1,
                overflow: "hidden",
                "& img": {
                  width: 1,
                  height: 1,
                  objectFit: "cover",
                  objectPosition: "right",
                },
              }}
            >
              <img src={Banner} alt="" />
            </Box>
          )}
          {/* <Box
            sx={{
              height: { xs: 65, md: 1 },
              width: 1,
              borderRadius: (theme) => ({
                xs: theme.spacing(2, 2, 0, 0),
                md: theme.spacing(2, 0, 0, 2),
              }),
              position: "relative",
              display: "flex",
              alignItems: "center",
              bgcolor: isDarkMode ? "primary.dark" : "primary.main",
              color: "white",
              overflow: "hidden",
              "& .circle": {
                position: "absolute",
                bottom: 0,
                right: 0,
                transform: "translateX(56%) translateY(70%) scaleX(0.85)",
                borderRadius: "50%",
                border: `1px solid rgba(255, 255, 255, ${
                  isDarkMode ? 0.2 : 0.3
                })`,
              },
              "& .circle1": { ...circleSize(0) },
              "& .circle2": { ...circleSize(1) },
              "& .circle3": { ...circleSize(2) },
              "& .circle4": { ...circleSize(3) },
              "& .circle5": { ...circleSize(4) },
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: { xs: 20, md: 30 },
                left: { xs: 20, md: 30 },
              }}
            >
              <a href="https://revos.in">
                <RevosLogo width={isMdUp ? 130 : 100} color="white" />
              </a>
            </Box>
            
            <span className="circle circle1" />
            <span className="circle circle2" />
            <span className="circle circle3" />
            <span className="circle circle4" />
            <span className="circle circle5" />
          </Box> */}
          <Box
            sx={{
              width: 1,
              px: { xs: 4, md: 6 },
              py: { xs: 8, md: 12 },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              // border: theme => `1px solid ${theme.palette.divider}`,
              // borderRadius: theme => theme.spacing(0, 2, 2, 0),
              // borderLeft: 0
            }}
          >
            <Switch>
              <Route
                exact
                path="/user/invite/:inviteData"
                component={UserSignUp}
              />
              <Route
                exact
                path="/company/invite/:inviteData"
                component={CompanySignUp}
              />
              <Route
                exact
                path="/"
                render={() =>
                  view === "sign-in" ? (
                    <SignIn setView={setView} />
                  ) : view === "sign-up" ? (
                    <SignUp setView={setView} />
                  ) : (
                    <ResetPassword setView={setView} />
                  )
                }
              />
              <Redirect to="/" />
            </Switch>
            {/* {view !== "sign-up" && ( */}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: 20,
                display: "flex",
                alignItems: "center",
                pt: 2,
              }}
            >
              {/* <Box>
              <Button
                color="inherit"
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                }}
                startIcon={<Language />}
                endIcon={<ArrowDropDown />}
              >
                English (US)
              </Button>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>English (US)</MenuItem>
                <MenuItem disabled onClick={handleClose}>
                  Chinese
                </MenuItem>
              </Menu>
            </Box> */}
              <Tooltip title={isDarkMode ? "Light theme" : "Dark theme"}>
                <IconButton onClick={handleThemeChage} color="inherit">
                  {isDarkMode ? (
                    <Brightness7 fontSize="small" />
                  ) : (
                    <Brightness4 fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
            {/* )} */}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Auth;
