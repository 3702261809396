import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { useMutation } from "react-query";
import { Circle, HighlightOff } from "@mui/icons-material";
import { HEALTH_URL } from "utils/constants";

interface Props {
  open: boolean;
  handleClose: () => void;
  data: any;
}

const NotificationDialog: React.FC<Props> = ({ open, handleClose, data }) => {
  const [messageInput, setMessageInput] = useState("");

  const [phoneList, setPhoneList] = useState<any[]>([]);
  const [phoneInput, setPhoneInput] = useState("");

  const [emailList, setEmailList] = useState<any[]>([]);
  const [emailInput, setEmailInput] = useState("");

  useEffect(() => {
    if (data && open) {
      let phoneList = [];
      if (data?.incharge?.phoneNumber) {
        phoneList.push(`${data?.incharge?.phoneNumber}`);
      }
      if (
        data?.owner?.phone &&
        data?.owner?.phone !== data?.incharge?.phoneNumber
      ) {
        phoneList.push(`${data?.owner?.phone}`);
      }
      if (data?.owner?.altPhone1) {
        phoneList.push(`${data?.owner?.altPhone1}`);
      }
      if (data?.owner?.altPhone2) {
        phoneList.push(`${data?.owner?.altPhone2}`);
      }
      setPhoneList(phoneList);

      let emailList = [];
      if (data?.incharge?.email) {
        emailList.push(`${data?.incharge?.email}`);
      }
      if (data?.owner?.email && data?.owner?.email !== data?.incharge?.email) {
        emailList.push(`${data?.owner?.email}`);
      }

      setEmailList(emailList);

      setPhoneInput("");
      setEmailInput("");
      setMessageInput("");
    }
  }, [data, open]);

  const mutation = useMutation(
    "sendNotificatons",
    () =>
      authorizedFetch(`${HEALTH_URL}/notify/incharge`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          productId: data?.charger?.chargerId,
          productType: "CHARGING_SOCKET",
          phone: phoneInput,
          email: emailInput,
          subject: "Charger Health Notification",
          message: messageInput,
        },
      }),
    {
      onSuccess: () => {
        setLoader(false);
        snackbar.success(`Notification sent`);
      },
      onError: () => {
        setLoader(false);
        snackbar.error(`Error sending notification`);
      },
    }
  );

  function onSend() {
    setLoader(true);
    mutation.mutate();
    handleClose();
  }

  const disabled = [phoneInput, emailInput, messageInput].includes("");

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          Notify Owner
          <Divider flexItem orientation="vertical" sx={{ mx: 1.5 }} />
          <Typography color="text.secondary">
            {data?.charger?.chargerId}
          </Typography>
          <Tooltip
            followCursor
            title={
              data?.charger?.health === "HEALTHY"
                ? "Used Within Last 15 Days"
                : data?.charger?.health === "MODERATE"
                ? "Used Between Last 16-30 Days"
                : data?.charger?.health === "CRITICAL"
                ? "Used Between Last 31-45 Days"
                : data?.charger?.health === "INACTIVE"
                ? "Used Before 45 Days"
                : "Not available"
            }
          >
            <IconButton
              size="small"
              sx={{
                ml: 0.5,
                color:
                  data?.charger?.health === "HEALTHY"
                    ? "#01A229"
                    : data?.charger?.health === "MODERATE"
                    ? "#62D16A"
                    : data?.charger?.health === "CRITICAL"
                    ? "#FFCC27"
                    : data?.charger?.health === "INACTIVE"
                    ? "#EB5C5C"
                    : "Not available",
              }}
              children={<Circle fontSize="small" />}
            />
          </Tooltip>
        </Box>
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "grid",
            gap: 2,
          }}
        >
          <Box
            sx={{
              my: 1,
              display: "flex",
            }}
          >
            <Typography color="text.secondary">Name</Typography>
            <Typography sx={{ ml: 2 }}>
              {data?.owner?.firstName || ""} {data?.owner?.lastName || ""}
            </Typography>
          </Box>
          <Box>
            <Typography className="label">Phone Number</Typography>
            <Select
              size="small"
              fullWidth
              className="primary"
              value={phoneInput}
              onChange={(e: any) => {
                setPhoneInput(e.target.value);
              }}
              displayEmpty
              renderValue={
                phoneInput !== "" ? undefined : () => <em>Select</em>
              }
            >
              {phoneList?.map((filter, i) => (
                <MenuItem key={i} value={filter}>
                  {filter}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <Typography className="label">Email</Typography>
            <Select
              size="small"
              fullWidth
              className="primary"
              value={emailInput}
              onChange={(e: any) => {
                setEmailInput(e.target.value);
              }}
              displayEmpty
              renderValue={
                emailInput !== "" ? undefined : () => <em>Select</em>
              }
            >
              {emailList?.map((filter, i) => (
                <MenuItem key={i} value={filter}>
                  {filter}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <Typography className="label">Message</Typography>
            <TextField
              fullWidth
              id="outlined-multiline-static"
              multiline
              rows={10}
              placeholder="Type a message..."
              value={messageInput}
              onChange={(e: any) => {
                setMessageInput(e.target.value);
              }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button disabled={disabled} variant="contained" onClick={onSend}>
          Notify
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotificationDialog;
