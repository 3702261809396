import { Box } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import AddModelDialog from "../../Common/AddModelDialog";
import Grid from "./Grid";
import List from "./List";
import { useLocation } from "react-router-dom";
import AppBar from "./AppBar";
import { appBar } from "utils";

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const Assembly = () => {
  let query = useQuery();

  const [tab, setTab] = useState(0);

  const containerRef = useRef<any>(null);

  const [addModelDialog, setAddModelDialog] = useState(false);

  const [view, setView] = useState<"grid" | "list">("grid");

  const [modelFilter, setModelFilter] = useState<any>(null);
  const [search, setSearch] = useState("");
  const [modelSearch, setModelSearch] = useState("");
  const [updateStatus, setUpdateStatus] = useState(0);

  useEffect(() => {
    if (containerRef.current)
      containerRef.current.scrollIntoView({ behavior: "smooth" });
  }, [view]);

  useEffect(() => {
    if (query.get("view") === "list") setView("list");
    if (query.get("tab") === "2") setTab(2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    appBar.open(
      <AppBar
        {...{
          updateStatus,
          setUpdateStatus,
          view,
          tab,
          modelFilter,
          setView,
          setAddModelDialog,
          setModelSearch,
        }}
      />
    );
    return () => {
      appBar.close();
    };
  }, [tab, updateStatus, view, modelFilter]);

  return (
    <>
      <AddModelDialog
        open={addModelDialog}
        handleClose={() => setAddModelDialog(false)}
        isRental={false}
      />
      <Box
        ref={containerRef}
        sx={{
          p: { xs: 1.5, md: 4 },
          pt: { xs: 2, md: 3 },
        }}
      >
        {view === "grid" ? (
          <Grid
            search={search}
            setView={setView}
            setModelFilter={setModelFilter}
            setTab={setTab}
            modelSearch={modelSearch}
          />
        ) : (
          <List
            search={search}
            model={modelFilter}
            clearFilter={() => setModelFilter(null)}
            view={view}
            tab={tab}
            setTab={setTab}
            setSearch={setSearch}
            updateStatus={updateStatus}
          />
        )}
      </Box>
    </>
  );
};

export default Assembly;
