import { useEffect, useState } from "react";
import AppBar from "./AppBar";
import { appBar, authorizedFetch, drawer } from "utils";
import { Box, Paper, useMediaQuery, useTheme } from "@mui/material";
import moment from "moment";
import Table from "components/Table";
import { RETAIL_URL } from "utils/constants";
import { useQuery } from "react-query";
import InfoDrawer from "./InfoDrawer";
import Search from "components/Search";

const OTA = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");

  const first = pageSize;
  const skip = pageSize * (page - 1);

  const {
    isLoading: otaLoading,
    data: otaData,
    refetch: refectData,
  } = useQuery(["getOtaData", first, skip, search], () => authorizedFetch(`${RETAIL_URL}/inventory/ota?first=${first}&skip=${skip}&search=${search}`));

  useEffect(() => {
    appBar.open(<AppBar ota={otaData} />);
    return () => {
      appBar.close();
      drawer.close();
    };
  }, [otaData]);

  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        // px: { xs: 2, md: 7 },
        // pt: { xs: 2, md: 4.75 },
        pb: { xs: 3, md: 7 },
      }}
    >
      <Paper
        sx={{
          width: 1,
          boxShadow: (theme) => theme.customShadows.card,
          borderRadius: 2,
          pt: 3,
        }}
      >
        <Box
          sx={{
            px: isMdDown ? 1 : 2.5,
            py: 2.5,
            display: isMdDown ? "" : "flex",
            flexDirection: isMdDown ? "" : "row-reverse",
            width: "100%",
          }}
        >
          <Search
            handleSearch={(input) => setSearch(input)}
            persist
            enableClear
          />
        </Box>
        {console.log(otaData?.data, 'data')}
        <Table
          loading={otaLoading}
          rowCount={otaData?.meta?.totalCount}
          rows={otaData?.data || []}
          serverSidePagination
          activePage={page}
          onRowClick={(row) =>
            drawer.open(<InfoDrawer ota={row} refectData={refectData} />)
          }
          activePageSize={pageSize}
          onPageChange={(value) => setPage(value)}
          onPageSizeChange={(value) => setPageSize(value)}
          columns={[
            { key: "fileName", label: "File Name" },
            { key: "company.name", label: "Company" },
            { key: "vehicleModel.name", label: "Model" },
            {
              key: "speedometer", label: "Speedometer",
              valueGetter(row) {
                return row?.vehicleModel?.components[0]?.modelId[0]?.key
              },
            },
            { key: "type", label: "Update Type" },
            { key: "version", label: "Version Number" },
            {
              key: "createddAt",
              label: "Created On",
              Render: (row: any) => (
                <Box>{moment(row.createdAt).format("YYYY-MM-DD")}</Box>
              ),
            },
            // {
            //   key: "actions",
            //   label: "Actions",
            //   Render: (row: any) => (
            //     <IconButton
            //       size="small"
            //       sx={{ color: (theme) => theme.customColors.action }}
            //       children={<InfoOutlined fontSize="small" />}
            //       onClick={() =>
            //         drawer.open(<InfoDrawer key={row._id} ota={row} />)
            //       }
            //     />
            //   ),
            // },
          ]}
        />
      </Paper>
    </Box>
  );
};

export default OTA;
