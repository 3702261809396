import { Box, Button } from "@mui/material";
import { Toc, WidgetsOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { getDarkModePreference, GlobalState } from "utils";

export type View = "grid" | "list";

interface Props {
  view: View;
  setView: (view: View) => void;
  extras?: () => JSX.Element;
}

const ViewSelector: React.FC<Props> = ({ view, setView, extras }) => {
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );

  return (
    <Box
      sx={{
        display: "flex",
        "& button": {
          minWidth: 40,
          height: 40,
          p: 0,
          border: 1,
          borderColor: (theme) => theme.customColors.border,
          bgcolor: isDarkMode ? "background.default" : "#fff",
          "&.active": {
            bgcolor: "primary.main",
            color: "#fff",
            border: "none",
          },
        },
      }}
    >
      {extras && extras()}
      <Button
        className={view === "grid" ? "active" : ""}
        sx={{ mr: { xs: 1, md: 2 } }}
        onClick={() => {
          setView("grid");
        }}
      >
        <WidgetsOutlined />
      </Button>
      <Button
        className={view === "list" ? "active" : ""}
        onClick={() => {
          setView("list");
        }}
      >
        <Toc />
      </Button>
    </Box>
  );
};

export default ViewSelector;
