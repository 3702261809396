import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDarkModePreference, GlobalState, setLoader } from "utils";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { resetPassword } from "actions";
import { subdomain } from "utils/constants";

const ResetPassword = ({ setView }: any) => {
  let isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );
  const [valid, setValid] = useState(true);
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();

  function handleSubmit() {
    if (email === "") setValid(false);
    else {
      setValid(true);
      // TODO: send password reset link to email
      dispatch(resetPassword(email));
      setLoader(true);
    }
  }
  return (
    <>
      <Helmet>
        <title>Reset password - {subdomain.name}</title>
      </Helmet>
      <Box sx={{ width: 1, maxWidth: 350, display: "grid" }}>
        <Box mb={3} display="flex" alignItems="center">
          <Tooltip title="Go back">
            <IconButton
              sx={{ transform: "translateX(-10px)" }}
              onClick={() => setView("sign-in")}
            >
              <ArrowBack />
            </IconButton>
          </Tooltip>
          <Typography
            variant="h5"
            sx={{
              fontSize: "1.5em",
              fontWeight: 600,
              color: isDarkMode ? "common.white" : "common.black",
            }}
          >
            Reset your password
          </Typography>
        </Box>
        <TextField
          sx={{ mb: 3 }}
          label="Email"
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!valid && email === ""}
          helperText={!valid && email === "" && "Enter an email"}
        />
        <Box>
          <Button variant="contained" onClick={handleSubmit}>
            Reset password
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ResetPassword;
