import { ContentCopy, HighlightOff, Link } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { AUTH_URL, getSectionName } from "utils/constants";
import validator from "validator";

const CompanyInviteDialog = ({ open, handleClose }: any) => {
  const [linkDialog, setLinkDialog] = useState<{ open: boolean; data: any }>({
    open: false,
    data: null,
  });
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [enabledSections, setEnabledSections] = useState<any>({
    retail: true,
    rental: true,
    charger: true,
  });

  let { retail, rental, charger } = enabledSections;

  function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault();

    setLoader(true);
    let permissions: string[] = [];

    if (retail && rental && charger) {
      permissions = ["dashboard:*"];
    } else {
      if (retail) permissions.push("dashboard:retail:*");
      if (rental) permissions.push("dashboard:rental:*");
      if (charger) permissions.push("dashboard:charger:*");
    }
    authorizedFetch(`${AUTH_URL}/company/invite`, {
      method: "POST",
      body: {
        email,
        name,
        permissions,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      setLoader(false);
      let { status, data } = response;
      if (status >= 400) {
        snackbar.error(
          status === 409 ? "Company already exists" : "An error occurred",
        );
      } else {
        let link = data.link;
        setLinkDialog({ open: true, data: { name, email, link } });
      }
    });
  }

  const disabled = !retail && !rental && !charger;

  useEffect(() => {
    if (!open) {
      setEmail("");
      setName("");
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        Invite New Company
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Box py={1}>
            <Box
              display="grid"
              gridTemplateColumns="auto 1fr"
              alignItems="center"
              gap={2}
              columnGap={3}
            >
              <Typography sx={{ fontWeight: 600 }}>Name</Typography>
              <TextField
                autoComplete="off"
                value={name}
                onChange={(e) =>
                  setName(
                    e.target.value?.length > 0
                      ? e.target.value.replace(/[^a-zA-Z ]/, "")
                      : e.target.value,
                  )
                }
                placeholder="Company Name"
                fullWidth
                size="small"
                required
                inputProps={{ maxLength: 30 }}
                helperText={
                  name.length === 30 ? "Maximum character limit reached" : ""
                }
              />
              <Typography sx={{ fontWeight: 600 }}>Email</Typography>
              <TextField
                autoComplete="off"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Admin Email"
                fullWidth
                size="small"
                type="email"
                required
                error={email !== "" && !validator.isEmail(email)}
                helperText={
                  email !== "" && !validator.isEmail(email)
                    ? "Invalid email address"
                    : ""
                }
              />
              <Typography sx={{ fontWeight: 600, alignSelf: "start", pt: 1 }}>
                Services
              </Typography>
              <Box>
                {["retail", "rental", "charger"].map((el, i) => (
                  <FormControlLabel
                    key={i}
                    control={
                      <Checkbox
                        checked={enabledSections[el]}
                        onChange={(e) =>
                          setEnabledSections((prev: any) => ({
                            ...prev,
                            [el]: e.target.checked,
                          }))
                        }
                      />
                    }
                    label={getSectionName(el)}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit" disabled={disabled}>
            Invite
          </Button>
          <LinkDialog
            open={linkDialog.open}
            handleClose={() =>
              setLinkDialog((prev) => ({ ...prev, open: false }))
            }
            data={linkDialog.data}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

const LinkDialog = ({ open, handleClose, data }: any) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      // TransitionComponent={Transition}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            transform: "translateY(-4px)",
          }}
        >
          <Avatar sx={{ mr: 1.5, bgcolor: "secondary.main" }}>
            <Link sx={{ color: "white" }} />
          </Avatar>
          Invite Link
        </Box>
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent>
        <Box pt={1.5} pb={6}>
          <Typography mb={2} sx={{ wordWrap: "revert", lineHeight: "1.75em" }}>
            Company Name: <b>{data?.name}</b>
            <br />
            Admin Email: <b>{data?.email}</b>
          </Typography>
          <TextField value={data?.link} size="small" fullWidth />
          <Button
            variant="contained"
            // color="secondary"
            startIcon={<ContentCopy />}
            sx={{ mt: 2 }}
            onClick={() => {
              navigator.clipboard.writeText(data?.link);
              snackbar.success("Link copied to clipboard");
            }}
          >
            Copy
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CompanyInviteDialog;
