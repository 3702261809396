import { AddRounded } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Search from "components/Search";
import Table from "components/Table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  appBar,
  authorizedFetch,
  drawer,
  getPermissions,
  titleCase,
} from "utils";
import { RETAIL_URL } from "utils/constants";
import AddDeviceIcon from "assets/images/icons/add-device.svg";
import BulkUploadIcon from "assets/images/icons/bulk-upload.svg";
import AddDeviceDialog from "./AddDeviceDialog";
import AppBar from "./AppBar";
import AssignOem from "./AssignOem";
import BulkUploadDialog from "./BulkUploadDialog";
import InfoDrawer from "./InfoDrawer";
import RecallDialog from "./RecallDialog";
import BulkInfoDrawer from "./BulkInfoDrawer";
 
const SCM = () => {
  const [tab, setTab] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [filters, setFilters] = useState({
    company: "",
    speedometer: "",
    model: "",
    fileKeyStatus: "",
  });

  const [addButtonAnchor, setAddButtonAnchor] = useState<null | HTMLElement>(
    null
  );

  const [bulkUploadDialogOpen, setBulkUploadDialogOpen] = useState(false);
  const [deviceDialogOpen, setDeviceDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [assignDialog, setAssignDialog] = useState(false);
  const [recallDialog, setRecallDialog] = useState(false);
  const first = pageSize;
  const skip = pageSize * (page - 1);

  const { isLoading: uploadHistoryLoading, data: uploadHistoryData } = useQuery(
    ["getUploadHistoryData", first, skip, filters],
    () =>
      authorizedFetch(
        `${RETAIL_URL}/assembly/component/bulkAdd/history?first=${first}&skip=${skip}&status=${filters.fileKeyStatus}`
      )
  );

  useEffect(() => {
    appBar.open(
      <AppBar
        {...{
          tab,
          setTab,
          filters,
          uploadHistoryData,
          setFilters,
        }}
      />,
    );
    return () => {
      appBar.close();
      drawer.close();
    };
  }, [tab, filters, uploadHistoryData]);

  useEffect(() => {
    setSelectedRows([]);
  }, [tab]);

 
  const status = tab === 0 ? "ASSIGNED" : tab === 1 ? "UNASSIGNED" : "";

  const { isLoading: scmLoading, data: scmData } = useQuery(
    ["getSCMData", first, skip, status, search, filters],
    () =>
      authorizedFetch(
        `${RETAIL_URL}/inventory/scm/components?status=${status}&first=${first}&skip=${skip}&search=${search}&assignee=${filters.company}&vehicleModel=${filters.model}&model=${filters.speedometer}&componentType=CONTROLLER`
      )
  );

  useEffect(() => {
    setPage(1);
  }, [tab]);

  const { canWrite } = getPermissions("housekeeping:components");

  return (
    <Box
      sx={{
        // px: { xs: 0, lg: 0 },
        // pt: { xs: 2, md: 4.75 },
        pb: { xs: 3, md: 7 },
      }}
    >
      <Paper
        sx={{
          width: 1,
          boxShadow: (theme) => theme.customShadows.card,
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            width: 1,
            p: 3,
            pb: 2.75,
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: { md: "space-between" },
            alignItems: { md: "center" },
          }}
        >
          <Box width="fit-content">
            <Tabs
              sx={{
                mr: { md: 2 },
                mb: { xs: 2, md: 0 },
              }}
              className="dense"
              value={tab}
              onChange={(e, tab) => setTab(tab)}
            >
              <Tab label="Assigned" className="hasCount" />
              <Tab label="Unassigned" />
              <Tab label="Upload History" />
            </Tabs>
          </Box>
          {(tab === 0 || tab === 1) && (
            <Box
              sx={{
                display: "flex",
                alignItems: { sm: "center" },
                justifyContent: {
                  xs: "space-between",
                },
                flexDirection: {
                  xs: "column-reverse",
                  sm: "row-reverse",
                },
                "& > :first-of-type": {
                  mt: { xs: 1, sm: 0 },
                  mr: { xs: 0, md: 1 },
                },
              }}
            >
              <Search
                handleSearch={(input) => setSearch(input)}
                persist
                enableClear
              />
              {canWrite && (
                <Button
                  sx={{
                    // width: { md: 300 },
                    px: 4,
                    py: 1,
                    borderRadius: 10,
                    borderWidth: "1.5px !important",
                    borderColor: (theme) => theme.palette.primary.main,
                    textTransform: "none",
                    fontWeight: 500,
                  }}
                  variant="outlined"
                  startIcon={<AddRounded />}
                  onClick={(e) => setAddButtonAnchor(e.currentTarget)}
                >
                  Add New
                </Button>
              )}
            </Box>
          )}
        </Box>
        {tab === 0 || tab === 1 ? (
          <>
            <Table
              loading={scmLoading}
              rowCount={scmData?.meta?.totalCount}
              rows={scmData?.data || []}
              serverSidePagination
              activePage={page}
              activePageSize={pageSize}
              selectable
              idKey="_id"
              onRowClick={(row) =>
                drawer.open(
                  <InfoDrawer
                    key={row._id}
                    onButtonClick={(rowId: any) => {
                      setSelectedRows([rowId]);
                      setDialogData(row)
                      // setDeviceDialogOpen(true)
                      tab === 0 ? setRecallDialog(true) : setAssignDialog(true);
                    }}
                    scm={row}
                    tab={tab}
                    setTab={setTab}
                    openDialog={()=> {
                      setDialogData(row)
                      setDeviceDialogOpen(true)
                    }}
                  />
                )
              }
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              onPageChange={(value) => setPage(value)}
              onPageSizeChange={(value) => setPageSize(value)}
              columns={[
                { key: "serialNumber", label: "Serial Number" },
                { key: "specs.macId", label: "MAC ID" },
                {
                  key: "parentModel.key",
                  label: "Speedometer Type",
                  Render: (row) => (
                    <Box>{row.parentModel.key.replace("CONTROLLER_", "")}</Box>
                  ),
                },
                { key: "oem.name", label: "Company" },
                { key: "vehicleModel.name", label: "Model" },
                {
                  key: "createdAt",
                  label: "Created On",
                  Render: (row) => (
                    <Box>{moment(row.createdAt).format("YYYY-MM-DD")}</Box>
                  ),
                },
              ]}
            />
            {selectedRows.length > 0 && (
              <Button
                sx={{
                  display: "flex",
                  height: 41,
                  maxWidth: 250,
                  borderRadius: 10,
                  borderWidth: "1.5px !important",
                  borderColor: (theme) => theme.palette.primary.main,
                  textTransform: "none",
                  fontWeight: 500,
                  px: 4,
                  ml: "auto",
                  marginRight: 4,
                }}
                variant="contained"
                onClick={() =>
                  tab === 0 ? setRecallDialog(true) : setAssignDialog(true)
                }
                // disabled={selectedRows.length === 0}
              >
                {tab === 0 ? "Recall" : "Assign"}
              </Button>
            )}
          </>
        ) : (
          <Table
            loading={uploadHistoryLoading}
            rowCount={uploadHistoryData?.meta?.totalCount}
            rows={
              uploadHistoryData?.data?.map(
                (item: {
                  passedEntries: any;
                  failedEntries: any;
                  totalItems: any;
                }) => ({
                  ...item,
                  totalItems: item.passedEntries + item.failedEntries || '0',
                  status: item.passedEntries === 0 ? "Failed" : "Success",
                  Render: () => <>{item.totalItems || 0}</>,
                }),
              ) || []
            }
            serverSidePagination
            activePage={page}
            activePageSize={pageSize}
            onRowClick={(row) =>
              drawer.open(<BulkInfoDrawer key={row._id}
                bulk={row} 
                totalItems={row.totalItems}/>)
            }
            onPageChange={(value) => setPage(value)}
            onPageSizeChange={(value) => setPageSize(value)}
            columns={[
              {
                key: "fileKey",
                label: "Uploaded File",
                Render: (row) => (
                  <Box sx={{ py: 1 }}>
                    <Typography>{row.fileKey}</Typography>
                    <Typography fontSize={12} color="text.secondary">
                      {moment(row.createdAt).format("DD-MMM-YYYY, hh:mm A")}
                    </Typography>
                  </Box>
                ),
              },
              {
                key: "status",
                label: "File Status",
                Render: (row) => (
                  <Avatar
                    variant="status"
                    className={
                      row.status === "Failed"
                        ? "red"
                        : row.status === "Success"
                        ? "green"
                        : ""
                    }
                  >
                    {row.status
                      ? row.status === "Success"
                        ? "Success"
                        : row.status === "Failed"
                        ? "Failed"
                        : titleCase(status.split("_").join(" "))
                      : "N/A"}
                  </Avatar>
                ),
              },
              { key: "totalItems", label: "Total Items" },
              {
                key: "passedEntries",
                label: "Success",
                format: (value) => value || 0,
              },
              {
                key: "failedEntries",
                label: "Failed",
                format: (value) => value || 0,
                Render: (row) => (
                  <Typography color="error.light">
                    {row.failedEntries}
                  </Typography>
                ),
              },
              {
                key: "fileURL",
                label: "Reports",
                Render: (row) => (
                  <Avatar
                    variant="status"
                    className="grey"
                    onClick={() => window.open(row.fileURL)}
                    sx={{ cursor: "pointer" }}
                  >
                    {row.fileURL ? "Download" : "N/A"}
                  </Avatar>
                ),
              },
              {
                key: "fileKeyStatus",
                label: "Status",
                Render: (row) => (
                  <Avatar
                    variant="status"
                    className={
                      row.fileKeyStatus === "ASSIGNED"
                        ? "green"
                        : row.fileKeyStatus === "UNASSIGNED"
                        ? "grey"
                        : ""
                    }
                  >
                    {row.fileKeyStatus
                      ? row.fileKeyStatus === "ASSIGNED"
                        ? "Assigned"
                        : row.fileKeyStatus === "UNASSIGNED"
                        ? "Unassigned"
                        : titleCase(status.split("_").join(" "))
                      : "N/A"}
                  </Avatar>
                ),
              }
            ]}
          />
        )}
      </Paper>
      <AssignOem
        open={assignDialog}
        handleClose={() => setAssignDialog(false)}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        setTab={setTab}
      />
      <RecallDialog
        open={recallDialog}
        handleClose={() => setRecallDialog(false)}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        setTab={setTab}
      />
      <BulkUploadDialog
        open={bulkUploadDialogOpen}
        handleClose={() => setBulkUploadDialogOpen(false)}
        setTab={setTab}
      />
      <AddDeviceDialog
        open={deviceDialogOpen}
        handleClose={() => {setDeviceDialogOpen(false); setDialogData({})}}
        dialogData={dialogData}
      />
      <Menu
        anchorEl={addButtonAnchor}
        open={Boolean(addButtonAnchor)}
        onClose={() => setAddButtonAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem
          onClick={() => {
            setDeviceDialogOpen(true);
            setAddButtonAnchor(null);
          }}
        >
          <ListItemIcon
            children={
              <img
                src={AddDeviceIcon}
                alt="recall icon"
                style={{ width: 20 }}
              />
            }
          />
          Add Device
        </MenuItem>
        <MenuItem
          onClick={() => {
            setBulkUploadDialogOpen(true);
            setAddButtonAnchor(null);
          }}
        >
          <ListItemIcon
            children={
              <img
                src={BulkUploadIcon}
                alt="recall icon"
                style={{ width: 20 }}
              />
            }
          />
          Bulk Upload
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default SCM;
