import { Box, Paper, Skeleton, Typography } from "@mui/material";
import Table from "components/Table";
import { useQuery } from "react-query";
import { authorizedFetch, getDuration } from "utils";
import { LEASE_URL } from "utils/constants";
import InfoLabel from "components/InfoLabel";
import moment from "moment";
import { useState } from "react";
import TripsMap from "./TripsMap";

const RecentTrips = ({ isMasterView }: any) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(4);

  const first = pageSize;
  const skip = pageSize * (page - 1);

  const tripsURL = `${LEASE_URL}/company/trips?first=${first}&skip=${skip}&heatMap=true&search=`;

  const { isLoading, data } = useQuery(
    ["getFMSTripsHeatmap", first, skip, isMasterView],
    () => authorizedFetch(tripsURL)
  );

  const total = (data?.data?.count || 0).toLocaleString();

  return (
    <Box
      sx={{
        gridColumn: "1/-1",
        display: "grid",
        gridTemplateColumns: { lg: "1fr 1fr" },
        gap: { xs: 2, md: 4.5 },
      }}
    >
      <Paper sx={{ minWidth: 0, height: "auto !important" }}>
        <Box
          sx={{
            pt: { xs: 3, md: 4 },
            px: { xs: 2, md: 4 },
            minWidth: { xs: 0, md: 360 },
            mb: { xs: 2, md: 2.5 },
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "flex-start", md: "center" },
          }}
        >
          <Typography variant="h3" mr={2.5} mb={{ xs: 1.5, md: 0 }}>
            Recent Trips
          </Typography>
          {isLoading ? (
            <Skeleton
              variant="rectangular"
              width={95}
              height={28}
              sx={{ borderRadius: 10 }}
            />
          ) : (
            <InfoLabel dense label="Total" value={total} />
          )}
        </Box>
        <Table
          rowCount={data?.data?.count}
          serverSidePagination={true}
          activePage={page}
          activePageSize={pageSize}
          onPageChange={(value) => setPage(value)}
          onPageSizeChange={(value) => setPageSize(value)}
          height={290}
          rowsPerPage={4}
          small
          smallPagination
          loading={isLoading}
          rows={data?.data?.trips || []}
          columns={[
            {
              key: "tripId",
              label: "Trip Id",
            },
            {
              key: "vin",
              label: "Vin",
              Render: (row) => {
                let { vin } = row;
                return `${vin.length > 17 ? vin.slice(0, 17) : vin}`;
              },
            },
            {
              key: "startTime",
              label: "Start",
              format: (value) => moment(value).format("DD/MM/YY, hh:mm a"),
            },
            {
              key: "distance",
              label: "Distance",
              format: (value) =>
                `${(parseFloat(value || 0) / 1000).toFixed(2)} km`,
            },

            {
              key: "duration",
              label: "Duration",
              Render: (row) =>
                getDuration(
                  moment
                    .duration(moment(row.endTime).diff(moment(row.startTime)))
                    .asMinutes()
                ),
            },
          ]}
        />
      </Paper>
      <Paper>
        <TripsMap data={data?.data?.trips || []} />
      </Paper>
    </Box>
  );
};

export default RecentTrips;
