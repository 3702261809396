import { useEffect, useRef, useState } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import { Box, TextField } from "@mui/material";

const MapWrapper = ({
  type,
  circleCoords,
  setCircleCoord,
  polygonCoords,
  setPolygonCoord,
  handleChange,
  data,
  isEditMode,
  open,
  setIsEditMode,
}: any) => {
  const [center, setCenter] = useState<any>();

  useEffect(() => {
    if (data) {
      if (data?.type === "CIRCULAR") {
        setCenter({
          lat: data?.location?.coordinates?.[0]?.[0]?.[1],
          lng: data?.location?.coordinates[0]?.[0]?.[0],
        });
      }

      if (data?.type === "POLYGONAL") {
        let sumLat = 0;
        let sumLng = 0;
        // eslint-disable-next-line
        data?.location?.coordinates[0]?.map((el: any) => {
          let lat = el[1];
          let lng = el[0];

          sumLat += lat;
          sumLng += lng;
        });

        const center = {
          lat: sumLat / data?.location?.coordinates[0]?.length,
          lng: sumLng / data?.location?.coordinates[0].length,
        };
        setCenter(center);
      }
    }
  }, [data]);
  return (
    <Box
      sx={{
        height: 1,
        width: 1,
        position: "relative",
      }}
    >
      <Wrapper
        libraries={["visualization", "places", "drawing", "geometry"]}
        apiKey={"AIzaSyDqq6Ywsf6L3lrgg_P8BI1Z7hvNbsjMQzY"}
      >
        <Map
          circleCoords={circleCoords}
          setCircleCoord={setCircleCoord}
          polygonCoords={polygonCoords}
          setPolygonCoord={setPolygonCoord}
          type={type}
          center={center}
          handleChange={handleChange}
          data={data}
          isEditMode={isEditMode}
          open={open}
          setIsEditMode={setIsEditMode}
        />
      </Wrapper>
    </Box>
  );
};

const Map = ({
  type,
  setCircleCoord,
  setPolygonCoord,
  handleChange,
  isEditMode,
  data,
  open,
  center,
  setIsEditMode,
}: any) => {
  const ref = useRef<HTMLElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [circle, setCircle] = useState<google.maps.Circle | null>(null);
  const [polygon, setPolygon] = useState<google.maps.Polygon | null>(null);
  const [autoCompleteWidget, setAutoCompleteWidget] = useState<
    google.maps.places.Autocomplete | undefined
  >(undefined);
  const [isNewShape, setIsNewShape] = useState(false);

  useEffect(() => {
    if (open) {
      setIsNewShape(false);
    }
  }, [open]);

  useEffect(() => {
    if (inputRef.current && !autoCompleteWidget) {
      setAutoCompleteWidget(
        new google.maps.places.Autocomplete(inputRef.current),
      );
    }
  }, [inputRef, autoCompleteWidget]);

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          fullscreenControl: false,
          clickableIcons: false,
          zoom: 12,
          center: { lat: 12.9716, lng: 77.5946 },
          disableDefaultUI: true,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, map]);

  const [circleManager, setCircleManager] =
    useState<google.maps.drawing.DrawingManager | null>(null);
  const [polygonManager, setPolygonManager] =
    useState<google.maps.drawing.DrawingManager | null>(null);

  useEffect(() => {
    if (!map) return;
    if (data) {
      if (data.type === "CIRCULAR") {
        console.log(data);
        polygon?.setMap(null);
        circle?.setMap(null);
        setCircle(
          new google.maps.Circle({
            strokeColor: "#61D169",
            strokeOpacity: 1,
            strokeWeight: 2,
            fillColor: "#61D169",
            fillOpacity: 0.35,
            radius: data.radius,
            center: {
              lat: data.location.coordinates[0]?.[0]?.[1],
              lng: data.location.coordinates[0]?.[0]?.[0],
            },
            editable: true,
            draggable: true,
            map,
          }),
        );
      }

      if (data.type === "POLYGONAL") {
        polygon?.setMap(null);
        circle?.setMap(null);
        const polyCords = [...data?.location?.coordinates[0]];
        polyCords.pop();
        console.log(data?.location?.coordinates[0]);
        let createPathArray: any = [];
        // eslint-disable-next-line
        polyCords.map((el: any) => {
          createPathArray.push({ lat: el[1], lng: el[0] });
        });

        setPolygon(
          new google.maps.Polygon({
            strokeColor: "#61D169",
            strokeOpacity: 1,
            strokeWeight: 2,
            fillColor: "#61D169",
            fillOpacity: 0.35,
            paths: createPathArray,
            editable: true,
            draggable: true,
            map,
          }),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, data]);

  useEffect(() => {
    if (circle && data?.type === "CIRCULAR" && !isNewShape) {
      if (!map) return;
      const circleBounds: any = circle?.getBounds();
      map.fitBounds(circleBounds);
      console.log(type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circle, map, data]);

  useEffect(() => {
    if (polygon && data?.type === "POLYGONAL" && !isNewShape) {
      if (!map) return;
      console.log(type);
      const polygonCoords = data?.location?.coordinates[0].map((el: any) => {
        return new google.maps.LatLng(el[1], el[0]);
      }, []);

      let bounds = new google.maps.LatLngBounds();
      polygonCoords?.forEach((latLng: any) => {
        bounds.extend(latLng);
      });

      map.setCenter(bounds.getCenter());
      map.fitBounds(bounds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polygon, map, data]);

  // Circle functions

  const createCircle = () => {
    // console.log("create circle");
    setIsNewShape(true);
    polygon?.setMap(null);
    polygonManager?.setMap(null);
    setCircleManager(
      new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.CIRCLE,
        drawingControl: false,
        map: map!,
        circleOptions: {
          strokeColor: "#61D169",
          strokeOpacity: 1,
          strokeWeight: 2,
          fillColor: "#61D169",
          fillOpacity: 0.35,
          editable: true,
          draggable: true,
        },
      }),
    );
  };

  useEffect(() => {
    // console.log("editable circle");

    if (circleManager) {
      google.maps.event.addListener(
        circleManager,
        "overlaycomplete",
        (event: any) => {
          const createCircle = {
            radius: event.overlay.getRadius(),
            center: {
              lat: event.overlay.getCenter().toJSON().lat,
              lng: event.overlay.getCenter().toJSON().lng,
            },
          };
          const saveCircle = {
            radius: event.overlay.getRadius(),
            center: [
              event.overlay.getCenter().toJSON().lng,
              event.overlay.getCenter().toJSON().lat,
            ],
          };
          setCircleCoord([saveCircle]);
          setCircle(
            new google.maps.Circle({
              strokeColor: "#61D169",
              strokeOpacity: 1,
              strokeWeight: 2,
              fillColor: "#61D169",
              fillOpacity: 0.35,
              center: createCircle.center,
              radius: createCircle.radius,
              editable: true,
              draggable: true,
              map: map!,
            }),
          );

          circleManager.setMap(null);

          setTimeout(() => {
            event.overlay.setMap(null);
          }, 10);
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circleManager]);

  useEffect(() => {
    if (circle) {
      // eslint-disable-next-line
      ["center_changed", "radius_changed"].map((eventName: any) => {
        google.maps.event.addListener(circle, eventName, () => {
          setCircleCoord([
            {
              radius: circle?.getRadius(),
              center: [
                circle?.getCenter()?.toJSON().lng,
                circle?.getCenter()?.toJSON().lat,
              ],
            },
          ]);
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circle]);

  // Polgon functions

  const createPolygon = () => {
    // console.log("create Polygon");
    setIsNewShape(true);

    circleManager?.setMap(null);
    circle?.setMap(null);
    setPolygonManager(
      new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
        drawingControl: false,
        map: map!,
        polygonOptions: {
          strokeColor: "#61D169",
          strokeOpacity: 1,
          strokeWeight: 2,
          fillColor: "#61D169",
          fillOpacity: 0.35,
          editable: true,
          draggable: true,
        },
      }),
    );
  };

  useEffect(() => {
    // console.log("editable polygon");

    if (polygonManager) {
      google.maps.event.addListener(
        polygonManager,
        "overlaycomplete",
        function (event: any) {
          let coordinates = event.overlay.getPath().getArray();

          let createPathArray: any = [];
          // eslint-disable-next-line
          coordinates.map((el: any) => {
            createPathArray.push({
              lat: el.toJSON().lat,
              lng: el.toJSON().lng,
            });
          });

          let savePathArray: any = [];
          // eslint-disable-next-line
          coordinates.map((el: any) => {
            savePathArray.push([el.toJSON().lng, el.toJSON().lat]);
          });
          savePathArray.push(savePathArray[0]);
          setPolygonCoord(savePathArray);
          setPolygon(
            new google.maps.Polygon({
              strokeColor: "#61D169",
              strokeOpacity: 1,
              strokeWeight: 2,
              fillColor: "#61D169",
              fillOpacity: 0.35,
              paths: createPathArray,
              editable: true,
              draggable: true,
              map: map!,
            }),
          );

          polygonManager.setMap(null);

          setTimeout(() => {
            event.overlay.setMap(null);
          }, 10);
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polygonManager]);

  useEffect(() => {
    if (polygon) {
      // eslint-disable-next-line
      ["insert_at", "remove_at", "set_at", "click"].map((eventName: any) => {
        google.maps.event.addListener(
          polygon.getPath(),
          eventName,
          function () {
            const vertices: any = polygon.getPath().getArray();
            let updatePathArray: any = [];
            // eslint-disable-next-line
            vertices.map((el: any) => {
              updatePathArray.push([el.toJSON().lng, el.toJSON().lat]);
            });
            updatePathArray.push(updatePathArray[0]);

            setPolygonCoord(updatePathArray);
          },
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polygon]);

  // useEffect(() => {
  //   if (!map) return;

  //   if (type !== "CIRCULAR") {
  //     circleManager?.setMap(null);

  //     createPolygon();
  //   } else {
  //     polygonManager?.setMap(null);
  //     createCircle();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [map, type]);

  console.log(circle);

  useEffect(() => {
    if (!map) return;
    if (!isEditMode) {
      if (type !== "CIRCULAR") {
        circleManager?.setMap(null);
        createPolygon();
      } else {
        polygonManager?.setMap(null);
        createCircle();
      }
    }
    if (isEditMode) {
      circleManager?.setMap(null);
      polygonManager?.setMap(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, type, isEditMode]);

  // useEffect(() => {
  //   circleManager?.setMap(null);
  //   polygonManager?.setMap(null);
  //   circle?.setMap(null);
  //   polygon?.setMap(null);
  // }, []);

  useEffect(() => {
    if (map && autoCompleteWidget && type) {
      autoCompleteWidget.bindTo("bounds", map);
      autoCompleteWidget.addListener("place_changed", () => {
        const place = autoCompleteWidget.getPlace();
        // setIsEditMode(false);
        // circle?.setMap(null);
        // polygon?.setMap(null);

        if (!place.geometry || !place.geometry.location) {
          window.alert("No details available for input: '" + place.name + "'");
          return;
        }

        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(17);
        }
      });
    }
    // eslint-disable-next-line
  }, [autoCompleteWidget, map, type]);

  return (
    <>
      <Box
        ref={ref}
        sx={{ position: "absolute", width: 1, height: 1, color: "black" }}
      />
      <TextField
        inputRef={inputRef}
        sx={{ position: "absolute", top: 10, right: 10 }}
        size="small"
        placeholder="Search..."
      />
    </>
  );
};

export default MapWrapper;
