import { CancelOutlined, Launch, SaveAlt } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
// import storageManager from "utils/storageManager";

const ReportPreviewDialog = ({ open, handleClose }: any) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: 0,
        },
      }}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle
        sx={{
          bgcolor: (theme) => theme.palette.primary.main,
          color: "#fff",
          p: "10px 32px !important",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography mb={0.5} fontSize={16} fontWeight={700}>
            Report.pdf
          </Typography>
          <Typography fontSize={12}>Aug 31st at 10:08 AM</Typography>
        </Box>
        <Box
          display="grid"
          gridTemplateColumns="repeat(3, auto)"
          gap={2}
          sx={{ transform: "translateX(16px)" }}
        >
          <Tooltip title="Download">
            <IconButton color="inherit" children={<SaveAlt />} />
          </Tooltip>
          <Tooltip title="Open in new window">
            <IconButton color="inherit" children={<Launch />} />
          </Tooltip>
          <IconButton
            color="inherit"
            children={<CancelOutlined />}
            onClick={handleClose}
          />
        </Box>
      </DialogTitle>
      <DialogContent sx={{ p: "0px !important" }}>
        <Box height="80vh"></Box>
      </DialogContent>
    </Dialog>
  );
};

export default ReportPreviewDialog;
