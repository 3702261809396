import { Box, Paper, Skeleton, Typography, useTheme } from "@mui/material";
import InfoLabel from "components/InfoLabel";
import moment from "moment";
import { useMemo } from "react";
import { Line } from "react-chartjs-2";
import { useQuery } from "react-query";
import { authorizedFetch } from "utils";
import { CMS_URL } from "utils/constants";

const NewUsers = ({ params }: any) => {
  const theme = useTheme();

  const { masterView, startTime, endTime, cities, usageType, chargerHealth } =
    params;

  const url = `${CMS_URL}/stats/user/new?startTime=${startTime}&endTime=${endTime}${cities}${
    usageType || ""
  }${chargerHealth || ""}`;
  const { isLoading, data } = useQuery(
    [
      "getNewUsersStats",
      masterView,
      startTime,
      endTime,
      cities,
      usageType,
      chargerHealth,
    ],
    () => authorizedFetch(url)
  );

  const { chartData, count, average } = useMemo(() => {
    let chartData: any[] = [];
    let count = 0;

    if (data?.data) {
      Object.entries(data.data || {}).forEach(([key, value]: any) => {
        // let parsedValue = value ? parseFloat(value.slice(2)) : 0;
        chartData.push({
          x: moment(key).format("MMM D"),
          y: value,
        });
        count += value;
      });
    }
    let average = Math.round(
      chartData.reduce((acc, cur: any) => acc + cur.y, 0) /
        (chartData.length || 1)
    );
    return { chartData, count, average };
  }, [data]);

  return (
    <Paper
      sx={{
        py: { xs: 2.5, md: 3.5 },
        px: { xs: 2, md: 3 },
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          mb: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h3" mr={1.25}>
            New Users
          </Typography>
          {isLoading ? (
            <>
              <Skeleton
                variant="rectangular"
                width={80}
                height={24}
                sx={{ borderRadius: 10, mr: 1.25 }}
              />
              <Skeleton
                variant="rectangular"
                width={80}
                height={24}
                sx={{ borderRadius: 10 }}
              />
            </>
          ) : (
            <>
              <InfoLabel
                dense
                label="Total"
                value={(count || 0).toLocaleString()}
                mr={1.25}
              />
              <InfoLabel
                dense
                label="Daily Average"
                value={(average || 0).toLocaleString()}
              />
            </>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          minHeight: 0,
        }}
      >
        <Line
          data={(canvas) => {
            let color = "#F88DDA";
            return {
              labels: chartData?.map((el: any) => el.x) || [],
              datasets: [
                {
                  fill: true,
                  data: chartData?.map((el: any) => el.y) || [],
                  borderColor: color,
                  backgroundColor: "#F88DDA0D",
                  tension: 0.4,
                  pointRadius: 0,
                  pointHoverRadius: 4,
                  pointHoverBackgroundColor: "#fff",
                  pointHoverBorderWidth: 3,
                },
              ],
            };
          }}
          options={{
            scales: {
              xAxis: {
                // type: 'time',
                grid: {
                  display: false,
                  tickWidth: 0,
                  tickLength: 16,
                  drawBorder: false,
                },
                ticks: {
                  color: theme.palette.text.secondary,
                },
              },
              yAxis: {
                title: {
                  display: true,
                  text: "Users",
                  padding: {
                    top: 0,
                    bottom: 8,
                  },
                },
                ticks: {
                  color: theme.palette.text.secondary,
                },
                // suggestedMin: 15,
                // suggestedMax: 30,

                grid: {
                  borderDash: [4],
                  tickWidth: 0,
                  tickLength: 16,
                  drawBorder: false,
                },
              },
            },
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                caretSize: 0,
                mode: "index",
                intersect: false,
                yAlign: "center",
                displayColors: false,
                caretPadding: 16,
                titleFont: {
                  weight: "400",
                },
                bodyFont: {
                  weight: "500",
                },
              },
            },
            interaction: {
              mode: "index",
              intersect: false,
            },
          }}
        />
      </Box>
    </Paper>
  );
};

export default NewUsers;
