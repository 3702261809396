import {
  ErrorOutline,
  FullscreenOutlined,
  HighlightOff,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Step,
  StepButton,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { queryClient } from "index";

import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { authorizedFetch, getPermissions, setLoader, snackbar } from "utils";
import { KYC_URL } from "utils/constants";
import ImageDialog from "./ImageDialog";

const UpdateKYC = ({ user, open, handleClose }: any) => {
  const { canWrite } = getPermissions("charger:chargers");

  const [step, setStep] = useState<number>(0);
  const steps = ["Update", "Finish"];
  const [images, setImages] = useState<any>([]);

  const [activeImage, setActiveImage] = useState(0);
  const [status, setStatus] = useState("APPROVE");
  const [note, setNote] = useState("");
  const [reason, setReason] = useState("");

  const [imageDialog, setImageDialog] = useState({
    open: false,
    images: [],
  });

  const selectedImages = images
    .filter((el: any) => el.selected)
    .map((el: any) => ({
      _id: el._id,
      url: el.url,
      selected: true,
      name: el.name,
    }));
  const disabledApprove = ![3, 4].includes(selectedImages.length);

  const disabledReject = [note, reason].includes("");

  function isComplete(step: number) {
    switch (step) {
      case 0:
        return true;
      case 1:
        return ![note, reason].includes("");
      default:
        break;
    }
  }

  const url = `${KYC_URL}/product/${user?.chargerId}`;
  const { isLoading: isKycLoading, data: kycData } = useQuery(
    ["getKyc", user?.chargerId],
    () => authorizedFetch(url),
    {
      enabled: !!user?.chargerId,
    }
  );

  const reasonsListUrl = `${KYC_URL}/company/product/chargers/remarks`;
  const { isLoading: isReasonsListLoading, data: reasonsListData } = useQuery(
    ["remarks"],
    () => authorizedFetch(reasonsListUrl)
  );

  const handleImageSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const imageArr = images.map((obj: any) =>
      obj.name === e.target.name ? { ...obj, selected: e.target.checked } : obj
    );
    setImages(imageArr);
  };

  const handleSave = () => {
    setLoader(true);
    authorizedFetch(`${KYC_URL}/company/product/handle`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        stage: "prod",
      },
      body: {
        productId: user?.chargerId,
        action: status,
        remark: status === "REJECT" ? reason : "",
        internalRemark: note,
      },
    })
      .then(() => {
        setLoader(false);
        queryClient.resetQueries("getKyc");
        queryClient.resetQueries("getChargersKYC");
        snackbar.success(`KYC updated`);
        handleClose();
      })
      .catch((err) => {
        setLoader(false);
        snackbar.error(`Error updating KYC`);
      });

    authorizedFetch(`${KYC_URL}/company/product/chargers/select/images`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        stage: "prod",
      },
      body: {
        productId: user?.chargerId,
        docs: selectedImages.map(({ name, ...image }: any) => image),
      },
    });
  };

  function handleNextStepper() {
    if (step === steps.length - 1) {
      handleSave();
    } else setStep(step + 1);
  }
  function handleBackStepper() {
    setStep(step - 1);
  }

  useEffect(() => {
    if (kycData) {
      let imageArr = kycData?.data?.documents?.map((el: any, i: number) => ({
        url: el.url,
        _id: el._id,
        selected: el.selected,
        name: `Image #${i + 1}`,
      }));
      setImages(imageArr);
    }
  }, [kycData]);

  useEffect(() => {
    if (open) {
      setActiveImage(0);
      setNote("");
      setStep(0);
      // setImages([]);
    }
  }, [open]);

  useEffect(() => {
    setStatus(
      user?.status === "APPROVED"
        ? "APPROVE"
        : user?.status === "REJECTED"
        ? "REJECT"
        : "APPROVE"
    );
  }, [user]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            maxWidth: 800,
            width: 1,
            "& .MuiInputBase-root": {
              fontSize: 14,
              borderRadius: 1,
              p: "3.5px 5px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box display="flex">
            {canWrite ? "Update" : "View"} KYC
            <Divider flexItem orientation="vertical" sx={{ mx: 1.5 }} />
            <Box display="flex" alignItems="center" fontSize={14}>
              {user.chargerId}
              <Avatar
                variant="status"
                className={user.status === "REJECTED" ? "red" : "yellow"}
                sx={{ ml: 2 }}
              >
                {user.status}
              </Avatar>
            </Box>
          </Box>

          <IconButton
            // sx={{ transform: "translate(3px, -3px)" }}
            children={<HighlightOff />}
            onClick={handleClose}
          />
        </DialogTitle>
        <DialogContent sx={{ mt: 3 }}>
          {canWrite && (
            <Stepper
              sx={{ mb: 4, mx: "auto", maxWidth: 500 }}
              activeStep={step}
              nonLinear
              alternativeLabel
            >
              {steps.map((label, i) => (
                <Step key={i}>
                  <StepButton onClick={() => setStep(i)}>{label}</StepButton>
                </Step>
              ))}
            </Stepper>
          )}

          {step === 0 && (
            <Box mx="auto" maxWidth={600}>
              <Typography className="label">KYC Documents</Typography>
              {isKycLoading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="200px"
                >
                  <CircularProgress size={24} />
                </Box>
              ) : (
                <Box
                  width="100%"
                  height="350px"
                  display="flex"
                  alignItems="center"
                  sx={{ position: "relative" }}
                  mt={3}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: "auto",
                      right: 0,
                      zIndex: 2,
                    }}
                  >
                    <IconButton
                      sx={{
                        background: "rgba(255, 255, 255, 0.3)",
                        "&:hover": {
                          background: "rgba(255, 255, 255, 0.4)",
                        },
                      }}
                      onClick={() => {
                        setImageDialog({
                          open: true,
                          images: images,
                        });
                      }}
                    >
                      <FullscreenOutlined
                        sx={{
                          width: 25,
                          borderRadius: 1,
                        }}
                      />
                    </IconButton>
                  </Box>
                  <Box
                    width="100%"
                    height="100%"
                    sx={{
                      position: "absolute",
                      top: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      zIndex: 1,
                    }}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        p: 0,
                        minWidth: 40,
                        width: 40,
                        height: 40,
                        borderRadius: 50,
                      }}
                      onClick={() => setActiveImage((prev) => prev - 1)}
                      disabled={activeImage === 0}
                    >
                      <KeyboardArrowLeft />
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{
                        p: 0,
                        minWidth: 40,
                        width: 40,
                        height: 40,
                        borderRadius: 50,
                      }}
                      onClick={() => setActiveImage((prev) => prev + 1)}
                      disabled={activeImage === images.length - 1}
                    >
                      <KeyboardArrowRight />
                    </Button>
                  </Box>
                  {images.map((image: any, i: number) => (
                    <Box
                      key={i}
                      component="img"
                      sx={{
                        height: 350,
                        maxWidth: 1,
                        maxHeight: 1,
                        width: 1,
                        display: activeImage === i ? "block" : "none",
                        objectFit: "contain",
                        overflow: "hidden",
                      }}
                      src={image.url}
                      alt={"KYC Document"}
                    />
                  ))}
                </Box>
              )}

              {canWrite && (
                <>
                  <Box
                    sx={{
                      mt: 4,
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography
                      className="label"
                      sx={{
                        mb: "0 !important",
                        mr: 2,
                      }}
                    >
                      Select Status
                    </Typography>
                    {isKycLoading ? (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="200px"
                      >
                        <CircularProgress size={24} />
                      </Box>
                    ) : (
                      <RadioGroup
                        row
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <FormControlLabel
                          value="APPROVE"
                          control={<Radio />}
                          label="Approve"
                        />

                        <FormControlLabel
                          value="REJECT"
                          control={<Radio />}
                          label="Reject"
                        />
                      </RadioGroup>
                    )}
                  </Box>
                  {status === "APPROVE" ? (
                    <>
                      <Typography mt={5} className="label">
                        Select 3 to 4 Thumbnails
                      </Typography>
                      <ImageList
                        sx={{
                          width: "100%",
                          height: "auto",
                          transform: "translateZ(0)",
                        }}
                        cols={3}
                        rowHeight={"auto"}
                        gap={20}
                      >
                        {images.map((image: any, i: any) => {
                          const cols = 1;
                          const rows = 1;

                          return (
                            <ImageListItem
                              key={i}
                              cols={cols}
                              rows={rows}
                              sx={{ mt: 1 }}
                            >
                              <img
                                src={image.url}
                                alt={image.name}
                                loading="lazy"
                              />
                              <ImageListItemBar
                                sx={{
                                  background: "#00000099",
                                  "& .MuiImageListItemBar-title": {
                                    color: "white",
                                  },
                                }}
                                // title={`Image #${i + 1}`}
                                position="top"
                                actionIcon={
                                  <FormControl
                                    required
                                    component="fieldset"
                                    variant="standard"
                                  >
                                    <FormGroup sx={{ fontColor: "white" }}>
                                      <FormControlLabel
                                        sx={{
                                          "& .MuiTypography-root": {
                                            color: "white",
                                          },
                                        }}
                                        control={
                                          <Checkbox
                                            checked={image.selected}
                                            onChange={handleImageSelection}
                                            name={`Image #${i + 1}`}
                                            sx={{
                                              ml: 2,
                                              color: "white",
                                            }}
                                          />
                                        }
                                        label={`Image #${i + 1}`}
                                      />
                                    </FormGroup>
                                  </FormControl>
                                }
                                actionPosition="left"
                              />
                            </ImageListItem>
                          );
                        })}
                      </ImageList>
                    </>
                  ) : (
                    <>
                      <Box
                        sx={{
                          my: 3,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography className="label" mt={1} mr={8}>
                          Reason
                        </Typography>
                        {isReasonsListLoading ? (
                          <CircularProgress />
                        ) : (
                          <Select
                            size="small"
                            fullWidth
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            displayEmpty
                          >
                            <MenuItem value="" disabled>
                              <em>Select</em>
                            </MenuItem>
                            {(reasonsListData?.data || []).map(
                              (el: any, i: number) => (
                                <MenuItem key={i} value={el}>
                                  {el}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        )}
                      </Box>

                      <Typography className="label" mb={1}>
                        Add a Note
                      </Typography>
                      <TextField
                        sx={{
                          width: 1,
                          maxWidth: "100%",
                          "& textarea": { p: 1 },
                        }}
                        multiline
                        rows={3}
                        placeholder="Please add a brief note for the reason for rejection..."
                        value={note}
                        onChange={(e) => {
                          if (e.target.value.length <= 30)
                            setNote(e.target.value);
                        }}
                      />
                    </>
                  )}
                </>
              )}
            </Box>
          )}
          {step === 1 && (
            <Box
              sx={{
                maxWidth: "100%",
                width: 560,
                mx: "auto",
                "& .table": {
                  borderCollapse: "collapse",
                  width: 1,
                  fontSize: 14,
                  lineHeight: "16px",
                  "& td": {
                    py: 1.25,
                    px: 2,
                  },
                  "& .bold": {
                    fontWeight: 500,
                  },
                  "& .header": {
                    px: 2,
                    py: 1,
                    position: "relative",
                    "& td": {
                      position: "absolute",
                      verticalAlign: "middle",
                      bgcolor: (theme) => theme.customColors.header,
                      width: 1,
                      borderRadius: "4px",
                      fontSize: 16,
                      fontWeight: 600,
                      "& span": {
                        display: "inline-block",
                        transform: "translateY(1px)",
                      },
                    },
                  },
                  "& .first > td": {
                    pt: 9,
                  },
                  "& .last > td": {
                    pb: 3,
                  },
                },
              }}
            >
              <table className="table">
                <tbody>
                  {[
                    { header: "Selected Status", onEdit: () => setStep(0) },
                    {
                      label: "Status",
                      value: status === "APPROVE" ? "APPROVED" : "REJECTED",
                      required: true,
                    },
                    ...(status === "APPROVE"
                      ? [
                          {
                            header: "Selected Images",
                            onEdit: () => setStep(0),
                            key: "image",
                          },
                          ...(![3, 4].includes(selectedImages.length)
                            ? [
                                {
                                  key: "select-3",
                                },
                              ]
                            : []),
                          ...selectedImages.map((el: any) => ({
                            label: el.name,
                            value: el.url,
                            key: "image",
                          })),
                        ]
                      : [
                          {
                            header: "Note",
                            onEdit: () => setStep(0),
                          },
                          {
                            label: "Reason for Rejection",
                            value: reason,
                            required: true,
                          },
                          {
                            label: "Note",
                            value: note,
                            required: true,
                            key: "note",
                          },
                        ]),
                  ].map(
                    (
                      { header, onEdit, label, value, required, key },
                      i,
                      arr
                    ) => {
                      const isFirst = arr[i - 1]?.header;
                      const isLast = !arr[i + 1] || arr[i + 1].header;

                      return (
                        <tr
                          key={i}
                          className={
                            header
                              ? "header"
                              : `${isFirst ? "first" : ""} ${
                                  isLast ? "last" : ""
                                }`
                          }
                        >
                          {header ? (
                            <td
                              colSpan={2}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span>{header}</span>
                              {/* <IconButton
                                sx={{ ml: 1.5 }}
                                children={<EditOutlined />}
                                color="primary"
                                size="small"
                                onClick={onEdit}
                              /> */}
                            </td>
                          ) : (
                            <>
                              {key === "image" ? (
                                <>
                                  <td>{label}</td>
                                  <td className="bold">
                                    {required &&
                                    ["", null, undefined].includes(value) ? (
                                      <Box display="flex" alignItems="center">
                                        <ErrorOutline
                                          fontSize="small"
                                          color="error"
                                          sx={{ mr: 1 }}
                                        />
                                        Required
                                      </Box>
                                    ) : (
                                      <>
                                        <div
                                          style={{
                                            width: "150px",
                                            maxWidth: "150px",
                                            height: "auto",
                                            objectFit: "contain",
                                          }}
                                        >
                                          <img
                                            style={{
                                              objectFit: "contain",
                                              width: "150px",
                                              height: "auto",
                                            }}
                                            src={value}
                                            alt={label}
                                          />
                                        </div>
                                      </>
                                    )}
                                  </td>
                                </>
                              ) : key === "select-3" ? (
                                <>
                                  {/* <td></td> */}
                                  <td>
                                    <Box display="flex" alignItems="center">
                                      <ErrorOutline
                                        fontSize="small"
                                        color="error"
                                        sx={{ mr: 1 }}
                                      />
                                      Please select 3 to 4 images
                                    </Box>
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td>{label}</td>
                                  <td className="bold">
                                    {required &&
                                    ["", null, undefined].includes(value) ? (
                                      <Box display="flex" alignItems="center">
                                        <ErrorOutline
                                          fontSize="small"
                                          color="error"
                                          sx={{ mr: 1 }}
                                        />
                                        Required
                                      </Box>
                                    ) : (
                                      value
                                    )}
                                  </td>
                                </>
                              )}
                            </>
                          )}
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          {canWrite && (
            <>
              {step !== 0 && (
                <Button variant="outlined" onClick={handleBackStepper}>
                  Back
                </Button>
              )}
              {step === 0 && (
                <Button variant="outlined" onClick={handleClose}>
                  Cancel
                </Button>
              )}
              <Button
                onClick={handleNextStepper}
                variant="contained"
                color={
                  isComplete(step) || step === steps.length - 1
                    ? "primary"
                    : "inherit"
                }
                disableElevation
                disabled={
                  step === steps.length - 1 &&
                  (status === "APPROVE" ? disabledApprove : disabledReject)
                }
              >
                {step === steps.length - 1 ? "Save" : "Next"}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <ImageDialog
        open={imageDialog.open}
        handleClose={() => {
          setImageDialog({ ...imageDialog, open: false });
        }}
        images={imageDialog.images}
        activeImage={activeImage}
        user={user}
      />
    </>
  );
};

export default UpdateKYC;
