import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useMutation } from "react-query";
import { snackbar, setLoader, authorizedFetch } from "utils";
import { WALLET_URL } from "utils/constants";

interface Props {
  open: boolean;
  data: any;
  handleClose: () => void;
  user: any;
  refetchRefunds: () => void;
}

const ConfirmRefund: React.FC<Props> = ({
  open,
  data,
  handleClose,
  user,
  refetchRefunds,
}) => {
  const mutation = useMutation(
    ["confirmRefund", data.customerId],
    () =>
      authorizedFetch(
        `${WALLET_URL}/v1/wallet/refundConfirmation?customerId=${user["_id"]}&bookingId=${data.bookingId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    {
      onSuccess: () => {
        snackbar.success(`Confirmation Successful`);
        refetchRefunds();
        setLoader(false);
      },
      onError: () => {
        snackbar.error(`Error in confirmation`);
      },
    }
  );

  function onconfirm() {
    setLoader(true);
    mutation.mutate();
    handleClose();
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Confirm Request</DialogTitle>
      <DialogContent className="py-1">
        Are you sure you want to confirm this request?
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onconfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmRefund;
