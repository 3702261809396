import {
  FileDownload,
  InfoOutlined,
  Sync,
  SyncProblem,
} from "@mui/icons-material";
import { Avatar, Box, IconButton, Link, Tooltip } from "@mui/material";
import { alpha } from "@mui/system";
import { ReactComponent as SubscriptionIcon } from "assets/images/icons/subscription.svg";
import Table, { Column } from "components/Table";
import { saveAs } from "file-saver";
import moment from "moment";
import { useQuery } from "react-query";
import {
  authorizedFetch,
  drawer,
  getDuration,
  setLoader,
  snackbar,
  titleCase,
  GlobalState,
} from "utils";
import { OCPP_URL, REPORTS_URL, WIFI_URL } from "utils/constants";
import InfoDrawer from "./InfoDrawer";
import { useSelector } from "react-redux";

function downloadInvoice(
  bookingId: any,
  chargerId: any,
  chargingStart: any,
  chargingEnd: any
) {
  setLoader(true);
  let request: any = authorizedFetch(
    `${REPORTS_URL}/v2/bookinginvoice?format=pdf&token=1234&id=${bookingId}`
  );
  request
    .then((result: any) => {
      setLoader(false);
      if (result.file) {
        const fileURL: any = result.file;
        saveAs(
          fileURL,
          `${chargerId}_${moment(chargingStart).format("DD-MM-YYYY")}.pdf`
        );
        snackbar.success("Downloaded invoice");
      } else if (result.message) {
        snackbar.error("Error downloading invoice");
        console.error(result);
      }
    })
    .catch((err: Error) => {
      console.error(err);
      setLoader(false);
      snackbar.error("Error downloading invoice");
    });
}

const BookingList = ({
  page,
  setPage,
  pageSize,
  setPageSize,
  isLoading,
  data,
  masterView,
  onChargerClick,
}: any) => {
  const { company } = useSelector((state: GlobalState) => state.global);
  const isCUGMonitorAccount = company?.type === "CUG_VENDOR";
  return (
    <Table
      rowCount={data?.data?.bookingsCount}
      serverSidePagination={true}
      activePage={page}
      activePageSize={pageSize}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
      rows={data?.data?.bookings || []}
      loading={isLoading}
      columns={[
        {
          key: "bookingId",
          label: "Booking ID",
          Render: (row) => (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Link
                sx={{
                  color: "text.primary",
                  cursor: "pointer",
                  textDecorationColor: (theme) =>
                    alpha(theme.palette.text.primary, 0.4),
                }}
                onClick={() => drawer.open(<InfoDrawer booking={row} />)}
              >
                {row.bookingId.length > 8
                  ? "..." + row.bookingId.slice(-8)
                  : ""}
              </Link>
              {row.subscriptionBooking && (
                <Tooltip title="Subscription-based">
                  <SubscriptionIcon height={22} style={{ marginLeft: 8 }} />
                </Tooltip>
              )}
            </Box>
          ),
        },
        {
          key: "chargerId",
          label: "Charger UID",
          Render: (row) => (
            <Link
              sx={{
                color: "text.primary",
                cursor: "pointer",
                textDecorationColor: (theme) =>
                  alpha(theme.palette.text.primary, 0.4),
              }}
              onClick={() => onChargerClick(row.chargerId)}
            >
              {row.chargerId}
            </Link>
          ),
        },
        {
          key: "bookingStatus",
          label: "Booking Status",
          Render: (row) => (
            <Avatar
              variant="status"
              className={
                row.bookingStatus === "CANCELLED"
                  ? "red"
                  : row.bookingStatus === "PAYMENT_PENDING"
                  ? "yellow"
                  : row.bookingStatus === "ENDED"
                  ? "blue"
                  : ""
              }
            >
              {titleCase(
                (row.bookingStatus === "ENDED"
                  ? "TERMINATED"
                  : row.bookingStatus === "TERMINATED"
                  ? "ENDED"
                  : row.bookingStatus === "CANCELLED"
                  ? "CANCELLED"
                  : row.bookingStatus === "PAYMENT_PENDING"
                  ? "PAYMENT_PENDING"
                  : row.bookingStatus === "BOOKED"
                  ? "BOOKED"
                  : "N/A"
                )
                  .split("_")
                  .join(" ")
              )}
            </Avatar>
          ),
        },
        ...(isCUGMonitorAccount
          ? [
              {
                key: "userName",
                label: "User Name",
                Render: (row: any) =>
                  row.userName
                    ? row.userName
                    : row.userEmail
                    ? row.userEmail
                    : row.userPhone
                    ? row.userPhone
                    : "No Details Found",
              },
            ]
          : [
              ...(true // TODO: Set to masterView later [https://revosauto.atlassian.net/browse/NIP-29?focusedCommentId=31062]
                ? [
                    {
                      key: "hostName",
                      label: "Host",
                      format: (val: any) => val?.slice(0, 35) || "-",
                    },
                    {
                      key: "userName",
                      label: "User",
                      Render: (row: any) =>
                        row.userName
                          ? row.userName
                          : row.userEmail
                          ? row.userEmail
                          : row.userPhone
                          ? row.userPhone
                          : "No Details Found",
                    },
                  ]
                : []),
            ]),
        {
          key: "bookingStart",
          label: "Start Time",
          format: (value) => moment(value).format("MMM DD, hh:mm a"),
        },
        ...(isCUGMonitorAccount
          ? ([
              {
                key: "bookingEnd",
                label: "End Time",
                format: (value) => moment(value).format("MMM DD, hh:mm a"),
              },
            ] as Column[])
          : []),
        {
          key: "bookingDuration",
          label: "Booking Duration",
          format: (value) => getDuration(value * 60),
        },
        ...(isCUGMonitorAccount || true // Set to masterView later
          ? [
              {
                key: "chargingDuration",
                label: "Charging Duration",
                format: (value: any) => getDuration(value * 60),
              },
            ]
          : []),
        // {
        //   key: "cugDetails",
        //   label: "CUG",
        //   Render: (row) => Object.keys(row?.cugDetails).length === 0 ? "-" : row?.cugDetails,
        // },
        {
          key: "kibanaSynced",
          label: "Sync Status",
          Render: (row) =>
            row.kibanaSynced === true ? (
              <Box display="flex" alignItems="center">
                <Sync fontSize="small" color="success" sx={{ mr: 0.5 }} />
                Synced
                <Tooltip
                  placement="right"
                  title={`Last Sync: ${
                    row.summarySyncTime
                      ? moment(row.summarySyncTime).format("MMM DD, hh:mm a")
                      : "N/A"
                  }`}
                >
                  <InfoOutlined
                    color="action"
                    sx={{
                      ml: 0.5,
                      fontSize: 14,
                      color: (theme) => theme.customColors.action,
                    }}
                  />
                </Tooltip>
              </Box>
            ) : (
              <Box display="flex" alignItems="center">
                <SyncProblem
                  fontSize="small"
                  color="warning"
                  sx={{ mr: 0.5 }}
                />
                Not synced
              </Box>
            ),
        },
        ...(isCUGMonitorAccount || true // Set to masterView later
          ? [
              {
                key: "estimatedAmount",
                label: "Estimated Bill",
                Render: (row: any) =>
                  `₹${
                    Number(row.estimatedAmount || 0) +
                    Number(row.subscriptionDiscount || 0) +
                    Number(row.couponDiscount || 0)
                  }`,
              },
            ]
          : []),
        {
          key: "amount",
          label: "Actual Bill",
          Render: (row) =>
            `₹${
              Number(row.amount || 0) +
              Number(row.subscriptionDiscount || 0) +
              Number(row.couponDiscount || 0)
            }`,
        },
        ...(!isCUGMonitorAccount
          ? [
              {
                key: "settlementStatus",
                label: "Settlement Status",
                format: (value: any) =>
                  value === "NA"
                    ? "N/A"
                    : titleCase(value.split("_").join(" ")),
              },
            ]
          : []),
        ...(true // Set to masterView later
          ? [
              {
                key: "estimatedEnergyConsumption",
                label: "Estimated Energy Consumption",
                format: (value: any) =>
                  typeof value === "number" ? value.toFixed(3) + " kWh" : "-",
              },
            ]
          : []),
        // { key: "transactionId", label: "Transaction ID" },
        {
          key: "energyConsumed",
          label: "Energy Utilised",
          // label: "Actual Energy Dispensed",
          Render: (row) => <LiveEnergy booking={row} />,
        },
        // { key: "city", label: "Location" },
        ...(isCUGMonitorAccount
          ? [
              {
                key: "cugGroup",
                label: "CUG Name",
                Render: (row: any) =>
                  row?.cugDetails?.cugId?.name
                    ? row.cugDetails.cugId.name
                    : "-",
              },
            ]
          : []),
        {
          key: "actions",
          label: "Actions",
          Render: (row) => (
            <>
              <Tooltip title="Info">
                <IconButton
                  size="small"
                  sx={{
                    mr: 0.5,
                  }}
                  onClick={() =>
                    drawer.open(<InfoDrawer key={row._id} booking={row} />)
                  }
                  children={<InfoOutlined fontSize="small" />}
                />
              </Tooltip>
              <Tooltip title="Download Invoice">
                <IconButton
                  size="small"
                  onClick={() =>
                    downloadInvoice(
                      row.bookingId,
                      row.chargerId,
                      row.bookingStart,
                      row.bookingEnd
                    )
                  }
                  children={<FileDownload fontSize="small" />}
                  disabled={row.bookingStatus === "BOOKED"}
                />
              </Tooltip>
            </>
          ),
        },
      ]}
    />
  );
};

const LiveEnergy = ({ booking }: any) => {
  const isOCPP = booking?.communicationProtocol === "OCPP";
  const isMQTT = booking?.communicationProtocol === "MQTT";
  const shouldShowLiveData =
    booking?.bookingStatus === "BOOKED" && (isOCPP || isMQTT);

  // const OCPP_URL_PROD = "https://ocpp.revos.in";
  // const WIFI_URL_PROD = "https://bolt-wifi.revos.in";

  const url = isOCPP
    ? `${OCPP_URL}/charger/getChargerData`
    : `${WIFI_URL}/fetchEnergyConsumedV2`;

  const { isLoading: chargerLoading, data: chargerData } = useQuery(
    [
      "getChargerData",
      booking?.chargerId,
      booking?.connectorId,
      booking?.bleMac,
      booking?.bookingId,
    ],
    () =>
      authorizedFetch(url, {
        method: "POST",
        headers: {
          stage: "test",
          "Content-Type": "application/json",
        },
        body: isOCPP
          ? {
              chargerId: booking?.chargerId + "/" + booking?.connectorId,
              action: "currentMeterValues",
            }
          : {
              deviceId: booking?.bleMac?.replaceAll(":", ""),
              bookingId: booking?.bookingId,
            },
      }),
    {
      enabled: shouldShowLiveData,
      refetchInterval: shouldShowLiveData ? 10000 : false,
    }
  );

  const { value, unit } = chargerData?.data || {};

  const liveEnergyConsumed = shouldShowLiveData
    ? isOCPP
      ? // For OCPP
        (value ? parseFloat(value).toFixed(3) : 0) + " " + (unit || "kWh")
      : // For MQTT
        (chargerData?.energyConsumed
          ? (chargerData?.energyConsumed / 1000).toFixed(3)
          : 0) + " kWh"
    : null;

  const energyConsumed =
    (booking?.energyConsumed ? booking.energyConsumed.toFixed(3) : 0) + " kWh";

  return (
    <>
      {shouldShowLiveData
        ? chargerLoading
          ? "Loading..."
          : liveEnergyConsumed
        : energyConsumed}
    </>
  );
};

export default BookingList;
