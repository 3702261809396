import React, { useEffect, useRef, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { Close, SearchOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { getDarkModePreference, GlobalState } from "utils";

// TODO: toggle case sensitive search

interface Props {
  handleSearch: (input: string) => void;
  placeholder?: string;
  persist?: boolean;
  enableClear?: boolean;
  enableClose?: boolean;
  onClose?: () => void;
  value?: string;
  disableFocus?: boolean;
  hideIcon?: boolean;
  isValid?: (val: string) => boolean;
}

const Search: React.FC<Props> = ({
  handleSearch,
  placeholder,
  persist,
  enableClear,
  enableClose,
  onClose,
  value,
  disableFocus,
  hideIcon,
  isValid,
}) => {
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [focused, setFocus] = useState(false);
  const [input, setInput] = useState("");
  const [clearButton, setClearButton] = useState(false);

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter" && e.currentTarget.value !== "") {
      if (isValid && !isValid(e.currentTarget.value)) return;
      handleSearch(e.currentTarget.value);
      setClearButton(true);
      if (!persist) setInput("");
    }
  }

  useEffect(() => {
    if (value) {
      setInput(value);
      setClearButton(true);
    }
  }, [value]);

  return (
    <Box
      onClick={() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }}
      sx={{
        cursor: "text",
        display: "flex",
        alignItems: "center",
        transition: "all 200ms",
        border: 1,
        borderColor: (theme) =>
          !focused ? theme.customColors.border : "primary.main",
        borderRadius: 10,
        pl: hideIcon ? 1.75 : 1.25,
        pr: 0.5,
        height: 40,
        bgcolor: (theme) => theme.palette.background.paper,
        "& input": {
          pt: 0.5,
          fontSize: 14,
          outline: "none",
          border: "none",
          transition: "all 150ms",
          width: focused && !disableFocus ? 140 : 120,
          bgcolor: "transparent",
          color: isDarkMode ? "white" : "black",
        },
      }}
    >
      {!hideIcon && (
        <SearchOutlined
          fontSize="small"
          sx={{
            mr: 1,
            height: "20px",
            color: (theme) => theme.customColors.greyTertiary,
          }}
        />
      )}
      <input
        ref={inputRef}
        type="text"
        name="search"
        placeholder={placeholder || "Search..."}
        value={input}
        autoComplete="off"
        onFocus={() => {
          setFocus(true);
        }}
        onBlur={() => {
          setFocus(false);
        }}
        onChange={(e) => {
          setInput(e.target.value);
        }}
        onKeyDown={handleKeyDown}
      />
      {enableClear && (
        <IconButton
          sx={{ visibility: clearButton || enableClose ? "visible" : "hidden" }}
          onClick={(e) => {
            e.stopPropagation();
            handleSearch("");
            setInput("");
            setClearButton(false);
            onClose && onClose();
          }}
          size="small"
        >
          <Close fontSize="small" />
        </IconButton>
      )}
    </Box>
  );
};

export default Search;
