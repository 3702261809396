import {
  HighlightOff,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { useState } from "react";

const ImageDialog = ({
  open,
  handleClose,
  images,
  activeImage: activeImageIndex,
  user,
}: any) => {
  const [activeImage, setActiveImage] = useState(activeImageIndex);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          KYC Documents
          <Divider flexItem orientation="vertical" sx={{ mx: 1.5 }} />
          <Typography color="text.secondary">{user?.chargerId}</Typography>
        </Box>
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            height: "75vh",
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              height: 1,
              width: 1,
              position: "absolute",
              top: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              zIndex: 2,
            }}
          >
            <Button
              variant="outlined"
              sx={{
                p: 0,
                minWidth: 40,
                width: 40,
                height: 40,
                borderRadius: 50,
              }}
              onClick={() => setActiveImage((prev: any) => prev - 1)}
              disabled={activeImage === 0}
            >
              <KeyboardArrowLeft />
            </Button>
            <Button
              variant="outlined"
              sx={{
                p: 0,
                minWidth: 40,
                width: 40,
                height: 40,
                borderRadius: 50,
              }}
              onClick={() => setActiveImage((prev: any) => prev + 1)}
              disabled={activeImage === images.length - 1}
            >
              <KeyboardArrowRight />
            </Button>
          </Box>
          {images.map((image: any, i: number) => (
            <Box
              key={i}
              component="img"
              sx={{
                height: 1,
                maxWidth: 1,
                maxHeight: 1,
                width: 1,
                display: activeImage === i ? "block" : "none",
                objectFit: "contain",
                overflow: "hidden",
              }}
              src={image.url}
              alt={"KYC Document"}
            />
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ImageDialog;
