import {
  Box,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  // TextField,
  // InputAdornment,
  // Autocomplete,
  DialogContent,
  Typography,
} from "@mui/material";
import { HighlightOff } from "@mui/icons-material";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { queryClient } from "index";
import { RETAIL_URL } from "utils/constants";
// import { useQuery } from "react-query";
import { useEffect } from "react";
import AssignVehicleIcon from "assets/images/icons/vehicle.svg";

const BulkAssignDialog = ({ open, handleClose, bulk, totalItems }: any) => {
  useEffect(() => {
    if (!open) return;
  }, [open]);

  function handleToggle() {
    setLoader(true);
    let url = `${RETAIL_URL}/assembly/component/bulkMark/status`;
    authorizedFetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        fileKeyId: bulk._id,
        statusMark: "ASSIGNED",
      },
    })
      .then((res) => {
        setLoader(false);
        if (res.meta.success === true) {
          snackbar.success(`Selected models assigned`);
          queryClient.resetQueries("getUploadHistoryData");
          handleClose();
        } else {
          console.error(res);
          snackbar.error("Error assigning device(s)");
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
        snackbar.error("Error assigning device(s)");
      });
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <div style={{ flexGrow: 1 }}></div>
          <IconButton
            children={<HighlightOff />}
            color="inherit"
            onClick={handleClose}
            sx={{ transform: "translate(8px, -8px)" }}
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={AssignVehicleIcon}
              alt="Vehicle icon"
              style={{ width: 80 }}
            />
          </div>
          <br />
          Are you sure you want to assign component?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleToggle();
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BulkAssignDialog;
