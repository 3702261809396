import { HighlightOff } from "@mui/icons-material";
import { Avatar, Box, IconButton, Tooltip } from "@mui/material";
import CircularLoader from "components/CircularLoader";
import { Fragment, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  authorizedFetch,
  drawer,
  getDarkModePreference,
  getDuration,
  GlobalState,
  titleCase,
} from "utils";
import { RETAIL_URL, VEHICLE_SUBSCRIPTION_URL } from "utils/constants";
import AddVehicleDialog from "./AddVehicleDialog";
import DeleteDialog from "./DeleteDialog";
import { getModelConfigLabel } from "./ModelDrawer";
import SellDialog from "./SellDialog";
import { ReactComponent as DeleteIcon } from "assets/images/icons/delete.svg";
import { ReactComponent as EditIcon } from "assets/images/icons/edit.svg";
import moment from "moment";

function getComponentSpecLabel(key: string) {
  switch (key) {
    case "macId":
      return "MAC ID";
    default:
      return key;
  }
}

const VehicleDrawer = ({ vehicle }: any) => {
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state),
  );

  const [editDialog, setEditDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [sellDialog, setSellDialog] = useState(false);

  const formatLastPing = (value: any) => {
    let lastPing: any;
    value
      ? (moment().valueOf() - moment(value).valueOf()) / 60000 < 360
        ? (lastPing = getDuration(
            Math.abs(moment().valueOf() - moment(value).valueOf()) / 60000,
          ))
        : (lastPing = "Dormant")
      : (lastPing = "Dormant");

    return lastPing;
  };

  const vehicleUrl = `${RETAIL_URL}/assembly/vehicle/${vehicle?._id}`;
  const { isLoading: vehicleLoading, data: vehicleData } = useQuery(
    ["getVehicle", vehicle?._id],
    () => authorizedFetch(vehicleUrl),
  );

  const subscriptionUrl = `${VEHICLE_SUBSCRIPTION_URL}/v1/subscription?vin=${vehicle?.vin}`;
  const { data: subscriptionData } = useQuery(
    ["getSubscription", vehicle?.vin],
    () => authorizedFetch(subscriptionUrl),
  );

  const vehicleInfo =
    vehicleData?.data?.constructor === Array ? vehicleData.data[0] : {};

  let components: any = {};

  vehicleInfo?.components?.forEach((component: any) => {
    let info =
      `Type: ${component?.id?.parentModel?.key}\n` +
      `UID: ${component?.id?.uid}\n`;

    if (component.category === "CONTROLLER") {
      info += `Serial Number: ${component?.id?.serialNumber || "N/A"}\n`;
    }

    let specs = Object.keys(component?.id?.specs || {})
      .map(
        (spec: any) =>
          `${getComponentSpecLabel(spec)}: ${component?.id?.specs?.[spec]}\n`,
      )
      .join("");

    components[titleCase(component.category)] = info + specs;
  });

  let vehicleDetails: any = {
    "Basic Information": {
      VIN: vehicle?.vin,
      "Vehicle Model": vehicle?.model?.name || vehicleInfo?.model?.name,
      Protocol: vehicle?.model?.protocol || vehicleInfo?.model?.protocol,
      // ...(vehicle?.status === "SOLD"
      //   ? { Distributor: vehicle?.distributor?.name || "-" }
      //   : {}),
      ...(vehicle?.status === "SOLD" ? { Color: vehicle?.specs?.color } : {}),
      ...(vehicle?.status === "SOLD" ? { Health: vehicle?.health } : {}),
      ...(vehicle?.status === "SOLD"
        ? {
            "Last Pinged":
              vehicle?.lastPinged === null
                ? "Dormant"
                : formatLastPing(vehicle?.lastPinged),
          }
        : {}),

      // Status: vehicle?.status,
    },
    "User Details": {
      Name: vehicle?.currentOwner?.firstName
        ? vehicle?.currentOwner?.firstName +
          " " +
          vehicle?.currentOwner?.lastName
        : "N/A",
      Phone: vehicle?.currentOwner?.phone
        ? vehicle?.currentOwner?.phone
        : "N/A",
      Email: vehicle?.currentOwner?.email
        ? vehicle?.currentOwner?.email
        : "N/A",
      Address: vehicle?.currentOwner?.address
        ? `${vehicle?.currentOwner?.address?.district}, ${vehicle?.currentOwner?.address?.state}, ${vehicle?.currentOwner?.address?.country}`
        : "N/A",
    },
    "Vehicle Configuration": vehicleLoading
      ? {}
      : vehicleInfo?.config?.vehicleConfigValues ||
        vehicleInfo?.model?.config?.configs ||
        {},
    ...(Object.keys(components).length > 0
      ? {
          Components: components,
        }
      : {}),
  };

  if (vehicle?.status === "SOLD" && !vehicleLoading) {
    vehicleDetails["Subscription Details"] = {
      "Subscription Eligibility":
        subscriptionData?.data?.eligible === "ELIGIBLE"
          ? "Eligible"
          : "Not Eligible",
      "Subscription Name": subscriptionData?.data?.name,
      "Subscription Status":
        subscriptionData?.data?.status === "ACTIVE"
          ? "Active"
          : subscriptionData?.data?.status === "INACTIVE"
          ? "Pending"
          : "N/A",
      "Subscribed on": !subscriptionData?.data?.subscribedAt
        ? "N/A"
        : moment(subscriptionData?.data?.subscribedAt).format("DD MMM YYYY"),
      "Subscription Validity": subscriptionData?.data?.validity,
      "Subscription ends on": !subscriptionData?.data?.endsOn
        ? "N/A"
        : moment(subscriptionData?.data?.endsOn).format("DD MMM YYYY"),
    };
  }
  return (
    <>
      <AddVehicleDialog
        open={editDialog}
        handleClose={() => setEditDialog(false)}
        isEditMode
        vehicle={vehicle}
        model={vehicle?.model}
      />
      <DeleteDialog
        open={deleteDialog}
        handleClose={() => setDeleteDialog(false)}
        vehicle={vehicle}
        onDelete={() => drawer.close()}
        isForVehicle
      />
      <SellDialog
        open={sellDialog}
        handleClose={() => setSellDialog(false)}
        vehicle={vehicle}
        onSave={() => drawer.close()}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            px: 3,
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: isDarkMode ? "#000" : "#3D3D3D",
            fontWeight: 500,
            color: "#fff",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {vehicle?.vin}
          </Box>
          <Box display="flex">
            {/* <Tooltip title="Sell">
              <IconButton
                sx={{ mr: 1 }}
                children={<SellOutlined />}
                color="inherit"
                size="small"
                onClick={() => setSellDialog(true)}
              />
            </Tooltip> */}
            <IconButton
              children={<HighlightOff />}
              color="inherit"
              size="small"
              onClick={() => drawer.close()}
            />
          </Box>
        </Box>
        <Box flexGrow={1} overflow="auto">
          {vehicle?.status === "SOLD" ? (
            ""
          ) : (
            <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
              <Tooltip title="Delete">
                <IconButton
                  sx={{ mr: 3, mt: 1 }}
                  children={<DeleteIcon />}
                  color="inherit"
                  size="small"
                  onClick={() => setDeleteDialog(true)}
                />
              </Tooltip>
            </Box>
          )}
          <Box
            sx={{
              px: 3,
              pt: 1,
              pb: 2,
              "& .table": {
                width: 1,
                fontSize: 14,
                lineHeight: "16px",
                "& td": {
                  // borderRadius: "4px",
                  // border: "1px solid #00000010",
                  py: 1.25,
                  px: 2,
                  whiteSpace: "pre-line",
                },
                "& .bold": {
                  fontWeight: 500,
                },
                "& .header": {
                  // px: 2,
                  // py: 1,
                  position: "relative",
                  "& td": {
                    position: "absolute",
                    verticalAlign: "middle",
                    backgroundColor: (theme: any) => theme.customColors.header,
                    width: 1,
                    borderRadius: "4px",
                    fontSize: 16,
                    fontWeight: 600,
                    "& .label": {
                      display: "inline-block",
                      transform: "translateY(1px)",
                      py: 1.125,
                    },
                  },
                },
                "& .first > td": {
                  pt: 9,
                },
                "& .last > td": {
                  pb: 2.75,
                },
              },
            }}
          >
            <table className="table">
              <tbody>
                {Object.keys(vehicleDetails).map((category: any, i: number) => (
                  <Box
                    sx={{
                      borderRadius: "4px",
                      border: "1px solid #00000010",
                      display: "flex",
                      flexDirection: "column",
                      mb: 3,
                    }}
                  >
                    <Fragment key={i}>
                      <tr className="header">
                        <td colSpan={2}>
                          {category === "Vehicle Configuration" ? (
                            <Box
                              sx={{
                                display: "flex",
                              }}
                            >
                              <span className="label">
                                {category.toUpperCase()}
                              </span>{" "}
                              <Tooltip title="Edit">
                                <IconButton
                                  sx={{ marginLeft: "auto" }}
                                  children={<EditIcon />}
                                  color="inherit"
                                  size="small"
                                  onClick={() => setEditDialog(true)}
                                />
                              </Tooltip>
                            </Box>
                          ) : (
                            <span className="label">
                              {category.toUpperCase()}
                            </span>
                          )}
                        </td>
                      </tr>
                      {Object.keys(vehicleDetails[category]).map(
                        (label: any, i: number, arr) => {
                          let value = vehicleDetails[category][label];
                          return (
                            <tr
                              key={i}
                              className={
                                (i === 0 ? "first " : "") +
                                (i === arr.length - 1 ? "last" : "")
                              }
                            >
                              <td style={{ width: 180 }}>
                                {category === "Vehicle Configuration"
                                  ? getModelConfigLabel(label)
                                  : label}
                              </td>
                              <td className="bold">
                                {label === "Health" ? (
                                  <Avatar
                                    variant="status"
                                    className={
                                      value === "active"
                                        ? ""
                                        : value === "moderate"
                                        ? "yellow"
                                        : value === "critical"
                                        ? "red"
                                        : "grey"
                                    }
                                  >
                                    {value}
                                  </Avatar>
                                ) : label === "Status" ? (
                                  <Avatar
                                    variant="status"
                                    className={
                                      value !== "ASSIGNED" ? "yellow" : ""
                                    }
                                  >
                                    {titleCase(value)}
                                  </Avatar>
                                ) : value === true ? (
                                  "Enabled"
                                ) : value === false ? (
                                  "Disabled"
                                ) : value === "" ? (
                                  "-"
                                ) : typeof value === "string" ? (
                                  value
                                ) : (
                                  JSON.stringify(value)
                                )}
                              </td>
                            </tr>
                          );
                        },
                      )}
                    </Fragment>
                  </Box>
                ))}
              </tbody>
            </table>
          </Box>
          {vehicleLoading && (
            <Box mt={6}>
              <CircularLoader />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default VehicleDrawer;
