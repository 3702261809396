import { Add, DeleteOutline, HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CircularLoader from "components/CircularLoader";
import RedAsterisk from "components/RedAsterisk";
import { queryClient } from "index";
import { Fragment, useEffect, useState } from "react";
import { useQueries, useQuery } from "react-query";
import { authorizedFetch, drawer, setLoader, snackbar } from "utils";
import { RETAIL_URL } from "utils/constants";
import RemapDialog from "./RemapDialog";

const AddVehicleDialog = ({
  open,
  handleClose,

  isEditMode = false,

  vehicle,
  onSave,
  refetchVehicles,
  refetchStats,
}: any) => {
  const [form, setForm] = useState<any>({ vin: "", specs: {} });
  const [specs, setSpecs] = useState<any>({});
  const [specsMenuAnchor, setSpecsMenuAnchor] = useState<any>(null);

  const [fmsModel, setFMSModel] = useState("");

  const [modelsList, setModelList] = useState<any>([]);

  const [selectedModel, setSelectedModel] = useState<any>({});

  const [newModel, setNewModel] = useState<any>();

  const [remapDialog, setRemapDialog] = useState({ open: false, message: "" });

  // useEffect(() => {
  //   if (selectedModel) {
  //     setNewModel(selectedModel);
  //   }
  // }, [selectedModel]);

  const specsData = useQueries(
    [
      "VEHICLE",
      ...(newModel?.components?.constructor === Array
        ? newModel.components.map((el: any) => el.category)
        : []),
    ].map((category: any) => ({
      queryKey: ["getSpecs", category],
      queryFn: () =>
        authorizedFetch(`${RETAIL_URL}/inventory/product-specs/${category}`),
      onSuccess: (data: any) => {
        setSpecs((prev: any) => ({
          ...prev,
          [category]: data.data,
        }));
      },
    }))
  );

  let specsLoading = (specsData?.constructor === Array ? specsData : []).some(
    (el: any) => el.isLoading
  );

  useEffect(() => {
    if (open) setForm({ vin: "", specs: {} });
  }, [open]);

  useEffect(() => {
    if (open && newModel) {
      setForm((prev: any) => ({
        ...prev,
        components: newModel?.components?.map((el: any) => ({
          uid: "",
          category: el.category,
          modelId: "",
        })),
      }));
    }
  }, [open, fmsModel, newModel]);

  useEffect(() => {
    if (!open) {
      setSelectedModel({});
      setNewModel({});
      setFMSModel("");
    }
  }, [open]);

  function handleSave(shouldRemap?: boolean) {
    setLoader(true);
    let createUrl = `${RETAIL_URL}/assembly/vehicle/create`;
    let updateUrl = `${RETAIL_URL}/assembly/vehicle/${vehicle?._id}`;
    authorizedFetch(isEditMode ? updateUrl : createUrl, {
      method: isEditMode ? "PUT" : "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        ...(!isEditMode
          ? {
              vin: form.vin,
              model: newModel._id,
            }
          : {}),
        specs: form.specs,
        components: form.components,
        rentalStatus: "INSTOCK",
        status: "RENTAL_UNASSIGNED",
        ...(shouldRemap ? { remap: true } : {}),
      },
    })
      .then((res) => {
        setLoader(false);
        if (res.meta.success) {
          snackbar.success(`${isEditMode ? "Updated" : "Added"} vehicle`);
          refetchVehicles();
          refetchStats();
          handleClose();
          onSave && onSave();
          queryClient.resetQueries("getVehicles");
          if (isEditMode) {
            queryClient.resetQueries("getVehicle");
            drawer.close();
          }
        } else {
          if (
            String(res.msg).includes("Component(s) with uids") &&
            String(res.msg).includes("already")
          ) {
            setRemapDialog({ open: true, message: res.msg });
          } else {
            snackbar.error("Error: " + res.msg);
          }
        }
      })
      .catch((err: any) => {
        setLoader(false);
        snackbar.error("An error occurred");
        console.error(err);
      });
  }

  const modelsUrl = `${RETAIL_URL}/assembly/model?orderBy=createdAt&ascending=false`;

  const { isLoading: modelsLoading, data: modelsData } = useQuery(
    "getModels",
    () => authorizedFetch(modelsUrl)
  );

  const modelUrl = `${RETAIL_URL}/assembly/model/${selectedModel?._id}`;
  const { isLoading: modelLoading, data: modelData } = useQuery(
    ["getModel", selectedModel?._id],
    () => authorizedFetch(modelUrl),
    { enabled: Boolean(selectedModel?._id) }
  );

  useEffect(() => {
    if (modelData) {
      setNewModel(modelData?.data[0]);
    }
  }, [modelData]);

  useEffect(() => {
    if (modelsData) {
      let arr = (modelsData?.data?.constructor === Array ? modelsData.data : [])
        .filter((model: any) => model.protocol === "PNP")
        .map((model: any) => {
          return {
            _id: model?._id,
            value: model?.name,
            components: model?.components,
          };
        });
      setModelList(arr);
    }
  }, [modelsData]);

  useEffect(() => {
    if (fmsModel) {
      let model = modelsList.find((el: any) => el._id === fmsModel);

      setSelectedModel(model);
    }
    // eslint-disable-next-line
  }, [fmsModel]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <RemapDialog
        open={remapDialog.open}
        message={remapDialog.message}
        onClose={() => setRemapDialog((prev) => ({ ...prev, open: false }))}
        onConfirm={() => handleSave(true)}
      />
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          Add Vehicle
        </Box>
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mx: "auto", maxWidth: 550 }}>
          <Typography variant="body2" color="text.secondary">
            <RedAsterisk /> Required fields
          </Typography>
          <Box
            sx={{
              my: 3,
              mx: 1,
              display: "grid",
              gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
              gap: 3,
            }}
          >
            <Box>
              <Typography className="label">
                VIN <RedAsterisk />
              </Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="VIN"
                value={form?.vin}
                onChange={(e) => {
                  if (
                    /^[a-zA-Z0-9]*$/.test(e.target.value) &&
                    e.target.value.length <= 17
                  )
                    setForm((prev: any) => ({
                      ...prev,
                      vin: e.target.value,
                    }));
                }}
                disabled={isEditMode}
                error={!isEditMode && form?.vin?.length > 17}
                helperText={
                  !isEditMode && form?.vin?.length > 17
                    ? "VIN cannot be more than 17 characters long"
                    : undefined
                }
              />
            </Box>
            <Box>
              <Typography className="label">
                Model
                <RedAsterisk />
              </Typography>
              <Select
                fullWidth
                value={fmsModel}
                onChange={(e: any) => {
                  setFMSModel(e.target.value);
                }}
                displayEmpty
                sx={{ maxHeight: 40 }}
              >
                <MenuItem disabled value="">
                  {modelsLoading ? "Loading..." : <em>Select</em>}
                </MenuItem>
                {modelsList?.map(({ _id, value }: any) => (
                  <MenuItem key={_id} value={_id}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            {modelLoading || specsLoading ? (
              <Box my={5} sx={{ gridColumn: { sm: "span 2" } }}>
                <CircularLoader />
              </Box>
            ) : (
              <>
                {fmsModel ? (
                  <Box sx={{ gridColumn: { sm: "span 2" } }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography className="label" sx={{ mb: "0 !important" }}>
                        Specs
                      </Typography>
                      <Tooltip title="Add">
                        <IconButton
                          sx={{ ml: 0.5 }}
                          size="small"
                          children={<Add fontSize="small" />}
                          onClick={(e) => setSpecsMenuAnchor(e.currentTarget)}
                        />
                      </Tooltip>
                    </Box>
                    <Menu
                      anchorEl={specsMenuAnchor}
                      open={Boolean(specsMenuAnchor)}
                      onClose={() => setSpecsMenuAnchor(null)}
                    >
                      {specs?.VEHICLE?.filter(
                        (el: any) =>
                          !Object.keys(form?.specs?.VEHICLE || {}).includes(
                            el.key
                          )
                      ).map((el: any, i: number) => (
                        <MenuItem
                          key={i}
                          onClick={() => {
                            setForm((prev: any) => ({
                              ...prev,
                              specs: {
                                ...prev.specs,
                                VEHICLE: {
                                  ...(prev.specs.VEHICLE || {}),
                                  [el.key]: "",
                                },
                              },
                            }));
                          }}
                        >
                          {el.key}
                        </MenuItem>
                      ))}
                    </Menu>
                    <Box
                      sx={{
                        mt: form?.specs?.VEHICLE ? 1 : 0,
                        display: "grid",
                        gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                        gap: 3,
                      }}
                    >
                      {Object.keys(form?.specs?.VEHICLE || {}).map(
                        (spec: string, i: number) => {
                          return (
                            <Box key={i}>
                              <Box
                                sx={{
                                  mb: 0.5,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  className="label"
                                  sx={{ mb: "0 !important" }}
                                >
                                  {spec}
                                </Typography>
                                <Tooltip title="Remove">
                                  <IconButton
                                    size="small"
                                    sx={{
                                      transform: "translateX(8px)",
                                      color: (theme) => theme.customColors.grey,
                                    }}
                                    children={
                                      <DeleteOutline
                                        fontSize="small"
                                        color="inherit"
                                      />
                                    }
                                    onClick={() =>
                                      setForm((prev: any) => {
                                        let newForm = { ...prev };
                                        delete newForm.specs.VEHICLE[spec];

                                        console.log(spec, newForm);
                                        return newForm;
                                      })
                                    }
                                  />
                                </Tooltip>
                              </Box>
                              <TextField
                                size="small"
                                fullWidth
                                placeholder={spec}
                                value={form?.specs?.VEHICLE?.[spec]}
                                onChange={(e) =>
                                  setForm((prev: any) => ({
                                    ...prev,
                                    specs: {
                                      ...prev.specs,
                                      VEHICLE: {
                                        ...prev.specs.VEHICLE,
                                        [spec]: e.target.value,
                                      },
                                    },
                                  }))
                                }
                              />
                            </Box>
                          );
                        }
                      )}
                    </Box>
                  </Box>
                ) : (
                  ""
                )}

                {newModel?.components?.map((el: any, i: number) => {
                  let component = form?.components?.find(
                    ({ category }: any) => category === el.category
                  );

                  function getLabel(key: string) {
                    switch (key) {
                      case "macId":
                        return "MAC ID";
                      case "firmware":
                        return "Firmware";
                      case "expectedFirmware":
                        return "Expected Firmware";
                      default:
                        return key;
                    }
                  }

                  function handleChange(key: string, value: any) {
                    setForm((prev: any) => {
                      let newComponents = [...prev.components];
                      let existing = newComponents.find(
                        ({ category }: any) => category === el.category
                      );

                      if (existing) existing[key] = value;

                      return { ...prev, components: newComponents };
                    });
                  }

                  return (
                    <Fragment key={i}>
                      <Box sx={{ mb: -1, gridColumn: { sm: "span 2" } }}>
                        <Typography variant="overline">
                          {el.category}
                        </Typography>
                        <Divider />
                      </Box>
                      <Box>
                        <Typography className="label">
                          Type <RedAsterisk />
                        </Typography>
                        <Select
                          size="small"
                          fullWidth
                          placeholder="Select"
                          displayEmpty
                          value={component?.modelId || ""}
                          renderValue={
                            component?.modelId !== ""
                              ? undefined
                              : () => <em>Select</em>
                          }
                          onChange={(e) =>
                            handleChange("modelId", e.target.value)
                          }
                        >
                          {el.modelId?.map((el: any, i: number) => (
                            <MenuItem key={i} value={el._id}>
                              {el.key}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                      <Box>
                        <Typography className="label">
                          UID <RedAsterisk />
                        </Typography>
                        <TextField
                          type="tel"
                          fullWidth
                          size="small"
                          placeholder="UID"
                          value={component?.uid}
                          onChange={(e) =>
                            handleChange(
                              "uid",
                              e.target.value?.length > 0
                                ? e.target.value.replace(/[^0-9]/, "")
                                : e.target.value
                            )
                          }
                          inputProps={{ maxLength: 18 }}
                        />
                      </Box>
                      {specs[el.category]?.map((spec: any, i: number) => (
                        <Fragment key={i}>
                          <Box>
                            <Typography className="label">
                              {getLabel(spec.key)}{" "}
                              {spec.required && <RedAsterisk />}
                            </Typography>
                            <TextField
                              fullWidth
                              size="small"
                              placeholder={getLabel(spec.key)}
                              value={
                                form?.specs?.[el.category]?.[spec.key] || ""
                              }
                              onChange={(e) =>
                                setForm((prev: any) => ({
                                  ...prev,
                                  specs: {
                                    ...(prev.specs || {}),
                                    [el.category]: {
                                      ...(prev.specs[el.category] || {}),
                                      [spec.key]:
                                        spec.key === "macId" &&
                                        e.target.value?.length > 0
                                          ? e.target.value.replace(
                                              /[^a-zA-Z0-9-_:]/,
                                              ""
                                            )
                                          : spec.key === "MSISDN Number"
                                          ? e.target.value?.length > 0
                                            ? e.target.value.replace(
                                                /[^0-9]/,
                                                ""
                                              )
                                            : e.target.value
                                          : e.target.value,
                                    },
                                  },
                                }))
                              }
                              inputProps={{
                                maxLength: spec.key === "macId" ? 20 : 13,
                              }}
                              type={spec.key === "macId" ? "" : "tel"}
                            />
                          </Box>
                        </Fragment>
                      ))}
                    </Fragment>
                  );
                })}
              </>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            handleSave();
          }}
          disabled={fmsModel === "" || form?.vin?.length === 0}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddVehicleDialog;
