import { Paper } from "@mui/material";

import Map from "components/Map";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

import { authorizedFetch } from "utils";
import { LEASE_URL } from "utils/constants";

const VehiclesMap = ({ isMasterView }: any) => {
  const [mapList, setMapList] = useState<any>();

  const mapUrl = `${LEASE_URL}/company/vehicles/map?master=${isMasterView}`;

  const { isLoading, data: mapsData } = useQuery(
    ["getVehicleMap", isMasterView],
    () => authorizedFetch(mapUrl)
  );

  useEffect(() => {
    if (mapsData && mapsData?.constructor === Array) {
      let arr = mapsData
        ?.filter(
          (el: any) =>
            Object.keys(el.location).length !== 0 &&
            ["AVAILABLE", "BOOKED"].includes(el.rentalStatus)
        )
        ?.map((el: any) => ({
          vehicle: { vehicleStatus: el.rentalStatus },
          id: el.vin,
          station: {
            location: {
              latitude: el.location.latitude,
              longitude: el.location.longitude,
            },
          },
        }));
      setMapList(arr);
    }
  }, [mapsData]);

  return (
    <Paper
      sx={{
        zIndex: 1,
        position: "relative",
        bgcolor: "divider",
        overflow: "hidden",
      }}
    >
      <Map
        key={mapList}
        loading={isLoading}
        type="points"
        showVehicleIcon
        dataArray={mapList || []}
      />
    </Paper>
  );
};

export default VehiclesMap;
