import { Box } from "@mui/material";
import Breadcrumbs from "../../../components/Breadcrumbs";
import List from "./List";

const Users = () => {
  return (
    <>
      <Box
        width={1}
        mb={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Breadcrumbs />
      </Box>
      <Box sx={{ width: 1 }}>
        <List />
      </Box>
    </>
  );
};

export default Users;
