import { Wifi, WifiOff } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { authorizedFetch, setLoader, snackbar, titleCase } from "utils";
import { BOLT_URL } from "utils/constants";

const OTAUpdateDialog = ({ open, handleClose, data }: any) => {
  const [version, setVersion] = useState("");

  const { isLoading: chargerStatusLoading, data: chargerStatusData } = useQuery(
    ["getChargerStatus", data?.charger?.chargerId],
    () =>
      authorizedFetch(
        `${BOLT_URL}/charger/remote/${data?.charger?.chargerId}/status`
      ),
    {
      enabled: !!data?.charger?.chargerId,
    }
  );

  function handleSubmit() {
    setLoader(true);
    authorizedFetch(
      `${BOLT_URL}/charger/${data?.charger?.chargerId}/markOtaUpdate`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          expectedFirmware: version || data?.specification?.firmware,
        },
      }
    )
      .then((res) => {
        setLoader(false);
        console.log(res);
        if (res.status === 200) {
          snackbar.success(
            data?.chargerType?.mode === "BLE"
              ? "Charger marked for OTA update"
              : "OTA update established successfully. The update will take less than 5 minutes."
          );
        } else {
          snackbar.error(`Error${res.message ? ": " + res.message : ""}`);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
        snackbar.error("An error occurred");
      });
  }

  const status = titleCase(data?.charger?.chargerStatus || "Unavailable");

  const disabled =
    chargerStatusData?.data?.chargerStatus !== "AVAILABLE" ||
    status !== "Available";

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
        {data?.chargerType?.mode === "BLE"
          ? "Mark for OTA Update"
          : "Push OTA Update"}
        <Divider flexItem orientation="vertical" sx={{ mx: 1.5 }} />
        <Typography color="text.secondary">
          {data?.charger?.chargerId}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            py: 2,
            display: "grid",
            columnGap: 2,
            rowGap: 1.75,
            gridTemplateColumns: "auto 1fr",
            alignItems: "center",
          }}
        >
          <Typography color="text.secondary">Connectivity Type</Typography>
          <Typography>{data?.chargerType?.mode}</Typography>
          {["WIFI", "SIM"].includes(data?.chargerType?.mode) && (
            <>
              <Typography color="text.secondary">
                Network Availability
              </Typography>
              <Typography sx={{ display: "flex", alignItems: "start" }}>
                {chargerStatusLoading ? (
                  "Loading..."
                ) : chargerStatusData?.data?.chargerStatus === "AVAILABLE" ? (
                  <>
                    <Wifi color="action" fontSize="small" sx={{ mr: 0.5 }} />
                    Online
                  </>
                ) : (
                  <>
                    <WifiOff color="action" fontSize="small" sx={{ mr: 0.5 }} />
                    Offline
                  </>
                )}
              </Typography>
            </>
          )}
          <Typography color="text.secondary">Charger State</Typography>
          <Avatar
            variant="status"
            className={status === "Unavailable" ? "grey" : ""}
          >
            {status}
          </Avatar>
          <Typography color="text.secondary">
            Current Firmware Version
          </Typography>
          <Typography>{data?.specification?.firmware || "-"}</Typography>
          <Typography color="text.secondary">
            Latest Firmware Version
          </Typography>
          <TextField
            sx={{ my: -1, width: 100 }}
            size="small"
            placeholder={data?.specification?.firmware || "0.0.0"}
            value={version}
            onChange={(e) => setVersion(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit} disabled={disabled}>
          {data?.chargerType?.mode === "BLE"
            ? "Mark for Update"
            : "Push Update"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OTAUpdateDialog;
