import {
  AddRounded,
  DeleteOutline,
  EditOutlined,
  InfoOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import Search from "components/Search";
import TableComponent, { Column } from "components/Table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  authorizedFetch,
  drawer,
  getPermissions,
  GlobalState,
  titleCase,
} from "utils";
import { BOLT_URL } from "utils/constants";
import CUGDialog from "./CUGDialog";
import DrawerContent from "./DrawerContent";
import UpdateDialog from "./UpdateDialog";

const List = ({ tab, setTab }: any) => {
  const {
    company,
    masterView,
    drawer: drawerState,
  } = useSelector((state: GlobalState) => state.global);
  const isCUGMonitorAccount = company?.type === "CUG_VENDOR";
  const { canWrite } = getPermissions("charger:users");
  const [cugDialog, setCugDialog] = useState({
    open: false,
    data: null,
    isEditMode: false,
  });
  const [updateDialog, setUpdateDialog] = useState({
    open: false,
    data: null,
    type: "",
  });

  const [type, setType] = useState<"APP_USER" | "COMPANY_USER">("APP_USER");

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState("name");

  const first = pageSize;
  const skip = pageSize * (page - 1);

  const usersUrl = `${BOLT_URL}/company/users?type=${type}&first=${first}&skip=${skip}${search ? `&${searchBy}=` + search : ""
    }`;
  const { isLoading: usersLoading, data: usersData } = useQuery(
    [
      "getAppUsers",
      first,
      skip,
      type,
      search ? searchBy : null,
      search,
      masterView,
    ],
    () =>
      authorizedFetch(usersUrl, {
        headers: {
          master: masterView,
        },
      }),
    { enabled: !isCUGMonitorAccount }
  );

  const cugUsersUrl = `${BOLT_URL}/company/v2/usergroup/users?first=${first}&skip=${skip}${search ? `&${searchBy}=${search}` : ""}`;
  const { isLoading: cugUsersLoading, data: cugUsersData } = useQuery(
    [
      "getMonitorUsers", 
      first, 
      skip, 
      search ? searchBy : null,
      search,
     isCUGMonitorAccount,
    ],
    () =>
      authorizedFetch(cugUsersUrl, {
        headers: {
          monitoringAccount: isCUGMonitorAccount,
        },
      }),
    {
      enabled: isCUGMonitorAccount,
    }
  );

  const hostsUrl = `${BOLT_URL}/company/hosts?first=${first}&skip=${skip}${search ? `&${searchBy}=${search}` : ""
    }`;
  const { isLoading: hostsLoading, data: hostsData } = useQuery(
    ["getHosts", first, skip, search ? searchBy : null, search, masterView],
    () =>
      authorizedFetch(hostsUrl, {
        headers: {
          master: masterView,
        },
      }),
    { enabled: !isCUGMonitorAccount }
  );

  const cugsUrl = `${BOLT_URL}/company/v2/usergroup?first=${first}&skip=${skip}${search ? `&search=${search}` : ""}`;
  const {
    isLoading: cugsLoading,
    data: cugsData,
    refetch,
  } = useQuery(
    [
      "getClosedUserGroups",
      first,
      skip,
      search,
      masterView,
      isCUGMonitorAccount,
    ],
    () =>
      authorizedFetch(cugsUrl, {
        headers: isCUGMonitorAccount
          ? {
            monitoringAccount: isCUGMonitorAccount,
          }
          : {
            master: masterView,
          },
      })
  );

  useEffect(() => {
    setPage(1);
  }, [type, tab, search]);

  useEffect(() => {
    drawer.close();
  }, [tab]);

  return (
    <Paper
      sx={{
        width: 1,
        boxShadow: (theme) => theme.customShadows.small,
        borderRadius: 2,
      }}
    >
      {!isCUGMonitorAccount && (
        <>
          <CUGDialog
            open={cugDialog.open}
            handleClose={() =>
              setCugDialog((prev) => ({
                ...prev,
                open: !cugDialog.open,
              }))
            }
            isEditMode={cugDialog.isEditMode}
            data={cugDialog.data}
          />
          <UpdateDialog
            open={updateDialog.open}
            handleClose={() => {
              setUpdateDialog({ ...updateDialog, open: false, type: "" });
              refetch();
            }}
            group={updateDialog.data}
            type={updateDialog.type}
          />
        </>
      )}
      <Box
        sx={{
          p: 3,
          pb: { xs: 1.5, md: 2.75 },
          display: "flex",
          flexDirection: {
            xs: "column",
            lg: drawerState.open ? "column" : "row",
          },
          justifyContent: {
            xs: "normal",
            lg: drawerState.open ? "normal" : "space-between",
          },
          alignItems: {
            xs: "normal",
            lg: drawerState.open ? "normal" : "center",
          },
        }}
      >
        <Box width="fit-content">
          <Tabs
            className="dense"
            value={tab}
            onChange={(e, value) => setTab(value)}
            sx={{
              mb: { xs: 1.5, lg: drawerState.open ? 1.5 : 0 },
              "& .MuiTab-root": {
                fontSize: 16,
                fontWeight: 600,
                letterSpacing: "normal",
              },
            }}
          >
            <Tab label="Users" value="users" />
            {!isCUGMonitorAccount && <Tab label="Hosts" value="hosts" />}
            {!["testTVS"].includes(company.name) && (
              <Tab
                label={
                  <Tooltip title="Closed User Groups" placement="top">
                    <span>CUGs</span>
                  </Tooltip>
                }
                value="CUGs"
              />
            )}
          </Tabs>
        </Box>
        {tab === "users" || tab === "hosts" ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: {
                xs: "column-reverse",
                lg: drawerState.open ? "column-reverse" : "row",
              },
            }}
          >
            <Box display="flex" alignItems="center">
              <Tooltip title="Search by..." placement="top">
                <Select
                  sx={{
                    mr: 1,
                    "& .MuiInputBase-input": {
                      fontSize: 14,
                    },
                  }}
                  size="small"
                  className="rounded"
                  value={searchBy}
                  onChange={(e) => setSearchBy(e.target.value)}
                  startAdornment={
                    <SearchOutlined
                      fontSize="small"
                      color="action"
                      sx={{ ml: -0.5, mr: 0.5 }}
                    />
                  }
                >
                  <MenuItem value="name">Name</MenuItem>
                  <MenuItem value="email">Email</MenuItem>
                  <MenuItem value="phone">Phone</MenuItem>
                </Select>
              </Tooltip>
              <Search
                value={search}
                handleSearch={(value) => {
                  setSearch(value);
                }}
                persist
                enableClear
                hideIcon
              />
            </Box>
            {tab === "users" && !isCUGMonitorAccount && (
              <ToggleButtonGroup
                value={type}
                exclusive
                onChange={(e, value) => {
                  if (value !== null) setType(value);
                }}
                color="primary"
                size="small"
                sx={{
                  ml: { xs: 0, lg: drawerState.open ? 0 : 1 },
                  mb: { xs: 1.5, lg: drawerState.open ? 1.5 : 0 },
                  "& .MuiToggleButton-root": {
                    px: 1.5,
                    textTransform: "none",
                  },
                }}
              >
                <ToggleButton value="APP_USER">App Users</ToggleButton>
                <ToggleButton value="COMPANY_USER">Employees</ToggleButton>
              </ToggleButtonGroup>
            )}
          </Box>
        ) : (
          tab === "CUGs" && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Search
                value={search}
                handleSearch={(value) => {
                  setSearch(value);
                }}
                persist
                enableClear
              />
              {!isCUGMonitorAccount && canWrite && (
                <Button
                  size="small"
                  sx={{
                    height: 40,
                    borderRadius: 10,
                    borderWidth: "1.5px !important",
                    borderColor: (theme) => theme.palette.primary.main,
                    textTransform: "none",
                    fontWeight: 500,
                    px: 2,
                    ml: 1,
                  }}
                  variant="outlined"
                  startIcon={<AddRounded />}
                  onClick={() =>
                    setCugDialog((prev) => ({
                      ...prev,
                      open: !cugDialog.open,
                      isEditMode: false,
                    }))
                  }
                >
                  Create New Group
                </Button>
              )}
            </Box>
          )
        )}
      </Box>
      {tab === "users" || tab === "hosts" ? (
        <TableComponent
          loading={
            tab === "hosts"
              ? hostsLoading
              : isCUGMonitorAccount
                ? cugUsersLoading
                : usersLoading
          }
          rowCount={
            (tab === "hosts"
              ? hostsData?.data?.count
              : isCUGMonitorAccount
                ? cugUsersData?.data?.totalCount
                : usersData?.data?.count) || 0
          }
          rows={
            (tab === "hosts"
              ? hostsData?.data?.users
              : isCUGMonitorAccount
                ? cugUsersData?.data?.userDetails
                : usersData?.data?.users) || []
          }
          serverSidePagination
          activePage={page}
          activePageSize={pageSize}
          onPageChange={(value) => setPage(value)}
          onPageSizeChange={(value) => setPageSize(value)}
          columns={[
            {
              key: "name",
              label: "Name",
              Render: (row) => {
                let { firstName, lastName } = isCUGMonitorAccount
                  ? row
                  : row.user;
                return firstName || lastName
                  ? `${firstName || ""} ${lastName || ""}`
                  : "-";
              },
            },
            {
              key: isCUGMonitorAccount ? "phone" : "user.phone",
              label: "Phone",
              format: (value) =>
                value ? `${value.slice(0, 3)} ${value.slice(3)}` : "-",
            },
            {
              key: isCUGMonitorAccount ? "email" : "user.email",
              label: "Email",
            },
            ...(!isCUGMonitorAccount
              ? ([
                {
                  key: "stats.totalBookings",
                  label: "Bookings",
                  format: (value) => (value ? value.toLocaleString() : 0),
                },
                {
                  key: "stats.lastBookingDate",
                  label: "Last Booking",
                  format: (value) =>
                    value !== null
                      ? moment(value).format("MMM DD, YYYY")
                      : "-",
                },
                {
                  key: "stats.totalEnergyConsumed",
                  label:
                    tab === "users" ? "Energy Consumed" : "Energy Dispensed",
                  format: (value) =>
                    value
                      ? value.toLocaleString({ maximumFractionDigits: 3 }) +
                      " kWh"
                      : "0 kWh",
                },
                {
                  key:
                    tab === "users"
                      ? "stats.totalAmount"
                      : "stats.totalSpend",
                  label: "Spend",
                  format: (value) =>
                    "₹" +
                    (value
                      ? value.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })
                      : 0),
                },
              ] as Column[])
              : []),
            ...(tab === "hosts"
              ? ([
                {
                  key: "stats.totalEarnings",
                  label: "Earnings",
                  format: (value: any) =>
                    "₹" +
                    (value
                      ? value.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })
                      : 0),
                },
              ] as Column[])
              : []),
            {
              key: "actions",
              label: "Actions",
              Render: (row: any) => (
                <IconButton
                  sx={{
                    color: (theme: any) => theme.customColors.grey,
                    mr: 0.5,
                  }}
                  size="small"
                  onClick={() =>
                    drawer.open(
                      <DrawerContent
                        key={
                          tab === "hosts"
                            ? row?.user?._id
                            : isCUGMonitorAccount
                              ? row?._id
                              : row?.user?._id
                        }
                        type={tab === "hosts" ? "host" : "user"}
                        row={row}
                      />
                    )
                  }
                >
                  <InfoOutlined fontSize="small" />
                </IconButton>
              ),
            },
          ]}
        />
      ) : (
        <TableComponent
          serverSidePagination
          rowCount={cugsData?.data?.totalCount || 0}
          activePage={page}
          activePageSize={pageSize}
          onPageChange={(value) => setPage(value)}
          onPageSizeChange={(value) => setPageSize(value)}
          loading={cugsLoading}
          rows={cugsData?.data?.groups || []}
          columns={[
            { key: "name", label: "Group Name" },
            {
              key: "status",
              label: "Status",
              Render: (row) => (
                <Avatar
                  variant="status"
                  className={
                    row?.status === "ACTIVE"
                      ? "green"
                      : row?.status === "INACTIVE"
                        ? "yellow"
                        : "red"
                  }
                >
                  {titleCase(row?.status || "-")}
                </Avatar>
              ),
            },
            { key: "totalUsers", label: "Assigned Users" },
            { key: "totalBookings", label: "Total Bookings" },
            {
              key: "currentCycleBalance",
              label: "Balance",
              Render: (row) => (
                <Box display="flex" alignItems="center">
                  {row?.currentCycleBalance
                    ? `₹${row.currentCycleBalance}`
                    : "-"}
                  {row?.currentCycleBalance !== 0 && (
                    <Tooltip title="Current cycle" placement="right">
                      <InfoOutlined
                        sx={{
                          ml: 1,
                          fontSize: 14,
                          cursor: "pointer",
                          color: (theme) => theme.customColors.grey,
                        }}
                      />
                    </Tooltip>
                  )}
                </Box>
              ),
            },
            {
              key: "currentCycleConsumption",
              label: "Actual Consumption",
              Render: (row) => (
                <Box display="flex" alignItems="center">
                  {row?.currentCycleConsumption === 0
                    ? "-"
                    : row?.currentCycleConsumption}
                  {row?.currentCycleConsumption !== 0 && (
                    <Tooltip title={<>Current Cycle</>} placement="right">
                      <InfoOutlined
                        sx={{ ml: 0.5, fontSize: 14, cursor: "pointer" }}
                      />
                    </Tooltip>
                  )}
                </Box>
              ),
            },
            {
              key: "createdAt",
              label: "Created On",
              Render: (row) => moment(row.createdAt).format("MMM DD, YYYY"),
            },
            {
              key: "updatedAt",
              label: "Updated On",
              Render: (row) => moment(row.updatedAt).format("MMM DD, YYYY"),
            },
            {
              key: "actions",
              label: "Actions",
              Render: (row: any) => row?.status !== "DELETED"
                ? (<Box display="flex">
                  <Tooltip title="Info">
                    <IconButton
                      size="small"
                      sx={{ color: (theme) => theme.customColors.grey }}
                      onClick={() =>
                        drawer.open(<DrawerContent type="group" group={row} />)
                      }
                    >
                      <InfoOutlined fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  {!isCUGMonitorAccount && canWrite && (
                    <>
                      <Tooltip title="Edit">
                        <IconButton
                          size="small"
                          sx={{
                            ml: 0.5,
                            color: (theme) => theme.customColors.grey,
                          }}
                          onClick={() =>
                            setCugDialog((prev) => ({
                              ...prev,
                              open: true,
                              isEditMode: true,
                              data: row,
                            }))
                          }
                        >
                          <EditOutlined fontSize="small" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Delete">
                        <IconButton
                          size="small"
                          sx={{
                            ml: 0.5,
                            color: (theme) => theme.customColors.grey,
                          }}
                          onClick={() =>
                            setUpdateDialog({
                              open: true,
                              data: row,
                              type: "DELETE",
                            })
                          }
                        >
                          <DeleteOutline fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </Box>)
                : (<></>),
            },
          ]}
        />
      )}
    </Paper>
  );
};

export default List;
