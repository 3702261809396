import { Box } from "@mui/material";
import { drawerWidth } from "pages/DashboardLayout/constants";
import { useSelector } from "react-redux";
import { GlobalState } from "utils";

const infoDrawerWidth = 550;

const InfoDrawer = () => {
  let drawerState = useSelector((state: GlobalState) => state.global.drawer);

  return (
    <Box
      sx={{
        zIndex: 1,
        width: {
          xs: drawerState.open ? "100vw" : 0,
          md: drawerState.open ? `calc(100vw - ${drawerWidth}px)` : 0,
          lg: drawerState.open ? infoDrawerWidth : 0,
        },
        boxShadow: "0 0 8px #15223214",
        overflowX: "hidden",
        transition: "200ms",
      }}
    >
      <Box
        sx={{
          witdth: 1,
          height: 1,
          backgroundColor: (theme) => theme.palette.background.paper,
        }}
      >
        {drawerState.content}
      </Box>
    </Box>
  );
};

export default InfoDrawer;
