import moment from "moment";
import { useEffect, useState } from "react";
import { appBar } from "utils";
import AppBar from "./AppBar";
import Cards from "./Cards";

const Overview = () => {
  const [filters, setFilters] = useState<any>({
    time: [moment().subtract({ months: 1 }), moment()],
  });

  const dateFrom = filters.time[0].format("YYYY-MM-DD");
  const dateTo = filters.time[1].format("YYYY-MM-DD");

  useEffect(() => {
    appBar.open(
      <AppBar
        {...{
          filters,
          setFilters,
        }}
      />
    );
    return () => {
      appBar.close();
    };
  }, [filters]);

  return (
    <Cards
      {...{
        dateFrom,
        dateTo,
      }}
    />
  );
};

export default Overview;
