import { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Avatar,
  Box,
} from "@mui/material";
import Table from "../../../components/Table";
import moment from "moment";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { VENDORS_URL_TEMP } from "utils/constants";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "index";
import Search from "components/Search";

const AssignChargers = ({
  open,
  handleClose,
  vendor,
  existingChargers,
}: any) => {
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const chargersUrl = `${VENDORS_URL_TEMP}/company/chargers?${
    search
      ? `first=${pageSize}&skip=${pageSize * (page - 1)}&search=${search}`
      : `first=${pageSize}&skip=${pageSize * (page - 1)}`
  }`;

  const { isLoading, data } = useQuery(
    ["getChargers", search, page, pageSize],
    () => authorizedFetch(chargersUrl)
  );

  const url = `${VENDORS_URL_TEMP}/company/vendor/${vendor?.id}/assignChargers`;

  const mutation = useMutation(
    ["assignChargers", vendor?.id],
    () =>
      authorizedFetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          stage: "prod",
        },
        body: {
          chargerIds: selectedRows,
        },
      }),
    {
      onSuccess: () => {
        setLoader(false);
        snackbar.success(`Chargers assigned`);
        [["getVendorChargers", vendor?.id], "getVendors"].forEach((key) => {
          queryClient.resetQueries(key, { exact: true });
        });
        handleClose();
      },
      onError: () => {
        setLoader(false);
        snackbar.error(`Error assigning chargers`);
      },
    }
  );

  console.log("vendor id is: ", vendor?.id);
  useEffect(() => {
    if (open && data?.data?.chargers?.constructor === Array) {
      let unassigned = data.data.chargers.filter(
        (el1: any) =>
          !(existingChargers || []).some(
            (el2: any) => el2.charger.chargerId === el1.charger.chargerId
          )
      );
      setRows(unassigned);
    }
  }, [open, data, existingChargers]);

  function handleSave() {
    setLoader(true);
    mutation.mutate();
  }

  useEffect(() => {
    if (!open) {
      setPage(1);
      setPageSize(10);
      setSearch("");
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          display: { xs: "grid", sm: "flex" },
          justifyContent: { sm: "space-between" },
          mb: 1,
        }}
      >
        <Box mb={{ xs: 1, sm: 0 }}>Assign Chargers</Box>
        <Box justifySelf={{ xs: "end", sm: "unset" }}>
          <Search
            handleSearch={(value) => setSearch(value)}
            persist
            enableClear
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Table
          px={0}
          rowCount={data?.data?.chargersCount}
          serverSidePagination={true}
          activePage={page}
          activePageSize={pageSize}
          onPageChange={(value) => setPage(value)}
          onPageSizeChange={(value) => setPageSize(value)}
          height={376}
          setSelectedRows={setSelectedRows}
          smallPagination
          selectable
          selectOnClick
          loading={isLoading}
          rows={isLoading ? [] : rows}
          columns={[
            {
              key: "uid",
              label: "Charger UID",
              Render: (row) => (
                <Box sx={{ py: 2 }}>{row.charger.chargerId}</Box>
              ),
            },
            {
              key: "activationDate",
              label: "Activation Date",
              Render: (row) => (
                <Box>
                  {moment(row.charger.createdAt).format("ddd, MMM DD, YYYY")}
                </Box>
              ),
            },
            {
              key: "health",
              label: "Health",
              // Render: (row) => <Avatar variant="status">Available</Avatar>,
              Render: (row) => (
                <Avatar variant="status">{row.charger.health}</Avatar>
              ),
            },
          ]}
          toolbar={() => <></>}
        />
      </DialogContent>
      <DialogActions className="dense">
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        {selectedRows.length === 0 ? (
          <Button variant="contained" disabled>
            Assign
          </Button>
        ) : (
          <Button variant="contained" onClick={handleSave}>
            Assign
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AssignChargers;
