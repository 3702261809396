import { HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import RedAsterisk from "components/RedAsterisk";
import { queryClient } from "index";
import { useEffect, useState } from "react";
import { authorizedFetch, drawer, setLoader, snackbar } from "utils";
import { AUTH_URL, RETAIL_URL } from "utils/constants";

const AddDeviceDialog = ({ open, handleClose, dialogData }: any) => {
  const editDialog = Object.keys(dialogData)?.length > 0;
  const [companies, setCompanies] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);
  const [primaryPrerequisites, setPrimaryPrerequisites] = useState([]);
  const [secondaryPrerequisites, setSecondaryPrerequisites] = useState([]);
  const [input, setInput] = useState({
    company: "",
    vehicleModel: "",
    primaryPrerequisite: "",
    secondaryPrerequisite: "",
    minor: 0,
    major: 0,
    patch: 0,
    serialNumber: "",
    macId: "",
    model: "",
    warranty: 420,
    assign: false,
  });

  useEffect(() => {
    if (editDialog) {
      const parseData = {
        company: dialogData?.oem?._id,
        vehicleModel: dialogData?.vehicleModel?._id,
        primaryPrerequisite: dialogData?.currentOta?.primary?._id,
        secondaryPrerequisite: dialogData?.currentOta?.secondary?._id,
        minor: dialogData?.hardware?.split(".")[1],
        major: dialogData?.hardware?.split(".")[0],
        patch: dialogData?.hardware?.split(".")[2],
        serialNumber: dialogData?.serialNumber,
        macId: dialogData?.specs?.macId,
        model: dialogData?.parentModel?.key,
        warranty: 420,
        assign: false,
      };
      setInput(parseData);
    }
  }, [dialogData, editDialog]);

  useEffect(() => {
    if (!open) {
      setInput({
        company: "",
        vehicleModel: "",
        primaryPrerequisite: "",
        secondaryPrerequisite: "",
        minor: -1,
        major: 0,
        patch: -1,
        serialNumber: "",
        macId: "",
        model: "",
        warranty: 420,
        assign: false,
      });
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      authorizedFetch(`${AUTH_URL}/company/list`)
        .then((res) => setCompanies(res?.data))
        .catch((err) => console.error(err));
    }
  }, [open]);

  useEffect(() => {
    if (input.company) {
      authorizedFetch(
        `${RETAIL_URL}/inventory/ota/vehicle-models?companyId=${input.company}`
      )
        .then((res) => setVehicleModels(res?.data))
        .catch((err) => console.error(err));
    }
  }, [input.company]);

  const getPrimaryPreRequisite = () => {
    authorizedFetch(
      `${RETAIL_URL}/inventory/ota/prerequisites?companyId=${input.company}&vehicleModel=${input.vehicleModel}&type=PRIMARY`
    )
      .then((res) => setPrimaryPrerequisites(res?.data))
      .catch((err) => console.error(err));
  };

  const getSecondaryPreRequisite = () => {
    authorizedFetch(
      `${RETAIL_URL}/inventory/ota/prerequisites?companyId=${input.company}&vehicleModel=${input.vehicleModel}&type=SECONDARY`
    )
      .then((res) => setSecondaryPrerequisites(res?.data))
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (input.company && input.vehicleModel) {
      getPrimaryPreRequisite();
      getSecondaryPreRequisite();
    }
    // eslint-disable-next-line
  }, [input.company, input.vehicleModel]);

  let requestBody: any;
  requestBody = {
    deviceType: "CONTROLLER",
    serialNumber: input.serialNumber,
    macId: input.macId,
    model: input.model,
    company: input.company,
    vehicleModel: input.vehicleModel,
    primary: input.primaryPrerequisite,
    ...(input?.model !== "SLED" && {
      secondary: input.secondaryPrerequisite,
    }),
    hardware: {
      major: input.major,
      minor: input.minor,
      patch: input.patch,
    },
    warranty: input.warranty,
    assign: input.assign,
  };

  if (editDialog) {
    requestBody = {
      companyId: input.company,
      vehicleModel: input.vehicleModel,
      speedometerType: input.model,
      serialNumber: input.serialNumber,
      MACID: input.macId,
      hardwareVersion: `${input.major}.${input.minor}.${input.patch}`,
      primaryFirmwareVersion: input.primaryPrerequisite,
      secondaryFirmwareVersion: input.secondaryPrerequisite
    };
  }

  function handleSave() {
    setLoader(true);
    let createUrl = `${RETAIL_URL}/assembly/component/create`;
    let updateUrl = `${RETAIL_URL}/assembly/component/${input.macId}/update`;
    authorizedFetch(editDialog ? updateUrl : createUrl, {
      method: editDialog ? "PUT" : "POST",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON?.stringify(requestBody)
      body: requestBody
    })
      .then((res) => {
        console.log("Response:", res); // Log the response for debugging
        setLoader(false);
        if (editDialog) {
          if (res.message === "Component updated") {
            snackbar.success("Component edited successfully");
            queryClient.resetQueries("getSCMData");
            drawer.close();
            handleClose();
          } else {
            console.error(res);
            snackbar.error("Error in editing device");
          }
        } else {
          if (res.meta.success === false) {
            snackbar.error("Error in adding item");
          } else {
            input.assign === false ? snackbar.success("Success, Item added successfully") : snackbar.success("Success, Item assigned successfully");
            queryClient.resetQueries("getSCMData");
            handleClose();
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error); // Log any errors for debugging
        setLoader(false);
        console.error(error);
        snackbar.error("Error in adding device(s)");
      });
  }

  let companyObject: any = {};

  if (companies.length > 0) {
    companyObject = companies?.filter(
      (company: any) => company?._id === input?.company
    )?.[0];
  }

  const companyName = companyObject?.name;

  const isAnyFieldEmpty = (input: any) => {
    return Object.keys(input).some((key: keyof typeof input) => {
      if (key === "secondaryPrerequisite" && input.model === "SLED") {
        return input[key] === "N/A";
      }
      return input[key] === "";
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {editDialog ? "Edit" : "Add"} Device
        </Box>
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mx: "auto", maxWidth: 550 }}>
          <Box
            sx={{
              my: 3,
              mx: 1,
              display: "grid",
              gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
              gap: 3,
            }}
          >
            <Box>
              <Typography className="label">
                Company
                <RedAsterisk />
              </Typography>
              <Select
                fullWidth
                value={input.company}
                onChange={(e: any) => {
                  setInput({ ...input, company: e.target.value });
                }}
                displayEmpty
                sx={{ maxHeight: 40, borderRadius: 10 }}
              >
                <MenuItem disabled value={""}>
                  {<em>Choose a company</em>}
                </MenuItem>
                {companies.length > 0 &&
                  companies?.map((model: any) => (
                    <MenuItem key={model._id} value={model._id}>
                      {model.name}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
            <Box>
              <Typography className="label">
                Model
                <RedAsterisk />
              </Typography>
              <Select
                fullWidth
                disabled={input.company?.length === 0}
                value={input.vehicleModel}
                onChange={(e: any) => {
                  const modelObject: any = vehicleModels.filter(
                    (model: any) => model._id === e.target.value
                  )?.[0];

                  const components = modelObject?.components;

                  const filteredCluster = components.filter(
                    (el: any) => el.category === "CONTROLLER"
                  )?.[0];

                  const clusterKey = filteredCluster?.modelId?.[0]?.key;

                  setInput({
                    ...input,
                    model: clusterKey,
                    vehicleModel: e.target.value,
                  });
                }}
                displayEmpty
                sx={{ maxHeight: 40, borderRadius: 10 }}
              >
                <MenuItem disabled value={""}>
                  {<em>Select a model</em>}
                </MenuItem>
                {vehicleModels?.map((model: any) => (
                  <MenuItem key={model._id} value={model._id}>
                    {model.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box>
              <Typography className="label">
                Serial Number
                <RedAsterisk />
              </Typography>
              {console.log(input.serialNumber, "input?")}
              <TextField
                fullWidth
                InputProps={{ sx: { borderRadius: 10 } }}
                disabled={
                  input.company?.length === 0 ||
                  input.vehicleModel?.length === 0
                }
                size="small"
                placeholder="Enter the serial number"
                value={input.serialNumber || ""}
                helperText="Min 8 & max 30 characters"
                onChange={(e: any) => {
                  const inputValue = e.target.value;
                  setInput({
                    ...input,
                    serialNumber: inputValue.substring(0, 30),
                  });
                }}
              />
            </Box>
            <Box>
              <Typography className="label">
                MAC ID
                <RedAsterisk />
              </Typography>
              <TextField
                fullWidth
                InputProps={{ sx: { borderRadius: 10 } }}
                disabled={
                  input.company?.length === 0 ||
                  input.serialNumber?.length === 0
                }
                size="small"
                placeholder="MAC ID Number"
                value={input.macId}
                helperText="Min 12 & max 18 characters"
                onChange={(e: any) => {
                  if (/^([A-Fa-f0-9]){0,18}$/.test(e.target.value)) {
                    const inputValue = e.target.value;
                    setInput({
                      ...input,
                      macId: inputValue
                        .substring(0, 18)
                        .toUpperCase(),
                    });
                  }
                }}
              />
            </Box>
            <Box>
              <Typography className="label">
                Cluster Type
                <RedAsterisk />
              </Typography>
              <TextField
                fullWidth
                InputProps={{ sx: { borderRadius: 10 } }}
                size="small"
                placeholder="Auto fill"
                value={input.model}
                disabled
              />
            </Box>
            <Box>
              <Typography className="label">
                Hardware Version
                <RedAsterisk />
              </Typography>
              <Box sx={{ display: "flex" }}>
                <TextField
                  disabled={
                    input.company?.length === 0 || input.macId?.length === 0
                  }
                  InputProps={{ sx: { borderRadius: 10, margin: "3px 4px" } }}
                  placeholder="0"
                  value={input.major > 0 ? input.major : ""}
                  size="small"
                  onChange={(e: any) => {
                    if (
                      /^[1-9]{0,1}$/.test(e.target.value) &&
                      e.target.value.length
                    ) {
                      setInput({ ...input, major: parseInt(e.target.value) });
                    } else {
                      setInput({ ...input, major: 0 });
                    }
                  }}
                />
                <TextField
                  disabled={
                    input.company?.length === 0 || input.macId?.length === 0
                  }
                  InputProps={{ sx: { borderRadius: 10, margin: "3px 4px" } }}
                  placeholder="0"
                  value={input.minor > -1 ? input.minor : ""}
                  size="small"
                  onChange={(e: any) => {
                    if (
                      /^[0-9]{0,1}$/.test(e.target.value) &&
                      e.target.value.length
                    ) {
                      console.log(e.target.value, "minor");
                      setInput({ ...input, minor: parseInt(e.target.value) });
                    } else {
                      setInput({ ...input, minor: -1 });
                    }
                  }}
                />
                <TextField
                  disabled={
                    input.company?.length === 0 || input.macId?.length === 0
                  }
                  InputProps={{ sx: { borderRadius: 10, margin: "3px 4px" } }}
                  placeholder="0"
                  value={input.patch > -1 ? input.patch : ""}
                  size="small"
                  onChange={(e: any) => {
                    console.log(e.target.value, "val");
                    if (
                      /^[0-9]{0,1}$/.test(e.target.value) &&
                      e.target.value?.length
                    ) {
                      setInput({ ...input, patch: parseInt(e.target.value) });
                    } else {
                      console.log("setting input");
                      setInput({ ...input, patch: -1 });
                    }
                  }}
                />
              </Box>
            </Box>
            <Box>
              <Typography className="label">
                Primary F/W Version
                <RedAsterisk />
              </Typography>
              <Select
                fullWidth
                value={input.primaryPrerequisite}
                disabled={
                  input.company?.length === 0 ||
                  input.vehicleModel?.length === 0
                }
                onChange={(e: any) =>
                  setInput({
                    ...input,
                    primaryPrerequisite: e.target.value,
                  })
                }
                displayEmpty
                sx={{ maxHeight: 40, borderRadius: 10 }}
              >
                <MenuItem disabled value={""}>
                  {<em>Select Primary F/W Version</em>}
                </MenuItem>
                {primaryPrerequisites?.map((model: any) => (
                  <MenuItem key={model._id} value={model._id}>
                    {model.version}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            {input?.model !== "SLED" && (
              <Box>
                <Typography className="label">
                  Secondary F/W Version
                  <RedAsterisk />
                </Typography>
                <Select
                  fullWidth
                  value={input.secondaryPrerequisite}
                  disabled={
                    input.company?.length === 0 ||
                    input.vehicleModel?.length === 0
                  }
                  onChange={(e: any) =>
                    setInput({
                      ...input,
                      secondaryPrerequisite: e.target.value,
                    })
                  }
                  displayEmpty
                  sx={{ maxHeight: 40, borderRadius: 10 }}
                >
                  <MenuItem disabled value={""}>
                    {<em>Select Secondary F/W Version</em>}
                  </MenuItem>
                  {secondaryPrerequisites?.map((model: any) => (
                    <MenuItem key={model._id} value={model._id}>
                      {model.version}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}

            <Box>
              <Typography className="label">
                Warranty Details
                <RedAsterisk />
              </Typography>
              <Select
                fullWidth
                value={input.warranty}
                onChange={(e: any) => {
                  setInput({ ...input, warranty: e.target.value });
                }}
                displayEmpty
                sx={{ maxHeight: 40, borderRadius: 10 }}
              >
                <MenuItem value={input.warranty}>14 MONTHS/420 DAYS</MenuItem>
              </Select>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
                margin: "30px 26px",
              }}
            >
              <Checkbox
                disabled={input.company?.length === 0}
                checked={input.assign}
                onChange={(e: any) =>
                  setInput({
                    ...input,
                    assign: e.target.checked,
                  })
                }
              />
              <Typography className="label" sx={{ margin: "12px 15px" }}>
                {companyName === undefined
                  ? "Assign to OEM"
                  : `Assign to ${companyName}`}
              </Typography>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleClose}
          sx={{ borderRadius: 10 }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ borderRadius: 10 }}
          onClick={() => handleSave()}
          disabled={
            isAnyFieldEmpty(input) ||
            (input.macId && input.macId.length <= 11 ? true : false) ||
            input.major < 1 ||
            input.patch < 0 ||
            input.minor < 0
          }
        >
          {input.assign === true ? "Save & Assign" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDeviceDialog;
