import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { queryClient } from "index";
import { useSelector } from "react-redux";
import { authorizedFetch, GlobalState, setLoader, snackbar } from "utils";
import { RETAIL_URL } from "utils/constants";

const DeleteDialog = ({
  open,
  handleClose,
  model,
  isForVehicle,
  vehicle,
  onDelete,
}: any) => {
  const { company } = useSelector((state: GlobalState) => state.global);
  const isBattRe = company?.id === "5cfa3a9da7b11b00073f95fa";
  const testerbolt1997 = company?.id === "62ff83623034029f2a85e266";

  function handleDelete() {
    setLoader(true);
    let url = isForVehicle
      ? `${RETAIL_URL}/assembly/vehicle/${
          isBattRe || testerbolt1997 ? vehicle.vin + "/hard" : vehicle._id
          // vehicle._id
        }`
      : `${RETAIL_URL}/assembly/model/${model._id}`;
    authorizedFetch(url, { method: "DELETE" })
      .then((res) => {
        setLoader(false);
        if (res.meta.success) {
          snackbar.success(`Deleted ${isForVehicle ? "vehicle" : "model"}`);
          queryClient.resetQueries(
            isForVehicle ? "getRetailVehicles" : "getModels",
          );
          onDelete();
          handleClose();
        } else {
          console.error(res);
          snackbar.error(
            `Error deleting ${isForVehicle ? "vehicle" : "model"}`,
          );
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
        snackbar.error(`Error deleting ${isForVehicle ? "vehicle" : "model"}`);
      });
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Delete {isForVehicle ? "Vehicle" : "Model"}?</DialogTitle>
      <DialogContent>
        <Typography sx={{ my: 1, mr: 5 }}>
          Are you sure you want to delete{" "}
          <b>{isForVehicle ? vehicle.vin : model.name}</b>?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={handleDelete}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
