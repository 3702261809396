import { gql, useMutation } from "@apollo/client";
import { EditOutlined, HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Step,
  StepButton,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { queryClient } from "index";
import { useEffect, useState } from "react";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneTextField from "components/PhoneTextField";
import { drawer, setLoader, snackbar } from "utils";
import validator from "validator";

const EditDialog = ({ open, handleClose, vendor, initialStep }: any) => {
  const [step, setStep] = useState<number>(0);
  const steps = ["Comapany Info", "User Info", "Finish"];
  const [input, setInput] = useState({
    name: "",
    phone: "",
    gstNumber: "",
    address: "",
    firstName: "",
    lastName: "",
    userPhone: "",
    email: "",
  });

  useEffect(() => {
    if (open) {
      setStep(initialStep || 0);
    }
  }, [open, initialStep]);

  useEffect(() => {
    if (vendor && open) {
      let userPhone = vendor.users[0]?.phone || "";
      setInput({
        name: vendor.name,
        phone: vendor.phone,
        gstNumber: vendor.gstNumber,
        address: vendor.address,
        firstName: vendor.users[0]?.firstName,
        lastName: vendor.users[0]?.lastName,
        userPhone: userPhone,
        email: vendor.users[0]?.email,
      });
    }
  }, [vendor, open]);

  const {
    name,
    phone,
    gstNumber,
    address,
    firstName,
    lastName,
    userPhone,
    email,
  } = input;

  function isComplete(step: number) {
    switch (step) {
      case 0:
        return ![name, phone, address, gstNumber].includes("");
      case 1:
        return ![firstName, lastName, userPhone, email].includes("");
      default:
        break;
    }
  }

  const UPDATE_STATUS = gql`
    mutation UpdateStatus(
      $where: CompanyWhereUniqueInput!
      $data: CreateDistributorInput!
    ) {
      company {
        updateSubCompany(where: $where, data: $data) {
          company {
            id
            name
          }
        }
      }
    }
  `;

  const [updateVendor] = useMutation(UPDATE_STATUS, {
    onCompleted: () => {
      setLoader(false);
      snackbar.success("Vendor updated");
      ["getVendors", ["getVendorChargers", vendor?.id]].forEach((key) => {
        queryClient.resetQueries(key, { exact: true });
      });
      drawer.close();
      handleClose();
    },
    onError: () => {
      setLoader(false);
      snackbar.error("Error updating vendor");
      handleClose();
    },
  });

  function onSave() {
    setLoader(true);
    updateVendor({
      variables: {
        where: { id: vendor?.id },
        data: {
          company: {
            name: name,
            address: address,
            phone: phone,
            gstNumber: gstNumber,
          },
          user: {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: userPhone,
          },
        },
      },
    });
  }

  function handleNext() {
    if (step === steps.length - 1) {
      onSave();
    } else setStep(step + 1);
  }
  function handleBack() {
    setStep(step - 1);
  }

  const validateEmail = (email: any) => {
    return validator.isEmail(email);
  };

  const disabled =
    [
      name,
      phone,
      address,
      gstNumber,
      firstName,
      lastName,
      userPhone,
      email,
    ].includes("") ||
    !validateEmail(email) ||
    !isValidPhoneNumber(userPhone) ||
    !isValidPhoneNumber(phone);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: 800,
          width: 1,
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        Edit Vendor
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent sx={{ pb: "16px !important" }}>
        <Stepper
          sx={{ my: { xs: 2, md: 4 }, mx: "auto", maxWidth: 534 }}
          activeStep={step}
          nonLinear
          alternativeLabel
        >
          {steps.map((label, i) => (
            <Step key={i}>
              <StepButton onClick={() => setStep(i)}>{label}</StepButton>
            </Step>
          ))}
        </Stepper>
        {step === 0 && (
          <Box
            sx={{
              maxWidth: { xs: 280, sm: 560 },
              mx: "auto",
              py: 2,
              display: "grid",
              gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
              gap: 3,
            }}
          >
            <Box gridColumn={{ sm: "span 2" }}>
              <Typography className="label">Company Name</Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="Company Name"
                value={name}
                onChange={(e: any) => {
                  setInput({ ...input, name: e.target.value });
                }}
              />
            </Box>
            <Box>
              <Typography className="label">Phone Number</Typography>
              <PhoneInput
                placeholder="Enter phone number"
                value={phone}
                onChange={(value) => setInput({ ...input, phone: value || "" })}
                defaultCountry="IN"
                international
                limitMaxLength
                inputComponent={PhoneTextField}
                error={!!phone && !isValidPhoneNumber(phone)}
              />
            </Box>
            <Box>
              <Typography className="label">GST Number</Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="GST Number"
                value={gstNumber}
                onChange={(e: any) => {
                  setInput({
                    ...input,
                    gstNumber:
                      e.target.value?.length > 0
                        ? e.target.value.replace(/[^a-zA-Z0-9 ]/g, "")
                        : e.target.value,
                  });
                }}
              />
            </Box>
            <Box gridColumn={{ sm: "span 2" }}>
              <Typography className="label">Address</Typography>
              <TextField
                multiline
                size="small"
                minRows={3}
                fullWidth
                value={address}
                placeholder="Address"
                // onChange={(e: any) => {
                //   setInput({ ...input, address: e.target.value });
                // }}
                onChange={(e: any) => {
                  setInput({
                    ...input,
                    address:
                      e.target.value?.length > 0
                        ? e.target.value.replace(/[^a-zA-Z0-9 ]/g, "")
                        : e.target.value,
                  });
                }}
              />
            </Box>
          </Box>
        )}
        {step === 1 && (
          <Box
            sx={{
              maxWidth: { xs: 280, sm: 560 },
              mx: "auto",
              py: 2,
              display: "grid",
              gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
              gap: 3,
            }}
          >
            <Box>
              <Typography className="label">First Name</Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="First Name"
                value={firstName}
                onChange={(e: any) => {
                  setInput({ ...input, firstName: e.target.value });
                }}
              />
            </Box>
            <Box>
              <Typography className="label">Last Name</Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="Last Name"
                value={lastName}
                onChange={(e: any) => {
                  setInput({ ...input, lastName: e.target.value });
                }}
              />
            </Box>
            <Box>
              <Typography className="label">Phone Number</Typography>
              <PhoneInput
                placeholder="Enter phone number"
                value={userPhone}
                onChange={(value) =>
                  setInput({ ...input, userPhone: value || "" })
                }
                defaultCountry="IN"
                international
                limitMaxLength
                inputComponent={PhoneTextField}
                error={!!userPhone && !isValidPhoneNumber(userPhone)}
              />
            </Box>

            <Box>
              <Typography className="label">Email</Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="Email"
                value={input.email}
                error={!validateEmail(input.email)}
                helperText={
                  input.email && !validateEmail(input.email)
                    ? "Invalid Email"
                    : input.email
                    ? ""
                    : "Enter Email"
                }
                onChange={(e: any) => {
                  setInput({ ...input, email: e.target.value });
                }}
              />
            </Box>
          </Box>
        )}

        {step === 2 && (
          <Box
            sx={{
              maxWidth: 560,
              mx: "auto",
              "& .table": {
                borderCollapse: "collapse",
                width: 1,
                fontSize: 14,
                lineHeight: "16px",
                "& td": {
                  py: 1.25,
                  px: 2,
                },
                "& .bold": {
                  fontWeight: 500,
                },
                "& .header": {
                  px: 2,
                  py: 1,
                  position: "relative",
                  "& td": {
                    position: "absolute",
                    verticalAlign: "middle",
                    bgcolor: (theme: any) => theme.customColors.header,
                    width: 1,
                    borderRadius: "4px",
                    fontSize: 16,
                    fontWeight: 600,
                    "& span": {
                      display: "inline-block",
                      transform: "translateY(1px)",
                    },
                  },
                },
                "& .first > td": {
                  pt: 9,
                },
                "& .last > td": {
                  pb: 3,
                },
              },
            }}
          >
            <table className="table">
              <tbody>
                {[
                  { header: "Company Info", onEdit: () => setStep(0) },
                  { label: "Company Name", value: name },
                  {
                    label: "Phone Number",
                    value: formatPhoneNumberIntl(phone),
                  },
                  { label: "GST Number", value: gstNumber },
                  { label: "Address", value: address },

                  { header: "User Info", onEdit: () => setStep(1) },
                  { label: "First Name", value: firstName },
                  { label: "Last Name", value: lastName },
                  {
                    label: "Phone Number",
                    value: formatPhoneNumberIntl(userPhone),
                  },
                  { label: "Email", value: email },
                ].map(({ header, onEdit, label, value }, i, arr) => {
                  const isFirst = arr[i - 1]?.header;
                  const isLast = !arr[i + 1] || arr[i + 1].header;

                  return (
                    <tr
                      key={i}
                      className={
                        header
                          ? "header"
                          : `${isFirst ? "first" : ""} ${isLast ? "last" : ""}`
                      }
                    >
                      {header ? (
                        <td colSpan={2}>
                          <span>{header}</span>
                          <IconButton
                            sx={{ ml: 1.5 }}
                            children={<EditOutlined />}
                            color="primary"
                            size="small"
                            onClick={onEdit}
                          />
                        </td>
                      ) : (
                        <>
                          <td>{label}</td>
                          <td className="bold">{value}</td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {step !== 0 && (
          <Button variant="outlined" onClick={handleBack}>
            Back
          </Button>
        )}
        <Button
          onClick={handleNext}
          variant="contained"
          color={
            isComplete(step) || step === steps.length - 1
              ? "primary"
              : "inherit"
          }
          disableElevation
          disabled={step === steps.length - 1 && disabled}
        >
          {step === steps.length - 1
            ? "Save"
            : isComplete(step)
            ? "Next"
            : "Skip"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDialog;
