import {
  EditOutlined,
  ErrorOutline,
  HighlightOff,
  QrCode2Outlined,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Step,
  StepButton,
  Stepper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { authorizedFetch, setLoader, snackbar, drawer } from "utils";
import { BOLT_URL, TICKETS_URL } from "utils/constants";

import QRDialog from "./QRDialog";
import TicketStatus from "./TicketStatus";

interface Props {
  open: boolean;
  handleClose: () => void;
  refetch: any;
}

const AddPricingDialog: React.FC<Props> = ({ open, handleClose, refetch }) => {
  const [step, setStep] = useState(0);
  const steps = ["Ticket Info", "Finish"];

  const [note, setNote] = useState("");
  const [selectedCharger, setSelectedCharger] = useState<any>("");

  const [qrData, setQRData] = useState("");

  const [isChargerOpen, setIsChargerOpen] = useState<boolean>(false);

  const [openQRDialog, setOpenQRDialog] = useState(false);
  const [tab, setTab] = useState(0);

  const addTicketURL = `${TICKETS_URL}/ticket/chargers/create`;

  const chargersAllUrl = `${BOLT_URL}/company/stats/all?`;

  const { isLoading, data: allData } = useQuery(["getAllChargers"], () =>
    authorizedFetch(chargersAllUrl)
  );

  const mutation = useMutation(
    `addChargerTicket`,
    () =>
      authorizedFetch(addTicketURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          UID: tab === 0 ? qrData : selectedCharger,
          currentNote: note,
        },
      }),
    {
      onSuccess: () => {
        snackbar.success(`Ticket Added`);
        drawer.close();
        refetch();
        setLoader(false);
      },
      onError: () => {
        snackbar.error(`Error adding ticket`);
      },
    }
  );

  const handleSave = () => {
    setLoader(true);
    mutation.mutate();
    handleClose();
  };

  function handleNext() {
    if (step === steps.length - 1) {
      handleSave();
    } else setStep(step + 1);
  }
  function handleBack() {
    setStep(step - 1);
  }

  function isComplete(step: number) {
    switch (step) {
      case 0:
        return (
          ![
            ...[
              tab === 0
                ? qrData === "Charger UID not found"
                  ? ""
                  : qrData
                : selectedCharger,
            ],
            note,
          ].includes("") &&
          (tab === 0
            ? !isChargerOpen
            : allData?.data?.chargers?.some(
                (el: any) => el?.id === selectedCharger
              ) && !isChargerOpen)
        );

      default:
        break;
    }
  }

  const disabled =
    [
      ...[
        tab === 0
          ? qrData === "Charger UID not found"
            ? ""
            : qrData
          : selectedCharger,
      ],
      note,
    ].includes("") ||
    (tab === 0
      ? isChargerOpen
      : !allData?.data?.chargers?.some(
          (el: any) => el?.id === selectedCharger
        ) || isChargerOpen);

  useEffect(() => {
    if (!open) {
      setStep(0);
      setSelectedCharger("");
      setNote("");
      setQRData("");
    }
  }, [open]);

  useEffect(() => {
    if (tab === 0) {
      setSelectedCharger("");
    }
    if (tab === 1) {
      setQRData("");
    }
  }, [tab]);

  useEffect(() => {
    if (selectedCharger === null) {
      setSelectedCharger("");
    }
  }, [selectedCharger]);

  return (
    <>
      <QRDialog
        open={openQRDialog}
        handleClose={() => setOpenQRDialog(false)}
        setData={setQRData}
        data={qrData}
        chargers={allData?.data?.chargers || []}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            margin: 2,
          },
        }}
        PaperProps={{
          sx: {
            maxWidth: 500,
            width: 1,
            p: 0,
            "& .MuiInputBase-root": {
              fontSize: 14,
              borderRadius: 1,
              p: "3.5px 5px",
              m: 0,
            },
            "& .MuiPaper-root": {
              margin: 0,
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          Add Ticket
          <IconButton
            children={<HighlightOff />}
            color="inherit"
            onClick={handleClose}
            sx={{ transform: "translate(8px, -8px)" }}
          />
        </DialogTitle>
        <DialogContent sx={{ pb: "16px !important" }}>
          <Stepper
            sx={{ my: 4, mx: "auto", maxWidth: 534 }}
            activeStep={step}
            nonLinear
            alternativeLabel
          >
            {steps.map((label, i) => (
              <Step key={i}>
                <StepButton onClick={() => setStep(i)}>{label}</StepButton>
              </Step>
            ))}
          </Stepper>
          {step === 0 && (
            <>
              <Box
                sx={{
                  maxWidth: { xs: 280, sm: 560 },
                  mx: "auto",
                  py: 2,
                  display: "grid",
                  gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                  gap: 3,
                  "& .required": {
                    color: "red",
                  },
                }}
              >
                <Box gridColumn={{ sm: "span 2" }}>
                  <Typography className="label">
                    Add a Charger <span className="required">&#x2a;</span>
                  </Typography>
                  <Box mt={2}>
                    <Tabs
                      className="less-dense"
                      value={tab}
                      onChange={(e, tab) => setTab(tab)}
                    >
                      <Tab label="Scan" sx={{ width: "50%" }} />
                      <Tab
                        label="Search"
                        sx={{ width: "50%" }}
                        disabled={qrData !== ""}
                      />
                    </Tabs>
                  </Box>
                </Box>

                {tab === 0 && (
                  <Box gridColumn={{ sm: "span 2" }} mt={2}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {qrData === "" ? (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            sx={{
                              // ml: 2,
                              px: 0.5,
                              minWidth: 0,
                              width: 100,
                              height: 100,
                            }}
                            variant="outlined"
                            onClick={() => {
                              setQRData("");
                              setOpenQRDialog(true);
                            }}
                          >
                            <QrCode2Outlined sx={{ fontSize: 50 }} />
                          </Button>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: 13,
                              mt: 1,
                              opacity: 0.7,
                            }}
                          >
                            Click to Scan
                          </Typography>
                        </Box>
                      ) : (
                        <>
                          <Box
                            sx={{
                              width: "100%",
                              height: 50,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              border: "1px solid #00000020",
                              borderRadius: 1,
                            }}
                          >
                            <Typography fontWeight="bold" ml={4}>
                              {qrData}
                            </Typography>
                            <IconButton
                              children={<HighlightOff />}
                              color="inherit"
                              onClick={() => setQRData("")}
                              sx={{ mr: 4, opacity: 0.5 }}
                            />
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                )}

                {tab === 0 &&
                  allData?.data?.chargers?.some(
                    (el: any) => el?.id === qrData
                  ) && (
                    <TicketStatus
                      qrData={qrData}
                      tab={tab}
                      open={allData?.data?.chargers?.some(
                        (el: any) => el?.id === qrData
                      )}
                      isChargerOpen={isChargerOpen}
                      setIsChargerOpen={setIsChargerOpen}
                      setSelectedCharger={setSelectedCharger}
                      setQRData={setQRData}
                    />
                  )}

                {tab === 1 && (
                  <Box
                    gridColumn={{
                      sm: "span 2",
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    <Autocomplete
                      sx={{ width: "100%" }}
                      loading={isLoading}
                      disablePortal
                      // disabled={isChargerOpen}
                      value={selectedCharger}
                      onChange={(e, newValue) => setSelectedCharger(newValue)}
                      options={
                        allData?.data?.chargers?.map((el: any) => el?.id) || []
                      }
                      getOptionLabel={(option: any) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Search a Charger..."
                        />
                      )}
                      onInputChange={(event, newInputValue, reason) => {
                        if (reason === "clear") {
                          setSelectedCharger("");
                          return;
                        } else {
                          setSelectedCharger(newInputValue);
                        }
                      }}
                    />
                  </Box>
                )}

                {tab === 1 &&
                  allData?.data?.chargers?.some(
                    (el: any) => el?.id === selectedCharger
                  ) && (
                    <TicketStatus
                      selectedCharger={selectedCharger}
                      tab={tab}
                      open={allData?.data?.chargers?.some(
                        (el: any) => el?.id === selectedCharger
                      )}
                      setIsChargerOpen={setIsChargerOpen}
                      setSelectedCharger={setSelectedCharger}
                      setQRData={setQRData}
                      isChargerOpen={isChargerOpen}
                    />
                  )}

                <Box mt={3} gridColumn={{ sm: "span 2" }}>
                  <Typography className="label">
                    Add Note <span className="required">&#x2a;</span>
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-multiline-static"
                    multiline
                    rows={8}
                    placeholder="Write a note for the issue..."
                    value={note}
                    onChange={(e: any) => {
                      if (e.target.value.length <= 250) setNote(e.target.value);
                    }}
                    sx={{ "& .MuiInputBase-root": { p: 1.5 } }}
                  />
                </Box>
              </Box>
            </>
          )}
          {step === 1 && (
            <Box
              sx={{
                maxWidth: 560,
                mx: "auto",
                "& .table": {
                  borderCollapse: "collapse",
                  width: 1,
                  fontSize: 14,
                  lineHeight: "16px",
                  "& td": {
                    py: 1.25,
                    px: 2,
                  },
                  "& .bold": {
                    fontWeight: 500,
                  },
                  "& .header": {
                    px: 2,
                    py: 1,
                    position: "relative",
                    "& td": {
                      position: "absolute",
                      verticalAlign: "middle",
                      bgcolor: (theme) => theme.customColors.header,
                      width: 1,
                      borderRadius: "4px",
                      fontSize: 16,
                      fontWeight: 600,
                      "& span": {
                        display: "inline-block",
                        transform: "translateY(1px)",
                      },
                    },
                  },
                  "& .first > td": {
                    pt: 9,
                  },
                  "& .last > td": {
                    pb: 3,
                  },
                },
              }}
            >
              <table className="table">
                <tbody>
                  {[
                    { header: "Ticket Info", onEdit: () => setStep(0) },
                    ...[
                      tab === 0
                        ? {
                            label: "Charger UID",
                            value: qrData,
                            required: true,
                          }
                        : {
                            label: "Charger UID",
                            value: selectedCharger,
                            required: true,
                          },
                    ],

                    { label: "Note", value: note, required: true },
                  ].map(
                    ({ header, onEdit, label, value, required }, i, arr) => {
                      const isFirst = arr[i - 1]?.header;
                      const isLast = !arr[i + 1] || arr[i + 1].header;

                      return (
                        <tr
                          key={i}
                          className={
                            header
                              ? "header"
                              : `${isFirst ? "first" : ""} ${
                                  isLast ? "last" : ""
                                }`
                          }
                        >
                          {header ? (
                            <td colSpan={2}>
                              <span>{header}</span>
                              <IconButton
                                sx={{ ml: 1.5 }}
                                children={<EditOutlined />}
                                color="primary"
                                size="small"
                                onClick={onEdit}
                              />
                            </td>
                          ) : (
                            <>
                              <td>{label}</td>
                              <td
                                className="bold"
                                style={{
                                  overflowWrap: "break-word",
                                  maxWidth: 100,
                                }}
                              >
                                {value ||
                                  (required && (
                                    <Box display="flex" alignItems="center">
                                      <ErrorOutline
                                        fontSize="small"
                                        color="error"
                                        style={{ marginRight: 8 }}
                                      />
                                      Required
                                    </Box>
                                  ))}
                              </td>
                            </>
                          )}
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          {step !== 0 && (
            <Button variant="outlined" onClick={handleBack}>
              Back
            </Button>
          )}
          {step === 0 && (
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          )}
          <Button
            onClick={handleNext}
            variant={
              isComplete(step) || step === steps.length - 1
                ? "contained"
                : "outlined"
            }
            disableElevation
            disabled={disabled}
          >
            {step === steps.length - 1 ? "Save" : "Next"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddPricingDialog;
