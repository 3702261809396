import { Box, Button, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import { GlobalState, drawer, getDarkModePreference } from "utils";
import { ReactComponent as DeleteIcon } from "assets/images/icons/delete.svg";
import { ReactComponent as CloseIcon } from "assets/images/icons/close.svg";
import DeleteDialog from "./DeleteDialog";
import { useState } from "react";
import { ReactComponent as EditIcon } from "assets/images/icons/edit.svg";

const InfoDrawer = ({ scm, tab, setTab, onButtonClick, openDialog }: any) => {
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state),
  );

  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
  });


  const table = [
    { header: "Component Information" },
    {
      label: "Company Name",
      value: scm?.oem?.name || "-",
    },
    {
      label: "Vehicle Model",
      value: scm?.vehicleModel?.name || "-",
    },
    {
      value: scm?.parentModel?.key.replace("CONTROLLER_", "") || "-",
      label: "Speedometer Type",
    },
    {
      label: "Serial Number",
      value: scm?.serialNumber || "-",
    },
    {
      label: "MAC ID",
      value: scm?.specs.macId,
    },
    {
      label: "Hardware Version",
      value: scm?.hardware || "-",
    },
    {
      label: "Primary Firmware Version",
      value: scm?.currentOta?.primary?.version || "-",
    },
    {
      label: "Secondary Firmware Version",
      value: scm?.currentOta?.secondary?.version || "-",
    },
    {
      label: "Warranty Details",
      value:
        scm?.warranty?.duration === undefined
          ? "-"
          : scm?.warranty?.duration + " days",
    },
    {
      value: moment(scm.createdAt).format("DD-MM-YYYY"),
      label: "Created On",
    },
  ];

  return (
    <>
      <DeleteDialog
        open={deleteDialog.open}
        handleClose={() => setDeleteDialog({ ...deleteDialog, open: false })}
        scm={scm}
        onDelete={() => { drawer.close(); setTab(1) }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            px: 3,
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: isDarkMode ? "#000" : "#3D3D3D",
            fontWeight: 500,
            color: "#fff",
          }}
        >
          {scm?.serialNumber || scm?.specs?.macId}
          <IconButton
            children={<CloseIcon />}
            color="inherit"
            size="small"
            onClick={() => drawer.close()}
          />
        </Box>
        <Box flexGrow={1} overflow="auto" pb={4}>
          <Box
            sx={{
              px: 3,
              pt: 2.5,
              "& .table": {
                borderCollapse: "collapse",
                width: 1,
                fontSize: 14,
                lineHeight: "16px",
                "& td": {
                  py: 1.5,
                  px: 2,
                  "&.secondary": {
                    color: "text.secondary",
                  },
                },
                "& .bold": {
                  fontWeight: 500,
                },
                "& .header": {
                  position: "relative",
                  "& td": {
                    py: 2,
                    position: "absolute",
                    verticalAlign: "middle",
                    backgroundColor: (theme: any) => theme.customColors.header,
                    width: 1,
                    borderRadius: "4px",
                    fontSize: 16,
                    fontWeight: 600,
                    "& span": {
                      display: "inline-block",
                      transform: "translateY(1px)",
                    },
                  },
                },
                "& .first > td": {
                  pt: 8.5,
                },
                "& .last > td": {
                  pb: 3,
                },
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",      // Optional: Align items vertically in the center
              }}
            >
              {tab === 1 ?
                <Tooltip title="Delete">
                  <IconButton
                    sx={{ mr: 3, mt: 1, float: "right" }}
                    children={<DeleteIcon />}
                    color="inherit"
                    size="small"
                    onClick={() => setDeleteDialog({
                      open: true,
                    })}
                  />
                </Tooltip> : ""}
              <Button
                sx={{
                  display: "flex",
                  height: 41,
                  maxWidth: 250,
                  float: "right",
                  borderRadius: 10,
                  borderWidth: "1.5px !important",
                  borderColor: (theme) => theme.palette.primary.main,
                  textTransform: "none",
                  fontWeight: 500,
                  px: 4,
                  ml: "auto",
                  marginRight: 0.5,
                  marginLeft: "8px",
                  backgroundColor: tab === 0 ? "#FA5F55" : "#26C72D",
                  transition: "background-color 0.3s ease",
                  "&:hover": {
                    backgroundColor: tab === 0 ? "#FF524D" : "#2DEA47",
                  },
                }}
                variant="contained"
                onClick={() => {
                  onButtonClick(scm._id);
                }}
              >
                {tab === 0 ? "Recall" : "Assign"}
              </Button>
            </Box>
            <br />
            <table className="table">
              <tbody>
                {table.map(({ header, label, value }, i) => {
                  const isFirst = table[i - 1]?.header;
                  const isLast = !table[i + 1] || table[i + 1].header;
                  return (
                    <tr
                      key={i}
                      className={
                        header
                          ? "header"
                          : `${isFirst ? "first" : ""} ${isLast ? "last" : ""}`
                      }
                    >
                      {header ? (
                        <td colSpan={2}>
                          <Box
                            sx={{
                              mb: 2,
                              // px: 2,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              height: 2
                            }}
                          >
                            <span>{header.toUpperCase()}</span>
                            {tab === 1 ?
                              <Tooltip title="Edit">
                                <IconButton
                                  sx={{ marginLeft: "auto" }}
                                  children={<EditIcon />}
                                  color="inherit"
                                  size="small"
                                  onClick={openDialog}
                                />
                              </Tooltip> : ""}
                          </Box>
                        </td>
                      ) : (
                        <>
                          <td className="bold">{label}</td>
                          <td
                            className={
                              value === "Loading..." ? "secondary" : ""
                            }
                          >
                            {value}
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default InfoDrawer;
