import {
  CurrencyRupee,
  ElectricMopedOutlined,
  GroupOutlined,
  StraightenOutlined,
} from "@mui/icons-material";
import { alpha, Box, Paper, Skeleton } from "@mui/material";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { authorizedFetch, GlobalState } from "utils";
import { LEASE_URL } from "utils/constants";
import TopVehicles from "./TopVehicles";
import RecentTrips from "./RecentTrips";
import TotalBookings from "./TotalBookings";
import TotalDistance from "./TotalDistance";
import TotalEarnings from "./TotalEarnings";
import TotalUsers from "./TotalUsers";
import VehicleAvailability from "./VehicleAvailability";
import VehicleHealth from "./VehicleHealth";
import VehicleService from "./VehicleService";
import VehiclesMap from "./VehiclesMap";
import CO2 from "./CO2";
import Fuel from "./Fuel";

const Cards = ({ dateFrom, dateTo }: any) => {
  const { masterView } = useSelector((state: GlobalState) => state.global);

  const companyId = useSelector(
    (state: GlobalState) => state.global.company.id
  );

  const { isLoading: vehicleStatsLoading, data: vehiclesStatsData } = useQuery(
    ["getFMSVehicleStats", masterView],
    () =>
      authorizedFetch(
        `${LEASE_URL}/company/vehicles/stats?master=${masterView}`
      )
  );

  const { isLoading: earningsStatsLoading, data: earningsStatsData } = useQuery(
    ["getFMSEarningsStats", dateFrom, dateTo, masterView],
    () =>
      authorizedFetch(
        `${LEASE_URL}/company/bookings/earnings?master=${masterView}&timeframe=custom&starttime=${dateFrom}&endtime=${dateTo}`
      )
  );

  const { isLoading: usersStatsLoading, data: usersStatsData } = useQuery(
    ["getFMSUsers", masterView],
    () => authorizedFetch(`${LEASE_URL}/company/users/all?master=${masterView}`)
  );

  const { isLoading: distanceStatsLoading, data: distanceStatsData } = useQuery(
    ["getFMSDistanceStats", masterView],
    () =>
      authorizedFetch(
        `${LEASE_URL}/company/bookings/distance/stats?master=${masterView}`
      )
  );

  const { isLoading: distanceChartLoading, data: distanceChartData } = useQuery(
    ["getFMSDistanceChart", dateFrom, dateTo, masterView],
    () =>
      authorizedFetch(
        `${LEASE_URL}/company/bookings/distance?timeframe=custom&starttime=${dateFrom}&endtime=${dateTo}&master=${masterView}`
      )
  );

  const { isLoading: bookingsStatsLoading, data: bookingsStatsData } = useQuery(
    ["getFMSBookingsStats", dateFrom, dateTo, masterView],
    () =>
      authorizedFetch(
        `${LEASE_URL}/company/bookings/count?timeframe=custom&starttime=${dateFrom}&endtime=${dateTo}&master=${masterView}`
      )
  );

  const totalVehicles =
    vehiclesStatsData?.data?.[0]?.count?.toLocaleString() || 0;
  const totalEarnings = `₹${
    earningsStatsData?.data?.total_earnings?.toLocaleString() || 0
  }`;
  const totalUsers = usersStatsData?.data?.count?.toLocaleString() || 0;
  const totalDistance = `${(
    (distanceStatsData?.data?.total_distance || 0) / 1000
  ).toLocaleString(undefined, { maximumFractionDigits: 2 })} km`;

  return (
    <>
      <Box
        sx={{
          px: { xs: 2, md: 0 },
          pt: { xs: 2, md: 2 },
          pb: { xs: 3, md: 0 },
          display: "grid",
          gridTemplateColumns: {
            lg: "repeat(12, 1fr)",
          },
          gap: { xs: 2, md: 4.5 },
          "& .MuiPaper-root": {
            borderRadius: "14px",
            boxShadow: (theme) => theme.customShadows.card,
            height: { xs: 260, md: 438 },
          },
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridColumn: "1/-1",
            gridTemplateColumns: {
              lg: "1fr 1fr",
            },
            gap: { xs: 3, md: 4 },
            "& .MuiPaper-root": {
              height: "auto",
              boxShadow: (theme) => theme.customShadows.card,
            },
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "1fr 1fr",
                lg: "repeat(4, 1fr)",
              },
              gap: { xs: 1.75, md: 4 },
              gridColumn: "1 / -1",
              "& .card": {
                height: "auto !important",
                width: 1,
                // borderRadius: 0,
                py: { xs: 1.25, md: 2 },
                px: { xs: 1.25, md: 3 },
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                "& .icon": {
                  borderRadius: 50,
                  height: { xs: 32, md: 72, xl: 98 },
                  width: { xs: 32, md: 72, xl: 98 },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  bgcolor: (theme) => alpha(theme.palette.primary.main, 0.2),
                  color: (theme) => theme.palette.primary.main,
                  fontSize: { xs: 18, md: 32, xl: 44 },
                },
                "& .description": {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  "& .label": {
                    mb: { xs: 1, md: 1.5 },
                    textAlign: "end",
                    fontSize: { xs: "12px", md: 16, xl: 18 },
                    fontWeight: 600,
                    lineHeight: { xs: "16px", md: "24.55px" },
                    color: (theme) => theme.customColors.text.grey,
                  },
                  "& .value": {
                    textAlign: "end",
                    fontSize: { xs: "16px", md: "20px", xl: "30px" },
                    fontWeight: 700,
                    lineHeight: { xs: "19.1px", md: "44px" },
                    letterSpacing: "0em",
                  },
                  "& .greyed": {
                    color: (theme) => theme.palette.text.disabled,
                    userSelect: "none",
                  },
                },
              },
            }}
          >
            <Paper className="card">
              <Box className="icon">
                <ElectricMopedOutlined fontSize="inherit" />
              </Box>
              <Box className="description">
                <span className="label">Total Vehicles</span>
                <span className="value">
                  {vehicleStatsLoading ? (
                    <Skeleton width={60} />
                  ) : (
                    totalVehicles
                  )}
                </span>
              </Box>
            </Paper>
            <Paper className="card">
              <Box className="icon">
                <CurrencyRupee fontSize="inherit" />
              </Box>
              <Box className="description">
                <span className="label">Total Earnings</span>
                <span className="value">
                  {earningsStatsLoading ? (
                    <Skeleton width={60} />
                  ) : (
                    totalEarnings
                  )}
                </span>
              </Box>
            </Paper>
            <Paper className="card">
              <Box className="icon">
                <GroupOutlined fontSize="inherit" />
              </Box>
              <Box className="description">
                <span className="label">Total Users</span>
                <span className="value">
                  {usersStatsLoading ? <Skeleton width={60} /> : totalUsers}
                </span>
              </Box>
            </Paper>
            <Paper className="card">
              <Box className="icon">
                <StraightenOutlined fontSize="inherit" />
              </Box>
              <Box className="description">
                <span className="label">Total Distance</span>
                <span className="value">
                  {distanceStatsLoading ? (
                    <Skeleton width={60} />
                  ) : (
                    totalDistance
                  )}
                </span>
              </Box>
            </Paper>
          </Box>
        </Box>
        <Paper
          sx={{
            gridColumn: { lg: "span 7" },
            overflow: "hidden",
            height: { xs: 260, md: 438 },
          }}
        >
          <VehiclesMap isMasterView={masterView} />
        </Paper>
        <Paper
          sx={{
            py: { xs: 3, md: 4 },
            px: { xs: 2, md: 5 },
            gridColumn: { lg: "span 5" },
            height: "auto !important",
          }}
        >
          <VehicleAvailability
            isLoading={vehicleStatsLoading}
            data={vehiclesStatsData}
          />
        </Paper>
        <Paper
          sx={{
            py: { xs: 3, md: 4 },
            px: { xs: 2, md: 5 },
            gridColumn: { lg: "span 6" },
            height: { xs: "auto !important", md: 438 },
          }}
        >
          <VehicleHealth />
        </Paper>
        <Paper
          sx={{
            py: { xs: 3, md: 4 },
            px: { xs: 2, md: 5 },
            gridColumn: { lg: "span 6" },
          }}
        >
          <VehicleService />
        </Paper>
        {(companyId === "63a41162d4423ec4f741df0d" ||
          companyId === "5cfa443da7b11b00073f9657") && (
          <>
            <Paper
              sx={{
                py: { xs: 3, md: 4 },
                px: { xs: 2, md: 5 },
                gridColumn: { lg: "span 6" },
              }}
            >
              <CO2 />
            </Paper>
            <Paper
              sx={{
                py: { xs: 3, md: 4 },
                px: { xs: 2, md: 5 },
                gridColumn: { lg: "span 6" },
              }}
            >
              <Fuel />
            </Paper>
          </>
        )}

        <Box sx={{ gridColumn: { lg: "span 6" } }}>
          <TotalBookings
            isLoading={bookingsStatsLoading}
            data={bookingsStatsData}
          />
        </Box>
        <Box sx={{ gridColumn: { lg: "span 6" } }}>
          <TotalEarnings
            isLoading={earningsStatsLoading}
            data={earningsStatsData}
          />
        </Box>
        <Box sx={{ gridColumn: { lg: "span 6" } }}>
          <TotalUsers {...{ dateFrom, dateTo, masterView }} />
        </Box>
        <Box sx={{ gridColumn: { lg: "span 6" } }}>
          <TotalDistance
            isLoading={distanceChartLoading}
            data={distanceChartData}
          />
        </Box>
        <Box
          sx={{
            gridColumn: { lg: "span 6" },
            height: { xs: 390, md: 438 },
            "& > .MuiPaper-root": {
              height: "100% !important",
            },
          }}
        >
          <TopVehicles {...{ dateFrom, dateTo }} />
        </Box>
        <RecentTrips isMasterView={masterView} />
      </Box>
    </>
  );
};

export default Cards;
