import { AlertColor } from "@mui/material";
import {
  setGlobalState,
  toggleDrawer,
  toggleLoader,
  toggleMasterView,
  toggleSnackbar,
} from "actions";
import moment from "moment";
import store from "store";
import { PageID, SectionID } from "./constants";
import { getBoltToken } from "./request";
import storageManager from "./storageManager";

interface SnackbarMessage {
  type: AlertColor;
  message: string;
  key: number;
}

type DrawerContent = JSX.Element | null;
type AppBarContent = JSX.Element | null;

export interface GlobalState {
  global: {
    [key: string]: any;
    activeSection: "charger" | "retail" | "rental" | "housekeeping" | null;
    token: string | null;
    user: any;
    company: any;
    prefersDarkMode: boolean | null;
    loader: boolean;
    masterView: boolean;
    snackbar: {
      open: boolean;
      snackPack: readonly SnackbarMessage[];
      messageInfo?: SnackbarMessage;
      actionInfo?: {
        buttonText: string;
        buttonAction: () => void;
      };
    };
    drawer: {
      open: boolean;
      content: DrawerContent;
    };
    appBar: {
      open: boolean;
      content: AppBarContent;
    };
    reports: any[];
    vehicleReports: any[];
    lastReadNotification: string | null;
  };
}

export type API_METHODS = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
interface Options {
  method?: API_METHODS;
  headers?: any;
  body?: any;
  onlyBody?: any;
}

export const authorizedFetch = async (url: string, options?: Options) => {
  let { method, headers, body, onlyBody } = options || {};

  let boltToken = await getBoltToken();

  let newHeaders = {
    ...(headers ? headers : {}),
    token: storageManager?.get("companyToken") || "",
    Authorization: `Bearer ${boltToken}`,
  };

  return fetch(url, {
    ...(method ? { method } : {}),
    headers: newHeaders,
    ...(body
      ? { body: JSON.stringify(body) }
      : onlyBody
      ? { body: onlyBody }
      : {}),
  }).then((res) => res.json());
};

export function addQueryParams(url: string, params: { [key: string]: string }) {
  return url.concat(
    "?",
    Object.keys(params)
      .map((key, i) => `${key}=${params[key]}`)
      .join("&")
  );
}

export const getDarkModePreference = (state: GlobalState) => {
  if (state.global.prefersDarkMode === null)
    return window.matchMedia("(prefers-color-scheme: dark)").matches;
  else return state.global.prefersDarkMode;
};

export const getMasterView = (state: GlobalState) => {
  return state.global.masterView;
};

export const getSectionPermissions = (
  sectionId: SectionID,
  permissions?: string[]
) => {
  if (!permissions) permissions = store.getState().global.user;
  if (
    (permissions || []).some(
      (permission: string) =>
        permission.includes("dashboard:*") ||
        permission.includes(`dashboard:${sectionId}`)
    )
  ) {
    console.log(permissions);
    return true;
  } else return false;
};

export const getPermissions = (pageId: PageID) => {
  let { permissions } = store.getState().global.user;
  let canRead = false,
    canWrite = false,
    isAdmin = false,
    isSuperAdmin = false;

  let section = pageId.split(":")[0];

  if (permissions.includes("dashboard:*")) {
    isSuperAdmin = true;
    isAdmin = true;
    canWrite = true;
    canRead = true;
  } else if (permissions.includes(`dashboard:${section}:*`)) {
    isAdmin = true;
    canWrite = true;
    canRead = true;
  } else if (
    [
      "dashboard:*:*:WRITE",
      `dashboard:${section}:*:WRITE`,
      `dashboard:${pageId}:WRITE`,
      `dashboard:${pageId}:*`,
    ].some((el) => permissions.includes(el))
  ) {
    canWrite = true;
    canRead = true;
  } else if (
    [
      "dashboard:*:*:READ",
      `dashboard:${section}:*:READ`,
      `dashboard:${pageId}:READ`,
    ].some((el) => permissions.includes(el))
  ) {
    canRead = true;
  }

  return { canRead, canWrite, isAdmin, isSuperAdmin };
};

export const getEnabledSections = (state: GlobalState) => {
  let array: string[] = [];
  let { permissions } = state.global.user;

  if (state.global.user.email === "scm@bolt.earth") return ["housekeeping"];
  if (state.global.user.email === "adi.setiakarsa@alvaauto.com")
    return ["charger"];

  if (state.global.user.email === "srikhantan.s@bounceshare.com")
    return ["rental"];

  console.log(permissions);
  console.log(state.global);

  if (permissions?.includes("dashboard:*"))
    array = ["retail", "rental", "charger", "housekeeping"];
  else {
    if (permissions?.includes("dashboard:retail:*")) array.push("retail");
    if (permissions?.includes("dashboard:rental:*")) array.push("rental");
    if (permissions?.includes("dashboard:charger:*")) array.push("charger");
    if (permissions?.includes("dashboard:housekeeping:*"))
      array.push("housekeeping");
  }
  return array;
};

export const snackbar = {
  success: createSnackbar("success"),
  info: createSnackbar("info"),
  warning: createSnackbar("warning"),
  error: createSnackbar("error"),
};

export const setLoader = (arg: boolean) => {
  store.dispatch(toggleLoader(arg));
};

export const setMasterView = (arg: boolean) => {
  store.dispatch(toggleMasterView(arg));
};

function createSnackbar(type: string) {
  return (
    message: string,
    options?: { buttonText: string; buttonAction: () => void }
  ) => {
    let snackbarState = store.getState().global.snackbar;
    if (snackbarState?.messageInfo?.message !== "An update is available.")
      store.dispatch(
        toggleSnackbar({
          ...snackbarState,
          snackPack: [
            ...snackbarState.snackPack,
            { type, message, key: new Date().getTime() },
          ],
          ...(options ? { actionInfo: options } : {}),
        })
      );
  };
}

export const drawer = {
  open: (content: DrawerContent) => {
    toggleInfoDrawer({ open: true, content });
  },
  close: () => {
    toggleInfoDrawer({ open: false });
  },
};

export const appBar = {
  open: (content: AppBarContent) => {
    toggleAppBar({ open: true, content });
  },
  close: () => {
    toggleAppBar({ open: false });
  },
};

function toggleInfoDrawer(arg: any) {
  let drawerState = store.getState().global.drawer;
  store.dispatch(toggleDrawer({ ...drawerState, ...arg }));
}

function toggleAppBar(arg: any) {
  let appBarState = store.getState().global.appBar;
  store.dispatch(setGlobalState({ appBar: { ...appBarState, ...arg } }));
}

export function getDuration(minutes: number) {
  if (typeof minutes !== "number" || !minutes) {
    return "0m";
  }

  let duration = moment.duration(minutes, "minutes");
  let d = duration.days();
  let h = duration.hours();
  let m = duration.minutes();
  let s = duration.seconds();

  // let hours = minutes / 60
  // let h = Math.floor(hours)
  // let m = Math.floor((hours - h) * 60)
  // let s = Math.round((minutes - (h * 60) - m) * 60)

  return (
    `${d ? d + "d " : ""}${h ? h + "h " : ""}${m ? m + "m " : ""}${
      s ? s + "s" : ""
    }` || "0m"
  );
}

export const resolvePath = (object: any, path: string) =>
  path.split(".").reduce((o: any, p) => (o ? o[p] : ""), object);

export const makeCamelCase = (str: string) => {
  let arr = str.toLowerCase().split(/-| /);
  arr.forEach((el, i) => {
    if (i !== 0) arr[i] = el[0]?.toUpperCase() + el.slice(1);
  });
  return arr.join("");
};

export function getUrlParams() {
  return window.location.search
    .substring(1)
    .split("&")
    .map((pair) => ({ [pair.split("=")[0]]: pair.split("=")[1] }));
}

export function validateLatLng(lat: any, lng: any) {
  lat = parseFloat(lat);
  lng = parseFloat(lng);
  const isLatitude = (num: number) => isFinite(num) && Math.abs(num) <= 90;
  const isLongitude = (num: number) => isFinite(num) && Math.abs(num) <= 180;
  return isLatitude(lat) && isLongitude(lng);
}

export function guidGenerator() {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
}

export const lerp = (a: number, b: number, amount: number) =>
  (1 - amount) * a + amount * b;

export function titleCase(string: string) {
  return string[0]?.toUpperCase() + string?.slice(1)?.toLowerCase();
}
