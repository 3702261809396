import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { useMutation } from "react-query";
import { HighlightOff } from "@mui/icons-material";
import { NOTIFIER_URL } from "utils/constants";

interface Props {
  open: boolean;
  handleClose: () => void;
  data: any;
}

const NotificationDialog: React.FC<Props> = ({ open, handleClose, data }) => {
  const [input, setInput] = useState({
    phone: "",
    email: "",
    message: "",
  });

  useEffect(() => {
    if (open && data) {
      setInput({ ...input, phone: data.phone, email: data.email });
    }
    if (!open) {
      setInput({
        phone: "",
        email: "",
        message: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, data]);

  const { phone, email, message } = input;

  const mutationSMS = useMutation(
    ["sendSMS", phone],
    () =>
      authorizedFetch(`${NOTIFIER_URL}/sms`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          phone: [phone],
          message: message,
        },
      }),
    {
      onSuccess: () => {
        snackbar.success(`Phone Notification Sent`);
        setLoader(false);
      },
      onError: () => {
        snackbar.error(`Error sending phone notification`);
      },
    }
  );

  const mutationEmail = useMutation(
    ["sendEMAIL", email],
    () =>
      authorizedFetch(`${NOTIFIER_URL}/email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          subject: "Notification from REVOS",
          email: [email],
          body: message,
        },
      }),
    {
      onSuccess: () => {
        snackbar.success(`Email Notification Sent`);
        setLoader(false);
      },
      onError: () => {
        snackbar.error(`Error sending email notification`);
      },
    }
  );

  function onSend() {
    setLoader(true);
    if (phone.length) {
      mutationSMS.mutate();
    }
    if (email.length) {
      mutationEmail.mutate();
    }
    handleClose();
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        Notify User
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent className="py-1">
        <Box>
          <Typography className="label">Phone Number</Typography>
          <TextField
            fullWidth
            size="small"
            placeholder="No User Phone"
            value={phone}
            onChange={(e: any) => {
              setInput({ ...input, phone: e.target.value });
            }}
          />
        </Box>
        <Box mt={4}>
          <Typography className="label">Email</Typography>
          <TextField
            fullWidth
            size="small"
            placeholder="No User Email"
            value={email}
            onChange={(e: any) => {
              setInput({ ...input, email: e.target.value });
            }}
          />
        </Box>
        <Box mt={4}>
          <Typography className="label">Message</Typography>
          <TextField
            fullWidth
            id="outlined-multiline-static"
            multiline
            rows={10}
            placeholder="Type a message..."
            value={message}
            onChange={(e: any) => {
              setInput({ ...input, message: e.target.value });
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          disabled={input.message.length === 0}
          variant="contained"
          onClick={onSend}
        >
          Notify
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotificationDialog;
