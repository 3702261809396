import { EditOutlined, ErrorOutline, HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Step,
  StepButton,
  Stepper,
  Typography,
} from "@mui/material";
import CircularLoader from "components/CircularLoader";
import { queryClient } from "index";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { RETAIL_URL } from "utils/constants";
import Config from "./Config";
import Devices from "./Devices";
import ModelInfo from "./ModelInfo";

const formObj = {
  image: "",
  imageKey: "",
  name: "",
  type: "",
  protocol: "",
  controller: "",
};

const AddModelDialog = ({
  open,
  handleClose,
  isEditMode,
  model: modelInfo,
  onEdit,
  isRental,
  refetchModels,
}: any) => {
  const steps = ["Model Info", "Config", "Devices", "Confirm"];
  const [step, setStep] = useState<number>(0);
  let isLastStep = step === steps.length - 1;

  const [form, setForm] = useState({ ...formObj });
  const [config, setConfig] = useState<any>({});
  const [devices, setDevices] = useState<any>([]);
  const [selectedDevices, setSelectedDevices] = useState<any>([]);

  const url = `${RETAIL_URL}/assembly/model/${modelInfo?._id}`;
  const { isLoading, data } = useQuery(
    ["getModel", modelInfo?._id],
    () => authorizedFetch(url),
    {
      enabled: Boolean(modelInfo?._id) && isEditMode,
    }
  );
  const model = data?.data?.constructor === Array ? data.data[0] : {};

  const configUrl = `${RETAIL_URL}/inventory/protocol-config/${form?.protocol}`;
  const { isLoading: configLoading, data: configData } = useQuery(
    ["getConfig", form?.protocol],
    () => authorizedFetch(configUrl),
    {
      enabled: Boolean(form?.protocol),
    }
  );

  const controllersUrl = `${RETAIL_URL}/inventory/controllers/${form.protocol}`;
  const { isLoading: controllersLoading, data: controllersData } = useQuery(
    ["getControllers", form.protocol],
    () => authorizedFetch(controllersUrl),
    {
      enabled: form.protocol !== "",
    }
  );

  const deviceOptionsUrl = `${RETAIL_URL}/inventory/protocol/devices/${form?.protocol}`;
  const { isLoading: deviceOptionsLoading, data: deviceOptionsData } = useQuery(
    ["getDevices", form?.protocol],
    () => authorizedFetch(deviceOptionsUrl),
    {
      enabled: Boolean(form?.protocol),
    }
  );

  useEffect(() => {
    // On open
    const model = data?.data?.constructor === Array ? data.data[0] : {};
    if (open) {
      setStep(0);
      if (isEditMode && model) {
        // Pre-fill fields if edit mode
        console.log(model);
        setForm({
          image: model?.image || "",
          imageKey: model?.imageKey || "",
          name: model?.name,
          type: model?.type,
          protocol: model?.protocol,
          controller:
            model?.components?.find((el: any) => el.category === "CONTROLLER")
              ?.modelId?.[0]?.key || "",
        });
        setConfig(model?.config?.configs);
        setDevices(
          model?.components
            .filter((el: any) => el.category !== "CONTROLLER")
            .map((el: any) => {
              let newEl = { ...el };
              let newArr = el.modelId.map((device: any) => device?._id);
              newEl.modelId = newArr;
              return newEl;
            })
        );
      } else {
        // Reset fields to blank
        setForm({ ...formObj });
        setConfig({});
        setDevices([]);
      }
    }
  }, [open, isEditMode, data]);

  useEffect(() => {
    // Create config object from configData, pre-fill values if isEditMode
    if ((isEditMode && data?.data?.constructor === Array) || !isEditMode) {
      if (configData?.data?.constructor === Array) {
        let configObj: any = {};
        configData.data.forEach((field: any) => {
          if (isEditMode) {
            const model = data.data[0];
            configObj[field.key] =
              model?.config?.configs?.[field.key] ??
              (field.type === "boolean" ? false : null);
          } else {
            configObj[field.key] = field.type === "boolean" ? false : null;
          }
        });
        setConfig(configObj);
      }
    }
  }, [configData, isEditMode, data]);

  useEffect(() => {
    // Reset selected devices on protocol change. For edit mode, only reset when selected protocol is different from existing one.
    if (
      (isEditMode &&
        data?.data?.constructor === Array &&
        data.data?.[0]?.protocol !== form.protocol) ||
      !isEditMode
    ) {
      setDevices([]);
      setForm((prev) => ({ ...prev, controller: "" }));
    }
  }, [form.protocol, isEditMode, data]);

  function handleNext() {
    if (!isLastStep) setStep((prev) => prev + 1);
    else {
      setLoader(true);
      let createUrl = `${RETAIL_URL}/assembly/model/create`;
      let editUrl = `${RETAIL_URL}/assembly/model/${model?._id}`;
      authorizedFetch(isEditMode ? editUrl : createUrl, {
        method: isEditMode ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          ...(!isEditMode
            ? {
                name: form.name,
                protocol: form.protocol,
              }
            : {}),
          type: form.type,
          image: form.imageKey,
          components: [],
          config: isRental
            ? {
                batteryMinVoltage: config.batteryMinVoltage,
                batteryMaxVoltage: config.batteryMaxVoltage,
              }
            : config,
          devices: [
            ...devices,
            ...(form.controller
              ? [
                  {
                    category: "CONTROLLER",
                    modelId: [
                      controllersData?.data?.[0]?.find(
                        (el: any) => el.key === form.controller
                      )?._id,
                    ],
                  },
                ]
              : []),
          ],
        },
      })
        .then((res) => {
          setLoader(false);
          if (res.meta.success) {
            if (isEditMode) onEdit();
            snackbar.success(isEditMode ? "Updated model" : "Added model");
            queryClient.resetQueries("getModel");
            queryClient.resetQueries("getModels");
            handleClose();
            if (isRental) refetchModels();
          } else {
            snackbar.error("Error: " + res.msg);
          }
        })
        .catch((err) => {
          setLoader(false);
          console.error(err);
          snackbar.error("An error occurred");
        });
    }
  }

  let disabled = false;

  if (step === 0)
    if (
      !form.name ||
      !form.protocol ||
      !form.imageKey ||
      !form.type ||
      form?.name?.length > 20
    )
      disabled = true;

  if (step === 1) {
    if (configLoading) {
      disabled = true;
    }
    if (configData?.data?.constructor === Array) {
      configData.data.forEach((field: any) => {
        // Only `batteryMaxVoltage` and `batteryMinVoltage` fields are required for FMS
        let isRequired = isRental
          ? ["batteryMaxVoltage", "batteryMinVoltage"].includes(field.key)
          : field.required;

        if (isRequired && [undefined, null].includes(config[field.key])) {
          disabled = true;
        }
      });
    }
    if (
      config?.batteryMaxVoltage !== null &&
      config?.batteryMinVoltage > config?.batteryMaxVoltage
    ) {
      disabled = true;
    }
  }

  if (step === 2)
    if (devices.length === 0 && form.protocol !== "SMART") disabled = true;

  const telematicsConfirm: any = selectedDevices?.length
    ? [
        {
          label: "TELEMATICS",
          value: selectedDevices.map((device: any, i: number) => {
            return (
              <Box id={device} key={i}>
                {device}
                <br />
                <br />
              </Box>
            );
          }),
        },
      ]
    : "";

  const controllerConfirm: any = devices.some((el: any) => {
    return el?.category === "CONTROLLER" && el?.modelId?.length !== 0;
  })
    ? [
        {
          label: "CONTROLLER",
          value: "CONTROLLER",
        },
      ]
    : "";

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        {isEditMode ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            Edit Model
            <Divider flexItem orientation="vertical" sx={{ mx: 1.5 }} />
            <Typography color="text.secondary">{modelInfo?.name}</Typography>
          </Box>
        ) : !isRental ? (
          "Add Model"
        ) : (
          "Add Model - (PNP)"
        )}
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent>
        <Stepper
          sx={{ mb: 4, mt: 2, mx: "auto", maxWidth: 534 }}
          activeStep={step}
          // nonLinear
          alternativeLabel
        >
          {steps.map((label, i) => (
            <Step key={i}>
              <StepButton onClick={() => setStep(i)}>{label}</StepButton>
            </Step>
          ))}
        </Stepper>
        {isLoading ? (
          <Box py={3}>
            <CircularLoader />
          </Box>
        ) : (
          <Box sx={{ mx: "auto", maxWidth: 550 }}>
            {step === 0 && (
              <ModelInfo
                {...{
                  form,
                  setForm,
                  isRental,
                  isEditMode,
                  controllersLoading,
                  controllersData,
                }}
              />
            )}
            {step === 1 && (
              <Config
                {...{
                  form,
                  config,
                  setConfig,
                  configLoading,
                  configData,
                  isRental,
                }}
              />
            )}
            {step === 2 && (
              <Devices
                {...{
                  form,
                  devices,
                  setDevices,
                  setSelectedDevices,
                  deviceOptionsLoading,
                  deviceOptionsData,
                }}
              />
            )}
            {step === 3 && (
              <Box
                sx={{
                  maxWidth: 560,
                  mx: "auto",
                  "& .table": {
                    borderCollapse: "collapse",
                    width: 1,
                    fontSize: 14,
                    lineHeight: "16px",
                    "& td": {
                      py: 1.25,
                      px: 2,
                    },
                    "& .bold": {
                      fontWeight: 500,
                    },
                    "& .header": {
                      px: 2,
                      py: 1,
                      position: "relative",
                      "& td": {
                        position: "absolute",
                        verticalAlign: "middle",
                        bgcolor: (theme) => theme.customColors.header,
                        width: 1,
                        borderRadius: "4px",
                        fontSize: 16,
                        fontWeight: 600,
                        "& span": {
                          display: "inline-block",
                          transform: "translateY(1px)",
                        },
                      },
                    },
                    "& .first > td": {
                      pt: 9,
                    },
                    "& .last > td": {
                      pb: 3,
                    },
                  },
                }}
              >
                <table className="table">
                  <tbody>
                    {[
                      { header: "Model Info", onEdit: () => setStep(0) },
                      {
                        label: "Model Name",
                        value: form?.name,
                        required: true,
                      },
                      {
                        label: "Protocol",
                        value: form?.protocol,
                        required: true,
                      },
                      {
                        label: "Cluster Type",
                        value: form?.controller,
                        required: true,
                      },
                      {
                        label: "Vehicle Type",
                        value:
                          form?.type === "TWO"
                            ? "Two Wheeler"
                            : form?.type === "THREE"
                            ? "Three Wheeler"
                            : "",
                        required: true,
                      },
                      { label: "Image", value: form?.image, required: true },

                      { header: "Config", onEdit: () => setStep(1) },

                      ...configData?.data
                        ?.filter(
                          (field: any) =>
                            !(
                              isRental &&
                              ![
                                "batteryMaxVoltage",
                                "batteryMinVoltage",
                              ].includes(field.key)
                            )
                        )
                        ?.map((field: any) => ({
                          label: field.name,
                          value:
                            field.type === "boolean"
                              ? config[field.key]
                                ? "Enabled"
                                : "Disabled"
                              : config[field.key],
                          required: field.required,
                        })),

                      { header: "Devices", onEdit: () => setStep(2) },
                      ...telematicsConfirm,
                      ...controllerConfirm,
                    ].map(
                      ({ header, onEdit, label, value, required }, i, arr) => {
                        const isFirst = arr[i - 1]?.header;
                        const isLast = !arr[i + 1] || arr[i + 1].header;

                        return (
                          <tr
                            key={i}
                            className={
                              header
                                ? "header"
                                : `${isFirst ? "first" : ""} ${
                                    isLast ? "last" : ""
                                  }`
                            }
                          >
                            {header ? (
                              <td colSpan={2}>
                                <span>{header}</span>
                                <IconButton
                                  sx={{ ml: 1.5 }}
                                  children={<EditOutlined />}
                                  color="primary"
                                  size="small"
                                  onClick={onEdit}
                                />
                              </td>
                            ) : (
                              <>
                                {label === "Image" ? (
                                  <>
                                    <td>{label}</td>
                                    <td className="bold">
                                      {(
                                        <img
                                          style={{
                                            width: "300px",
                                            height: "auto",
                                            maxWidth: "100%",
                                            objectFit: "contain",
                                          }}
                                          alt={value}
                                          src={value}
                                        />
                                      ) ||
                                        (required && (
                                          <Box
                                            display="flex"
                                            alignItems="center"
                                          >
                                            <ErrorOutline
                                              fontSize="small"
                                              color="error"
                                              style={{ marginRight: 8 }}
                                            />
                                            Required
                                          </Box>
                                        ))}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    {value ? (
                                      <>
                                        <td>{label}</td>
                                        <td className="bold">
                                          {value ||
                                            (required && (
                                              <Box
                                                display="flex"
                                                alignItems="center"
                                              >
                                                <ErrorOutline
                                                  fontSize="small"
                                                  color="error"
                                                  style={{ marginRight: 8 }}
                                                />
                                                Required
                                              </Box>
                                            ))}
                                        </td>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </Box>
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {step !== 0 && (
          <Button
            variant="outlined"
            onClick={() => setStep((prev) => prev - 1)}
          >
            Back
          </Button>
        )}
        <Button variant="contained" onClick={handleNext} disabled={disabled}>
          {isLastStep ? "Save" : "Next"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddModelDialog;
