import { EditOutlined, ErrorOutline, HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Step,
  StepButton,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { LEASE_URL } from "utils/constants";

interface Props {
  open: boolean;
  handleClose: () => void;
  refetchPricing: any;
}

type inputData = {
  pricingName: string;
  pricingUnit: string;
  baseAmount: number;
  costPerUnit: number;
  // paymentAccount: string;
};

const AddPricingDialog: React.FC<Props> = ({
  open,
  handleClose,
  refetchPricing,
}) => {
  const [step, setStep] = useState(0);
  const steps = ["Pricing Info", "Finish"];

  const [input, setInput] = useState<inputData>({
    pricingName: "",
    pricingUnit: "HOURLY",
    baseAmount: 0,
    costPerUnit: 0,
    // paymentAccount: "",
  });

  const [searchPricing, setSearchPricing] = useState(false);

  const { pricingName, pricingUnit, baseAmount, costPerUnit } = input;

  const addPricingURL = `${LEASE_URL}/company/pricing/create`;

  const mutation = useMutation(
    `addPricing`,
    () =>
      authorizedFetch(addPricingURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          name: pricingName,
          // status: "ACTIVE",
          type: "ONETIME",
          unit: pricingUnit,
          baseAmount: Number(baseAmount),
          // minimumPayableAmount: 0,
          costPerUnit: Number(costPerUnit),
          // amountPayable: 0,
          // paymentMeta: paymentAccount,
        },
      }),
    {
      onSuccess: () => {
        snackbar.success(`Pricing Added`);
        refetchPricing();
        setLoader(false);
      },
      onError: () => {
        snackbar.error(`Error adding pricing`);
      },
    }
  );

  function handleChange(key: string, value: string) {
    setInput((prevInput: inputData) => ({ ...prevInput, [key]: value }));
  }

  const handleSave = () => {
    setLoader(true);
    mutation.mutate();
    handleClose();
  };

  function handleNext() {
    if (step === steps.length - 1) {
      handleSave();
    } else setStep(step + 1);
  }
  function handleBack() {
    setStep(step - 1);
  }

  function isComplete(step: number) {
    switch (step) {
      case 0:
        return ![pricingName, pricingUnit, baseAmount, costPerUnit].includes(
          ""
        );

      default:
        break;
    }
  }

  const disabled = [pricingName, pricingUnit, baseAmount, costPerUnit].includes(
    ""
  );

  useEffect(() => {
    if (!open) {
      setInput({
        pricingName: "",
        pricingUnit: "",
        baseAmount: 0,
        costPerUnit: 0,
        // paymentAccount: "",
      });
      setStep(0);
      setSearchPricing(false);
    }
  }, [open]);

  // const paymentsURL = `${LEASE_URL}/company/pricing/paymentmeta`;

  // const { data: paymentsData } = useQuery("getPAYMENTMETA", () =>
  //   authorizedFetch(paymentsURL)
  // );

  // console.log(paymentAccount);

  // const fourDigitRegex = /^[0-9]{4}$/i;
  const pricingUrl = `${LEASE_URL}/company/pricing/all`;
  const { data: allpricingData } = useQuery("getPricing", () =>
    authorizedFetch(pricingUrl)
  );

  function handlePricingSearch(search: any) {
    const check = allpricingData?.data.find(
      (item: any) => item.name === search
    );
    if (check === undefined) {
      setSearchPricing(false);
    } else {
      setSearchPricing(true);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: 800,
          width: 1,
          "& .MuiInputBase-root": {
            fontSize: 14,
            borderRadius: 1,
            p: "3.5px 5px",
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        Add Pricing
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent sx={{ pb: "16px !important" }}>
        <Stepper
          sx={{ my: 4, mx: "auto", maxWidth: 534 }}
          activeStep={step}
          nonLinear
          alternativeLabel
        >
          {steps.map((label, i) => (
            <Step key={i}>
              <StepButton onClick={() => setStep(i)}>{label}</StepButton>
            </Step>
          ))}
        </Stepper>
        {step === 0 && (
          <>
            <Box sx={{ maxWidth: { xs: 280, sm: 560 }, mx: "auto" }}>
              <Typography sx={{ fontSize: "0.875rem" }}>
                All fields with ( * ) are required
              </Typography>
            </Box>
            <Box
              sx={{
                maxWidth: { xs: 280, sm: 560 },
                mx: "auto",
                py: 2,
                display: "grid",
                gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                gap: 3,
                "& .required": {
                  color: "red",
                },
              }}
            >
              <Box>
                <Typography className="label">
                  Pricing Name <span className="required">&#x2a;</span>
                </Typography>
                <TextField
                  fullWidth
                  autoComplete="off"
                  size="small"
                  value={pricingName}
                  placeholder="Pricing Name"
                  onChange={(e) => {
                    handleChange(
                      "pricingName",
                      e.target.value?.length > 0
                        ? e.target.value.replace(/[^a-zA-Z0-9-_ ]/, "")
                        : e.target.value
                    );
                    handlePricingSearch(e.target.value);
                  }}
                  inputProps={{ maxLength: 17 }}
                  error={searchPricing ? true : false}
                  helperText={
                    searchPricing ? "Pricing Name already exists" : ""
                  }
                />
              </Box>
              <Box>
                <Typography className="label">
                  Pricing Unit <span className="required">&#x2a;</span>
                </Typography>
                <Select
                  sx={{ height: 46 }}
                  fullWidth
                  value={pricingUnit}
                  onChange={(e: any) => {
                    handleChange("pricingUnit", e.target.value);
                  }}
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    <em>Select</em>
                  </MenuItem>
                  {["HOURLY", "DAILY", "WEEKLY", "MONTHLY"].map((option, i) => (
                    <MenuItem key={i} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box>
                <Typography className="label">
                  Base Amount <span className="required">&#x2a;</span>
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={baseAmount ? baseAmount : ""}
                  placeholder="Base Amount"
                  // onInput={(e: any) => {
                  //   e.target.value = Math.max(0, parseInt(e.target.value))
                  //     .toString()
                  //     .slice(0, 6);
                  // }}
                  onChange={(e) => {
                    if (e.target.value.length <= 6)
                      handleChange(
                        "baseAmount",
                        e.target.value?.length > 0
                          ? e.target.value.replace(/[^0-9]/, "")
                          : e.target.value
                      );
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ ml: 1 }}>
                        ₹
                      </InputAdornment>
                    ),
                  }}

                  // error={baseAmount.toString().length === 6 ? true : false}
                  // helperText={
                  //   baseAmount.toString().length === 6
                  //     ? "Maximum character limit reached"
                  //     : ""
                  // }
                />
              </Box>
              <Box>
                <Typography className="label">
                  Cost Per Unit <span className="required">&#x2a;</span>
                </Typography>
                <TextField
                  // onInput={(e: any) => {
                  //   e.target.value = Math.max(0, parseInt(e.target.value))
                  //     .toString()
                  //     .slice(0, 6);
                  // }}
                  // type="number"
                  fullWidth
                  size="small"
                  value={costPerUnit ? costPerUnit : ""}
                  placeholder="Cost Per Unit"
                  onChange={(e) => {
                    if (e.target.value.length <= 6)
                      handleChange(
                        "costPerUnit",
                        e.target.value?.length > 0
                          ? e.target.value.replace(/[^0-9]/, "")
                          : e.target.value
                      );
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ ml: 1 }}>
                        ₹
                      </InputAdornment>
                    ),
                  }}
                  // error={costPerUnit.toString().length === 6 ? true : false}
                  // helperText={
                  //   costPerUnit.toString().length === 6
                  //     ? "Max 6 digit length"
                  //     : ""
                  // }
                />
              </Box>
              {/* <Box gridColumn={{ sm: "span 2" }}>
                <Typography className="label">
                  Payment Account <span className="required">&#x2a;</span>
                </Typography>
                <Select
                  fullWidth
                  value={paymentAccount}
                  onChange={(e: any) => {
                    handleChange("paymentAccount", e.target.value);
                  }}
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    <em>Select</em>
                  </MenuItem>
                  {paymentList?.map((el: any) => (
                    <MenuItem key={el.id} value={el.id}>
                      {el.value}
                    </MenuItem>
                  ))}
                </Select>
              </Box> */}
            </Box>
          </>
        )}
        {step === 1 && (
          <Box
            sx={{
              maxWidth: 560,
              mx: "auto",
              "& .table": {
                borderCollapse: "collapse",
                width: 1,
                fontSize: 14,
                lineHeight: "16px",
                "& td": {
                  py: 1.25,
                  px: 2,
                },
                "& .bold": {
                  fontWeight: 500,
                },
                "& .header": {
                  px: 2,
                  py: 1,
                  position: "relative",
                  "& td": {
                    position: "absolute",
                    verticalAlign: "middle",
                    bgcolor: (theme) => theme.customColors.header,
                    width: 1,
                    borderRadius: "4px",
                    fontSize: 16,
                    fontWeight: 600,
                    "& span": {
                      display: "inline-block",
                      transform: "translateY(1px)",
                    },
                  },
                },
                "& .first > td": {
                  pt: 9,
                },
                "& .last > td": {
                  pb: 3,
                },
              },
            }}
          >
            <table className="table">
              <tbody>
                {[
                  { header: "Pricing Info", onEdit: () => setStep(0) },
                  { label: "Pricing Name", value: pricingName, required: true },
                  { label: "Pricing Unit", value: pricingUnit, required: true },
                  {
                    label: "Base Amount",
                    value: baseAmount ? `₹ ${baseAmount}` : "",
                    required: true,
                  },
                  {
                    label: "Cost Per Unit",
                    value: costPerUnit ? `₹ ${costPerUnit}` : "",
                    required: true,
                  },
                  // {
                  //   label: "Payment Account",
                  //   value: paymentAccount,
                  //   required: true,
                  // },
                ].map(({ header, onEdit, label, value, required }, i, arr) => {
                  const isFirst = arr[i - 1]?.header;
                  const isLast = !arr[i + 1] || arr[i + 1].header;

                  return (
                    <tr
                      key={i}
                      className={
                        header
                          ? "header"
                          : `${isFirst ? "first" : ""} ${isLast ? "last" : ""}`
                      }
                    >
                      {header ? (
                        <td colSpan={2}>
                          <span>{header}</span>
                          <IconButton
                            sx={{ ml: 1.5 }}
                            children={<EditOutlined />}
                            color="primary"
                            size="small"
                            onClick={onEdit}
                          />
                        </td>
                      ) : (
                        <>
                          <td>{label}</td>
                          <td className="bold">
                            {value ||
                              (required && (
                                <Box display="flex" alignItems="center">
                                  <ErrorOutline
                                    fontSize="small"
                                    color="error"
                                    style={{ marginRight: 8 }}
                                  />
                                  Required
                                </Box>
                              ))}
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {step !== 0 && (
          <Button variant="outlined" onClick={handleBack}>
            Back
          </Button>
        )}
        {step === 0 && (
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        )}
        <Button
          onClick={handleNext}
          variant={
            isComplete(step) || step === steps.length - 1
              ? "contained"
              : "outlined"
          }
          disableElevation
          disabled={
            // step === steps.length - 1
            // &&
            disabled
          }
        >
          {step === steps.length - 1 ? "Save" : "Next"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPricingDialog;
