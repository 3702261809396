import { Box, Skeleton, Typography } from "@mui/material";
import InfoLabel from "components/InfoLabel";
import { Fragment } from "react";
import { Doughnut } from "react-chartjs-2";

const colors = {
  Booked: "#E2445C",
  Available: "#66CCFF",
  Unavailable: "#FFCB00",
};

const ChargerAvailability = ({
  statsLoading: isLoading,
  statsData: data,
  disableTotal,
}: any) => {
  const totalChargers = data?.data?.stats?.totalChargers || 0;
  const bookedChargers = data?.data?.stats?.totalBooked || 0;
  const availableChargers = data?.data?.stats?.totalAvailable || 0;
  const unavailableChargers = data?.data?.stats?.totalUnavailable || 0;

  function format(n: number) {
    return n.toLocaleString();
  }

  return (
    <>
      <Box
        sx={{
          minWidth: { xs: 0, md: 360 },
          mb: { xs: 2, md: 7.5 },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "flex-start", md: "center" },
        }}
      >
        <Typography variant="h3" mr={2.5} mb={{ xs: 2.5, md: 0 }}>
          Charger Availability
        </Typography>
        {!disableTotal ? (
          isLoading ? (
            <Skeleton
              variant="rectangular"
              width={95}
              height={28}
              sx={{ borderRadius: 10 }}
            />
          ) : (
            <InfoLabel label="Total" value={format(totalChargers)} />
          )
        ) : null}
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "140px auto",
            md: "minmax(0, 265px) auto",
          },
          gap: { xs: 3, md: 4 },
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              width: 1,
              position: "relative",
              pt: "100%",
            }}
          >
            <Skeleton
              variant="circular"
              sx={{
                position: "absolute",
                top: 0,
                width: 1,
                height: 1,
              }}
            />
          </Box>
        ) : (
          <Box width={1} alignSelf="center" position="relative">
            <Doughnut
              style={{ position: "relative", zIndex: 2 }}
              data={(canvas) => {
                return {
                  datasets: [
                    {
                      data: [
                        bookedChargers,
                        availableChargers,
                        unavailableChargers,
                      ],
                      backgroundColor: Object.values(colors),
                      hoverBackgroundColor: Object.values(colors),
                      borderWidth: 0,
                      cutout: "85%",
                      hoverOffset: 3,
                    },
                  ],
                  labels: ["Booked", "Available", "Unavailable"],
                };
              }}
              options={{
                layout: {
                  padding: 5,
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    displayColors: false,
                  },
                },
              }}
            />
            <Box
              sx={{
                zIndex: 1,
                position: "absolute",
                top: "35%",
                right: 0,
                left: 0,
                mx: "auto",
                pointerEvents: "none",
                textAlign: "center",
                color: "text.secondary",
              }}
            >
              <Typography fontSize={{ xs: 18, md: 32 }} fontWeight={700}>
                {format(totalChargers)}
              </Typography>
              <Typography fontSize={14}>Total</Typography>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            width: 1,
            minWidth: 0,
            maxWidth: 210,
            justifySelf: "end",
            height: "fit-content",
            ml: 2,
            display: "grid",
            gridTemplateColumns: "1fr auto",
            rowGap: 4,
            columnGap: 1,
            fontSize: { xs: 12, md: 16 },
            "& .label": {
              position: "relative",
              "&::before": {
                content: '""',
                position: "absolute",
                mt: 0.25,
                transform: "translateX(-15.96px)",
                borderRadius: 10,
                width: "6.39px",
                height: "17.89px",
              },
              "&.Booked::before": {
                bgcolor: colors.Booked,
              },
              "&.Available::before": {
                bgcolor: colors.Available,
              },
              "&.Unavailable::before": {
                bgcolor: colors.Unavailable,
              },
            },
            "& .value": {
              // textAlign: "right",
              fontWeight: 700,
            },
          }}
        >
          {[
            {
              label: "Booked",
              value: format(bookedChargers),
            },
            {
              label: "Available",
              value: format(availableChargers),
            },
            {
              label: "Unavailable",
              value: format(unavailableChargers),
            },
          ].map((el, i) => (
            <Fragment key={i}>
              {isLoading ? (
                <Skeleton width={150} />
              ) : (
                <span className={`label ${el.label}`}>{el.label}</span>
              )}
              {isLoading ? (
                <Skeleton width={20} />
              ) : (
                <span className="value">{el.value}</span>
              )}
            </Fragment>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default ChargerAvailability;
