import { useEffect, useState } from "react";
import List from "./List";
import { appBar, drawer } from "utils";

import AppBar from "./AppBar";
import Cards from "./Cards";
import { Box } from "@mui/material";

const Users = () => {
  const [tab, setTab] = useState<"users" | "hosts" | "CUGs">("users");

  useEffect(() => {
    appBar.open(<AppBar />);

    return () => {
      appBar.close();
      drawer.close();
    };
  }, []);

  return (
    <Box
      sx={{
        px: { xs: 2, md: 7 },
        pt: { xs: 2, md: 4.75 },
        pb: { xs: 3, md: 7 },
      }}
    >
      <Cards {...{ tab }} />
      <List {...{ tab, setTab }} />
    </Box>
  );
};

export default Users;
