import { DeleteOutline, EditOutlined, InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Hidden,
  IconButton,
  Paper,
  Tooltip,
} from "@mui/material";

import Table from "components/Table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  appBar,
  authorizedFetch,
  drawer,
  // getPermissions,
  setLoader,
  snackbar,
} from "utils";
import CreateDialog from "./CreateDialog";
import EditDialog from "./EditDialog";
import InfoDrawer from "./InfoDrawer";

import { RETAIL_URL } from "utils/constants";
import Search from "components/Search";
import AppBar from "./AppBar";

const Geofence = () => {
  // TODO: update later
  // const { canWrite } = getPermissions("rental:geofence");

  const [type, setType] = useState(0);
  const [createDialog, setCreateDialog] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [search, setSearch] = useState("");

  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    data: [],
  });
  const [editDialog, setEditDialog] = useState({
    open: false,
    data: [],
  });

  const url = `${RETAIL_URL}/fence?orderBy=createdAt&ascending=false&ownerType="COMPANY"?first=${pageSize}&skip=${
    pageSize * (page - 1)
  }&name=${search}&type=${
    type === 0 ? "" : type === 1 ? "CIRCULAR" : "POLYGONAL"
  }`;
  const {
    isLoading,
    data: fenceData,
    refetch: refetchFences,
  } = useQuery(["getFences", page, pageSize, type, search], () =>
    authorizedFetch(url)
  );

  const countUrl = `${RETAIL_URL}/fence/count`;
  const {
    // isLoading: countLoading,
    data: countData,
    refetch: refetchCount,
  } = useQuery(["getCount"], () => authorizedFetch(countUrl));

  useEffect(() => {
    if (search) {
      setPage(1);
      setPageSize(10);
    }
  }, [search]);

  useEffect(() => {
    return () => {
      drawer.close();
    };
  }, []);

  const [counts, setCounts] = useState({
    all: 0,
    circular: 0,
    polygonal: 0,
  });

  useEffect(() => {
    if (countData) {
      const all: any[] = countData?.data?.filter((el: any) => {
        return el.type === "all";
      });
      const circular: any[] = countData?.data?.filter((el: any) => {
        return el.type === "CIRCULAR";
      });
      const polygonal: any[] = countData?.data?.filter((el: any) => {
        return el.type === "POLYGONAL";
      });

      setCounts({
        all: all.length ? all[0]?.total : 0,
        circular: circular.length ? circular[0]?.total : 0,
        polygonal: polygonal.length ? polygonal[0]?.total : 0,
      });
    }
  }, [countData]);

  useEffect(() => {
    appBar.open(<AppBar {...{ setCreateDialog, counts, type, setType }} />);
    return () => {
      appBar.close();
    };
  }, [counts, type]);

  return (
    <>
      <Paper
        sx={{
          width: 1,
          boxShadow: (theme) => theme.customShadows.small,
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            width: 1,
            p: 3,
            pb: 2.75,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* <Box width="fit-content">
            <Box display="flex" width={1}>
              <Tooltip title="Geofence Status" placement="top-start">
                <FormControl sx={{ mr: 2 }}>
                  <Select
                    size="small"
                    sx={{ width: "auto" }}
                    value={type}
                    onChange={(e: any) => setType(e.target.value)}
                    startAdornment={<Sort sx={{ mr: 1 }} />}
                    renderValue={(selected) =>
                      selected === 0
                        ? "Status : All"
                        : selected === 1
                        ? "Status: Circular"
                        : "Status: Polygonal"
                    }
                  >
                    <MenuItem value={0}>
                      All
                      <span style={{ fontSize: 10, marginLeft: 5 }}>
                        ({counts?.all})
                      </span>
                    </MenuItem>
                    <MenuItem value={1}>
                      Circular
                      <span style={{ fontSize: 10, marginLeft: 5 }}>
                        ({counts?.circular})
                      </span>
                    </MenuItem>
                    <MenuItem value={2}>
                      Polygonal
                      <span style={{ fontSize: 10, marginLeft: 5 }}>
                        ({counts?.polygonal})
                      </span>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Tooltip>
            </Box>
          </Box> */}
          <Box display="flex" width={1} mr={2}>
            <Hidden mdDown>
              <Box ml="auto" mr={0}>
                <Search
                  handleSearch={(value) => {
                    setSearch(value);
                  }}
                  persist
                  enableClear
                />
              </Box>
            </Hidden>
          </Box>
          {/* {canWrite && (
            <Button
              sx={{ height: 40, width: 250, textTransform: "none" }}
              startIcon={<Add />}
              color="primary"
              variant="contained"
              onClick={() => setCreateDialog(true)}
            >
              Create Geofence
            </Button>
          )} */}
        </Box>

        <Table
          rowCount={counts?.all || 0}
          serverSidePagination={true}
          activePage={page}
          activePageSize={pageSize}
          onPageChange={(value) => setPage(value)}
          onPageSizeChange={(value) => setPageSize(value)}
          loading={isLoading}
          rows={fenceData?.data || []}
          idKey="id"
          columns={[
            {
              key: "name",
              label: "Fence Name",
            },
            {
              key: "type",
              label: "Type",
              Render: (row) => {
                return (
                  <Box>
                    {row.type === "CIRCULAR" ? "Circular" : "Polygonal"}
                  </Box>
                );
              },
            },

            {
              key: "createdAt",
              label: "Created At",
              format: (value) => moment(value).format("ddd, MMM DD, YYYY"),
            },

            {
              key: "actions",
              label: "Actions",
              Render: (row) => (
                <>
                  <Tooltip title="Info" followCursor>
                    <IconButton
                      size="small"
                      sx={{
                        color: (theme: any) => theme.customColors.grey,
                        mr: 0.5,
                      }}
                      onClick={() => drawer.open(<InfoDrawer data={row} />)}
                      children={<InfoOutlined fontSize="small" />}
                    />
                  </Tooltip>
                  <Tooltip title="Edit" followCursor>
                    <IconButton
                      size="small"
                      sx={{ color: (theme) => theme.customColors.grey }}
                      onClick={() => setEditDialog({ open: true, data: row })}
                    >
                      <EditOutlined fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete" followCursor>
                    <IconButton
                      size="small"
                      sx={{ color: (theme) => theme.customColors.grey }}
                      onClick={() => setDeleteDialog({ open: true, data: row })}
                    >
                      <DeleteOutline fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </>
              ),
            },
          ]}
        />
      </Paper>
      <CreateDialog
        open={createDialog}
        handleClose={() => setCreateDialog(false)}
        refetchFences={refetchFences}
        refetchCount={refetchCount}
      />
      <EditDialog
        open={editDialog.open}
        handleClose={() => setEditDialog({ ...editDialog, open: false })}
        data={editDialog.data}
        refetchFences={refetchFences}
        refetchCount={refetchCount}
      />
      <DeleteDialog
        open={deleteDialog.open}
        data={deleteDialog.data}
        handleClose={() => {
          setDeleteDialog({ ...deleteDialog, open: false });
        }}
        refetchFences={refetchFences}
        refetchCount={refetchCount}
      />
    </>
  );
};

const DeleteDialog = ({
  open,
  handleClose,
  data,
  refetchFences,
  refetchCount,
}: any) => {
  const addURL = `${RETAIL_URL}/fence/${data?._id}`;

  const addFencesMutation = useMutation(
    "addFences",
    () =>
      authorizedFetch(addURL, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          // token: "1234",
        },
      }),
    {
      onSuccess: () => {
        setLoader(false);
        refetchFences();
        refetchCount();
        snackbar.success(`Fence deleted`);
        handleClose();
      },
      onError: () => {
        setLoader(false);
        snackbar.error(`Error deleting fence`);
      },
    }
  );
  function handleDelete() {
    setLoader(true);
    addFencesMutation.mutate();
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Delete Geofence</DialogTitle>
      <DialogContent className="py-1">
        Are you sure you want to delete "<b>{data?.name}</b>"?
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleDelete}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Geofence;
