import { DeleteOutline, EditOutlined, HighlightOff } from "@mui/icons-material";
import { Avatar, Box, IconButton } from "@mui/material";
import CircularLoader from "components/CircularLoader";
import { Fragment, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { authorizedFetch, getDarkModePreference, GlobalState } from "utils";
import { RETAIL_URL } from "utils/constants";
import AddModelDialog from "../../../Common/AddModelDialog";
import DeleteDialog from "./DeleteDialog";

export function getModelConfigLabel(key: string) {
  switch (key) {
    case "speedDivisor":
      return "Speed Divisor";
    case "odoDivisor":
      return "Odometer Divisor";
    case "accessType":
      return "Access Type";
    case "wheelDiameter":
      return "Wheel Diameter";
    case "maxSpeed":
      return "Max Speed";
    case "speedLimit":
      return "Speed Limit";
    case "pickupControlLimit":
      return "Pickup Control Limit";
    case "brakeRegenLimit":
      return "Brake Regeneration Limit";
    case "zeroThrottleRegenLimit":
      return "Zero Throttle Limit";
    case "currentLimit":
      return "Current Limit";
    case "overVoltageLimit":
      return "Controller Over Voltage Limit";
    case "underVoltageLimit":
      return "Controller Under Voltage Limit";
    case "batteryMinVoltage":
      return "Battery Min Voltage Limit";
    case "batteryMaxVoltage":
      return "Battery Max Voltage Limit";
    case "hillAssistStatus":
      return "Hill Assist";
    case "parkingStatus":
      return "Parking Status";
    case "regenBrakingStatus":
      return "Regenerative Braking";
    case "eabsStatus":
      return "E-ABS Status";
    default:
      return key;
  }
}

const ModelDrawer = ({ model, handleClose }: any) => {
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );

  const [editDialog, setEditDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const url = `${RETAIL_URL}/assembly/model/${model?._id}`;
  const { isLoading, data } = useQuery(
    ["getModel", model?._id],
    () => authorizedFetch(url),
    { enabled: !!model?._id }
  );

  const modelInfo = data?.data?.constructor === Array ? data.data[0] : {};

  let components: any = {};
  let componentsArr =
    modelInfo.components?.constructor === Array ? modelInfo.components : [];

  componentsArr.forEach((el: any) => {
    components[el.category] = el.modelId
      .map((device: any) => device.key)
      .join(", \n");
  });

  const modelDetails: any = {
    "Basic Info": {
      Name: modelInfo.name,
      Protocol: modelInfo.protocol,
      Type:
        modelInfo.type === "TWO"
          ? "Two Wheeler"
          : modelInfo.type === "THREE"
          ? "Three Wheeler"
          : modelInfo.type,
      Status: modelInfo.status,
      "In Stock": modelInfo.vehicles?.length,
    },
    ...(modelInfo.components?.constructor === Array &&
    modelInfo.components.length > 0
      ? {
          Devices: components,
        }
      : {}),
    ...(modelInfo.config?.configs
      ? {
          Configuration: modelInfo.config.configs || {},
        }
      : {}),
  };

  return (
    <>
      <AddModelDialog
        open={editDialog}
        handleClose={() => setEditDialog(false)}
        isEditMode
        model={modelInfo}
        onEdit={handleClose}
        isRental={false}
      />
      <DeleteDialog
        open={deleteDialog}
        handleClose={() => setDeleteDialog(false)}
        model={modelInfo}
        onDelete={handleClose}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            px: 3,
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: isDarkMode ? "#000" : "#3D3D3D",
            fontWeight: 500,
            color: "#fff",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {model?.name}
          </Box>
          <Box display="flex">
            <IconButton
              sx={{ mr: 1 }}
              children={<EditOutlined />}
              color="inherit"
              size="small"
              onClick={() => setEditDialog(true)}
            />
            <IconButton
              sx={{ mr: 1 }}
              children={<DeleteOutline />}
              color="inherit"
              size="small"
              onClick={() => setDeleteDialog(true)}
            />
            <IconButton
              children={<HighlightOff />}
              color="inherit"
              size="small"
              onClick={handleClose}
            />
          </Box>
        </Box>
        <Box flexGrow={1} overflow="auto">
          {isLoading ? (
            <Box p={3}>
              <CircularLoader />
            </Box>
          ) : (
            <Box
              sx={{
                px: 3,
                py: 2,
                "& .table": {
                  borderCollapse: "collapse",
                  width: 1,
                  fontSize: 14,
                  lineHeight: "16px",
                  "& td": {
                    py: 1.25,
                    px: 2,
                    whiteSpace: "pre-line",
                  },
                  "& .bold": {
                    fontWeight: 500,
                  },
                  "& .header": {
                    px: 2,
                    py: 1,
                    position: "relative",
                    "& td": {
                      position: "absolute",
                      verticalAlign: "middle",
                      backgroundColor: (theme: any) =>
                        theme.customColors.header,
                      width: 1,
                      borderRadius: "4px",
                      fontSize: 16,
                      fontWeight: 600,
                      "& .label": {
                        display: "inline-block",
                        transform: "translateY(1px)",
                        py: 1.125,
                      },
                    },
                  },
                  "& .first > td": {
                    pt: 9,
                  },
                  "& .last > td": {
                    pb: 2.75,
                  },
                },
              }}
            >
              <table className="table">
                <tbody>
                  {Object.keys(modelDetails).map((category: any, i: number) => (
                    <Fragment key={i}>
                      <tr className="header">
                        <td colSpan={2}>
                          <span className="label">{category}</span>
                        </td>
                      </tr>
                      {Object.keys(modelDetails[category]).map(
                        (label: any, i: number, arr) => {
                          let value = modelDetails[category][label];
                          return (
                            <tr
                              key={i}
                              className={
                                (i === 0 ? "first " : "") +
                                (i === arr.length - 1 ? "last" : "")
                              }
                            >
                              <td>{getModelConfigLabel(label)}</td>
                              <td className="bold">
                                {label === "Status" ? (
                                  <Avatar
                                    variant="status"
                                    className={
                                      value !== "ACTIVE" ? "yellow" : ""
                                    }
                                  >
                                    {value}
                                  </Avatar>
                                ) : value === true ? (
                                  "Enabled"
                                ) : value === false ? (
                                  "Disabled"
                                ) : value === "" ? (
                                  "-"
                                ) : typeof value === "object" ? (
                                  JSON.stringify(value)
                                ) : (
                                  value
                                )}
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ModelDrawer;
