import { Business } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { login } from "actions";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { setLoader, snackbar } from "utils";
import { AUTH_URL, subdomain } from "utils/constants";
import { countries } from "utils/countries";
import { Heading } from ".";

const CompanySignUp = ({ match }: any) => {
  const dispatch = useDispatch();
  const [isLinkExpired, setExpired] = useState(false);

  const formTemplate = {
    companyName: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    password: "",
    confirmPass: "",
    gstNumber: "",
    panNumber: "",
  };
  const [form, setForm] = useState({ ...formTemplate });
  const [countryCode, setCountryCode] = useState(
    countries.find((el) => el.name === "India")
  );
  const [showPass, setShowPass] = useState(false);
  const [valid, setValid] = useState(true);
  const [signUpError, setSignUpError] = useState(null);

  const {
    companyName,
    firstName,
    lastName,
    email,
    phone,
    password,
    confirmPass,
    address,
    gstNumber,
    panNumber,
  } = form;

  function handleChange(key: string, value: string) {
    setForm((prev) => ({ ...prev, [key]: value }));
  }

  useEffect(() => {
    const { inviteData } = match.params;
    try {
      let parsed: any = jwt_decode(inviteData);
      let { name, email, exp } = parsed;
      if (moment(exp * 1000) < moment()) setExpired(true);
      handleChange("email", email || "");
      handleChange("companyName", name || "");
    } catch (err) {
      console.error(err);
    }
  }, [match.params]);

  function validatePassword() {
    if (password === "" && confirmPass === "") return "Enter a password";
    else if (password !== confirmPass) return "Passwords don't match";
    else if (password.length < 8)
      return "Use 8 characters or more for your password";
    else if (
      !/[A-Za-z]/.test(password) ||
      !/[0-9]/.test(password) ||
      !/[^A-Za-z0-9]/.test(password)
    )
      return "Please choose a stronger password. Try a mix of letters, numbers, and symbols.";
    else return null;
  }

  function handleSubmit() {
    if ([firstName, lastName, email, phone].includes("") || validatePassword())
      setValid(false);
    else {
      setValid(true);
      setSignUpError(null);
      setLoader(true);
      fetch(`${AUTH_URL}/company/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          code: match.params.inviteData,
        },
        body: JSON.stringify({
          user: {
            firstName,
            lastName,
            phone: "+" + countryCode?.code + phone,
            password,
          },
          company: {
            address,
            gstNumber,
            panNumber,
          },
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.status >= 409) {
            setLoader(false);
            setSignUpError(response.message);
          } else if (response.status === 201) {
            snackbar.success("Registered successfully. Logging in...");
            dispatch(login({ email, password }));
          }
        });
    }
  }
  return (
    <>
      <Helmet>
        <title>Sign up - {subdomain.name}</title>
      </Helmet>
      {isLinkExpired ? (
        <Alert severity="error">Invite has expired.</Alert>
      ) : (
        <form
          style={{ width: "100%", maxWidth: 450 }}
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Box display="grid">
            <Heading value="Sign up with Bolt.Earth" />
            <Alert severity="info" icon={<Business />}>
              {/* Signing up for <b>{email}</b> */}
              <AlertTitle sx={{ mb: 0 }}>{companyName}</AlertTitle>
            </Alert>
            <Typography mt={2} fontWeight={500}>
              Admin Details
            </Typography>
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr"
              mt={2.5}
              gap={3}
              columnGap={2.5}
            >
              <TextField
                size="small"
                value={firstName}
                onChange={(e) => handleChange("firstName", e.target.value)}
                fullWidth
                label="First Name"
                error={!valid && firstName === ""}
                helperText={!valid && firstName === "" && "Enter first name"}
              />
              <TextField
                size="small"
                value={lastName}
                onChange={(e) => handleChange("lastName", e.target.value)}
                fullWidth
                label="Last Name"
                error={!valid && lastName === ""}
                helperText={!valid && lastName === "" && "Enter last name"}
              />
              <TextField
                size="small"
                value={email}
                fullWidth
                label="Email"
                sx={{
                  gridColumn: "span 2",
                  caretColor: "transparent",
                }}
              />
              <Box
                sx={{
                  gridColumn: "span 2",
                  display: "grid",
                  gridTemplateColumns: "auto 1fr",
                  gap: 2,
                }}
              >
                <Autocomplete
                  sx={{ width: 180 }}
                  size="small"
                  options={countries}
                  autoHighlight
                  getOptionLabel={(option) =>
                    `${option.icon} ${option.name} (+${option.code})`
                  }
                  value={countryCode}
                  onChange={(e, newValue) => setCountryCode(newValue)}
                  disableClearable
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.abbr.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.abbr.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.name} (+{option.code})
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country Code"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                <TextField
                  size="small"
                  value={phone}
                  onChange={(e) => handleChange("phone", e.target.value)}
                  fullWidth
                  label="Phone"
                  name="phone"
                  error={!valid && phone === ""}
                  inputProps={{
                    maxLength: 10,
                  }}
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="start">+91</InputAdornment>
                  //   ),
                  // }}
                  helperText={
                    !valid &&
                    phone === "" &&
                    "Enter a valid 10-digit phone number"
                  }
                />
              </Box>
              <TextField
                size="small"
                value={password}
                onChange={(e) => handleChange("password", e.target.value)}
                fullWidth
                label="Password"
                type={showPass ? "text" : "password"}
                autoComplete="new-password"
                error={
                  !valid && (password === "" || Boolean(validatePassword()))
                }
              />
              <TextField
                size="small"
                value={confirmPass}
                onChange={(e) => handleChange("confirmPass", e.target.value)}
                fullWidth
                label="Confirm"
                type={showPass ? "text" : "password"}
                autoComplete="new-password"
                error={
                  !valid && (confirmPass === "" || Boolean(validatePassword()))
                }
              />
            </Box>
            <Typography
              variant="body2"
              sx={{
                pt: 1,
                px: 1.75,
                color: !valid ? "error.main" : "text.secondary",
              }}
            >
              {!valid
                ? validatePassword()
                : validatePassword()
                ? "Use 8 or more characters with a mix of letters, numbers & symbols"
                : null}
            </Typography>
            <FormControlLabel
              control={<Checkbox size="small" />}
              value={showPass}
              onChange={() => setShowPass((value) => !value)}
              label="Show password"
            />
            <Typography mt={2} fontWeight={500}>
              Company details (Optional)
            </Typography>
            <Box
              sx={{
                mt: 2.5,
                mb: 1,
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: 3,
                columnGap: 2.5,
              }}
            >
              <TextField
                size="small"
                value={gstNumber}
                onChange={(e) => handleChange("gstNumber", e.target.value)}
                fullWidth
                label="GSTIN"
              />
              <TextField
                size="small"
                value={panNumber}
                onChange={(e) => handleChange("panNumber", e.target.value)}
                fullWidth
                label="PAN"
              />
              <TextField
                multiline
                minRows={2}
                value={address}
                onChange={(e) => handleChange("address", e.target.value)}
                label="Address"
                name="address"
                sx={{ gridColumn: "span 2" }}
              />
            </Box>
            <Box mt={2}>
              {signUpError && (
                <Typography mb={1} color="error" variant="body2">
                  {signUpError === "EMAIL/PHONE CONFLICT WITH EXISTING USER"
                    ? "The phone number is already in use by another account."
                    : signUpError}
                </Typography>
              )}
              <Button
                sx={{ mt: 1 }}
                type="submit"
                variant="contained"
                size="large"
              >
                Sign up
              </Button>
              {/* <Button size="large" onClick={() => setView("sign-in")}>
                Sign in instead
              </Button> */}
            </Box>
          </Box>
        </form>
      )}
    </>
  );
};

export default CompanySignUp;
