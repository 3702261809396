import { Box, IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import { drawer, getDarkModePreference, GlobalState } from "utils";

import { HighlightOff } from "@mui/icons-material";
import TableComponent from "components/Table";
import moment from "moment";

const NotificationsDrawer: any = ({ notifications, onClose }: any) => {
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: 1,
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          px: 3,
          py: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: isDarkMode ? "#000" : "#3D3D3D",
          fontWeight: 500,
          color: "#fff",
        }}
      >
        Notification Log
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          size="small"
          onClick={() => (onClose ? onClose() : drawer.close())}
        />
      </Box>
      <Box flexGrow={1} overflow="auto" pt={1}>
        <TableComponent
          small
          height={1}
          px={1}
          rows={notifications}
          columns={[
            {
              key: "mesg",
              label: "Message",
            },
            {
              key: "time",
              label: "Time",
              format: (value) => moment(value).calendar(),
            },
          ]}
        />
      </Box>
    </Box>
  );
};

export default NotificationsDrawer;
