import { AddRounded, ChevronRight } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import Search from "components/Search";
import ViewSelector from "components/ViewSelector";
import { getPermissions } from "utils";

const AppBar = ({
  view,
  modelFilter,
  setView,
  tab,
  setAddModelDialog,
  updateStatus,
  setUpdateStatus,
  setModelSearch,
}: any) => {
  const { canWrite } = getPermissions("retail:assembly");
  return (
    <Box
      sx={{
        width: 1,
        py: 3,
        px: { xs: 2, md: 7 },
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box display="flex" alignItems="center" justifyContent={"center"}>
        <Typography variant="h2">Vehicles</Typography>
        {view === "grid" ? (
          <>
            <ChevronRight sx={{ mx: 1 }} />
            <Typography>Models</Typography>
          </>
        ) : modelFilter ? (
          <>
            <ChevronRight sx={{ mx: 1 }} />
            Models <ChevronRight sx={{ mx: 1 }} />
            {modelFilter.name}
          </>
        ) : (
          <>
            <ChevronRight sx={{ mx: 1 }} />
            <Typography>All Vehicles</Typography>
          </>
        )}
      </Box>
      <Box sx={{ display: "flex" }}>
        {view === "list" && tab !== 3 && (
          <Tooltip title="Updates">
            <FormControl size="small" sx={{ mr: 3 }}>
              <Select
                className="shadow rounded"
                sx={{ width: 290 }}
                value={updateStatus}
                onChange={(e: any) => setUpdateStatus(e.target.value)}
                // startAdornment={<Sort sx={{ mr: 1 }} />}
                renderValue={(selected) =>
                  selected === 0
                    ? "Updates : All"
                    : selected === 1
                    ? "Updates: Up To Date"
                    : "Updates: Update Pending"
                }
              >
                <MenuItem value={0}>All</MenuItem>
                <MenuItem value={1}>Up To Date</MenuItem>
                <MenuItem value={2}>Update Pending</MenuItem>
              </Select>
            </FormControl>
          </Tooltip>
        )}
        {view === "grid" && (
          <Box sx={{ mr: 2 }}>
            <Search
              handleSearch={(input) => setModelSearch(input)}
              enableClear
              persist
              placeholder="Search Models..."
            />
          </Box>
        )}

        {canWrite && (
          <Tooltip title="Add Model">
            <Button
              sx={{
                // width: { md: 300 },
                px: 6,
                py: 1,
                mr: 2,
                borderRadius: 10,
                borderWidth: "1.5px !important",
                borderColor: (theme) => theme.palette.primary.main,
                textTransform: "none",
                fontWeight: 500,
              }}
              variant="outlined"
              startIcon={<AddRounded />}
              onClick={() => setAddModelDialog(true)}
            >
              Add New
            </Button>
          </Tooltip>
        )}

        <ViewSelector view={view} setView={setView} />
      </Box>
    </Box>
  );
};

export default AppBar;
