import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const RemapDialog = ({ open, message, onConfirm, onClose }: any) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Remap Device(s)?</DialogTitle>
      <DialogContent>
        {message}.<br />
        Do you want to remap the device(s)?
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>No</Button>
        <Button variant="contained" onClick={onConfirm}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemapDialog;
