import { Box, Button, Paper, Typography, useTheme } from "@mui/material";
import { SxProps } from "@mui/system";
import { useState, useMemo, useEffect } from "react";
import { Doughnut, Bar } from "react-chartjs-2";
import InsertChartOutlinedTwoToneIcon from "@mui/icons-material/InsertChartOutlinedTwoTone";
import DonutLargeOutlinedIcon from "@mui/icons-material/DonutLargeOutlined";
import { getDarkModePreference, GlobalState } from "utils";
import { useSelector } from "react-redux";

interface GraphCardProps {
  id?: number;
  type: String;
  title: String;
  color: String;
  sx?: SxProps;
  showDateFilter?: boolean;
  data?: any;
  setType: Function;
}

const GraphCard: React.FC<GraphCardProps> = ({
  id = 1,
  type = "line",
  title = "Title",
  color = "#538ADC",
  sx = {},
  showDateFilter = true,
  data,
  setType,
}) => {
  const [doughnutdata, setdoughntdata] = useState([]);

  const theme = useTheme();
  const colors = color;

  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );

  const colorArr: any = useMemo(
    () => [
      theme.customColors.greenSecondary,
      theme.customColors.blueSecondary,
      theme.customColors.greySecondary,
      theme.customColors.orangeSecondary,
      theme.customColors.redSecondary,
      theme.customColors.yellowSecondary,
    ],
    [theme.customColors]
  );

  const Chart: any = useMemo(() => {
    switch (type) {
      // case "line":
      //   return Line;
      case "doughnut":
        return Doughnut;
      case "bar":
        return Bar;
    }
  }, [type]);

  const [vehOverview, setVehOverview] = useState<any>([]);
  useEffect(() => {
    if (data) {
      let temparr = [];
      setVehOverview(data);
      if (id === 1) {
        temparr = data
          ?.map((obj: any, i: number) => ({
            value: obj.name.slice(0, 13),
            label: obj.soldCount,
            color: colorArr[i],
          }))
          .slice(0, 6);
      }
      if (id === 2) {
        temparr = data
          ?.map((obj: any, i: number) => ({
            value: obj.model.name.slice(0, 13),
            label: obj.count,
            color: colorArr[i],
          }))
          .slice(0, 6);
      }
      if (id === 3) {
        temparr = data
          ?.map((obj: any, i: number) => ({
            value: obj.label.slice(0, 13),
            label: obj.count,
            color: colorArr[i],
          }))
          .slice(0, 6);
      }
      console.log(temparr, "temparrrr");
      setdoughntdata(temparr);
    }
  }, [data, id, colorArr]);

  const typeData = () => {
    console.log(vehOverview, "overview");
    let graphData = [];
    let labels = [];
    let temparr = [];
    if (id === 1) {
      graphData = vehOverview?.map((obj: any) => obj.soldCount).slice(0, 6);
      labels = vehOverview
        ?.map((obj: any) => obj.name.slice(0, 13))
        .slice(0, 6);
      temparr = vehOverview
        ?.map((obj: any, i: number) => ({
          value: obj.name,
          label: obj.soldCount,
          color: colorArr[i],
        }))
        .slice(0, 6);
    }
    if (id === 2) {
      graphData = vehOverview?.map((obj: any) => obj.count).slice(0, 6);
      labels = vehOverview
        ?.map((obj: any) => obj.model.name.slice(0, 13))
        .slice(0, 6);
      temparr = vehOverview
        ?.map((obj: any, i: number) => ({
          value: obj.model.name,
          label: obj.count,
          color: colorArr[i],
        }))
        .slice(0, 6);
    }
    if (id === 3) {
      graphData = vehOverview?.map((obj: any) => obj.count).slice(0, 6);
      labels = vehOverview
        ?.map((obj: any) => obj.label.slice(0, 13))
        .slice(0, 6);
      temparr = vehOverview
        ?.map((obj: any, i: number) => ({
          value: obj.label,
          label: obj.count,
          color: colorArr[i],
        }))
        .slice(0, 6);
    }

    console.log(temparr, "temparrrr");
    // setdoughntdata(temparr)

    // const data =
    //   id === 1
    //     ? vehOverview.inventory?.map((inventory: any) => inventory.count)
    //     : vehOverview.distribution?.map((dist: any) => dist.count);
    console.log("data of dist", graphData);
    //  [50, 25, 20, 75, 50, 80, 70, 80, 15, 37];
    // const labels =
    //   id === 1
    //     ? vehOverview.inventory?.map((inventory: any) => inventory.model)
    //     : vehOverview.distribution?.map((dist: any) => dist.status);
    console.log("data of dist labels", labels);
    const defaultData = {
      labels,
      datasets: [
        {
          fill: true,
          data: graphData,
          borderWidth: 0,
          backgroundColor: colors,
        },
      ],
    };

    switch (type) {
      case "doughnut":
        const chartData = {
          ...defaultData,
          datasets: defaultData.datasets.map((oneData: any) => ({
            ...oneData,

            // backgroundColor: type === "line" ? "transparent" : color,
            backgroundColor: colorArr,
            spacing: 1,
            hoverOffset: 0,
            borderWidth: 0,
            borderRadius: 50,
            cutout: "80%",
          })),
        };
        return { ...chartData };
      // return {
      //   datasets: [
      //     {
      //       data: [3, 10],
      //       backgroundColor: [
      //         theme.customColors.greenSecondary,
      //         theme.customColors.blueSecondary,
      //       ],
      //       spacing: 1,
      //       hoverOffset: 0,
      //       borderWidth: 0,
      //       borderRadius: 50,
      //       cutout: "80%",
      //     },
      //   ],
      //   labels: ["Assigned Vendors", "Not Assigned"],
      // };
      // case "line": {
      //   const chartData = {
      //     ...defaultData,
      //     datasets: defaultData.datasets.map((oneData: any) => ({
      //       ...oneData,
      //       borderColor: color,
      //       borderWidth: 2,
      //       backgroundColor: alpha(
      //         theme.customColors.blueSecondary,
      //         isDarkMode ? 0.1 : 0.2
      //       ),
      //       tension: 0,
      //       pointRadius: 0,
      //       pointHoverRadius: 4,
      //       pointHoverBackgroundColor: "#fff",
      //       pointHoverBorderWidth: 3,
      //     })),
      //   };
      //   return { ...chartData };
      // }
      case "bar": {
        // default: {
        const chartData = {
          ...defaultData,
          datasets: defaultData.datasets.map((oneData: any) => ({
            ...oneData,
            borderColor: colors,
            borderWidth: 3,
            lineTension: 0.2,
            // backgroundColor: type === "line" ? "transparent" : color,
            backgroundColor: colors,
            pointHoverRadius: 4,
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderWidth: 3,
            borderRadius: 5,
            barPercentage: 0.6,
            maxBarThickness: 25,
          })),
        };
        return { ...chartData };
      }
      // case "bar" : {
      //   // default: {
      //     const chartData = {
      //       ...defaultData,
      //       datasets: defaultData.datasets.map((oneData: any) => ({
      //         ...oneData,
      //         borderColor: colors.primary,
      //         borderWidth: 3,
      //         lineTension: 0.2,
      //        // backgroundColor: type === "line" ? "transparent" : color,
      //         backgroundColor: colors.primary,
      //         pointHoverRadius: 4,
      //         pointHoverBackgroundColor: "#fff",
      //         pointHoverBorderWidth: 3,
      //         borderRadius: 5,
      //         barPercentage: 0.6,
      //         maxBarThickness: 25,
      //       })),
      //     };
      //     return { ...chartData };
      //   }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <Paper
      sx={{
        height: 488,
        gridColumn: "span 3",
        p: 3,
        display: "flex",
        flexDirection: "column",
        borderRadius: "12px",
        boxShadow: "0 0 10px #1c295a14",
        ...sx,
      }}
    >
      <Box mb={3} display="flex" justifyContent="space-between">
        <Typography variant="h6">{title}</Typography>
        {showDateFilter && (
          <Box
            sx={{
              display: "flex",
              "& button": {
                minWidth: 40,
                height: 40,
                p: 0,
                border: 1,
                borderColor: (theme) => theme.customColors.border,
                bgcolor: isDarkMode ? "background.default" : "#fff",
                "&.active": {
                  bgcolor: "primary.main",
                  color: "#fff",
                  border: "none",
                },
              },
            }}
          >
            <Button
              className={type === "bar" ? "active" : ""}
              sx={{ mr: { xs: 1, md: 2 } }}
              onClick={() =>
                setType((prev: any) => {
                  console.log(prev, id, "rev");
                  let old = { ...prev };
                  old[id] = "bar";
                  return old;
                })
              }
            >
              <InsertChartOutlinedTwoToneIcon />
            </Button>
            <Button
              className={type === "doughnut" ? "active" : ""}
              onClick={() =>
                setType((prev: any) => {
                  console.log(prev, id, "rev");
                  let old = { ...prev };
                  old[id] = "doughnut";
                  return old;
                })
              }
            >
              <DonutLargeOutlinedIcon />
            </Button>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          width: 1,
          minHeight: 0,
        }}
      >
        {type !== "doughnut" ? (
          <Chart
            data={typeData()}
            options={{
              // scales: {

              //   xAxis: {
              //     // type: 'time',
              //     grid: {
              //       display: false,
              //       tickWidth: 0,
              //       tickLength: 16,
              //       drawBorder: false,
              //     },
              //     ticks: {
              //       color: theme.palette.text.secondary,
              //     },
              //     scaleLabel: {
              //       display: true,
              //       labelString: "Y text",
              //     },
              //   },
              //   yAxis: {
              //     title: {
              //       // display: true,
              //       display: false,
              //       text: "Users (%)",
              //       padding: {
              //         top: 0,
              //         bottom: 8,
              //       },
              //       color: theme.customColors.grey,
              //       font: {
              //         weight: "500",
              //         size: 12,
              //       },
              //     },
              //     ticks: {
              //       color: theme.palette.text.secondary,
              //     },
              //     suggestedMin: 2,
              //     suggestedMax: 20,
              //     grid: {
              //       borderDash: [10],
              //       tickWidth: 0,
              //       tickLength: 16,
              //       drawBorder: false,
              //     },
              //     scaleLabel: {
              //       display: true,
              //       labelString: "Y text",
              //     },
              //   },
              // },
              scales: {
                xAxis: {
                  // type: 'time',
                  title: {
                    display: true,
                    text:
                      id === 1
                        ? "Distributor Name"
                        : id === 2
                        ? "Model Name"
                        : "State Name",
                    padding: {
                      bottom: 0,
                      top: 15,
                    },
                    color: theme.customColors.grey,
                    font: {
                      weight: "500",
                      size: 12,
                    },
                  },
                  grid: {
                    display: false,
                    tickWidth: 0,
                    tickLength: 16,
                    drawBorder: false,
                  },
                  ticks: {
                    // color: theme.palette.text.secondary,
                    display: true,
                  },
                },
                yAxis: {
                  title: {
                    display: true,
                    text: "Sales",
                    padding: {
                      top: 0,
                      bottom: 15,
                    },
                    color: theme.customColors.grey,
                    font: {
                      weight: "500",
                      size: 12,
                    },
                  },
                  ticks: {
                    color: theme.palette.text.primary,
                  },
                  // suggestedMin: 15,
                  // suggestedMax: 30,
                  grid: {
                    borderDash: [4],
                    tickWidth: 0,
                    tickLength: 16,
                    drawBorder: false,
                  },
                },
              },
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  // enabled:false,
                  caretSize: 0,
                  mode: "index",
                  intersect: false,
                  yAlign: "center",
                  displayColors: false,
                  caretPadding: 16,
                  titleFont: {
                    weight: "400",
                  },
                  bodyFont: {
                    weight: "500",
                  },
                },
                datalabels: {
                  display: type === "bar",
                  anchor: "end",
                  align: "top",
                  formatter: Math.round,
                  font: {
                    weight: "bold",
                  },
                },
              },
              interaction: {
                mode: "index",
                intersect: false,
              },
            }}
          />
        ) : (
          <Box
            sx={{
              width: 1,
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              justifyItems: "center",
            }}
          >
            <Box width={190} alignSelf="center" position="relative">
              <Chart
                style={{ position: "relative", zIndex: 2 }}
                data={typeData()}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      displayColors: false,
                    },
                  },
                }}
              />
              <Box
                sx={{
                  zIndex: 1,
                  position: "absolute",
                  top: 70,
                  right: 0,
                  left: 0,
                  mx: "auto",
                  pointerEvents: "none",
                  textAlign: "center",
                }}
              >
                <Typography fontSize={28} fontWeight={700} lineHeight="1.2em">
                  {doughnutdata
                    ?.map((value: any) => value?.label)
                    ?.reduce((a: any, b: any) => a + b, 0)}
                </Typography>
                <Typography
                  sx={{
                    textTransform: "uppercase",
                    fontSize: 14,
                    fontFamily: "Poppins !important",
                  }}
                >
                  Total Sales
                </Typography>
              </Box>
            </Box>
            <Box mt={3} ml={3}>
              {doughnutdata?.map(({ label, value, color }, i) => (
                <Box
                  key={i}
                  sx={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    // width: 1,
                    pl: 2.75,
                    mb: 2.5,
                    "& .value": {
                      mb: 1,
                      lineHeight: "1.2em",
                      fontSize: 14,
                      fontWeight: 700,
                      color: color,
                      "&:before": {
                        content: '""',
                        position: "absolute",
                        top: 4,
                        left: 0,
                        width: 14,
                        height: 14,
                        bgcolor: color,
                        borderRadius: "2px",
                      },
                    },
                    "& .title": {
                      color: "text.secondary",
                      fontSize: 14,
                      lineHeight: "1.4em",
                    },
                  }}
                >
                  <span className="value">{value}</span>
                  <span className="title">{label}</span>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

// {
//   responsive: true,
//   maintainAspectRatio: false,
//   cutoutPercentage: 80,
//   tooltip: { enabled: false },
//   centerText: {
//     display: true,
//     text: `90%`,
//   },
// }

export default GraphCard;
