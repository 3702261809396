import { Box, Paper, Skeleton, Typography } from "@mui/material";
import InfoLabel from "components/InfoLabel";
import { Fragment } from "react";
import { Doughnut } from "react-chartjs-2";

const legend = [
  {
    key: "totalPrivateChargers",
    label: "Private",
    color: "#66CCFF",
  },
  {
    key: "totalPublicChargers",
    label: "Public",
    color: "#0086C0",
  },
  {
    key: "totalRestrictedChargers",
    label: "Restricted",
    color: "#A25DDC",
  },
];

const legendStyles: any = {};

legend.forEach((el) => {
  legendStyles[`&.${el.key}::before`] = {
    bgcolor: el.color,
  };
});

const ChargerAccess = ({ statsLoading: isLoading, statsData: data }: any) => {
  const totalChargers = (
    data?.data?.stats?.totalChargers || 0
  ).toLocaleString();

  return (
    <Paper
      sx={{
        py: { xs: 3, md: 4 },
        px: { xs: 2, md: 5 },
      }}
    >
      <Box
        sx={{
          mb: { xs: 2, md: 7.5 },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "flex-start", md: "center" },
        }}
      >
        <Typography variant="h3" mr={2.5} mb={{ xs: 2.5, md: 0 }}>
          Charger Access
        </Typography>
        {isLoading ? (
          <Skeleton
            variant="rectangular"
            width={95}
            height={28}
            sx={{ borderRadius: 10 }}
          />
        ) : (
          <InfoLabel label="Total" value={totalChargers} />
        )}
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "140px auto",
            md: "minmax(0, 265px) auto",
          },
          gap: { xs: 3, md: 4 },
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              width: 1,
              position: "relative",
              pt: "100%",
            }}
          >
            <Skeleton
              variant="circular"
              sx={{
                position: "absolute",
                top: 0,
                width: 1,
                height: 1,
              }}
            />
          </Box>
        ) : (
          <Box width={1} alignSelf="center" position="relative">
            <Doughnut
              style={{ position: "relative", zIndex: 2 }}
              data={(canvas) => {
                return {
                  datasets: [
                    {
                      data: legend.map((el) => data?.data?.stats?.[el.key]),
                      backgroundColor: legend.map((el) => el.color),
                      hoverBackgroundColor: legend.map((el) => el.color),
                      borderWidth: 0,
                      cutout: "85%",
                      hoverOffset: 3,
                    },
                  ],
                  labels: legend.map((el) => el.label),
                };
              }}
              options={{
                layout: {
                  padding: 5,
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    displayColors: false,
                  },
                },
              }}
            />
            <Box
              sx={{
                zIndex: 1,
                position: "absolute",
                top: "35%",
                right: 0,
                left: 0,
                mx: "auto",
                pointerEvents: "none",
                textAlign: "center",
                color: "text.secondary",
              }}
            >
              <Typography fontSize={{ xs: 18, md: 32 }} fontWeight={700}>
                {totalChargers}
              </Typography>
              <Typography fontSize={14}>Total</Typography>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            width: 1,
            maxWidth: 210,
            justifySelf: "end",
            height: "fit-content",
            ml: 2,
            display: "grid",
            gridTemplateColumns: "1fr auto",
            rowGap: 4,
            columnGap: 2,
            fontSize: { xs: 12, md: 16 },
            "& .label": {
              position: "relative",
              "&::before": {
                content: '""',
                position: "absolute",
                mt: 0.25,
                transform: "translateX(-15.96px)",
                borderRadius: 10,
                width: "6.39px",
                height: "17.89px",
              },
              ...legendStyles,
            },
            "& .value": {
              // textAlign: "right",
              fontWeight: 700,
            },
          }}
        >
          {legend.map((el, i) => (
            <Fragment key={i}>
              {isLoading ? (
                <Skeleton width={150} />
              ) : (
                <span className={`label ${el.key}`}>{el.label}</span>
              )}
              {isLoading ? (
                <Skeleton width={20} />
              ) : (
                <span className="value">
                  {data?.data?.stats?.[el.key]?.toLocaleString()}
                </span>
              )}
            </Fragment>
          ))}
        </Box>
      </Box>
    </Paper>
  );
};

export default ChargerAccess;
