import moment from "moment";
import { setLoader, snackbar } from "utils";
import { saveAs } from "file-saver";
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { GetApp } from "@mui/icons-material";
import TableComponent from "components/Table";

const ReportDialog = ({ open, data, onClose }: any) => {
  function downloadReports(format: string) {
    setLoader(true);
    let url = data?.downloadUrl + `&format=${format}`;
    let startTime = moment(data?.timeRange[0]).format("DD-MM-YYYY");
    let endTime = moment(data?.timeRange[1]).format("DD-MM-YYYY");
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        setLoader(false);
        console.log(result);
        if (result.file) {
          const fileURL = result.file;
          saveAs(
            fileURL,
            `${String(data?.name)
              .split(" ")
              .join("_")}_Summary_Report_${startTime}–${endTime}.${
              format === "excel" ? "xlsx" : "pdf"
            }`
          );
        } else if (result.message) {
          snackbar.error(result.message);
        }
      });
  }

  return (
    <Dialog open={open} maxWidth="md" fullWidth onClose={onClose}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography variant="h6">Reports Summary</Typography>
            <Divider
              orientation="vertical"
              flexItem
              style={{ margin: "0 8px" }}
            />
            <Typography variant="h6" color="textSecondary">
              {data?.name}
            </Typography>
            {data && (
              <Typography color="textSecondary" style={{ marginLeft: 8 }}>
                {`(${moment(data?.timeRange[0]).format(
                  "DD/MM/YYYY"
                )} – ${moment(data?.timeRange[1]).format("DD/MM/YYYY")})`}
              </Typography>
            )}
          </Box>
          <ButtonGroup>
            <Button
              startIcon={<GetApp />}
              onClick={() => downloadReports("pdf")}
            >
              PDF
            </Button>
            <Button
              startIcon={<GetApp />}
              onClick={() => downloadReports("excel")}
            >
              Excel
            </Button>
          </ButtonGroup>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box height={490}>
          {data && (
            <TableComponent
              rows={data?.array || []}
              columns={
                data?.name === "All Chargers"
                  ? [
                      {
                        key: "info.uid",
                        label: "UID",
                      },
                      {
                        key: "info.count",
                        label: "Bookings",
                      },
                      {
                        key: "info.users",
                        label: "Users",
                      },
                      {
                        key: "info.consumption",
                        label: "Consumption",
                        format: (value) => (value ? `${value} kWh` : "0 kWh"),
                      },
                      {
                        key: "info.amount",
                        label: "Amount",
                        format: (value) => "₹" + value,
                      },
                    ]
                  : [
                      {
                        key: "date",
                        label: "Date",
                        format: (value) => moment(value).format("MMM D, YYYY"),
                      },
                      {
                        key: "count",
                        label: "Bookings",
                        format: (value) => String(value),
                      },
                      {
                        key: "userCount",
                        label: "Users",
                        format: (value) => String(value),
                      },
                      {
                        key: "consumption",
                        label: "Consumption",
                        format: (value) => (value ? `${value} kWh` : "0 kWh"),
                      },
                      {
                        key: "amount",
                        label: "Amount",
                        format: (value) => "₹" + value,
                      },
                    ]
              }
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportDialog;
