import { useTheme } from "@mui/material";

const RedAsterisk = () => {
  const theme = useTheme();

  return (
    <span
      style={{
        fontSize: "16px",
        fontWeight: 600,
        color: theme.palette.error.main,
      }}
    >
      *
    </span>
  );
};

export default RedAsterisk;
