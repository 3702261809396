import {
  CloudUploadOutlined,
  DescriptionOutlined,
  Download,
  FileUploadOutlined,
  HighlightOff,
  // InfoOutlined,
} from "@mui/icons-material";
import {
  alpha,
  Box,
  Button,
  Card,
  // CardActionArea,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  Skeleton,
  Typography,
} from "@mui/material";
import { ReactComponent as SuccessIcon } from "assets/images/success.svg";
import { queryClient } from "index";
import { useEffect, useRef, useState } from "react";
import { authorizedFetch, snackbar, setLoader, GlobalState } from "utils";
import { RETAIL_URL } from "utils/constants";
import saveAs from "file-saver";
import { ReactComponent as UploadFailed } from "assets/images/icons/upload-failed.svg";
import { useSelector } from "react-redux";

const ExcelJS = require("exceljs");

const BulkUploadDialog = ({
  open,
  handleClose,
  model,
  setView,
  setTab,
}: any) => {
  const [isDragActive, setDragActive] = useState(0);

  const [file, setFile] = useState<null | File>(null);
  const fileInputRef = useRef<any>();

  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [error, setError] = useState<any>({
    open: false,
    message: "",
    file: null,
  });

  const [latestUploadInfo, setLatestUploadInfo] = useState<any>(null);

  const { company } = useSelector((state: GlobalState) => state.global);
  const isBattRe = company?.id === "5cfa3a9da7b11b00073f95fa";

  useEffect(() => {
    if (!file && fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }, [file]);

  function handleUpload() {
    if (!file) return;

    const fileSize = file.size / 1000 / 1000; // in MB

    if (fileSize > 10) {
      snackbar.error("File size should be less than 10MB");
    } else {
      const formData = new FormData();
      formData.append("uploadFile", file, file.name);

      setUploading(true);

      authorizedFetch(
        `${RETAIL_URL}/assembly/vehicle/bulkUpload?modelId=${model?._id}&protocol=${model?.protocol}`,
        {
          method: "POST",
          onlyBody: formData,
        }
      )
        .then((res) => {
          setUploading(false);
          if (res?.meta?.success) {
            setUploaded(true);
            getLatestUploadInfo();
            queryClient.resetQueries("getVehiclesBulkUploadHistory");
            queryClient.resetQueries("getRetailVehicles");
          } else {
            console.log(res);
            setError({ open: true, message: res?.msg, file: file });
            setUploading(false);
            snackbar.error("An error occurred");
            // setFile(null);
          }
        })
        .catch((err) => {
          console.error(err);
          setError({ open: true, message: err, file: file });
          setUploading(false);
          snackbar.error("An error occurred");
          // setFile(null);
        });
    }
  }

  function getLatestUploadInfo() {
    setLatestUploadInfo(null);
    authorizedFetch(
      `${RETAIL_URL}/assembly/vehicle/bulkUpload/history?first=15&skip=0`
    )
      .then((res) => {
        if (res.meta.success) {
          setLatestUploadInfo(res?.data?.[0]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getReferenceFile() {
    setLoader(true);
    const modelsUrl = `${RETAIL_URL}/inventory/components?vehicleModel=${model?._id}&all=true&assigned=true`;

    authorizedFetch(modelsUrl)
      .then((res) => {
        setLoader(false);

        if (res.meta.success && res.data.length === 0) {
          snackbar.error("No Components for the Model found");
        }
        if (res.meta.success && res.data.length > 0) {
          const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
          const template = res.data.map((entry: any) => ({
            "Serial Number": entry.serialNumber || "",
            "MAC ID": entry.specs.macId || "",
            Model: model.name || "",
            VIN: "",
            Color: [
              "BLUE",
              "RED",
              "WHITE",
              "YELLOW",
              "ORANGE",
              "BLACK",
              "GREY",
              "SILVER",
              ...(isBattRe
                ? [
                    "Oxford Blue",
                    "Pearl White",
                    "Ecru Yellow",
                    "Midnight Black",
                    "Stormy Grey",
                    "Electric Blue",
                    "Tea Green",
                    "Ice Blue",
                    "Starlight Blue",
                  ]
                : []),
            ],
            Action: ["Save", "No Update"],
          }));

          const columnOrder = Object.keys(template[0]);

          const workbook = new ExcelJS.Workbook();
          const sheet = workbook.addWorksheet("Vehicles Bulk Upload");

          sheet.columns = columnOrder.map((el: any) => ({
            header: el,
            width: 30,
            key: el,
          }));

          columnOrder.forEach((key, index): any => {
            const column = alphabet[index];

            sheet.getCell(`${column}1`).alignment = {
              horizontal: "center",
            };
            sheet.getCell(`${column}1`).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFFFF00" },
            };

            if (key !== "VIN" && key !== "Action" && key !== "Color") {
              template.forEach((entry: any, rowIndex: any) => {
                sheet.getCell(`${column}${rowIndex + 2}`).value = entry[key];
              });
            }

            if (key === "Color") {
              const actions = template[0][key];
              const formula = ['"' + actions.join(",") + '"'];

              const numbersArray = Array.from(
                { length: template.length },
                (_, rowIndex) => rowIndex + 2
              );

              numbersArray.forEach((rowIndex) => {
                sheet.getCell(`${column}${rowIndex}`).dataValidation = {
                  type: "list",
                  formulae: formula,
                  showDropDown: true,
                  promptTitle: "Select",
                  prompt: "Select an action",
                  errorTitle: "Invalid Selection",
                  error: "Please use the drop-down to select a valid value",
                };
              });
            }
            if (key === "Action") {
              const actions = template[0][key];
              const formula = ['"' + actions.join(",") + '"'];

              const numbersArray = Array.from(
                { length: template.length },
                (_, rowIndex) => rowIndex + 2
              );

              numbersArray.forEach((rowIndex) => {
                sheet.getCell(`${column}${rowIndex}`).dataValidation = {
                  type: "list",
                  formulae: formula,
                  showDropDown: true,
                  promptTitle: "Select",
                  prompt: "Select an action",
                  errorTitle: "Invalid Selection",
                  error: "Please use the drop-down to select a valid value",
                };
              });
            }
          });

          sheet.eachRow((row: any) => {
            row.eachCell((cell: any) => {
              cell.alignment = { horizontal: "center" };
            });
          });

          workbook.xlsx.writeBuffer().then(function (data: any) {
            var blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            saveAs(blob, "Vehicles Bulk Upload Format.xlsx");
          });
          snackbar.success("Sample File Downloaded");
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
        snackbar.error("Error downloading sample file");
      });
  }

  return (
    <Dialog maxWidth="lg" fullWidth open={open} onClose={handleClose}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        Bulk Upload
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            mb: 3,
            py: 6,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            border: `${file ? "1px solid" : "2px dashed"}`,
            borderColor: (theme) =>
              isDragActive > 0
                ? alpha(theme.palette.primary.main, 0.5)
                : theme.customColors.border,
            backgroundColor: (theme) =>
              isDragActive > 0
                ? alpha(theme.palette.primary.main, 0.05)
                : "inherit",
            borderRadius: 2.5,
          }}
          {...(!file
            ? {
                onDrop: (e: any) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setDragActive((prev) => prev - 1);

                  if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                    const file = e.dataTransfer.files[0];
                    if (
                      [
                        ".csv",
                        "text/csv",
                        "application/csv",
                        "text/comma-separated-values",
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      ].includes(file.type)
                    ) {
                      setFile(file);
                    }
                  }
                },
                onDragOver: (e: any) => {
                  e.stopPropagation();
                  e.preventDefault();
                },
                onDragEnter: (e: any) => setDragActive((prev) => prev + 1),
                onDragLeave: (e: any) => setDragActive((prev) => prev - 1),
                onMouseOut: (e: any) => setDragActive(0),
              }
            : {})}
        >
          <Box sx={{ mb: 1.5 }}>
            {error.open ? (
              <UploadFailed />
            ) : file ? (
              uploaded ? (
                <SuccessIcon />
              ) : uploading ? (
                <CircularProgress size={80} />
              ) : (
                <DescriptionOutlined color="primary" sx={{ fontSize: 80 }} />
              )
            ) : (
              <CloudUploadOutlined color="inherit" sx={{ fontSize: 80 }} />
            )}
          </Box>
          <Typography
            variant="h2"
            mb={uploaded ? 4 : 2}
            color={error.open ? "red" : ""}
          >
            {error.open
              ? "Unexpected Error!"
              : file
              ? uploaded
                ? "Your file has been successfully uploaded"
                : uploading
                ? "Uploading..."
                : "Ready for Upload"
              : "Drop your XLSX file here, or"}{" "}
            {error.open ? (
              ""
            ) : !file ? (
              <label htmlFor="bulk-upload-input">
                <Link
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  browse
                </Link>
              </label>
            ) : (
              ""
            )}
            <input
              ref={fileInputRef}
              id="bulk-upload-input"
              type="file"
              accept=".csv, text/csv, application/csv, text/comma-separated-values, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              hidden
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  const file = e.target.files[0];
                  setFile(file);
                }
              }}
            />
          </Typography>
          {error.open && (
            <Box>
              <Typography
                variant="body1"
                color="text.secondary"
                mt={-1}
                textAlign={"center"}
              >
                {error?.message}
              </Typography>
              <Box display="flex" alignItems="center" justifyContent={"center"}>
                <Typography variant="body1" fontWeight={600} mb={0.5} mt={3}>
                  {error?.file?.name} : {(error?.file?.size / 1000).toFixed(1)}{" "}
                  kB
                </Typography>

                <Button
                  size="medium"
                  sx={{ textTransform: "none", mt: 2, ml: 2, fontWeight: 900 }}
                  onClick={() => {
                    setFile(null);
                    setUploaded(false);
                    setError({ open: false, message: "", file: null });
                  }}
                >
                  Retry Upload
                </Button>
              </Box>
            </Box>
          )}
          {!uploaded &&
            !error.open &&
            (file ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">
                  {file.name} ({(file.size / 1000).toFixed(1)} kB)
                  {!uploading && (
                    <Link
                      sx={{
                        ml: 1.25,
                        fontWeight: 600,
                        textDecoration: "none",
                        cursor: "pointer",
                        color: (theme) => theme.palette.error.light,
                      }}
                      onClick={() => setFile(null)}
                    >
                      Remove File
                    </Link>
                  )}
                </Typography>
              </Box>
            ) : (
              <Typography variant="body2" color="text.secondary" component="ul">
                <li>The file should be in .xlsx or format</li>
                <li>Size should be less than 10 MB</li>
                <li>
                  Make sure that your file has the correct column header as per
                  the sample file
                </li>
              </Typography>
            ))}
        </Box>

        {file && uploaded && !error.open && (
          <Box
            sx={{
              mb: 3,
              p: 3,
              border: (theme) => `1px solid ${theme.customColors.border}`,
              borderRadius: 2.5,
              display: "grid",
              gridTemplateColumns: "35px 1fr",
              gap: 3,
            }}
          >
            <DescriptionOutlined color="primary" sx={{ fontSize: 35 }} />
            <Box>
              <Typography variant="h6" mb={0.5}>
                {file?.name}
              </Typography>
              <Typography variant="body2" color="text.secondary" mb={1}>
                {(file.size / 1000).toFixed(1)} kB
              </Typography>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "end",
                }}
              >
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "auto auto auto",
                    gap: 7.5,
                    "& > .MuiBox-root": {
                      py: 2,
                      display: "flex",
                      flexDirection: "column",
                      "& .label": {
                        color: (theme) => theme.palette.text.primary,
                        fontWeight: 600,
                        mb: 1,
                      },
                      "& .value": {
                        color: (theme) => theme.palette.text.secondary,
                        "&.red": {
                          color: (theme) => theme.palette.error.light,
                        },
                      },
                    },
                  }}
                >
                  <Box>
                    <span className="label">Total Items</span>
                    <span className="value">
                      {!latestUploadInfo ? (
                        <Skeleton />
                      ) : (
                        latestUploadInfo?.passedEntries +
                        latestUploadInfo?.failedEntries
                      )}
                    </span>
                  </Box>
                  <Box>
                    <span className="label">Successful</span>
                    <span className="value">
                      {!latestUploadInfo ? (
                        <Skeleton />
                      ) : (
                        latestUploadInfo?.passedEntries
                      )}
                    </span>
                  </Box>
                  <Box>
                    <span className="label">Failed</span>
                    <span className="value red">
                      {!latestUploadInfo ? (
                        <Skeleton />
                      ) : (
                        latestUploadInfo?.failedEntries
                      )}
                    </span>
                  </Box>
                </Box>
                <Button
                  size="large"
                  sx={{ textTransform: "none" }}
                  onClick={() => {
                    handleClose();
                    setTimeout(() => {
                      setFile(null);
                      setUploaded(false);
                      setView("list");
                      setTab(3);
                    }, 1000);
                  }}
                >
                  View all uploads
                </Button>
              </Box>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            mb: 6,
          }}
        >
          {uploaded && !error.open && (
            <Button
              variant="outlined"
              size="large"
              startIcon={<FileUploadOutlined />}
              sx={{
                mr: 3,
                textTransform: "none",
                borderRadius: 5,
                py: 1.5,
                px: 6.5,
                visibility: file ? "visible" : "hidden",
              }}
              onClick={() => {
                setFile(null);
                setUploaded(false);
              }}
            >
              Upload New File
            </Button>
          )}
          {!error.open && (
            <Button
              variant="contained"
              size="large"
              sx={{
                textTransform: "none",
                borderRadius: 5,
                py: 1.5,
                px: 6.5,
                visibility: file ? "visible" : "hidden",
              }}
              onClick={() => {
                if (uploaded) {
                  handleClose();
                  setTimeout(() => {
                    setFile(null);
                    setUploaded(false);
                  }, 1000);
                } else {
                  handleUpload();
                }
              }}
              disabled={uploading}
            >
              {uploaded ? "Done" : "Upload"}
            </Button>
          )}
        </Box>
        {!uploaded && !error.open && (
          <Box
            sx={{
              display: "grid",
              // gridTemplateColumns: "2fr 3fr",
              gridTemplateColumns: "1fr",
              gap: 2.5,
              mb: 6,
              "& > .MuiCard-root": {
                border: 1,
                borderRadius: 2.5,
                borderColor: (theme) => theme.customColors.border,
              },
            }}
          >
            {/* <Card elevation={0}>
              <CardActionArea
                sx={{ height: 1 }}
                onClick={() =>
                  saveAs(
                    "/docs/fms-bulk-upload-user-guide.pdf",
                    "Bulk Upload User Guide.pdf"
                  )
                }
              >
                <CardContent sx={{ px: 3, py: 2, height: 1 }}>
                  <Typography variant="h6" mb={1.5}>
                    Need help?
                  </Typography>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 56px",
                      gap: 4.5,
                    }}
                  >
                    <Typography variant="body2" color="text.secondary">
                      Getting started with bulk upload? Click here to view the
                      bulk upload user guide.
                    </Typography>
                    <Box
                      sx={{
                        mb: 1,
                        width: 56,
                        height: 56,
                        bgcolor: (theme) => theme.palette.primary.main,
                        borderRadius: 1.5,
                        color: "#FFF",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <InfoOutlined color="inherit" fontSize="large" />
                    </Box>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card> */}
            <Card
              sx={{
                border: 1,
                borderRadius: 2.5,
                borderColor: (theme) => theme.customColors.border,
              }}
              elevation={0}
            >
              <CardContent sx={{ px: 3, py: 2 }}>
                <Typography variant="h6" mb={1.5}>
                  Download Sample File
                </Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 56px",
                    gap: 4.5,
                  }}
                >
                  <Box>
                    <Typography variant="body2" color="text.secondary" mb={1.5}>
                      Bulk upload of vehicles are required to be in the format
                      specified in the sample file provided. Please download the
                      file and edit it with your vehicle's information.
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        "& .MuiButton-root": {
                          textTransform: "none",
                        },
                      }}
                    >
                      <Button
                        size="small"
                        variant="outlined"
                        startIcon={<Download fontSize="small" />}
                        onClick={() => getReferenceFile()}
                      >
                        Sample XLSX
                      </Button>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      mb: 1,
                      width: 56,
                      height: 56,
                      bgcolor: (theme) => theme.palette.primary.main,
                      borderRadius: 1.5,
                      color: "#FFF",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DescriptionOutlined color="inherit" fontSize="large" />
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default BulkUploadDialog;
