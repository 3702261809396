import {
  Box,
  MenuItem,
  Paper,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import { Chart } from "chart.js";
import CircularLoader from "components/CircularLoader";
import { useMemo, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { authorizedFetch, lerp, titleCase } from "utils";
import { LEASE_URL } from "utils/constants";

const TopVehicles = ({ dateFrom, dateTo }: any) => {
  const theme = useTheme();
  const history = useHistory();

  const chartRef = useRef();

  const filters = ["trips", "distance"];
  const [filter, setFilter] = useState("trips");

  const { isLoading, data } = useQuery(["getTopVehicles", filter], () =>
    authorizedFetch(`${LEASE_URL}/company/vehicles/stats/${filter}`)
  );

  const chartData = useMemo(() => {
    let chartData = (data?.data?.constructor === Array ? data.data : []).map(
      (el: any) => ({
        x: el.vin,
        y: el[filter],
      })
    );
    return chartData;
  }, [data, filter]);

  return (
    <Paper
      sx={{
        py: { xs: 3, md: 3 },
        px: { xs: 2, md: 5 },
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          mb: 4,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h3" mr={1.25}>
          Top Vehicles
        </Typography>
        <Select
          className="rounded"
          sx={{
            minWidth: 180,
            color: (theme) => theme.customColors.text.plain,
          }}
          size="small"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        >
          {filters.map((el, i) => (
            <MenuItem key={i} value={el}>
              {titleCase(el)}
            </MenuItem>
          ))}
        </Select>
      </Box>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <Box
          sx={{
            flexGrow: 1,
            minHeight: 0,
          }}
        >
          <Bar
            ref={chartRef}
            data={{
              labels: chartData?.map((el: any) => el.x) || [],
              datasets: [
                {
                  data:
                    chartData?.map((el: any) =>
                      filter === "trips"
                        ? el.y
                        : (parseInt(el.y) / 1000).toFixed(2)
                    ) || [],
                  borderWidth: 0,
                  backgroundColor(ctx: any) {
                    const maxIndex = chartData?.length;
                    const alpha = lerp(1, 0.5, ctx.dataIndex / maxIndex);
                    return `rgba(204, 82, 226, ${alpha})`;
                  },
                  borderRadius: 1,
                  maxBarThickness: 12,
                },
              ],
            }}
            getElementAtEvent={(i: any, event: any) => {
              if (chartRef.current) {
                const chart = Chart.getChart(chartRef.current)!;
                const clickedElements = chart.getElementsAtEventForMode(
                  event,
                  "y",
                  { axis: "y", intersect: false },
                  true
                );
                if (clickedElements.length > 0) {
                  history.push(
                    "/vehicles?vin=" + chartData[clickedElements[0].index].x
                  );
                }
              }
            }}
            options={{
              onHover: (event: any, chartElement) => {
                const target = event.native
                  ? event.native.target
                  : event.target;
                target.style.cursor = chartElement[0] ? "pointer" : "default";
              },
              indexAxis: "y",
              scales: {
                yAxis: {
                  title: {
                    // display: true,
                    text: "Vehicles",
                  },
                  grid: {
                    display: false,
                    tickWidth: 0,
                    tickLength: 16,
                    drawBorder: false,
                  },
                  ticks: {
                    display: true,
                    color: theme.palette.text.primary,
                    // callback(this, tickValue, index, ticks) {
                    //   let maxLength = 13;
                    //   let label = String(chartData[index].x);
                    //   if (label.length > maxLength)
                    //     return label.substring(0, maxLength) + "...";
                    //   else return label;
                    // },
                  },
                },
                xAxis: {
                  title: {
                    display: true,
                    text: filter === "trips" ? "Trips" : "Distance (km)",
                    padding: {
                      top: 8,
                      bottom: 0,
                    },
                    color: theme.customColors.text.grey,
                    font: {
                      weight: "400",
                      size: 12,
                    },
                  },
                  ticks: {
                    display: true,
                    precision: 0,
                  },
                  grid: {
                    borderDash: [5],
                    tickWidth: 0,
                    tickLength: 16,
                    drawBorder: false,
                  },
                },
              },
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  caretSize: 5,
                  mode: "y",
                  intersect: false,
                  yAlign: "center",
                  displayColors: false,
                  caretPadding: 16,
                  titleFont: {
                    weight: "400",
                  },
                  bodyFont: {
                    weight: "500",
                  },
                  callbacks: {
                    label: (context: any) => {
                      let label = context.parsed.x || "";
                      if (filter !== "trips" && context.parsed.x !== null) {
                        label = context.parsed.x + " km";
                      }
                      return label;
                    },
                  },
                },
              },
              interaction: {
                mode: "y",
                intersect: false,
              },
            }}
          />
        </Box>
      )}
    </Paper>
  );
};

export default TopVehicles;
