import { FileUploadOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { authorizedFetch } from "utils";
import { AUTH_URL, RETAIL_URL } from "utils/constants";
import BulkUploadDialog from "../BulkUploadDialog";

const AppBar = ({ tab, setTab, filters, setFilters, uploadHistoryData }: any) => {
  const [bulkUploadDialogOpen, setBulkUploadDialogOpen] = useState(false);

  const { data: companiesData } = useQuery(["get companies"], () =>
    authorizedFetch(`${AUTH_URL}/company/list`)
  );
  const companies =
    companiesData?.data?.constructor === Array ? companiesData.data : [];

  const { data: modelsData } = useQuery(["get models", filters.company], () =>
    authorizedFetch(
      `${RETAIL_URL}/inventory/ota/vehicle-models?companyId=${filters.company}`
    )
  );
  const models = modelsData?.data?.constructor === Array ? modelsData.data : [];

  const { data: speedometerData } = useQuery(["get speedometer"], () =>
    authorizedFetch(`${RETAIL_URL}/inventory/controllers/SMART`)
  );

  const speedometers =
    speedometerData?.data?.constructor === Array ? speedometerData.data[0] : [];

  // const speedometers = useMemo(() => {
  //   const models =
  //     modelsData?.data?.constructor === Array ? modelsData.data : [];
  //   const array: any[] = [];
  //   models.forEach((model: any) => {
  //     model?.components?.forEach((component: any) => {
  //       if (component.category === "CONTROLLER") {
  //         component.modelId?.forEach((device: any) => {
  //           if (!array.find((el) => el._id === device._id)) {
  //             array.push(device);
  //           }
  //         });
  //       }
  //     });
  //   });
  //   return array;
  // }, [modelsData]);

  const selectedCompany =
    companies.find((company: any) => company._id === filters.company)?.name ??
    "";
  const selectedModel =
    models?.find((model: any) => model._id === filters.model)?.name ?? "";
  // const selectedSpeedometer =
  //   speedometers?.find((meter: any) => meter._id === filters.speedometer)
  //     ?.key ?? "";

  const selectedSpeedometer =
    speedometers?.find(
      (speedometer: any) => speedometer._id === filters.speedometer
    )?.key ?? "";

  return (
    <Box
      sx={{
        width: 1,
        py: 3,
        px: { xs: 2, md: 7 },
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box display="flex" alignItems="center">
        <Typography variant="h2">SCM Dashboard</Typography>
        <Typography
          sx={{
            ml: 1,
            fontSize: { xs: 16, md: 20 },
            fontWeight: 600,
            color: (theme) => theme.customColors.text.grey,
          }}
        >
          {/* ({(counts?.all || 0).toLocaleString()}) */}
        </Typography>
      </Box>
      <Box display="flex" alignItems="space-between">
        {tab === 2 && (
          <>
            <FormControl size="small">
              <Select
                className="shadow rounded"
                sx={{ width: 220, mr: 1 }}
                value={filters.fileKeyStatus}
                onChange={(e) =>
                  setFilters((prev: any) => ({
                    ...prev,
                    fileKeyStatus: e.target.value,
                  }))
                }
                displayEmpty
                renderValue={(value) => "Assignee: " + (value === "" ? "All" : value)}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="ASSIGNED">
                  ASSIGNED
                </MenuItem>
                <MenuItem value="UNASSIGNED">
                  UNASSIGNED
                </MenuItem>
              </Select>
            </FormControl>
            <Button
              sx={{
                px: 5,
                py: 0.8,
                borderRadius: 10,
                borderWidth: "1.5px !important",
                borderColor: (theme) => theme.palette.primary.main,
                textTransform: "none",
                fontWeight: 500,
              }}
              variant="outlined"
              startIcon={<FileUploadOutlined />}
              onClick={() => setBulkUploadDialogOpen(true)}
            >
              Bulk Upload
            </Button>
          </>
        )}
        {tab === 0 || tab === 1 ? (
          <>
            <FormControl size="small">
              <Select
                className="shadow rounded"
                sx={{ width: 220, mr: 1 }}
                value={filters.company}
                onChange={(e) =>
                  setFilters((prev: any) => ({
                    ...prev,
                    company: e.target.value,
                  }))
                }
                displayEmpty
                placeholder="Company"
                renderValue={(value) =>
                  "Company: " + (selectedCompany || "All")
                }
              >
                <MenuItem value="">All</MenuItem>
                {companies.map((company: any, i: number) => (
                  <MenuItem key={i} value={company._id}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small">
              <Select
                className="shadow rounded"
                sx={{ width: 250, mr: 1 }}
                value={filters.speedometer}
                onChange={(e) =>
                  setFilters((prev: any) => ({
                    ...prev,
                    speedometer: e.target.value,
                  }))
                }
                displayEmpty
                renderValue={(value) =>
                  "Speedometer: " + (selectedSpeedometer || "All")
                }
              >
                <MenuItem value="">All</MenuItem>
                {speedometers.map((meter: any, i: number) => (
                  <MenuItem key={i} value={meter._id}>
                    {meter.key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small">
              <Select
                className="shadow rounded"
                sx={{ width: 250 }}
                value={filters.model}
                onChange={(e) =>
                  setFilters((prev: any) => ({
                    ...prev,
                    model: e.target.value,
                  }))
                }
                displayEmpty
                renderValue={(value) => "Model: " + (selectedModel || "All")}
              >
                <MenuItem value="">All</MenuItem>
                {models.map((model: any, i: number) => (
                  <MenuItem key={i} value={model._id}>
                    {model.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        ) : (
          ""
        )}
      </Box>
      <BulkUploadDialog
        open={bulkUploadDialogOpen}
        handleClose={() => setBulkUploadDialogOpen(false)}
        setTab={setTab}
      />
    </Box>
  );
};

export default AppBar;
