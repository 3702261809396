import {
  CheckBox,
  CheckBoxOutlineBlank,
  HighlightOff,
} from "@mui/icons-material";

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { authorizedFetch, setLoader, snackbar } from "utils";

import { LEASE_URL, RETAIL_URL } from "utils/constants";
import EditMap from "./EditMap";

interface Props {
  open: boolean;
  handleClose: () => void;
  refetchFences: any;
  refetchCount: any;
  data: any;
}

type inputData = {
  geofenceName: string;
  type: string;
};

const EditFenceDialog: React.FC<Props> = ({
  open,
  handleClose,
  refetchFences,
  refetchCount,
  data,
}) => {
  const [input, setInput] = useState<inputData>({
    geofenceName: "",

    type: "CIRCULAR",
  });

  const [circleCoords, setCircleCoord] = useState<any>();
  const [polygonCoords, setPolygonCoord] = useState<any>();

  const { geofenceName, type } = input;

  function handleChange(key: string, value: string) {
    setInput((prevInput: inputData) => ({ ...prevInput, [key]: value }));
  }

  useEffect(() => {
    if (open && data) {
      if (data.type === "CIRCULAR") {
        setCircleCoord([
          {
            radius: data?.radius,
            center: data?.location?.coordinates[0][0],
          },
        ]);
      } else {
        setPolygonCoord(data?.location?.coordinates[0]);
      }
    }
  }, [open, data]);

  useEffect(() => {
    if (open && data) {
      setInput({
        geofenceName: data?.name || "-",
        type: data?.type,
      });
    }
    if (!open) {
      setInput({
        geofenceName: "",
        type: "CIRCULAR",
      });
    }
  }, [open, data]);

  const circleRadius =
    type === "CIRCULAR"
      ? { radius: circleCoords ? circleCoords[0]?.radius : "" }
      : "";

  const vehiclesUrl = `${LEASE_URL}/company/vehicles?all=true`;

  const { data: vehiclesData } = useQuery("getVehicles", () =>
    authorizedFetch(vehiclesUrl)
  );

  const [vehicleList, setVehicleList] = useState<any>([]);

  useEffect(() => {
    if (vehiclesData) {
      let arr: any = [];
      // eslint-disable-next-line
      vehiclesData?.data?.vehicles?.map((el: any) => {
        arr.push(el?.vin);
      });
      setVehicleList(arr.sort((a: any, b: any) => a.localeCompare(b)));
    }
  }, [vehiclesData]);

  const onSave = () => {
    setLoader(true);
    authorizedFetch(`${RETAIL_URL}/fence/${data?._id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        // token: "1234",
      },
      body: {
        // name: geofenceName,
        // uid: String(Math.random() * 100000000 + 1),
        ...circleRadius,
        type: type === "CIRCULAR" ? "CIRCULAR" : "POLYGONAL",
        location: {
          type: type === "CIRCULAR" ? "Point" : "Polygon",
          coordinates:
            type === "CIRCULAR" ? circleCoords[0]?.center : [polygonCoords],
        },
        vins: selectedVehicles,
      },
    })
      .then((res) => {
        setLoader(false);
        if (res.meta.success) {
          refetchFences();
          refetchCount();
          snackbar.success(`Fence Updated`);
          handleClose();
        }
        if (res.msg.includes("Can't extract geo keys")) {
          snackbar.error(`Polygon shape is invalid. Please try again.`);
        }
      })
      .catch((err) => {
        setLoader(false);
        snackbar.error(`Error updating fence`);
      });
  };

  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" />;

  const [selectedVehicles, setSelectedVehicles] = useState<any>([]);

  const [isEditMode, setIsEditMode] = useState(true);

  useEffect(() => {
    if (open && data) {
      setSelectedVehicles(data?.vins);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, data]);

  useEffect(() => {
    if (!open) {
      setIsEditMode(true);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: "90%",
          height: "90vh",
          width: 1,
          "& .MuiInputBase-root": {
            fontSize: 14,
            borderRadius: 1,
            p: "3.5px 5px",
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
          maxHeight: "90%",
        }}
      >
        Edit Geofence
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent sx={{ pb: "16px !important" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            mx: "auto",
            py: 2,
            display: "grid",
            gridTemplateColumns: { xs: "1fr", sm: "1fr 3fr" },
            gap: 3,
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              rowGap: 4,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box>
              <Typography className="label">Geofence Name</Typography>
              <TextField
                disabled
                fullWidth
                size="small"
                value={geofenceName}
                placeholder="Geofence Name"
                onChange={(e) => {
                  if (e.target.value.length <= 20) {
                    handleChange("geofenceName", e.target.value);
                  }
                }}
              />
            </Box>
            <Box>
              <Typography className="label">Vehicle</Typography>
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                size="medium"
                options={vehicleList}
                defaultValue={selectedVehicles}
                disableCloseOnSelect
                getOptionLabel={(option: any) => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Search Vehicles" />
                )}
                onChange={(event: any, newValue: any) => {
                  setSelectedVehicles(
                    newValue.map((el: any) => {
                      return el;
                    })
                  );
                }}
              />
            </Box>
            <Box>
              <Typography className="label">Type</Typography>
              <RadioGroup
                row
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                }}
                value={type}
                onChange={(e: any) => {
                  handleChange("type", e.target.value);
                  setIsEditMode(false);
                }}
              >
                <FormControlLabel
                  value="CIRCULAR"
                  control={<Radio />}
                  label="Circular"
                />

                <FormControlLabel
                  value="POLYGONAL"
                  control={<Radio />}
                  label="Polygon"
                />
              </RadioGroup>
            </Box>
          </Box>
          <Box sx={{ width: "100%", height: "100%" }}>
            <EditMap
              circleCoords={circleCoords}
              setCircleCoord={setCircleCoord}
              polygonCoords={polygonCoords}
              setPolygonCoord={setPolygonCoord}
              type={input.type}
              data={data}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              open={open}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onSave}>
          Update Zone
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditFenceDialog;
