import { Box, Paper, Typography, Avatar } from "@mui/material";
import { Fragment, useState, useEffect } from "react";

import GraphCard from "./GraphCard";
import { AccountTreeOutlined } from "@mui/icons-material";
// import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
// import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import { getBoltToken } from "utils/request";
import storageManager from "utils/storageManager";
import { format } from "date-fns";

const Dashboard = ({ range }: any) => {
  // const [activeCard, setActiveCard] = useState<number | null>(null);

  const [homepageGraph, setHomepageGraph] = useState({}) as any;
  const [graphType, setGraphType] = useState({
    1: "bar",
    2: "bar",
    3: "bar",
  }) as any;

  useEffect(() => {
    console.log(graphType, "type");
  }, [graphType]);

  let dateFrom = format(range[0], "yyyy-MM-dd");
  let dateTo = format(range[1], "yyy-MM-dd");

  const overviewGraphs = async () => {
    try {
      let boltToken = await getBoltToken();
      let newHeaders = {
        token: storageManager?.get("companyToken") || "",
        Authorization: `Bearer ${boltToken}`,
      };
      const res = await fetch(
        `${
          process.env.REACT_APP_RETAIL_URL || "https://retail.dev.revos.in"
        }/homepage/overview?startTime=${dateFrom}&endTime=${dateTo}`,
        { headers: newHeaders, method: "GET" }
      ).then((res) => res.json());
      setHomepageGraph(res.data[0]);
      console.log(res.data, "response at companyOverview");
    } catch (e: any) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    overviewGraphs();
    // eslint-disable-next-line
  }, [dateFrom, dateTo]);

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Paper
            sx={{
              p: 2,
              width: "16%",
              display: "grid",
              gridColumn: { lg: "span 2" },
              gap: 2,
              gridTemplateRows: {
                xs: "1fr 1fr",
                sm: "1fr 1fr 1fr",
                lg: "1fr 1fr",
              },
              "& > div": {
                p: 2,
                pb: 1,
                border: 1,
                borderColor: (theme: any) => theme.customColors.border,
                borderRadius: 1,
                "& .icon": {
                  width: 44,
                  height: 44,
                  borderRadius: 50,
                },
                "& .value": {
                  fontWeight: 700,
                  fontSize: 25,
                  color: "text.primary",
                  lineHeight: "1.75em",
                  mb: 1,
                  mt: 2,
                  "& span": {
                    lineHeight: "0.8em",
                    fontWeight: 500,
                    fontSize: 16,

                    "&.bigger": {
                      fontSize: 24,
                      fontWeight: 700,
                      lineHeight: "1em",
                    },
                  },
                },
                "& .title": {
                  fontSize: 18,
                  lineHeight: "26px",
                  color: "text.secondary",
                },
              },
            }}
          >
            <Box>
              <Avatar variant="icon" className="icon">
                <AccountTreeOutlined />
              </Avatar>
              <Typography className="value">
                {homepageGraph?.companyOverview?.totalVendors}
              </Typography>
              <Typography variant="body2" className="title">
                Total Distributors
              </Typography>
            </Box>
            <Box>
              <Avatar variant="icon" className="icon">
                <TaskAltIcon />
              </Avatar>
              <Typography className="value">
                {homepageGraph?.companyOverview?.totalSales}
              </Typography>
              <Typography className="title">Total Sales</Typography>
            </Box>
            {/* <Box>
              <Avatar variant="icon" className="icon">
                <CurrencyRupeeIcon />
              </Avatar>
              <Typography className="value">{100}</Typography>
              <Typography className="title">Total Earnings</Typography>
            </Box> */}
            {/* <Box>
              <Avatar variant="icon" className="icon">
                <EventAvailableOutlinedIcon />
              </Avatar>
              <Typography className="value">{100}</Typography>
              <Typography className="title">Total Bookings</Typography>
            </Box> */}
          </Paper>
          <GraphCard
            id={1}
            data={homepageGraph?.vendorSummary}
            type={graphType[1]}
            setType={setGraphType}
            title="Top Distributors"
            color="#09abed"
            sx={{ width: "81%" }}
            showDateFilter={true}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            mt: 3,
          }}
        >
          <GraphCard
            id={2}
            data={homepageGraph?.modelMonthlySales}
            type={graphType[2]}
            setType={setGraphType}
            title="Model Wise Sale"
            color="#ffe100"
            sx={{ width: "49%" }}
            showDateFilter={true}
          />
          <GraphCard
            id={3}
            data={homepageGraph?.stateWiseSales}
            type={graphType[3]}
            setType={setGraphType}
            title="State Wise Sale"
            color="#A020F0"
            sx={{ width: "48%" }}
            showDateFilter={true}
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export default Dashboard;
