import { Route, Switch } from "react-router-dom";
import VehicleView from "views/Common/VehicleView";
import List from "./List";

const SoldVehicles = () => {
  return (
    <Switch>
      <Route exact path="/sold-vehicles" render={() => <List />} />
      <Route
        path="/sold-vehicles/:id"
        render={(props) => {
          return <VehicleView {...props} />;
        }}
      />
    </Switch>
  );
};

export default SoldVehicles;
