import { CheckCircleOutline } from "@mui/icons-material";
import { TextField } from "@mui/material";
import { forwardRef } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";

const PhoneTextField = (props: any, ref: any) => {
  return (
    <TextField
      {...props}
      inputRef={ref}
      size="small"
      InputProps={
        isValidPhoneNumber(props.value)
          ? {
              endAdornment: (
                <CheckCircleOutline color="primary" fontSize="small" />
              ),
            }
          : undefined
      }
    />
  );
};
export default forwardRef(PhoneTextField);
