import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "actions";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Button,
  IconButton,
  Link,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";
// import { Heading } from ".";
import { getDarkModePreference, GlobalState } from "utils";
import { subdomain } from "utils/constants";
import Logo from "components/BoltLogo";
import jwt from "jwt-simple";
import storageManager from "utils/storageManager";

const SignIn = ({ setView }: any) => {
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [valid, setValid] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const error = useSelector((state: GlobalState) => {
    let { loginResponse = {} } = state.global;
    if (
      loginResponse.hasOwnProperty("code") &&
      loginResponse.hasOwnProperty("message")
    )
      return loginResponse;
    else return false;
  });
  const dispatch = useDispatch();

  // const validate = () => {}
  const handleLogin = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if ([email, password].includes("")) setValid(false);
    else {
      setValid(true);
      const userCredentials = { email, password };
      const loginJwt = jwt.encode(userCredentials, "bolt-secret");
      storageManager.set("loginJwt", loginJwt);

      dispatch(login(userCredentials));

      // For testing purposes:

      // const token =
      //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI1Y2ZhNDNjNmE3YjExYjAwMDczZjk2NTYiLCJpYXQiOjE2OTE1ODcwODcsImV4cCI6MTY5NDE3OTA4N30.edJGgRzp_pjgz3p_cbqD5kWoIxBoUkCjjDSRG2qKfNY";

      // storageManager.set("boltToken", token);

      // dispatch(
      //   loginSuccess({
      //     token,
      //     user: {
      //       permissions: ["dashboard:*"],
      //     },
      //     company: {
      //       permissions: ["dashboard:*"],
      //     },
      //     activeSection: "charger",
      //   })
      // );
    }
  };

  if (error?.message?.startsWith("{")) {
    error.message = JSON.parse(error.message)?.error?.message;
  }

  return (
    <Box width={400} mt={isMdUp ? "" : 15}>
      <Helmet>
        <title>
          Sign in - {subdomain.name === "REVOS" ? "Bolt.Earth" : subdomain.name}
        </title>
      </Helmet>
      <form
        style={{ width: "100%", maxWidth: 400, display: "grid" }}
        onSubmit={handleLogin}
      >
        {subdomain.name === "REVOS" ? (
          <Box mb={2}>
            <Logo width={100} />
          </Box>
        ) : (
          ""
        )}

        {subdomain.name === "REVOS" ? (
          <></>
        ) : (
          // <Heading value={`Sign in to REVOS`} />
          <Box mt={3} display="flex" alignItems="center">
            <Box
              sx={{
                mr: 1.5,
                width: 90,
                height: 90,
                borderRadius: 1,
                // border: 1,
                // borderColor: theme => theme.customColors.border,
                boxShadow: (theme) => theme.shadows[2],
                bgcolor: "white",
              }}
            >
              <img width="100%" src={subdomain.icon} alt="Company Logo" />
            </Box>
            <Typography
              sx={{
                fontSize: 28,
                fontWeight: 600,
                lineHeight: "1em",
                color: isDarkMode ? "common.white" : "common.black",
              }}
            >
              {subdomain.name}
            </Typography>
          </Box>
        )}
        <Typography sx={{ mb: 2, mt: 3, fontSize: 18, fontWeight: "bold" }}>
          Login to the dashboard
        </Typography>
        {error && (
          <Typography color="error" variant="body2">
            {error.message}
          </Typography>
        )}
        <TextField
          sx={{ mt: 1.5, mb: 3 }}
          fullWidth
          label="Email"
          type="email"
          name="email"
          value={email}
          onChange={(e) => {
            setEmail(e.currentTarget.value);
          }}
          error={!valid && email === ""}
          helperText={!valid && email === "" && "Enter an email"}
        />
        <Box width={1} position="relative">
          <TextField
            sx={{ "& input": { pr: 7 } }}
            fullWidth
            label="Password"
            type={showPassword ? "text" : "password"}
            name="password"
            value={password}
            onChange={(e) => {
              setPassword(e.currentTarget.value);
            }}
            error={!valid && password === ""}
            helperText={!valid && password === "" && "Enter a password"}
          />
          <IconButton
            onClick={() => setShowPassword((value) => !value)}
            sx={{ position: "absolute", right: 8, top: 8 }}
            color="inherit"
          >
            {password && (showPassword ? <VisibilityOff /> : <Visibility />)}
          </IconButton>
        </Box>
        <Link
          sx={{
            display: "block",
            width: "max-content",
            mt: 1.5,
            mb: 4,
            // color: "#62D16A",
          }}
          href="#"
          underline="hover"
          variant="body2"
          onClick={(e) => {
            e.preventDefault();
            setView("reset-password");
          }}
        >
          Forgot password?
        </Link>
        {/* <Box width='100%' display='flex' justifyContent='space-between'> */}
        <Button
          type="submit"
          variant="contained"
          // sx={{ background: "#62D16A" }}
          size="large"
        >
          Sign in
        </Button>
        {/* <Button color='primary' onClick={() => setView('sign-up')}>Create account</Button> */}
        {/* </Box> */}
        <Typography sx={{ mt: 2, textAlign: "center", userSelect: "none" }}>
          <span style={{ opacity: 0.8 }}>Don't have an account? </span>
          <Link
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setView("sign-up");
            }}
            // sx={{ color: "#62D16A" }}
          >
            Sign up
          </Link>
        </Typography>
        {subdomain.name === "REVOS" ? (
          ""
        ) : (
          <Typography
            sx={{
              mt: 1,
              textAlign: "center",
              userSelect: "none",
              fontSize: 12,
              opacity: 0.5,
            }}
          >
            Powered By <strong>Bolt.Earth</strong>
          </Typography>
        )}
      </form>
    </Box>
  );
};

export default SignIn;
