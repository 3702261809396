import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { queryClient } from "index";
import { authorizedFetch, drawer, setLoader, snackbar } from "utils";
import { RETAIL_URL } from "utils/constants";

const ToggleDialog = ({ open, handleClose, distributor }: any) => {
  function handleToggle() {
    let body = {};
    if (distributor?.status === "ACTIVE") {
      body = {
        deactivate: true,
      };
    } else {
      body = {
        deactivate: false,
      };
    }
    setLoader(true);
    let url = `${RETAIL_URL}/distribution/distributor/${distributor?._id}/deactivate`;
    authorizedFetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then((res) => {
        setLoader(false);
        if (res.meta.success) {
          snackbar.success(
            distributor?.status === "ACTIVE"
              ? `Distributor deactivated`
              : `Distributor activated`
          );
          queryClient.resetQueries("getDistributors");
          drawer.close();
          handleClose();
        } else {
          console.error(res);
          snackbar.error(
            distributor?.status === "ACTIVE"
              ? `Error deactivating distributor`
              : `Error activating distributor`
          );
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
        snackbar.error(
          distributor?.status === "ACTIVE"
            ? `Error deactivating distributor`
            : `Error activating distributor`
        );
      });
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {distributor?.status === "ACTIVE"
          ? "Deactivate Distributor?"
          : "Activate Distributor?"}
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ my: 1, mr: 5 }}>
          Are you sure you want to{" "}
          {distributor?.status === "ACTIVE" ? "deactivate" : "activate"}{" "}
          <b>{distributor?.name}</b>?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}> Cancel</Button>
        <Button variant="contained" onClick={handleToggle}>
          {distributor?.status === "ACTIVE" ? "Deactivate" : "Activate"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ToggleDialog;
