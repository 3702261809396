import { EditOutlined, HighlightOff } from "@mui/icons-material";
import { Avatar, Box, IconButton, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  authorizedFetch,
  drawer,
  getDarkModePreference,
  GlobalState,
  titleCase,
} from "utils";

import Map from "components/Map";

import { useQuery } from "react-query";
import { BOLT_URL, TICKETS_URL } from "utils/constants";
import moment from "moment";

import CircularLoader from "components/CircularLoader";
import Table from "components/Table";

const DrawerContent = ({ ticket, setUpdateDialog }: any) => {
  const [currentStatus, setCurrentStatus] = useState([
    { header: "Ticket Info" },
    { label: "Charger UID", value: "" },
    { label: "Current Status", value: "" },
    { label: "Created By", value: "" },
    { label: "Created At", value: "" },
    { label: "Note", value: "" },
    { header: "Status History" },
  ]);

  const [chargerDetails, setChargerDetails] = useState({
    basicInfo: [
      { label: "UID", value: "" },
      { label: "Name", value: "" },
      { label: "Created At", value: "" },
      { label: "Visibility", value: "" },
    ],
    ownerInfo: [
      { label: "Name", value: "" },
      { label: "Phone Number", value: "" },
      { label: "Alternate Phone 1", value: "" },
      { label: "Alternate Phone 2", value: "" },
      { label: "Email", value: "" },
      { label: "Address", value: "" },
    ],
    stationInfo: [
      { label: "Contact Name", value: "" },
      { label: "Contact Number", value: "" },
      { label: "Usage Type", value: "" },
      { label: "Station Name", value: "" },
      { label: "Station Address", value: "" },
      { label: "City", value: "" },
      { label: "Latitude", value: "" },
      { label: "Longitude", value: "" },
    ],
    specs: [
      { label: "Manufacturer Name", value: "" },
      { label: "Firmware Version", value: "" },
      { label: "Power Rating", value: "" },
      { label: "Communication Protocol", value: "" },
      { label: "Connector Type", value: "" },
      { label: "Current", value: "" },
      { label: "Voltage", value: "" },
    ],
  });

  const [tab, setTab] = useState(0);

  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );

  const chargerUrl = `${BOLT_URL}/company/charger/${ticket.UID}`;
  const { isLoading: chargerLoading, data: chargerData } = useQuery(
    ["getChargerForTickets", ticket.UID, ticket.status],
    () => authorizedFetch(chargerUrl)
  );

  const ticketUrl = `${TICKETS_URL}/ticket/chargers/${ticket._id}`;
  const { isLoading: ticketLoading, data: ticketData } = useQuery(
    ["getTicket", ticket._id, ticket.status],
    () => authorizedFetch(ticketUrl)
  );

  // const chargerSpecificationUrl = `${BOLT_URL}/charger/${ticket.UID}/config`;
  // const {
  //   data: chargerSpecificationData,
  //   isLoading: chargerSpecificationLoading,
  // } = useQuery(
  //   ["getChargerSpecification", ticket.UID, ticket.status],
  //   () => authorizedFetch(chargerSpecificationUrl),
  //   {
  //     enabled: Boolean(ticket.UID),
  //   }
  // );

  const charger: any =
    chargerData?.data && JSON.stringify(chargerData.data) !== "{}"
      ? chargerData.data
      : null;

  // const chargerSpecs: any =
  //   chargerSpecificationData?.data?.specification &&
  //   JSON.stringify(chargerSpecificationData?.data?.specification) !== "{}"
  //     ? chargerSpecificationData.data.specification
  //     : null;

  const nearbyChargersUrl = `${BOLT_URL}/charger/getAvailableList?lat=${charger?.station.location.latitude}&lng=${charger?.station.location.longitude}&first=20&skip=0&radius=10000`;
  const { data: nearbyChargers } = useQuery(
    ["getNearbyChargers", ticket.UID],
    () => authorizedFetch(nearbyChargersUrl),
    {
      enabled: Boolean(charger),
    }
  );

  useEffect(() => {
    if (charger) {
      setChargerDetails({
        basicInfo: [
          { label: "UID", value: charger.charger.chargerId },
          { label: "Name", value: charger.charger.chargerName },
          {
            label: "Created At",
            value: moment(charger.charger.createdAt).format(
              "MMM DD, YYYY, HH:mm"
            ),
          },
          {
            label: "Visibility",
            value: charger?.charger?.hidden === false ? "Unhidden" : "Hidden",
          },
        ],
        ownerInfo: [
          {
            label: "Name",
            value: charger.owner.firstName
              ? charger.owner.firstName + " " + charger.owner.lastName
              : "-",
          },
          {
            label: "Phone Number",
            value: charger.owner.phone ? charger.owner.phone : "-",
          },
          {
            label: "Alternate Phone 1",
            value: charger.owner.altPhone1 ? charger.owner.altPhone1 : "-",
          },
          {
            label: "Alternate Phone 2",
            value: charger.owner.altPhone2 ? charger.owner.altPhone2 : "-",
          },
          {
            label: "Email",
            value: charger.owner.email ? charger.owner.email : "-",
          },
          {
            label: "Address",
            value: charger.owner.address ? charger.owner.address : "-",
          },
        ],
        stationInfo: [
          {
            label: "Contact Name",
            value: charger.incharge.name,
          },
          {
            label: "Contact Number",
            value: charger.incharge.phoneNumber,
          },
          {
            label: "Usage Type",
            value: charger.charger.usageType,
          },
          {
            label: "Station Name",
            value: charger.station.stationName,
          },
          {
            label: "Station Address",
            value: charger.station.address,
          },
          {
            label: "City",
            value: charger.station.city,
          },
          {
            label: "Latitude",
            value: charger.station.location.latitude,
          },
          {
            label: "Longitude",
            value: charger.station.location.longitude,
          },
        ],

        specs: [
          {
            label: "Auto Cutoff",
            // eslint-disable-next-line
            value: charger.specification.autoCutOff.value + " " + "seconds",
          },
          {
            label: "Power Output",
            // eslint-disable-next-line
            value: charger.specification.powerOutput.value + " " + "A",
          },
          {
            label: "Manufacturer",
            value:
              charger.charger.manufacturer ||
              charger.chargerType.manufacturerName ||
              "-",
          },
          {
            label: "Model",
            value:
              charger.charger.model || charger.chargerType.modelName || "-",
          },
          {
            label: "Power Rating",
            value: charger.chargerType.powerRating.value + " kW",
          },
          {
            label: "Firmware Version",
            value: charger.specification.firmware,
          },
          {
            label: "Communication Protocol",
            value: charger.chargerType.communicationProtocol,
          },
          {
            label: "Connector Type",
            value: charger.chargerType.connectorType,
          },
          {
            label: "Current",
            value: charger.chargerType.current.value + " A",
          },
          {
            label: "Voltage",
            value: charger.chargerType.voltage.value + " V",
          },
          // {
          //   label: "Over Voltage",
          //   value: `${chargerSpecs.chargerConfigParams.overVoltage} V`,
          // },
          // {
          //   label: "Over Voltage Time",
          //   value: `${chargerSpecs.chargerConfigParams.overVoltageTime.value} seconds`,
          // },
          // {
          //   label: "Under Voltage",
          //   value: `${chargerSpecs.chargerConfigParams.underVoltage} V`,
          // },
          // {
          //   label: "Under Voltage Time",
          //   value: `${chargerSpecs.chargerConfigParams.underVoltageTime.value} seconds`,
          // },
          // {
          //   label: "Over Current",
          //   value: `${chargerSpecs.chargerConfigParams.overCurrent} AMP`,
          // },
          // {
          //   label: "Over Current Time",
          //   value: `${chargerSpecs.chargerConfigParams.overCurrentTime.value} seconds`,
          // },
          // {
          //   label: "Over Temperature",
          //   value: `${chargerSpecs.chargerConfigParams.overTemperature} °C`,
          // },
          // {
          //   label: "Over Temperature Time",
          //   value: `${chargerSpecs.chargerConfigParams.overTemperatureTime.value} seconds`,
          // },
          // {
          //   label: "Under Temperature",
          //   value: `${chargerSpecs.chargerConfigParams.underTemperature} °C`,
          // },
          // {
          //   label: "Under Temperature Time",
          //   value: `${chargerSpecs.chargerConfigParams.underTemperatureTime.value} seconds`,
          // },
          // {
          //   label: "Auto Cutoff",
          //   value: chargerSpecs.chargerConfigParams.autoCutOff.value,
          // },
          // {
          //   label: "Auto Cutoff Time",
          //   value: `${chargerSpecs.chargerConfigParams.autoCutOffTime.value} seconds`,
          // },
          // {
          //   label: "Current Factor",
          //   value: chargerSpecs.chargerConfigParams.currentFactor,
          // },
          // {
          //   label: "Voltage Factor",
          //   value: chargerSpecs.chargerConfigParams.voltageFactor,
          // },
          // {
          //   label: "Active Power Factor",
          //   value: chargerSpecs.chargerConfigParams.activePowerFactor,
          // },
          // {
          //   label: "Energy Factor",
          //   value: charger.charger.energyFactor,
          // },
          // {
          //   label: "Power Output",
          //   // eslint-disable-next-line
          //   value: `${chargerSpecs.powerOutput.value} A`,
          // },
        ],
      });
    }
    // eslint-disable-next-line
  }, [charger]);

  useEffect(() => {
    if (ticketData && ticketData?.data?.length > 0) {
      setCurrentStatus([
        { header: "Ticket Info" },
        { label: "Charger UID", value: ticketData?.data[0]?.UID },
        { label: "Current Status", value: ticketData?.data[0]?.status },
        { label: "Created By", value: ticketData?.data[0]?.createdBy },
        {
          label: "Created At",
          value: moment(ticketData?.data[0]?.createdAt).format(
            "Do MMM, hh:mm a"
          ),
        },
        { label: "Note", value: ticketData?.data[0]?.currentNote },
      ]);
    }
    // eslint-disable-next-line
  }, [ticketData]);

  const state = useSelector((state: GlobalState) => state.global);

  useEffect(() => {
    if (!state.drawer.open) {
      setTab(0);
    }
  }, [state.drawer]);

  if (ticketLoading || chargerLoading)
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            px: 3,
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: isDarkMode ? "#000" : "#3D3D3D",
            fontWeight: 500,
            color: "#fff",
            mb: 2,
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            {ticket?.UID}
            <Avatar
              variant="status"
              className={
                ["CHARGER_FIXED", "CLOSED", "CHARGER_REPLACED"].includes(
                  ticket?.status
                )
                  ? "green"
                  : ticket?.status === "SERVICE_INITIATED"
                  ? "red"
                  : ticket?.status === "FIRMWARE_UPDATED"
                  ? "yellow"
                  : ticket?.status === "RCA_INITIATED"
                  ? "orange"
                  : "grey"
              }
            >
              {ticket?.status
                ? ticket?.status.split("_").map(titleCase).join(" ")
                : "N/A"}
            </Avatar>
          </Box>

          <IconButton
            children={<HighlightOff />}
            color="inherit"
            size="small"
            onClick={() => drawer.close()}
          />
        </Box>
        <CircularLoader />
      </Box>
    );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            px: 3,
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: isDarkMode ? "#000" : "#3D3D3D",
            fontWeight: 500,
            color: "#fff",
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            {ticket?.UID}
            <Avatar
              variant="status"
              className={
                ["CHARGER_FIXED", "CLOSED", "CHARGER_REPLACED"].includes(
                  ticket?.status
                )
                  ? "green"
                  : ticket?.status === "SERVICE_INITIATED"
                  ? "red"
                  : ticket?.status === "FIRMWARE_UPDATED"
                  ? "yellow"
                  : ticket?.status === "RCA_INITIATED"
                  ? "orange"
                  : "grey"
              }
            >
              {ticket?.status
                ? ticket?.status.split("_").map(titleCase).join(" ")
                : "N/A"}
            </Avatar>
          </Box>
          <Box>
            {ticket.status !== "CLOSED" && (
              <IconButton
                children={<EditOutlined />}
                color="inherit"
                size="small"
                onClick={() => {
                  setUpdateDialog({
                    open: true,
                    data: ticket,
                  });
                }}
              />
            )}

            <IconButton
              children={<HighlightOff />}
              color="inherit"
              size="small"
              onClick={() => drawer.close()}
            />
          </Box>
        </Box>

        <Box px={2} pt={2}>
          <Tabs
            className="less-dense"
            value={tab}
            onChange={(e, tab) => setTab(tab)}
          >
            <Tab label="Info" sx={{ width: "33.3333%" }} />
            <Tab label="Map" sx={{ width: "33.3333%" }} />
            <Tab label="History" sx={{ width: "33.3333%" }} />
          </Tabs>
        </Box>

        {tab === 0 && (
          <>
            <Box flexGrow={1} overflow="auto">
              <Box
                sx={{
                  px: 3,
                  pt: 2.5,
                  // pb: 10,
                  "& .table": {
                    borderCollapse: "collapse",
                    width: 1,
                    fontSize: 14,
                    lineHeight: "16px",
                    "& td": {
                      py: 2,
                      px: 2,
                    },
                    "& .bold": {
                      fontWeight: 500,
                      width: 135,
                    },
                    "& .header": {
                      px: 2,
                      py: 1,
                      position: "relative",

                      "& td": {
                        position: "absolute",
                        verticalAlign: "middle",
                        backgroundColor: (theme) => theme.customColors.header,
                        width: 1,
                        borderRadius: "4px",
                        fontSize: 16,
                        fontWeight: 600,
                        "& span": {
                          display: "inline-block",
                          transform: "translateY(1px)",
                        },
                      },
                    },
                    "& .first > td": {
                      pt: 9,
                    },
                    "& .last > td": {
                      pb: 3,
                    },
                  },
                }}
              >
                <table className="table">
                  <tbody>
                    {currentStatus.map(({ header, label, value }: any, i) => {
                      const isFirst = currentStatus[i - 1]?.header;
                      const isLast =
                        !currentStatus[i + 1] || currentStatus[i + 1].header;

                      return (
                        <tr
                          key={i}
                          className={
                            header
                              ? "header"
                              : `${isFirst ? "first" : ""} ${
                                  isLast ? "last" : ""
                                }`
                          }
                        >
                          {header ? (
                            <td colSpan={2}>
                              <span>{header}</span>
                              {/* <IconButton
                            sx={{ ml: 1.5 }}
                            children={<EditOutlined />}
                            color="primary"
                            size="small"
                          /> */}
                            </td>
                          ) : (
                            <>
                              <td className="bold">{label}</td>
                              <td>
                                {label === "Current Status" ? (
                                  <Avatar
                                    variant="status"
                                    className={
                                      [
                                        "CHARGER_FIXED",
                                        "CLOSED",
                                        "CHARGER_REPLACED",
                                      ].includes(value)
                                        ? "green"
                                        : value === "SERVICE_INITIATED"
                                        ? "red"
                                        : value === "FIRMWARE_UPDATED"
                                        ? "yellow"
                                        : value === "RCA_INITIATED"
                                        ? "orange"
                                        : "grey"
                                    }
                                  >
                                    {value
                                      ? value
                                          .split("_")
                                          .map(titleCase)
                                          .join(" ")
                                      : "N/A"}
                                  </Avatar>
                                ) : (
                                  value
                                )}
                              </td>
                            </>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <table className="table">
                  <tbody>
                    <tr className="header">
                      <td colSpan={2}>
                        <span className="label">Charger Info</span>
                      </td>
                    </tr>
                    {chargerDetails.basicInfo.map(({ label, value }, i) => (
                      <tr
                        key={i}
                        className={
                          i === 0
                            ? "first"
                            : i === chargerDetails.basicInfo.length - 1
                            ? "last"
                            : ""
                        }
                      >
                        <td className="bold">{label}</td>
                        <td>{value}</td>
                      </tr>
                    ))}
                    <tr className="header">
                      <td colSpan={2}>
                        <span className="label">Charger Station Info</span>
                      </td>
                    </tr>
                    {chargerDetails.stationInfo.map(({ label, value }, i) => (
                      <tr
                        key={chargerDetails.stationInfo.length + i}
                        className={
                          i === 0
                            ? "first"
                            : i === chargerDetails.stationInfo.length - 1
                            ? "last"
                            : ""
                        }
                      >
                        <td className="bold">{label}</td>
                        <td>{value}</td>
                      </tr>
                    ))}

                    <tr className="header">
                      <td colSpan={2}>
                        <span className="label">Charger Owner Info</span>
                      </td>
                    </tr>
                    {chargerDetails.ownerInfo.map(({ label, value }, i) => (
                      <tr
                        key={i}
                        className={
                          i === 0
                            ? "first"
                            : i === chargerDetails.ownerInfo.length - 1
                            ? "last"
                            : ""
                        }
                      >
                        <td className="bold">{label}</td>
                        <td>{value}</td>
                      </tr>
                    ))}

                    <tr className="header" style={{ marginTop: "20px" }}>
                      <td
                        style={{
                          height: "50px",
                          display: "flex",
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                        colSpan={2}
                      >
                        <span className="label">Charger Specifications</span>
                      </td>
                    </tr>

                    {chargerDetails.specs.map(({ label, value }, i) => (
                      <tr
                        key={chargerDetails.basicInfo.length + i}
                        className={
                          i === 0
                            ? "first"
                            : i === chargerDetails.specs.length - 1
                            ? "last"
                            : ""
                        }
                      >
                        <td className="bold">{label}</td>
                        <td>{value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Box>
            </Box>
          </>
        )}
        {tab === 1 && (
          <Box p={2.5} height={436}>
            <Map
              loading={false}
              type="charger"
              borderRadius={1}
              location={charger?.station.location}
              dataArray={nearbyChargers?.data || []}
            />
          </Box>
        )}
        {tab === 2 && (
          <Box mt={3}>
            <Table
              disablePagination
              hideDivider
              disableSorting
              px={2}
              height={550}
              rowsPerPage={10}
              rows={ticketData?.data[0]?.updates || []}
              loading={ticketLoading}
              columns={[
                {
                  key: "status",
                  label: "Status",
                  Render: ({ status }) => {
                    return (
                      <Avatar
                        variant="status"
                        className={
                          [
                            "CHARGER_FIXED",
                            "CLOSED",
                            "CHARGER_REPLACED",
                          ].includes(status)
                            ? "green"
                            : status === "SERVICE_INITIATED"
                            ? "red"
                            : status === "FIRMWARE_UPDATED"
                            ? "yellow"
                            : status === "RCA_INITIATED"
                            ? "orange"
                            : "grey"
                        }
                      >
                        {status
                          ? status.split("_").map(titleCase).join(" ")
                          : "N/A"}
                      </Avatar>
                    );
                  },
                },
                {
                  key: "updatedBy",
                  label: "Updated By",
                },
                {
                  key: "updatedAt",
                  label: "Updated At",
                  format: (value) => moment(value).format("Do MMM, hh:mm a"),
                },
                {
                  key: "note",
                  label: "Note",
                },
              ]}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default DrawerContent;
