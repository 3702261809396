import { HighlightOff } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { queryClient } from "index";
import { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { GlobalState, authorizedFetch, setLoader, snackbar } from "utils";
import { BOLT_URL } from "utils/constants";

interface VendorProps {
  open: boolean;
  handleClose: () => void;
  data: any;
}

const AssignVendorDialog: React.FC<VendorProps> = ({
  open,
  handleClose,
  data,
}) => {
  const { masterView } = useSelector((state: GlobalState) => state.global);
  const s = data.length > 1 ? "s" : "";

  const [selectedVendor, setSelectedVendor] = useState<any>(null);

  const url = `${BOLT_URL}/company/getVendors`;
  const { data: vendorsData } = useQuery(
    ["getVendors", masterView],
    () =>
      authorizedFetch(url, {
        headers: {
          master: masterView,
        },
      }),
    {
      enabled: !!data, // Defer until chargers are loaded
    }
  );

  const vendors =
    vendorsData?.data?.constructor === Array ? vendorsData.data : [];

  function handleSave() {
    setLoader(true);
    authorizedFetch(
      `${BOLT_URL}/company/vendor/${selectedVendor.id}/assignChargers`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          stage: "prod",
        },
        body: {
          chargerIds: data,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        setLoader(false);
        snackbar.success(
          `${data.length} charger${s} assigned to "${selectedVendor.name}"`
        );
        queryClient.resetQueries("getAllStats");
        handleClose();
      } else {
        setLoader(false);
        snackbar.error(`Error assigning charger${s}`);
      }
    });
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        Assign Vendor
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 0.5, pb: 2 }}>
          <Typography mb={2}>
            Assigning <strong>{data.length}</strong> selected charger{s}
          </Typography>
          <Autocomplete
            size="small"
            sx={{ maxWidth: 300 }}
            options={vendors}
            getOptionLabel={(option) => option.name}
            onChange={(e: any, newValue: any) => {
              setSelectedVendor(newValue);
            }}
            renderInput={(params: any) => (
              <TextField {...params} label="Choose Vendor" />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={!selectedVendor}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignVendorDialog;
