import { HighlightOff } from "@mui/icons-material";
import { Box, Button, IconButton, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { drawer, getDarkModePreference, GlobalState } from "utils";
import AddDialog from "../AddDialog";
import DeleteDialog from "../DeleteDialog";
import AssignedVehicles from "./AssignedVehicles";
import Info from "./Info";
import ToggleDialog from "../ToggleDialog";

const DrawerContent = ({ distributor }: any) => {
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );

  const [tab, setTab] = useState(0);
  const [editDialog, setEditDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [toggleDialog, setToggleDialog] = useState(false);

  return (
    <>
      <AddDialog
        open={editDialog}
        handleClose={() => setEditDialog(false)}
        isEditMode
        distributor={distributor}
      />
      <DeleteDialog
        open={deleteDialog}
        handleClose={() => setDeleteDialog(false)}
        distributor={distributor}
      />
      <ToggleDialog
        open={toggleDialog}
        handleClose={() => setToggleDialog(false)}
        distributor={distributor}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            px: 3,
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: isDarkMode ? "#000" : "#3D3D3D",
            fontWeight: 500,
            color: "#fff",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {distributor?.name}
          </Box>
          <Box display="flex">
            {/* <IconButton
              sx={{ mr: 1 }}
              children={<EditOutlined />}
              color="inherit"
              size="small"
              onClick={() => setEditDialog(true)}
            /> */}
            {/* <IconButton
              sx={{ mr: 1 }}
              children={<DeleteOutline />}
              color="inherit"
              size="small"
              onClick={() => setDeleteDialog(true)}
            /> */}
            <Button
              variant="contained"
              size="small"
              sx={{ mr: 1 }}
              onClick={() => setToggleDialog(true)}
            >
              {distributor?.status === "ACTIVE" ? "DEACTIVATE" : "ACTIVATE"}
            </Button>
            <IconButton
              children={<HighlightOff />}
              color="inherit"
              size="small"
              onClick={() => drawer.close()}
            />
          </Box>
        </Box>
        <Box flexGrow={1} overflow="auto" pb={2}>
          <Box p={2}>
            <Tabs
              className="less-dense"
              value={tab}
              onChange={(e, tab) => setTab(tab)}
            >
              <Tab label="Info" />
              <Tab label="Assigned Vehicles" />
            </Tabs>
          </Box>
          {tab === 0 ? (
            <Info distributor={distributor} />
          ) : (
            <AssignedVehicles distributor={distributor} />
          )}
        </Box>
      </Box>
    </>
  );
};

export default DrawerContent;
