import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { queryClient } from "index";
import { useMutation } from "react-query";

import { snackbar, setLoader, authorizedFetch } from "utils";
import { LEASE_URL } from "utils/constants";
import { getBoltToken } from "utils/request";
import storageManager from "utils/storageManager";

const UnAssignVehicles = ({ open, handleClose, vendor, selected }: any) => {
  let boltToken = getBoltToken();

  const mutation = useMutation(
    ["unassignVehicles", vendor?._id],
    () =>
      authorizedFetch(`${LEASE_URL}/company/vendors/unassign-vendor`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          stage: "test",
          token: storageManager?.get("companyToken") || "",
          Authorization: `Bearer ${boltToken}`,
        },
        body: {
          distributor: vendor?._id,
          vehicles: selected,
        },
      }),
    {
      onSuccess: (res) => {
        setLoader(false);
        if (res.msg.includes("Vendor unassigned")) {
          snackbar.success(`Vehicles Unassigned`);
          queryClient.resetQueries("getVehicles");
          queryClient.resetQueries("getVendorVehicles");
          queryClient.resetQueries("getVendorVehicle");
          handleClose();
        } else {
          console.error(res);
          snackbar.error(res.msg);
        }
        handleClose();
      },
      onError: () => {
        setLoader(false);
        snackbar.error(`Error unassigning vehicles`);
      },
    }
  );
  function confirm() {
    setLoader(true);
    mutation.mutate();
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Unassign Vehicles</DialogTitle>
      <DialogContent className="py-1">
        Unassign selected vehicles?
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={confirm}
        >
          Unassign
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnAssignVehicles;
