import { Box } from "@mui/material";
import Total from "./Total";
import Leases from "./Leases";
import Earnings from "./Earnings";
import Distance from "./Distance";

const Dashboard = ({ statsData, statsLoading }: any) => {
  return (
    <Box
      sx={{
        width: 1,
        gap: { xs: 2, md: 3 },
        display: "grid",
        gridTemplateColumns: {
          xs: "1fr",
          lg: "repeat(6, 1fr)",
        },
        "& > .MuiPaper-root": {
          borderRadius: 2,
          boxShadow: (theme) => theme.customShadows.small,
        },
        mt: 2,
      }}
    >
      <Total statsData={statsData} statsLoading={statsLoading} />
      <Leases />
      <Earnings />
      <Distance />
    </Box>
  );
};

export default Dashboard;
