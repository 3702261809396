import { Avatar, Box } from "@mui/material";
import CircularLoader from "components/CircularLoader";
import { Fragment, useMemo } from "react";
import { useQuery } from "react-query";
import { authorizedFetch } from "utils";
import { RETAIL_URL } from "utils/constants";

function getAddress(obj: any) {
  let address = "";
  let fields = ["local", "city", "district", "state", "country", "pincode"];
  fields.forEach((field) => {
    if (obj && obj[field]) address += obj[field] + "\n";
  });

  return address;
}

const Info = ({ distributor }: any) => {
  const url = `${RETAIL_URL}/distribution/distributor/${distributor?._id}`;
  const { isLoading, data } = useQuery(
    ["getDistributor", distributor?._id],
    () => authorizedFetch(url),
    { enabled: Boolean(distributor) }
  );

  const distributorDetails: any = useMemo(() => {
    if (!data?.data?.[0]) return {};

    let { distributor, user } = data.data[0];

    let distributorDetails = {
      "Distributor Info": {
        "Company Name": distributor.name,
        Phone: distributor.phone,
        GST: distributor.gstNumber,
        Address: getAddress(distributor.address),
      },
      "User Info": {
        "First Name": user?.firstName,
        "Last Name": user?.lastName,
        Phone: user?.phone,
        Email: user?.email,
      },
    };
    return distributorDetails;
  }, [data]);

  if (isLoading) return <CircularLoader />;

  return (
    <Box
      sx={{
        px: 3,
        "& .table": {
          borderCollapse: "collapse",
          width: 1,
          fontSize: 14,
          lineHeight: "16px",
          "& td": {
            py: 1.25,
            px: 2,
            whiteSpace: "pre-line",
          },
          "& .bold": {
            fontWeight: 500,
          },
          "& .header": {
            px: 2,
            py: 1,
            position: "relative",
            "& td": {
              position: "absolute",
              verticalAlign: "middle",
              backgroundColor: (theme: any) => theme.customColors.header,
              width: 1,
              borderRadius: "4px",
              fontSize: 16,
              fontWeight: 600,
              "& .label": {
                display: "inline-block",
                transform: "translateY(1px)",
                py: 1.125,
              },
            },
          },
          "& .first > td": {
            pt: 9,
          },
          "& .last > td": {
            pb: 2.75,
          },
        },
      }}
    >
      <table className="table">
        <tbody>
          {Object.keys(distributorDetails).map((category: any, i: number) => (
            <Fragment key={i}>
              <tr className="header">
                <td colSpan={2}>
                  <span className="label">{category}</span>
                </td>
              </tr>
              {Object.keys(distributorDetails[category]).map(
                (label: any, i: number) => {
                  let value = distributorDetails[category][label];
                  return (
                    <tr
                      key={i}
                      className={
                        i === 0
                          ? "first"
                          : i ===
                            Object.keys(distributorDetails[category]).length - 1
                          ? "last"
                          : ""
                      }
                    >
                      <td>{label}</td>
                      <td className="bold">
                        {label === "Status" ? (
                          <Avatar
                            variant="status"
                            className={value !== "ACTIVE" ? "yellow" : ""}
                          >
                            {value}
                          </Avatar>
                        ) : value === true ? (
                          "Enabled"
                        ) : value === false ? (
                          "Disabled"
                        ) : value === "" ? (
                          "-"
                        ) : (
                          value
                        )}
                      </td>
                    </tr>
                  );
                }
              )}
            </Fragment>
          ))}
        </tbody>
      </table>
    </Box>
  );
};

export default Info;
