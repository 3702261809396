import {
  // AccessTime,

  AccountTreeOutlined,
  ElectricMopedOutlined,
} from "@mui/icons-material";
import { alpha, Box, Paper, Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
import { GlobalState } from "utils";

const Cards = ({ vendorsData, vendorsLoading }: any) => {
  const { drawer } = useSelector((state: GlobalState) => state.global);

  const totalVendors = vendorsData?.data?.count || 0;

  const totalAssignedVehicles = vendorsData?.data?.assignedVehiclesCount || 0;

  return (
    <Box
      sx={{
        display: "grid",
        mb: { xs: 3, md: 4 },
        gridTemplateColumns: {
          lg: drawer.open ? "1fr" : "1fr 1fr",
        },
        gap: { xs: 3, md: 4 },
        "& .MuiPaper-root": {
          boxShadow: (theme) => theme.customShadows.card,
        },
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr 1fr",
            lg: "repeat(2, 1fr)",
          },
          gap: { xs: 1.75, md: 4 },
          gridColumn: "1 / -1",
          "& .card": {
            height: "auto !important",
            width: 1,
            // borderRadius: 0,
            py: { xs: 1.25, md: 2 },
            px: { xs: 1.25, md: 3 },
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            "& .icon": {
              borderRadius: 50,
              height: { xs: 32, md: 72, xl: 98 },
              width: { xs: 32, md: 72, xl: 98 },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              bgcolor: (theme) => alpha(theme.palette.primary.main, 0.2),
              color: (theme) => theme.palette.primary.main,
              fontSize: { xs: 18, md: 32, xl: 44 },
            },
            "& .description": {
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              "& .label": {
                mb: { xs: 1, md: 1.5 },
                textAlign: "end",
                fontSize: { xs: "12px", md: 16, xl: 18 },
                fontWeight: 600,
                lineHeight: { xs: "16px", md: "24.55px" },
                color: (theme) => theme.customColors.text.grey,
              },
              "& .value": {
                textAlign: "end",
                fontSize: { xs: "16px", md: "24px", xl: "32px" },
                fontWeight: 700,
                lineHeight: { xs: "19.1px", md: "44px" },
                letterSpacing: "0em",
              },
              "& .greyed": {
                color: (theme) => theme.palette.text.disabled,
                userSelect: "none",
              },
            },
          },
        }}
      >
        <Paper className="card">
          <Box className="icon">
            <AccountTreeOutlined fontSize="inherit" />
          </Box>
          <Box className="description">
            <span className="label">Total Vendors</span>
            <span className="value">
              {vendorsLoading ? <Skeleton width={60} /> : totalVendors}
            </span>
          </Box>
        </Paper>
        <Paper className="card">
          <Box className="icon">
            <ElectricMopedOutlined fontSize="inherit" />
          </Box>
          <Box className="description">
            <span className="label">Total Assigned Vehicles</span>
            <span className="value">
              {vendorsLoading ? <Skeleton width={60} /> : totalAssignedVehicles}
            </span>
          </Box>
        </Paper>
        {/* <Paper className="card">
          <Box className="icon">
            <AccessTime fontSize="inherit" />
          </Box>
          <Box className="description">
            <span className="label">Total Leases</span>
            <span className="value">
              {bookingsStatsLoading ? <Skeleton width={60} /> : bookingDuration}
            </span>
          </Box>
        </Paper> */}
      </Box>
    </Box>
  );
};

export default Cards;
