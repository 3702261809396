import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { VEHICLE_SUBSCRIPTION_URL } from "utils/constants";

const MarkEligible = ({
  open,
  sequence: initialSequence,
  handleClose,
  vehicle,
  refetch,
}: any) => {
  const [sequence, setSequence] = useState(initialSequence);

  useEffect(() => {
    setSequence(initialSequence);
  }, [initialSequence]);

  function handleToggle() {
    setLoader(true);

    let url = `${VEHICLE_SUBSCRIPTION_URL}/v1/647585d1aa75915373a3d68f/mark-eligibility?sequence=${sequence}`;
    authorizedFetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        vin: vehicle?.vin,
      },
    })
      .then((res) => {
        setLoader(false);
        snackbar.info(res.msg);
        refetch();
        handleClose();
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
        snackbar.error("An error occurred");
      });
  }
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle display="flex" alignItems="center">
        Subscription Plan
        <Typography ml={1} variant="overline" fontSize={14} lineHeight="1em">
          (Pro Rider)
        </Typography>
      </DialogTitle>
      <DialogContent>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <Box display="flex" alignItems="center" width="100%">
            <Box sx={{ flexGrow: 1 }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-around"
              >
                <Box display="flex" flexDirection="column" alignItems="center">
                  <img
                    src="sequenceA.png"
                    width="120px"
                    height="250px"
                    alt="Option 1"
                  />
                  <FormControlLabel
                    checked={sequence === "A"}
                    value="A"
                    onChange={() => setSequence("A")}
                    control={<Radio />}
                    labelPlacement="end"
                    label="Sequence A"
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  height="270px"
                >
                  <img
                    src="sequenceB.png"
                    width="110px"
                    height="250px"
                    alt="Option 2"
                  />
                  <FormControlLabel
                    checked={sequence === "B"}
                    value="B"
                    onChange={() => setSequence("B")}
                    control={<Radio />}
                    labelPlacement="end"
                    label="Sequence B"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={handleToggle}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MarkEligible;
