import { Breadcrumbs as MuiBreadcrumbs, Typography, Link } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import { useLocation, useHistory } from "react-router";
import { routes } from "routes";
import { useSelector } from "react-redux";
import { GlobalState } from "utils";

const Breadcrumbs = ({ subItem, isVehicleView, vin }: any) => {
  const { activeSection } = useSelector((state: GlobalState) => state.global);
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const history = useHistory();

  return (
    <MuiBreadcrumbs
      sx={{
        "& .MuiTypography-root": {
          fontSize: 20,
          color: "text.primary",
          letterSpacing: "-0.015em",
        },
      }}
      separator={<ChevronRight />}
    >
      {pathnames.length === 0 ? (
        <Typography fontWeight={700}>
          {activeSection &&
            routes[activeSection]?.find((route) => route.path === "/")?.name}
        </Typography>
      ) : (
        pathnames.map((value, index, { length }) => {
          const to = `/${pathnames.slice(0, index + 1).join("/")}`;
          const name =
            activeSection &&
            (routes[activeSection].find((route) => route.path === to)?.name ||
              pathnames[index]);

          return index !== length - 1 ? ( // All items except the last one to be links
            <Link
              underline="hover"
              key={index}
              href={to}
              onClick={(e) => {
                e.preventDefault();
                history.push(to);
              }}
            >
              {name}
            </Link>
          ) : (
            <Typography key={index} fontWeight={700}>
              {isVehicleView ? vin : name}
            </Typography>
          );
        })
      )}
      {subItem && <Typography>{subItem}</Typography>}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
